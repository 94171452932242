import { Injectable } from '@angular/core';
import { CompaniesService } from '@routes/companies/companies.service';

import { CropService } from '@routes/crops/crop.service';
import { PackagingService } from '@routes/packagings/packaging.service';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';

import { DataProviderEntity } from '@shared/constants/data-provider.constants';
import { IProviderData } from '@shared/models/data-provider.models';
import { GenericPagination } from '@shared/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {

  private lastUpdates: Map<DataProviderEntity, Date>;
  private endpoints: Map<DataProviderEntity, any>;
  data: IProviderData;
  init: boolean;

  constructor(
    private siteService: WorkingSiteService,
    private cropService: CropService,
    private packagingService: PackagingService,
    private companyService: CompaniesService
  ) {}

  initService = () => {
    this.lastUpdates = new Map();
    this.endpoints = new Map();
    this.endpoints.set(DataProviderEntity.Sites, this.siteService.getSites);
    this.endpoints.set(DataProviderEntity.Crops, this.cropService.getCrops);
    this.endpoints.set(DataProviderEntity.Packagings, this.packagingService.getPackagings);
    this.endpoints.set(DataProviderEntity.Companies, this.companyService.getCompanies);
  }

  updateEntities = async (entities: DataProviderEntity[]) => {
    if (!this.init) {
      this.initService();
      this.init = true;
      this.data = {};
    }
    return await Promise.all(entities.map(async entity => {
      const lastUpdate = this.lastUpdates.has(entity)
        ? this.lastUpdates.get(entity)
        : null;
      const pagination = new GenericPagination({ sortField: 'name' });
  
      if (lastUpdate) pagination.params.updated = lastUpdate;
      this.lastUpdates.set(entity, new Date());
      this.data[entity] = await this.endpoints.get(entity)(pagination);
    }));
  }
  
}
