import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ICompanyDeliveryAddress } from '@routes/companies/companies.model';
import { FORM_MODES } from '@shared/constants/forms';

@Component({
  selector: 'app-companies-edit-delivery-addresses',
  templateUrl: './companies-edit-delivery-addresses.component.html',
  styleUrls: ['./companies-edit-delivery-addresses.component.scss']
})
export class CompaniesEditDeliveryAddressesComponent implements OnInit {

  @Input() deliveryAddresses: ICompanyDeliveryAddress[];
  @Input() mode: FORM_MODES;
  @Output() addressesUpdated = new EventEmitter<ICompanyDeliveryAddress[]>();
  @ViewChild('deliveryAddress') deliveryAddress: ElementRef;

  dataSource: MatTableDataSource<ICompanyDeliveryAddress>;
  form: FormGroup;

  FORM_MODES = FORM_MODES;

  columns: string[] = ['address', 'city', 'state', 'zipcode', 'country', 'actions'];

  constructor() {}

  ngOnInit(): void {
    this.setForm();
    this.setTable();
  }

  setForm = () => this.form = new FormGroup({
    address: new FormControl(null, Validators.required),
    city: new FormControl(null, Validators.required),
    state: new FormControl(null, Validators.required),
    zipcode: new FormControl(null, Validators.required),
    country: new FormControl(null, Validators.required),
  });

  setTable = () => {
    this.dataSource = new MatTableDataSource(this.deliveryAddresses);
    if (this.mode === FORM_MODES.detail) this.columns.splice(this.columns.indexOf('actions'), 1);
  }

  addAddress = () => {
    if (this.form.invalid) return;
    this.deliveryAddresses.push({ ...this.form.value });
    this.addressesUpdated.emit(this.deliveryAddresses);
    this.setTable();
    this.form.reset();
  }

  deleteAddress = (index: number) => {
    this.deliveryAddresses.splice(index, 1);
    this.addressesUpdated.emit(this.deliveryAddresses);
    this.setTable();
  }

}
