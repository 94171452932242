<ul class="detail">
    <li class="category">
        <p class="title">{{ 'campaigns.workingDays.mass_edit_dialog.DATE' | translate }}</p>
        <p class="content">{{filters? (filters.startDate | date : dateFormat : 'GMT+2') : '-'}}</p>
    </li>
    <li class="category">
        <p class="title">{{ 'campaigns.workingDays.DEVICE' | translate }}</p>
        <p class="content">{{selectedDevice? selectedDevice : '-' }}</p>
    </li>
</ul>
