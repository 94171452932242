<section class="login-page">
    <form class="form" [formGroup]="loginForm" (submit)="logIn()">
        <img class="form-logo" src="/assets/images/logos/vivero_el_pinar_icon_logo.png" alt="Vivero El Pinar"/>
        <mat-form-field class="input full">
            <mat-label>{{ 'login.form.USER' | translate | titlecase }}</mat-label>
            <input matInput type="text" formControlName="username"/>     
        </mat-form-field>
        <mat-form-field class="input full">
            <mat-label>{{ 'login.form.PASS' | translate | titlecase }}</mat-label>
            <input matInput type="password" formControlName="password"/>
        </mat-form-field>
        <button mat-button color="primary" type="button" [routerLink]="REQUEST_PASSWORD_RECOVERY_ROUTE">{{ 'login.form.FORGOT_PASSWORD' | translate}}</button>
        <button mat-flat-button color="primary" class="form-button btn" type="submit" [disabled]="loginForm.invalid || loginInProgress">
            {{ 'login.form.LOGIN' | translate | titlecase }}
        </button>
    </form>
</section>