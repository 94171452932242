import { environment } from 'src/environments/environment'
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor() {}

    public getBaseUrl = () => environment.baseURL;
}