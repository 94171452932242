import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hour'
})
export class HourPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    if (!value && value !== 0) return '--:--:--';
    
    value = Number.parseFloat(value);

    let hours = 0;
    let minutes = 0;
    let seconds = value * 60 * 60;

    let hoursS: string;
    let minutesS: string;
    let secondsS: string;

    if (seconds >= 3600) {
      hours = Math.floor(seconds / 3600);
      seconds -= hours * 3600;
    }
    if (seconds >= 60) {
      minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60;
    }
    seconds = Math.round(seconds);

    hoursS = hours < 10 ? `0${hours}` : hours.toString();
    minutesS = minutes < 10 ? `0${minutes}` : minutes.toString();
    secondsS = seconds < 10 ? `0${seconds}` : seconds.toString();

    return `${hoursS}:${minutesS}:${secondsS}`;
  }

}
