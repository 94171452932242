import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IBatchMixedItem } from '@routes/batchs/batchs.model';
import { ItemReceived } from '@routes/items/items.model';
import { BatchsEditService } from '@routes/batchs/batchs-edit/batchs-edit.service';

import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { FORM_MODES } from '@shared/constants/forms';

@Component({
  selector: 'app-batch-edit-items-dialog',
  templateUrl: './batch-edit-items-dialog.component.html',
  styleUrls: ['./batch-edit-items-dialog.component.scss']
})
export class BatchEditItemsDialogComponent implements OnInit {

  FORM_MODES = FORM_MODES;
  mode: FORM_MODES;
  loading: boolean;

  items: ItemReceived[];
  filteredItems: ItemReceived[];
  form: FormGroup;

  batchItems: IBatchMixedItem[];
  item: IBatchMixedItem;

  cancelButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button
  };
  submitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CONFIRM',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit
  };

  constructor(
    private batchsEditService: BatchsEditService,
    private dialogRef: MatDialogRef<BatchEditItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.mode = this.data.mode;
    if (this.mode === FORM_MODES.delete) return;

    const label = this.mode === FORM_MODES.add ? 'ADD' : 'EDIT';
    this.submitButtonConfig.text = `general.actions.${label}`;

    this.items = this.batchsEditService.data.items;
    this.filteredItems = this.items;
    this.batchItems = this.data.batchItems;
    this.item = this.data.item;
    this.setupForm();
  }

  setupForm = () => {
    this.form = new FormGroup({
      item: new FormControl(null, [Validators.required, this.codeIsValidAndNotRepeated()]),
      boxes: new FormControl(null, [Validators.required, Validators.min(1), Validators.pattern(/^\d+$/)]),
      weight: new FormControl(null, [Validators.required, Validators.min(0)]),
      deleted: new FormControl(false, Validators.required)
    });
    if (this.item) {
      const item = this.items.find(item => item._id === this.item._id);
      this.form.patchValue({ ...this.item, item }, { emitEvent: false });
    }
    this.setWeightAutocalculation();
    this.setAutocompleteFiltering();
  }

  setWeightAutocalculation = () => merge(this.form.get('boxes').valueChanges, this.form.get('item').valueChanges)
    .pipe(filter(() => this.form.get('boxes').valid && this.form.get('item').valid)).subscribe(() => {
      const weight = this.form.get('item').value.weight.net;
      const boxes = this.form.get('boxes').value;
      this.form.get('weight').setValue(Number.parseFloat((weight * boxes).toFixed(2)));
  });

  setAutocompleteFiltering = () => this.form.get('item').valueChanges.pipe(
    filter(value => typeof value === 'string'),
    map((search: string) => this.filterItems(search.toLowerCase())))
  .subscribe(items => this.filteredItems = items);

  displayItem = (item: ItemReceived) => item ? item.description : '';

  selectItem = (event: MatAutocompleteSelectedEvent) => {
    const value = event.option.value;
    this.form.get('item').setValue(value, { emitEvent: false });
  }

  codeIsValidAndNotRepeated = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return { itemNotSelected: { value: control.value }};
      const selected = this.items.find(item => item._id === control.value._id);
      if (!selected) return { itemNotSelected: { value: control.value }};

      const codes = this.batchItems.map(b => b._id);
      if (this.item) codes.splice(codes.findIndex(c => c === this.item._id), 1);
      const existing = codes.find(c => c === selected._id);
      return existing ? { alreadyExists: { value: control.value }} : null
  }

  itemIsCorrectValidator = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
    const codes = this.batchItems.map(b => b._id);
    const existing = codes.find(c => c === control.value._id);
    return existing ? null : { itemNoExists: { value: control.value }}
  }

  filterItems = (search: string): ItemReceived[] => search !== ''
    ? this.items.filter(item =>
      item.sageReference.toLowerCase().startsWith(search) ||
      item.description.toLowerCase().startsWith(search))
    : this.items;

  submitForm = () => {
    if (this.form.invalid) return;
    this.loading = true;
    try {
      const data = {
        ...this.form.value.item,
        ...this.form.value };
      this.dialogRef.close(data);
    } catch (error) {
      console.error('Error en submitForm:', error);
    } finally {
      this.loading = false;
    }
  };

  confirmDelete = () => this.dialogRef.close(true);

}
