import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';

@Component({
  selector: 'app-campaigns-detail-working-days',
  templateUrl: './campaigns-detail-working-days.component.html',
  styleUrls: ['./campaigns-detail-working-days.component.scss']
})
export class CampaignsDetailWorkingDaysComponent implements OnInit {

  campaign: Campaign;

  constructor(
    private campaignService: CampaignPlannerService
  ) {}

  ngOnInit(): void {
    this.campaign = this.campaignService.campaign;
  }

}
