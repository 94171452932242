import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

import { WorkingSitesComponent } from './working-sites.component';
import { WorkingSitesMainComponent } from './working-sites-main/working-sites-main.component';
import { WorkingSitesEditComponent } from './working-sites-edit/working-sites-edit.component';
import { WORKING_SITES_ROUTES } from '@shared/constants/routes';
import { FORM_MODES } from '@shared/constants/forms';

const routes: Routes = [
    {
        path: '',
        component: WorkingSitesComponent,
        children: [
            {
                path: '',
                component: WorkingSitesMainComponent,
                pathMatch: 'full'
            },
            {
                path: WORKING_SITES_ROUTES.add,
                component: WorkingSitesEditComponent,
                data: {
                    mode: FORM_MODES.add
                }
            },
            {
                path: `${WORKING_SITES_ROUTES.edit}/:id`,
                component: WorkingSitesEditComponent,
                data: {
                    mode: FORM_MODES.edit
                }
            },
            {
                path: `${WORKING_SITES_ROUTES.detail}/:id`,
                component: WorkingSitesEditComponent,
                data: {
                    mode: FORM_MODES.detail
                }
            }
        ]
    }
];

@NgModule({
    declarations: [
        WorkingSitesComponent,
        WorkingSitesMainComponent,
        WorkingSitesEditComponent,
    ],
    providers: [

    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class WorkingSitesModule { }
