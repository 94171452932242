<app-campaigns-planner-basic
    *ngIf="section === 'basic'"
    (changeSection)="changeSection($event)">
</app-campaigns-planner-basic>

<app-campaigns-planner-sites
    *ngIf="section === 'sites'"
    (changeSection)="changeSection($event)">
</app-campaigns-planner-sites>

<app-campaigns-planner-zones
    *ngIf="section === 'zones'"
    (changeSection)="changeSection($event)">
</app-campaigns-planner-zones>

<app-campaigns-planner-workers
    *ngIf="section === 'workers'"
    (changeSection)="changeSection($event)">
</app-campaigns-planner-workers>

<app-campaigns-planner-summary
    *ngIf="section === 'summary'"
    (changeSection)="changeSection($event)">
</app-campaigns-planner-summary>