<ul class="list">
    <li>
        <p class="category">{{ 'batchs.CODE' | translate }}</p>
        <p class="content">{{ batch.code }}</p>
    </li>
    <ng-container *ngIf="category === BATCH_CATEGORY.UNIQUE">
        <li>
            <p class="category">{{ 'batchs.PLATE' | translate }}</p>
            <p class="content">{{ $any(batch).plate }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.STATUS' | translate }}</p>
            <p class="content">{{ 'batchs.status.' + $any(batch).status.toUpperCase() | translate }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.DATETIME' | translate }}</p>
            <p class="content">{{ $any(batch).datetime | date : 'd MMMM, HH:mm' : 'GMT' }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.SITE' | translate }}</p>
            <p class="content">{{ $any(batch).site.name }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.ZONE' | translate }}</p>
            <p class="content">{{ $any(batch).zoneName || '-' }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.CAMPAIGN' | translate }}</p>
            <p class="content">{{ $any(batch).campaign?.name || '-' }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.CROP' | translate }}</p>
            <p class="content">{{ $any(batch).crop.name }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.TOTAL' | translate }}</p>
            <p class="content">{{ $any(batch).amount | number : '1.0-2' }} {{ unit }}</p>
        </li>
    </ng-container>
    <ng-container *ngIf="category === BATCH_CATEGORY.MIXED">
        <li>
            <p class="category">{{ 'batchs.CUSTOM_CODE' | translate }}</p>
            <p class="content">{{ $any(batch).customCode || '-' }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.STATUS' | translate }}</p>
            <p class="content">{{ 'batchs.substatus.' + $any(batch).status.toUpperCase() | translate }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.DATETIME' | translate }}</p>
            <p class="content">{{ $any(batch).datetime | date : 'd MMMM, HH:mm' : 'GMT' }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.YEAR' | translate }}</p>
            <p class="content">{{ $any(batch).datetime | date : 'yyyy' : 'GMT' }}</p>
        </li>
        <!-- <li>
            <p class="category">{{ 'batchs.BOXES' | translate }}</p>
            <p class="content">{{ $any(batch).boxes }} {{ 'batchs.BOXES' | translate | lowercase }}</p>
        </li> -->
        <li>
            <p class="category">{{ 'batchs.TOTAL' | translate }}</p>
            <p class="content">{{ $any(batch).amount ? ($any(batch).amount | number : '1.0-2') + ' kg' : '-' }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.PALLET_NUMBER' | translate }}</p>
            <p class="content">{{ $any(batch).palletNumber || '-' }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.COMPANY' | translate }}</p>
            <p class="content">{{ $any(batch).company.businessName }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.WAREHOUSE' | translate }}</p>
            <p class="content">{{ $any(batch).warehouse.warehouse }}</p>
        </li>
        <li>
            <p class="category">{{ 'batchs.PRODUCTION_LINE' | translate }}</p>
            <p class="content">{{ $any(batch).productionLine.description }}</p>
        </li>
    </ng-container>
</ul>
