import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { BATCH_CATEGORY } from '@routes/batchs/batchs.constants';
import { GenericFiltersService } from '@shared/components/generic-filters/generic-filters-service.service';

@Component({
  selector: 'app-batchs',
  templateUrl: './batchs.component.html',
  styleUrls: ['./batchs.component.scss']
})
export class BatchsComponent implements OnInit, OnDestroy {

  title: string;
  subscription: Subscription;

  constructor(
    private router: Router,
    private genericFiltersService: GenericFiltersService
  ) {}

  ngOnInit(): void {
    this.checkBatchTitle();

    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart))
    .subscribe((event: NavigationStart) => this.checkBatchTitle(event));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.genericFiltersService.removeFiltersValue();
  }

  checkBatchTitle = (event?: NavigationStart) => {
    const url = event
      ? event.url
      : this.router.routerState.snapshot.url;
    const i18n = url.split('/').includes(BATCH_CATEGORY.UNIQUE) 
      ? 'UNIQUE'
      : 'MIXED';
    this.title = `batchs.titles.${i18n}`;
  }

}
