import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { MobileAppComponent } from '@routes/mobile-app/mobile-app.component';
import { MobileAppMainComponent } from '@routes/mobile-app/mobile-app-main/mobile-app-main.component';
import { MobileAppResolver } from '@routes/mobile-app/mobile-app.resolver';

const routes: Routes = [
    {
      path: '',
      component: MobileAppComponent,
      children: [{
        path: '',
        component: MobileAppMainComponent,
        pathMatch: 'full',
        resolve: {
          build: MobileAppResolver
        }
      }]
    }
  ];
  

  @NgModule({
    declarations: [
      MobileAppComponent,
      MobileAppMainComponent
    ],
    imports: [
      CommonModule,
      SharedModule,
      RouterModule.forChild(routes)
    ]
  })

export class MobileAppModule {}