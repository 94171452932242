import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploadPageConfiguration } from '@shared/interfaces/upload';

@Component({
  selector: 'app-upload-file-page-form',
  templateUrl: './upload-file-page-form.component.html',
  styleUrls: ['./upload-file-page-form.component.scss']
})
export class UploadFilePageFormComponent implements OnInit {

  @Input() config: UploadPageConfiguration;
  @Input() loading: boolean;

  @Output() uploadFile: EventEmitter<File> = new EventEmitter<File>();
  @Output() exitUpload: EventEmitter<void> = new EventEmitter<void>();

  file: File;

  constructor() {}

  ngOnInit(): void {}

  uploadedFile = (file: File) => this.file = file;
  
  processFile = () => this.uploadFile.emit(this.file);

}
