import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CompaniesService } from '@routes/companies/companies.service';
import { Pagination } from '@shared/models/pagination.model';

@Injectable({
    providedIn: 'root'
})
export class CompaniesResolver implements Resolve<object> {

    constructor(private companiesService: CompaniesService) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<object> {
        const _id = route.params.id;
        let companies = (await this.companiesService.getCompanies()).data;
        if (_id) companies = companies.filter(company => company._id !== _id);
        const inUseDispatchNoteCodes = companies
            .map(company => company.dispatchNoteCodes ? Object.values(company.dispatchNoteCodes) : [])
            .reduce((x, y) => x.concat(y), [])
            .filter(code => code);
        return {
            company: _id ? await this.companiesService.getCompany(_id) : null,
            inUseDispatchNoteCodes
        };
    }

}
