import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserManualInfo } from '@shared/models/user-manual-data.model';
import { ApiService } from '@shared/services/api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManualService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { }

  private getUserManualUrl = (): string => `${this.api.getBaseUrl()}/user-manual`;
  private getLatestsUserManualUrl = () => `${this.getUserManualUrl()}/latest`;

  getUserManualInfo = (): Promise<UserManualInfo> => lastValueFrom(this.http.get<UserManualInfo>(this.getLatestsUserManualUrl()));

  downloadUserManual = (): Promise<Blob> =>  lastValueFrom(this.http.get(this.getUserManualUrl(), { responseType: 'blob' }));
}
