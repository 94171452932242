<h2 class="h2">{{ 'campaigns.workingDays.complaint_dialog.TITLE' | translate }}</h2>
<p [innerHTML]="'campaigns.workingDays.complaint_dialog.DATA' | translate : { startTimestamp: workingDay.startTimestamp | date : 'EEE d MMMM', worker: workingDay.worker }"></p>
<p [innerHTML]="'campaigns.workingDays.complaint_dialog.NOTE' | translate"></p>
<app-generic-table
    [tableConfig]="tableConfig">
</app-generic-table>
<p class="total-boxes">
    {{ 'campaigns.workingDays.complaint_dialog.TOTAL_BOXES' | translate : {totalBoxes: totalBoxes} }}
</p>
<div class="buttons">
    <button mat-flat-button color="primary" class="btn" [disabled]="resolvingRoute" (click)="dialogRef.close()">
        {{ 'general.actions.BACK' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" [disabled]="resolvingRoute" (click)="editHarvestTask()">
        {{ 'general.actions.EDIT' | translate }}
    </button>
</div>
