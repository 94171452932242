import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';

import { DISPATCH_NOTE_BATCH_STATUS, DISPATCH_NOTE_STATUS, DISPATCH_NOTE_UNIT_PER_TYPE } from '@routes/dispatch-notes/dispatch-notes.constants';
import { DispatchNoteBatch, IDispatchNoteReceived, IDispatchNoteReceivedInvalidBatch, IDispatchNoteReceivedValidBatch } from '@routes/dispatch-notes/dispatch-notes.model';
import { DispatchNotesService } from '@routes/dispatch-notes/dispatch-notes.service';
import { DispatchNotesDetailConfirmationComponent } from '@routes/dispatch-notes/dispatch-notes-detail/dispatch-notes-detail-confirmation/dispatch-notes-detail-confirmation.component';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { ABS_BASE_ROUTE, BATCHS_ROUTES, SECTIONS } from '@shared/constants/routes';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableColumnStyle } from '@shared/components/generic-table/generic-table.constants';

@Component({
  selector: 'app-dispatch-notes-detail',
  templateUrl: './dispatch-notes-detail.component.html',
  styleUrls: ['./dispatch-notes-detail.component.scss'],
  providers: [DecimalPipe]
})
export class DispatchNotesDetailComponent implements OnInit {

  status = DISPATCH_NOTE_STATUS;
  dispatchNote: IDispatchNoteReceived;

  unit: string;
  loading = false;
  downloadDisabled = false;

  validBatchs: DispatchNoteBatch[] = [];
  invalidBatchs: DispatchNoteBatch[] = [];

  validBatchsTableConfig: TableConfiguration<IDispatchNoteReceivedValidBatch>;
  invalidBatchsTableConfig: TableConfiguration<IDispatchNoteReceivedInvalidBatch>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dispatchNotesService: DispatchNotesService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {
    this.dispatchNote = this.activatedRoute.snapshot.data.dispatchNote;
    this.unit = DISPATCH_NOTE_UNIT_PER_TYPE[this.dispatchNote.type];
    this.setupValidBatchsTable();
    this.setupInvalidBatchsTable();
    
    this.dispatchNote.batchs.forEach(batch => batch.status === DISPATCH_NOTE_BATCH_STATUS.VALID
      ? this.validBatchs.push(batch)
      : this.invalidBatchs.push(batch));
    this.downloadDisabled = this.validBatchs.length === 0;

    this.invalidBatchsTableConfig.data.next({ data: this.invalidBatchs, total: this.invalidBatchs.length });
    this.validBatchsTableConfig.data.next({ data: this.validBatchs, total: this.validBatchs.length });
  }

  setupValidBatchsTable = () => this.validBatchsTableConfig = new TableConfiguration<IDispatchNoteReceivedValidBatch>({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({ data: null, total: 0}),
    pagination: {
      page: 1,
      size: 10,
      search: null,
      sort: { field: 'code', order: 1 }
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: batch => batch.code
      }),
      new TableColumnText({
        header: 'ORIGIN',
        name: 'site',
        sorting: 'site.name',
        displayAt: 640,
        value: batch => batch.site.name
      }),
      new TableColumnText({
        header: 'ZONE',
        name: 'zone',
        sorting: 'zoneName',
        displayAt: 1440,
        value: batch => batch.zoneName,
      }),
      new TableColumnText({
        header: 'CROP',
        name: 'crop',
        sorting: 'crop.name',
        displayAt: 1024,
        value: batch => `${batch.crop.name} (${batch.crop.variety})`,
      }),
      new TableColumnText({
        header: 'TOTAL',
        name: 'amount',
        sorting: 'amount',
        displayAt: 520,
        value: batch => `${this.decimalPipe.transform(batch.amount, '1.0-2')} ${this.unit}`,
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: batch => [ABS_BASE_ROUTE, SECTIONS.traceability, BATCHS_ROUTES.parent, BATCHS_ROUTES.unique, batch._id, BATCHS_ROUTES.detail]
          })
        ]
      })
    ]
  });

  setupInvalidBatchsTable = () => this.invalidBatchsTableConfig = new TableConfiguration<IDispatchNoteReceivedInvalidBatch>({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({ data: null, total: 0}),
    pagination: {
      page: 1,
      size: 10,
      search: null,
      sort: { field: 'code', order: 1 }
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: batch => batch.code
      }),
      new TableColumnText({
        header: 'ERROR',
        name: 'site',
        sorting: 'site.name',
        displayAt: 960,
        value: batch => this.translateService.instant(`dispatchNotes.messages.${batch.reason}`),
      })
    ]
  });

  closeDispatchNote = () =>
    this.dialog.open(DispatchNotesDetailConfirmationComponent, {
      width: '420px',
      data: { dispatchNote: this.dispatchNote}
    }).afterClosed().subscribe(async data => {
      if (data && data.updated) {
        this.dispatchNote = await this.dispatchNotesService.getDispatchNote(this.dispatchNote._id);
        this.snackbarService.success(this.translateService.instant('dispatchNotes.messages.SUCCESFUL_CLOSING'));
      }
    });

  downloadDispatchNote = async () => {
    this.loading = true;
    this.snackbarService.loading(this.translateService.instant('dispatchNotes.files.LOADING'));

    try {
      const blob = await this.dispatchNotesService.downloadDispatchNote(this.dispatchNote._id);
      saveAs(blob, this.translateService.instant('dispatchNotes.files.NAME', { code: this.dispatchNote.code.replace(/\./g,'') }));
      this.snackbarService.snackBar.dismiss();
    } catch (error) {
      this.snackbarService.error(error.error.message);
    } finally {
      this.loading = false;
    }
  }

}
