import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-campaigns-detail-finish-dialog',
  templateUrl: './campaigns-detail-finish-dialog.component.html',
  styleUrls: ['./campaigns-detail-finish-dialog.component.scss']
})
export class CampaignsDetailFinishDialogComponent implements OnInit {

  campaign: Campaign;
  confirmation: boolean;
  loading: boolean;
  translation: any;

  cancelButtonConfig: IGenericButtonConfig = {
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button,
    text: 'general.actions.CANCEL',
  };
  confirmButtonConfig: IGenericButtonConfig = {
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button,
    text: 'general.actions.FINISH',
  };

  constructor(
    private translateService: TranslateService,
    private campaignService: CampaignService,
    private snackbarService: CustomSnackbarService,
    private dialogRef: MatDialogRef<CampaignsDetailFinishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.campaign = this.data.campaign;
    this.translation = this.translateService.instant('campaigns.finish');
  }

  confirm = () => {
    if (!this.confirm) return;
    this.loading = true;

    this.campaignService.finishCampaign(this.campaign.id)
      .then(campaign => this.dialogRef.close({finished: true, campaign}))
      .catch(error => this.snackbarService.error(error.error.message))
      .finally(() => this.loading = false);
  }

  close = () => this.dialogRef.close();

}
