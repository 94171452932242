import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { PasswordRecoveryCodeExpiredDialogComponent } from '@routes/password-recovery/password-recovery-code-expired-dialog/password-recovery-code-expired-dialog.component';
import { PasswordRecoveryDialogComponent } from '@routes/password-recovery/password-recovery-dialog/password-recovery-dialog.component';
import { UserService } from '@routes/users/user.service';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { LOGIN_ROUTE } from '@shared/constants/routes';
import { passwordMatchValidator } from '@shared/utils/validators.utils';
import { RECOVERY_FORM_MODES } from '@shared/constants/password-recovery';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  LOGIN_ROUTE = `/${LOGIN_ROUTE}`;
  processing = false;

  form: FormGroup;
  changing: boolean;
  recovering: boolean;
  recoveryCode: string;

  constructor(
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    const mode = this.activatedRoute.snapshot.data.mode;
    this.changing = mode === RECOVERY_FORM_MODES.CHANGE;
    this.recovering = mode === RECOVERY_FORM_MODES.RECOVERY;
    this.recoveryCode = this.activatedRoute.snapshot.queryParams.recoveryCode
    this.initForm();
  }

  initForm = () => this.form = new FormGroup({
    email: new FormControl({value: null, disabled: this.changing},
      [Validators.required, Validators.email]),
    password: new FormControl({value: null, disabled: this.recovering},
      Validators.required),
    repeat: new FormControl({value: null, disabled: this.recovering},
      [Validators.required, passwordMatchValidator('password')]),
  });

  displayFinishDialog = () => this.dialog.open(PasswordRecoveryDialogComponent,
    {width: '420px', disableClose: true}
  ).afterClosed().subscribe(() => this.router.navigate([LOGIN_ROUTE]));

  displayExpiredDialog = (email: string) => this.dialog.open(PasswordRecoveryCodeExpiredDialogComponent,
    {width: '420px', disableClose: true, data: {email}}
  ).afterClosed().subscribe(() => this.router.navigate([LOGIN_ROUTE]));

  submitForm = async () => {
    if (this.form.invalid || this.processing) return;
    this.processing = true;
    this.recovering
        ? await this.submitEmail()
        : await this.submitPassword();
  }

  submitPassword = () => this.userService.resetPassword(this.recoveryCode, this.form.value.password)
    .then(() => {
      this.snackbarService.success(this.translateService.instant('passwordRecovery.resetForm.SUCCESS'))
      this.router.navigate([LOGIN_ROUTE])})
    .catch(error => {
      if(error.error.message === "RECOVERY_CODE_EXPIRED") {
        return this.displayExpiredDialog(error.error.email);
      } 
      this.snackbarService.error(this.translateService.instant(`passwordRecovery.errors.${error.error.message}`))
      this.router.navigate([LOGIN_ROUTE])
    })
    .finally(() => this.processing = false);

  submitEmail = () => this.userService.recoverPassword(this.form.value.email)
    .then(() =>  this.displayFinishDialog())
    .catch(error => this.snackbarService.error(error.error.message))
    .finally(() => this.processing = false);

}
