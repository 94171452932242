import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { IDeviceHelp } from '@routes/devices/device.model';
import { HelpSelectorValue } from '@routes/help/help.models';
import { UserManualInfo } from '@shared/models/user-manual-data.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { UserManualService } from '@shared/services/user-manual.service';
import { saveAs } from 'file-saver';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-help-main',
  templateUrl: './help-main.component.html',
  styleUrls: ['./help-main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpMainComponent implements OnInit {

  help: IDeviceHelp;
  body: string;
  sections: HelpSelectorValue[] = [];

  manualInfo: UserManualInfo;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userManualService: UserManualService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.help = this.activatedRoute.snapshot.data.help;
    this.extractSections();
    this.extractHelp();
    this.getUserManual();
  }

  extractSections = () => {
    const html = document.createElement('html');
    html.insertAdjacentHTML('afterbegin', this.help.text);

    this.sections = Array.from(html.querySelectorAll('.section'))
      .map((element: HTMLElement) =>({section: element.dataset.section, i18n: element.dataset.i18n}));
  }

  extractHelp = (section?: string) => {
    const element = document.createElement('html');
    element.innerHTML = this.help.text;

    const selector = section ? `#${section}` : 'body';
    this.body = element.querySelector(selector).innerHTML;
  }

  selectSection = (event: MatSelectChange) => this.extractHelp(event.value);

  getUserManual = async () => this.manualInfo = await this.userManualService.getUserManualInfo();

  downloadUserManual = async () => {
    try {
      const blob = await this.userManualService.downloadUserManual();
      saveAs(blob, this.translateService.instant('help.file'));
      this.snackbarService.success(this.translateService.instant('help.messages.SUCCESS'));
    } catch (error) {
      this.snackbarService.error(this.translateService.instant('help.messages.ERROR'));
    } 
  };

  

}
