import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { PRODUCTION_LINES_DIALOG_TYPES } from '@routes/production-lines/production-lines.constants';
import { IProductionLineSent } from '@routes/production-lines/production-lines.model';
import { ProductionLinesService } from '@routes/production-lines/production-lines.service';

import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-production-lines-dialog',
  templateUrl: './production-lines-dialog.component.html',
  styleUrls: ['./production-lines-dialog.component.scss']
})
export class ProductionLinesDialogComponent implements OnInit {

  PRODUCTION_LINES_DIALOG_TYPES = PRODUCTION_LINES_DIALOG_TYPES;

  form: FormGroup;
  productionLine: IProductionLineSent;
  type: PRODUCTION_LINES_DIALOG_TYPES;
  loading: boolean;

  constructor(
    private productionLinesService: ProductionLinesService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<ProductionLinesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.productionLine = this.data.productionLine;
    this.type = this.data.type;
    this.setupForm();
  }

  setupForm = () => {
    this.form = new FormGroup({
      description: new FormControl(null, Validators.required),
    });
    if (this.productionLine) this.form.patchValue(this.productionLine);
  }

  submitForm = () => {
    const productionLine = { ...this.form.value, _id: this.productionLine && this.productionLine._id } as IProductionLineSent;

    const fn = this.type === PRODUCTION_LINES_DIALOG_TYPES.EDIT ?
      this.productionLinesService.editProductionLine(productionLine._id, productionLine) :
      this.productionLinesService.addProductionLine(productionLine);

    fn.then(() => {
      this.snackbarService.success(this.translateService.instant('productionLines.dialog.SUCCESS_' + this.type.toUpperCase()));
      return this.dialogRef.close();
    })
    .catch(error => this.snackbarService.error(error.error.message))
    .finally(() => this.loading = false);
  }

}
