<h2 class="title h2">{{ 'campaigns.workingDays.errors_dialog.TITLE' | translate }}</h2>
<ul class="detail">
    <li class="category">
        <p class="title">{{ texts.WORKER }}</p>
        <p class="content">{{workingDay.worker.name}} {{workingDay.worker.surname}}</p>
    </li>
    <li class="category">
        <p class="title">{{ texts.SITE }}</p>
        <p class="content">{{workingDay.site.name}} ({{workingDay.site.alias}})</p>
    </li>
    <li class="category">
        <p class="title">{{ texts.DEVICE }}</p>
        <p class="content">{{workingDay.device.name}}</p>
    </li>
    <li class="category">
        <p class="title">{{ texts.WORKING_DAY_START }}</p>
        <p class="content">{{workingDay.startTimestamp | date : dateFormat : 'GMT'}}</p>
    </li>
    <li class="category">
        <p class="title">{{ texts.WORKING_DAY_END }}</p>
        <p class="content">{{workingDay.endTimestamp | date : dateFormat : 'GMT' || '-'}}</p>
    </li>
</ul>
<mat-tab-group animationDuration="0ms">
    <!-- Working day -->
    <mat-tab [label]="texts.WORKING_DAY" *ngIf="errors.workingDay">
        <div class="section">
            <p class="alert alert-warning" [innerHTML]="texts.WORKING_DAY_WARNING"></p>
            <app-generic-table
                [tableConfig]="workingDayTableConfiguration"
            ></app-generic-table>
        </div>
    </mat-tab>
    <!-- Breaks -->
    <mat-tab [label]="texts.BREAKS" *ngIf="errors.breaks.length">
        <div class="section">
            <p class="alert alert-warning" [innerHTML]="texts.BREAKS_WARNING"></p>
            <app-generic-table
                [tableConfig]="breaksTableConfiguration"
            ></app-generic-table>
        </div>
    </mat-tab>
    <!-- Tasks -->
    <mat-tab [label]="texts.TASKS" *ngIf="errors.tasks.length">
        <div class="section">
            <p class="alert alert-warning" [innerHTML]="texts.TASKS_WARNING"></p>
            <app-generic-table
                [tableConfig]="tasksTableConfiguration"
            ></app-generic-table>
        </div>
    </mat-tab>
    <!-- Intervals -->
    <mat-tab [label]="texts.INTERVALS" *ngIf="errors.intervals.length">
        <div class="section">
            <p class="alert alert-warning" [innerHTML]="texts.INTERVALS_WARNING"></p>
            <app-generic-table
                [tableConfig]="intervalsTableConfiguration"
            ></app-generic-table>
        </div>
    </mat-tab>
</mat-tab-group>
<div class="buttons">
    <app-generic-button
        [config]="exitButtonConfig" (click)="closeDialog()"
    ></app-generic-button>
</div>
