<div class="mobile-app-wrapper-identifier wrapper">
    <section class="help" >
        <h3>{{ 'mobileApp.info.VERSION_NUMBER' | translate }} <strong>{{ $any(appBuild).version }}</strong></h3>
        <h3>{{ 'mobileApp.info.APP_UPDATED' | translate }}<strong>{{ $any(appBuild).updated_at | date : 'd MMMM yyyy' : 'GMT' }}</strong></h3>
        <button mat-flat-button color="primary" class="btn" type="button" (click)="download()">
            <mat-icon>download</mat-icon>
            {{ 'mobileApp.button.DOWNLOAD' | translate }}
        </button>
    </section>
</div>

