import { Component, OnInit, OnDestroy } from '@angular/core';
import { WORKER_ROUTES, SECTIONS, ABS_BASE_ROUTE } from '@shared/constants/routes';
import { ITYPE, WORKER_HISTORICS } from '@shared/interfaces/types';
import { WorkersDetailService } from '../workers-detail.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-workers-detail-historic',
  templateUrl: './workers-detail-historic.component.html',
  styleUrls: ['./workers-detail-historic.component.scss']
})
export class WorkersDetailHistoricComponent implements OnInit, OnDestroy {

  historicSection: string;
  subscriptions: Subscription[] = [];
  historics: ITYPE[] = WORKER_HISTORICS;
  WORKERS_LIST_ROUTE = [ABS_BASE_ROUTE, SECTIONS.workforce, WORKER_ROUTES.parent];


  constructor(
    private detailService: WorkersDetailService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.detailService.historicObservable.subscribe(section => this.historicSection = section));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
