<div class="edit-form">
    <form [formGroup]="form" class="form">
        <mat-tab-group animationDuration="0ms">
            <!-- Site -->
            <mat-tab [label]="texts.SITE">
                <div class="section editables form-wrapper">
                    <div class="form-inputs">
                        <h3 class="form-subtitle">{{ 'campaigns.workingDays.SITE' | translate }}</h3>
                        <mat-checkbox color="primary" formControlName="siteChecked">{{
                            'campaigns.workingDays.mass_edit_dialog.checkbox.SITE' | translate }}</mat-checkbox>

                        <mat-form-field class="w-100 mt-3" appearance="fill">
                            <mat-label>{{ 'campaigns.workingDays.SITE' | translate }}</mat-label>
                            <mat-select formControlName="site" [appReactiveDisable]="!enabledControls.siteChecked">
                                <mat-option *ngFor="let site of allSites" [value]="site.siteId">
                                    {{ site.siteName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-tab>
            <!-- Device -->
            <mat-tab [label]="texts.DEVICE">
                <div class="section editables form-wrapper">
                    <div class="form-inputs">
                        <h3 class="form-subtitle">{{ 'campaigns.workingDays.DEVICE' | translate }}</h3>
                        <mat-checkbox color="primary" formControlName="deviceChecked">{{
                            'campaigns.workingDays.mass_edit_dialog.checkbox.DEVICE' | translate }}</mat-checkbox>
                        <mat-form-field class="w-100 mt-3" appearance="fill">
                            <mat-label>{{ 'campaigns.workingDays.DEVICE' | translate }}</mat-label>
                            <mat-select formControlName="device" [appReactiveDisable]="!enabledControls.deviceChecked">
                                <mat-option *ngFor="let device of allDevices" [value]="device._id">
                                    {{ device.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-tab>
            <!-- Start Date -->
            <mat-tab [label]="texts.START_DATE">
                <div class="form-wrapper w-100 d-flex flex-wrap mt-3">
                    <h3 class="form-subtitle form-inputs">{{ 'campaigns.workingDays.START_TITLE' | translate }}</h3>
                    <p class="alert alert-warning">{{ 'campaigns.workingDays.mass_edit_dialog.alerts.START_TASK_CHANGE'
                        | translate }}</p>
                    <mat-checkbox color="primary" formControlName="startDateChecked">{{
                        'campaigns.workingDays.mass_edit_dialog.checkbox.START_DATE' | translate }}</mat-checkbox>
                    <div>
                        <mat-form-field class="mr-3 input-date" appearance="standard">
                            <mat-label for="startDate">{{ 'campaigns.workingDays.START_DATE' | translate }}</mat-label>
                            <input matInput id="startDate" name="startDate" [matDatepicker]="startDate"
                                formControlName="startDate" [appReactiveDisable]="!enabledControls.startDateChecked">
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                            <mat-hint> {{ 'general.form.LONG_DATE' | translate }}</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="ml-3 input-time" appearance="standard">
                            <mat-label for="startTime">{{ 'campaigns.workingDays.START_TIME' | translate }}</mat-label>
                            <input matInput appReactiveTimeInput type="text" id="startTime" name="startTime"
                                formControlName="startTime" [dateControl]="startControl"
                                [appReactiveDisable]="!enabledControls.startDateChecked">
                            <mat-hint>{{ 'general.form.SHORT_TIME' | translate }}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </mat-tab>
            <!-- End Date -->
            <mat-tab [label]="texts.END_DATE">
                <div class="form-wrapper w-100 d-flex flex-wrap mt-3">
                    <h3 class="form-subtitle form-inputs">{{ 'campaigns.workingDays.END_TITLE' | translate }}</h3>
                    <p class="alert alert-warning">{{ 'campaigns.workingDays.mass_edit_dialog.alerts.END_TASK_CHANGE' |
                        translate }}</p>
                    <mat-checkbox color="primary" formControlName="endDateChecked">{{
                        'campaigns.workingDays.mass_edit_dialog.checkbox.END_DATE' | translate }}</mat-checkbox>
                    <div>
                        <mat-form-field class="mr-3 input-date" appearance="standard">
                            <mat-label for="endDate">{{ 'campaigns.workingDays.END_DATE' | translate }}</mat-label>
                            <input matInput id="endDate" name="endDate" [matDatepicker]="endDate"
                                formControlName="endDate" [appReactiveDisable]="!enabledControls.endDateChecked">
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                            <mat-hint> {{ 'general.form.LONG_DATE' | translate }}</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="ml-3 input-time" appearance="standard">
                            <mat-label for="endTime">{{ 'campaigns.workingDays.END_TIME' | translate }}</mat-label>
                            <input matInput appReactiveTimeInput type="text" id="endTime" name="endTime"
                                formControlName="endTime" [dateControl]="endControl"
                                [appReactiveDisable]="!enabledControls.endDateChecked">
                            <mat-hint>{{ 'general.form.SHORT_TIME' | translate }}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </mat-tab>
            <!-- Break -->
            <mat-tab [label]="texts.BREAK">
                <div class="section">
                    <div class="form-wrapper w-100 d-flex flex-wrap mt-3">
                        <h3 class="form-subtitle form-inputs">{{
                            'campaigns.workingDays.mass_edit_dialog.START_END_BREAK' | translate }}</h3>
                        <p class="alert alert-warning">{{ 'campaigns.workingDays.mass_edit_dialog.alerts.REPLACE_BREAKS'
                            | translate }}</p>
                        <mat-checkbox color="primary" formControlName="breakChecked">{{
                            'campaigns.workingDays.mass_edit_dialog.checkbox.BREAK' | translate }}</mat-checkbox>
                        <div>
                            <mat-form-field class="mr-3 input-date" appearance="standard">
                                <mat-label for="startDateBreak">{{ 'campaigns.workingDays.BREAK_START_DATE' |
                                    translate }}</mat-label>
                                <input matInput id="startDateBreak" name="startDateBreak"
                                    [matDatepicker]="startDateBreak" formControlName="startDateBreak"
                                    [appReactiveDisable]="!enabledControls.breakChecked">
                                <mat-datepicker-toggle matSuffix [for]="startDateBreak"></mat-datepicker-toggle>
                                <mat-datepicker #startDateBreak></mat-datepicker>
                                <mat-hint> {{ 'general.form.LONG_DATE' | translate }}</mat-hint>
                            </mat-form-field>
                            <mat-form-field class="ml-3 input-time" appearance="standard">
                                <mat-label for="startTimeBreak">{{ 'campaigns.workingDays.BREAK_START_TIME' |
                                    translate }}</mat-label>
                                <input matInput appReactiveTimeInput type="text" id="startTimeBreak"
                                    name="startTimeBreak" formControlName="startTimeBreak"
                                    [dateControl]="breakStartControl"
                                    [appReactiveDisable]="!enabledControls.breakChecked">
                                <mat-hint>{{ 'general.form.SHORT_TIME' | translate }}</mat-hint>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="mr-3 input-date" appearance="standard">
                                <mat-label for="endDateBreak">{{ 'campaigns.workingDays.BREAK_END_DATE' | translate }}
                                </mat-label>
                                <input matInput id="endDateBreak" name="endDateBreak" [matDatepicker]="endDateBreak"
                                    formControlName="endDateBreak" [appReactiveDisable]="!enabledControls.breakChecked">
                                <mat-datepicker-toggle matSuffix [for]="endDateBreak"></mat-datepicker-toggle>
                                <mat-datepicker #endDateBreak></mat-datepicker>
                                <mat-hint> {{ 'general.form.LONG_DATE' | translate }}</mat-hint>
                            </mat-form-field>
                            <mat-form-field class="ml-3 input-time" appearance="standard">
                                <mat-label for="endTimeBreak">{{ 'campaigns.workingDays.BREAK_END_TIME' | translate }}
                                </mat-label>
                                <input matInput appReactiveTimeInput type="text" id="endTimeBreak" name="endTimeBreak"
                                    formControlName="endTimeBreak" [dateControl]="breakEndControl"
                                    [appReactiveDisable]="!enabledControls.breakChecked">
                                <mat-hint>{{ 'general.form.SHORT_TIME' | translate }}</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>
</div>

