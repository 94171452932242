<section class="process" *ngFor="let process of processes | keyvalue">
    <h3 class="h3">
        <img class="icon" [src]="images[process.key]"/>
        <span class="text">{{ 'batchs.processes.' + process.key.toUpperCase() | translate }}</span>
    </h3>
    <section class="record" *ngFor="let record of process.value">
        <ng-container *ngFor="let status of BATCH_UNIQUE_STATUS | keyvalue">
            <p *ngIf="category === BATCH_CATEGORY.UNIQUE && record.status === status.value">
                {{ 'batchs.messages.' + status.key | translate : ({ date: record.datetime | date : 'dd/MM/yyyy' : 'GMT', time: record.datetime | date : 'HH:mm' : 'GMT'}) }}:
            </p>
        </ng-container>
        <ul class="list">
            <li class="item" *ngIf="record.status === BATCH_UNIQUE_STATUS.PALLETIZED">{{ 'batchs.SITE' | translate }}: <strong>{{ $any(batch).site.name }}</strong></li>
            <li class="item" *ngIf="record.status === BATCH_UNIQUE_STATUS.PALLETIZED">{{ 'batchs.ZONE' | translate }}: <strong>{{ $any(batch).zoneName || '-' }}</strong></li>
            <li class="item" *ngIf="record.status === BATCH_UNIQUE_STATUS.PALLETIZED">{{ 'batchs.WEIGHT' | translate }}: <strong>{{ $any(batch).amount | number : '1.0-2' }} {{ ('general.units.ABS_' + $any(batch).type.toUpperCase()) | translate}}</strong></li>
            <li class="item" *ngIf="record.status === BATCH_UNIQUE_STATUS.STORED">{{ 'batchs.TEMPERATURE' | translate }}: <strong>{{ record.temperature ? record.temperature : '-' }}ºC</strong></li>
            <li class="item" *ngIf="record.status === BATCH_UNIQUE_STATUS.STORED">{{ 'batchs.STORAGE' | translate }}: <strong>{{ record.storage ? record.storage : '-' }}</strong></li>
            <li class="item" *ngIf="record.status === BATCH_UNIQUE_STATUS.DISPATCHED">{{ 'batchs.DISPATCH_NOTE' | translate }}: <strong>{{ record.dispatchNoteCode}}</strong></li>
            <li class="item" *ngIf="category === BATCH_CATEGORY.UNIQUE">{{ 'batchs.DEVICE' | translate }}: <strong>{{ record.device?.name ? record.device.name : '-' }}</strong></li>
        </ul>
    </section>
    <ng-container *ngFor="let status of BATCH_MIXED_STATUS | keyvalue">
        <ng-container *ngIf="batch.status === status.value">
            <p [innerHTML]="'batchs.messages.' + status.key | translate"></p>
            <p class="alert alert-warning" [innerHTML]="'batchs.messages.' + status.key + '_WARNING' | translate"></p>
        </ng-container>
    </ng-container>
</section>

<div class="buttons" [ngClass]="{ 'space-layout': category === BATCH_CATEGORY.MIXED }">
    <button mat-flat-button color="primary" class="btn" type="button" (click)="navigateToPreviousLink()">
        {{ 'general.actions.BACK' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" (click)="downloadBatchReport()" *ngIf="category === BATCH_CATEGORY.MIXED && batch.status !== BATCH_MIXED_STATUS.OPEN">
        <mat-icon>description</mat-icon>
        {{ 'general.menu.REPORT' | translate}}
    </button>
    <button mat-flat-button color="primary" class="btn" type="button"
        *ngIf="category === BATCH_CATEGORY.MIXED && batch.status !== BATCH_MIXED_STATUS.SAGE" [routerLink]="editLink"
        [queryParams]="{ section: 'basic' }">
        <mat-icon left >edit</mat-icon>
        {{ 'general.actions.EDIT_BATCH' | translate }}
    </button><button mat-flat-button color="warn" class="btn" type="button" (click)="displayDeleteBatchDialog()"
        *ngIf="checkCanDeleteBatch(batch)">
        <mat-icon>description</mat-icon>
        {{ 'general.menu.DELETE' | translate}}
    </button>
</div>