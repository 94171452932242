import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { PackagingType, PackagingUnit } from '@routes/packagings/packagings.constants';
import { PackagingService } from '@routes/packagings/packaging.service';
import { IPackagingReceived, IPackagingSent } from '@routes/packagings/packaging.model';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { ABS_BASE_ROUTE, PACKAGINGS_ROUTES, SECTIONS } from '@shared/constants/routes';
import { FORM_MODES } from '@shared/constants/forms';

@Component({
  selector: 'app-packagings-edit',
  templateUrl: './packagings-edit.component.html',
  styleUrls: ['./packagings-edit.component.scss']
})
export class PackagingsEditComponent implements OnInit  {
  
  PackagingUnit = PackagingUnit;
  PackagingType = PackagingType;
  FormMode = FORM_MODES;

  loading = false;
  mainRoute = [ABS_BASE_ROUTE, SECTIONS.production, PACKAGINGS_ROUTES.parent];
  packaging: IPackagingReceived;
  form: FormGroup;
  mode: FORM_MODES;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbarService: CustomSnackbarService,
    private packagingService: PackagingService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      description: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      unit: new FormControl(null, Validators.required),
      amount: new FormControl(null, [Validators.required, Validators.min(0.01)]),
      sageReference: new FormControl(null, Validators.required),
      traceCode: new FormControl({value: null, disabled: true})
    });
    this.setMode();
  }

  setMode = () => {
    const packaging = this.activatedRoute.snapshot.data.packaging;
    this.mode = this.activatedRoute.snapshot.data.mode;

    this.mode === FORM_MODES.detail
      ? this.form.disable()
      : this.form.enable();

    if (this.mode !== FORM_MODES.add) {
      this.form.patchValue(packaging);
      this.packaging = packaging;
    }
    this.form.get('traceCode').disable();
  }

  submitForm = () => {
  this.loading = true;

  const packaging: IPackagingSent = { ...this.form.value };

  const fn = this.mode === FORM_MODES.add || this.mode === FORM_MODES.clone
    ? this.packagingService.addPackaging(packaging)
    : this.packagingService.editPackaging(this.packaging._id, packaging);

  fn
    .then(() => this.router.navigate(this.mainRoute))
    .catch(error => this.snackbarService.error(error.error.message))
    .finally(() => {
      this.loading = false;
    });
}

}
