import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignService } from '@routes/campaigns/campaign.service';

import { INavTabLink } from '@shared/interfaces/nav-tabs';
import { ABS_BASE_ROUTE, SECTIONS, CAMPAIGNS_ROUTES, CAMPAIGNS_DETAIL_ROUTES } from '@shared/constants/routes';

@Component({
  selector: 'app-campaigns-detail',
  templateUrl: './campaigns-detail.component.html',
  styleUrls: ['./campaigns-detail.component.scss']
})
export class CampaignsDetailComponent implements OnInit, OnDestroy {

  campaign: Campaign;
  links: INavTabLink[];
  commonRoute: string[];
  activeLink: INavTabLink;

  constructor(
    private plannerService: CampaignPlannerService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    public campaignService: CampaignService,
  ) { }

  @HostListener('window:popstate', ['$event'])
  onPopState(event): void {
    if (this.commonRoute.join('/') === this.location.path()) this.location.back();
    this.findActiveRoute();
   }

   ngOnDestroy(): void {
     this.plannerService.clean();
   }

  ngOnInit(): void {
    this.plannerService.setCampaignBasic(this.activatedRoute.snapshot.data.campaign);
    this.campaign = this.plannerService.campaign;

    this.commonRoute = [
      ABS_BASE_ROUTE,
      SECTIONS.production,
      CAMPAIGNS_ROUTES.parent,
      CAMPAIGNS_ROUTES.detail,
      this.campaign.id
    ];

    this.links = [
      {
        name: 'basic',
        text: this.translateService.instant('campaigns.detail.TAB_INFORMATION'),
        route: [...this.commonRoute, CAMPAIGNS_DETAIL_ROUTES.basic]
      },
      {
        name: 'sites',
        text: this.translateService.instant('campaigns.detail.TAB_SITES_ZONES'),
        route: [...this.commonRoute, CAMPAIGNS_DETAIL_ROUTES.sites]
      },
      {
        name: 'workers',
        text: this.translateService.instant('campaigns.detail.TAB_WORKERS'),
        route: [...this.commonRoute, CAMPAIGNS_DETAIL_ROUTES.workers]
      },
      {
        name: 'working-days',
        text: this.translateService.instant('campaigns.detail.TAB_WORKING_DAYS'),
        route: [...this.commonRoute, CAMPAIGNS_DETAIL_ROUTES.workingDays]
      },
      {
        name: 'tasks',
        text: this.translateService.instant('campaigns.detail.TAB_TASKS'),
        route: [...this.commonRoute, CAMPAIGNS_DETAIL_ROUTES.tasks]
      },
      {
        name: 'reports',
        text: this.translateService.instant('campaigns.detail.TAB_REPORTS'),
        route: [...this.commonRoute, CAMPAIGNS_DETAIL_ROUTES.reports]
      },
      {
        name: 'unique-batches',
        text: this.translateService.instant('campaigns.detail.TAB_UNIQUE_BATCHS'),
        route: [...this.commonRoute, CAMPAIGNS_DETAIL_ROUTES.uniqueBatchs]
      }
    ];

    this.findActiveRoute();

    if (this.commonRoute.join('/') === this.router.url) {
      this.activeLink = this.links.find(l => l.name === 'basic');
      this.router.navigate(this.activeLink.route);
    }
  }

  findActiveRoute = () => this.activeLink = this.links.find(l => this.location.path().match(l.route.join('/')));

}
