import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WorkerReceived } from 'src/app/routes/workers/worker.model';
import { WorkerService } from './worker.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkerResolver implements Resolve<WorkerReceived> {

  constructor(
    private workerService: WorkerService
  ) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Promise<WorkerReceived> {
      const id = activatedRouteSnapshot.params.id;
      return this.workerService.getWorker(id);
  }

}