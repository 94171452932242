import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';

import { GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnText, TableConfiguration } from '@shared/components/generic-table/generic-table.model';
import { WeightUnit } from '@shared/constants/common.constants';
import { IHarvestInterval, IProductionInterval, TaskReceived } from '@shared/models/task.model';
import { HourPipe } from '@shared/pipes/hour.pipe';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { TASK_TYPES_TYPES } from '@shared/constants/task-types';

@Component({
  selector: 'app-campaigns-detail-tasks-intervals-dialog',
  templateUrl: './campaigns-detail-tasks-intervals-dialog.component.html',
  styleUrls: ['./campaigns-detail-tasks-intervals-dialog.component.scss'],
  providers: [DatePipe, HourPipe]
})
export class CampaignsDetailTasksIntervalsDialogComponent implements OnInit {

  CampaignStatus = CampaignStatus;

  campaign: Campaign;
  task: TaskReceived;
  productionIntervals: IProductionInterval[];
  totalBoxes: number;
  isHarvestTask: boolean;
  tableConfig: TableConfiguration<IProductionInterval>;

  cancelButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    type: GenericButtonType.Button,
    color: GenericButtonColor.Warn
  };
  editButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.EDIT',
    type: GenericButtonType.Button,
    color: GenericButtonColor.Primary
  };

  constructor(
    private datePipe: DatePipe,
    private hourPipe: HourPipe,
    public dialogRef: MatDialogRef<CampaignsDetailTasksIntervalsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.campaign = this.data.campaign;
    this.task = this.data.task;
    this.productionIntervals = this.data.task.productionIntervals;
    this.totalBoxes = this.productionIntervals.reduce((total, interval) => total + interval.amount, 0);
    this.isHarvestTask = this.task.taskType.type === TASK_TYPES_TYPES.HARVEST;
    this.initTable();
  }

  initTable = () => {
    this.tableConfig = new TableConfiguration({
      behaviour: GenericTableBehaviour.Local,
      i18nRoot: 'campaigns.tasks.table',
      pagination: {
        page: 1, size: 10, search: '', params: {},
        sort: { field: 'endTimestamp', order: 1 }
      },
      data: new BehaviorSubject({
        data: this.productionIntervals,
        total: this.productionIntervals.length
      }),
      columns: [
        new TableColumnText({
          header: 'TIME',
          name: 'time',
          sorting: 'endTimestamp',
          displayAt: 0,
          value: interval => this.datePipe.transform(interval.endTimestamp, 'HH:mm', 'GMT')
        }),
        new TableColumnText({
          header: 'LENGTH',
          name: 'length',
          sorting: 'totalHours',
          displayAt: 0,
          value: interval => this.hourPipe.transform(interval.totalHours)
        }),
        new TableColumnText({
          header: 'ZONE',
          name: 'zone',
          sorting: 'zoneName',
          displayAt: 0,
          value: interval => interval.zoneName
        }),
        new TableColumnText({
          header: 'CROP',
          name: 'crop',
          sorting: 'crop.name',
          displayAt: 0,
          value: interval => `${interval.crop.name} (${interval.crop.variety})`
        }),
        new TableColumnText({
          header: 'PACKAGING',
          name: 'packaging',
          sorting: 'packaging.description',
          displayAt: 0,
          value: (interval: IHarvestInterval) => interval.packaging?.description
        }),
        new TableColumnText({
          header: 'AMOUNT',
          name: 'amount',
          sorting: 'amount',
          displayAt: 0,
          value: interval => `${interval.amount} ${WeightUnit.Units}`
        })
      ]
    });
    if (this.task.taskType.type === TASK_TYPES_TYPES.WORKABLE) {
      const index = this.tableConfig.columns.findIndex(column => column.name === 'packaging');
      this.tableConfig.columns.splice(index, 1);
    }
  }

  editTask = () => {
    if (this.campaign.status === CampaignStatus.Finished) return;
    this.dialogRef.close({ edit: true, task: this.task });
  } 

}
