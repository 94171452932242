import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { TasksComponent } from './tasks.component';
import { TasksMainComponent } from './tasks-main/tasks-main.component';
import { TasksTableComponent } from './tasks-main/tasks-table/tasks-table.component';
import { TasksEditComponent } from './tasks-edit/tasks-edit.component';
import { TASKS_ROUTES } from '@shared/constants/routes';
import { FORM_STATUS } from '@shared/interfaces/forms';

const routes: Routes = [
  {
    path: '',
    component: TasksComponent,
    children: [
      {
        path: '',
        component: TasksMainComponent,
        pathMatch: 'full'
      },
      {
        path: TASKS_ROUTES.add,
        component: TasksEditComponent,
        data: {
          mode: FORM_STATUS.add
        }
      },
      {
        path: `${TASKS_ROUTES.detail}/:id`,
        component: TasksEditComponent,
        data: {
          mode: FORM_STATUS.detail
        }
      },
      {
        path: `${TASKS_ROUTES.edit}/:id`,
        component: TasksEditComponent,
        data: {
          mode: FORM_STATUS.edit
        }
      }
    ]
  }
];

@NgModule({
  declarations: [
    TasksMainComponent,
    TasksTableComponent,
    TasksEditComponent,
    TasksComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TasksModule { }
