<form [formGroup]="form" (submit)="submitForm()">
    <h2 class="h2">{{ 'productionLines.dialog.' + type.toUpperCase() | translate }}</h2>
    <p class="alert alert-secondary">{{ 'productionLines.dialog.' + type.toUpperCase() + '_TEXT' | translate }}</p>
    <!-- Description -->
    <mat-form-field class="input" appearance="standard">
        <mat-label>{{ 'productionLines.dialog.DESCRIPTION' | translate }}</mat-label>
        <input matInput formControlName="description" type="text" name="description" id="description">
    </mat-form-field>
    <!-- Buttons -->
    <mat-dialog-actions class="d-flex justify-content-between">
        <button mat-flat-button mat-dialog-close color="warn" class="actions-button btn" type="button">
            {{ 'general.actions.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" class="actions-button btn" type="submit" [disabled]="form.invalid || loading">
            {{ (type === PRODUCTION_LINES_DIALOG_TYPES.ADD ? 'general.actions.ADD' : 'general.actions.EDIT') | translate }}
        </button>
    </mat-dialog-actions>
</form>
