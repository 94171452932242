export interface ITYPE {
    value: string, 
    tag: string
}

export const CROP_TYPES: ITYPE[] = [
    { value: 'fruit', tag: 'FRUIT' },
    { value: 'plant', tag: 'PLANT' }
];

export const WORKING_SITE_TYPES: ITYPE[] = [
    ...CROP_TYPES,
    { value: 'both', tag: 'BOTH' }
];

export const CAMPAIGN_TYPES: ITYPE[] = [
    { value: 'active', tag: 'ACTIVE' },
    { value: 'finished', tag: 'FINISHED' }
];

export const WORKER_STATUSES: ITYPE[] = [
    { value: 'active', tag: 'ACTIVE' },
    { value: 'inactive', tag: 'INACTIVE' }
];

export const WORKER_GENDERS: ITYPE[] = [
    { value: 'male', tag: 'MALE' },
    { value: 'female', tag: 'FEMALE' },
    { value: 'unspecified', tag: 'UNSPECIFIED'}
];

export const WORKER_MARITAL_STATUSES: ITYPE[] = [
    { value: 'single', tag: 'SINGLE' },
    { value: 'married', tag: 'MARRIED' },
    { value: 'widowed', tag: 'WIDOWED' },
    { value: 'divorced', tag: 'DIVORCED' },
    { value: 'registeredPartnership', tag: 'REGISTERED_PARTNERSHIP' },
    { value: 'separated', tag: 'SEPARATED' },
    { value: 'unspecified', tag: 'UNSPECIFIED' },
];

export const WORKER_HISTORICS: ITYPE[] = [
    { value: 'campaigns', tag: 'CAMPAIGNS'}, 
    { value: 'positions', tag: 'POSITIONS' },
    { value: 'agencies', tag: 'AGENCIES' },
    { value: 'addresses', tag: 'ADDRESSES' },
    { value: 'sites', tag: 'SITES' },
];

export const CAMPAIGNS_WORKERS_SECTIONS: ITYPE[] = [
    { value: 'search', tag: 'WORKERS_SEARCH' },
    { value: 'included', tag: 'WORKERS_INCLUDED' }
];