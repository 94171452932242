import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkingDaysMassEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/working-days-mass-edit.service';
import { WORKING_DAY_STATUSES } from '@shared/constants/working-day';

@Component({
  selector: 'app-campaigns-detail-working-days-mass-edit-summary',
  templateUrl: './campaigns-detail-working-days-mass-edit-summary.component.html',
  styleUrls: ['./campaigns-detail-working-days-mass-edit-summary.component.scss']
})
export class CampaignsDetailWorkingDaysMassEditSummaryComponent implements OnInit {

  form: FormGroup;
  editDevice: string;
  editSite: string;

  confirmationForm : FormGroup;
  statuses:any;
  avaliableStatuses: string[];
  summaryDateFormat = 'dd/MM/yyyy HH:mm'

  constructor(
    private massEditService: WorkingDaysMassEditService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({});
    this.massEditService.formChanges().subscribe(form => {
      this.form = form;
    })
    this.massEditService.editSiteChanges().subscribe(site => {
      this.editSite = site;
    })
    this.massEditService.editDeviceChanges().subscribe(device => {
      this.editDevice = device;
    })

    this.confirmationForm = new FormGroup({
      status: new FormControl(""),
      confirmEdit: new FormControl(false, Validators.required)
    })
    this.massEditService.setConfirmationForm(this.confirmationForm);

    this.confirmationForm.valueChanges.subscribe(form => {
      this.massEditService.setConfirmationForm(this.confirmationForm);
    })

    this.statuses = {...WORKING_DAY_STATUSES, EMPTY:""}
    this.avaliableStatuses = [WORKING_DAY_STATUSES.AGREE, WORKING_DAY_STATUSES.PENDING, ""];
  }

}
