<form (submit)="synchronizeSage()">
    <h2 class="title h2">{{ 'batchs.dialog.sage.TITLE' | translate }}</h2>
    <ng-container *ngIf="selectedBatchs.size; else withoutSelection">
        <p>{{ 'batchs.dialog.sage.BODY_WITH_SELECTION' | translate : { length: selectedBatchs.size } }}</p>
    </ng-container>
    <ng-template #withoutSelection>
        <p [innerHTML]="'batchs.dialog.sage.BODY' | translate"></p>
        <div class="alert alert-warning" [innerHTML]="'batchs.dialog.sage.WARNING' | translate"></div>
    </ng-template>
    <mat-dialog-actions class="d-flex justify-content-between">
        <button mat-flat-button mat-dialog-close color="warn" class="actions-button btn">
            {{ 'general.actions.CANCEL' | translate}}
        </button>
        <button mat-flat-button color="primary" class="actions.button btn" type="submit">
            {{ 'general.actions.CONFIRM' | translate }}
        </button>
    </mat-dialog-actions>
</form>
