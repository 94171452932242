import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { User } from '../user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-users-delete-dialog',
  templateUrl: './users-delete-dialog.component.html',
  styleUrls: ['./users-delete-dialog.component.scss']
})
export class UsersDeleteDialogComponent implements OnInit {

  user: User;

  constructor(
    public dialogRef: MatDialogRef<UsersDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.user = this.data.user;
  }

  deleteUser = () => {
    this.userService.deleteUser(this.user._id).then(() => {
      const message = this.translateService.instant('users.message.ACCOUNT_DELETED');
      this.snackbarService.open(SNACKBAR_TYPES.SUCCESS, message, 3000);
      this.dialogRef.close({ updated: true });
    }).catch(error => this.snackbarService.error(error.error.message))
  }

}
