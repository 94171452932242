import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildInfo } from '@shared/models/buildinfo.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { BuildService } from '@shared/services/build.service';
import { saveAs } from 'file-saver';
import { APK_NAME, BUILDS_FORMAT_EXTENSIONS } from '@shared/constants/builds';

@Component({
  selector: 'app-mobile-app-main',
  templateUrl: './mobile-app-main.component.html',
  styleUrls: ['./mobile-app-main.component.scss']
})
export class MobileAppMainComponent implements OnInit {

  appBuild: BuildInfo;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private buildService: BuildService
  ) { }

  ngOnInit(): void {
    this.appBuild = this.activatedRoute.snapshot.data.build;
  }

  download = () => {
    const downloadText = 'mobileApp.downloadText';
    this.snackbarService.loading(this.translateService.instant(`${downloadText}.DOWNLOADING_FILE`));

    this.buildService.downloadApk().then(blob => {
      saveAs(blob, `${APK_NAME}${this.appBuild.version}.${BUILDS_FORMAT_EXTENSIONS.APK}`);
      const message = this.translateService.instant(`${downloadText}.DOWNLOAD_SUCCESSFUL`);
      this.snackbarService.success(message);
    })
    .catch((error) => this.snackbarService.error(`${downloadText}.DOWNLOAD_ERROR`))
  }
}
