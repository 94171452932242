import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnText, TableConfiguration } from '@shared/components/generic-table/generic-table.model';
import { IHarvestInterval, ITaskSent } from '@shared/models/task.model';
import { IWorkingDayComplaintInterval, WorkingDay, WorkingDayComplaint } from '@shared/models/working-day.model';
import { HourPipe } from '@shared/pipes/hour.pipe';
import { WeightUnit } from '@shared/constants/common.constants';
import { ABS_BASE_ROUTE, CAMPAIGNS_DETAIL_ROUTES, CAMPAIGNS_ROUTES, SECTIONS } from '@shared/constants/routes';

@Component({
  selector: 'app-campaigns-detail-working-days-table-complaint-dialog',
  templateUrl: './campaigns-detail-working-days-table-complaint-dialog.component.html',
  styleUrls: ['./campaigns-detail-working-days-table-complaint-dialog.component.scss'],
  providers: [DatePipe, HourPipe]
})
export class CampaignsDetailWorkingDaysTableComplaintDialogComponent implements OnInit {

  tableConfig: TableConfiguration<IWorkingDayComplaintInterval>;
  workingDay: WorkingDay;
  harvestTask: ITaskSent;
  complaint: WorkingDayComplaint;
  totalBoxes: number;
  taskEditLink: string[];
  resolvingRoute = false;

  constructor(
    public dialogRef: MatDialogRef<CampaignsDetailWorkingDaysTableComplaintDialogComponent>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private hourPipe: HourPipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.workingDay = this.data.workingDay;
    this.harvestTask = this.workingDay.pickingTasks[0];
    this.getComplain();
    this.initTable();
    this.generateTaskEditLink();
  }

  getComplain = () => {
    const intervals = this.workingDay.pickingTasks[0].productionIntervals as IHarvestInterval[];
    const complaint = this.workingDay.complaints;
    this.complaint = new WorkingDayComplaint(intervals, complaint);
    this.totalBoxes = this.harvestTask.productionIntervals?.reduce((total, interval) => total + interval.amount, 0);
  }

  generateTaskEditLink = () => this.taskEditLink = [
    ABS_BASE_ROUTE, SECTIONS.production, CAMPAIGNS_ROUTES.parent,
    CAMPAIGNS_ROUTES.detail, this.workingDay.campaignId,
    CAMPAIGNS_DETAIL_ROUTES.workingDays, this.workingDay._id,
    CAMPAIGNS_DETAIL_ROUTES.taskEdit, this.harvestTask._id];

  editHarvestTask = () => {
    this.resolvingRoute = true;
    this.router.navigate(this.taskEditLink, {relativeTo: this.activatedRoute})
      .then(resolved => resolved ? this.dialogRef.close() : this.resolvingRoute = false);
  }

  initTable = () => {
    this.tableConfig = new TableConfiguration({
      behaviour: GenericTableBehaviour.Local,
      i18nRoot: 'campaigns.tasks.table',
      pagination: {
        page: 1, size: 5, search: '', params: {},
        sort: { field: 'endTimestamp', order: 1 }
      },
      data: new BehaviorSubject({
        data: this.complaint.intervals,
        total: this.complaint.intervals.length
      }),
      columns: [
        new TableColumnText({
          header: 'TIME',
          name: 'time',
          sorting: 'endTimestamp',
          displayAt: 640,
          value: interval => this.datePipe.transform(interval.endTimestamp, 'HH:mm', 'GMT')
        }),
        new TableColumnText({
          header: 'LENGTH',
          name: 'length',
          sorting: 'totalHours',
          displayAt: 768,
          value: interval => this.hourPipe.transform(interval.totalHours)
        }),
        new TableColumnText({
          header: 'ZONE',
          name: 'zone',
          sorting: 'zoneName',
          displayAt: 1024,
          value: interval => interval.zoneName
        }),
        new TableColumnText({
          header: 'CROP',
          name: 'crop',
          sorting: 'crop.name',
          displayAt: 1280,
          value: interval => `${interval.crop.name} (${interval.crop.variety})`
        }),
        new TableColumnText({
          header: 'PACKAGING',
          name: 'packaging',
          sorting: 'packaging.description',
          displayAt: 0,
          value: interval => interval.packaging.description
        }),
        new TableColumnText({
          header: 'PREVIOUS_BOXES',
          name: 'previousBoxes',
          sorting: 'amount.previous',
          displayAt: 0,
          value: interval => `${interval.amount.previous} ${WeightUnit.Units}`
        }),
        new TableColumnText({
          header: 'CURRENT_BOXES',
          name: 'currentBoxes',
          sorting: 'amount.current',
          displayAt: 0,
          value: interval => interval.amount.current ? `${interval.amount.current} ${WeightUnit.Units}` : null
        })
      ]
    });
  }

}
