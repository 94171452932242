import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';

import { ICampaign, ICampaignSite } from '@routes/campaigns/campaign.model';
import { ICrop } from '@routes/crops/crop.model';
import { IPackagingReceived } from '@routes/packagings/packaging.model';

import { GenericFiltersService } from '@shared/components/generic-filters/generic-filters-service.service';
import { LayoutDirection } from '@shared/components/generic-filters/generic-filters.constants';
import { FiltersButtonAction, FiltersButtonLink, FiltersConfiguration, FiltersControl, FiltersControlCheckbox, FiltersControlDate, FiltersControlGroupOption, FiltersControlGroupSelect, FiltersControlSelect, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { REPORTS_FILTER_INPUTS, REPORTS_FORMAT_EXTENSIONS } from '@shared/constants/reports';
import { Pagination } from '@shared/models/pagination.model';
import { IWorkingDayDevice, IWorkingDayTaskType } from '@shared/models/working-day.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-generic-report',
  templateUrl: './generic-report.component.html',
  styleUrls: ['./generic-report.component.scss']
})
export class GenericReportComponent<T> implements OnInit, OnDestroy {

  title: string;
  filters: { string: any };
  getReport: (pagination: Pagination) => Promise<T>;
  downloadReport: (pagination: Pagination) => Promise<Blob>;
  filename: string;

  pagination = new Pagination();

  campaign: ICampaign;
  devices: IWorkingDayDevice[];
  sites: ICampaignSite[];
  taskTypes: IWorkingDayTaskType[];
  packagings: IPackagingReceived[];
  crops: ICrop[];

  report: T;
  filtersConfig: FiltersConfiguration;

  filtersValue: object;

  loadingWarning = true;
  exportingExcel = false;
  loadingReport = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private filterService: GenericFiltersService
  ) { }

  ngOnInit(): void {
    this.getDataFromRouter('title');
    this.getDataFromRouter('filters');
    this.getDataFromRouter('filename');
    this.getDataFromResolver('getReport');
    this.getDataFromResolver('downloadReport');
    this.getDataFromResolver('campaign');
    this.getDataFromResolver('devices');
    this.getDataFromResolver('sites');
    this.getDataFromResolver('taskTypes');
    this.getDataFromResolver('packagings');
    this.getDataFromResolver('crops');
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.filterService.removeFiltersValue();
  }

  setupForm = () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    const filterNames = Object.keys(this.filters);
    const controls = [
      new FiltersControlDate({
        label: 'START_DATE',
        name: REPORTS_FILTER_INPUTS.START_DATE,
        value: this.filters[REPORTS_FILTER_INPUTS.START_DATE],
        modifier: date => date.setHours(0, 0, 0, 0),
        maxDateReferenceName: REPORTS_FILTER_INPUTS.END_DATE
      }),
      new FiltersControlDate({
        label: 'END_DATE',
        name: REPORTS_FILTER_INPUTS.END_DATE,
        value: this.filters[REPORTS_FILTER_INPUTS.END_DATE],
        modifier: date => date.setHours(23, 59, 59, 999),
        minDateReferenceName: REPORTS_FILTER_INPUTS.START_DATE
      })] as FiltersControl[];

    if (filterNames.includes(REPORTS_FILTER_INPUTS.SITE_IDS))
      controls.push(
        new FiltersControlSelect({
          label: 'SITES',
          name: REPORTS_FILTER_INPUTS.SITE_IDS,
          value: [],
          options: new BehaviorSubject(this.sites.map(site => ({ value: site.siteId, display: site.siteName })))
        }));

    if (filterNames.includes(REPORTS_FILTER_INPUTS.DEVICE_IDS))
      controls.push(
        new FiltersControlSelect({
          label: 'DEVICES',
          name: REPORTS_FILTER_INPUTS.DEVICE_IDS,
          value: [],
          options: new BehaviorSubject(this.devices.map(device => ({ value: device._id, display: device.name })))
        }));

    if (filterNames.includes(REPORTS_FILTER_INPUTS.TASK_TYPE_IDS))
      controls.push(
        new FiltersControlSelect({
          label: 'TASK_TYPES',
          name: REPORTS_FILTER_INPUTS.TASK_TYPE_IDS,
          value: [],
          options: new BehaviorSubject(this.taskTypes.map(type => ({ value: type._id, display: type.name })))
        }));

    if (filterNames.includes(REPORTS_FILTER_INPUTS.WORKABLE_TASKS))
      controls.push(
        new FiltersControlSelect({
          label: 'TASK_TYPES',
          name: REPORTS_FILTER_INPUTS.TASK_TYPE_IDS,
          value: [],
          options: new BehaviorSubject(this.taskTypes.map(type => ({ value: type._id, display: type.name })))
        }));

    if (filterNames.includes(REPORTS_FILTER_INPUTS.PACKAGING_IDS))
      controls.push(
        new FiltersControlSelect({
          label: 'PACKAGINGS',
          name: REPORTS_FILTER_INPUTS.PACKAGING_IDS,
          value: [],
          options: new BehaviorSubject(this.packagings.map(packaging => ({ value: packaging._id, display: packaging.description })))
        }));

    if (filterNames.includes(REPORTS_FILTER_INPUTS.CROP_IDS))
      controls.push(
        new FiltersControlSelect({
          label: 'CROPS',
          name: REPORTS_FILTER_INPUTS.CROP_IDS,
          value: [],
          options: new BehaviorSubject(this.crops.map(crop => ({ value: crop._id, display: `${crop.name} (${crop.variety})` })))
        }));

    if (filterNames.includes(REPORTS_FILTER_INPUTS.ZONE_IDS))
      controls.push(
        new FiltersControlGroupSelect({
          label: 'ZONES',
          name: REPORTS_FILTER_INPUTS.ZONE_IDS,
          value: [],
          options: new BehaviorSubject(this.loadZones())
        }));

    if (filterNames.includes(REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS) && this.campaign.agreeWorkdays)
      controls.push(
        new FiltersControlCheckbox({
          label: 'CONFIRMED_WORKING_DAYS',
          name: REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS,
          value: this.filters[REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]
        }));

    this.filtersConfig = new FiltersConfiguration({
      search: false,
      controls,
      layout: LayoutDirection.Vertical,
      buttons: [
        new FiltersButtonLink({
          text: 'BACK',
          link: ['..']
        }),
        new FiltersButtonAction({
          text: 'GENERATE_REPORT',
          icon: 'report',
          action: this.retrieveReport,
          disabled: () => this.loadingReport || this.filtersValue[REPORTS_FILTER_INPUTS.START_DATE] > this.filtersValue[REPORTS_FILTER_INPUTS.END_DATE]
        }),
        new FiltersButtonAction({
          text: 'EXPORT_EXCEL',
          icon: 'download',
          action: this.exportExcel,
          disabled: () => !this.report
        }),

      ]
    });
  }

  retrieveReport = () => {
    this.report = null;
    this.loadingReport = true;
    this.loadingWarning = false;
    this.snackbarService.loading(this.translateService.instant('reports.messages.LOADING_REPORT'));

    this.pagination.params = { ...this.filtersValue, campaignId: this.campaign._id, format: null };
    this.pagination.params = Object.assign({}, ...Object.entries(this.pagination.params).filter(([key, value]) => Array.isArray(value) ? value.length : true).map(([key, value]) => ({ [key]: value })));
    if(this.pagination.params.zoneIds) this.pagination.params.zoneIds = this.pagination.params.zoneIds.map(zone => JSON.stringify(zone))
    this.getReport(this.pagination).then(report => {
      this.report = report;
      this.snackbarService.snackBar.dismiss();
    })
      .catch(() => this.snackbarService.error(this.translateService.instant('reports.messages.ERROR_LOADING')))
      .finally(() => this.loadingReport = false);
  }

  exportExcel = () => {
    this.exportingExcel = true;
    this.snackbarService.loading(this.translateService.instant('reports.messages.GENERATING_EXCEL_FILE'));

    this.pagination.params = { ...this.pagination.params, format: REPORTS_FORMAT_EXTENSIONS.XLSX };
    this.downloadReport(this.pagination).then(blob => {
      saveAs(blob, this.translateService.instant(this.filename));
      this.snackbarService.snackBar.dismiss();
    })
      .catch(() => this.snackbarService.error(this.translateService.instant('reports.messages.ERROR_EXPORTING')))
      .finally(() => this.exportingExcel = false);
  }

  updateFilters = (event: FiltersEvent) => {
    this.filtersValue = event.params;
    this.report = null;
    this.loadingReport = false;
    this.loadingWarning = true;
  }

  loadZones = () => this.sites.map(site => {
      const zones = site.zones.map(zone =>  {
        return {value: {siteId: site.siteId, zoneSequentialId: zone.sequentialId}, display: zone.name}})
      return {name: site.siteName, options: zones}
    })

  getDataFromRouter = (key: string) => this[key] = this.activatedRoute.snapshot.data[key];
  getDataFromResolver = (key: string) => this[key] = this.activatedRoute.snapshot.data.data[key];
}
