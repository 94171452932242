import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BatchsEditUniqueDialogComponent } from '@routes/batchs/batchs-edit/batchs-edit-unique-batchs/batch-edit-unique-modal/batch-edit-unique-dialog.component';
import { createBatchFormGroup } from '@routes/batchs/batchs-edit/batchs-edit.utils';
import { IBatchMixedBatch, IBatchMixedBatchSent } from '@routes/batchs/batchs.model';
import { BATCH_MIXED_STATUS } from '@routes/batchs/batchs.constants';

import { ExternalBatchPipe } from '@shared/pipes/externalBatch.pipe';
import { GenericButtonColor, GenericButtonIcon, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableColumnStyle } from '@shared/components/generic-table/generic-table.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { FORM_MODES } from '@shared/constants/forms';

@Component({
  selector: 'app-batchs-edit-unique-batchs',
  templateUrl: './batchs-edit-unique-batchs.component.html',
  styleUrls: ['./batchs-edit-unique-batchs.component.scss'],
  providers: [ExternalBatchPipe]
})
export class BatchsEditUniqueBatchsComponent implements OnInit {

  BATCH_MIXED_STATUS = BATCH_MIXED_STATUS;

  tableConfig: TableConfiguration<IBatchMixedBatch>;
  form: FormArray;
  status: BATCH_MIXED_STATUS;

  addButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.ADD',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button,
    icon: GenericButtonIcon.Add
  };

  constructor(
    private dialog: MatDialog,
    private externalBatchPipe: ExternalBatchPipe,
    private parentFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.status = this.parentFormGroup.control.get('basic.status').value;
    this.form = this.parentFormGroup.control.get('batchs') as FormArray;
    this.initTable();

    this.form.valueChanges.subscribe(batchs =>
      this.tableConfig.data.next({ data: batchs, total: batchs.length }));
  }

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({
      data: this.form.value,
      total: this.form.value.length
    }),
    pagination: {
      page: 1, size: 10, search: null,
      sort: { field: 'code', order: 1 }
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: batch => batch.code,
      }),
      new TableColumnText({
        header: 'CROP',
        name: 'crop',
        sorting: 'crop.name',
        displayAt: 860,
        value: batch => this.externalBatchPipe.transform(batch, '{0} ({1})', 'crop.name', 'crop.variety')
      }),
      new TableColumnText({
        header: 'PACKAGING',
        name: 'packaging',
        sorting: 'packaging.description',
        displayAt: 1024,
        value: batch => this.externalBatchPipe.transform(batch, '{0}', 'packaging.description')
      }),
      new TableColumnText({
        header: 'BOXES',
        name: 'boxes',
        sorting: 'boxes',
        displayAt: 520,
        value: batch => batch.boxes,
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            action: batch => this.editBatchDialog(batch)
          }),
          new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: batch => this.deleteBatchDialog(batch)
          })
        ]
      })
    ]
  });

  addBatchDialog = () => this.dialog.open(BatchsEditUniqueDialogComponent, {
    data: { batchs: this.form.value, mode: FORM_MODES.add },
    width: '420px'
  }).afterClosed().pipe(filter(batch => batch)).subscribe(batch => {
    const formGroup = createBatchFormGroup(batch);
    this.form.push(formGroup);
  });

  editBatchDialog = (batch: IBatchMixedBatch) => {
    const index = this.form.value.findIndex((b: IBatchMixedBatchSent) => b.code === batch.code);

    this.dialog.open(BatchsEditUniqueDialogComponent, {
      data: { batchs: this.form.value, batch, mode: FORM_MODES.edit },
      width: '420px'
    }).afterClosed()
      .pipe(filter(batch => batch))
      .subscribe(batch => this.form.at(index).reset(batch));
  }

  deleteBatchDialog = (batch: IBatchMixedBatch) => {
    const index = this.form.value.findIndex((b: IBatchMixedBatchSent) => b.code === batch.code);
    
    this.dialog.open(BatchsEditUniqueDialogComponent, {
      data: { mode: FORM_MODES.delete },
      width: '420px'
    }).afterClosed()
      .pipe(filter(remove => remove))
      .subscribe(() => this.form.removeAt(index));
  }

}
