import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ItemReceived, ItemsPaginated } from '@routes/items/items.model';

import { FileMode } from '@shared/constants/files';
import { IDataPaginated, Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import { UploadData } from '@shared/interfaces/upload';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  private getItemsUrl = () => `${this.apiService.getBaseUrl()}/items`;
  private getItemUrl = (_id: string) => `${this.getItemsUrl()}/${_id}`;
  private getItemsFileUrl = (mode: string) => `${this.getItemsUrl()}/file/${mode}`;

  getItems = (pagination?: Pagination): Promise<IDataPaginated<ItemReceived>> =>
    lastValueFrom(this.http.get<IDataPaginated<ItemReceived>>(this.getItemsUrl(), {params: pagination?.loadHttpParams()}));

  getItem = (_id: string): Promise<ItemReceived> =>
    lastValueFrom(this.http.get<ItemReceived>(this.getItemUrl(_id)));

  uploadItemsFile = (data: FormData, mode: FileMode): Promise<UploadData> =>
    lastValueFrom(this.http.post<any>(this.getItemsFileUrl(mode), data));

}
