<div class="wrapper">
    <app-generic-filters class="filters" [filtersConfig]="filtersConfig" (filtersEvent)="updateFilters($event)" (validationEvent)="updateSubmitButton($event)">
    </app-generic-filters>
    <div class="report">
        <app-generic-table
            [tableConfig]="tableConfig"
            (selectionEvent)="updateSelection($event)"
        ></app-generic-table>
    </div>
</div>
<div class="btn-container buttons">
    <button mat-flat-button color="primary" class="btn" type="button" routerLink=".." queryParamsHandling="preserve">
        {{ 'general.actions.CANCEL' | translate }}
    </button>
    <button mat-flat-button matStepperNext class="actions-button btn button" type="button" color="primary" [disabled]="selectedWorkingDays.size === 0" >
        <span class="btn-text">{{ 'campaigns.workingDays.mass_edit_dialog.steps.NEXT' | translate }}</span>
    </button>
</div>
