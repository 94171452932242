import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  @Input() text: string;
  @Input() color: string;
  @Input() button: string;
  @Input() formats: string[];

  @Output() uploadedFile: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('input') input: ElementRef;

  file: File;
  error: string;

  constructor(
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.text = this.translateService.instant('general.upload.UPLOAD_FILE');

    if (this.formats.length) {
      this.text = `${this.text} (${this.formats.map(f => `.${f}`).join(', ')})`;
    }
  }

  checkFile = (file?: File): void => {
    this.file = file ? file : this.input.nativeElement.files[0];

    if (this.formats.length && !this.formatIsSupported()) {
      this.error = this.translateService.instant('general.upload.UNSUPPORTED_FORMAT', {
        formats: this.formats.join(', ')
      });
      this.uploadedFile.emit(null);

      return;
    }
    this.error = null;
    this.uploadedFile.emit(this.file);
  };

  formatIsSupported = (): boolean => {
    const chunks = this.file.name.split('.');
    const format = chunks[chunks.length - 1];
    const index = this.formats.findIndex(f => f === format);
    return index !== -1;
  }

  triggerFileExplorer = () => (<HTMLInputElement>this.input.nativeElement).click();

}
