import { Pipe, PipeTransform } from '@angular/core';
import { BATCH_REG_EXP } from '@shared/constants/regexp';

@Pipe({
  name: 'externalBatch',
  pure: false
})
export class ExternalBatchPipe implements PipeTransform {
  transform: any = (value: any, format: string, ...fields: string[]) => {
    if (!BATCH_REG_EXP.UNIQUE.test(value.code)) return '- EXT -';
    
    try {
      fields.forEach((field, idx) => {
        format = format.replace('{' + idx + '}', field.split('.').reduce((val, f) => val[f], value))
      })
      return format;
    } catch (error) {
      return '-';
    }
  }
}
