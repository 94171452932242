<h3 class="h2 title">{{ translation.TITLE }}</h3>
<p class="alert alert-warning" [innerHTML]="translation.IMPLICATIONS"></p>
<ul class="list">
    <li *ngFor="let text of translation.TEXTS">{{ text }}</li>
</ul>
<p class="alert alert-success" [innerHTML]="translation.FOOTNOTE"></p>
<mat-checkbox class="checkbox" color="primary" [(ngModel)]="confirmation">
    {{ translation.CONFIRMATION }}
</mat-checkbox>
<div class="buttons">
    <app-generic-button
        [config]="cancelButtonConfig" (click)="close()" [disabled]="loading"
    ></app-generic-button>
    <app-generic-button
        [config]="confirmButtonConfig" (click)="confirm()" [disabled]="!confirmation || loading"
    ></app-generic-button>
</div>
