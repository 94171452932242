import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { Campaign } from '@routes/campaigns/campaign.model';
import { TaskService } from '@routes/tasks/task.service';
import { CampaignsDetailTasksIntervalsDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-intervals-dialog/campaigns-detail-tasks-intervals-dialog.component';

import { GenericPagination, Pagination } from '@shared/models/pagination.model';
import { TaskReceived } from '@shared/models/task.model';
import { DeleteDialogComponent } from '@shared/widgets/delete-dialog/delete-dialog.component';
import { WorkingDayService } from '@shared/services/working-day.service';
import { ABS_BASE_ROUTE, CAMPAIGNS_DETAIL_ROUTES, CAMPAIGNS_ROUTES, SECTIONS } from '@shared/constants/routes';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { FiltersConfiguration, FiltersControlDate, FiltersControlOption, FiltersControlSelect } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TaskWrapper } from '@shared/constants/tasks';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';

@Component({
  selector: 'app-campaigns-detail-tasks-table',
  templateUrl: './campaigns-detail-tasks-table.component.html',
  styleUrls: ['./campaigns-detail-tasks-table.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailTasksTableComponent implements OnInit {

  private pagination = new GenericPagination({ sortField: 'name' });

  tableConfig: TableConfiguration<TaskReceived>;
  filtersConfig: FiltersConfiguration;

  campaign: Campaign;
  tasks: TaskReceived[];

  constructor(
    private plannerService: CampaignPlannerService,
    private workingDayService: WorkingDayService,
    private taskService: TaskService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.campaign = this.plannerService.campaign;
    this.getTaskTypes();
    this.initFilters();
    this.initTable();
    this.retrieveCampaignData();
  }

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [
      new FiltersControlDate({
        label: 'START_DATE',
        name: 'startDate',
        value: new Date(),
        modifier: date => date.setHours(0, 0, 0, 0)
      }),
      new FiltersControlDate({
        label: 'END_DATE',
        name: 'endDate',
        value: null,
        modifier: date => date.setHours(23, 59, 59, 999)
      }),
      new FiltersControlSelect({
        label: 'TASK_TYPES',
        name: 'taskTypeIds',
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'DEVICES',
        name: 'deviceIds',
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'SITES',
        name: 'siteIds',
        value: [],
        options: new BehaviorSubject([])
      })
    ],
    buttons: []
  });

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'campaigns.tasks.table',
    pagination: {
      page: 1, size: 10, params: {}, search: '',
      sort: { field: 'startTimestamp', order: -1 }
    },
    data: new BehaviorSubject({ data: null, total: 0 }),
    columns: [
      new TableColumnText({
        header: 'WORKER',
        name: 'worker',
        sorting: 'worker.name',
        displayAt: 0,
        value: task => `${task.worker.name} ${task.worker.surname}`
      }),
      new TableColumnText({
        header: 'TASK_TYPE',
        name: 'taskType',
        sorting: 'taskType.name',
        displayAt: 640,
        value: task => task.taskType.name
      }),
      new TableColumnText({
        header: 'START',
        name: 'startTimestamp',
        sorting: 'startTimestamp',
        displayAt: 960,
        value: task => this.datePipe.transform(task.startTimestamp, 'dd/MM (HH:mm)', 'GMT')
      }),
      new TableColumnText({
        header: 'END',
        name: 'endTimestamp',
        sorting: 'endTimestamp',
        displayAt: 960,
        value: task => this.datePipe.transform(task.endTimestamp, 'dd/MM (HH:mm)', 'GMT')
      }),
      new TableColumnText({
        header: 'TOTAL_HOURS',
        name: 'totalHours',
        sorting: 'totalHours',
        displayAt: 1280,
        value: task => task.totalHours
      }),
      new TableColumnText({
        header: 'DEVICE',
        name: 'device',
        sorting: 'device.name',
        displayAt: 1024,
        value: task => task.device.name
      }),
      new TableColumnText({
        header: 'WORKING_SITE',
        name: 'site',
        sorting: 'site.name',
        displayAt: 1024,
        value: task => task.site.name
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'PRODUCTION_INTERVALS',
            icon: GenericMenuOptionIcon.NumberedList,
            action: task => this.displayIntervalsDialog(task),
            disabled: task => !task.productionIntervals?.length
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            action: task => this.navigateToEdit(task),
            disabled: () => this.campaign.status === CampaignStatus.Finished
          }),
          new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: task => this.displayDeleteTaskDialog(task),
            disabled: () => this.campaign.status === CampaignStatus.Finished
          })
        ]
      })
    ]
  });

  getTasks = async (pagination: Pagination) => {
    const response = await this.workingDayService.getTasks(this.campaign.id, pagination);
    this.tableConfig.data.next({ data: response.tasks, total: response.total });
  }

  getTaskTypes = async () => this.taskService.getTasks(this.pagination)
    .then(response => this.filtersConfig.updateControlOptions('taskTypeIds', response.tasksTypes.map(taskType => ({
      value: taskType._id,
      display: taskType.name
    }))));

  retrieveCampaignData = async () => {
    this.filtersConfig.updateControlOptions('deviceIds', this.plannerService.devices
      .map((device): FiltersControlOption => ({display: device.name, value: device._id})));
    this.filtersConfig.updateControlOptions('siteIds', this.campaign.sites
      .map((site): FiltersControlOption => ({display: site.siteName, value: site.siteId})));
  }
  
  displayDeleteTaskDialog = (task: TaskReceived) => {
    const title = 'campaigns.tasks.delete_task_dialog.TITLE';
    const msg = this.translateService.instant('campaigns.tasks.delete_task_dialog.BODY', { worker: task.worker });

    this.dialog.open(DeleteDialogComponent, {
      data: { title, msg },
      width: '520px'
    }).afterClosed().pipe(filter(data => data && data.confirmDelete)).subscribe(async () => {
      const workingDay = await this.workingDayService.getWorkingDay(this.campaign.id, task.workingDayId);
      const taskType = await this.taskService.getTask(task.taskTypeId);
      const wrapper = TaskWrapper[taskType.type];
      workingDay[wrapper].find((t: TaskReceived) => task._id === t._id).deleted = true;   
      
      await this.workingDayService.editWorkingDay(this.campaign.id, workingDay._id, workingDay);
      await this.getTasks(new Pagination(this.tableConfig.pagination));
    });
  }

  displayIntervalsDialog = (task: TaskReceived) => this.dialog.open(CampaignsDetailTasksIntervalsDialogComponent, {
    data: { task, campaign: this.campaign }, width: '1024px'
  }).afterClosed()
    .pipe(filter(data => data && data.edit && data.task._id))
    .subscribe(data => this.navigateToEdit(data.task));

  navigateToEdit = (task: TaskReceived) => this.router.navigate([
    ABS_BASE_ROUTE, SECTIONS.production,
    CAMPAIGNS_ROUTES.parent, CAMPAIGNS_ROUTES.detail,
    this.campaign.id, CAMPAIGNS_DETAIL_ROUTES.workingDays,
    task.workingDayId, CAMPAIGNS_DETAIL_ROUTES.taskEdit,task._id]);

}