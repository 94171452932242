import { Component, Input, OnInit } from '@angular/core';
import { IBatchUniqueReceived } from '@routes/batchs/batchs.model';

@Component({
  selector: 'app-batchs-detail-summary',
  templateUrl: './batchs-detail-summary.component.html',
  styleUrls: ['./batchs-detail-summary.component.scss']
})
export class BatchsDetailSummaryComponent implements OnInit {

  @Input() batch: IBatchUniqueReceived;

  constructor() {}

  ngOnInit(): void {}

}
