import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { IProductionLineReceived } from '@routes/production-lines/production-lines.model';
import { ProductionLinesService } from '@routes/production-lines/production-lines.service';
import { ProductionLinesDialogComponent } from '@routes/production-lines/production-lines-modal/production-lines-dialog.component';
import { PRODUCTION_LINES_DIALOG_TYPES } from '@routes/production-lines/production-lines.constants';

import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableColumnAlign, GenericTableColumnStyle } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { Pagination } from '@shared/models/pagination.model';
import { FiltersButtonAction, FiltersConfiguration } from '@shared/components/generic-filters/generic-filters.model';

@Component({
  selector: 'app-production-lines-table',
  templateUrl: './production-lines-table.component.html',
  styleUrls: ['./production-lines-table.component.scss']
})
export class ProductionLinesTableComponent implements OnInit {

  PRODUCTION_LINES_DIALOG_TYPES = PRODUCTION_LINES_DIALOG_TYPES;
  tableConfig: TableConfiguration<IProductionLineReceived>;
  filtersConfig: FiltersConfiguration;

  constructor(
    private productionLinesService: ProductionLinesService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initFilters();
    this.initTable();
  }

  getProductionLines = async (pagination: Pagination) => 
    this.tableConfig.data.next(await this.productionLinesService.getProductionLines(pagination));

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    buttons: [
      new FiltersButtonAction({
        icon: 'add',
        text: 'ADD',
        action: () => this.displayDialog(null, PRODUCTION_LINES_DIALOG_TYPES.ADD)
      })
    ],
    controls: []
  });

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'productionLines.table',
    data: new BehaviorSubject({data: null, total: 0}),
    pagination: {
      page: 1, size: 10, search: '',
      sort: { field: 'description', order: 1 },
      params: {deleted: false}
    },
    columns: [
      new TableColumnText({
        header: 'TRACE_CODE',
        name: 'traceCode',
        sorting: 'traceCode',
        displayAt: 0,
        style: GenericTableColumnStyle.Compact,
        align: GenericTableColumnAlign.Left,
        value: productionLine => productionLine.traceCode
      }),
      new TableColumnText({
        header: 'DESCRIPTION',
        name: 'description',
        sorting: 'description',
        displayAt: 0,
        value: productionLine => productionLine.description
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            action: productionLine => this.displayDialog(productionLine, PRODUCTION_LINES_DIALOG_TYPES.EDIT)
          })
        ]
      })
    ]
  });

  displayDialog = (productionLine: IProductionLineReceived, type: PRODUCTION_LINES_DIALOG_TYPES) => {
    this.dialog.open(ProductionLinesDialogComponent, {
      data: { productionLine, type },
      width: '520px'
    }).afterClosed().subscribe(() => this.getProductionLines(new Pagination(this.tableConfig.pagination)));
  }

}
