<table class="table" mat-table matSort [dataSource]="_elements" (matSortChange)="sort($event)">
    <ng-container *ngFor="let column of _tableConfig.columns">
        <ng-container [matColumnDef]="column.columnDef">
            <ng-container *ngIf="!column.buttons || !column.buttons.length">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.sortPath" [class]="'table-cell ' + column.columnDef">
                    {{ column.header | translate }}
                </th>
            </ng-container>
            <ng-container *ngIf="column.buttons && column.buttons.length">
                <th [class]="'table-cell table-actions ' + column.columnDef" mat-header-cell *matHeaderCellDef>
                    {{ column.header | translate }}
                </th>
            </ng-container>
            <td mat-cell *matCellDef="let element" [class]="'table-cell ' + column.columnDef" [ngClass]="{'table-actions': column.columnDef === 'actions'}">
                <ng-container *ngIf="column.valuePath">
                    {{(column.formatFunction ? column.formatFunction(getProperty(element,column.valuePath)) : getProperty(element,column.valuePath)) | translate}}
                </ng-container>
                <ng-container *ngIf="!column.valuePath && column.template">
                    <ng-container *ngTemplateOutlet="column.template"></ng-container>
                </ng-container>
                <ng-container *ngIf="column.buttons && column.buttons.length">
                    <ng-container *ngFor="let button of column.buttons">
                        <button *ngIf="!button.visibility || button.visibility(element)" #tooltip="matTooltip"
                            [matTooltip]="((button.tooltip || '') | translate)"
                            [title]="((button.tooltip ? '' : (button.title || '')) | translate)"
                            [class]="'action-btn ' + button.buttonClass"
                            (click)="buttonAction(button, element)">
                            <img class="action-img" [src]="button.icon" />
                        </button>
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>
    </ng-container>
    <!-- Row definition -->
    <tr mat-header-row *matHeaderRowDef="_tableConfig.columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let rowToDisplay; columns: _tableConfig.columnsToDisplay"></tr>
</table>

<mat-paginator [length]="_paginationTotal" [pageSize]="pagination.size"
    [pageSizeOptions]="_tableConfig.paginationConfig.pagingSizes" (page)="paginate($event)">
</mat-paginator>