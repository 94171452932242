import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CampaignsDetailWorkingDaysMassEditErrorsComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit-errors/campaigns-detail-working-days-mass-edit-errors.component';
import { WorkingDaysMassEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/working-days-mass-edit.service';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { IWorkingDayMassEdits, WorkingDay } from '@shared/models/working-day.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { WorkingDayService } from '@shared/services/working-day.service';
import { transformDateToUTC } from '@shared/utils/date.utils';

@Component({
  selector: 'app-campaigns-detail-working-days-mass-edit-confirmation',
  templateUrl: './campaigns-detail-working-days-mass-edit-confirmation.component.html',
  styleUrls: ['./campaigns-detail-working-days-mass-edit-confirmation.component.scss']
})
export class CampaignsDetailWorkingDaysMassEditConfirmationComponent implements OnInit {

  confirmationForm: FormGroup;
  form: FormGroup;
  selectedWorkingDays: Map<string, WorkingDay>;
  loading = false;
  
  constructor(
    private massEditService: WorkingDaysMassEditService,
    private workingDayService: WorkingDayService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private plannerService: CampaignPlannerService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.confirmationForm = new FormGroup({});
    this.form = new FormGroup({});
    this.massEditService.confirmationFormChanges().subscribe(form => {
      this.confirmationForm = form;
    })
    this.massEditService.selectedWorkingDaysChanges().subscribe(selected => {
      this.selectedWorkingDays = selected;
    })
    this.massEditService.formChanges().subscribe(form => {
      this.form = form;
    })
  }

  submitEdits = async (confirmation = false) => {
    const workingDayIds = Array.from(this.selectedWorkingDays, ([name]) => (name)).join();
    const edits: IWorkingDayMassEdits = {
      siteId: this.form.value.site,
      deviceId: this.form.value.device,
    }

    if(this.confirmationForm.value.status !== "") edits.status = this.confirmationForm.value.status
    if(this.form.value.startDate) edits.startTimestamp = transformDateToUTC(this.form.value.startDate);
    if(this.form.value.endDate) edits.endTimestamp = transformDateToUTC(this.form.value.endDate);
    if(this.form.value.startDateBreak) edits.breaks = [
        {
          startTimestamp: transformDateToUTC(this.form.value.startDateBreak),
          endTimestamp: transformDateToUTC(this.form.value.endDateBreak)
        }
      ]
    this.loading = true;
    this.workingDayService.editMultipleWorkingDays(this.plannerService.campaign._id, workingDayIds, edits, confirmation)
      .then(() => {this.snackbarService.success(this.translateService.instant('campaigns.workingDays.mass_edit_dialog.SUCCESS')); this.router.navigate(['..'], { relativeTo: this.activatedRoute })})
      .catch((error) => this.displayErrorsDialog(error))
      .finally(() => this.loading = false); 
    
  }

  displayErrorsDialog = (error: any) => this.dialog.open(CampaignsDetailWorkingDaysMassEditErrorsComponent, {
    data: {error}, width: '520px', disableClose: true})
    .afterClosed().subscribe(res => {
      if(res) this.submitEdits(res);
    });

}
