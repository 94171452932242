import { BATCH_CATEGORY, BATCH_MIXED_STATUS, BATCH_UNIQUE_STATUS, BATCH_TYPE } from "@routes/batchs/batchs.constants"
import { CAMPAIGN_STATUSES } from "@shared/constants/campaigns";

export interface IBatchUniquePaginated {
    _id: string;
    status: BATCH_UNIQUE_STATUS;
    code: string;
    plate: string;
    type: BATCH_TYPE;
    category: BATCH_CATEGORY;
    datetime: Date;
    zoneName: string;
    amount: number;
    siteId: string;
    cropId: string;
    createdAt: Date;
    updatedAt: Date;
    site: IBatchUniqueSite;
    crop: IBatchUniqueCrop;
    packaging: IBatchUniquePackaging;
    dispatchNoteCode?: string;
    deleted: boolean;
    campaign: IBatchUniqueCampaign;
    historic: boolean;
};

export interface IBatchUniqueSite {
    _id: string;
    name: string;
    alias: string;
};
export interface IBatchUniqueCampaign {
    _id: string;
    name: string;
    status: CAMPAIGN_STATUSES;
    deleted: boolean;
};
export interface IBatchUniqueCrop {
    _id: string;
    name: string;
};

export interface IBatchUniquePackaging {
    _id: string;
    unit: string;
    boxes: number;
    description: string;
    total: number;
};

export interface IBatchUniqueWorker {
    _id: string;
    name: string;
    surname: string;
};

export interface IBatchUniqueReceived {
    _id: string;
    status: BATCH_UNIQUE_STATUS;
    code: string;
    plate: string;
    type: BATCH_TYPE;
    category: BATCH_CATEGORY;
    dispatchNoteCode?: string;
    datetime: Date;
    zoneName: string;
    amount: number;
    siteId: string;
    site: IBatchUniqueSite;
    cropId: string;
    crop: IBatchUniqueCrop;
    packagingId: string;
    packaging: IBatchUniquePackaging;
    createdAt: Date;
    updatedAt: Date;
    record: IBatchUniqueReceivedRecord[];
    intervals: IBatchUniqueReceivedInterval[];
    mixedBatchs: IBatchUniqueReceivedMixedBatch[];
};

export interface IBatchUniqueReceivedRecord {
    status: BATCH_UNIQUE_STATUS;
    deviceId: string;
    datetime: Date;
    device: IBatchUniqueReceivedDevice;
    amount?: number;
    temperature?: number;
    storage?: string;
    dispatchNoteCode?: string;
};

export interface IBatchUniqueReceivedDevice {
    _id: string;
    name: string;
};

export interface IBatchUniqueReceivedInterval {
    packaging: IBatchUniquePackaging;
    worker: IBatchUniqueWorker;
    endTimestamp: Date;
};

export interface IBatchUniqueReceivedMixedBatch {
    _id: string;
    code: string;
    deleted: boolean;
    boxes: number;
    datetime: Date;
    blocked: boolean;
    palletNumber: number;
};

export interface IBatchMixedPaginated {
    _id: string;
    code: string;
    customCode?: string;
    status: BATCH_MIXED_STATUS;
    category: BATCH_CATEGORY;
    amount?: number;
    palletNumber?: number;
    datetime: Date;
    record: IBatchMixedRecord[];
    batchs: IBatchMixedBatch[];
    items: IBatchMixedItem[];
    companyId: string;
    company: IBatchMixedCompany;
    warehouseId: string;
    warehouse: IBatchMixedWarehouse;
    productionLineId: string;
    productionLine: IBatchMixedProductionLine;
    deleted: boolean;
    blocked: boolean;
    createdAt: Date;
    updatedAt: Date;
};

export interface IBatchMixedReceived {
    _id: string;
    customId: string;
    code: string;
    customCode?: string;
    status: BATCH_MIXED_STATUS;
    category: BATCH_CATEGORY;
    amount?: number;
    palletNumber?: number;
    datetime: Date;
    record: IBatchMixedRecord[];
    batchs: IBatchMixedBatch[];
    items: IBatchMixedItem[];
    companyId: string;
    company: IBatchMixedCompany;
    warehouseId: string;
    warehouse: IBatchMixedWarehouse;
    productionLineId: string;
    productionLine: IBatchMixedProductionLine;
    deleted: boolean;
    blocked: boolean;
    createdAt: Date;
    updatedAt: Date;
};

export interface IBatchMixedRecord {
    status: BATCH_MIXED_STATUS;
    deviceId: string;
    datetime: Date;
};

export interface IBatchMixedBatch {
    _id: string;
    code: string;
    boxes: number;
    siteId: string;
    site: IBatchMixedSite;
    cropId: string;
    crop: IBatchMixedCrop;
    packagingId: string;
    packaging: IBatchMixedPackaging;
    zoneName: string;
    type: BATCH_TYPE;
    deleted: boolean;
};

export interface IBatchMixedItem {
    _id: string;
    sageReference: string;
    description: string;
    category: string;
    subcategory: string;
    boxes: number;
    weight: number;
    deleted: boolean;
};

export interface IBatchMixedCrop {
    _id: string;
    name: string;
};

export interface IBatchMixedPackaging {
    _id: string;
    amount: number;
    description: string;
};

export interface IBatchMixedSite {
    _id: string;
    name: string;
    alias: string;
};

export interface IBatchMixedCompany {
    _id: string;
    businessName: string;
    sageReference: string;
};

export interface IBatchMixedWarehouse {
    _id: string;
    warehouse: string;
    sageReference: string;
};

export interface IBatchMixedProductionLine {
    _id: string;
    description: string;
};

export interface IBatchMixedSent {
    _id: string;
    customId: string;
    code: string;
    category: BATCH_CATEGORY.MIXED;
    status: BATCH_MIXED_STATUS;
    datetime: Date;
    amount?: number;
    palletNumber?: number;
    batchs: IBatchMixedBatchSent[];
    items: IBatchMixedItemSent[];
    companyId: string;
    warehouseId: string;
    productionLineId: string;
}

export interface IBatchMixedBatchSent {
    code: string;
    boxes: number;
    deleted: boolean;
}

export interface IBatchMixedItemSent {
    _id: string;
    boxes: number;
    weight: number;
    deleted: boolean;
}

export class BatchMixedReceived implements IBatchMixedReceived {
    _id: string;
    customId: string;
    code: string;
    customCode?: string;
    status: BATCH_MIXED_STATUS;
    category: BATCH_CATEGORY;
    amount?: number;
    palletNumber?: number;
    datetime: Date;
    record: IBatchMixedRecord[];
    batchs: IBatchMixedBatch[];
    items: IBatchMixedItem[];
    companyId: string;
    company: IBatchMixedCompany;
    warehouseId: string;
    warehouse: IBatchMixedWarehouse;
    productionLineId: string;
    productionLine: IBatchMixedProductionLine;
    deleted: boolean;
    blocked: boolean;
    createdAt: Date;
    updatedAt: Date;

    constructor(batch) {
        Object.assign(this, batch);
        if (!batch.batchs) this.batchs = [];
        if (!batch.items) this.items = [];
    }
}

export type IBatchType = IBatchUniqueReceived | IBatchMixedReceived;

export type IBatchTypePaginated = IBatchUniquePaginated | IBatchMixedPaginated;

export type IBatchTypeRecord = IBatchUniqueReceivedRecord | IBatchMixedRecord;
