import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BatchMixedReceived, IBatchMixedReceived, IBatchType } from '@routes/batchs/batchs.model';
import { BatchsService } from './batchs.service';

@Injectable({
    providedIn: 'root'
})
export class BatchsResolver implements Resolve<IBatchType> {

    constructor(
        private batchsService: BatchsService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IBatchType> | IBatchType {
        const _id = route.params.id;
        const category = route.data.category;

        return _id
            ? this.batchsService.getBatch(_id, category)
            : new BatchMixedReceived({});
    }

}