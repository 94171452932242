<form [formGroup]="form" class="form" (submit)="submitForm()">
    <h2 class="h2 title">{{ title | translate }}</h2>
    <!-- Name -->
    <mat-form-field class="input">
        <mat-label for="name">{{ 'agencies.form.NAME' | translate }}</mat-label>
        <input matInput type="text" name="name" id="name" formControlName="name">
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
        <mat-error *ngFor="let error of form.get('name').errors | keyvalue">
            {{'general.form.errors.' + error.key | translate : error.value}}
        </mat-error>
    </mat-form-field>
    <!-- Abbreviation -->
    <mat-form-field class="input">
        <mat-label for="abbreviation">{{ 'agencies.form.ABBREVIATION' | translate }}</mat-label>
        <input matInput type="text" name="abbreviation" id="abbreviation" formControlName="abbreviation">
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
        <mat-error *ngFor="let error of form.get('abbreviation').errors | keyvalue">
            {{'general.form.errors.' + error.key | translate : error.value}}
        </mat-error>
    </mat-form-field>
    <!-- Actions -->
    <div class="buttons" [ngClass]="{ 'space-layout': !isDetail }">
        <app-generic-button
            [config]="exitButtonConfig" [routerLink]="exitRoute"
        ></app-generic-button>
        <app-generic-button
            [config]="actionButtonConfig" [disabled]="form.invalid || loading" *ngIf="!isDetail"
        ></app-generic-button>
    </div>
</form>
