import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICompanyReceived, ICompanySent } from "@routes/companies/companies.model";
import { IDataPaginated, Pagination } from "@shared/models/pagination.model";
import { ApiService } from "@shared/services/api.service";
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {

    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) {}

    private getCompaniesUrl = () => `${this.apiService.getBaseUrl()}/companies`;
    private getCompanyUrl = (_id: string) => `${this.getCompaniesUrl()}/${_id}`;

    getCompanies = (pagination?: Pagination): Promise<IDataPaginated<ICompanyReceived>> =>
        lastValueFrom(this.http.get<IDataPaginated<ICompanyReceived>>(this.getCompaniesUrl(), {params: pagination?.loadHttpParams()}));

    getCompany = (_id: string): Promise<ICompanyReceived> =>
        lastValueFrom(this.http.get<ICompanyReceived>(this.getCompanyUrl(_id)));

    addCompany = (company: ICompanySent): Promise<ICompanyReceived> =>
        lastValueFrom(this.http.post<ICompanyReceived>(this.getCompaniesUrl(), company));

    editCompany = (_id: string, company: ICompanySent): Promise<ICompanyReceived> =>
        lastValueFrom(this.http.put<ICompanyReceived>(this.getCompanyUrl(_id), company));

}