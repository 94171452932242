import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { KeychainsComponent } from './keychains.component';
import { KeychainsMainComponent } from './keychains-main/keychains-main.component';
import { KeychainsFileUploadComponent } from './keychains-file-upload/keychains-file-upload.component';
import { KeychainResolver } from './keychain.resolver';
import { KEYCHAIN_ROUTES } from '@shared/constants/routes';
import { FORM_MODES } from '@shared/constants/forms';
import { KeychainFormComponent } from './keychain-form/keychain-form.component';

const routes: Routes = [
  {
    path: '',
    component: KeychainsComponent,
    children: [
      {
        path: '',
        component: KeychainsMainComponent,
        pathMatch: 'full'
      },
      {
        path: KEYCHAIN_ROUTES.add,
        component: KeychainFormComponent,
        data: {
          mode: FORM_MODES.add
        }
      },
      {
        path: KEYCHAIN_ROUTES.file,
        component: KeychainsFileUploadComponent
      },
      {
        path: `${KEYCHAIN_ROUTES.edit}/:id`,
        component: KeychainFormComponent,
        resolve: {
          keychain: KeychainResolver
        },
        data: {
          mode: FORM_MODES.edit
        }
      },
      {
        path: `${KEYCHAIN_ROUTES.detail}/:id`,
        component: KeychainFormComponent,
        resolve: {
          keychain: KeychainResolver
        },
        data: {
          mode: FORM_MODES.detail
        }
      }
    ]
  }
];

@NgModule({
  declarations: [
    KeychainsComponent,
    KeychainsMainComponent,
    KeychainsFileUploadComponent,
    KeychainFormComponent
  ],
  providers: [

  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class KeychainsModule {}