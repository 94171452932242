import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { DeviceReceived } from '@routes/devices/device.model';
import { DeviceService } from '@routes/devices/device.service';
import { WorkingSite } from '@routes/working-sites/working-site.model';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';

import { GenericPagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { DeviceStatus, DeviceRole } from '@shared/constants/device.constants';
import { FORM_MODES } from '@shared/constants/forms';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { ICountry } from '@shared/interfaces/country.models';
import { CountryService } from '@shared/services/country.service';

@Component({
  selector: 'app-devices-dialog',
  templateUrl: './devices-dialog.component.html',
  styleUrls: ['./devices-dialog.component.scss']
})
export class DevicesDialogComponent implements OnInit {

  DeviceRole = DeviceRole;
  DeviceStatus = DeviceStatus;
  FORM_MODES = FORM_MODES;

  form: FormGroup;
  mode: FORM_MODES;
  device: DeviceReceived;
  loading: boolean;
  title: string;
  titles: Map<string, string>;

  campaigns: Campaign[];
  warehouses: WorkingSite[];
  roles: string[];
  countries: ICountry[];

  submitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CONFIRM',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit,
  };
  cancelButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button,
  };

  constructor(
    private campaignService: CampaignService,
    private deviceService: DeviceService,
    private siteService: WorkingSiteService,
    private countryService: CountryService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private dialogRef: MatDialogRef<DevicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.device = this.data.device;
    this.mode = this.data.mode;
    this.countries = this.countryService.getCountries();
    this.roles = Object.values(DeviceRole).sort((a, b) => a.localeCompare(b));
    this.titles = new Map([
      [this.FORM_MODES.add, 'APPROVE'],
      [this.FORM_MODES.edit, 'EDIT'],
      [this.FORM_MODES.delete, 'DELETE'],
    ]);

    if (this.mode !== FORM_MODES.delete) {
      this.getCampaigns();
      this.getWarehouses();
    }
    this.setTitle();
    this.setupForm();
  }

  getCampaigns = async () => {
    const pagination = new GenericPagination({sortField: 'name'});
    pagination.params = {status: CampaignStatus.Active};
    const response = await this.campaignService.getLiteCampaigns(pagination);
    this.campaigns = response.data;
  }


  getWarehouses = async () => {
    const response = await this.siteService.getSites(new GenericPagination({sortField: 'name'}));
    this.warehouses = response.sites;
  }

  setupForm = () => {
    if (this.mode === FORM_MODES.delete) {
      this.form = new FormGroup({confirm: new FormControl(false, Validators.requiredTrue)});
      return;
    }
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      appVersion: new FormControl({value: null, disabled: this.mode === FORM_MODES.edit}),
      country: new FormControl({value: null, disabled: this.mode === FORM_MODES.edit}, Validators.required),
      role: new FormControl({value: null, disabled: this.mode === FORM_MODES.edit}, Validators.required),
      campaignId: new FormControl({value: null, disabled: true}, Validators.required),
      warehouseId: new FormControl({value: null, disabled: true}, Validators.required)
    });
    const campaignControl = this.form.get('campaignId');
    const warehouseControl = this.form.get('warehouseId');

    this.form.get('role').valueChanges.subscribe(role => {
      if (role === DeviceRole.Harvest) {
        campaignControl.enable();
        warehouseControl.disable();
        warehouseControl.setValue(null);
        return;
      }
      warehouseControl.enable();
      campaignControl.disable();
      campaignControl.setValue(null);
    });
    this.form.patchValue(this.device);
  }

  setTitle = () => this.title = this.translateService.instant(`devices.titles.${this.titles.get(this.mode)}`);

  submitForm = async () => {
    if (this.form.invalid) return;
    this.loading = true;
    const device = {...this.device, ...this.form.value, status: DeviceStatus.Approved};

    try {
      this.mode === FORM_MODES.delete
        ? await this.deviceService.deleteDevice(device._id)
        : await this.deviceService.editDevice(device._id, device);
      const message = this.mode === FORM_MODES.delete
        ? 'DEVICE_SUCCESSFULLY_DELETED'
        : 'DEVICE_SUCCESSFULLY_EDITED';
      this.snackbarService.success(this.translateService.instant(`devices.dialog.${message}`));
      this.dialogRef.close();
    } catch (error) {
      this.snackbarService.error(error.error.message)
    } finally {
      this.loading = false;
    }
  }

}
