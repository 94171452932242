<table class="table" mat-table matSort [dataSource]="data" *ngIf="data.length; else noData">
    <!-- Historic name -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="table-cell name">{{ getHistoricTag() | translate }}</th>
        <td mat-cell *matCellDef="let element" class="table-cell name">{{ getHistoricNameCell(element) }}</td>
    </ng-container>
    <!-- Start date -->
    <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef class="table-cell startDate">{{ 'workers.detail.historic.START_DATE' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="table-cell startDate">{{ element.startDate | date: 'longDate' }}</td>
    </ng-container>
    <!-- End date -->
    <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef class="table-cell endDate">{{ 'workers.detail.historic.END_DATE' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="table-cell endDate">{{ element.endDate | date: 'longDate' }}</td>
    </ng-container>
    <!-- Table actions -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="table-cell table-actions">{{ 'workers.detail.historic.ACTIONS' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index" class="table-cell table-actions">
            <button class="action-btn" (click)="editHistoric(i)">
                <img class="action-img" src="/assets/images/icons/edit.svg" alt="{{ 'general.alt.EDIT' | translate }}"/>
            </button>
            <button class="action-btn" (click)="deleteHistoric(i)">
                <img class="action-img" src="/assets/images/icons/delete.svg" alt="{{ 'general.alt.DELETE' | translate }}"/>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>

<ng-template #noData>
    <p class="alert alert-secondary text-center" role="alert">{{ 'workers.detail.historic.WITHOUT_HISTORIC_DATA' | translate }}</p>
</ng-template>

<ng-container *ngIf="!editMode; else edditing">
    <div class="d-flex justify-content-center mt-4">
        <button mat-flat-button color="primary" class="btn" type="button" [routerLink]="workersList">{{ 'general.actions.BACK' | translate }}</button>
    </div>
</ng-container>

<ng-template #edditing>
    <div class="d-flex justify-content-between mt-4">
        <button mat-flat-button color="primary" class="btn" type="button" [routerLink]="workersList">{{ 'general.actions.BACK' | translate }}</button>
        <button mat-flat-button color="primary" class="btn" type="button" *ngIf="editMode && historicEditable" (click)="addHistoric()">{{ 'general.actions.ADD' | translate }}</button>
    </div>
</ng-template>