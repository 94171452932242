import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignZone } from '@routes/campaigns/campaign.model';
import { CampaignUtilsService } from '@routes/campaigns/campaign.utils.service';

@Component({
  selector: 'app-campaigns-planner-zones-table',
  templateUrl: './campaigns-planner-zones-table.component.html',
  styleUrls: ['./campaigns-planner-zones-table.component.scss']
})
export class CampaignsPlannerZonesTableComponent implements OnInit, OnDestroy {

  zones: CampaignZone[];
  subscription: Subscription;
  siteID: string;

  constructor(
    private plannerService: CampaignPlannerService,
    private campaignUtilsService: CampaignUtilsService
  ) {}

  ngOnInit(): void {
    this.zones = this.plannerService.zones;
    this.siteID = this.plannerService.site.siteId;

    this.subscription = this.plannerService.campaignChanges()
      .subscribe(() => this.zones = this.plannerService.zones);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getDensity = (area: number, plants: number) =>
    this.campaignUtilsService.calculateDensity(area, plants);

  removeZone = (sequentialId: number) =>
    this.plannerService.removeZone(sequentialId, this.siteID);
  
}
