import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignSite } from '@routes/campaigns/campaign.model';
import { ICrop } from '@routes/crops/crop.model';

@Component({
  selector: 'app-campaigns-planner-zones',
  templateUrl: './campaigns-planner-zones.component.html',
  styleUrls: ['./campaigns-planner-zones.component.scss']
})
export class CampaignsPlannerZonesComponent implements OnInit {

  @Input() crops: ICrop[];

  @Output() changeSection = new EventEmitter<string>();

  site: CampaignSite;

  constructor(
    private plannerService: CampaignPlannerService
  ) {}

  ngOnInit(): void {
    this.site = this.plannerService.site;
  }
}
