<div class="help-wrapper-identifier wrapper">
    <mat-form-field class="selector" appearance="fill">
        <mat-label>{{ 'help.selector.LABEL' | translate }}</mat-label>
        <mat-select value="" (selectionChange)="selectSection($event)">
            <mat-option value="">{{ 'help.selector.FULL_MANUAL' | translate }}</mat-option>
            <mat-option *ngFor="let section of sections" [value]="section.section">
                {{ 'help.selector.' + section.i18n | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div>
        <div class="alert alert-secondary last-updated">
            <p class="message">{{ 'help.download.UPDATE' | translate }}: <strong>{{ manualInfo?.updatedAt | date : 'dd/MM/yyyy' }}</strong></p>
            <p class="message">{{ 'help.download.VERSION' | translate }}: <strong>{{ manualInfo?.version }}</strong></p>
            <button mat-flat-button color="primary" class="actions-button btn align-self-center" type="button" (click)="downloadUserManual()">
                <span class="btn-text">{{ 'help.download.DOWNLOAD_BTN' | translate }}</span>
            </button>
        </div>
        <section class="help" [innerHTML]="body | html"></section>
    </div>
</div>
