<h3 class="h3">{{ 'campaigns.workingDays.reassign_dialog.TITLE' | translate }}</h3>
<mat-form-field class="mb-2">
    <mat-label>{{ 'campaigns.workingDays.reassign_dialog.CURRENT_WORKER' | translate }}</mat-label>
    <input matInput disabled type="text" [value]="displayWorker(workingDay.worker)"/>
</mat-form-field>
<mat-form-field class="mb-2">
    <mat-label>{{ 'campaigns.workingDays.reassign_dialog.SELECTED_WORKER' | translate }}</mat-label>
    <input matInput type="text" [matAutocomplete]="workerAutocomplete" #workerSearcher (input)="reassignLocked = true">
    <mat-autocomplete #workerAutocomplete="matAutocomplete" [displayWith]="displayWorker" (optionSelected)="checkSelectedWorker($event)">
        <mat-option *ngFor="let worker of workers" [value]="worker">{{ displayWorker(worker) }}</mat-option>
    </mat-autocomplete>
</mat-form-field>
<div class="alert alert-danger mb-4" *ngIf="errorMessage">{{ errorMessage }}</div>
<div class="buttons">
    <button mat-flat-button color="warn" class="btn" (click)="dialogRef.close()">{{ 'general.actions.CANCEL' | translate }}</button>
    <button mat-flat-button color="primary" class="btn" [disabled]="reassignLocked" (click)="reassignWorkingDay()">{{ 'general.actions.REASSIGN' | translate }}</button>
</div>