import { ValidatorFn } from '@angular/forms';
import { ButtonType, FilterOperator, FilterType, LayoutDirection } from '@shared/components/generic-filters/generic-filters.constants';
import { BehaviorSubject } from 'rxjs';

interface IFiltersConfiguration {
    search?: boolean;
    layout?: LayoutDirection;
    buttons: IFiltersButtonBase[];
    controls: IFiltersControlBase[];
}

interface IFiltersButtonBase {
    text: string;
    icon?: string;
    hidden?: () => boolean;
    disabled?: () => boolean;
}

interface IFiltersButtonAction extends IFiltersButtonBase {
    action: (...args: any) => void;
}

interface IFiltersButtonLink extends IFiltersButtonBase {
    link: string[];
}

interface IFiltersControlBase {
    name: string;
    field?: string;
    label: string;
    value: any | any[];
    operator?: FilterOperator;
    hint?: string;
    validatorFn?: ValidatorFn;
}

interface IFiltersControlDate extends IFiltersControlBase {
    modifier?: (date: Date) => any;
    minDate?: Date;
    maxDate?: Date;
    minDateReferenceName?: string;
    maxDateReferenceName?: string;
    timeReferenceName?: string;
}

interface IFiltersControlTime extends IFiltersControlBase {
    dateReferenceName: string;
}

interface IFiltersControlSelect extends IFiltersControlBase {
    options: BehaviorSubject<IFiltersSelectOption[]>;
    multiple?: boolean;
}

interface IFiltersControlGroupSelect extends IFiltersControlBase {
    options: BehaviorSubject<IFiltersSelectGroupOption[]>;
    multiple?: boolean;
}

interface IFiltersSelectOption {
    value: any;
    display: string;
}

interface IFiltersSelectGroupOption {
    options: IFiltersSelectOption[];
    name: string;
}

interface IFiltersControlCheckbox extends IFiltersControlBase {
    value: boolean;
}

interface IFiltersEvent {
    params: any;
    search: string;
}

export class FiltersConfiguration implements IFiltersConfiguration {
    search = true;
    buttons: FiltersButton[];
    controls: FiltersControl[];
    layout = LayoutDirection.Horizontal;

    constructor(config: IFiltersConfiguration) {
        Object.assign(this, config);
    }

    updateControlOptions = (name: string, options: FiltersControlOption[]) => {
        const index = this.controls.findIndex(control => control.name === name);
        if (index === -1) throw new Error('ERROR_FILTERS_CONTROL_NOT_FOUND');

        if (this.controls[index].type === FilterType.Select) {
            (this.controls[index] as FiltersControlSelect).options.next(options);
        }
    }
}

export class FiltersButton implements IFiltersButtonBase {
    type: ButtonType;
    text: string;
    icon?: string;
    hidden?: () => boolean;
    disabled?: () => boolean;

    constructor(config: IFiltersButtonBase) {
        Object.assign(this, config);
    }
}

export class FiltersButtonAction extends FiltersButton implements IFiltersButtonAction {
    type = ButtonType.Action;
    action: (...args: any) => void;

    constructor(config: IFiltersButtonAction) {
        super(config);
        Object.assign(this, config);
    }
}

export class FiltersButtonLink extends FiltersButton implements IFiltersButtonLink {
    type = ButtonType.Link;
    link: string[];

    constructor(config: IFiltersButtonLink) {
        super(config);
        Object.assign(this, config);
    }
}

export class FiltersControl implements IFiltersControlBase {
    type: FilterType;
    name: string;
    field?: string;
    label: string;
    value: any | any[];
    hint?: string;
    operator?: FilterOperator;
    validatorFn?: ValidatorFn;

    constructor(config: IFiltersControlBase) {
        Object.assign(this, config);
    }
}

export class FiltersControlDate extends FiltersControl implements IFiltersControlDate {
    type = FilterType.Date;
    value: Date;
    modifier?: (date: Date) => any;
    minDate?: Date;
    maxDate = new Date();
    minDateReferenceName?: string;
    maxDateReferenceName?: string;
    timeReferenceName?: string;

    constructor(config: IFiltersControlDate) {
        super(config);
        Object.assign(this, config);
    }
}

export class FiltersControlTime extends FiltersControl implements IFiltersControlTime {
    type = FilterType.Time;
    dateReferenceName: string;

    constructor(config: IFiltersControlTime) {
        super(config);
        Object.assign(this, config);
    }
}

export class FiltersControlSelect extends FiltersControl implements IFiltersControlSelect {
    type = FilterType.Select;
    options: BehaviorSubject<FiltersControlOption[]>;
    multiple = true;

    constructor(config: IFiltersControlSelect) {
        super(config);
        Object.assign(this, config);
    }
}

export class FiltersControlGroupSelect extends FiltersControl implements IFiltersControlGroupSelect {
    type = FilterType.GroupSelect;
    options: BehaviorSubject<FiltersControlGroupOption[]>;
    multiple = true;

    constructor(config: IFiltersControlGroupSelect) {
        super(config);
        Object.assign(this, config);
    }
}

export class FiltersControlOption implements IFiltersSelectOption {
    value: any;
    display: string;

    constructor(option: IFiltersSelectOption) {
        Object.assign(this, option);
    }
}

export class FiltersControlGroupOption implements IFiltersSelectGroupOption {
    options: IFiltersSelectOption[];
    name: string;

    constructor(option: IFiltersSelectOption) {
        Object.assign(this, option);
    }
}

export class FiltersControlCheckbox extends FiltersControl implements IFiltersControlCheckbox {
    type = FilterType.Checkbox;
}

export class FiltersEvent implements IFiltersEvent {
    params: any;
    search: string;

    constructor(event: IFiltersEvent) {
        Object.assign(this, event);
    }
}
