import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { CampaignsComponent } from '@routes/campaigns/campaigns.component';
import { CampaignsMainComponent } from '@routes/campaigns/campaigns-main/campaigns-main.component';
import { CampaignsActiveComponent } from '@routes/campaigns/campaigns-main/campaigns-active/campaigns-active.component';
import { CampaignsInactiveComponent } from '@routes/campaigns/campaigns-main/campaigns-inactive/campaigns-inactive.component';
import { CampaignsPlannerComponent } from '@routes/campaigns/campaigns-planner/campaigns-planner.component';
import { CampaignsPlannerBasicComponent } from '@routes/campaigns/campaigns-planner/campaigns-planner-basic/campaigns-planner-basic.component';
import { CampaignsPlannerSitesComponent } from '@routes/campaigns/campaigns-planner/campaigns-planner-sites/campaigns-planner-sites.component';
import { CampaignsPlannerSummaryComponent } from '@routes/campaigns/campaigns-planner/campaigns-planner-summary/campaigns-planner-summary.component';
import { CampaignsPlannerZonesComponent } from '@routes/campaigns/campaigns-planner/campaigns-planner-zones/campaigns-planner-zones.component';
import { CampaignsPlannerZonesTableComponent } from '@routes/campaigns/campaigns-planner/campaigns-planner-zones/campaigns-planner-zones-table/campaigns-planner-zones-table.component';
import { CampaignsPlannerZonesFormComponent } from '@routes/campaigns/campaigns-planner/campaigns-planner-zones/campaigns-planner-zones-form/campaigns-planner-zones-form.component';
import { CampaignsDetailComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail.component';
import { CampaignResolver } from '@routes/campaigns/campaign.resolver';
import { ReportResolver } from '@routes/campaigns/campaigns-detail/campaigns-detail-reports/report.resolver';
import { CampaignsPlannerWorkersComponent } from '@routes/campaigns/campaigns-planner/campaigns-planner-workers/campaigns-planner-workers.component';
import { CampaignsWorkersComponent } from '@routes/campaigns/campaigns-workers/campaigns-workers.component';
import { CampaignsDetailZonesComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-zones/campaigns-detail-zones.component';
import { CampaignsDetailZonesTableComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-zones/campaigns-detail-zones-table/campaigns-detail-zones-table.component';
import { CampaignsDetailZonesFormComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-zones/campaigns-detail-zones-form/campaigns-detail-zones-form.component';
import { CampaignsDetailWorkersComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-workers/campaigns-detail-workers.component';
import { CampaignsDetailWorkingDaysComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days.component';
import { CampaignsDetailWorkingDaysTableComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-table.component';
import { CampaignsDetailWorkingDaysFormComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-form/campaigns-detail-working-days-form.component';
import { CampaignsDetailBasicComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-basic/campaigns-detail-basic.component';
import { CampaignsDetailTasksComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks.component';
import { CampaignsDetailTasksTableComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-table/campaigns-detail-tasks-table.component';
import { CampaignsDetailWorkingDayResolver } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-day.resolver';
import { CampaignsDetailWorkingDaysTableReassignmentDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-table-reassignment-dialog/campaigns-detail-working-days-table-reassignment-dialog.component';
import { CampaignsDetailPerformanceComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-performance/campaigns-detail-performance.component';
import { CampaignsDetailReportsComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-reports/campaigns-detail-reports.component';
import { CampaignsDetailReportsHarvestComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-reports/campaigns-detail-reports-harvest/campaigns-detail-reports-harvest.component';
import { CampaignsDetailReportsMainComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-reports/campaigns-detail-reports-main/campaigns-detail-reports-main.component';
import { CampaignsDetailZonesFormResolver } from '@routes/campaigns/campaigns-detail/campaigns-detail-zones/campaigns-detail-zones-form/campaigns-detail-zones-form.resolver';
import { CampaignsDetailTasksIntervalsDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-intervals-dialog/campaigns-detail-tasks-intervals-dialog.component';
import { CampaignsDetailTasksEditComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.component';
import { CampaignsDetailTasksEditTaskComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit-task/campaigns-detail-tasks-edit-task.component';
import { CampaignsDetailTasksEditProductionIntervalsComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit-production-intervals/campaigns-detail-tasks-edit-production-intervals.component';
import { CampaignsDetailTasksEditProductionIntervalsDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit-production-intervals/campaigns-detail-tasks-edit-production-intervals-dialog/campaigns-detail-tasks-edit-production-intervals-dialog.component';
import { CampaignsDetailTasksEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.service';
import { CampaignsDetailTasksEditResolver } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.resolver';
import { CampaignsDetailFinishDialogComponent } from '@routes/campaigns/campaigns-main/campaigns-detail-finish-dialog/campaigns-detail-finish-dialog.component';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaingDetailsZonesDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-zones/campaing-details-zones-dialog/campaing-details-zones-dialog.component';
import { CampaignFinishedResolver } from '@routes/campaigns/campaign-finished.resolver';
import { CampaignsDetailWorkingDaysTableComplaintDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-table-complaint-dialog/campaigns-detail-working-days-table-complaint-dialog.component';
import { CampaignsDetailUniqueBatchsTableComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-batchs/campaigns-detail-unique-batchs-table/campaigns-detail-unique-batchs-table.component';
import { BatchsDetailComponent } from '@routes/batchs/batchs-detail/batchs-detail.component';
import { BatchsResolver } from '@routes/batchs/batchs.resolver';
import { BATCH_CATEGORY } from '@routes/batchs/batchs.constants';
import { CampaignsDetailBatchsComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-batchs/campaigns-detail-batchs.component';
import { CampaignsDetailWorkingDaysTableErrorsDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-table-errors-dialog/campaigns-detail-working-days-table-errors-dialog.component';

import { GenericReportComponent } from '@shared/components/generic-report/generic-report.component';
import { SharedModule } from '@shared/shared.module';
import { CAMPAIGNS_ROUTES, CAMPAIGNS_DETAIL_ROUTES, REPORTS_ROUTES, BATCHS_ROUTES } from '@shared/constants/routes';
import { FORM_MODES } from '@shared/constants/forms';
import { REPORTS_FILTER_INPUTS, REPORTS_I18N_FILES_LIST, REPORTS_I18N_LIST } from '@shared/constants/reports';
import { CampaignsDetailWorkingDaysSelectStateDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-select-state-dialog/campaigns-detail-working-days-select-state-dialog.component';
import { CampaignsDetailWorkingDaysMassEditComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit.component';
import { CampaignsDetailWorkingDaysMassEditErrorsComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit-errors/campaigns-detail-working-days-mass-edit-errors.component';
import { CampaignsDetailWorkingDaysMassEditSelectorComponent } from './campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit-selector/campaigns-detail-working-days-mass-edit-selector.component';
import { CampaignsDetailWorkingDaysMassEditFiltersComponent } from './campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit-filters/campaigns-detail-working-days-mass-edit-filters.component';
import { CampaignsDetailWorkingDaysMassEditFormComponent } from './campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit-form/campaigns-detail-working-days-mass-edit-form.component';
import { CampaignsDetailWorkingDaysMassEditEditorComponent } from './campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit-editor/campaigns-detail-working-days-mass-edit-editor.component';
import { CampaignsDetailWorkingDaysMassEditSummaryComponent } from './campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit-summary/campaigns-detail-working-days-mass-edit-summary.component';
import { CampaignsDetailWorkingDaysMassEditConfirmationComponent } from './campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit-confirmation/campaigns-detail-working-days-mass-edit-confirmation.component';

const routes: Routes = [
  {
    path: '',
    component: CampaignsComponent,
    children: [
      {
        path: '',
        component: CampaignsMainComponent,
        pathMatch: 'full'
      },
      {
        path: CAMPAIGNS_ROUTES.planning,
        component: CampaignsPlannerComponent
      },
      {
        path: `${CAMPAIGNS_ROUTES.detail}/:campaignId`,
        component: CampaignsDetailComponent,
        resolve: {
          campaign: CampaignResolver
        },
        children: [
          {
            path: CAMPAIGNS_DETAIL_ROUTES.basic,
            component: CampaignsDetailBasicComponent
          },
          {
            path: CAMPAIGNS_DETAIL_ROUTES.sites,
            component: CampaignsDetailZonesComponent,
            children: [
              {
                path: '',
                component: CampaignsDetailZonesTableComponent,
                pathMatch: 'full'
              },
              {
                path: `:siteId/${CAMPAIGNS_DETAIL_ROUTES.addZone}`,
                component: CampaignsDetailZonesFormComponent,
                data: {
                  mode: FORM_MODES.add
                },
                resolve: {
                  data: CampaignsDetailZonesFormResolver,
                  editable: CampaignFinishedResolver
                }
              },
              {
                path: `:siteId/${CAMPAIGNS_DETAIL_ROUTES.editZone}/:sequentialId`,
                component: CampaignsDetailZonesFormComponent,
                data: {
                  mode: FORM_MODES.edit
                },
                resolve: {
                  data: CampaignsDetailZonesFormResolver,
                  editable: CampaignFinishedResolver
                }
              }
            ]
          },
          {
            path: CAMPAIGNS_DETAIL_ROUTES.workers,
            component: CampaignsDetailWorkersComponent
          },
          {
            path: CAMPAIGNS_DETAIL_ROUTES.uniqueBatchs,
            component: CampaignsDetailBatchsComponent,
            children: [
              {
                path: '',
                component: CampaignsDetailUniqueBatchsTableComponent,
                pathMatch: 'full'
              },
               {
                path: `:id/${BATCHS_ROUTES.detail}`,
                component: BatchsDetailComponent,
                resolve: { batch: BatchsResolver },
                data: { category: BATCH_CATEGORY.UNIQUE }
              }
            ]
          },
          {
            path: CAMPAIGNS_DETAIL_ROUTES.workingDays,
            component: CampaignsDetailWorkingDaysComponent,
            children: [
              {
                path: '',
                component: CampaignsDetailWorkingDaysTableComponent,
                pathMatch: 'full'
              },
              {
                path: `${CAMPAIGNS_DETAIL_ROUTES.workingDayAdd}`,
                component: CampaignsDetailWorkingDaysFormComponent,
                data: { mode: FORM_MODES.add },
                resolve: { editable: CampaignFinishedResolver }
              },
              {
                path: `${CAMPAIGNS_DETAIL_ROUTES.workingDayMassEdit}`,
                component: CampaignsDetailWorkingDaysMassEditComponent,
                resolve: { editable: CampaignFinishedResolver }
              },
              {
                path: `${CAMPAIGNS_DETAIL_ROUTES.workingDayEdit}/:id`,
                component: CampaignsDetailWorkingDaysFormComponent,
                data: { mode: FORM_MODES.edit },
                resolve: {
                  workingDay: CampaignsDetailWorkingDayResolver,
                  editable: CampaignFinishedResolver
                }
              },
              {
                path: `${CAMPAIGNS_DETAIL_ROUTES.workingDayDetail}/:id`,
                component: CampaignsDetailWorkingDaysFormComponent,
                data: { mode: FORM_MODES.detail },
                resolve: { workingDay: CampaignsDetailWorkingDayResolver }
              },
              {
                path: `:id/${CAMPAIGNS_DETAIL_ROUTES.taskAdd}`,
                component: CampaignsDetailTasksEditComponent,
                data: { mode: FORM_MODES.add },
                resolve: {
                  data: CampaignsDetailTasksEditResolver,
                  editable: CampaignFinishedResolver
                }
              },
              {
                path: `:id/${CAMPAIGNS_DETAIL_ROUTES.taskEdit}/:taskId`,
                component: CampaignsDetailTasksEditComponent,
                data: { mode: FORM_MODES.edit },
                resolve: {
                  data: CampaignsDetailTasksEditResolver,
                  editable: CampaignFinishedResolver
                }
              }
            ]
          },
          {
            path: CAMPAIGNS_DETAIL_ROUTES.tasks,
            component: CampaignsDetailTasksComponent,
            children: [
              {
                path: '',
                component: CampaignsDetailTasksTableComponent,
                pathMatch: 'full'
              }
            ]
          },
          {
            path: CAMPAIGNS_DETAIL_ROUTES.reports,
            component: CampaignsDetailReportsComponent,
            children: [
              {
                path: '',
                component: CampaignsDetailReportsMainComponent,
                pathMatch: 'full'
              },
              {
                path: REPORTS_ROUTES.harvest,
                component: CampaignsDetailReportsHarvestComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.HARVEST,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().setHours(0, 0, 0, 0) - 1000 * 60 * 60 * 24),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(new Date().setHours(23, 59, 59, 999) - 1000 * 60 * 60 * 24),
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  }
                }
              },
              {
                path: REPORTS_ROUTES.harvestWeekly,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.WEEKLY_HARVEST,
                  filename: REPORTS_I18N_FILES_LIST.WEEKLY_HARVEST,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (new Date().getDay() - 1)),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  },
                }
              },
              {
                path: REPORTS_ROUTES.workerHours,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.WORKER_HOURS,
                  filename: REPORTS_I18N_FILES_LIST.WORKER_HOURS,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (new Date().getDate() - 1)),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  },
                }
              },
              {
                path: REPORTS_ROUTES.maintenanceTasks,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.MAINTENANCE_TASKS,
                  filename: REPORTS_I18N_FILES_LIST.MAINTENANCE_TASKS,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.TASK_TYPE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  },
                }
              },
              {
                path: REPORTS_ROUTES.harvestIncentive,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.HARVEST_INCENTIVE,
                  filename: REPORTS_I18N_FILES_LIST.HARVEST_INCENTIVE,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  },
                }
              },
              {
                path: REPORTS_ROUTES.dailyPerformance,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.DAILY_PERFORMANCE,
                  filename: REPORTS_I18N_FILES_LIST.DAILY_PERFORMANCE,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.ZONE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  },
                }
              },
              {
                path: REPORTS_ROUTES.harvestMaintenanceSummary,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.HARVEST_MAINTENANCE_SUMMARY,
                  filename: REPORTS_I18N_FILES_LIST.HARVEST_MAINTENANCE_SUMMARY,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (new Date().getDate() - 1)),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  },
                }
              },
              {
                path: REPORTS_ROUTES.harvestPackagingCost,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.HARVEST_PACKAGING_COST,
                  filename: REPORTS_I18N_FILES_LIST.HARVEST_PACKAGING_COST,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (new Date().getDate() - 1)),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.ZONE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CROP_IDS]: null,
                  [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  },
                }
              },
              {
                path: REPORTS_ROUTES.weeklyPerformance,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.WEEKLY_PERFORMANCE,
                  filename: REPORTS_I18N_FILES_LIST.WEEKLY_PERFORMANCE,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (new Date().getDate() - 1)),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.ZONE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  }
                }
              },
              {
                path: REPORTS_ROUTES.plantsHarvest,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.PLANTS_HARVEST,
                  filename: REPORTS_I18N_FILES_LIST.PLANTS_HARVEST,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (new Date().getDate() - 1)),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.CONFIRMED_WORKING_DAYS]: true,
                  }
                }
              },
              {
                path: REPORTS_ROUTES.workableTasks,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.WORKABLE_TASKS,
                  filename: REPORTS_I18N_FILES_LIST.WORKABLE_TASKS,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (new Date().getDate() - 1)),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.WORKABLE_TASKS]: null,
                  }
                }
              },
              {
                path: REPORTS_ROUTES.workLog,
                component: GenericReportComponent,
                resolve: {
                  data: ReportResolver
                },
                data: {
                  title: REPORTS_I18N_LIST.WORK_LOG,
                  filename: REPORTS_I18N_FILES_LIST.WORK_LOG,
                  filters: {
                    [REPORTS_FILTER_INPUTS.START_DATE]: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (new Date().getDate() - 1)),
                    [REPORTS_FILTER_INPUTS.END_DATE]: new Date(),
                    [REPORTS_FILTER_INPUTS.DEVICE_IDS]: null,
                    [REPORTS_FILTER_INPUTS.SITE_IDS]: null,
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  declarations: [
    CampaignsComponent,
    CampaignsMainComponent,
    CampaignsActiveComponent,
    CampaignsInactiveComponent,
    CampaignsPlannerComponent,
    CampaignsPlannerBasicComponent,
    CampaignsPlannerSitesComponent,
    CampaignsPlannerSummaryComponent,
    CampaignsPlannerZonesComponent,
    CampaignsPlannerZonesTableComponent,
    CampaignsPlannerZonesFormComponent,
    CampaignsPlannerWorkersComponent,
    CampaignsWorkersComponent,
    CampaignsDetailComponent,
    CampaignsDetailBasicComponent,
    CampaignsDetailZonesComponent,
    CampaignsDetailZonesTableComponent,
    CampaignsDetailZonesFormComponent,
    CampaignsDetailWorkersComponent,
    CampaignsDetailWorkingDaysComponent,
    CampaignsDetailWorkingDaysTableComponent,
    CampaignsDetailWorkingDaysFormComponent,
    CampaignsDetailTasksComponent,
    CampaignsDetailTasksTableComponent,
    CampaignsDetailWorkingDaysTableReassignmentDialogComponent,
    CampaignsDetailWorkingDaysTableComplaintDialogComponent,
    CampaignsDetailPerformanceComponent,
    CampaignsDetailReportsComponent,
    CampaignsDetailReportsMainComponent,
    CampaignsDetailReportsHarvestComponent,
    CampaignsDetailTasksEditComponent,
    CampaignsDetailTasksIntervalsDialogComponent,
    CampaignsDetailTasksEditTaskComponent,
    CampaignsDetailTasksEditProductionIntervalsComponent,
    CampaignsDetailTasksEditProductionIntervalsDialogComponent,
    CampaingDetailsZonesDialogComponent,
    CampaignsDetailFinishDialogComponent,
    CampaignsDetailUniqueBatchsTableComponent,
    CampaignsDetailBatchsComponent,
    CampaignsDetailWorkingDaysTableErrorsDialogComponent,
    CampaignsDetailWorkingDaysSelectStateDialogComponent,
    CampaignsDetailWorkingDaysMassEditComponent,
    CampaignsDetailWorkingDaysMassEditErrorsComponent,
    CampaignsDetailWorkingDaysMassEditSelectorComponent,
    CampaignsDetailWorkingDaysMassEditFiltersComponent,
    CampaignsDetailWorkingDaysMassEditFormComponent,
    CampaignsDetailWorkingDaysMassEditEditorComponent,
    CampaignsDetailWorkingDaysMassEditSummaryComponent,
    CampaignsDetailWorkingDaysMassEditConfirmationComponent
  ],
  providers: [
    CampaignPlannerService,
    CampaignsDetailTasksEditService,
    CampaignsDetailTasksEditResolver
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CampaignsModule { }
