import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { WorkerReceived, WorkerReceivedGroup } from '@routes/workers/worker.model';
import { WorkerService } from '@routes/workers/worker.service';

import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { Pagination } from '@shared/models/pagination.model';
import { WorkingDay } from '@shared/models/working-day.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { WorkingDayService } from '@shared/services/working-day.service';

@Component({
  selector: 'app-campaigns-detail-working-days-table-reassignment-dialog',
  templateUrl: './campaigns-detail-working-days-table-reassignment-dialog.component.html',
  styleUrls: ['./campaigns-detail-working-days-table-reassignment-dialog.component.scss']
})
export class CampaignsDetailWorkingDaysTableReassignmentDialogComponent implements OnInit {

  @ViewChild('workerSearcher', {static: true}) searcher: ElementRef;

  workingDay: WorkingDay;
  selectedWorker: WorkerReceivedGroup;
  workers: WorkerReceivedGroup[] = [];
  errorMessage: string;
  reassignLocked = true;
  pagination = new Pagination({page: 1, size: 10});

  constructor(
    private workerService: WorkerService,
    private workingDayService: WorkingDayService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    public dialogRef: MatDialogRef<CampaignsDetailWorkingDaysTableReassignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.workingDay = this.data.workingDay;

    fromEvent(this.searcher.nativeElement, 'input').pipe(debounceTime(250)).subscribe(async (event: InputEvent) => {
      this.pagination.search = (<HTMLInputElement>event.target).value;
      this.pagination.params = {};
      this.workers = (await this.workerService.getWorkers(this.pagination)).workers;
    });
  }

  displayWorker = (worker: WorkerReceivedGroup | WorkerReceived) =>
    worker ? `${worker.name} ${worker.surname} (${worker.sequentialId}) (${worker.nif ? worker.nif : ''})` : '';

  checkSelectedWorker = async (event: MatAutocompleteSelectedEvent) => {
    this.selectedWorker = <WorkerReceivedGroup>event.option.value;

    if (this.selectedWorker._id === this.workingDay.workerId) {
      this.errorMessage = this.translateService.instant('campaigns.workingDays.reassign_dialog.SAME_WORKER');
      this.reassignLocked = true;
      return;
    }
    this.errorMessage = '';
    this.reassignLocked = false;
  };

  reassignWorkingDay = () => {
    this.workingDay.workerId = this.selectedWorker._id;
    this.workingDayService.editWorkingDay(this.workingDay.campaignId, this.workingDay._id, this.workingDay)
      .then(() => this.dialogRef.close({ workingDayChanged: true }))
      .catch(error => this.snackbarService.open(SNACKBAR_TYPES.ERROR, error.message || error.message.message));
  }

}
