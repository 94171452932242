<table class="table">
    <thead class="table-head">
        <tr class="table-row">
            <th class="table-cell sector">{{ 'campaigns.zones.NAME' | translate }}</th>
            <th class="table-cell crop">{{ 'campaigns.zones.CROP' | translate }}</th>
            <th class="table-cell traceCode">{{ 'campaigns.zones.TRACE_CODE' | translate }}</th>
            <th class="table-cell area">{{ 'campaigns.zones.AREA' | translate }}</th>
            <th class="table-cell plants">{{ 'campaigns.zones.PLANTS' | translate }}</th>
            <th class="table-cell actions">{{ 'campaigns.zones.ACTIONS' | translate }}</th>
        </tr>
    </thead>
    <tbody class="table-body">
        <ng-container *ngIf="zones.length; else noZones">
            <tr class="table-row" *ngFor="let zone of zones">
                <td class="table-cell sector">{{ zone.name }}</td>
                <td class="table-cell crop">{{ zone.cropName }} ({{ zone.cropVariety }})</td>
                <td class="table-cell traceCode">{{ zone.traceCode }}</td>
                <td class="table-cell area">{{ zone.area }}</td>
                <td class="table-cell plants">{{ zone.plants }}</td>
                <td class="table-cell actions table-actions">
                    <button class="action-btn" (click)="removeZone(zone.sequentialId)">
                        <img class="action-img" src="/assets/images/icons/delete.svg" alt="{{ 'general.alt.DELETE' | translate }}"/>
                    </button>
                </td>
            </tr>
        </ng-container>
        <ng-template #noZones>
            <tr class="table-row">
                <td class="table-cell" colspan="6">{{ 'campaigns.zones.NO_ZONES' | translate }}</td>
            </tr>
        </ng-template>
    </tbody>
</table>