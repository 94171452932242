import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CampaignService } from '@routes/campaigns/campaign.service';
import { TaskService } from '@routes/tasks/task.service';
import { ReportService } from '@routes/campaigns/campaigns-detail/campaigns-detail-reports/report.service';
import { PackagingService } from '@routes/packagings/packaging.service';
import { CropService } from '@routes/crops/crop.service';

import { GenericPagination, Pagination } from '@shared/models/pagination.model';
import { CAMPAIGN_TYPES } from '@shared/constants/campaigns';
import { TASK_TYPES_TYPES } from '@shared/constants/task-types';
import { REPORTS_FILTER_INPUTS } from '@shared/constants/reports';

@Injectable({
    providedIn: 'root'
})
export class ReportResolver implements Resolve<object> {

    constructor(
        private campaignService: CampaignService,
        private reportService: ReportService,
        private taskTypeService: TaskService,
        private translateService: TranslateService,
        private packagingService: PackagingService,
        private cropService: CropService,
    ) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<object> {
        const campaignId = route.parent.parent.params.campaignId;

        const result = this.reportService.getMethodsByTag(route.url[0].path) as any;

        result.campaign = await this.campaignService.getCampaign(campaignId);
        result.unit = result.campaign.type === CAMPAIGN_TYPES.PLANT
            ? this.translateService.instant('general.units.ABS_FULL_PLANT').toLowerCase()
            : this.translateService.instant('general.units.ABS_FRUIT');

        if (!route.data.filters) return result;

        const filters = Object.keys(route.data.filters);

        if (filters.includes(REPORTS_FILTER_INPUTS.DEVICE_IDS)) {
            result.devices = result.campaign.devices?.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (filters.includes(REPORTS_FILTER_INPUTS.SITE_IDS)) {
            result.sites = result.campaign.sites;
        }
        if (filters.includes(REPORTS_FILTER_INPUTS.ZONE_IDS)) {
            result.sites = result.campaign.sites;
        }
        if (filters.includes(REPORTS_FILTER_INPUTS.PACKAGING_IDS))
            result.packagings = (await this.packagingService.getPackagings(
                new Pagination({ ...new GenericPagination({ sortField: 'description' }), ...{ params: { type: result.campaign.type } } }))
            ).data;
        if (filters.includes(REPORTS_FILTER_INPUTS.CROP_IDS))
            result.crops = (await this.cropService.getCrops(
                new Pagination({ ...new GenericPagination({ sortField: 'name' }), ...{ params: { type: result.campaign.type } } }))
            ).data;
        if (filters.includes(REPORTS_FILTER_INPUTS.TASK_TYPE_IDS)) {
            const pagination = new Pagination({});
            pagination.params = { types: [TASK_TYPES_TYPES.WORKABLE, TASK_TYPES_TYPES.OTHER] };
            result.taskTypes = (await this.taskTypeService.getTasks(pagination)).tasksTypes;
        }
        if (filters.includes(REPORTS_FILTER_INPUTS.WORKABLE_TASKS)) {
            const pagination = new Pagination({});
            pagination.params = { types: [TASK_TYPES_TYPES.WORKABLE] };
            result.taskTypes = (await this.taskTypeService.getTasks(pagination)).tasksTypes;
        }
        Object.assign(result, this.reportService.getMethodsByTag(route.url[0].path));

        return result;
    }
}