<mat-form-field class="campaigns-select" appearance="standard">
  <mat-label>{{ 'campaigns.main.TYPE' | translate }}</mat-label>
  <mat-select [value]="campaignType" (selectionChange)="selectCampaignType($event)">
    <mat-option *ngFor="let type of campaignTypes; let i = index" value="{{ type.value }}">
      {{ getCampaignType(i) | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
<ul class="campaigns-actions button-container">
  <li>
    <button mat-flat-button color="primary" class="actions-button btn" type="button"
      [routerLink]="CAMPAIGN_PLAINNING_ROUTE">
      <img class="btn-image" src="/assets/images/icons/add.svg" alt="{{ 'campaigns.main.PLAN' | translate }}" />
      <span class="btn-text">{{ 'campaigns.main.PLAN' | translate }}</span>
    </button>
  </li>
</ul>
<app-campaigns-active *ngIf="campaignType === 'active'"></app-campaigns-active>
<app-campaigns-inactive *ngIf="campaignType === 'finished'"></app-campaigns-inactive>
