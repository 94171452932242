import { TranslateService } from "@ngx-translate/core";

export class TranslationUtils {

    constructor(
        private translationService: TranslateService
    ) {}

    protected translate = (keys: string[], params: any = {}) => {
        keys.sort(a => a.startsWith('general') ? -1 : 1);
        const translation = Object.values(this.translationService.instant(keys, params));
        const mergedTranslation = translation.reduce((prev, curr) => Object.assign(prev, curr), {});
        return JSON.parse(JSON.stringify(mergedTranslation).replace(/{{\s*([^}\s]+)\s*}}/gm, (match, group) => params[group] ?? ""));
    }

}