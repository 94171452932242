import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { IDispatchNoteReceived } from "./dispatch-notes.model";
import { DispatchNotesService } from "./dispatch-notes.service";

@Injectable({
    providedIn: 'root'
})
export class DispatchNotesResolver implements Resolve<IDispatchNoteReceived> {

    constructor(
        private dispatchNotesService: DispatchNotesService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IDispatchNoteReceived> {
        const _id = route.params.id;
        return this.dispatchNotesService.getDispatchNote(_id);
    }
    
}