import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';
import { ReportService } from '@routes/campaigns/campaigns-detail/campaigns-detail-reports/report.service';

import { IPerformanceReport, IPerformanceReportDate } from '@shared/models/reports.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { ConfirmDialogComponent } from '@shared/widgets/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-campaigns-detail-performance',
  templateUrl: './campaigns-detail-performance.component.html',
  styleUrls: ['./campaigns-detail-performance.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailPerformanceComponent implements OnInit, OnDestroy {

  CampaignStatus = CampaignStatus;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  campaign: Campaign;
  
  paginatorSize = 3;
  paginatorLength: number;
  loadingSummary = false;
  missingReport = false;
  subscriptions: Subscription[] = [];

  displayedRow: string;
  averageUnit: string;
  totalUnit: string;

  report: IPerformanceReport = null;
  dates: IPerformanceReportDate[] = [];

  constructor(
    private plannerService: CampaignPlannerService,
    private reportService: ReportService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.campaign = this.plannerService.campaign;
    this.averageUnit = this.campaign.type === 'fruit' ? 'kg/h/px' : 'pl/h/px';
    this.totalUnit = this.campaign.type === 'fruit' ? 'kg' : 'pl';

    const fn = this.reportService.getPerformanceReport(this.campaign.id);
    this.retrievePerformance(fn);
    
    this.subscriptions.push(this.plannerService.campaignChanges()
      .subscribe(campaign => this.campaign = campaign));
  }

  ngOnDestroy(): void {
    this.snackbarService.snackBar.dismiss();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  retrievePerformance = async (fn: Promise<IPerformanceReport>) => {
    try {
      this.loadingSummary = true;
      this.missingReport = false;
      this.report = null;
      this.snackbarService.loading(this.translateService.instant('reports.messages.LOADING_REPORT'));

      this.report = await fn;
      this.paginatorLength = this.report.performance
        ? this.report.performance.dates.length
        : 0;
        
      this.paginatePerformance();
      this.snackbarService.snackBar.dismiss();
    } catch (error) {
      if (error.status === 404) {
        this.missingReport = true;
        return this.snackbarService.snackBar.dismiss();
      }
      this.snackbarService.error(error.error.message);
    } finally {
      this.loadingSummary = false;
    }
  }

  paginatePerformance = (event?: PageEvent) => {
    const page = event?.pageIndex || 0;
    const size = event?.pageSize || 3;
    this.dates = this.report.performance
      ? this.report.performance.dates.slice(page * size, (page + 1) * size)
      : [];
  }

  loadReportDialog = () => this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: this.translateService.instant('general.dialogs.confirm.REPORT_CREATION_TITLE'),
      message: this.translateService.instant('general.dialogs.confirm.REPORT_CREATION_MESSAGE')
    }
  }).afterClosed().pipe(
    filter(data => data?.confirm)
  ).subscribe(async () =>await this.retrievePerformance(this.reportService.upsertPerformanceReport(this.campaign.id)));

  showCropRow = (tableIndex: number, rowIndex: number) => {
    const className = `show-${tableIndex}-${rowIndex}`;

    this.displayedRow = this.displayedRow === className
      ? null
      : className;
  }

}