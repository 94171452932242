import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TaskType, ITaskType, TaskTypeUnit } from '../task.model';
import { FORM_STATUS } from '@shared/interfaces/forms';
import { SECTIONS, ABS_BASE_ROUTE, TASKS_ROUTES } from '@shared/constants/routes';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { TaskService } from '../task.service';
import { TranslateService } from '@ngx-translate/core';
import { taskTypesUnits, TASK_TYPES_TYPES } from '@shared/constants/task-types';

@Component({
  selector: 'app-tasks-edit',
  templateUrl: './tasks-edit.component.html',
  styleUrls: ['./tasks-edit.component.scss']
})
export class TasksEditComponent implements OnInit {

  FORM_STATUS = FORM_STATUS;

  TASK_LIST_ROUTE = [ABS_BASE_ROUTE, SECTIONS.production, TASKS_ROUTES.parent];

  taskTypesUnits: TaskTypeUnit[] = taskTypesUnits;
  loading = false;
  form: FormGroup;
  title: string;
  mode: string;
  id: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private taskService: TaskService,
    private snackbarService: CustomSnackbarService
  ) {}

  ngOnInit(): void {
    this.setMode();
    this.setForm();
  }

  setMode = (): void => {
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.title = this.translateService.instant(`tasks.form.title.${this.mode.toUpperCase()}`);
  }

  setForm = (): void => {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      workable: new FormControl(false),
      unit: new FormControl({ value: null, disabled: true }, Validators.required)
    });

    this.form.get('workable').valueChanges.subscribe(value => value && this.mode !== FORM_STATUS.detail
      ? this.form.get('unit').enable()
      : this.form.get('unit').disable());

    if (this.mode === FORM_STATUS.detail) this.form.disable();
    if (this.mode !== FORM_STATUS.add) this.retrieveEntity();
  }

  retrieveEntity = () => {
    this.id = this.activatedRoute.snapshot.params.id;

    this.taskService.getTask(this.id)
      .then((task: TaskType) => this.form.patchValue(task));
  }

  translateUnit = (unit: string) => this.translateService.instant(`tasks.units.${unit}`);

  submitForm = () => {
    this.loading = true;
  
    const fn = this.mode === FORM_STATUS.add
      ? this.taskService.addTask
      : this.taskService.editTask;
  
    const type: TASK_TYPES_TYPES = this.form.value.workable
      ? TASK_TYPES_TYPES.WORKABLE
      : TASK_TYPES_TYPES.OTHER;
      
    const task: ITaskType = new TaskType({ id: this.id, ...this.form.value, type });
  
    fn(task)
      .then(() => this.router.navigate([ABS_BASE_ROUTE, SECTIONS.production, TASKS_ROUTES.parent]))
      .catch(error => this.snackbarService.open(SNACKBAR_TYPES.ERROR, error.error.message || error.message))
      .finally(() => {
        this.loading = false;
      });
  }

}
