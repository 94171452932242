import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, FormGroupDirective, NG_VALIDATORS, Validator } from '@angular/forms';
import { updateValidationAllControls } from '@shared/utils/forms.utils';

@Directive({
  selector: '[appDifferentFrom]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DifferentFromDirective, multi: true }]

})
export class DifferentFromDirective implements Validator  {

  @Input() fomControlNameDifferentTo: string | string[];

  constructor(private form: FormGroupDirective) { }

  validate(control: AbstractControl): { [key: string]: any } {
    if (!control.value) return null;
    if (!this.fomControlNameDifferentTo || !this.fomControlNameDifferentTo.length) return null;
    const error = typeof this.fomControlNameDifferentTo === 'string'
      ? this.form.control.get(this.fomControlNameDifferentTo)?.value === control.value
      : this.fomControlNameDifferentTo.some(controlName => this.form.control.get(controlName)?.value === control.value);
    return error ? ({ repeatedValue: true }) : null;
  }

  @HostListener('input') onInput() {
    updateValidationAllControls(this.form.control);
  }
}
