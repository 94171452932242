import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { PositionsMainService } from '@routes/positions/positions-main.service';
import { PositionsRoutingService } from '@routes/positions/positions-routing.service';
import { Position } from '@routes/positions/positions.models';

import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { FORM_MODES } from '@shared/constants/forms';
import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-positions-edit',
  templateUrl: './positions-edit.component.html',
  styleUrls: ['./positions-edit.component.scss']
})
export class PositionsEditComponent implements OnInit {

  form: FormGroup;
  position: Position;
  mode: FORM_MODES;
  texts: any;
  exitRoute: string[];

  exitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button
  };
  submitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CONFIRM',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit
  };
  loading = false;
  literals: string[] = [];

  constructor(
    private positionsMainService: PositionsMainService,
    private positionsRoutingService: PositionsRoutingService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.position = this.activatedRoute.snapshot.data.position;
    this.texts = this.translateService.instant('positions.edit');
    this.exitRoute = this.positionsRoutingService.main();
    this.setupForm();
    this.loadExistentLiterals();
  }

  setupForm = () => {
    this.form = new FormGroup({name: new FormControl(null, Validators.required)});
    if (this.mode !== FORM_MODES.add) this.form.patchValue(this.position);
  }

  loadExistentLiterals = async() =>
    this.literals = (await this.positionsMainService.getAll()).positions
      .map(position => position.name);

  submitForm = () => {
    if (this.form.invalid) return;
    this.loading = true;
    
    const fn = this.mode === FORM_MODES.add
      ? this.positionsMainService.add(this.form.value)
      : this.positionsMainService.edit(this.position._id, this.form.value);

    fn.then(() => {
      const message = this.translateService.instant('positions.edit.SUCCESSFUL_' + this.mode.toUpperCase());
      this.snackbarService.success(message);
      this.router.navigate(this.exitRoute);
    })
    .catch(error => this.snackbarService.error(error.error.message))
    .finally(() => this.loading = false);
  }

}
