<div class="pb-3">
    <h2 class="title mb-3">{{ title }}</h2>
    <p>{{ message }}</p>
    <mat-dialog-actions class="d-flex justify-content-between">
      <button mat-flat-button mat-dialog-close color="warn" class="actions-button btn" type="button" (click)="closeDialog(false)">
        {{ 'general.actions.CANCEL' | translate }}
      </button>
      <button mat-flat-button color="primary" class="actions-button btn" type="button" (click)="closeDialog(true)">
        {{ 'general.actions.CONFIRM' | translate }}
      </button>
    </mat-dialog-actions>
  </div>