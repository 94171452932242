import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import {
  IGenericReport,
  IPerformanceReport,
  IReportDailyHarvestIncentive,
  IReportHarvestIncentive,
  IReportHarvestMaintenanceSummary,
  IReportMaintenanceTasks,
  IReportWorkerHours,
  ITaskHarvestReport,
  ITaskHarvestWeeklyReport
} from '@shared/models/reports.model';
import { REPORTS_ROUTES } from '@shared/constants/routes';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getReportUrl = () => `${this.apiService.getBaseUrl()}/reports`;
  getPerformanceReportUrl = campaignId => `${this.getReportUrl()}/performance/${campaignId}`;
  getHarvestReportUrl = () => `${this.getReportUrl()}/harvest`;
  getWeeklyHarvestReportUrl = () => `${this.getReportUrl()}/weekly_harvest`;
  getWorkerHoursUrl = () => `${this.getReportUrl()}/worker_hours`;
  getMaintenanceTasksUrl = () => `${this.getReportUrl()}/maintenance_tasks`;
  getHarvestIncentiveReportUrl = () => `${this.getReportUrl()}/harvest_incentive`;
  getHarvestMaintenanceSummaryReportUrl = () => `${this.getReportUrl()}/harvest_maintenance_summary`;
  getDailyPerformanceReportUrl = () => `${this.getReportUrl()}/daily_performance`;
  getHarvestPackagingCostReportUrl = () => `${this.getReportUrl()}/harvest_packaging_cost`;
  getWeeklyPerformanceReportUrl = () => `${this.getReportUrl()}/weekly_performance`;
  getPlantsHarvestReportUrl = () => `${this.getReportUrl()}/plants_harvest`;
  getWorkableTasksReportUrl = () => `${this.getReportUrl()}/workable_tasks`;
  getWorkLogReportUrl = () => `${this.getReportUrl()}/work_log`;

  getPerformanceReport = (campaignId: string): Promise<IPerformanceReport> =>
    lastValueFrom(this.http.get<IPerformanceReport>(this.getPerformanceReportUrl(campaignId)));

  upsertPerformanceReport = (campaignId: string): Promise<IPerformanceReport> =>
    lastValueFrom(this.http.put<IPerformanceReport>(this.getPerformanceReportUrl(campaignId), {}));


  getReport = <Type>(pagination: Pagination, url: string): Promise<Type> => lastValueFrom(this.http.get<Type>(url, { params: pagination.loadHttpParams() }));

  getHarvestReport = pagination => this.getReport<ITaskHarvestReport>(pagination, this.getHarvestReportUrl());
  getHarvestWeekyReport = pagination => this.getReport(pagination, this.getWeeklyHarvestReportUrl());
  getWorkerHoursReport = pagination => this.getReport(pagination, this.getWorkerHoursUrl());
  getMaintenanceTasksReport = pagination => this.getReport(pagination, this.getMaintenanceTasksUrl());
  getHarvestIncentiveReport = pagination => this.getReport(pagination, this.getHarvestIncentiveReportUrl());
  getHarvestMaintenanceSummaryReport = pagination => this.getReport(pagination, this.getHarvestMaintenanceSummaryReportUrl());
  getDailyPerformanceReport = pagination => this.getReport(pagination, this.getDailyPerformanceReportUrl());
  getWeeklyPerformanceReport = pagination => this.getReport(pagination, this.getWeeklyPerformanceReportUrl());
  getHarvestPackagingCostReport = pagination => this.getReport(pagination, this.getHarvestPackagingCostReportUrl());
  getPlantsHarvestReport = pagination => this.getReport(pagination, this.getPlantsHarvestReportUrl());
  getWorkableTasksReport = pagination => this.getReport(pagination, this.getWorkableTasksReportUrl());
  getWorkLogReport = pagination => this.getReport(pagination, this.getWorkLogReportUrl());

  downloadReport = (pagination: Pagination, url: string): Promise<Blob> => lastValueFrom(this.http.get(url, { responseType: 'blob', params: pagination.loadHttpParams() }));

  downloadHarvestWeeklyReport = pagination => this.downloadReport(pagination, this.getWeeklyHarvestReportUrl());
  downloadWorkerHoursReport = pagination => this.downloadReport(pagination, this.getWorkerHoursUrl());
  downloadMaintenanceTasksReport = pagination => this.downloadReport(pagination, this.getMaintenanceTasksUrl());
  downloadHarvestIncentiveReport = pagination => this.downloadReport(pagination, this.getHarvestIncentiveReportUrl());
  downloadHHarvestMaintenanceSummaryReport = pagination => this.downloadReport(pagination, this.getHarvestMaintenanceSummaryReportUrl());
  downloadDailyPerformanceReport = pagination => this.downloadReport(pagination, this.getDailyPerformanceReportUrl());
  downloadWeeklyPerformanceReport = pagination => this.downloadReport(pagination, this.getWeeklyPerformanceReportUrl());
  downloadHarvestPackagingCostReport = pagination => this.downloadReport(pagination, this.getHarvestPackagingCostReportUrl());
  downloadPlantsHarvestReport = pagination => this.downloadReport(pagination, this.getPlantsHarvestReportUrl());
  downloadWorkableTasksReport = pagination => this.downloadReport(pagination, this.getWorkableTasksReportUrl());
  downloadWorkLogReport = pagination => this.downloadReport(pagination, this.getWorkLogReportUrl());

  private createObject = (getReport, downloadReport) => ({ getReport, downloadReport });

  // tslint:disable-next-line: member-ordering
  private methodsByTag = new Map<string, { getReport: (Pagination) => Promise<IGenericReport>, downloadReport: (Pagination) => Promise<Blob> }>([
    [REPORTS_ROUTES.harvestWeekly, this.createObject(this.getHarvestWeekyReport, this.downloadHarvestWeeklyReport)],
    [REPORTS_ROUTES.harvestIncentive, this.createObject(this.getHarvestIncentiveReport, this.downloadHarvestIncentiveReport)],
    [REPORTS_ROUTES.harvestMaintenanceSummary, this.createObject(this.getHarvestMaintenanceSummaryReport, this.downloadHHarvestMaintenanceSummaryReport)],
    [REPORTS_ROUTES.maintenanceTasks, this.createObject(this.getMaintenanceTasksReport, this.downloadMaintenanceTasksReport)],
    [REPORTS_ROUTES.workerHours, this.createObject(this.getWorkerHoursReport, this.downloadWorkerHoursReport)],
    [REPORTS_ROUTES.dailyPerformance, this.createObject(this.getDailyPerformanceReport, this.downloadDailyPerformanceReport)],
    [REPORTS_ROUTES.weeklyPerformance, this.createObject(this.getWeeklyPerformanceReport, this.downloadWeeklyPerformanceReport)],
    [REPORTS_ROUTES.harvestPackagingCost, this.createObject(this.getHarvestPackagingCostReport, this.downloadHarvestPackagingCostReport)],
    [REPORTS_ROUTES.plantsHarvest, this.createObject(this.getPlantsHarvestReport, this.downloadPlantsHarvestReport)],
    [REPORTS_ROUTES.workableTasks, this.createObject(this.getWorkableTasksReport, this.downloadWorkableTasksReport)],
    [REPORTS_ROUTES.workLog, this.createObject(this.getWorkLogReport, this.downloadWorkLogReport)],
  ]);

  getMethodsByTag = (route: string) => this.methodsByTag.get(route) || this.createObject(undefined, undefined);

}
