import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) {}

    getDataUrl = (): string => `${this.apiService.getBaseUrl()}/users`;

    getUserMe = (): Promise<any> => lastValueFrom(this.http.get(`${this.getDataUrl()}/me`).pipe(map(this.parseUser)));

    private parseUser = userData => {
        return new User(
            userData.username,
            userData.email,
            userData.role
        );
    }
}