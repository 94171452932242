import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WorkingDay } from '@shared/models/working-day.model';
import { WorkingDayService } from '@shared/services/working-day.service';

@Injectable({
    providedIn: 'root'
})
export class CampaignsDetailWorkingDayResolver implements Resolve<WorkingDay> {

    constructor(
        private workingDayService: WorkingDayService
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Promise<WorkingDay> {
        const campaignId = activatedRoute.parent.parent.params.campaignId;
        const workingDayId = activatedRoute.params.id;
        return this.workingDayService.getWorkingDay(campaignId, workingDayId);
    }
    
}