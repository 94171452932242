import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";

import { IDeviceHelp } from "@routes/devices/device.model";
import { DeviceService } from "@routes/devices/device.service";

@Injectable({
    providedIn: 'root'
})
export class HelpResolver implements Resolve<IDeviceHelp> {

    constructor(
        private deviceService: DeviceService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IDeviceHelp> {
        return this.deviceService.getDeviceFAQ();
    }

}
