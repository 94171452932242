import { IAgency } from '@models/agency.model';
import { ICampaign } from '@routes/campaigns/campaign.model';
import { IWorkingSite } from '@routes/working-sites/working-site.model';

export interface IWorkerSent {
    name: string;
    surname: string;
    address: string;
    taxResidence: string;
    iban: string;
    nationality: string;
    country: string;
    nif: string;
    status: string;
    promoter: boolean;
    agencyId?: string;
    campaignId?: string;
    keychainId?: string;
    positionId?: string;
    recommendedBy?: string;
}

export class WorkerSent {
    name: string;
    surname: string;
    address: string;
    taxResidence: string;
    iban: string;
    nationality: string;
    country: string;
    nif: string;
    status: string;
    promoter: boolean;
    agencyId?: string;
    campaignId?: string;
    keychainId?: string;
    positionId?: string;
    recommendedBy?: string;

    constructor(worker: IWorkerSent) {
        Object.assign(this, worker);
    }
}

export interface IWorkerRecommendedBy {
    _id?: string;
    name: string;
    surname: string;
    nif: string;
}

export interface IWorkerPosition {
    _id: string;
    name: string;
}

export interface IWorkerKeychain {
    _id?: string;
    code: number;
    tag: string;
}

export interface IWorkerAddressHistoric {
    address: string;
    startDate: Date;
    endDate: Date;
}

export interface IWorkerPositionHistoric {
    positionId: string;
    name: string;
    startDate: Date;
    endDate?: Date;
}

export interface IWorkerCampaignHistoric {
    campaignId: string;
    startDate: Date;
    endDate?: Date;
    site: ICampaign;
}

export interface IWorkerAgencyHistoric {
    agencyId: string;
    startDate: Date;
    endDate?: Date;
    agency: IAgency;
}

export interface IWorkerSiteHistoric {
    siteId: string;
    startDate: Date;
    endDate?: Date;
    agency: IWorkingSite;
}

export interface IWorkerReceived {
    _id?: string;
    id: string;
    sequentialId: number;
    alphanumericCode: string;
    name: string;
    surname: string;
    promoter: boolean;
    status: string;
    country: string;
    nif?: string;
    nationality?: string;
    taxResidence?: string;
    iban?: string;
    address?: string;
    agency?: IAgency;
    campaign?: ICampaign;
    position?: IWorkerPosition;
    site?: IWorkingSite;
    recommendedBy?: IWorkerRecommendedBy;
    keychain?: IWorkerKeychain;
    addresses: IWorkerAddressHistoric[];
    positions: IWorkerPositionHistoric[];
    agencies: IWorkerAgencyHistoric[];
    campaigns: IWorkerCampaignHistoric[];
    sites: IWorkerSiteHistoric[];
}

export class WorkerReceived {
    _id?: string;
    id: string;
    sequentialId: number;
    alphanumericCode: string;
    name: string;
    surname: string;
    promoter: boolean;
    status: string;
    country: string;
    birthDate: string;
    gender: string;
    maritalStatus: string;
    socialSecurityNumber: string;
    phoneNumber: string;
    nif?: string;
    nationality?: string;
    taxResidence?: string;
    iban?: string;
    address?: string;
    agency?: IAgency;
    campaign?: ICampaign;
    position?: IWorkerPosition;
    site?: IWorkingSite;
    recommendedBy?: IWorkerRecommendedBy;
    keychain?: IWorkerKeychain;
    addresses: IWorkerAddressHistoric[];
    positions: IWorkerPositionHistoric[];
    agencies: IWorkerAgencyHistoric[];
    campaigns: IWorkerCampaignHistoric[];
    sites: IWorkerSiteHistoric[];

    constructor(worker: IWorkerReceived) {
        Object.assign(this, worker);
    }
}

export interface IWorkerReceivedGroup {
    _id?: string;
    id: string;
    sequentialId: number;
    alphanumericCode: string;
    name: string;
    surname: string;
    status: string;
    promoter: boolean;
    country: string;
    nationality?: string;
    nif?: string;
    address?: string;
    taxResidence?: string;
    iban?: string;
    recommendedBy?: string;
    agencyId?: string;
    campaignId?: string;
    siteId?: string;
    agency?: IAgency;
    campaign?: ICampaign;
    site?: IWorkingSite;
    keychain?: IWorkerKeychain;
    position?: IWorkerPosition;
}

export class WorkerReceivedGroup implements IWorkerReceivedGroup {
    _id?: string;
    id: string;
    sequentialId: number;
    alphanumericCode: string;
    name: string;
    surname: string;
    status: string;
    country: string;
    promoter: boolean;
    nationality?: string;
    nif?: string;
    address?: string;
    taxResidence?: string;
    iban?: string;
    recommendedBy?: string;
    agencyId?: string;
    campaignId?: string;
    siteId?: string;
    agency?: IAgency;
    campaign?: ICampaign;
    site?: IWorkingSite;
    keychain?: IWorkerKeychain;
    position?: IWorkerPosition;

    constructor(worker: IWorkerReceivedGroup) {
        Object.assign(this, worker);
    }
}

export interface IWorkerPaginated {
    workers: IWorkerReceivedGroup[];
    total: number;
}

export interface IWorkerFileResponse {
    createdWorkers: WorkerReceived[];
    warnings: { msg: string }[];
    error: { msg: string }[];
}