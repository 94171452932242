import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignService } from '@routes/campaigns/campaign.service';

@Injectable({
    providedIn: 'root'
})
export class CampaignResolver implements Resolve<Campaign> {

    constructor(
        private campaignService: CampaignService
    ) {}

    resolve(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Promise<Campaign> {
        const id = activatedRouteSnapshot.params.campaignId;
        return this.campaignService.getCampaign(id);
    }

}