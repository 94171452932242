import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { Position } from '@routes/positions/positions.models';
import { PositionsMainService } from '@routes/positions/positions-main.service';

import { FiltersButtonLink, FiltersConfiguration } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { DeleteDialogComponent } from '@shared/widgets/delete-dialog/delete-dialog.component';
import { filter } from 'rxjs/operators';
import { PositionsRoutingService } from '@routes/positions/positions-routing.service';

@Component({
  selector: 'app-positions-table',
  templateUrl: './positions-table.component.html',
  styleUrls: ['./positions-table.component.scss']
})
export class PositionsTableComponent implements OnInit {

  tableConfig: TableConfiguration<Position>;
  filtersConfig: FiltersConfiguration;
  i18nDialog = 'positions.dialog.delete';

  constructor(
    private positionsMainService: PositionsMainService,
    private positionsRoutingService: PositionsRoutingService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initFilters();
    this.initTable();
  }

  getPositions = (pagination: Pagination) => this.positionsMainService.getAll(pagination)
    .then(response => this.tableConfig.data.next({data: response.positions, total: response.total}))
    .catch(error => this.snackbarService.error(error.error.message));

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [],
    buttons: [
      new FiltersButtonLink({
        text: 'ADD',
        icon: 'add',
        link: this.positionsRoutingService.add()
      })
    ]
  })

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'positions.table',
    data: new BehaviorSubject({data: null, total: 0}),
    pagination: {
      size: 10, page: 1, search: '',
      params: {deleted: false},
      sort: {field: 'name', order: 1}
    },
    columns: [
      new TableColumnText({
        header: 'NAME',
        name: 'name',
        sorting: 'name',
        displayAt: 0,
        value: position => position.name
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            link: position => this.positionsRoutingService.edit(position._id)
          }),
          new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: position => this.displayDeleteDialog(position)
          })
        ]
      })
    ]
  })

  displayDeleteDialog = (position: Position) => this.dialog.open(DeleteDialogComponent, {
    data: {
      title: `${this.i18nDialog}.TITLE`,
      msg: this.translateService.instant(`${this.i18nDialog}.BODY`, {position})
    },
    width: '520px'
  }).afterClosed()
    .pipe(filter(result => result?.confirmDelete))
    .subscribe(() => this.positionsMainService.delete(position._id)
      .then(() => {
        this.getPositions(this.tableConfig.pagination as Pagination);
        const message = this.translateService.instant(`${this.i18nDialog}.SUCCESSFUL_DELETION`);
        this.snackbarService.success(message);
      })
      .catch(error => this.snackbarService.error(error.error.message)));

}
