import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Campaign, CampaignSite, CampaignZone } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { CropService } from '@routes/crops/crop.service';
import { ICrop } from '@routes/crops/crop.model';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { FORM_MODES } from '@shared/constants/forms';
import { Pagination } from '@shared/models/pagination.model';

@Component({
  selector: 'app-campaigns-detail-zones-form',
  templateUrl: './campaigns-detail-zones-form.component.html',
  styleUrls: ['./campaigns-detail-zones-form.component.scss']
})
export class CampaignsDetailZonesFormComponent implements OnInit {

  @ViewChild('crop', { static: true }) crop: ElementRef;

  mode: FORM_MODES;
  campaign: Campaign;
  site: CampaignSite;
  zone: CampaignZone;
  form: FormGroup;
  title: string;

  crops: ICrop[] = [];
  pagination = new Pagination({
    page: 1,
    size: 5
  });

  constructor(
    private cropService: CropService,
    private plannerService: CampaignPlannerService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private campaignService: CampaignService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.campaign = this.activatedRoute.snapshot.data.data.campaign;
    this.site = this.activatedRoute.snapshot.data.data.site;
    this.zone = this.activatedRoute.snapshot.data.data.zone;
    this.mode = this.activatedRoute.snapshot.data.mode;

    this.plannerService.setCampaignBasic(this.campaign);
    this.plannerService.selectSite(this.site.siteId);

    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      crop: new FormControl(null, Validators.required),
      area: new FormControl(null, [Validators.required, Validators.min(0), Validators.pattern(/^\d+([.]{1}\d+)*$/)]),
      plants: new FormControl(null, [Validators.required, Validators.min(0), Validators.pattern(/^\d+$/)])
    });

    if (this.mode === FORM_MODES.edit) {
      const crop = this.activatedRoute.snapshot.data.data.crop;
      this.form.patchValue({ ...this.zone, crop });
      this.plannerService.selectZone(this.zone.sequentialId);
    }
    this.setEvents();
    this.setTitle();
  }

  setEvents = () => {
    fromEvent(this.crop.nativeElement, 'input').pipe(debounceTime(250)).subscribe((event: InputEvent) => this.searchCrops((<HTMLInputElement>event.target).value));
    fromEvent(this.crop.nativeElement, 'input').subscribe(() => this.form.get('crop').setErrors({ incorrect: true }));
  }

  setTitle = () => this.title = this.translateService.instant(this.mode === FORM_MODES.add
    ? 'campaigns.detail.ADD_ZONE'
    : 'campaigns.detail.EDIT_ZONE');

  displayCrop = (crop: ICrop) => crop ? `${crop.name} (${crop.variety})` : '';

  searchCrops = (search: string) => {
    this.pagination.search = search;
    this.cropService.getCrops(this.pagination).then(result => this.crops = result.data);
  };

  selectCrop = (event: MatAutocompleteSelectedEvent) => {
    this.form.get('crop').setValue(event.option.value);
    this.form.get('crop').updateValueAndValidity(); 
  }

  submitForm = () => {
    if (!this.form.valid) return;

    const crop: ICrop = this.form.value.crop;
    const cropId = crop._id;
    const cropName = crop.name;
    const cropVariety = crop.variety;

    const zone = new CampaignZone({ ...this.form.value, cropId, cropName, cropVariety });

    this.mode === FORM_MODES.edit
      ? this.plannerService.editZone({ ...zone, sequentialId: this.zone.sequentialId, traceCode: this.zone.traceCode })
      : this.plannerService.addZone(zone, this.site.siteId);

    this.campaignService.editCampaign(this.plannerService.campaign)
      .then(() => this.router.navigate([this.getExitRoute()], { relativeTo: this.activatedRoute }))
      .catch(error => this.snackbarService.open(SNACKBAR_TYPES.ERROR, error.message));
  }

  getExitRoute = () => this.mode === FORM_MODES.add ? '../..' : '../../..';

}
