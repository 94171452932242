import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ABS_BASE_ROUTE, SECTIONS } from '@shared/constants/routes';
import { USERS_FORM_MODES, USERS_FORM_TITLES, USERS_ROLES } from '@shared/constants/users';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { User } from '../user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {

  USERS_ROLES = USERS_ROLES;
  USERS_FORM_MODES = USERS_FORM_MODES;
  USERS_FORM_TITLES = USERS_FORM_TITLES;
  EXIT_ROUTE = [ABS_BASE_ROUTE, SECTIONS.users];

  user: User;
  mode: string;
  form: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService
  ) {}

  ngOnInit(): void {
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.user = this.activatedRoute.snapshot.data.user;
    
    this.form = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      repeat: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      role: new FormControl(null, Validators.required)
    });

    if (this.mode !== USERS_FORM_MODES.add) {
      this.form.patchValue(this.user);
      this.form.get('password').clearValidators();
      this.form.get('repeat').clearValidators();
    }
    if (this.mode === USERS_FORM_MODES.detail) this.form.disable();
  }

  getFormTitle = () => this.translateService.instant(this.USERS_FORM_TITLES[this.mode]);

  getEditFunction = (user: User) => this.mode === USERS_FORM_MODES.add
    ? this.userService.addUser(user)
    : this.userService.editUser(this.user._id, user);

  submitForm = () => {
    if (this.form.invalid) return;

    if (this.form.value.password !== this.form.value.repeat) {
      this.snackbarService.error('users.messages.PASSWORD_MISMATCH');
      return;
    };

    const user = new User(this.form.value);
    const fn = this.getEditFunction(user);

    fn
      .then(()=> this.router.navigate(this.EXIT_ROUTE))
      .catch(error => this.snackbarService.error(error.error.message));
  }

}
