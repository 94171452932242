import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BatchEditItemsDialogComponent } from '@routes/batchs/batchs-edit/batchs-edit-items/batch-edit-items-modal/batch-edit-items-dialog.component';
import { createItemFormGroup } from '@routes/batchs/batchs-edit/batchs-edit.utils';
import { IBatchMixedItem, IBatchMixedItemSent } from '@routes/batchs/batchs.model';
import { ItemReceived } from '@routes/items/items.model';
import { BATCH_MIXED_STATUS } from '@routes/batchs/batchs.constants';

import { GenericButtonColor, GenericButtonIcon, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { ExternalBatchPipe } from '@shared/pipes/externalBatch.pipe';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableColumnStyle } from '@shared/components/generic-table/generic-table.constants';
import { FORM_MODES } from '@shared/constants/forms';
import { WeightUnit } from '@shared/constants/common.constants';
import { BatchsEditService } from '../batchs-edit.service';


@Component({
  selector: 'app-batchs-edit-items',
  templateUrl: './batchs-edit-items.component.html',
  styleUrls: ['./batchs-edit-items.component.scss'],
  providers: [ExternalBatchPipe]
})
export class BatchsEditItemsComponent implements OnInit {

  BATCH_MIXED_STATUS = BATCH_MIXED_STATUS;

  items: ItemReceived[];

  tableConfig: TableConfiguration<IBatchMixedItem>;
  form: FormArray;
  status: BATCH_MIXED_STATUS;

  addButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.ADD',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button,
    icon: GenericButtonIcon.Add
  };

  constructor(
    private parentFormGroup: FormGroupDirective,
    private batchsEditService: BatchsEditService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.status = this.parentFormGroup.control.get('basic.status').value;
    this.form = this.parentFormGroup.control.get('items') as FormArray;
    this.items = this.batchsEditService.data.items;
    this.initTable();

    this.form.valueChanges.subscribe(items =>
      this.tableConfig.data.next({ data: items, total: items.length }));
  }

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({
      data: this.form.value,
      total: this.form.value.length
    }),
    pagination: {
      page: 1, size: 10, search: null,
      sort: { field: 'sageReference', order: 1 }
    },
    columns: [
      new TableColumnText({
        header: 'SAGE_REFERENCE',
        name: 'sageReference',
        sorting: 'sageReference',
        displayAt: 0,
        value: item => item.sageReference
      }),
      new TableColumnText({
        header: 'DESCRIPTION',
        name: 'description',
        sorting: 'description',
        displayAt: 1024,
        value: item => item.description
      }),
      new TableColumnText({
        header: 'CATEGORY',
        name: 'category',
        sorting: 'category',
        displayAt: 1280,
        value: item => item.category
      }),
      new TableColumnText({
        header: 'BOXES',
        name: 'boxes',
        sorting: 'boxes',
        displayAt: 520,
        value: item => item.boxes
      }),
      new TableColumnText({
        header: 'WEIGHT_NET',
        name: 'weight',
        sorting: 'weight',
        displayAt: 640,
        value: item => `${item.weight} ${WeightUnit.Kilogram}`
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            action: item => this.editItemDialog(item)
          }),
          new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: item => this.deleteItemDialog(item)
          })
        ]
      })
    ]
  });

  addItemDialog = () => {
    this.dialog.open(BatchEditItemsDialogComponent, {
      data: { batchItems: this.form.value, mode: FORM_MODES.add },
      width: '420px', autoFocus: false
    }).afterClosed().pipe(filter(item => item)).subscribe(item => {
      const formGroup = createItemFormGroup(item);
      this.form.push(formGroup);
    });
  }

  editItemDialog = (item: IBatchMixedItem) => {
    const index = this.form.value.findIndex((i: IBatchMixedItemSent) => i._id === item._id);

    this.dialog.open(BatchEditItemsDialogComponent, {
      data: { item, batchItems: this.form.value, mode: FORM_MODES.edit },
      width: '420px', autoFocus: false
    }).afterClosed()
      .pipe(filter(item => item))
      .subscribe(item => this.form.at(index).patchValue(item));
  }

  deleteItemDialog = (item: IBatchMixedItem) => {
    const index = this.form.value.findIndex((i: IBatchMixedItemSent) => i._id === item._id);

    this.dialog.open(BatchEditItemsDialogComponent, {
      data: { mode: FORM_MODES.delete },
      width: '520px'
    }).afterClosed()
      .pipe(filter(remove => remove))
      .subscribe(() => this.form.removeAt(index));
  }

}
