export enum BATCH_EDIT_TAB_NAMES {
    Basic = 'basic',
    Batchs = 'batchs',
    Items = 'items'
};

export const BATCH_EDIT_TAB_INDEX = {
    [BATCH_EDIT_TAB_NAMES.Basic]: 0, 
    [BATCH_EDIT_TAB_NAMES.Batchs]: 1, 
    [BATCH_EDIT_TAB_NAMES.Items]: 2, 
};
