<div class="pb-3">
  <h2 class="title mb-3">{{ (title || 'general.dialogs.delete.TITLE') | translate }}</h2>
  <div class="dialog-body" [innerHTML]="'<p>' + ((msg || 'general.dialogs.delete.BODY') | translate) + '</p>'"></div>
  
  <mat-dialog-actions class="d-flex justify-content-between">
    <button mat-flat-button mat-dialog-close color="warn" class="actions-button btn" type="button" (click)="confirm(false)">
      {{ (cancelButtonText || 'general.actions.CANCEL') | translate }}
    </button>
    <button mat-flat-button color="primary" class="actions-button btn" type="button" (click)="confirm(true)">
      {{ (confirmButtonText || 'general.actions.CONFIRM') | translate }}
    </button>
  </mat-dialog-actions>
</div>