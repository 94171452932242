<form class="form" [formGroup]="form" (submit)="submitForm()">
    <h2 class="title h2">Confirmación de estado</h2>
    <p class="text">{{ 'batchs.edit.status.dialog.BODY_1' | translate }}</p>
    <p class="text">{{ 'batchs.edit.status.dialog.BODY_2' | translate }}</p>
    <mat-form-field class="input" appearance="fill">
        <mat-label>{{ 'batchs.edit.main.STATUS' | translate }}</mat-label>
        <mat-select formControlName="status">
            <mat-option *ngFor="let status of BATCH_MIXED_STATUS_EDIT" [value]="status">
                {{ 'batchs.status.' + status.toUpperCase() | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="alert alert-warning">
        <p class="text" [innerHTML]="'batchs.edit.status.dialog.BODY_3' | translate"></p>
    </div>
    <mat-dialog-actions class="buttons">
        <app-generic-button
            [config]="cancelButtonConfig" mat-dialog-close
        ></app-generic-button>
        <app-generic-button
            [config]="submitButtonConfig"
        ></app-generic-button>
    </mat-dialog-actions>
</form>
