import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericFiltersService } from '@shared/components/generic-filters/generic-filters-service.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: [
    './campaigns.component.scss',
    './../../shared/styles/dashboard.scss'
  ]
})
export class CampaignsComponent implements OnInit, OnDestroy {

  constructor(
    private genericFiltersService: GenericFiltersService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.genericFiltersService.removeFiltersValue();
  }

}
