import { Injectable } from '@angular/core';
import { StorageService } from '@core/storage/storage.service';
import { Observable, Subject } from 'rxjs';
import { FILTER_STORAGE_KEY, FILTER_VALUES } from './header.constants';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    private typeFilterSubject = new Subject<string>();

    get typeFilter(): string { return this.storageService.getItem(FILTER_STORAGE_KEY); }

    constructor(
        private storageService: StorageService
    ) { }

    setTypeFilter = (typeFilter: string) => {
        typeFilter === FILTER_VALUES.BOTH
            ? this.storageService.removeItem(FILTER_STORAGE_KEY)
            : this.storageService.setItem(FILTER_STORAGE_KEY, typeFilter);
        this.typeFilterSubject.next(this.typeFilter);
    }

    typeFilterChanges = (): Observable<string> => this.typeFilterSubject.asObservable();
}
