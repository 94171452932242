<app-search (evtSearch)="search($event)"></app-search>
<ul class="button-container">
  <li>
    <button id="new-task-button" mat-flat-button color="primary" class="actions-button btn" type="button"
      (click)="addTask()">
      <img class="btn-image" src="/assets/images/icons/add.svg" alt="{{ 'general.alt.ADD' | translate }}"/>
      <span class="btn-text">{{ 'general.actions.NEW' | translate }}</span>
    </button>
  </li>
</ul>
<app-tasks-table [searchPattern]="searchPattern"></app-tasks-table>
