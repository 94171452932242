<div class="wrapper" [ngClass]="filtersConfig.layout">
    <!-- Searcher -->
    <div class="searcher" *ngIf="filtersConfig.search">
        <img class="icon" src="/assets/images/icons/search.svg"/>
        <mat-form-field class="control">
            <mat-label>{{ 'general.filters.SEARCHER' | translate }}</mat-label>
            <input matInput #searcher type="text" [(ngModel)]="searchPattern">
        </mat-form-field>
    </div>
    <!-- Buttons -->
    <div class="buttons {{ filtersConfig.layout }}" *ngIf="filtersConfig.buttons.length" [ngClass]="{ 'buttons-bottom': !filtersConfig.search }">
        <ng-container *ngFor="let button of filtersConfig.buttons">
            <ng-container *ngIf="!button.hidden || (button.hidden && !button.hidden())">
                <button *ngIf="button.type === ButtonType.Action" mat-flat-button color="primary" class="actions-button btn button" type="button" [disabled]="button.disabled && button.disabled()" (click)="button.action()">
                    <img *ngIf="button.icon" class="btn-image" src="/assets/images/icons/{{ button.icon }}.svg" alt="#"/>
                    <span class="btn-text">{{ 'general.actions.' + button.text | translate }}</span>
                </button>
                <a *ngIf="button.type === ButtonType.Link" mat-flat-button color="primary" class="actions-button btn button" type="button" [disabled]="button.disabled && button.disabled()" [routerLink]="button.link">
                    <img *ngIf="button.icon" class="btn-image" src="/assets/images/icons/{{ button.icon }}.svg" alt="#"/>
                    <span class="btn-text">{{ 'general.actions.' + button.text | translate }}</span>
                </a>
            </ng-container>
        </ng-container>
    </div>
    <!-- Filters -->
    <form [formGroup]="form"  *ngIf="filtersConfig.controls.length" class="filters {{ filtersConfig.layout }}" [ngClass]="{ 'side-with-searcher': filtersConfig.search && !filtersConfig.buttons.length, 'filters-top': !filtersConfig.search }">
        <ng-container *ngFor="let control of filtersConfig.controls">
            <!-- Select -->
            <mat-form-field class="select filter-field" appearance="fill" *ngIf="control.type === FilterType.Select">
                <mat-label>{{ 'general.filters.' + control.label | translate }}</mat-label>
                <mat-select [formControlName]="control.name" [multiple]="control.multiple">
                    <mat-select-trigger>{{ getSelectorTriggerText(control.name) }}</mat-select-trigger>
                    <mat-option *ngFor="let option of options[control.name]" [value]="option.value">
                        {{ option.display }}
                    </mat-option>
                </mat-select>
                <mat-hint class="hint" *ngIf="control.hint">{{  'general.hints.' + control.hint | translate }}</mat-hint>
            </mat-form-field>
            <!-- Group Select -->
            <mat-form-field class="select filter-field" appearance="fill" *ngIf="control.type === FilterType.GroupSelect">
                <mat-label>{{ 'general.filters.' + control.label | translate }}</mat-label>
                <mat-select
                [formControlName]="control.name"
                [multiple]="control.multiple"
                panelClass="select-auto-width"
                >
                    <mat-optgroup *ngFor="let group of groupOptions[control.name]" [label]="group.name">
                        <mat-option *ngFor="let option of group.options" [value]="option.value">
                            {{ option.display }}
                        </mat-option>
                  </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <!-- Checkbox -->
            <mat-checkbox *ngIf="control.type === FilterType.Checkbox" [formControlName]="control.name"
                [checked]="control.value" [color]="GenericButtonColor.Primary">{{ 'general.filters.' + control.label | translate }}</mat-checkbox>
            <!-- Date -->
            <mat-form-field class="filter-field" appearance="fill" *ngIf="control.type === FilterType.Date" [ngClass]="{ 'thin-date-filter': control.timeSelector }">
                <mat-label>{{ 'general.filters.' + control.label | translate }}</mat-label>
                <input matInput [matDatepicker]="date" [formControlName]="control.name" [min]="control.minDate" [max]="control.maxDate"
                    appUpdateHour [timeControlReference]="control.timeReferenceName" [modifier]="control.modifier"
                    appDateComparison [minDateControlName]="control.minDateReferenceName" [maxDateControlName]="control.maxDateReferenceName"
                >
                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
                <mat-hint class="hint" *ngIf="control.hint">{{  'general.hints.' + control.hint | translate }}</mat-hint>
                <mat-error *ngIf="form.get(control.name).errors as errors">
                    <ng-container *ngIf="errors.minValue">
                        {{ 'general.filters.errors.DATE_LOWER_THAN_MIN_VALUE' | translate }}
                    </ng-container>
                    <ng-container *ngIf="errors.maxValue">
                        {{ 'general.filters.errors.DATE_GREATER_THAN_MAX_VALUE' | translate }}
                    </ng-container>
                </mat-error>
            </mat-form-field>
            <!-- Time -->
            <mat-form-field class="filter-field" appearance="fill"  *ngIf="control.type === FilterType.Time">
                <mat-label class="control">{{ 'general.filters.' + control.label | translate }}</mat-label>
                <input matInput type="text" [pattern]="TIME_REG_EXP.HOUR_MINUTES"
                    appTextToHours [formControlName]="control.name"
                    appUpdateDateControl [dateControlReference]="control.dateReferenceName" >
                <mat-hint class="hint" *ngIf="control.hint">{{  'general.hints.' + control.hint | translate }}</mat-hint>
            </mat-form-field>
        </ng-container>
    </form>
</div>
