import { Component, OnInit } from '@angular/core';
import { WarehousesService } from '@routes/warehouses/warehouses.service';
import { ABS_BASE_ROUTE, WAREHOUSES_ROUTES, SECTIONS } from '@shared/constants/routes';

@Component({
  selector: 'app-warehouses-upload',
  templateUrl: './warehouses-upload.component.html',
  styleUrls: ['./warehouses-upload.component.scss']
})
export class WarehousesUploadComponent implements OnInit {

  backRoute: string[] = [
    ABS_BASE_ROUTE, SECTIONS.production,
    WAREHOUSES_ROUTES.parent
  ];
  uploadConfig = {
    uploadFunction: this.warehousesService.uploadWarehousesFile,
    backRoute: this.backRoute,
    fileFormats: ['csv', 'xls', 'xlsx'],
    tableColumns: [
      {
        header: 'warehouses.table.COMPANY_SAGE_REFERENCE',
        value: 'companySageReference',
        name: 'companySageReference'
      }, {
        header: 'warehouses.table.SAGE_REFERENCE',
        value: 'sageReference',
        name: 'sageReference'
      }, {
        header: 'warehouses.table.WAREHOUSE',
        value: 'warehouse',
        name: 'warehouse'
      },
    ],
    successTab: {
      content: 'warehouses.upload.WAREHOUSES_TAB',
      empty: 'warehouses.upload.NO_WAREHOUSES'
    }
  };

  constructor(
    private warehousesService: WarehousesService
  ) {}

  ngOnInit(): void {
  }

}
