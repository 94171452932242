import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { UploadData, UploadPageConfiguration } from '@shared/interfaces/upload';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-upload-file-page-table',
  templateUrl: './upload-file-page-table.component.html',
  styleUrls: ['./upload-file-page-table.component.scss']
})
export class UploadFilePageTableComponent implements OnInit, AfterViewInit {

  @Input() file: File;
  @Input() records: UploadData;
  @Input() config: UploadPageConfiguration;
  @Input() loading: boolean; 

  @Output() backToUpload: EventEmitter<void> = new EventEmitter<void>();
  @Output() uploadFileAndExit: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  dataSource: MatTableDataSource<any>
  tableColumns: string[];
  enableUpload = false;

  constructor() {}

  ngOnInit(): void {
    this.tableColumns = this.config.tableColumns.map(column => column.name);
    this.dataSource = new MatTableDataSource(this.records.data);
    this.enableUpload = this.records.data.length && !this.records.error.length;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  confirmUpload = () => this.uploadFileAndExit.emit(this.file);

}
