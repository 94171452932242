<div [class]="'snack-bar-body ' + snackbarClass">
  <em [class]="'snack-bar-icon ' + iconClass"></em>
  <h3 class="snack-bar-message">{{ data.message }}</h3>
  <div class="button-group">
    <button
      mat-flat-button
      class="snack-bar-dismiss-button"
      (click)="snackBarRef.dismiss()"
    >
      <em class="fa fa-times"></em>
    </button>
  </div>
</div>
