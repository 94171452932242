<h2 class="h2">{{ 'workers.detail.TITLE' | translate }} - {{worker.name}} {{worker.surname}}</h2>
<div class="row justify-content-md-center">
    <article class="col-xl-7 order-3 order-xl-2">
        <ng-container *ngIf="section === 'general'; else historic">
            <app-workers-detail-form></app-workers-detail-form>
        </ng-container>
        <ng-template #historic>
            <app-workers-detail-historic class="container px-0"></app-workers-detail-historic>
        </ng-template>
    </article>
    <aside class="col-xl-3 order-2 order-xl-3 mb-5 ml-xl-4">
        <mat-form-field appearance="fill">
            <mat-label>{{ 'workers.detail.selector.SELECTOR' | translate }}</mat-label>
            <mat-select [value]="section" (selectionChange)="displayHistoricSection($event)">
                <mat-option value="general">{{ 'workers.detail.selector.GENERAL' | translate }}</mat-option>
                <mat-option *ngFor="let historic of historics" [value]="historic.value">
                    {{ 'workers.detail.selector.' + historic.tag | translate }}
                </mat-option>
            </mat-select>
            <mat-hint>{{ 'workers.detail.selector.HINT' | translate }}</mat-hint>
        </mat-form-field>
    </aside>
</div>
