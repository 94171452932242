import { Injectable } from '@angular/core';
import { ABS_BASE_ROUTE, POSITIONS_ROUTES, SECTIONS } from '@shared/constants/routes';

@Injectable({
  providedIn: 'root'
})
export class PositionsRoutingService {

  constructor() {}

  private parentRoute = [ABS_BASE_ROUTE, SECTIONS.workforce, POSITIONS_ROUTES.parent];

  main = () => [
    ...this.parentRoute];

  add = () => [
    ...this.main(), POSITIONS_ROUTES.add];

  edit = (positionId: string) => [
    ...this.main(), POSITIONS_ROUTES.edit, positionId];

  detail = (positionId: string) => [
    ...this.main(), POSITIONS_ROUTES.edit, positionId];

}
