import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { BatchsComponent } from '@routes/batchs/batchs.component';
import { BatchsDetailComponent } from '@routes/batchs/batchs-detail/batchs-detail.component';
import { BatchsDetailSummaryStatusComponent } from '@routes/batchs/batchs-detail/batchs-detail-summary/batchs-detail-summary-status/batchs-detail-summary-status.component';
import { BatchsDetailSummaryDescriptionComponent } from '@routes/batchs/batchs-detail/batchs-detail-summary/batchs-detail-summary-description/batchs-detail-summary-description.component';
import { BatchsDetailIntervalsComponent } from '@routes/batchs/batchs-detail/batchs-detail-intervals/batchs-detail-intervals.component';
import { BatchsResolver } from '@routes/batchs/batchs.resolver';
import { BatchsDetailSummaryComponent } from '@routes/batchs/batchs-detail/batchs-detail-summary/batchs-detail-summary.component';
import { BatchsDetailUniqueBatchsComponent } from '@routes/batchs/batchs-detail/batchs-detail-unique-batchs/batchs-detail-unique-batchs.component';
import { BatchsDetailItemsComponent } from '@routes/batchs/batchs-detail/batchs-detail-items/batchs-detail-items.component';
import { BatchsSageModalComponent } from '@routes/batchs/batchs-sage-modal/batchs-sage-modal.component';
import { BatchsTableUniqueComponent } from '@routes/batchs/batchs-table-unique/batchs-table-unique.component';
import { BatchsTableMixedComponent } from '@routes/batchs/batchs-table-mixed/batchs-table-mixed.component';
import { BatchsEditComponent } from '@routes/batchs/batchs-edit/batchs-edit.component';
import { BatchsEditItemsComponent } from '@routes/batchs/batchs-edit/batchs-edit-items/batchs-edit-items.component';
import { BatchsEditUniqueBatchsComponent } from '@routes/batchs/batchs-edit/batchs-edit-unique-batchs/batchs-edit-unique-batchs.component';
import { BatchsEditUniqueDialogComponent } from '@routes/batchs/batchs-edit/batchs-edit-unique-batchs/batch-edit-unique-modal/batch-edit-unique-dialog.component';
import { BatchEditItemsDialogComponent } from '@routes/batchs/batchs-edit/batchs-edit-items/batch-edit-items-modal/batch-edit-items-dialog.component';
import { BatchsEditMainComponent } from '@routes/batchs/batchs-edit/batchs-edit-main/batchs-edit-main.component';
import { BatchsEditResolver } from '@routes/batchs/batchs-edit/batchs-edit.resolver';
import { BatchsEditCloseDialogComponent } from '@routes/batchs/batchs-edit/batchs-edit-close-dialog/batchs-edit-close-dialog.component';
import { BATCH_CATEGORY } from '@routes/batchs/batchs.constants';

import { SharedModule } from '@shared/shared.module';
import { BATCHS_ROUTES } from '@shared/constants/routes';
import { FORM_MODES } from '@shared/constants/forms';
import { DataProviderEntity } from '@shared/constants/data-provider.constants';
import { DataProviderResolver } from '@shared/resolvers/data-provider.resolver';
import { BatchsDetailMixedBatchsComponent } from '@routes/batchs/batchs-detail/batchs-detail-mixed-batchs/batchs-detail-mixed-batchs.component';

const routes: Routes = [
  {
    path: '',
    component: BatchsComponent,
    children: [
      {
        path: BATCHS_ROUTES.unique,
        children: [
          {
            path: '',
            component: BatchsTableUniqueComponent,
            pathMatch: 'full',
            data: {
              entities: [DataProviderEntity.Crops, DataProviderEntity.Sites]
            },
            resolve: {
              provider: DataProviderResolver
            }
          },
          {
            path: `:id/${BATCHS_ROUTES.detail}`,
            component: BatchsDetailComponent,
            resolve: { batch: BatchsResolver },
            data: { category: BATCH_CATEGORY.UNIQUE },
            pathMatch: 'full',
          }
        ]
      },
      {
        path: BATCHS_ROUTES.mixed,
        children: [
          {
            path: '',
            component: BatchsTableMixedComponent,
            pathMatch: 'full',
          },
          {
            path: `:id/${BATCHS_ROUTES.detail}`,
            component: BatchsDetailComponent,
            resolve: { batch: BatchsResolver },
            data: { category: BATCH_CATEGORY.MIXED}
          },
          {
            path: `:id/${BATCHS_ROUTES.edit}`,
            component: BatchsEditComponent,
            resolve: {
              batch: BatchsEditResolver
            },
            data: {
              category: BATCH_CATEGORY.MIXED,
              mode: FORM_MODES.edit
            }
          },
          {
            path: BATCHS_ROUTES.add,
            component: BatchsEditComponent,
            resolve: {
              batch: BatchsEditResolver
            },
            data: {
              category: BATCH_CATEGORY.MIXED,
              mode: FORM_MODES.add
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  declarations: [
    BatchsComponent,
    BatchsDetailComponent,
    BatchsDetailIntervalsComponent,
    BatchsDetailSummaryComponent,
    BatchsDetailSummaryStatusComponent,
    BatchsDetailSummaryDescriptionComponent,
    BatchsDetailUniqueBatchsComponent,
    BatchsDetailItemsComponent,
    BatchsEditComponent,
    BatchsEditMainComponent,
    BatchsEditUniqueBatchsComponent,
    BatchsEditUniqueDialogComponent,
    BatchsEditItemsComponent,
    BatchEditItemsDialogComponent,
    BatchsSageModalComponent,
    BatchsTableUniqueComponent,
    BatchsTableMixedComponent,
    BatchsEditCloseDialogComponent,
    BatchsDetailMixedBatchsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class BatchsModule {}
