import { Component, OnInit, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Pagination } from '@shared/models/pagination.model';
import { ITableBasicStruture, ITableButton } from '@shared/interfaces/tables';
import { Observable, Subscription } from 'rxjs';
import { PaginationService } from '@shared/services/pagination.service';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnDestroy {

  pagination: Pagination;
  _tableConfig: ITableBasicStruture;
  _paginationTotal: number;
  _elements: any[];

  private resetPageEventSubscription: Subscription;
  private paramsSubscription: Subscription;

  @Input('tableConfig') set tableConfig(tableConfig: ITableBasicStruture) {
    this._tableConfig = tableConfig;
    this.pagination = new Pagination({
      size: tableConfig.paginationConfig.pagingSizeDefault || 5,
      page: 1,
      sort: {
        field: tableConfig.defaultSorting.field,
        order: tableConfig.defaultSorting.order
      },
      params: tableConfig.searchParams || {}
    });
  }

  @Input('elements') set elements(elements: any[]) {
    this._elements = elements;
  }

  @Input('paginationTotal') set setPaginationTotal(paginationTotal: number) {
    this._paginationTotal = paginationTotal;
  }

  @Input('resetPageEvent') resetPageEvent: Observable<void>;
  @Input('paramsUpdated') paramsUpdated: Observable<Object>;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @Output('reload') reload = new EventEmitter<Pagination>();

  constructor(
    private paginationService: PaginationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.paramsSubscription = this.paramsUpdated?.subscribe(params => this.pagination.params = params);
    this.resetPageEventSubscription = this.resetPageEvent.subscribe(() => this.resetPage());
    this.reload.emit(this.pagination);
  }

  ngOnDestroy() {
    this.resetPageEventSubscription.unsubscribe();
  }

  getProperty = (data: any, path: string) => _.get(data, path);

  resetPage = () => {
    this.pagination.page = 1;
    this.paginator.firstPage();
  }

  paginate = (event: PageEvent) => {
    this.pagination = this.paginationService.paginate(this.pagination, event);
    this.reload.emit(this.pagination);
  }

  sort = (sort: Sort) => {
    this.pagination = this.paginationService.sort(this.pagination, sort);
    this.resetPage();
    this.reload.emit(this.pagination);
  }
  buttonAction = (button: ITableButton, element: any) => {
    if (button.getLink) {
      this.router.navigate(button.getLink(element));
    } else if (button.action) {
      button.action(element);
    }
  }

}
