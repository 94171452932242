import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { WORKING_DAY_STATUSES } from '@shared/constants/working-day';
import { WorkingDay } from '@shared/models/working-day.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { WorkingDayService } from '@shared/services/working-day.service';

@Component({
  selector: 'app-campaigns-detail-working-days-select-state-dialog',
  templateUrl: './campaigns-detail-working-days-select-state-dialog.component.html',
  styleUrls: ['./campaigns-detail-working-days-select-state-dialog.component.scss']
})
export class CampaignsDetailWorkingDaysSelectStateDialogComponent implements OnInit {

  form: FormGroup;
  WORKING_DAY_STATUSES = WORKING_DAY_STATUSES;
  workingDay: WorkingDay;
  campaignId: string;
  avaliableStatuses: string[];
  
  constructor(
    private snackbarService: CustomSnackbarService,
    public dialogRef: MatDialogRef<CampaignsDetailWorkingDaysSelectStateDialogComponent>,
    private translateService: TranslateService,
    private workingDayService: WorkingDayService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.workingDay = this.data.workingDay;
    this.campaignId = this.data.workingDay.campaignId;
    this.form = new FormGroup({
      status: new FormControl(null, Validators.required)
    });
    this.avaliableStatuses = [WORKING_DAY_STATUSES.AGREE, WORKING_DAY_STATUSES.PENDING];
  }

  submitForm = async () => {
    this.workingDay.status = this.form.value.status;
    this.workingDayService.editWorkingDay(this.campaignId, this.workingDay._id, this.workingDay)
      .then(() => this.dialogRef.close())
      .catch(error => this.snackbarService.error(error.error.message));
    
  }

}
