export enum GenericButtonColor {
    Primary = 'primary',
    Warn = 'warn',
    Acent = 'acent'
};

export enum GenericButtonType {
    Submit = 'submit',
    Reset = 'reset',
    Button = 'button'
};

export enum GenericButtonIcon {
    Add = 'add'
};

export enum GenericButtonSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large'
};
