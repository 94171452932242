import { Component, OnInit } from '@angular/core';
import { CampaignPlannerService } from './campaign.planner.service';

@Component({
  selector: 'app-campaigns-planner',
  templateUrl: './campaigns-planner.component.html',
  styleUrls: ['./campaigns-planner.component.scss'],
  providers: []
})
export class CampaignsPlannerComponent implements OnInit {

  section = 'basic';

  constructor() { }

  ngOnInit(): void { }

  changeSection = (section: string): string => this.section = section;
}
