<h2 class="h2 title">{{ 'batchs.edit.main.MAIN' | translate }}</h2>
<form [formGroup]="form" class="form">
    <!-- Code -->
    <mat-form-field class="input code" appearance="standard">
        <mat-label>{{ 'batchs.edit.main.CODE' | translate }}</mat-label>
        <input matInput formControlName="code" type="text" name="code" id="code">
        <mat-hint>{{ 'general.form.AUTO_GENERATED' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Custom Code -->
    <mat-form-field class="input code" appearance="standard">
        <mat-label>{{ 'batchs.edit.main.CUSTOM_CODE' | translate }}</mat-label>
        <input matInput formControlName="customCode" type="text" name="customCode" id="customCode">
    </mat-form-field>
    <!-- Status -->
    <mat-form-field class="input status" appearance="standard">
        <mat-label>{{ 'batchs.edit.main.STATUS' | translate }}</mat-label>
        <mat-select formControlName="status">
            <mat-option *ngFor="let status of BATCH_MIXED_STATUS_EDIT" [value]="status">
                {{ 'batchs.status.' + status.toUpperCase() | translate }}
            </mat-option>
        </mat-select>
        <mat-hint>{{ 'general.form.NOT_EDITABLE_HERE' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Company -->
    <mat-form-field class="input company" appearance="standard">
        <mat-label>{{ 'batchs.edit.main.COMPANY' | translate }}</mat-label>
        <mat-select formControlName="companyId">
            <mat-option *ngFor="let company of companies" [value]="company._id">
                {{ company.businessName }}
            </mat-option>
        </mat-select>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Warehouse -->
    <mat-form-field class="input warehouse" appearance="standard">
        <mat-label>{{ 'batchs.edit.main.WAREHOUSE' | translate }}</mat-label>
        <mat-select formControlName="warehouseId">
            <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse._id">
                {{ warehouse.warehouse }}
            </mat-option>
        </mat-select>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Production line -->
    <mat-form-field class="input production-line" appearance="standard">
        <mat-label>{{ 'batchs.edit.main.PRODUCTION_LINE' | translate }}</mat-label>
        <mat-select formControlName="productionLineId">
            <mat-option *ngFor="let productionLine of productionLines" [value]="productionLine._id">
                {{ productionLine.description }}
            </mat-option>
        </mat-select>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Amount -->
    <mat-form-field class="input amount" appearance="standard">
        <mat-label>{{ 'batchs.edit.main.AMOUNT' | translate }}</mat-label>
        <input matInput formControlName="amount" type="number" name="amount" id="amount">
        <mat-hint *ngIf="batch.status === BATCH_MIXED_STATUS.CLOSED">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Pallet number -->
    <mat-form-field class="input pallet-number" appearance="standard">
        <mat-label>{{ 'batchs.edit.main.PALLET_NUMBER' | translate }}</mat-label>
        <input matInput formControlName="palletNumber" type="number" name="amount" id="amount">
        <mat-hint *ngIf="batch.status === BATCH_MIXED_STATUS.CLOSED">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
</form>
