import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { BATCH_CATEGORY, BATCH_MIXED_STATUS_EDIT } from '@routes/batchs/batchs.constants';
import { IBatchMixedBatch, IBatchMixedReceived } from '@routes/batchs/batchs.model';

import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { ABS_BASE_ROUTE, BATCHS_ROUTES, TRACEABILITY_ROUTES } from '@shared/constants/routes';
import { ExternalBatchPipe } from '@shared/pipes/externalBatch.pipe';

@Component({
  selector: 'app-batchs-detail-unique-batchs',
  templateUrl: './batchs-detail-unique-batchs.component.html',
  styleUrls: ['./batchs-detail-unique-batchs.component.scss'],
  providers:[ExternalBatchPipe]
})
export class BatchsDetailUniqueBatchsComponent implements OnInit {

  @Input() batch: IBatchMixedReceived;

  tableConfig: TableConfiguration<IBatchMixedBatch>;
  editable: boolean;
  editLink: string[] = ['..', BATCHS_ROUTES.edit];
  navigation: string;

  exitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.BACK',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button
  };
  editButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.EDIT_BATCH',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button
  };

  constructor(
    private location: Location,
    private router: Router,
    private externalBatchPipe: ExternalBatchPipe,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setupTable();
    this.setEditable();
    this.activatedRoute.queryParams.subscribe(params => this.navigation = params['navigation']);
  }

  setupTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({ data: this.batch.batchs, total: this.batch.batchs.length }),
    pagination: {
      page: 1, size: 10, search: '', params: {},
      sort: { field: 'code', order: 1 }
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: batch => batch.code
      }),
      new TableColumnText({
        header: 'CROP',
        name: 'crop',
        sorting: 'crop.name',
        displayAt: 0,
        value: batch => this.externalBatchPipe.transform(batch, '{0}', 'crop.name')
      }),
      new TableColumnText({
        header: 'PACKAGING',
        name: 'packaging',
        sorting: 'packaging.description',
        displayAt: 0,
        value: batch => this.externalBatchPipe.transform(batch, '{0}', 'packaging.description')
      }),
      new TableColumnText({
        header: 'BOXES',
        name: 'boxes',
        sorting: 'boxes',
        displayAt: 0,
        value: batch => batch.boxes
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: batch => [ABS_BASE_ROUTE, TRACEABILITY_ROUTES.parent, BATCHS_ROUTES.parent, BATCHS_ROUTES.unique, batch._id, BATCHS_ROUTES.detail],
            disabled: batch => !batch.crop,
            queryParams: {navigation: "previous"} 
          })
        ]
      })
    ]
  });

  setEditable = () => this.editable = BATCH_MIXED_STATUS_EDIT.includes(this.batch.status);

  navigateToPreviousLink = () => this.navigation === 'previous' 
    ? this.location.back() 
    : this.router.navigate(['../..'], { relativeTo: this.activatedRoute })

}
