import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { PositionsMainService } from "@routes/positions/positions-main.service";
import { Position } from "@routes/positions/positions.models";

@Injectable({
    providedIn: 'root'
})
export class PositionsResolver implements Resolve<Position> {

    constructor(
        private positionsMainService: PositionsMainService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Promise<Position> {
        const _id = route.params.positionId;
        return this.positionsMainService.get(_id);
    }

}