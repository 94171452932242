import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WorkingDaysMassEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/working-days-mass-edit.service';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { GenericFiltersService } from '@shared/components/generic-filters/generic-filters-service.service';
import { LayoutDirection } from '@shared/components/generic-filters/generic-filters.constants';
import { FiltersButtonAction, FiltersConfiguration, FiltersControlDate, FiltersControlSelect, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { GenericTableBehaviour, GenericTableElementColor } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnSelector, TableColumnText, TableConfiguration } from '@shared/components/generic-table/generic-table.model';
import { Pagination } from '@shared/models/pagination.model';
import { WorkingDay } from '@shared/models/working-day.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { WorkingDayService } from '@shared/services/working-day.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-campaigns-detail-working-days-mass-edit-selector',
  templateUrl: './campaigns-detail-working-days-mass-edit-selector.component.html',
  styleUrls: ['./campaigns-detail-working-days-mass-edit-selector.component.scss']
})
export class CampaignsDetailWorkingDaysMassEditSelectorComponent implements OnInit {

  selectedWorkingDays: Map<string, WorkingDay> = new Map();
  filtersConfig: FiltersConfiguration;
  areFiltersValid = false;
  loading = false;
  filtersValue: object;
  workingDays: WorkingDay[] = [];
  tableConfig: TableConfiguration<WorkingDay>;

  selectedDevice: string;
  filters: any;

  constructor(
    private plannerService: CampaignPlannerService,
    private workingDayService: WorkingDayService,
    private datepipe: DatePipe,
    private translateService: TranslateService,
    private filterService: GenericFiltersService,
    private massEditService: WorkingDaysMassEditService,
    private snackbarService: CustomSnackbarService
  ) { }

  ngOnInit(): void {
    this.setupFilters();
    this.setupTable();
  }

  ngOnDestroy(): void {
    this.filterService.removeFiltersValue();
  }

  setupFilters = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.filtersConfig = new FiltersConfiguration({
      search: false,
      layout: LayoutDirection.Vertical,
      controls: [
        new FiltersControlDate({
          label: 'DATE',
          name: 'startDate',
          value: today,
          hint: 'REQUIRED',
        }),
        new FiltersControlSelect({
          label: 'DEVICES',
          name: 'deviceId',
          value: null,
          multiple: false,
          hint: 'REQUIRED',
          validatorFn: Validators.required,
          options: new BehaviorSubject(this.plannerService.devices.map(device => ({ value: device._id, display: device.name })))
        })],
      buttons: [
        new FiltersButtonAction({
          text: 'SEARCH_WORKING_DAYS',
          action: () => this.retrieveWorkingDays(),
          disabled: () => !this.areFiltersValid || this.loading
        }),
      ]
    });
  }

  retrieveWorkingDays = async () => {
    this.selectedWorkingDays.clear();
    this.filters = Object.assign({}, ...Object.entries(this.filtersValue).filter(([key, value]) => Array.isArray(value) ? value.length : true).map(([key, value]) => ({ [key]: value })));
    const endDate = new Date(this.filters.startDate).setDate(this.filters.startDate.getDate() + 1)
    const pagination = new Pagination({
      page: 1, size: Number.MAX_SAFE_INTEGER, search: '', params: {deviceIds: this.filters.deviceId, startDate: this.filters.startDate, endDate: new Date(endDate)},
      sort: { field: 'startTimestamp', order: -1 }
    })

    try {
      this.loading = true;
      const data = await this.workingDayService.getWorkingDays(this.plannerService.campaign._id, pagination);
      this.workingDays = data.workingDays;
      this.tableConfig.data.next({ data: data.workingDays, total: data.total });
    } catch (error) {
      this.snackbarService.error(this.translateService.instant('campaigns.workingDays.mass_edit_dialog.ERROR_LOADING'));
    } finally {
      this.loading = false;
    }
    this.selectedDevice = this.plannerService.devices.filter(device => this.filters.deviceId.includes(device._id))[0].name;
    this.massEditService.setFilters(this.filters);
    this.massEditService.setSelectedDevice(this.selectedDevice);
  }

  updateFilters = (event: FiltersEvent) => this.filtersValue = event.params;

  updateSubmitButton = (isValid: boolean) => this.areFiltersValid = isValid;

  setupTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'campaigns.workingDays',
    data: new BehaviorSubject({ data: this.workingDays, total: this.workingDays.length }),
    pagination: {
      page: 1, size: 5, search: '', params: null,
      sort: { field: 'startTimestamp', order: -1 }
    },
    columns: [
      new TableColumnSelector({
        name: 'select',
        displayAt: 0,
        color: GenericTableElementColor.Primary,
        selectionProperty: '_id'
      }),  
      new TableColumnText({
        header: 'WORKER',
        name: 'worker',
        sorting: 'worker.name',
        displayAt: 0,
        value: workingDay => `${workingDay.worker.name} ${workingDay.worker.surname}`
      }),
      new TableColumnText({
        header: 'START',
        name: 'startTimestamp',
        sorting: 'startTimestamp',
        displayAt: 520,
        value: workingDay => this.datepipe.transform(workingDay.startTimestamp, 'HH:mm', 'GMT')
      }),
      new TableColumnText({
        header: 'END',
        name: 'endTimestamp',
        sorting: 'endTimestamp',
        displayAt: 960,
        value: workingDay => this.datepipe.transform(workingDay.endTimestamp, 'HH:mm', 'GMT')
      }),
      new TableColumnText({
        header: 'HOURS',
        name: 'hours',
        sorting: 'totalHours',
        displayAt: 1024,
        value: workingDay => workingDay.totalHours
      }),
      new TableColumnText({
        header: 'SITE',
        name: 'site',
        sorting: 'site.name',
        displayAt: 1280,
        value: workingDay => workingDay.site.name
      }),
      new TableColumnText({
        header: 'STATUS',
        name: 'status',
        sorting: 'status',
        displayAt: 1650,
        value: workingDay => this.translateService.instant(`campaigns.workingDays.${workingDay.status.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).toUpperCase()}`)
      }),
      
    ]
  })

  updateSelection = (selected: Map<string, WorkingDay>) => {
    this.selectedWorkingDays = selected;
    this.massEditService.setSelectedWorkingDays(this.selectedWorkingDays);
  }


  
}
