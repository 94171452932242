import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { v4 } from 'uuid';

import { CampaignZone } from '@routes/campaigns/campaign.model';
import { CampaignsDetailTasksEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.service';
import { ICrop } from '@routes/crops/crop.model';
import { IPackagingReceived } from '@routes/packagings/packaging.model';

import { IProductionInterval, TaskEdit } from '@shared/models/task.model';
import { TASK_TYPES_TYPES } from '@shared/constants/task-types';
import { TIME_REG_EXP } from '@shared/constants/regexp';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { FORM_MODES } from '@shared/constants/forms';
import { WorkingDay } from '@shared/models/working-day.model';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { transformDateToUTC } from '@shared/utils/date.utils';

@Component({
  selector: 'app-campaigns-detail-tasks-edit-production-intervals-dialog',
  templateUrl: './campaigns-detail-tasks-edit-production-intervals-dialog.component.html',
  styleUrls: ['./campaigns-detail-tasks-edit-production-intervals-dialog.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailTasksEditProductionIntervalsDialogComponent implements OnInit {

  TASK_TYPES_TYPES = TASK_TYPES_TYPES;
  FORM_MODES = FORM_MODES;

  form: FormGroup;
  dateControl: FormControl;
  isHarvest: boolean;
  taskIsExtended: boolean;

  mode: FORM_MODES;
  taskType: TASK_TYPES_TYPES;

  workingDay: WorkingDay;
  task: TaskEdit;
  interval: IProductionInterval;
  crops: ICrop[];
  packagings: IPackagingReceived[];
  zones: CampaignZone[];

  cancelButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button
  };
  submitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CONFIRM',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit
  };

  constructor(
    private datePipe: DatePipe,
    private snackbarService: CustomSnackbarService,
    private tasksEditService: CampaignsDetailTasksEditService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<CampaignsDetailTasksEditProductionIntervalsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.mode = this.data.mode;
    this.task = this.data.task;
    this.interval = this.data.interval;

    this.taskType = this.tasksEditService.findTaskTypeType(this.task.taskTypeId);
    this.isHarvest = this.taskType === TASK_TYPES_TYPES.HARVEST;
    this.calculateTaskExtension();
    this.retrieveServiceData();
    this.setForm();

    if (this.taskType === TASK_TYPES_TYPES.WORKABLE) {
      this.crops = this.crops.filter(crop => crop._id === this.task.cropId);
      this.zones = this.zones.filter(zone => zone.sequentialId === this.task.zoneSequentialId);
      this.form.patchValue({ cropId: this.crops[0]._id, zoneSequentialId: this.zones[0].sequentialId });
    }
    if (this.mode === FORM_MODES.edit) {
      const endTime = this.datePipe.transform(this.interval.endTimestamp, 'HH:mm', 'GMT');
      this.form.patchValue({ ...this.interval, endTime });
    }
    this.dateControl = this.form.get('endTimestamp') as FormControl;
  }

  retrieveServiceData = () => {
    this.zones = this.tasksEditService.zones;
    this.crops = this.tasksEditService.crops;
    this.packagings = this.tasksEditService.packagings;
    this.workingDay = this.tasksEditService.workingDay;
  }

  setForm = () => {
    this.form = new FormGroup({
      endTimestamp: new FormControl(null, Validators.required),
      endTime: new FormControl(null, [Validators.required, Validators.pattern(TIME_REG_EXP.HOUR_MINUTES)]),
      zoneSequentialId: new FormControl(null, Validators.required),
      cropId: new FormControl(null, Validators.required),
      packagingId: new FormControl({ value: null, disabled: !this.isHarvest }, Validators.required),
      amount: new FormControl(null, [Validators.required, Validators.min(1)]),
      endedWithKeychain: new FormControl(false)
    });
    if (!this.taskIsExtended) this.form.patchValue({endTimestamp: new Date(this.task.startTimestamp)});
  }

  calculateTaskExtension = () => {
    if (!this.task.endTimestamp) return this.taskIsExtended = false;
    const startDate = transformDateToUTC(this.task.startTimestamp);
    const endDate = transformDateToUTC(this.task.endTimestamp);
    const startValue = [startDate.getFullYear(), startDate.getMonth(), startDate.getDate()].join('-');
    const endValue = [endDate.getFullYear(), endDate.getMonth(), endDate.getDate()].join('-');
    this.taskIsExtended = startValue !== endValue;
  }

  submitForm = () => {
    if (this.mode === FORM_MODES.delete) return this.dialogRef.close({ remove: true });
  
    const endTimestamp = transformDateToUTC(this.form.value.endTimestamp);
    const zoneName = this.zones.find(zone => zone.sequentialId === this.form.value.zoneSequentialId).name;
    const crop = this.crops.find(crop => crop._id === this.form.value.cropId);

    const interval = { ...this.form.value, endTimestamp, zoneName, crop };
    interval._id = this.mode === FORM_MODES.add ? v4() : this.interval._id;

    if (this.taskType === TASK_TYPES_TYPES.HARVEST) {
      interval.packaging = this.packagings.find(packaging => packaging._id === this.form.value.packagingId);
    }
    if (
      endTimestamp.getTime() < transformDateToUTC(this.task.startTimestamp).getTime() ||
      endTimestamp.getTime() > (transformDateToUTC(this.task.endTimestamp)?.getTime() || Number.MAX_SAFE_INTEGER)
    ) {
      const message = 'campaigns.tasks.edit.ERROR_INTERVAL_TIMESTAMP_MUST_BE_WITHIN_TASK_TIMESTAMPS';
      this.snackbarService.error(this.translateService.instant(message));
      return;
    }
    this.dialogRef.close({interval});
  }

  closeDialog = () => this.dialogRef.close();

}
