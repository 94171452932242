<header>
    <mat-toolbar color="primary" class="toolbar py-1 px-4 mat-elevation-z3">
        <img class="logo" src="/assets/images/logos/el-pinar-entregables-logotipo-vectorial-blanco-horizontal.png" alt="Vivero El Pinar">
        <span class="spacer"></span>
        <div class="menu-wrapper">
            <button class="menu-button" mat-icon-button (click)="this.evtMenu.emit()">
                <mat-icon>menu</mat-icon>
            </button>
            <mat-form-field  class="menu-select">
                <mat-select [(value)]="filter" (selectionChange)="processFilter($event.value)">
                    <mat-select-trigger>
                        <img *ngIf="filter !== FILTER_VALUES.BOTH" class="select-img" [src]="getAsset(filter, '-white')">
                        {{ ('general.types.' + filter.toUpperCase()) | translate }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let filter of filterValues" [class.select-no-img]="filter === FILTER_VALUES.BOTH" [value]="filter">
                        <img *ngIf="filter !== FILTER_VALUES.BOTH" class="select-img" [src]="getAsset(filter)">
                        {{ ('general.types.' + filter.toUpperCase()) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-toolbar>
</header>