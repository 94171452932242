import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { CustomDateAdapter } from '@shared/classes/custom-date-adapter';
import { SearchComponent } from './widgets/search/search.component';
import { CustomSnackbarComponent } from './widgets/custom-snackbar/custom-snackbar.component';
import { CustomSnackbarService } from './services/snackbar.service';
import { LoginComponent } from '../routes/login/login.component';
import { PasswordRecoveryComponent } from '@routes/password-recovery/password-recovery.component';
import { PasswordRecoveryDialogComponent } from '@routes/password-recovery/password-recovery-dialog/password-recovery-dialog.component';
import { PasswordRecoveryCodeExpiredDialogComponent } from '@routes/password-recovery/password-recovery-code-expired-dialog/password-recovery-code-expired-dialog.component';
import { PaginatorI18n } from './widgets/paginator-i18n/paginator-i18n';
import { DataTableComponent } from './widgets/data-table/data-table.component';
import { DeleteDialogComponent } from './widgets/delete-dialog/delete-dialog.component';
import { UploadFileComponent } from './widgets/upload-file/upload-file.component';
import { DropFileDirective } from './directives/drop-file.directive';
import { ReactiveDisableDirective } from '@shared/directives/reactive-disable.directive';
import { TextToHoursDirective } from '@shared/directives/text-to-hours.directive';
import { UploadFilePageComponent } from './widgets/upload-file-page/upload-file-page.component';
import { UploadFilePageFormComponent } from './widgets/upload-file-page/upload-file-page-form/upload-file-page-form.component';
import { UploadFilePageTableComponent } from './widgets/upload-file-page/upload-file-page-table/upload-file-page-table.component';
import { RedirectComponent } from './widgets/redirect/redirect.component';
import { ConfirmDialogComponent } from './widgets/confirm-dialog/confirm-dialog.component';

import { GenericTableComponent } from '@shared/components/generic-table/generic-table.component';
import { GenericButtonComponent } from '@shared/components/generic-button/generic-button.component';
import { GenericFiltersComponent } from '@shared/components/generic-filters/generic-filters.component';
import { GenericListComponent } from '@shared/components/generic-list/generic-list.component';
import { GenericReportComponent } from '@shared/components/generic-report/generic-report.component';

import { HourPipe } from '@shared/pipes/hour.pipe';
import { HtmlPipe } from '@shared/pipes/html.pipe';
import { ExternalBatchPipe } from '@shared/pipes/externalBatch.pipe';
import { DateComparisonDirective } from '@shared/directives/date-comparison.directive';
import { UpdateDateControlDirective } from '@shared/directives/update-date-control.directive';
import { UpdateHourDirective } from '@shared/directives/update-hour.directive';
import { ReactiveTimeInputDirective } from '@shared/directives/reactive-time-input.directive';
import { DifferentFromDirective } from '@shared/directives/different-from.directive';
import { UniqueInValidatorDirective } from '@shared/directives/unique-in-validator.directive';

@NgModule({
    declarations: [
        LoginComponent,
        PasswordRecoveryComponent,
        PasswordRecoveryDialogComponent,
        PasswordRecoveryCodeExpiredDialogComponent,
        SearchComponent,
        CustomSnackbarComponent,
        DataTableComponent,
        DeleteDialogComponent,
        UploadFileComponent,
        DropFileDirective,
        ReactiveDisableDirective,
        TextToHoursDirective,
        UploadFilePageComponent,
        UploadFilePageFormComponent,
        UploadFilePageTableComponent,
        RedirectComponent,
        HourPipe,
        HtmlPipe,
        ExternalBatchPipe,
        ConfirmDialogComponent,
        GenericTableComponent,
        GenericButtonComponent,
        GenericFiltersComponent,
        GenericListComponent,
        GenericReportComponent,
        UpdateDateControlDirective,
        UpdateHourDirective,
        DateComparisonDirective,
        ReactiveTimeInputDirective,
        DifferentFromDirective,
        UniqueInValidatorDirective
    ],
    providers: [
        CustomSnackbarService,
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        }, {
            provide: MAT_DATE_LOCALE,
            useValue: 'es-ES'
        }, {
            provide: LOCALE_ID,
            useValue: 'es'
        }, {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                hideRequiredMarker: true
            }
        }, {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }, {
            provide: DateAdapter,
            useClass: CustomDateAdapter
        }
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        MatButtonModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatListModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatTabsModule,
        MatDialogModule,
        MatTooltipModule,
        MatIconModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatRadioModule,
        MatStepperModule
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        MatButtonModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatListModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatTabsModule,
        MatDialogModule,
        MatTooltipModule,
        MatIconModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatRadioModule,
        MatStepperModule,
        SearchComponent,
        DataTableComponent,
        DeleteDialogComponent,
        UploadFileComponent,
        DropFileDirective,
        ReactiveDisableDirective,
        UploadFilePageComponent,
        UploadFilePageFormComponent,
        UploadFilePageTableComponent,
        HourPipe,
        HtmlPipe,
        ExternalBatchPipe,
        GenericTableComponent,
        GenericButtonComponent,
        GenericFiltersComponent,
        GenericListComponent,
        GenericReportComponent,
        ReactiveTimeInputDirective,
        DifferentFromDirective,
        UniqueInValidatorDirective
    ]
})
export class SharedModule {}
