import { Component, OnInit } from '@angular/core';
import { GenericFiltersService } from '@shared/components/generic-filters/generic-filters-service.service';

@Component({
  selector: 'app-campaigns-detail-reports',
  templateUrl: './campaigns-detail-reports.component.html',
  styleUrls: ['./campaigns-detail-reports.component.scss']
})
export class CampaignsDetailReportsComponent implements OnInit {

  constructor(
    private filtersService: GenericFiltersService
  ) {}

  ngOnInit(): void {
    this.filtersService.removeFiltersValue();
  }

}
