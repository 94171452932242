import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { BatchsService } from '@routes/batchs/batchs.service';

@Component({
  selector: 'app-batchs-sage-modal',
  templateUrl: './batchs-sage-modal.component.html',
  styleUrls: ['./batchs-sage-modal.component.scss']
})
export class BatchsSageModalComponent implements OnInit {

  selectedBatchs: Set<string>;

  constructor(
    private batchsService: BatchsService,
    private snackbackService: CustomSnackbarService,
    private dialogRef: MatDialogRef<BatchsSageModalComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.selectedBatchs = this.data.selectedBatchs;
  }

  synchronizeSage = () => {
    this.snackbackService.loading(this.getSnackbarMessage('SYNCHRONIZING_SAGE'));

    this.batchsService.synchronizeSage(Array.from(this.selectedBatchs))
      .then(() => {
        this.snackbackService.success(this.getSnackbarMessage('SYNCHRONIZED'));
        this.dialogRef.close(true);
      })
      .catch(() => this.getSnackbarMessage('SYNCHRONIZATION_ERROR'));
  }

  getSnackbarMessage = (message: string) =>
    this.translateService.instant(`batchs.messages.${message}`);

}
