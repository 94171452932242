<div class="search-row">
    <app-search (evtSearch)="searchUsers($event)"></app-search>
    <button mat-flat-button color="primary" class="btn" type="button" [routerLink]="USERS_ROUTES.add">
        <img class="btn-image" src="/assets/images/icons/add.svg" alt="{{ 'general.alt.ADD' | translate }}"/>
        <span class="btn-text">{{ 'general.actions.ADD' | translate }}</span>
    </button>
</div>

<div [hidden]="!users.length">
    <table class="table w-100" mat-table matSort [dataSource]="users" (matSortChange)="sortUsers($event)">
        <!-- Username -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="username" class="username">{{ 'users.main.USERNAME' | translate }}</th>
            <td mat-cell *matCellDef="let user" class="username">{{ user.username }}</td>
        </ng-container>
        <!-- Name -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="name">{{ 'users.main.NAME' | translate }}</th>
            <td mat-cell *matCellDef="let user" class="name">{{ user.name }}</td>
        </ng-container>
        <!-- Email -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="email" class="email">{{ 'users.main.EMAIL' | translate }}</th>
            <td mat-cell *matCellDef="let user" class="email">{{ user.email }}</td>
        </ng-container>
        <!-- Role -->
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="role" class="role">{{ 'users.main.ROLE' | translate }}</th>
            <td mat-cell *matCellDef="let user" class="role">{{ 'general.roles.' + user.role | translate }}</td>
        </ng-container>
        <!-- Disabled -->
        <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="disabled" class="disabled">{{ 'users.main.STATUS' | translate }}</th>
            <td mat-cell *matCellDef="let user" class="disabled">{{ getUserDisabledLabel(user) }}</td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="table-cell table-actions">{{ 'workers.table.ACTIONS' | translate }}</th>
            <td mat-cell *matCellDef="let user" class="table-cell table-actions">
                <button class="action-btn" [routerLink]="[USERS_ROUTES.detail, user._id]">
                    <img class="action-img" src="/assets/images/icons/search.svg" alt="{{ 'general.alt.DETAIL' | translate }}"/>
                </button>
                <button class="action-btn" [routerLink]="[USERS_ROUTES.edit, user._id]">
                    <img class="action-img" src="/assets/images/icons/edit.svg" alt="{{ 'general.alt.EDIT' | translate }}"/>
                </button>
                <button class="action-btn" (click)="displayDialog(USERS_DIALOGS.password, user)">
                    <img class="action-img" src="/assets/images/icons/password.svg" alt="{{ 'general.alt.CHANGE_PASSWORD' | translate }}"/>
                </button>
                <button class="action-btn" (click)="displayDialog(USERS_DIALOGS.disable, user)">
                    <img class="action-img" src="/assets/images/icons/{{ user.disabled ? 'approve' : 'forbidden' }}.svg" alt="{{ 'general.alt.DISABLED' | translate }}"/>
                </button>
                <button class="action-btn" (click)="displayDialog(USERS_DIALOGS.delete, user)">
                    <img class="action-img" src="/assets/images/icons/delete.svg" alt="{{ 'general.alt.DELETE' | translate }}"/>
                </button>
            </td>
        </ng-container>
        <!-- Row definition -->
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
    <mat-paginator
        [length]="total" [pageSize]="pagination.size" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginateUsers($event)">
    </mat-paginator>
</div>
<!-- Loading users -->
<div *ngIf="loading" class="alert alert-secondary">
    <p class="m-0 text-center">{{ 'users.message.LOADING_USERS' | translate }}</p>
</div>
<!-- Users not found -->
<div *ngIf="!users.length && !loading" class="alert alert-warning">
    <p class="m-0 text-center">{{ 'users.message.NO_USERS' | translate }}</p>
</div>
