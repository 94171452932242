import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ICrop } from '@routes/crops/crop.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignSite } from '@routes/campaigns/campaign.model';
import { CampaignUtilsService } from '@routes/campaigns/campaign.utils.service';
import { CropService } from '@routes/crops/crop.service';

import { Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-campaigns-planner-zones-form',
  templateUrl: './campaigns-planner-zones-form.component.html',
  styleUrls: ['./campaigns-planner-zones-form.component.scss']
})
export class CampaignsPlannerZonesFormComponent implements OnInit, AfterViewInit {

  @Input() site: CampaignSite;
  @ViewChild('crop', { static: true }) crop: ElementRef;
  @ViewChild('density') density: ElementRef;

  form: FormGroup;
  crops: ICrop[] = [];
  pagination: Pagination = new Pagination({
    size: 10,
    page: 1,
    sort: {
      field: 'name',
      order: 1
    }
  });

  constructor(
    private plannerService: CampaignPlannerService,
    private cropService: CropService,
    private campaignUtilsServices: CampaignUtilsService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      crop: new FormControl(null, Validators.required),
      area: new FormControl(null, [Validators.required, Validators.pattern(/^\d+([.]{1}\d+)*$/)]),
      plants: new FormControl(null, [Validators.required, Validators.pattern(/^\d+$/)])
    });
    fromEvent(this.crop.nativeElement, 'input').subscribe(() => this.form.get('crop').setErrors({ incorrect: true }));

    this.form.valueChanges.subscribe(() =>
      this.calculateDensity(this.form.value.area, this.form.value.plants));
  }

  ngAfterViewInit(): void {
    fromEvent(this.crop.nativeElement, 'input').pipe(debounceTime(250)).subscribe(() => {
      this.pagination.search = (this.crop.nativeElement as HTMLInputElement).value;
      this.cropService.getCrops(this.pagination).then(result => this.crops = result.data);
    });
  }

  displayCrop = (crop: ICrop) => crop ? `${crop.name} (${crop.variety})` : '';

  selectCrop = (event: MatAutocompleteSelectedEvent) => {
    this.form.get('crop').setValue(event.option.value);
    this.form.get('crop').updateValueAndValidity();
    this.crops = [];
  };

  addZone = (): void => {
    if (!this.form.valid) return;
    
    const zone = { ...this.form.value };
    zone.cropId = this.form.value.crop._id;
    zone.cropName = this.form.value.crop.name;
    zone.cropVariety = this.form.value.crop.variety;
    delete zone.crop;

    try {
      this.plannerService.addZone(zone, this.site.siteId);
      this.form.reset();
    } catch (error) {
      const message = this.translateService.instant(`campaigns.zones.${error.message}`);
      this.snackbarService.error(message);
    }
  }

  calculateDensity = (area: number, plants: number) => 
    (this.density.nativeElement as HTMLInputElement).value = this.campaignUtilsServices.calculateDensity(area, plants);
  
}
