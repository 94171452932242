import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BATCH_MIXED_STATUS, BATCH_MIXED_STATUS_EDIT } from '@routes/batchs/batchs.constants';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';

@Component({
  selector: 'app-batchs-edit-close-dialog',
  templateUrl: './batchs-edit-close-dialog.component.html',
  styleUrls: ['./batchs-edit-close-dialog.component.scss']
})
export class BatchsEditCloseDialogComponent implements OnInit {

  BATCH_MIXED_STATUS_EDIT = BATCH_MIXED_STATUS_EDIT;

  form: FormGroup;

  cancelButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button
  };
  submitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CONFIRM',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit
  };

  constructor(
    private dialog: MatDialogRef<BatchsEditCloseDialogComponent>
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      status: new FormControl(BATCH_MIXED_STATUS.OPEN, Validators.required)
    });
  }

  submitForm = () => {
    if (this.form.invalid) return;
    this.dialog.close(this.form.value.status);
  }

}
