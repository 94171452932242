import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[appReactiveDisable]'
})
export class ReactiveDisableDirective implements OnChanges {

  @Input() appReactiveDisable: boolean;

  constructor(
    private control: NgControl
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const control = this.control.control as FormControl;

    if (changes.appReactiveDisable.currentValue) {
      control.disable();
      control.setValue(null);
    } else {
      control.enable();
    }
  }

}
