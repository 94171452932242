import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { User } from '../user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-users-password-dialog',
  templateUrl: './users-password-dialog.component.html',
  styleUrls: ['./users-password-dialog.component.scss']
})
export class UsersPasswordDialogComponent implements OnInit {

  form: FormGroup;
  user: User;

  constructor(
    public dialogRef: MatDialogRef<UsersPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.user = this.data.user;

    this.form = new FormGroup({
      password: new FormControl(null, Validators.required),
      repeat: new FormControl(null, Validators.required)
    });
  }

  editPassword = () => {
    if (this.form.invalid) return;

    if (this.form.value.password !== this.form.value.repeat) {
      this.snackbarService.error(this.translateService.instant('users.message.PASSWORD_MISMATCH'))
      return;
    };

    this.userService.editUserPassword(this.user._id, this.form.value.password).then(() => {
      const message = this.translateService.instant('users.message.PASSWORD_UPDATED')
      this.snackbarService.open(SNACKBAR_TYPES.SUCCESS, message, 3000);
      this.dialogRef.close({ updated: true });
    }).catch(error => this.snackbarService.error(error.error.message))
  }

}
