import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  @Output('evtSearch') evtSearch = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef;

  searchPattern: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.searchPattern = this.activatedRoute.snapshot.queryParams.search || '';

    if (this.searchPattern) {
      this.evtSearch.emit(this.searchPattern);
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'input').pipe(debounceTime(250)).subscribe(() => {
      const searchPattern = (this.input.nativeElement as HTMLInputElement).value;

      this.router.navigate([], {
        queryParamsHandling: 'merge',
        queryParams: { search: searchPattern },
      });
      this.evtSearch.emit(searchPattern);
    });
  }
}
