import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBatchType, IBatchMixedBatch, IBatchMixedReceived, IBatchUniqueReceived, IBatchUniqueReceivedInterval, IBatchMixedItem } from '@routes/batchs/batchs.model';
import { BATCH_CATEGORY } from '@routes/batchs/batchs.constants';

@Component({
  selector: 'app-batchs-detail',
  templateUrl: './batchs-detail.component.html',
  styleUrls: ['./batchs-detail.component.scss']
})
export class BatchsDetailComponent implements OnInit {

  BATCH_CATEGORY = BATCH_CATEGORY;
  batch: IBatchType = null;
  category: BATCH_CATEGORY = null;

  intervals: IBatchUniqueReceivedInterval[] = [];
  uniqueBatchs: IBatchMixedBatch[] = [];
  items: IBatchMixedItem[] = [];

  constructor(
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.category = this.activatedRoute.snapshot.data.category;
    this.batch = this.activatedRoute.snapshot.data.batch;

    switch (this.category) {
      case BATCH_CATEGORY.UNIQUE:
        this.intervals = (<IBatchUniqueReceived>this.batch).intervals;
        break;
      case BATCH_CATEGORY.MIXED:
        this.uniqueBatchs = (<IBatchMixedReceived>this.batch).batchs;
        this.items = (<IBatchMixedReceived>this.batch).items;
        break;
    }
  }

}
