import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BATCH_REG_EXP } from '@shared/constants/regexp';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { BatchsService } from '@routes/batchs/batchs.service';
import { saveAs } from 'file-saver';
import { REPORT_TYPES } from '../reports.constants';

@Component({
  selector: 'app-reports-main',
  templateUrl: './reports-main.component.html',
  styleUrls: ['./reports-main.component.scss']
})
export class ReportsMainComponent implements OnInit {
  
  form: FormGroup
  placeholder: string;
  loading = false; 
  errorMessage = "traceabilityReports.messages.ERROR_PLATE";
  translation: any;

  constructor(
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private batchsService: BatchsService,
  ) { }

  ngOnInit(): void {
    this.placeholder = this.translateService.instant('traceabilityReports.placeholder.PLATE');
    this.translation = this.translateService.instant('traceabilityReports.messageList');
    this.initForm()
  }

  initForm = () => {
    this.form = new FormGroup({
      search: new FormControl(null, [Validators.required, Validators.pattern(BATCH_REG_EXP.PLATE)]),
      reportCategory: new FormControl(REPORT_TYPES.PLATE)
    });
    this.form.get('reportCategory').valueChanges.subscribe(category => this.changeValidators(category));
  }

  submitForm = async () => {
    if (this.form.invalid) return;
    this.loading = true;
    this.snackbarService.loading(this.translateService.instant('reports.messages.LOADING_REPORT'));
    const search = this.form.value.search;
    const category = this.form.value.reportCategory;
    try {
        const blob = await this.batchsService.downloadLatestBatchReport(search, category);
        const fileName = category === REPORT_TYPES.PLATE
          ? 'traceabilityReports.files.PLATE'
          : 'traceabilityReports.files.MIXED_BATCH'
        saveAs(blob, this.translateService.instant(fileName, { code: search }));
        this.snackbarService.snackBar.dismiss();
    } catch (error) {
      let errorMessage = 'reports.messages.ERROR_LOADING'
        if(error.status === 404) errorMessage = category === REPORT_TYPES.PLATE
            ? 'traceabilityReports.error.PLATE_NOT_FOUND'
            : 'traceabilityReports.error.CODE_NOT_FOUND'
      this.snackbarService.error(this.translateService.instant(errorMessage))
    } finally {
        this.loading = false;
    }
  }

  changeValidators = (category) => {
    const placeholder = category === REPORT_TYPES.PLATE ? 'PLATE' : 'BATCH_CODE';
    this.placeholder = this.translateService.instant(`traceabilityReports.placeholder.${placeholder}`);
    const regexp = category === REPORT_TYPES.PLATE ? BATCH_REG_EXP.PLATE : BATCH_REG_EXP.MIXED;
    this.form.get('search').setValidators([Validators.required, Validators.pattern(regexp)])
    this.form.get('search').updateValueAndValidity();

  }
}
