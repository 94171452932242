import { Injectable } from '@angular/core';
import { ABS_BASE_ROUTE, CAMPAIGNS_DETAIL_ROUTES, CAMPAIGNS_ROUTES, SECTIONS } from '@shared/constants/routes';

@Injectable({
  providedIn: 'root'
})
export class CampaignsRoutingService {

  constructor() {}

  private mainParentRoute =
    [ABS_BASE_ROUTE, SECTIONS.production, CAMPAIGNS_ROUTES.parent];

  planner = () => [];

  detail = (campaignId: string) => [...this.mainParentRoute, CAMPAIGNS_ROUTES.detail, campaignId];

  mainWorkingDays = (campaignId: string) => [
    ...this.detail(campaignId),
    CAMPAIGNS_DETAIL_ROUTES.workingDays];

  addWorkingDay = (campaignId: string) => [
    ...this.mainWorkingDays(campaignId),
    CAMPAIGNS_DETAIL_ROUTES.workingDayAdd];

  editWorkingDay = (campaignId: string, workingDayId: string) => [
    ...this.mainWorkingDays(campaignId),
    CAMPAIGNS_DETAIL_ROUTES.workingDayEdit, workingDayId];

  detailWorkingDay = (campaignId: string, workingDayId: string) => [
    ...this.mainWorkingDays(campaignId),
    CAMPAIGNS_DETAIL_ROUTES.workingDayDetail, workingDayId];

  mainTasks = (campaignId: string) => [
    ...this.detail(campaignId), CAMPAIGNS_DETAIL_ROUTES.tasks];

  addTask = (campaignId: string, workingDayId: string) => [
    ...this.mainWorkingDays(campaignId),
    workingDayId, CAMPAIGNS_DETAIL_ROUTES.taskAdd];
  
  editTask = (campaignId: string, workingDayId: string, taskId: string) => [
    ...this.mainWorkingDays(campaignId),
    workingDayId, CAMPAIGNS_DETAIL_ROUTES.taskEdit, taskId];

}
