<div class="table-wrapper" *ngIf="taskType && taskType !== TASK_TYPES_TYPES.OTHER">
    <app-generic-table
        [tableConfig]="tableConfig">
    </app-generic-table>
    <app-generic-button
        [config]="addButtonConfig" class="add-button" (click)="addInterval()"
    ></app-generic-button>
</div>
<div *ngIf="taskType === TASK_TYPES_TYPES.OTHER" class="alert alert-secondary">
    {{ 'campaigns.tasks.edit.NOT_WORKABLE_TASK_INTERVALS_MESSAGE' | translate }}
</div>
