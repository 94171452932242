import { Component, OnInit } from '@angular/core';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';

@Component({
  selector: 'app-campaigns-detail-zones',
  templateUrl: './campaigns-detail-zones.component.html',
  styleUrls: ['./campaigns-detail-zones.component.scss']
})
export class CampaignsDetailZonesComponent implements OnInit {

  constructor(
    private plannerService: CampaignPlannerService
  ) {}

  ngOnInit(): void {
    if (!this.plannerService.site) {
      this.plannerService.selectSite(this.plannerService.campaign.sites[0].siteId);
    }
  }

}