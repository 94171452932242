import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { PositionsComponent } from '@routes/positions/positions.component';
import { PositionsTableComponent } from '@routes/positions/positions-table/positions-table.component';
import { PositionsEditComponent } from '@routes/positions/positions-edit/positions-edit.component';
import { PositionsResolver } from '@routes/positions/positions.resolver';

import { SharedModule } from '@shared/shared.module';
import { POSITIONS_ROUTES } from '@shared/constants/routes';
import { FORM_MODES } from '@shared/constants/forms';

const routes: Routes = [
  {
    path: '',
    component: PositionsComponent,
    children: [{
      path: '',
      component: PositionsTableComponent,
      pathMatch: 'full'
    }, {
      path: POSITIONS_ROUTES.add,
      component: PositionsEditComponent,
      data: {mode: FORM_MODES.add}
    }, {
      path: `${POSITIONS_ROUTES.edit}/:positionId`,
      component: PositionsEditComponent,
      data: {mode: FORM_MODES.edit},
      resolve: {position: PositionsResolver}
    }, {
      path: `${POSITIONS_ROUTES.detail}/:positionId`,
      component: PositionsEditComponent,
      data: {mode: FORM_MODES.detail},
      resolve: {position: PositionsResolver}
    }]
  }
];

@NgModule({
  declarations: [
    PositionsComponent,
    PositionsTableComponent,
    PositionsEditComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PositionsModule { }
