import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';

import { CropsComponent } from './crops.component';
import { CropsMainComponent } from './crops-main/crops-main.component';
import { CropsEditComponent } from './crops-edit/crops-edit.component';
import { CROPS_ROUTES } from '@shared/constants/routes';
import { FORM_MODES } from '@shared/constants/forms';

const routes: Routes = [
  {
    path: '',
    component: CropsComponent,
    children: [
      {
        path: '',
        component: CropsMainComponent,
        pathMatch: 'full'
      },
      {
        path: CROPS_ROUTES.add,
        component: CropsEditComponent,
        data: {
          mode: FORM_MODES.add
        }
      },
      {
        path: `${CROPS_ROUTES.detail}/:id`,
        component: CropsEditComponent,
        data: {
          mode: FORM_MODES.detail
        }
      },
      {
        path: `${CROPS_ROUTES.edit}/:id`,
        component: CropsEditComponent,
        data: {
          mode: FORM_MODES.edit
        }
      }
    ]
  }
];

@NgModule({
  declarations: [
    CropsComponent,
    CropsMainComponent,
    CropsEditComponent,
  ],
  providers: [

  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CropsModule { }