import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/core/storage/storage.service';
import { LOGIN_ROUTE } from '@shared/constants/routes';
import { throwError } from 'rxjs';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private api: ApiService,
        private router: Router,
        private storageService: StorageService
    ) { }

    getLoginUrl = () => `${this.api.getBaseUrl()}/auth/basic`;
    getRefreshUrl = () => `${this.api.getBaseUrl()}/auth/token`;

    getToken = (): string => this.storageService.getItem('token');
    getRefreshToken = (): string => this.storageService.getItem('refreshToken');

    isAuthenticated = (): boolean => Boolean(this.getToken());

    logIn = (data: { username: string, password: string }): Promise<any> =>
        lastValueFrom(this.http.post(this.getLoginUrl(), data).pipe(tap(this.storeTokens)));

    logOut = () => {
        this.storageService.clear();
        this.router.navigate([`/${LOGIN_ROUTE}`]);
    };

    regenerateToken = (): Observable<any> => {
        const refreshToken = this.storageService.getItem('refreshToken');

        if (!refreshToken) {
            return throwError('It is needed to log in again');
        }
        return this.http.post(this.getRefreshUrl(), { refreshToken }).pipe(tap(this.storeTokens))

    }

    private storeTokens = (tokens: {}) => {
        this.storageService.setItem('token', tokens['token']);

        if (tokens['refreshToken']) {
            this.storageService.setItem('refreshToken', tokens['refreshToken']);
        }
    }
}