import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAgency } from '@models/agency.model';

import { CampaignService } from '@routes/campaigns/campaign.service';
import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { IKeychain } from '@routes/keychains/keychain.model';
import { IWorkerReceived } from '@routes/workers/worker.model';

import { SECTIONS, ABS_BASE_ROUTE, CAMPAIGNS_ROUTES } from '@shared/constants/routes';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { UploadPageConfiguration } from '@shared/interfaces/upload';
import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-campaigns-detail-workers',
  templateUrl: './campaigns-detail-workers.component.html',
  styleUrls: ['./campaigns-detail-workers.component.scss']
})
export class CampaignsDetailWorkersComponent implements OnInit {

  MAIN_ROUTE = [ABS_BASE_ROUTE, SECTIONS.production, CAMPAIGNS_ROUTES.parent];
  CampaignStatus = CampaignStatus;
  
  campaign: Campaign;
  campaignChanged = false;
  section = 'table';
  uploadConfig: UploadPageConfiguration;

  constructor(
    private campaignService: CampaignService,
    private plannerService: CampaignPlannerService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.uploadConfig = {
      uploadFunction: this.campaignService.addWorkersFromFile,
      tableColumns: [
        {
          header: 'workers.table.NAME',
          name: 'name',
          value: 'name'
        }, {
          header: 'workers.table.SURNAME',
          name: 'surname',
          value: 'surname'
        }, {
          header: 'workers.table.NIF',
          name: 'nif',
          value: 'nif',
          formatFunction: (nif: string) => nif || '-'
        }, {
          header: 'workers.table.POSITION',
          name: 'position',
          value: 'position',
          formatFunction: (position: string) => position || '-'
        }, {
          header: 'workers.table.KEYCHAIN',
          name: 'keychain',
          value: 'keychain',
          formatFunction: (keychain: IKeychain) => keychain ? keychain.code : '-'
        }, {
          header: 'workers.table.NATIONALITY',
          name: 'nationality',
          value: 'nationality',
          formatFunction: (nationality: string) => nationality || '-'
        }, {
          header: 'workers.table.ADDRESS',
          name: 'address',
          value: 'address'
        }, {
          header: 'workers.table.TAX_RESIDENCE',
          name: 'taxResidence',
          value: 'taxResidence'
        }, {
          header: 'workers.table.IBAN',
          name: 'iban',
          value: 'iban'
        }, {
          header: 'workers.table.RECOMMENDED_BY',
          name: 'recommendedBy',
          value: 'recommendedBy',
          formatFunction: (recommendedBy: IWorkerReceived) => recommendedBy ? `${recommendedBy.name} ${recommendedBy.surname} (${recommendedBy.nif})` : '-'
        }, {
          header: 'workers.table.AGENCY',
          name: 'aggency',
          value: 'agency',
          formatFunction: (agency: IAgency) => agency?.name || '-'
        }
      ],
      successTab: {
        content: 'campaigns.workers.WORKERS_ASSIGNED',
        empty: 'campaigns.workers.NO_WORKERS_ASSIGNED'
      },
      fileFormats: ['csv', 'xls', 'xlsx'],
      entityId: this.plannerService.campaign._id
    }
    this.campaign = this.plannerService.campaign;
  }

  changeSection = async (section: string) => {
    this.section = section;

    if (this.section === 'table') {
      const campaign = await this.campaignService.getCampaign(this.plannerService.campaign._id);
      this.plannerService.setCampaignBasic(campaign);
    }
  };

  editCampaign = () => this.campaignService.editCampaign(this.plannerService.campaign)
    .then(() => this.campaignChanged = false)
    .catch(error => this.snackbarService.open(SNACKBAR_TYPES.ERROR, error.error.message));
}