<form [formGroup]="form" class="form">
    <div class="data">
        <article class="workingDay">
            <section [hidden]="section !== WorkingDayFormSection.Basic">
                <mat-form-field class="w-100" appearance="standard">
                    <mat-label for="worker">{{ 'campaigns.workingDays.WORKER' | translate }}</mat-label>
                    <input matInput type="text" id="worker" name="worker" formControlName="worker" [matAutocomplete]="workersAuto" #worker>
                    <mat-autocomplete #workersAuto="matAutocomplete" [displayWith]="displayWorker" (optionSelected)="selectWorker($event)">
                        <mat-option *ngFor="let worker of workers" [value]="worker">{{ worker.name }} {{ worker.surname }}</mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="mode === FORM_MODES.add">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
                    <mat-hint *ngIf="mode === FORM_MODES.edit">{{ 'general.form.NOT_EDITABLE' | translate }}</mat-hint>
                </mat-form-field>

                <mat-form-field class="w-100 mt-3" appearance="standard">
                    <mat-label for="site">{{ 'campaigns.workingDays.SITE' | translate }}</mat-label>
                    <mat-select formControlName="site">
                        <mat-option *ngFor="let site of campaign.sites" [value]="site.siteId">{{ site.siteName }}</mat-option>
                    </mat-select>
                    <mat-hint *ngIf="mode === FORM_MODES.add">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
                    <mat-hint *ngIf="mode === FORM_MODES.edit">{{ 'general.form.NOT_EDITABLE' | translate }}</mat-hint>
                </mat-form-field>

                <div class="w-100 d-flex mt-3">
                    <mat-form-field class="mr-3 flex-grow-1" appearance="standard">
                        <mat-label for="device">{{ 'campaigns.workingDays.DEVICE' | translate }}</mat-label>
                        <input matInput type="text" id="device" name="device" formControlName="device" [matAutocomplete]="devicesAuto" #device>
                        <mat-autocomplete #devicesAuto="matAutocomplete" [displayWith]="displayDevice" (optionSelected)="selectDevice($event)">
                            <mat-option *ngFor="let device of devices" [value]="device">{{ device.name }}</mat-option>
                        </mat-autocomplete>
                        <mat-hint *ngIf="mode === FORM_MODES.add">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
                        <mat-hint *ngIf="mode === FORM_MODES.edit">{{ 'general.form.NOT_EDITABLE' | translate }}</mat-hint>
                    </mat-form-field>

                    <mat-form-field class="ml-3 flex-grow-1" appearance="standard">
                        <mat-label for="hours">{{ 'campaigns.workingDays.HOURS' | translate }}</mat-label>
                        <input matInput type="text" id="hours" name="hours" formControlName="hours">
                        <mat-hint *ngIf="mode === FORM_MODES.add">{{ 'general.form.AUTOCALCULATED' | translate }}</mat-hint>
                        <mat-hint *ngIf="mode === FORM_MODES.edit">{{ 'general.form.NOT_EDITABLE' | translate }}</mat-hint>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="w-100 mt-3" appearance="fill">
                        <mat-label>{{ 'campaigns.workingDays.STATUS' | translate }}</mat-label>
                        <mat-select formControlName="status">
                            <mat-option *ngFor="let status of WORKING_DAY_STATUSES | keyvalue" [value]="status.value" [disabled]="!avaliableStatuses.includes(status.value)">
                                {{ 'campaigns.workingDays.' + status.key | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
                    </mat-form-field>

                    <div class="date-wrapper w-100 d-flex flex-wrap mt-3">
                        <h3 class="form-subtitle">{{ 'campaigns.workingDays.START_TITLE' | translate }}</h3>
                        <mat-form-field class="mr-3 input-date" appearance="standard">
                            <mat-label for="startDate">{{ 'campaigns.workingDays.START_DATE' | translate }}</mat-label>
                            <input matInput id="startDate" name="startDate" [matDatepicker]="startDate" formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                            <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">* {{ 'general.form.MANDATORY_LONG_DATE' | translate }}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="ml-3 input-time" appearance="standard">
                            <mat-label for="startTime">{{ 'campaigns.workingDays.START_TIME' | translate }}</mat-label>
                            <input matInput appReactiveTimeInput type="text" id="startTime" name="startTime" formControlName="startTime" [dateControl]="startControl">
                            <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">* {{ 'general.form.MANDATORY_SHORT_TIME' | translate }}</mat-hint>
                        </mat-form-field>

                        <mat-checkbox class="w-100 mt-3" color="primary" id="startedWithKeychain" name="startedWithKeychain" formControlName="startedWithKeychain">
                            {{ 'campaigns.workingDays.STARTED_WITH_KEYCHAIN' | translate }}
                        </mat-checkbox>
                    </div>

                    <div class="date-wrapper w-100 d-flex flex-wrap mt-3">
                        <h3 class="form-subtitle">{{ 'campaigns.workingDays.END_TITLE' | translate }}</h3>

                        <mat-form-field class="mr-3 input-date" appearance="standard">
                            <mat-label for="endDate">{{ 'campaigns.workingDays.END_DATE' | translate }}</mat-label>
                            <input matInput id="endDate" name="endDate" [matDatepicker]="endDate" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                            <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">{{ 'general.form.LONG_DATE' | translate }}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="ml-3 input-time" appearance="standard">
                            <mat-label for="endTime">{{ 'campaigns.workingDays.END_TIME' | translate }}</mat-label>
                            <input matInput appReactiveTimeInput type="text" id="endTime" name="endTime" formControlName="endTime" [dateControl]="endControl">
                            <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">{{ 'general.form.SHORT_TIME' | translate }}</mat-hint>
                        </mat-form-field>

                        <mat-checkbox class="w-100 mt-3" color="primary" id="endedWithKeychain" name="endedWithKeychain" formControlName="endedWithKeychain">
                            {{ 'campaigns.workingDays.ENDED_WITH_KEYCHAIN' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </section>
            <section [hidden]="section !== WorkingDayFormSection.Breaks">
                <ol *ngIf="breaks.controls.length; else noBreaks" class="break-list" formArrayName="breaks">
                    <li class="break-item mb-4 pt-3 pb-4 px-4" *ngFor="let break of breaks.controls; let i = index" [formGroup]="break">
                        <div class="break-row">
                            <mat-form-field class="mr-3" appearance="standard">
                                <mat-label for="startDate">{{ 'campaigns.workingDays.BREAK_START_DATE' | translate }}</mat-label>
                                <input matInput id="startDate" name="startDate" [matDatepicker]="breakStartDate" formControlName="startDate">
                                <mat-datepicker-toggle matSuffix [for]="breakStartDate"></mat-datepicker-toggle>
                                <mat-datepicker #breakStartDate></mat-datepicker>
                                <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">* {{ 'general.form.MANDATORY_LONG_DATE' | translate }}</mat-hint>
                            </mat-form-field>

                            <mat-form-field class="ml-3" appearance="standard">
                                <mat-label for="startTime">{{ 'campaigns.workingDays.BREAK_START_TIME' | translate }}</mat-label>
                                <input matInput type="text" id="startTime" name="startTime" formControlName="startTime">
                                <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">* {{ 'general.form.MANDATORY_SHORT_TIME' | translate }}</mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="break-row mt-3">
                            <mat-form-field class="mr-3" appearance="standard">
                                <mat-label for="endDate">{{ 'campaigns.workingDays.BREAK_END_DATE' | translate }}</mat-label>
                                <input matInput id="endDate" name="endDate" [matDatepicker]="breakEndDate" formControlName="endDate">
                                <mat-datepicker-toggle matSuffix [for]="breakEndDate"></mat-datepicker-toggle>
                                <mat-datepicker #breakEndDate></mat-datepicker>
                                <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">* {{ 'general.form.MANDATORY_LONG_DATE' | translate }}</mat-hint>
                            </mat-form-field>

                            <mat-form-field class="ml-3" appearance="standard">
                                <mat-label for="endTime">{{ 'campaigns.workingDays.BREAK_END_TIME' | translate }}</mat-label>
                                <input matInput type="text" id="endTime" name="endTime" formControlName="endTime">
                                <mat-hint *ngIf="mode === FORM_MODES.add || mode === FORM_MODES.edit">* {{ 'general.form.MANDATORY_SHORT_TIME' | translate }}</mat-hint>
                            </mat-form-field>
                        </div>

                        <button class="button" [disabled]="mode === FORM_MODES.detail" (click)="breaks.removeAt(i)" type="button">
                            <img class="button-img" src="/assets/images/icons/cross.svg" alt="{{ 'general.alt.DELETE' | translate }}"/>
                        </button>
                    </li>
                </ol>

                <ng-template #noBreaks>
                    <div class="alert alert-secondary">{{ 'campaigns.workingDays.NO_BREAK_ASSIGN' | translate }}</div>
                </ng-template>

                <div *ngIf="(mode === FORM_MODES.add || mode === FORM_MODES.edit) && campaign.status !== CampaignStatus.Finished" class="add-break d-flex justify-content-center">
                    <button mat-mini-fab mat-flat-button color="primary" aria-label="Add" (click)="addBreakFormGroup()" type="button">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

            </section>
        </article>
        <aside class="selector mb-3 mb-lg-0 ml-lg-5">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>{{ 'campaigns.workingDays.sections.LABEL' | translate }}</mat-label>
                <mat-select [value]="section" id="selector" name="selector" (selectionChange)="changeSection($event)">
                    <mat-option [value]="WorkingDayFormSection.Basic">{{ 'campaigns.workingDays.sections.BASIC' | translate }}</mat-option>
                    <mat-option [value]="WorkingDayFormSection.Breaks">{{ 'campaigns.workingDays.sections.BREAKS' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </aside>
    </div>
    <div class="buttons d-flex justify-content-between mt-5">
        <!-- Adding -->
        <ng-container *ngIf="mode === FORM_MODES.add">
            <button mat-flat-button color="primary" class="btn" type="button" routerLink=".." queryParamsHandling="preserve">
                {{ 'general.actions.CANCEL' | translate }}
            </button>
            <button mat-flat-button color="primary" class="btn" type="button" (click)="submitForm()" [disabled]="form.invalid" queryParamsHandling="preserve">
                {{ 'general.actions.ADD' | translate }}
            </button>
        </ng-container>
        <!-- Editing -->
        <ng-container *ngIf="mode === FORM_MODES.edit">
            <button mat-flat-button color="warn" class="btn" type="button" routerLink="../.." queryParamsHandling="preserve">
                {{ 'general.actions.DISCARD_CHANGES' | translate }}
            </button>
            <button mat-flat-button color="primary" class="btn" type="submit" (click)="submitForm()" [disabled]="form.invalid" queryParamsHandling="preserve">
                {{ 'general.actions.APPLY_CHANGES' | translate }}
            </button>
        </ng-container>
        <!-- Detail -->
        <ng-container *ngIf="mode === FORM_MODES.detail">
            <button mat-flat-button color="primary" class="btn" type="button" routerLink="../.." queryParamsHandling="preserve">
                {{ 'general.actions.BACK' | translate }}
            </button>
            <button mat-flat-button color="primary" class="btn" type="button" [disabled]="campaign.status === CampaignStatus.Finished" [routerLink]="EDIT_ROUTE" queryParamsHandling="preserve">
                {{ 'general.actions.ENABLE_EDITION' | translate }}
            </button>
        </ng-container>
    </div>
</form>