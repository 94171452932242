import { Injectable } from '@angular/core';
import { WorkerReceived } from '../worker.model';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { WORKER_HISTORICS } from '@shared/interfaces/types';
import { WorkerService } from '../worker.service';

@Injectable({
  providedIn: 'root'
})
export class WorkersDetailService {

  worker: WorkerReceived;
  selectorSection: string;
  historicSection: string;
  editMode: boolean;

  formMode: string;
  historicIndex: number;

  private workerSubject: ReplaySubject<WorkerReceived> = new ReplaySubject(1);
  private selectorSubject: ReplaySubject<string> = new ReplaySubject(1);
  private historicSubject: ReplaySubject<string> = new ReplaySubject(1);
  private editSubject: ReplaySubject<boolean> = new ReplaySubject(1);

  workerObservable: Observable<WorkerReceived> = this.workerSubject.asObservable();
  selectorObservable: Observable<string> = this.selectorSubject.asObservable();
  historicObservable: Observable<string> = this.historicSubject.asObservable();
  editObservable: Observable<boolean> = this.editSubject.asObservable();

  constructor(
    private workerService: WorkerService
  ) {}

  setWorker = (worker: WorkerReceived) => {
    this.worker = worker;
    this.workerSubject.next(worker);
  }

  setSelectorSection = (section: string) => {
    this.selectorSection = section;
    this.selectorSubject.next(section);
  }

  setHistoricSection = (section: string) => {
    this.historicSection = section;
    this.historicSubject.next(section);
  }

  setEditMode = (edit: boolean) => {
    this.editMode = edit;
    this.editSubject.next(edit);
  }

  getHistoricTag = (section: string) =>
    `workers.detail.historic.${WORKER_HISTORICS.find(h => h.value === section).tag}`;

  getHistoricData = (section: string) => this.worker[section];

  editWorkerHistoric = async (workerId: string, section: string, data: any): Promise<void> => {
    await this.workerService.editWorkerHistoric(workerId, section, data);
    const worker = await this.workerService.getWorker(this.worker.id);
    this.setWorker(worker);
    this.setHistoricSection('table');
  }

}