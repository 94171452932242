import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { filter } from 'rxjs/operators';

import { CampaignZone } from '@routes/campaigns/campaign.model';
import { ICrop } from '@routes/crops/crop.model';
import { TaskType } from '@routes/tasks/task.model';
import { CampaignsDetailTasksEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.service';

import { TASK_TYPES_TYPES } from '@shared/constants/task-types';
import { WorkingDay } from '@shared/models/working-day.model';
import { FORM_MODES } from '@shared/constants/forms';
import { TaskReceived } from '@shared/models/task.model';

@Component({
  selector: 'app-campaigns-detail-tasks-edit-task',
  templateUrl: './campaigns-detail-tasks-edit-task.component.html',
  styleUrls: ['./campaigns-detail-tasks-edit-task.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailTasksEditTaskComponent implements OnInit {

  @Input() mode: FORM_MODES;

  FORM_MODES = FORM_MODES;
  TASK_TYPES_TYPES = TASK_TYPES_TYPES;

  mandatoryForm: FormGroup;
  optionalForm: FormGroup;
  intervalsForm: FormArray;
  startControl: FormControl;
  endControl: FormControl;

  taskTypeType: string;
  isHarvestTask: boolean;

  workingDay: WorkingDay;
  task: TaskReceived;
  crops: ICrop[];
  zones: CampaignZone[];
  taskTypes: TaskType[];

  constructor(
    private parentFormGroup: FormGroupDirective,
    private taskEditService: CampaignsDetailTasksEditService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.workingDay = this.taskEditService.workingDay;
    this.taskTypes = this.taskEditService.taskTypes;
    this.task = this.taskEditService.task;
    this.zones = this.taskEditService.zones;
    this.crops = this.taskEditService.crops;

    this.mandatoryForm = this.parentFormGroup.form.get('mandatory') as FormGroup;
    this.optionalForm = this.parentFormGroup.form.get('optional') as FormGroup;
    this.intervalsForm = this.parentFormGroup.form.get('productionIntervals') as FormArray;
    this.startControl = this.mandatoryForm.get('startTimestamp') as FormControl;
    this.endControl = this.mandatoryForm.get('endTimestamp') as FormControl;

    this.checkTaskTypeStatus();
    this.setupForm();
  }

  setupForm = () => {
    this.mandatoryForm.get('taskTypeId').valueChanges.subscribe(value => {
      const {startTimestamp, endTimestamp} = this.workingDay;
      this.checkTaskTypeStatus(value);

      if (this.isHarvestTask) {
        const startTime = this.datePipe.transform(startTimestamp, 'HH:mm', 'GMT');
        const endTime = this.datePipe.transform(endTimestamp, 'HH:mm', 'GMT');
        this.mandatoryForm.patchValue({startTimestamp, startTime, endTimestamp, endTime});
      }
    });
    this.optionalForm.get('zoneSequentialId').valueChanges.pipe(filter(value => value && !this.isHarvestTask)).subscribe(zoneSequentialId => {
      const zoneName = this.zones.find(zone => zone.sequentialId === zoneSequentialId).name;
      this.intervalsForm.controls.forEach(control => control.patchValue({ zoneName, zoneSequentialId }));
    });
    this.optionalForm.get('cropId').valueChanges.pipe(filter(value => value && !this.isHarvestTask)).subscribe(cropId => {
      const crop = this.crops.find(crop => crop._id === cropId);
      this.intervalsForm.controls.forEach(control => control.patchValue({ cropId, crop }));
    });
  }

  checkTaskTypeStatus = (taskTypeId?: string) => {
    if (!taskTypeId) taskTypeId = this.mandatoryForm.value.taskTypeId;
    if (!taskTypeId) return;

    this.taskTypeType = this.taskEditService.findTaskTypeType(taskTypeId);
    this.isHarvestTask = this.taskTypeType === TASK_TYPES_TYPES.HARVEST;
  }

}
