
<div class="header">
    <h2 class="h2 title">{{ 'batchs.tabs.USED_BATCHS' | translate }}</h2>
    <app-generic-button
        [config]="addButtonConfig" (click)="addBatchDialog()"
    ></app-generic-button>
</div>

<app-generic-table
    *ngIf="form.value.length; else noBatchs"
    [tableConfig]="tableConfig"
></app-generic-table>

<ng-template #noBatchs>
    <div
        *ngIf="status === BATCH_MIXED_STATUS.OPEN"
        class="alert alert-warning"
        [innerHTML]="'batchs.messages.NOT_INCLUDED_BATCHS' | translate"
    ></div>
    <div
        *ngIf="status === BATCH_MIXED_STATUS.CLOSED"
        class="alert alert-danger"
        [innerHTML]="'batchs.messages.NOT_MANDATORY_BATCHS' | translate"
    ></div>
</ng-template>
