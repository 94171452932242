import { Component, OnInit } from '@angular/core';
import { ABS_BASE_ROUTE, KEYCHAIN_ROUTES, SECTIONS } from '@shared/constants/routes';
import { KeychainService } from '@routes/keychains/keychain.service';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { IKeychain } from '@routes/keychains/keychain.model';
import { FiltersButtonLink, FiltersConfiguration } from '@shared/components/generic-filters/generic-filters.model';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { BehaviorSubject } from 'rxjs';
import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { Pagination } from '@shared/models/pagination.model';
import { DeleteDialogComponent } from '@shared/widgets/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-keychains-main',
  templateUrl: './keychains-main.component.html',
  styleUrls: ['./keychains-main.component.scss']
})
export class KeychainsMainComponent implements OnInit {

  BASE_ROUTE = ABS_BASE_ROUTE;
  SECTIONS = SECTIONS;
  KEYCHAIN_ROUTES = KEYCHAIN_ROUTES;

  ADD_KEYCHAIN_ROUTE = [ABS_BASE_ROUTE, SECTIONS.workforce, KEYCHAIN_ROUTES.parent, KEYCHAIN_ROUTES.add];
  FILE_KEYCHAIN_ROUTE = [ABS_BASE_ROUTE, SECTIONS.workforce, KEYCHAIN_ROUTES.parent, KEYCHAIN_ROUTES.file];

  tableConfig: TableConfiguration<IKeychain>;
  filtersConfig: FiltersConfiguration;

  constructor(
    private keychainService: KeychainService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initFilters();
    this.initTable();

  }

  getKeychains = (pagination: Pagination) => this.keychainService.getKeychains(pagination)
      .then(response => this.tableConfig.data.next({ data: response.keychains, total: response.total }))
      .catch(error => this.snackbarService.error(error.error.message));
  
  displayDeleteDialog = keychain => this.dialog.open(DeleteDialogComponent, {
    data: {
      title: 'keychains.delete.TITLE',
      msg: this.translateService.instant('keychains.delete.BODY', {
        code: keychain.code,
        tag:  ' - ' +  keychain.tag
      })
    },
    width: '520px'
  }).afterClosed().subscribe((result: any) =>
    result?.confirmDelete
    && this.keychainService.deleteKeychain(keychain._id)
      .then(() => this.getKeychains(this.tableConfig.pagination as Pagination)))


  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [],
    buttons: [
      new FiltersButtonLink({
        text: 'ADD',
        icon: 'add',
        link: [ABS_BASE_ROUTE, SECTIONS.workforce, KEYCHAIN_ROUTES.parent, KEYCHAIN_ROUTES.add]
      }),
      new FiltersButtonLink({
        text: 'UPLOAD_FILE',
        icon: 'folder',
        link: [ABS_BASE_ROUTE, SECTIONS.workforce, KEYCHAIN_ROUTES.parent, KEYCHAIN_ROUTES.file]
      })
    ]
  })


  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'keychains.table',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      size: 10,
      page: 1,
      search: '',
      params: { deleted: false },
      sort: {
        field: 'code',
        order: 1
      }
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: keychain => keychain.code
      }),
      new TableColumnText({
        header: 'TAG',
        name: 'tag',
        sorting: 'tag',
        displayAt: 640,
        value: keychain => keychain.tag
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: keychain => [ABS_BASE_ROUTE, SECTIONS.workforce, KEYCHAIN_ROUTES.parent, KEYCHAIN_ROUTES.detail, keychain.id]
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            link: keychain => [ABS_BASE_ROUTE, SECTIONS.workforce, KEYCHAIN_ROUTES.parent, KEYCHAIN_ROUTES.edit, keychain.id]
          }),
         new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: keychain => this.displayDeleteDialog(keychain)
          }) 
        ]
      })
    ]
  })

}
