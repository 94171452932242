import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { ItemsComponent } from '@routes/items/items.component';
import { ItemsTableComponent } from '@routes/items/items-table/items-table.component';
import { ItemsUploadComponent } from '@routes/items/items-upload/items-upload.component';
import { ITEMS_ROUTES } from '@shared/constants/routes';

const routes: Routes = [
  {
    path: '',
    component: ItemsComponent,
    children: [
      {
        path: '',
        component: ItemsTableComponent,
        pathMatch: 'full'
      },
      {
        path: ITEMS_ROUTES.upload,
        component: ItemsUploadComponent,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  declarations: [
    ItemsComponent,
    ItemsTableComponent,
    ItemsUploadComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ]
})
export class ItemsModule { }
