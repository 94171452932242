import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { HeaderService } from '@layout/header/header.service';
import { ICrop } from '@routes/crops/crop.model';
import { CropService } from '@routes/crops/crop.service';

import { FiltersButtonLink, FiltersConfiguration, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnChip, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { CROP_TYPES } from '@shared/constants/crops';
import { Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { CropsRoutingService } from '@routes/crops/crops-routing.service';

@Component({
  selector: 'app-crops-main',
  templateUrl: './crops-main.component.html',
  styleUrls: ['./crops-main.component.scss']
})
export class CropsMainComponent implements OnInit {

  tableConfig: TableConfiguration<ICrop>;
  filtersConfig: FiltersConfiguration;

  constructor(
    private cropsService: CropService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private headerService: HeaderService,
    private cropsRoutingService: CropsRoutingService
  ) {}

  ngOnInit(): void {
    this.setupFilters();
    this.setupTable();
    this.headerService.typeFilterChanges().subscribe(type => {
      Object.assign(this.tableConfig.pagination.params, { type });
      this.tableConfig.updatePagination.next(this.tableConfig.pagination as FiltersEvent);
    });
  }

  getCrops = (pagination: Pagination) => this.cropsService.getCrops(pagination)
    .then(response => this.tableConfig.data.next(response))
    .catch(error => this.snackbarService.error(error.error.message));

  setupFilters = () => this.filtersConfig = new FiltersConfiguration({
    buttons: [
      new FiltersButtonLink({
        text: 'NEW',
        icon: 'add',
        link: this.cropsRoutingService.add()
      })
    ],
    controls: []
  })

  setupTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'crops.table',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1, size: 10, search: '',
      sort: { field: 'name', order: 1 },
      params: (this.headerService.typeFilter ? { type: this.headerService.typeFilter } : {}),
    },
    columns: [
      new TableColumnText({
        header: 'NAME',
        name: 'name',
        sorting: 'name',
        displayAt: 0,
        value: crop => crop.name
      }),
      new TableColumnText({
        header: 'VARIETY',
        name: 'variety',
        sorting: 'variety',
        displayAt: 400,
        value: crop => crop.variety
      }),
      new TableColumnText({
        header: 'TRACE_CODE',
        name: 'traceCode',
        sorting: 'traceCode',
        displayAt: 960,
        value: crop => crop.traceCode
      }),
      new TableColumnChip({
        header: 'TYPE',
        name: 'type',
        sorting: 'type',
        displayAt: 768,
        value: crop => this.translateService.instant(`general.types.${crop.type.toUpperCase()}`),
        color: crop => crop.type === CROP_TYPES.FRUIT ? 'warn' : 'primary'
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: crop => this.cropsRoutingService.detail(crop._id)
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            link: crop => this.cropsRoutingService.edit(crop._id)
          })
        ]
      })
    ]
  });

}
