import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

import { COMPANY_OWNERSHIP } from '@routes/companies/companies.constants';
import { ICompanyPaginated, ICompanyReceived } from '@routes/companies/companies.model';
import { CompaniesService } from '@routes/companies/companies.service';

import { COMPANIES_ROUTES } from '@shared/constants/routes';
import { Pagination } from '@shared/models/pagination.model';
import { PaginationService } from '@shared/services/pagination.service';
import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-companies-table',
  templateUrl: './companies-table.component.html',
  styleUrls: ['./companies-table.component.scss']
})
export class CompaniesTableComponent implements OnInit {

  companies: ICompanyReceived[] = [];
  total: number = 0;
  form: FormGroup;

  routes = COMPANIES_ROUTES;
  ownerships = COMPANY_OWNERSHIP;
  addLink = [COMPANIES_ROUTES.add];

  pagination = new Pagination({
    page: 1, size: 10, search: '', params: {},
    sort: { field: 'businessName', order: 1 } });
  columns: string[] = [
    'businessName', 'identification', 'ownership',
    'address', 'traceCode', 'sageReference', 'actions'];

  constructor(
    private paginationService: PaginationService,
    private companiesService: CompaniesService,
    private snackbarService: CustomSnackbarService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getCompanies();
  }

  setForm = () => {
    this.form = new FormGroup({
      ownership: new FormControl('')
    });

    this.form.valueChanges.subscribe(value => {
      this.pagination.params = value;
      this.getCompanies();
    });
  }

  getCompanies = () => this.companiesService.getCompanies(this.pagination)
    .then(response => {
      this.companies = response.data;
      this.total = response.total;
    })
    .catch(error => this.snackbarService.error(error.error.message));

  searchCompanies = (search: string) => {
    this.pagination.search = search;
    this.getCompanies();
  }

  sortCompanies = (event: Sort) => {
    this.pagination = this.paginationService.sort(this.pagination, event);
    this.getCompanies();
  }

  paginateCompanies = (event: PageEvent) => {
    this.pagination = this.paginationService.paginate(this.pagination, event);
    this.getCompanies();
  }

}
