import { Component, OnInit, OnDestroy } from '@angular/core';
import { WORKER_HISTORICS, ITYPE } from '@shared/interfaces/types';
import { HISTORIC_SECTIONS, WorkerService } from '@routes/workers/worker.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkersDetailService } from '../../workers-detail.service';
import { ABS_BASE_ROUTE, SECTIONS, WORKER_ROUTES } from '@shared/constants/routes';
import { WorkerReceived } from '@routes/workers/worker.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-workers-detail-historic-table',
  templateUrl: './workers-detail-historic-table.component.html',
  styleUrls: ['./workers-detail-historic-table.component.scss']
})
export class WorkersDetailHistoricTableComponent implements OnInit, OnDestroy {

  workersList: string[] = [ABS_BASE_ROUTE, SECTIONS.workforce, WORKER_ROUTES.parent];
  columns: string[] = ['name', 'startDate', 'endDate', 'actions'];
  historics: ITYPE[] = WORKER_HISTORICS;

  worker: WorkerReceived;
  data: any[] = [];
  section: string;
  editMode: boolean;
  historicEditable: boolean;
  subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private detailService: WorkersDetailService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.detailService.selectorObservable.subscribe(section => {
      this.section = section;

      this.section === HISTORIC_SECTIONS.CAMPAIGNS
        ? this.isNotEditable() : this.isEditable();

      this.data = this.detailService.getHistoricData(this.section);
    }));

    this.subscriptions.push(this.detailService.workerObservable.subscribe(worker => {
      this.worker = worker;

      if (this.section) {
        this.data = this.detailService.getHistoricData(this.section);
      }
    }));

    this.editMode = this.detailService.editMode;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  isEditable = (): void => {
    this.columns = ['name', 'startDate', 'endDate', 'actions'];
    this.historicEditable = true;
  }

  isNotEditable = (): void => {
    this.columns.splice(this.columns.indexOf('actions'), 1);
    this.historicEditable = false;
  }

  getHistoricTag = (): string => this.detailService.getHistoricTag(this.section);

  getHistoricNameCell = (element: any): string => {
    switch (this.section) {
      case HISTORIC_SECTIONS.ADDRESSES:
        return element.address;
      case HISTORIC_SECTIONS.CAMPAIGNS:
        return element.campaign.name;
      case HISTORIC_SECTIONS.SITES:
          return element.site.name;
      case HISTORIC_SECTIONS.POSITIONS:
        return element.position.name;
      default:
        return element.agency
        ? element.agency.name
        : this.translateService.instant('workers.detail.historic.WITHOUT_AGENCY');
    }
  }

  addHistoric = () => {
    this.detailService.formMode = 'add';
    this.detailService.setHistoricSection('form');
  }

  editHistoric = (index: number) => {
    this.detailService.formMode = 'edit';
    this.detailService.historicIndex = index;
    this.detailService.setHistoricSection('form');
  }

  deleteHistoric = async (index: number) => {
    this.detailService.worker[this.section].splice(index, 1);
    await this.detailService.editWorkerHistoric(this.worker.id, this.section, this.worker[this.section]);
  }
}
