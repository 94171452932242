import { Injectable } from '@angular/core';
import { getNames, registerLocale, getName } from 'i18n-iso-countries';
import { TranslatorService } from '@core/translator/translator.service';
import { ICountry } from '@shared/interfaces/country.models';

@Injectable({
  providedIn: 'root'
})
export class CountryService  {

  constructor(
    private translateService: TranslatorService
  ) {
    this.init();
  }

  private init = async () => await Promise.all(this.translateService.getAvailableLanguages()
    .map(async ({code}) => registerLocale(await import(`i18n-iso-countries/langs/${code}.json`))));

  getCountries = (): ICountry[] => Object.entries(getNames(this.translateService.getCurrentLanguage()))
    .map(([code, name]) => ({code, name}))
    .sort((a, b) => a.name.localeCompare(b.name));

  getCountryNameByCode = (name: string): string =>
    getName(name, this.translateService.getCurrentLanguage(), {select: 'official'});

}
