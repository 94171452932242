import { Injectable } from '@angular/core';

import { IMenuItem } from '@shared/interfaces/menu';

@Injectable()
export class MenuService {

    menuItems: IMenuItem[] = [];

    constructor() { }

    async addMenu(items: IMenuItem[]) {
        this.menuItems = items;
        return { menu: this.menuItems };
    }

    getMenu() {
        return this.menuItems;
    }

}
