import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { Routes, RouterModule } from '@angular/router';

import { DevicesComponent } from './devices.component';
import { DevicesTableComponent } from './devices-table/devices-table.component';
import { DevicesDialogComponent } from './devices-modal/devices-dialog.component';
import { DEVICES_ROUTES } from '@shared/constants/routes';

const routes: Routes = [
  {
    path: '',
    component: DevicesComponent,
    children: [
      {
        path: DEVICES_ROUTES.main,
        component: DevicesTableComponent,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  declarations: [
    DevicesComponent,
    DevicesTableComponent,
    DevicesDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DevicesModule {}