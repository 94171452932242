import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-recovery-dialog',
  templateUrl: './password-recovery-dialog.component.html',
  styleUrls: ['./password-recovery-dialog.component.scss']
})
export class PasswordRecoveryDialogComponent implements OnInit {

  texts: any;

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.texts = this.translateService.instant('passwordRecovery.dialog');
  }

}
