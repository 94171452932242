<ol class="list">
    <li class="card {{ campaign.type }}" *ngFor="let campaign of campaigns">
        <h2 class="card-name h2">{{ campaign.name }}</h2>
        <div class="card-chip chip {{ campaign.type }}">
            <img class="chip-img" src="/assets/images/icons/chips/{{ campaign.type }}.svg" alt="#" />
            <span class="chip-txt">{{ campaignService.getCampaignTypeTranslation(campaign.type) }}</span>
        </div>
        <p class="card-date">{{ campaign.startDate | date: 'longDate' }} · {{ campaign.endDate | date: 'longDate' }}</p>
        <div class="card-action">
            <button class="card-button" [routerLink]="CAMPAIGN_DETAIL_BASE_ROUTE.concat(campaign._id)">
                <img class="card-icon" src="/assets/images/icons/search.svg" alt="{{ 'general.alt.EDIT' | translate }}" />
            </button>
        </div>
    </li>
</ol>
<mat-paginator [length]="paginationTotal" [pageSize]="pagination.size" [pageSizeOptions]="[3, 5, 10]"
    (page)="paginateCampaigns($event)">
</mat-paginator>