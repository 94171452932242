import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@routes/users/user.service';
import { LOGIN_ROUTE } from '@shared/constants/routes';
import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-password-recovery-code-expired-dialog',
  templateUrl: './password-recovery-code-expired-dialog.component.html',
  styleUrls: ['./password-recovery-code-expired-dialog.component.scss']
})
export class PasswordRecoveryCodeExpiredDialogComponent implements OnInit {

  email: string;
  texts: any;

  constructor(
    public dialogRef: MatDialogRef<PasswordRecoveryCodeExpiredDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: CustomSnackbarService,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.email = this.data.email;
    this.texts = this.translateService.instant('passwordRecovery.codeExpiredDialog')
  }

  resendRecovery = () => {
    this.userService.recoverPassword(this.email).then(() =>  {
      this.snackbarService.success(this.translateService.instant('passwordRecovery.dialog.SUCCESS'));
      this.dialogRef.close();
      this.router.navigate([LOGIN_ROUTE])
    });
  }

}
