import { Component, Input, OnInit } from '@angular/core';
import { DISPATCH_NOTE_UNIT_PER_TYPE } from '@routes/dispatch-notes/dispatch-notes.constants';
import { IDispatchNoteReceived } from '@routes/dispatch-notes/dispatch-notes.model';

@Component({
  selector: 'app-dispatch-notes-detail-description',
  templateUrl: './dispatch-notes-detail-description.component.html',
  styleUrls: ['./dispatch-notes-detail-description.component.scss']
})
export class DispatchNotesDetailDescriptionComponent implements OnInit {

  @Input() dispatchNote: IDispatchNoteReceived;

  unit: string;

  constructor() {}

  ngOnInit(): void {
    this.unit = DISPATCH_NOTE_UNIT_PER_TYPE[this.dispatchNote.type];
  }

}
