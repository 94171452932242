import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, FormGroupDirective, NG_VALIDATORS, Validator } from '@angular/forms';
import { updateValidationAllControls } from '@shared/utils/forms.utils';

@Directive({
  selector: '[appUniqueInValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UniqueInValidatorDirective, multi: true }]
})
export class UniqueInValidatorDirective implements Validator {

  @Input() elements: string[];
  @Input() caseInsensitive = true;

  constructor(private form: FormGroupDirective) { }

  validate(control: AbstractControl): { [key: string]: any } {
    if (!control.value) return null;
    if (!this.elements.length) return null;
    const { array, value } = this.caseInsensitive
    ? { array: this.elements.map(element => element.trim().toUpperCase()), value: control.value.trim().toUpperCase() }
    : { array: this.elements.map(element => element.trim()), value: control.value.trim() };
    return array.includes(value) ? ({ alreadyExists: true }) : null;
  }

  @HostListener('input') onInput() {
    updateValidationAllControls(this.form.control);
  }

}
