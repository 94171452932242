import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICompanyPaginated, ICompanyDeliveryAddress, ICompanyReceived } from '@routes/companies/companies.model';
import { CompaniesService } from '@routes/companies/companies.service';
import { DISPATCH_NOTE_STATUS } from '@routes/dispatch-notes/dispatch-notes.constants';
import { IDispatchNoteReceived, IDispatchNoteSent } from '@routes/dispatch-notes/dispatch-notes.model';
import { DispatchNotesService } from '@routes/dispatch-notes/dispatch-notes.service';
import { IWorkingSite } from '@routes/working-sites/working-site.model';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';
import { GENERIC_REG_EXP, TIME_REG_EXP } from '@shared/constants/regexp';
import { Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { DISPATCH_NOTES_CONFIRMATION_STEPS } from './dispatch-notes-detail-confirmation.constants';

@Component({
  selector: 'app-dispatch-notes-detail-confirmation',
  templateUrl: './dispatch-notes-detail-confirmation.component.html',
  styleUrls: ['./dispatch-notes-detail-confirmation.component.scss']
})
export class DispatchNotesDetailConfirmationComponent implements OnInit {

  dispatchNote: IDispatchNoteReceived;
  companies: ICompanyReceived[] = [];
  sites: IWorkingSite[];
  deliveryAddresses: ICompanyDeliveryAddress[];

  STEPS = DISPATCH_NOTES_CONFIRMATION_STEPS;
  step: DISPATCH_NOTES_CONFIRMATION_STEPS;
  loading: boolean;

  paginationCompanies = new Pagination({
    page: 1, size: Number.MAX_SAFE_INTEGER,
    sort: { field: 'businessName', order: 1 }
  });
  paginationSites = new Pagination({
    page: 1, size: Number.MAX_SAFE_INTEGER,
    sort: { field: 'name', order: 1 }
  });
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<DispatchNotesDetailConfirmationComponent>,
    private fb: FormBuilder,
    private companiesService: CompaniesService,
    private snackbarService: CustomSnackbarService,
    private dispatchNotesService: DispatchNotesService,
    private workingSiteService: WorkingSiteService
  ) {}

  ngOnInit(): void {
    this.step = DISPATCH_NOTES_CONFIRMATION_STEPS.DELIVERY;
    this.dispatchNote = this.data.dispatchNote;

    this.setForm();
    this.getCompanies();
  }

  setForm = () => {
    this.form = this.fb.group({
      delivery: this.fb.group({
        originId: [null, Validators.required],
        destinationId: [null, Validators.required],
        deliveryAddress: [null, Validators.required],
        comments: [null],
      }),
      loadPlace: this.fb.group({
        site: [null],
        time: [null, Validators.pattern(TIME_REG_EXP.HOUR_MINUTES)],
        personInCharge: [null],
        nif: [null],
      }),
      transport: this.fb.group({
        company: [null],
        truckNumber: [null],
        trailerLoadNumber: [null],
        chofer: [null],
        phone: [null, Validators.pattern(GENERIC_REG_EXP.PHONE)],
        nif: [null],
        temperature: [null],
      }),
      reception: this.fb.group({
        name: [null],
        nif: [null],
      }),
      others: this.fb.group({
        comments: [null],
        temperature: [null],
      }),
    });
    this.form.get('delivery.destinationId').valueChanges.subscribe(id => this.updateDeliveryAddresses(id));
  }

  getCompanies = () => this.companiesService.getCompanies(this.paginationCompanies)
    .then(response => {
      this.companies = response.data;
      this.selectDefaultCompanies();
    })
    .catch(error => this.snackbarService.error(error.error.message));

  selectDefaultCompanies = () => {
    if (this.dispatchNote.site.companyId) {
      const company = this.companies.find(company => company._id === this.dispatchNote.site.companyId);
      this.form.get('delivery.originId').setValue(company._id);
    }
    const company = this.companies.find(company => company.businessName === 'Vivero El Pinar');
    this.form.get('delivery.destinationId').setValue(company._id);
  }

  updateDeliveryAddresses = (destinationId: string) => {
    const destination = this.companies.find(company => company._id === destinationId);
    this.deliveryAddresses = destination.deliveryAddresses;
    
    if (this.deliveryAddresses.length === 1) {
      this.form.get('delivery.deliveryAddress').setValue(this.deliveryAddresses[0]);
    }
  }

  findCurrentStepIndex = () => Object.values(this.STEPS).indexOf(this.step);

  previousStep = () => this.step = Object.values(this.STEPS)[this.findCurrentStepIndex() - 1];

  nextStep = () => this.step = Object.values(this.STEPS)[this.findCurrentStepIndex() + 1];

  submitForm = () => {
    if (this.form.invalid) return;

    const formData = {
      ...this.form.value.delivery,
      ...this.form.value,
      ...this.form.value.others,
    };
    const dispatchNote: IDispatchNoteSent = {
      ...this.dispatchNote,
      ...formData,
      status: DISPATCH_NOTE_STATUS.CLOSED
    };
    this.loading = true;

    this.dispatchNotesService.editDispatchNote(this.dispatchNote._id, dispatchNote)
      .then(() => {
        this.closeDialog({ updated: true });
        this.loading = false;
      })
      .catch(error => this.snackbarService.error(error.error.message));
  }

  closeDialog = (data?: any) => this.dialog.close(data);

}
