import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { CampaignsRoutingService } from '@routes/campaigns/campaigns-routing.service';
import { TasksEditOrigin, TasksEditSection } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.constants';
import { WorkingDayFormOrigin, WorkingDayFormSection } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-form/campaigns-detail-working-days-form.constants';

import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { IErrorInterval, ITaskReceived } from '@shared/models/task.model';
import { IWorkingDayBreak, WorkingDay, WorkingDayErrors } from '@shared/models/working-day.model';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';

@Component({
  selector: 'app-campaigns-detail-working-days-table-errors-dialog',
  templateUrl: './campaigns-detail-working-days-table-errors-dialog.component.html',
  styleUrls: ['./campaigns-detail-working-days-table-errors-dialog.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailWorkingDaysTableErrorsDialogComponent implements OnInit {

  workingDayTableConfiguration: TableConfiguration<WorkingDay>;
  breaksTableConfiguration: TableConfiguration<IWorkingDayBreak>;
  tasksTableConfiguration: TableConfiguration<ITaskReceived>;
  intervalsTableConfiguration: TableConfiguration<IErrorInterval>;
  workingDay: WorkingDay;
  errors: WorkingDayErrors;
  texts: any;
  
  dateFormat = 'dd/MM (HH:mm)';
  timeFormat = 'HH:mm';
  exitButtonConfig: IGenericButtonConfig = {
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button,
    text: 'Cerrar'};

  constructor(
    private campaignsRoutingService: CampaignsRoutingService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private router: Router,
    private dialog: MatDialogRef<CampaignsDetailWorkingDaysTableErrorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.workingDay = this.data.workingDay;
    this.errors = new WorkingDayErrors(this.workingDay);
    this.texts = Object.values(this.translateService.instant([
      'general.entities',
      'campaigns.workingDays.errors_dialog']
    )).reduce((prev, curr) => Object.assign(prev, curr), {});
    this.initTables();
  }

  closeDialog = () => this.dialog.close();

  initTables = () => {
    this.initWorkingDayTable();
    this.initBreaksTable();
    this.initTasksTable();
    this.initIntervalsTable();
  }

  initWorkingDayTable = () => this.workingDayTableConfiguration = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    data: new BehaviorSubject({data: [this.errors.workingDay], total: 1}),
    pagination: {page: 1, size: 5, search: null, sort: {field: 'startTimestamp', order: 1}},
    columns: [
      new TableColumnText({
        header: this.texts.START_DATE,
        name: 'startTimestamp',
        sorting: 'startTimestamp',
        displayAt: 768,
        value: workingDay => this.datePipe.transform(workingDay.startTimestamp, this.dateFormat, 'GMT')
      }),
      new TableColumnText({
        header: this.texts.END_DATE,
        name: 'endTimestamp',
        sorting: 'endTimestamp',
        displayAt: 960,
        value: workingDay => this.datePipe.transform(workingDay.endTimestamp, this.dateFormat, 'GMT')
      }),
      new TableColumnText({
        header: this.texts.ERROR,
        name: 'error',
        displayAt: 0,
        value: workingDay => this.texts[workingDay.error]
      }),
      new TableColumnMenu({
        header: this.texts.ACTIONS,
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            icon: GenericMenuOptionIcon.Edit,
            text: 'EDIT',
            action: () => {
              const route = this.campaignsRoutingService.editWorkingDay(this.workingDay.campaignId, this.workingDay._id);
              const queryParams = {origin: WorkingDayFormOrigin.Errors}
              this.dialog.close();
              this.router.navigate(route, {queryParams});
            }
          })
        ]
      })
    ]
  });

  initBreaksTable = () => this.breaksTableConfiguration = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    data: new BehaviorSubject({data: this.errors.breaks, total: this.errors.breaks.length}),
    pagination: {page: 1, size: 5, search: null, sort: {field: 'startTimestamp', order: 1}},
    columns: [
      new TableColumnText({
        header: this.texts.START_DATE,
        name: 'startTimestamp',
        sorting: 'startTimestamp',
        displayAt: 768,
        value: br => this.datePipe.transform(br.startTimestamp, this.dateFormat, 'GMT')
      }),
      new TableColumnText({
        header: this.texts.END_DATE,
        name: 'endTimestamp',
        sorting: 'endTimestamp',
        displayAt: 960,
        value: br => this.datePipe.transform(br.endTimestamp, this.dateFormat, 'GMT')
      }),
      new TableColumnText({
        header: this.texts.ERROR,
        name: 'error',
        displayAt: 0,
        value: br => this.texts[br.error]
      }),
      new TableColumnMenu({
        header: this.texts.ACTIONS,
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            icon: GenericMenuOptionIcon.Edit,
            text: 'EDIT',
            action: () => {
              const route = this.campaignsRoutingService.editWorkingDay(this.workingDay.campaignId, this.workingDay._id);
              const queryParams = {section: WorkingDayFormSection.Breaks, origin: WorkingDayFormOrigin.Errors};
              this.dialog.close();
              this.router.navigate(route, {queryParams});
            }
          })
        ]
      })
    ]
  });

  initTasksTable = () => this.tasksTableConfiguration = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    data: new BehaviorSubject({data: this.errors.tasks, total: this.errors.tasks.length}),
    pagination: {page: 1, size: 5, search: null, sort: {field: 'startTimestamp', order: 1}},
    columns: [
      new TableColumnText({
        header: this.texts.TASK,
        name: 'taskType',
        sorting: 'taskType.name',
        displayAt: 640,
        value: task => task.taskType.name
      }),
      new TableColumnText({
        header: this.texts.START_DATE,
        name: 'startTimestamp',
        sorting: 'startTimestamp',
        displayAt: 960,
        value: task => this.datePipe.transform(task.startTimestamp, this.dateFormat, 'GMT')
      }),
      new TableColumnText({
        header: this.texts.END_DATE,
        name: 'endTimestamp',
        sorting: 'endTimestamp',
        displayAt: 1024,
        value: task => this.datePipe.transform(task.endTimestamp, this.dateFormat, 'GMT')
      }),
      new TableColumnText({
        header: this.texts.ERROR,
        name: 'error',
        displayAt: 0,
        value: task => this.texts[task.error]
      }),
      new TableColumnMenu({
        header: this.texts.ACTIONS,
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            icon: GenericMenuOptionIcon.Edit,
            text: 'EDIT',
            action: task => {
              const route = this.campaignsRoutingService.editTask(this.workingDay.campaignId, this.workingDay._id, task._id);
              const queryParams = {origin: TasksEditOrigin.Errors};
              this.dialog.close();
              this.router.navigate(route, {queryParams});
            }
          })
        ]
      })
    ]
  });

  initIntervalsTable = () => this.intervalsTableConfiguration = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    data: new BehaviorSubject({data: this.errors.intervals, total: this.errors.intervals.length}),
    pagination: {page: 1, size: 5, search: null, sort: {field: 'startTimestamp', order: 1}},
    columns: [
      new TableColumnText({
        header: this.texts.TASK,
        name: 'taskType',
        sorting: 'taskType.name',
        displayAt: 640,
        value: interval => interval.taskName
      }),
      new TableColumnText({
        header: this.texts.TIME,
        name: 'endTimestamp',
        sorting: 'endTimestamp',
        displayAt: 1024,
        value: task => this.datePipe.transform(task.endTimestamp, this.timeFormat, 'GMT')
      }),
      new TableColumnText({
        header: this.texts.BOXES,
        name: 'amount',
        sorting: 'amount',
        displayAt: 1024,
        value: interval => interval.amount
      }),
      new TableColumnText({
        header: this.texts.ERROR,
        name: 'error',
        displayAt: 0,
        value: interval => this.texts[interval.error]
      }),
      new TableColumnMenu({
        header: this.texts.ACTIONS,
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            icon: GenericMenuOptionIcon.Edit,
            text: 'EDIT',
            action: interval => {
              const route = this.campaignsRoutingService.editTask(this.workingDay.campaignId, this.workingDay._id, interval.taskId);
              const queryParams = {section: TasksEditSection.Intervals,origin: TasksEditOrigin.Errors, interval: interval._id};
              this.dialog.close();
              this.router.navigate(route, {queryParams});
            }
          })
        ]
      })
    ]
  });

}
