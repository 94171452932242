import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICampaign } from '@routes/campaigns/campaign.model';
import { ReportService } from '@routes/campaigns/campaigns-detail/campaigns-detail-reports/report.service';
import { GenericFiltersService } from '@shared/components/generic-filters/generic-filters-service.service';
import { LayoutDirection } from '@shared/components/generic-filters/generic-filters.constants';
import { FiltersButtonAction, FiltersButtonLink, FiltersConfiguration, FiltersControlDate, FiltersControlGroupSelect, FiltersControlSelect, FiltersControlTime, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { PRINT_CLASSES } from '@shared/constants/print';
import { TIME_REG_EXP } from '@shared/constants/regexp';
import { REPORTS_FILTER_INPUTS } from '@shared/constants/reports';
import { Pagination } from '@shared/models/pagination.model';
import { ITaskHarvestReport } from '@shared/models/reports.model';
import { IWorkingDayDevice } from '@shared/models/working-day.model';
import { HourPipe } from '@shared/pipes/hour.pipe';
import { PrintService } from '@shared/services/print.service';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-campaigns-detail-reports-harvest',
  templateUrl: './campaigns-detail-reports-harvest.component.html',
  styleUrls: ['./campaigns-detail-reports-harvest.component.scss'],
  providers: [HourPipe]
})
export class CampaignsDetailReportsHarvestComponent implements OnInit, OnDestroy {


  title: string;
  filters: { string: any };
  campaign: ICampaign;
  devices: IWorkingDayDevice[];
  harvestReport: ITaskHarvestReport = null;
  filtersConfig: FiltersConfiguration;

  pagination = new Pagination();
  unit: string;
  filtersValue: object;
  areFiltersValid = true;
  loading = false;
  loadingWarning = true;

  @HostListener('window:afterprint') afterPrint() {
    this.printService.endPrint();
  }
  constructor(
    private printService: PrintService,
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private snackbarService: CustomSnackbarService,
    private filterService: GenericFiltersService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    Object.assign(this, this.activatedRoute.snapshot.data.data);
    this.getDataFromRouter('title');
    this.getDataFromRouter('filters');
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.snackbarService.snackBar.dismiss();
    this.filterService.removeFiltersValue();

  }

  setupForm = async () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    this.filtersConfig = new FiltersConfiguration({
      search: false,
      layout: LayoutDirection.Vertical,
      controls: [
        new FiltersControlDate({
          label: 'START_DATE',
          name: REPORTS_FILTER_INPUTS.START_DATE,
          value: this.filters[REPORTS_FILTER_INPUTS.START_DATE],
          maxDateReferenceName: REPORTS_FILTER_INPUTS.END_DATE,
          timeReferenceName: REPORTS_FILTER_INPUTS.START_TIME,
        }),
        new FiltersControlTime({
          label: 'START_TIME',
          name: REPORTS_FILTER_INPUTS.START_TIME,
          value: '00:00',
          dateReferenceName: REPORTS_FILTER_INPUTS.START_DATE,
          validatorFn: Validators.pattern(TIME_REG_EXP.HOUR_MINUTES)
        }),
        new FiltersControlDate({
          label: 'END_DATE',
          name: REPORTS_FILTER_INPUTS.END_DATE,
          value: this.filters[REPORTS_FILTER_INPUTS.END_DATE],
          minDateReferenceName: REPORTS_FILTER_INPUTS.START_DATE,
          timeReferenceName: REPORTS_FILTER_INPUTS.END_TIME,
        }),
        new FiltersControlTime({
          label: 'END_TIME',
          name: REPORTS_FILTER_INPUTS.END_TIME,
          value: '23:59',
          dateReferenceName: REPORTS_FILTER_INPUTS.END_DATE,
          validatorFn: Validators.pattern(TIME_REG_EXP.HOUR_MINUTES)
        }),
        new FiltersControlSelect({
          label: 'SITES',
          name: REPORTS_FILTER_INPUTS.SITE_IDS,
          value: [],
          options: new BehaviorSubject(this.campaign.sites.map(site => ({ value: site.siteId, display: site.siteName })))
        }),
        new FiltersControlSelect({
          label: 'DEVICES',
          name: REPORTS_FILTER_INPUTS.DEVICE_IDS,
          value: [],
          options: new BehaviorSubject(this.devices.map(device => ({ value: device._id, display: device.name })))
        })],
      buttons: [
        new FiltersButtonAction({
          text: 'GENERATE_REPORT',
          icon: 'report',
          action: this.retrieveReport,
          disabled: () => this.loading || !this.areFiltersValid
        }),
        new FiltersButtonAction({
          text: 'EXPORT_EXCEL',
          icon: 'download',
          action: this.startPrint,
          disabled: () => !this.harvestReport
        }),
        new FiltersButtonLink({
          text: 'BACK',
          link: ['..']
        }),
      ]
    });
  }

  retrieveReport = () => {
    this.loading = true;
    this.loadingWarning = false;
    this.harvestReport = null;
    this.snackbarService.loading(this.translateService.instant('reports.messages.LOADING_REPORT'));

    this.pagination.params = { ...this.filtersValue, campaignId: this.campaign._id };
    this.pagination.params = Object.assign({}, ...Object.entries(this.pagination.params).filter(([key, value]) => Array.isArray(value) ? value.length : true).map(([key, value]) => ({ [key]: value })));
    this.reportService.getHarvestReport(this.pagination).then(report => {
      this.harvestReport = report;
      this.snackbarService.snackBar.dismiss();
    })
      .catch(() => this.snackbarService.error(this.translateService.instant('reports.messages.ERROR_LOADING')))
      .finally(() => this.loading = false);
  }

  startPrint = () => this.printService.startPrint([
    PRINT_CLASSES.LAYOUT_PRINT,
    PRINT_CLASSES.LAYOUT_FULL_HEIGHT,
    PRINT_CLASSES.HIDE_SIDEBAR,
    PRINT_CLASSES.HIDE_SYNC_ERROR_MESSAGE
  ])

  updateFilters = (event: FiltersEvent) => {
    this.filtersValue = event.params;
    this.harvestReport = null;
    this.loading = false;
    this.loadingWarning = true;
  }

  updateSubmitButton = (isValid: boolean) => this.areFiltersValid = isValid;

  getDataFromRouter = (key: string) => this[key] = this.activatedRoute.snapshot.data[key];

}
