import { Directive, HostListener, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { updateValidationAllControls } from '@shared/utils/forms.utils';

@Directive({
  selector: '[appUpdateHour]'
})
export class UpdateHourDirective {

  @Input() formControlName: string;
  @Input() timeControlReference: string;
  @Input() modifier: (date: Date) => Date;

  constructor(
    private form: FormGroupDirective
  ) { }

  @HostListener('dateChange') onDateChange() {
    const control = this.form.control.get(this.formControlName);
    if (this.modifier) this.modifier(control.value);

    if (!this.timeControlReference) return updateValidationAllControls(this.form.control);

    const timeControl = this.form.control.get(this.timeControlReference);
    const [hours, minutes] = timeControl.value.split(':').map(element => parseInt(element, 10));
    const currentDate = control.value as Date;
    control.setValue(new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      minutes));
    updateValidationAllControls(this.form.control);
  }

}
