import { AbstractControl, FormArray, ValidatorFn } from "@angular/forms";

export const passwordMatchValidator = (controlName: string) => (control: AbstractControl) => {
    const password = control.parent?.get(controlName).value;
    if (!password) return null;

    const repeat = control.value;
    return password === repeat ? null : {mismatch: true};
}

export const minLengthArray = (min: number) => (control: AbstractControl) => {
    if (!(control instanceof FormArray)) throw new Error('Control has to be a Form Array');
    return (control as FormArray).length >= min ? null : {minLength: false};
}

export const maxLengthArray = (max: number) => (control: AbstractControl) => {
    if (!(control instanceof FormArray)) throw new Error('Control has to be a Form Array');
    return (control as FormArray).length <= max ? null : {maxLength: false};
}

export const maxDecimals =
  (max: number): ValidatorFn =>
  (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    const value = control.value.toString() as string;
    if (max < 0) {
      throw new Error('Decimals must be greater or equal to 0');
    }
    if (!new RegExp(/^\d*([,]|[.])?\d*$/).test(value)) {
      throw new Error('Invalid number value for decimal validator');
    }
    const current = value.replace(',', '.').split('.')[1]?.length ?? 0;
    return current <= max ? null : { maxDecimals: { current, max } };
  };
