<h2 class="title h2">{{ title }}</h2>
<form class="form" [formGroup]="form" (submit)="submitForm()">
    <!-- Company data -->
    <section class="section basic" *ngIf="section === 'basic'">
        <!-- Business name -->
        <mat-form-field class="input full">
            <mat-label>{{ 'companies.entities.BUSINESS_NAME' | translate }}</mat-label>
            <input matInput type="text" formControlName="businessName"/>
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Identification -->
        <mat-form-field class="input half left">
            <mat-label>{{ 'companies.entities.IDENTIFICATION' | translate }}</mat-label>
            <input matInput type="text" formControlName="identification"/>
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Ownership -->
        <mat-form-field class="input half right">
            <mat-label>{{ 'companies.entities.OWNERSHIP' | translate }}</mat-label>
            <mat-select formControlName="ownership">
                <mat-option *ngFor="let ownership of COMPANY_OWNERSHIP | keyvalue" [value]="ownership.value">
                    {{ 'companies.ownerships.' + ownership.key.toUpperCase() | translate }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Address -->
        <mat-form-field class="input full">
            <mat-label>{{ 'companies.entities.ADDRESS' | translate }}</mat-label>
            <input matInput type="text" formControlName="address"/>
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- City -->
        <mat-form-field class="input half">
            <mat-label>{{ 'companies.entities.CITY' | translate }}</mat-label>
            <input matInput type="text" formControlName="city"/>
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- State -->
        <mat-form-field class="input half">
            <mat-label>{{ 'companies.entities.STATE' | translate }}</mat-label>
            <input matInput type="text" formControlName="state"/>
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- ZipCode -->
        <mat-form-field class="input half">
            <mat-label>{{ 'companies.entities.ZIPCODE' | translate }}</mat-label>
            <input matInput type="text" formControlName="zipcode"/>
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Country -->
        <mat-form-field class="input half">
            <mat-label>{{ 'companies.entities.COUNTRY' | translate }}</mat-label>
            <input matInput type="text" formControlName="country" />
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Dispatch Note Codes -->
        <mat-form-field formGroupName="dispatchNoteCodes" class="input half left ">
            <mat-label>{{ 'companies.entities.DISPATCH_NOTE_CODES_FRUIT' | translate }}</mat-label>
            <input matInput type="text" formControlName="fruit" appDifferentFrom fomControlNameDifferentTo="dispatchNoteCodes.plant"/>
            <mat-error *ngIf="form.get('dispatchNoteCodes.fruit').errors as errors">
                <ng-container *ngIf="errors.repeatedValue">
                    <p>{{ 'general.errors.fields.REPEATED_FIELD' | translate }}</p>
                </ng-container>
                <ng-container *ngIf="errors.pattern">
                    <p>{{ 'companies.errors.DISPATCH_NOTE_CODE_ALREADY_IN_USE' | translate }}</p>
                </ng-container>
            </mat-error>
        </mat-form-field>
        <mat-form-field  formGroupName="dispatchNoteCodes" class="input half right">
            <mat-label>{{ 'companies.entities.DISPATCH_NOTE_CODES_PLANT' | translate }}</mat-label>
            <input matInput type="text" formControlName="plant" appDifferentFrom fomControlNameDifferentTo="dispatchNoteCodes.fruit"/>
            <mat-error *ngIf="form.get('dispatchNoteCodes.plant').errors as errors">
                <ng-container *ngIf="errors.repeatedValue">
                    <p>{{ 'general.errors.fields.REPEATED_FIELD' | translate }}</p>
                </ng-container>
                <ng-container *ngIf="errors.pattern">
                    <p>{{ 'companies.errors.DISPATCH_NOTE_CODE_ALREADY_IN_USE' | translate }}</p>
                </ng-container>
            </mat-error>
        </mat-form-field>
        <!-- Has SAGE reference -->
        <div class="input full">
            <mat-checkbox color="primary" [disabled]="mode === FORM_MODES.detail" (change)="enableSageReference($event.checked)" [(ngModel)]="hasSageReference" [ngModelOptions]="{ standalone: true }">
                {{ 'companies.entities.HAS_SAGE_REFERENCE' | translate }}
            </mat-checkbox>
        </div>
        <!-- SAGE reference -->
        <mat-form-field class="input full" *ngIf="hasSageReference">
            <mat-label>{{ 'companies.entities.SAGE_REFERENCE' | translate }}</mat-label>
            <input matInput type="text" formControlName="sageReference"/>
            <mat-hint *ngIf="mode !== FORM_MODES.detail">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
    </section>
    <!-- Delivery addresses -->
    <section class="deliveryAddresses" *ngIf="section === 'deliveryAddresses'">
        <app-companies-edit-delivery-addresses
            [deliveryAddresses]="form.value.deliveryAddresses"
            [mode]="mode"
            (addressesUpdated)="updateDeliveryAddresses($event)">
        </app-companies-edit-delivery-addresses>
    </section>
    <!-- Form buttons -->
    <div class="buttons">
        <button mat-flat-button color="primary" class="btn" [routerLink]="exitRoute" type="button" *ngIf="mode === FORM_MODES.detail">{{ 'general.actions.BACK' | translate }}</button>
        <button mat-flat-button color="warn" class="btn" [routerLink]="exitRoute" type="button" *ngIf="mode !== FORM_MODES.detail">{{ 'general.actions.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" class="btn" [disabled]="form.invalid || loading" type="submit" *ngIf="mode !== FORM_MODES.detail">{{ 'general.actions.CONFIRM' | translate }}</button>
    </div>
</form>
<mat-form-field appearance="fill" class="selector">
    <mat-label>{{ 'companies.messages.FORM_SECTION' | translate }}</mat-label>
    <mat-select value="basic" (selectionChange)="selectSection($event)">
        <mat-option value="basic">{{ 'companies.messages.COMPANY_DATA' | translate }}</mat-option>
        <mat-option value="deliveryAddresses">{{ 'companies.messages.DELIVERY_ADDRESSES' | translate }}</mat-option>
    </mat-select>
</mat-form-field>