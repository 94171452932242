import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

import { CropService } from '@routes/crops/crop.service';
import { ICrop } from '@routes/crops/crop.model';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { CROP_TYPES } from '@shared/interfaces/types';
import { FORM_MODES } from '@shared/constants/forms';
import { SECTIONS, CROPS_ROUTES, ABS_BASE_ROUTE } from '@shared/constants/routes';

@Component({
  selector: 'app-crops-edit',
  templateUrl: './crops-edit.component.html',
  styleUrls: ['./crops-edit.component.scss']
})
export class CropsEditComponent implements OnInit {

  EXIT_ROUTE = [ABS_BASE_ROUTE, SECTIONS.production, CROPS_ROUTES.parent];
  FORM_MODES = FORM_MODES;
  CROP_TYPES = CROP_TYPES;

  loading = false;
  form: FormGroup;
  mode: string;
  title: string;
  cropId: string;

  constructor(
    private translateService: TranslateService,
    private cropService: CropService,
    private snackbarService: CustomSnackbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      variety: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required)
    });

    this.cropId = this.activatedRoute.snapshot.params.id;
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.title = this.translateService.instant(`crops.titles.${this.mode.toUpperCase()}`);

    if (this.mode === FORM_MODES.detail) this.form.disable();
    if (this.mode !== FORM_MODES.add) this.retrieveCrop();
  }

  retrieveCrop = () => this.cropService.getCrop(this.cropId).then((site: ICrop) => this.form.patchValue(site));

  submitForm = () => {
    this.loading = true;
  
    const crop = { id: this.cropId, ...this.form.value };
    const fn = this.mode === FORM_MODES.add
      ? this.cropService.addCrop(crop)
      : this.cropService.editCrop(this.cropId, crop);
  
    fn
      .then(() => this.router.navigate(this.EXIT_ROUTE))
      .catch((error: HttpErrorResponse) => this.snackbarService.error(error.error.message))
      .finally(() => {
        this.loading = false;
      });
  }

}
