import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { CampaignsDetailTasksEditService } from "@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.service";

@Injectable()
export class CampaignsDetailTasksEditResolver implements Resolve<boolean> {

    constructor(
        private tasksEditService: CampaignsDetailTasksEditService
    ) {}

    resolve = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
        await this.tasksEditService.initService(route);
        return true;
    }

}