import { DeviceReceived } from '@routes/devices/device.model';
import { Campaign } from '@routes/campaigns/campaign.model';
import { WorkerReceived } from '@routes/workers/worker.model';
import { WorkingSite } from '@routes/working-sites/working-site.model';

import { BreakError, WorkingDayError, WORKING_DAY_STATUSES } from '@shared/constants/working-day';
import { IHarvestInterval, ITaskReceivedPackaging, ITaskReceivedCrop, IProductionInterval, ITaskReceived, IErrorInterval } from '@shared/models/task.model';

export interface IWorkingDayPaginated {
    workingDays: WorkingDay[];
    total: number;
}

export interface IWorkingDayBreak {
    startTimestamp: Date;
    endTimestamp: Date;
    error?: BreakError;
}

export interface IWorkingDayDevice {
    _id: string;
    name: string;
}

export interface IWorkingDaySite {
    _id: string;
    name: string;
}

export interface IWorkingDayTaskType {
    _id: string;
    name: string;
}

export interface IWorkingDayComplaint {
    intervalId: string;
    amount: {
        previous: number;
        current: number;
    };
    applied: boolean;
}

export interface IWorkingDayComplaintInterval {
    endTimestamp: Date;
    totalHours: number;
    zoneName: string;
    packaging: ITaskReceivedPackaging;
    crop: ITaskReceivedCrop;
    amount: {
        previous: number;
        current: number;
    }
}

export interface IWorkingDay {
    _id: string;
    status: WORKING_DAY_STATUSES;
    startTimestamp: Date;
    startedWithKeychain: boolean;
    endTimestamp?: Date;
    endedWithKeychain?: boolean;
    totalHours: number;
    workerId: string;
    worker: WorkerReceived;
    campaignId: string;
    campaign: Campaign;
    deviceId: string;
    device: DeviceReceived;
    siteId: string;
    site: WorkingSite;
    breaks: IWorkingDayBreak[];
    complaints?: IWorkingDayComplaint[];
}

export interface IWorkingDaysPerformanceUnits {
    workingDays: number;
    totalHarvested: number;
    totalHours: number;
    averageHarvested: number;
    averageHours: number;
}

export interface IWorkingDaysPerformancePaginated {
    performance: IWorkingDaysPerformance;
    total: number;
}

export interface IWorkingDaysPerformance extends IWorkingDaysPerformanceUnits {
    dates: IWorkingDaysPerformanceDate[];
    crops: IWorkingDaysPerformanceCrop[];
}

export interface IWorkingDaysPerformanceDate extends IWorkingDaysPerformanceUnits {
    date: Date;
    sites: IWorkingDaysPerformanceSite[];
}

export interface IWorkingDaysPerformanceSite extends IWorkingDaysPerformanceUnits {
    _id: string;
    name: string;
    alias: string;
    crops: IWorkingDaysPerformanceCrop[];
}

export interface IWorkingDaysPerformanceCrop extends IWorkingDaysPerformanceUnits {
    name: string;
}

export interface IWorkingDaysPerformanceCropFilter {
    name: string;
    ids: string[];
}

export class WorkingDay implements IWorkingDay {
    _id: string;
    status: WORKING_DAY_STATUSES;
    startTimestamp: Date;
    startedWithKeychain: boolean;
    endTimestamp?: Date;
    endedWithKeychain?: boolean;
    totalHours: number;
    deleted: boolean;
    workerId: string;
    worker: WorkerReceived;
    campaignId: string;
    campaign: Campaign;
    deviceId: string;
    device: DeviceReceived;
    siteId: string;
    site: WorkingSite;
    error?: WorkingDayError;
    breaks: IWorkingDayBreak[];
    complaints?: IWorkingDayComplaint[];
    pickingTasks?: ITaskReceived[];
    workableTasks?: ITaskReceived[];
    notWorkableTasks?: ITaskReceived[];
    tasks?: ITaskReceived[];

    constructor(workingDay: IWorkingDay) {
        Object.assign(this, workingDay);
    }
}

export class WorkingDaysPerformanceUnits implements IWorkingDaysPerformanceUnits {
    workingDays: number;
    totalHarvested: number;
    totalHours: number;
    averageHarvested: number;
    averageHours: number;

    constructor(units: IWorkingDaysPerformanceUnits) {
        Object.assign(this, units);
    }
}

export class WorkingDaysPerformance extends WorkingDaysPerformanceUnits implements IWorkingDaysPerformance {
    dates: WorkingDaysPerformanceDate[];
    crops: WorkingDaysPerformanceCrop[];

    constructor(performance: IWorkingDaysPerformance) {
        super(performance);
        Object.assign(this, performance);
    }
}

export class WorkingDaysPerformanceDate extends WorkingDaysPerformanceUnits implements IWorkingDaysPerformanceDate {
    date: Date;
    sites: WorkingDaysPerformanceSite[];

    constructor(startDate: IWorkingDaysPerformanceDate) {
        super(startDate);
        Object.assign(this, startDate);
    }
}

export class WorkingDaysPerformanceSite extends WorkingDaysPerformanceUnits implements IWorkingDaysPerformanceSite {
    _id: string;
    name: string;
    alias: string;
    crops: WorkingDaysPerformanceCrop[];

    constructor(site: IWorkingDaysPerformanceSite) {
        super(site);
        Object.assign(this, site);
    }
}

export class WorkingDaysPerformanceCrop extends WorkingDaysPerformanceUnits implements IWorkingDaysPerformanceCrop {
    name: string;

    constructor(crop: IWorkingDaysPerformanceCrop) {
        super(crop);
        Object.assign(this, crop);
    }
}

export interface IWorkingDaySent {
    _id: string;
    campaignId: string;
    workerId: string;
    siteId: string;
    deviceId: string;
    status: WORKING_DAY_STATUSES;
    deleted: boolean;
    startTimestamp: Date;
    startedWithKeychain: boolean;
    endTimestamp: Date;
    endedWithKeychain: boolean;
    breaks: IWorkingDaySentBreak[];
};

export interface IWorkingDaySentBreak {
    startTimestamp: Date;
    endTimestamp: Date;
};

export class WorkingDaySent implements IWorkingDaySent {
    _id: string;
    campaignId: string;
    workerId: string;
    siteId: string;
    deviceId: string;
    status: WORKING_DAY_STATUSES;
    deleted: boolean;
    startTimestamp: Date;
    startedWithKeychain: boolean;
    endTimestamp: Date;
    endedWithKeychain: boolean;
    breaks: WorkingDaySentBreak[];

    constructor(workingDay: IWorkingDaySent) {
        Object.assign(this, workingDay);
    }
};

export class WorkingDaySentBreak implements IWorkingDaySentBreak {
    startTimestamp: Date;
    endTimestamp: Date;

    constructor(br: IWorkingDaySentBreak) {
        Object.assign(this, br);
    }
};

export class WorkingDayComplaint {
    complaints: IWorkingDayComplaint[];
    intervals: IWorkingDayComplaintInterval[];

    constructor (intervals: IHarvestInterval[], complaints: IWorkingDayComplaint[]) {
        this.complaints = complaints;
        this.formatIntervals(intervals);
    }

    private formatIntervals = (intervals: IHarvestInterval[]) =>
        this.intervals = intervals.map(interval => {
            const complaint = this.complaints.find(complaint => complaint.intervalId === interval._id);
            const amount = complaint ? complaint.amount : { previous: interval.amount, current: null };
            return { ...interval, amount };
        });

}

export class WorkingDayErrors {
    workingDay: WorkingDay = null;
    breaks: IWorkingDayBreak[] = [];
    tasks: ITaskReceived[] = [];
    intervals: IErrorInterval[] = [];

    constructor(workingDay: WorkingDay) {
        if (workingDay.error) this.workingDay = workingDay;
        this.breaks = workingDay.breaks.filter(br => br.error);
        this.tasks = workingDay.tasks.filter(task => {
            this.intervals.push(...(task.productionIntervals ?? [])
                .filter(interval => interval.error)
                .map(interval => ({...interval, taskId: task._id, taskName: task.taskType.name}) as IErrorInterval));
            return task.error;
        });
    }

    hasErrors = (): boolean =>
        this.workingDay !== null || this.breaks.length > 0 ||
        this.tasks.length > 0 || this.intervals.length > 0;
}




export interface IWorkingDaySent {

};

export interface IWorkingDayReceived {

};




export class WorkingDayReceived {
    _id: string;
    campaignId: string;
    workerId: string;
    siteId: string;
    deviceId: string;
    startTimestamp: Date;
    startedWithKeychain: boolean;
    endTimestamap?: Date;
    endedWithKeychain: boolean;
}


export interface IWorkingDayMassEdits {
    status?: WORKING_DAY_STATUSES;
    deviceId?: string;
    siteId?: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
    breaks?: IWorkingDayBreak[];
}