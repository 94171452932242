export const BITRISE_ROUTE = 'install';
export const LOGIN_ROUTE = 'login';
export const PASSWORD_RECOVERY_ROUTE = 'request-password-recovery';
export const RESET_PASSWORD_ROUTE = 'reset-password';
export const BASE_ROUTE = 'dashboard';
export const ABS_BASE_ROUTE = `/${BASE_ROUTE}`;

export enum SECTIONS {
    users = 'users',
    production = 'production',
    workforce = 'workforce',
    workingSites = 'working-sites',
    traceability = 'traceability',
    help = 'help'
}

export enum WORKING_SITES_ROUTES {
    parent = 'working-sites',
    main = '',
    add = 'add-working-site',
    edit = 'edit-working-site',
    detail = 'details-working-site'
}

export enum TASKS_ROUTES {
    parent = 'tasks',
    main = '',
    add = 'add-task',
    edit = 'edit-task',
    detail = 'details-task'
}

export enum PACKAGINGS_ROUTES {
    parent = 'packagings',
    main = '',
    add = 'add-packaging',
    edit = 'edit-packaging',
    clone = 'clone-packaging',
    detail = 'details-packaging'
}

export enum CROPS_ROUTES {
    parent = 'crops',
    main = '',
    add = 'add-crop',
    edit = 'edit-crop',
    detail = 'details-crop'
}

export enum CAMPAIGNS_ROUTES {
    parent = 'campaigns',
    main = '',
    planning = 'planning-campaign',
    detail = 'details-campaign'
}

export enum CAMPAIGNS_DETAIL_ROUTES {
    basic = 'basic',
    sites = 'sites-and-zones',
    addZone = 'add-zone',
    editZone = 'edit-zone',
    workers = 'workers',
    workingDays = 'working-days',
    workingDayAdd = 'working-day-add',
    workingDayMassEdit = 'working-day-mass-edit',
    workingDayEdit = 'working-day-edit',
    workingDayDetail = 'working-day-detail',
    tasks = 'tasks',
    taskAdd = 'add-task',
    taskEdit = 'edit-task',
    productionIntervalEdit = 'edit-production-interval',
    reports = 'reports',
    uniqueBatchs = 'unique-batchs'
}

export enum AGENCIES_ROUTES {
    parent = 'agencies',
    main = '',
    add = 'add-agency',
    edit = 'edit-agency',
    detail = 'details-agency'
}

export enum WORKER_ROUTES {
    parent = 'worker',
    main = '',
    add = 'add-worker',
    edit = 'edit-worker',
    detail = 'details-worker',
    file = 'upload-file'
}

export enum POSITIONS_ROUTES {
    parent = 'positions',
    add = 'add-position',
    edit = 'edit-position',
    detail = 'details-position',
}

export enum DEVICES_ROUTES {
    parent = 'devices',
    main = ''
}

export enum KEYCHAIN_ROUTES {
    parent = 'keychain',
    main = '',
    add = 'add-keychain',
    edit = 'edit-keychain',
    detail = 'details-keychain',
    file = 'upload-file'
}

export enum REPORTS_ROUTES {
    harvest = 'harvest',
    harvestWeekly = 'harvest-weekly',
    workerHours = 'worker-hours',
    maintenanceTasks = 'maintenance-tasks',
    harvestIncentive = 'harvest-incentive',
    harvestMaintenanceSummary = 'harvest-maintenance-summary',
    dailyPerformance = 'daily-performance',
    weeklyPerformance = 'weekly-performance',
    harvestPackagingCost = 'harvest-packaging-cost',
    plantsHarvest = 'plants-harvest',
    workableTasks = 'workable-tasks',
    workLog = 'work-log'
}

export enum USERS_ROUTES {
    parent = 'users',
    main = '',
    add = 'add',
    edit = 'edit',
    detail = 'detail'
}

export enum TRACEABILITY_ROUTES {
    parent = 'traceability'
}

export enum BATCHS_ROUTES {
    parent = 'batchs',
    detail = 'details',
    edit = 'edit',
    add = 'add',
    unique = 'unique',
    mixed = 'mixed'
}

export enum DISPATCH_NOTES_ROUTES {
    parent = 'dispatch-notes',
    detail = 'details'
}

export enum TRACEABILITY_REPORTS_ROUTES {
    parent = 'traceability-reports'
}

export enum COMPANIES_ROUTES {
    parent = 'companies',
    detail = 'detail',
    add = 'add-company',
    edit = 'edit-company'
}

export enum ITEMS_ROUTES {
    parent = 'items',
    upload = 'upload'
}

export enum WAREHOUSES_ROUTES {
    parent = 'warehouses',
    upload = 'upload'
}

export enum PRODUCTION_LINES_ROUTES {
    parent = 'production-lines',
    add = 'add',
    edit = 'edit'
}

export enum HELP_ROUTES {
    parent = 'help'
}

export enum MOBILE_APP_ROUTES {
    parent = 'mobile-app'
}
