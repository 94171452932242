import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { v4 } from 'uuid';

import { BatchsService } from '@routes/batchs/batchs.service';
import { BatchsEditCloseDialogComponent } from '@routes/batchs/batchs-edit/batchs-edit-close-dialog/batchs-edit-close-dialog.component';
import { createBatchFormGroup, createItemFormGroup } from '@routes/batchs/batchs-edit/batchs-edit.utils';
import { IBatchMixedReceived, IBatchMixedSent } from '@routes/batchs/batchs.model';
import { BATCH_CATEGORY, BATCH_MIXED_STATUS } from '@routes/batchs/batchs.constants';
import { BATCH_EDIT_TAB_INDEX } from '@routes/batchs/batchs-edit/batchs-edit.constants';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FORM_MODES } from '@shared/constants/forms';
import { minLengthArray } from '@shared/utils/validators.utils';

@Component({
  selector: 'app-batchs-edit',
  templateUrl: './batchs-edit.component.html',
  styleUrls: ['./batchs-edit.component.scss']
})
export class BatchsEditComponent implements OnInit {

  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  batch: IBatchMixedReceived;
  category: BATCH_CATEGORY;
  isSaving = false;
  form: FormGroup;
  mode: FORM_MODES;
  minimumRecords: number;

  cancelButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button
  };
  saveButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.SAVE',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit
  };
  exitRoute: string[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private batchService: BatchsService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.category = this.activatedRoute.snapshot.data.category;
    this.batch = this.activatedRoute.snapshot.data.batch;
    this.mode = this.activatedRoute.snapshot.data.mode;

    this.exitRoute = this.batch._id ? ['../..'] : ['..'];
    this.minimumRecords = this.batch.status === BATCH_MIXED_STATUS.OPEN ? 0 : 1

    this.batch.batchs.forEach(batch => batch.deleted = false);
    this.batch.items.forEach(item => item.deleted = false);
    this.initForm();
    this.displaySection();
  }

  initForm = () => {
    this.form = new FormGroup({
      basic: new FormGroup({
        code: new FormControl({ value: null, disabled: true }),
        customCode: new FormControl(null),
        status: new FormControl({ value: null, disabled: true }),
        companyId: new FormControl(null, Validators.required),
        warehouseId: new FormControl(null, Validators.required),
        productionLineId: new FormControl(null, Validators.required),
        amount: new FormControl(null, Validators.min(0)),
        palletNumber: new FormControl(null, [Validators.min(0), Validators.pattern(/^\d+$/)])
      }),
      batchs: new FormArray([]),
      items: new FormArray([])
    });
    if (this.batch.status === BATCH_MIXED_STATUS.CLOSED) {
      this.form.get('basic.amount').setValidators([Validators.required, Validators.min(0)]);
      this.form.get('basic.palletNumber').setValidators([Validators.required, Validators.min(0), Validators.pattern(/^\d+$/)]);
      this.form.get('batchs').setValidators([Validators.required, minLengthArray(this.minimumRecords)]);
      this.form.get('items').setValidators([Validators.required, minLengthArray(this.minimumRecords)]);
    }
    this.patchForm();
  }

  patchForm = () => {
    this.batch.batchs.forEach(batch => {
      const formGroup = createBatchFormGroup(batch);
      (this.form.get('batchs') as FormArray).push(formGroup);
    });
    this.batch.items.forEach(item => {
      const formGroup = createItemFormGroup(item);
      (this.form.get('items') as FormArray).push(formGroup);
    });
    this.form.get('basic').patchValue(this.batch);
  }

  displaySection = () => {
    const section = this.activatedRoute.snapshot.queryParams.section;
    if (section) this.tabGroup.selectedIndex = BATCH_EDIT_TAB_INDEX[section];
  }

  batchMessagesLocale = (variable: string) => this.translateService.instant(`batchs.messages.${variable.toUpperCase()}`);

  submitForm = () => {
    if (this.form.invalid) return;

    const batch: IBatchMixedSent = {
      ...this.form.getRawValue().basic,
      batchs: this.form.value.batchs,
      items: this.form.value.items,
      customId: this.batch.customId || v4(),
      category: BATCH_CATEGORY.MIXED,
      datetime: this.createBatchDate(),
      deleted: false
    };
    this.batchCanBeClosed(batch)
      ? this.displayCloseDialog(batch)
      : this.confirmSubmission(batch);
  }

  createBatchDate = () => {
    const date = new Date();
    
    return new Date(Date.UTC(
      date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  batchCanBeClosed = (batch: IBatchMixedSent) => [
    batch.status === BATCH_MIXED_STATUS.OPEN,
    batch.items.length > 0, batch.batchs.length > 0,
    batch.amount !== null, batch.amount > 0,
    batch.palletNumber !== null, batch.palletNumber >= 0
  ].every(condition => condition === true);

  displayCloseDialog = (batch: IBatchMixedSent) => this.dialog.open(BatchsEditCloseDialogComponent, {
    width: '420px'
  }).afterClosed().pipe(filter(status => status)).subscribe(status => {
    batch.status = status;
    this.confirmSubmission(batch); 
  });

  confirmSubmission = (batch: IBatchMixedSent) => {
    this.isSaving = true;
    const { fn, message, route } = this.batch._id
      ? { fn: this.batchService.editBatch(this.batch._id, batch), message: 'UPDATED', route: () => ['../details'] }
      : { fn: this.batchService.addBatch(batch), message: 'ADDED', route: response => ['..', response._id, 'details'] };
    fn
      .then(response => {
        this.snackbarService.success(this.batchMessagesLocale(`BATCH_${message}_SUCCESSFULLY`));
        this.router.navigate(route(response), { relativeTo: this.activatedRoute });
      })
      .catch(error => {
        this.snackbarService.error(error.error.message);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }
  
  
}
