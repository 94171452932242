import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { BATCH_MIXED_STATUS } from "@routes/batchs/batchs.constants";
import { BatchMixedReceived, IBatchType } from "@routes/batchs/batchs.model";
import { BatchsService } from "@routes/batchs/batchs.service";

import { FORM_MODES } from "@shared/constants/forms";
import { CustomSnackbarService } from "@shared/services/snackbar.service";
import { BatchsEditService } from "./batchs-edit.service";

@Injectable({
    providedIn: 'root'
})
export class BatchsEditResolver implements Resolve<IBatchType | void> {

    constructor(
        private batchsService: BatchsService,
        private snackbarService: CustomSnackbarService,
        private translateService: TranslateService,
        private batchsEditService: BatchsEditService
    ) {}
    
    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IBatchType | void> {
        if (route.data.mode === FORM_MODES.add) {
            await this.batchsEditService.retrieveReferenceData();
            return new BatchMixedReceived({ status: BATCH_MIXED_STATUS.OPEN });
        }
        const _id = route.params.id;
        const category = route.data.category;
        const batch = await this.batchsService.getBatch(_id, category);

        if (batch.status !== BATCH_MIXED_STATUS.OPEN && batch.status !== BATCH_MIXED_STATUS.CLOSED) {
            const i18n = 'batchs.messages.MIXED_BATCH_NOT_EDITABLE';
            const message = this.translateService.instant(i18n);
            this.snackbarService.error(message);
            throw new Error('MIXED_BATCH_NOT_EDITABLE');
        }
        await this.batchsEditService.retrieveReferenceData();
        return batch;
    }
    
}
