export enum CommonTranslation {
    Action = 'general.actions',
    Filter = 'general.filters',
    Dialog = 'general.dialogs',
    Upload = 'general.upload',
    Entities = 'general.entities',
    Units = 'general.units',
    Roles = 'general.roles',
    Menu = 'general.menu'
}
