import { Component, Input, OnInit } from '@angular/core';
import { GenericButtonSize } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';

@Component({
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss']
})
export class GenericButtonComponent implements OnInit {

  GenericButtonSize = GenericButtonSize;

  @Input() config: IGenericButtonConfig;
  @Input() disabled: boolean;

  constructor() {}

  ngOnInit(): void {}

}
