import { Component, OnInit, Input } from '@angular/core';
import { TaskType, ITaskTypesPaginated } from '@routes/tasks/task.model';
import { ITableBasicStruture } from '@shared/interfaces/tables';
import { ABS_BASE_ROUTE, SECTIONS, TASKS_ROUTES } from '@shared/constants/routes';
import { Subject } from 'rxjs';
import { Pagination } from '@shared/models/pagination.model';
import * as _ from 'lodash';
import { TaskService } from '@routes/tasks/task.service';

@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss']
})
export class TasksTableComponent implements OnInit {

  BASE_ROUTE = ABS_BASE_ROUTE;
  SECTIONS = SECTIONS;
  TASKS_ROUTES = TASKS_ROUTES;
  eventResetPage: Subject<void> = new Subject<void>();

  @Input('searchPattern') set searchPattern(search: string) {
    this.search(search);
  }

  pagination: Pagination;
  currentSearch: string;

  getEditLink = (task: TaskType) => [...this.getBaseLink(), TASKS_ROUTES.edit, task.id];
  getDetailsLink = (task: TaskType) => [...this.getBaseLink(), TASKS_ROUTES.detail, task.id];
  getBaseLink = () => [ABS_BASE_ROUTE, SECTIONS.production, TASKS_ROUTES.parent];

  // tslint:disable-next-line: member-ordering
  tableConfig: ITableBasicStruture = {
    columnsToDisplay: ['name', 'workable', 'unit', 'actions'],
    columns: [
      {
        header: 'tasks.table.NAME',
        columnDef: 'name',
        valuePath: 'name',
        sortPath: 'name'
      },
      {
        header: 'tasks.table.WORKABLE',
        columnDef: 'workable',
        valuePath: 'workable',
        sortPath: 'workable',
        formatFunction: (value: any) => value ? 'tasks.table.values.WORKABLE' : 'tasks.table.values.NOT_WORKABLE'
      },
      {
        header: 'tasks.table.UNIT',
        columnDef: 'unit',
        valuePath: 'unit',
        sortPath: 'unit',
        formatFunction: (value: string) => value ? value : '-'
      },
      {
        header: 'tasks.table.ACTIONS',
        columnDef: 'actions',
        buttons: [
          {
            icon: '/assets/images/icons/search.svg',
            getLink: this.getDetailsLink,
          },
          {
            icon: '/assets/images/icons/edit.svg',
            visibility: (element: any) => !element.protected,
            getLink: this.getEditLink
          }
        ]
      }
    ],
    defaultSorting: {
      field: 'name',
      order: 1
    },
    paginationConfig: {
      pagingSizes: [5, 10, 25, 50, 100],
      pagingSizeDefault: 10,
    }
  };
  paginationTotal: number;
  tasks: TaskType[] = [];

  constructor(
    private taskService: TaskService
  ) { }

  ngOnInit(): void { }

  resetPage = () => this.eventResetPage.next()

  search = (search: string) => {
    this.currentSearch = search;
    this.resetPage();
    this.refresh(this.pagination);
  }

  refresh(pagination: Pagination) {
    if (pagination) {
      pagination.search = _.cloneDeep(this.currentSearch);
      this.pagination = new Pagination(pagination);
      this.getTasks();
    }
  }

  getTasks = (): Promise<void | object> =>
    this.taskService.getTasks(this.pagination).then((data: ITaskTypesPaginated) => this.refreshTasks(data));

  refreshTasks(data: ITaskTypesPaginated) {
    this.tasks = [...data.tasksTypes];
    this.paginationTotal = data.total;
  }
}
