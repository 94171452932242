<h2 class="h2">{{ title }}</h2>
<form [formGroup]="form" class="form d-flex flex-row flex-wrap" (submit)="submitForm()">
    <mat-form-field class="w-100 mb-4">
        <mat-label for="name">{{ 'campaigns.detail.ZONE' | translate }} </mat-label>
        <input matInput type="text" name="name" id="name" formControlName="name"/>
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
    </mat-form-field>
    <mat-form-field class="w-100 mb-4">
        <mat-label for="crop">{{ 'campaigns.detail.CROP' | translate }} </mat-label>
        <input matInput type="text" name="crop" id="crop" formControlName="crop" [matAutocomplete]="auto" #crop/>
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCrop" (optionSelected)="selectCrop($event)">
            <mat-option *ngFor="let crop of crops" [value]="crop">{{ crop.name }} ({{ crop.variety }})</mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="w-50 mb-4 pr-3">
        <mat-label for="area">{{ 'campaigns.detail.AREA' | translate }} </mat-label>
        <input matInput type="number" name="area" id="area" formControlName="area"/>
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
    </mat-form-field>
    <mat-form-field class="w-50 mb-4 pl-3">
        <mat-label for="plants">{{ 'campaigns.detail.PLANTS' | translate }} </mat-label>
        <input matInput type="number" name="plants" id="plants" formControlName="plants"/>
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
    </mat-form-field>
    <section class="controls d-flex justify-content-between align-self-stretch w-100 mt-4">
        <button mat-flat-button color="warn" class="btn" type="button" [routerLink]="getExitRoute()">
            {{ 'general.actions.BACK' | translate }}
        </button>
        <button mat-flat-button color="primary" class="btn" type="submit" [disabled]="form.invalid">
            {{ 'general.actions.APPLY_CHANGES' | translate }}
        </button>
    </section>
</form>