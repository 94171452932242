import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslatorService } from '@core/translator/translator.service';

import { routes } from '@routes/routes';

import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [],
    providers: [],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class RoutesModule {
    constructor(tr: TranslatorService) {}
}
