import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Keychain } from '@routes/keychains/keychain.model';
import { KeychainService } from '@routes/keychains/keychain.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeychainResolver implements Resolve<Keychain> {

  constructor(
    private keychainService: KeychainService
  ) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Promise<Keychain> {
      const id = activatedRouteSnapshot.params.id;
      return this.keychainService.getKeychain(id);
  }

}