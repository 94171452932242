<h2 class="h2">{{ title | translate }}</h2>

<div class="wrapper">
    <app-generic-filters
        class="filters" [filtersConfig]="filtersConfig" (filtersEvent)="updateFilters($event)">
    </app-generic-filters>

    <article class="report" [ngSwitch]="true">
        <ng-container *ngSwitchCase="loadingWarning">
            <div class="alert alert-secondary text-center">{{ 'reports.messages.LOADING_WARNING' | translate }}</div>
        </ng-container>
        <ng-container *ngSwitchCase="loadingReport">
            <div class="alert alert-secondary text-center">{{ 'reports.messages.LOADING_REPORT' | translate }}</div>
        </ng-container>
        <ng-container *ngSwitchCase="!loadingReport && !loadingWarning && !report">
            <div class="alert alert-warning text-center">{{ 'reports.messages.NO_HARVEST_REPORTS' | translate }}</div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="alert alert-success text-center">{{ 'reports.messages.REPORT_READY_TO_DOWNLOAD' | translate }}
            </div>
        </ng-container>
    </article>
</div>