<form class="form d-flex flex-column flex-nowrap align-items-stretch w-100" [formGroup]="form" (submit)="submitForm()">
    <h2 class="dashboard-subheading">{{ 'campaigns.basic.TITLE' | translate }}</h2>
    <div class="d-flex flex-row flex-nowrap">
        <mat-form-field class="w-100" appearance="standard">
            <mat-label>{{ 'campaigns.basic.NAME' | translate }}</mat-label>
            <input matInput formControlName="name">
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row flex-nowrap mt-3">
        <mat-form-field class="flex-grow-1 mr-3" appearance="standard">
            <mat-label>{{ 'campaigns.basic.START' | translate }}</mat-label>
            <input matInput [matDatepicker]="startDate" formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field class="flex-grow-1 ml-3" appearance="standard">
            <mat-label>{{ 'campaigns.basic.END' | translate }}</mat-label>
            <input matInput [matDatepicker]="endDate" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row flex-nowrap mt-3">
        <mat-form-field class="flex-grow-1" appearance="standard">
            <mat-label>{{ 'campaigns.basic.TYPE' | translate }}</mat-label>
            <mat-select formControlName="type">
                <mat-option value="" disabled="disabled" hidden="hidden">{{ 'campaigns.basic.selector.DEFAULT' | translate }}
                </mat-option>
                <mat-option value="fruit">{{ 'campaigns.basic.selector.FRUIT' | translate }}</mat-option>
                <mat-option value="plant">{{ 'campaigns.basic.selector.PLANT' | translate }}</mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row flex-nowrap mt-3">
            <mat-checkbox color="primary" formControlName="agreeWorkdays">{{ 'campaigns.basic.AGREE_WORKDAYS' | translate }}
            </mat-checkbox>
    </div>

    <div class="buttons d-flex justify-content-between mt-5">
        <button mat-flat-button color="warn" class="btn" type="button" [routerLink]="['..']">{{ 'general.actions.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" class="btn" type="submit" [disabled]="this.form.invalid">{{ 'general.actions.CONTINUE' | translate }}</button>
    </div>
</form>