import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CampaignsDetailWorkingDaysMassEditComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/campaigns-detail-working-days-mass-edit.component';
import { WorkingDay } from '@shared/models/working-day.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn:'root'
})
export class WorkingDaysMassEditService {

  private formSubject = new Subject<FormGroup>();
  private editSiteSubject = new Subject<string>();
  private editDeviceSubject = new Subject<string>();
  private confirmationFormSubject = new Subject<FormGroup>();
  private selectedDeviceSubject = new Subject<string>();
  private filtersSubject = new Subject<any>();
  private selectedWorkingDaysSubject = new Subject<Map<string, WorkingDay>>();

  constructor() { }

  formChanges = (): Observable<FormGroup> => this.formSubject.asObservable();
  setForm = (form: FormGroup) => this.formSubject.next(form);

  editSiteChanges = (): Observable<string> => this.editSiteSubject.asObservable();
  setEditSite = (editSite: string) => this.editSiteSubject.next(editSite);

  editDeviceChanges = (): Observable<string> => this.editDeviceSubject.asObservable();
  setEditDevice = (editDevice: string) => this.editDeviceSubject.next(editDevice);

  confirmationFormChanges = (): Observable<FormGroup> => this.confirmationFormSubject.asObservable();
  setConfirmationForm = (form: FormGroup) => this.confirmationFormSubject.next(form);

  selectedDeviceChanges = (): Observable<string> => this.selectedDeviceSubject.asObservable();
  setSelectedDevice = (selectedDevice: string) => this.selectedDeviceSubject.next(selectedDevice);

  filtersChanges = (): Observable<any> => this.filtersSubject.asObservable();
  setFilters = (filters: any) => this.filtersSubject.next(filters);

  selectedWorkingDaysChanges = (): Observable<Map<string, WorkingDay>> => this.selectedWorkingDaysSubject.asObservable();
  setSelectedWorkingDays = (selectedWorkingDays: Map<string, WorkingDay>) => this.selectedWorkingDaysSubject.next(selectedWorkingDays);

}
