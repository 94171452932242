import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ICampaign, ICampaignSite, ICampaignZone, Campaign, ICampaignWorker, CampaignZone, ICampaignDevice } from '@routes/campaigns/campaign.model';
import { max } from 'lodash';

@Injectable()
export class CampaignPlannerService {
    private _campaign: ICampaign;
    private _site: ICampaignSite;
    private _zone: ICampaignZone;

    private campaignSubject = new Subject<Campaign>();

    get campaign(): ICampaign { if (this._campaign) return JSON.parse(JSON.stringify(this._campaign)); }
    get site(): ICampaignSite { if (this._site) return JSON.parse(JSON.stringify(this._site)); }
    get workers(): ICampaignWorker[] { if (this._campaign) return JSON.parse(JSON.stringify(this._campaign.workers)); }
    get zones(): ICampaignZone[] { if (this._site) return [...JSON.parse(JSON.stringify(this._site.zones))].sort((a, b) => a.name.localeCompare(b.name)); }
    get zone(): ICampaignZone { if (this._zone) return JSON.parse(JSON.stringify(this._zone)); }
    get devices(): ICampaignDevice[] {return this.campaign.devices?.sort((a, b) => a.name.localeCompare(b.name)) || []; }

    constructor() {}

    campaignChanges = (): Observable<Campaign> => this.campaignSubject.asObservable();

    setCampaignBasic = (campaign: ICampaign) => {
        this._campaign = new Campaign(campaign);
        this.campaignSubject.next(this.campaign);
    }

    addSite = (site: ICampaignSite) => {
        this._campaign.sites.push(site);
        this.campaignSubject.next(this.campaign);
    }

    removeSite = (site: ICampaignSite) => {
        this._campaign.sites.splice(this._campaign.sites.findIndex(s => s.siteId === site.siteId), 1);
        this.campaignSubject.next(this.campaign);
    }

    removeAllSites = () => {
        if (typeof this._campaign !== 'undefined') {
            this._campaign.sites.splice(0, this._campaign.sites.length);
            this.campaignSubject.next(this.campaign);
        }
    }

    siteIsIncluded = (id: string): boolean => this._campaign.sites.findIndex(site => site.siteId === id) !== -1;

    selectSite = (id: string) => {
        this._site = this.campaign.sites.find(site => site.siteId === id);
        this.campaignSubject.next(this.campaign);
    }

    addWorker = (worker: ICampaignWorker) => {
        this._campaign.workers.push(worker);
        this.campaignSubject.next(this.campaign);
    }

    removeWorker = (worker: ICampaignWorker) => {
        this._campaign.workers.splice(this._campaign.workers.findIndex(w => w.workerId === worker.workerId), 1);
        this.campaignSubject.next(this.campaign);
    }

    workerIsIncluded = (id: string): boolean =>
        this._campaign.workers.findIndex(worker => worker.workerId === id) !== -1

    addZone = (zone: ICampaignZone, siteId: string) => {
        if (!this.zoneNameIsUnique(zone.name)) {
            throw new Error('ERROR_REPEATED_NAME');
        }
        if (!this.traceCodeIsUnique(zone.traceCode)) {
            throw new Error('ERROR_REPEATED_TRACE_CODE');
        }
        const siteIndex = this.campaign.sites.findIndex(site => site.siteId === siteId);
        const sequentialId = this.generateZoneIndex(siteId);

        zone.traceCode = sequentialId < 10
            ? `0${sequentialId}`
            : `${sequentialId}`;
        zone.sequentialId = sequentialId;

        this._campaign.sites[siteIndex].zones.push(zone);
        this.selectSite(siteId);
        this.campaignSubject.next(this.campaign);
    }

    editZone = (zone: CampaignZone) => {
        const siteIndex = this._campaign.sites.findIndex(s => s.siteId === this._site.siteId);
        const zoneIndex = this._campaign.sites[siteIndex].zones.findIndex(z => z.sequentialId === zone.sequentialId);

        this._campaign.sites[siteIndex].zones[zoneIndex] = zone;
        this._site = this.campaign.sites[siteIndex];
        this._zone = zone;
        this.campaignSubject.next(this.campaign);
    }

    removeZone = (sequentialId: number, siteId: string) => {
        const siteIndex = this.campaign.sites.findIndex(site => site.siteId === siteId);
        const zoneIndex = this.campaign.sites[siteIndex].zones.findIndex(z => z.sequentialId === sequentialId);

        this._campaign.sites[siteIndex].zones.splice(zoneIndex, 1);
        this.selectSite(siteId);
        this.campaignSubject.next(this.campaign);
    }

    generateZoneIndex = (siteId: string) => {
        const zonesSequentialIds = this._campaign.sites.find(s => s.siteId === siteId).zones.map(z => z.sequentialId);
        return zonesSequentialIds.length ? max(zonesSequentialIds) + 1 : 1;
    }

    selectZone = (sequentialId: number) =>
        this._zone = this._site.zones.find(z => z.sequentialId === sequentialId)

    zoneNameIsUnique = (name: string): boolean => {
        const zones = this._zone
            ? this._site.zones.filter(zone => zone.sequentialId !== this._zone.sequentialId)
            : this._site.zones;
        return zones.find(zone => zone.name.toLowerCase() === name.toLowerCase()) === undefined;
    }

    traceCodeIsUnique = (traceCode: string): boolean => {
        const zones = this._zone
            ? this._site.zones.filter(zone => zone.traceCode !== this._zone.traceCode)
            : this._site.zones;
        return zones.find(zone => zone.traceCode === traceCode) === undefined;
    }

    getZone = (sequentialId: number): CampaignZone => this.site.zones.find(zone => zone.sequentialId === sequentialId);

    clean = () => {
        this._campaign = null;
        this._site = null;
        this._zone = null;
    }
}
