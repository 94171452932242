import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CampaignUtilsService {
    calculateDensity = (area: number, plants: number): string => {
        if (area && plants && [area, plants].every(n => Number.isNaN(n) === false)) {
            const density = plants / area;
            
            return Number.isInteger(density)
                ? density.toFixed(0)
                : density.toFixed(2);
        }
        return '';
      }
}