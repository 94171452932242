<form [formGroup]="form" (submit)="submitForm()">
    <h2 class="title">{{ 'campaigns.detail.ADD_SITE' | translate }}</h2>
    <!-- Site -->
    <mat-form-field class="input" appearance="standard">
        <mat-label>{{ 'campaigns.detail.SITE' | translate }}</mat-label>
        <mat-select formControlName="site">
            <mat-option *ngFor="let site of sites" [value]="site">{{ site.name | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- Buttons -->
    <mat-dialog-actions class="d-flex justify-content-between">
        <button mat-flat-button mat-dialog-close color="warn" class="actions-button btn" type="button">
            {{ 'general.actions.CANCEL' | translate }}
        </button>
        <button mat-flat-button mat-dialog-close color="primary" class="actions-button btn" type="submit" [disabled]="form.invalid">
            {{ 'general.actions.ADD' | translate }}
        </button>
    </mat-dialog-actions>
</form>