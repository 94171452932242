import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ABS_BASE_ROUTE, PASSWORD_RECOVERY_ROUTE } from '@shared/constants/routes';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginInProgress: boolean;
  loginMessage: string;
  REQUEST_PASSWORD_RECOVERY_ROUTE = `/${PASSWORD_RECOVERY_ROUTE}`;

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });
    
  }

  logIn() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loginInProgress = true;

    this.authService.logIn(this.loginForm.value)
      .then(() => this.router.navigate([ABS_BASE_ROUTE]))
      .catch(error => {
        this.loginInProgress = false;
        const i18n = error.status === 401 
        ? 'login.form.INVALID_CREDENTIALS'
        : 'login.form.SERVER_ERROR';
        const message = this.translateService.instant(i18n);
        this.snackbarService.error(message)
        
      });
  }


}
