<form class="filters" [formGroup]="form">
    <app-search class="searcher" (evtSearch)="searchCompanies($event)"></app-search>
    <mat-form-field appearance="standard">
        <mat-label>{{ 'companies.entities.OWNERSHIP' | translate }}</mat-label>
        <mat-select formControlName="ownership">
            <mat-option value="">{{ 'companies.ownerships.ALL' | translate }}</mat-option>
            <mat-option *ngFor="let ownership of ownerships | keyvalue" [value]="ownership.value">
                {{ 'companies.ownerships.' + ownership.key.toUpperCase() | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>
<div class="buttons">
    <button mat-flat-button class="btn" color="primary" [routerLink]="addLink">{{ 'general.actions.ADD' | translate }}</button>
</div>
<table class="table" mat-table matSort [dataSource]="companies" (matSortChange)="sortCompanies($event)">
    <!-- Business name -->
    <ng-container matColumnDef="businessName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="businessName" class="table-cell businessName">{{ 'companies.entities.BUSINESS_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let company" class="table-cell businessName">{{ company.businessName }}</td>
    </ng-container>
    <!-- Identification -->
    <ng-container matColumnDef="identification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="identification" class="table-cell identification">{{ 'companies.entities.IDENTIFICATION' | translate }}</th>
        <td mat-cell *matCellDef="let company" class="table-cell identification">{{ company.identification }}</td>
    </ng-container>
    <!-- Ownership -->
    <ng-container matColumnDef="ownership">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="ownership" class="table-cell ownership">{{ 'companies.entities.OWNERSHIP' | translate }}</th>
        <td mat-cell *matCellDef="let company" class="table-cell ownership">
            {{ 'companies.ownerships.' + company.ownership.toUpperCase() | translate }}
        </td>
    </ng-container>
    <!-- Address -->
    <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="address" class="table-cell address">{{ 'companies.entities.ADDRESS' | translate }}</th>
        <td mat-cell *matCellDef="let company" class="table-cell address">{{ company.address }}</td>
    </ng-container>
    <!-- Trace code -->
    <ng-container matColumnDef="traceCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="traceCode" class="table-cell traceCode">{{ 'companies.entities.TRACE_CODE' | translate }}</th>
        <td mat-cell *matCellDef="let company" class="table-cell traceCode">{{ company.traceCode }}</td>
    </ng-container>
    <!-- Sage reference -->
    <ng-container matColumnDef="sageReference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="sageReference" class="table-cell sageReference">{{ 'companies.entities.SAGE_REFERENCE' | translate }}</th>
        <td mat-cell *matCellDef="let company" class="table-cell sageReference">{{ company.sageReference ? company.sageReference : '-' }}</td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
        <th class="table-cell table-actions" mat-header-cell *matHeaderCellDef>{{ 'general.tables.ACTIONS' | translate }}</th>
        <td class="table-cell table-actions" mat-cell *matCellDef="let company">
            <button class="action-btn" [routerLink]="[routes.detail, company._id]">
                <img class="action-img" src="/assets/images/icons/search.svg" alt="{{ 'general.alt.DETAIL' | translate }}"/>
            </button>
            <button class="action-btn" [routerLink]="[routes.edit, company._id]">
                <img class="action-img" src="/assets/images/icons/edit.svg" alt="{{ 'general.alt.EDIT' | translate }}"/>
            </button>
        </td>
    </ng-container>
    <!-- Structure definition -->
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
<mat-paginator
    class="paginator" [length]="total" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="pagination.size" (page)="paginateCompanies($event)">
</mat-paginator>