import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { IErrorSync } from '@shared/interfaces/errors-sync.models';
import { ErrorsSyncService } from '@shared/services/errors-sync.service';
import { PrintService } from '@shared/services/print.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  showMenu = false;
  printClasses: string;
  printSubscription: Subscription;
  errorsSync: IErrorSync[] = [];

  constructor(
    private printService: PrintService,
    private errorsSyncService: ErrorsSyncService
  ) {}

  async ngOnInit(): Promise<void> {
    this.printSubscription = this.printService.printObservable.subscribe(classes =>
      this.printClasses = classes
        ? classes.join(' ')
        : null);
    this.errorsSync = await this.errorsSyncService.getErrorsSync({ deleted: false });
  }

  ngOnDestroy(): void {
    this.printSubscription.unsubscribe();
  }

  toggleMenu = (): boolean => this.showMenu = !this.showMenu;
}
