import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';

import { CompaniesService } from '@routes/companies/companies.service';
import { ICompanyDeliveryAddress, ICompanyReceived } from '@routes/companies/companies.model';
import { COMPANY_OWNERSHIP } from '@routes/companies/companies.constants';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { Pagination } from '@shared/models/pagination.model';
import { FORM_MODES } from '@shared/constants/forms';

@Component({
  selector: 'app-companies-edit',
  templateUrl: './companies-edit.component.html',
  styleUrls: ['./companies-edit.component.scss']
})
export class CompaniesEditComponent implements OnInit {

  COMPANY_OWNERSHIP = COMPANY_OWNERSHIP;

  company: ICompanyReceived;
  title: string;
  mode: FORM_MODES;
  section = 'basic';
  exitRoute = ['..'];
  inUseDispatchNoteCodes: string[];
  loading = false;
  hasSageReference = false;

  pagination = new Pagination({
    page: 1, size: 10,
    sort: { field: 'name', order: 1 }
  });
  form: FormGroup;
  deliveryAddressForm: FormGroup;

  FORM_MODES = FORM_MODES;

  getDispatchNoteValidator = () => Validators.pattern(`^(?!^(${this.inUseDispatchNoteCodes.join('|')})$).+$`);

  constructor(
    private companiesService: CompaniesService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.inUseDispatchNoteCodes = this.activatedRoute.snapshot.data.data.inUseDispatchNoteCodes;
    this.setTitle();
    this.setForm();

    if (this.mode === FORM_MODES.edit || this.mode === FORM_MODES.detail) {
      this.company = this.activatedRoute.snapshot.data.data.company;
      this.form.patchValue(this.company);
      this.exitRoute = ['../..'];

      const hasSageReference = this.company.sageReference ? true : false;
      this.enableSageReference(hasSageReference);
    }
    if (this.mode === FORM_MODES.detail) this.form.disable();
  }

  setForm = () => this.form = new FormGroup({
    businessName: new FormControl(null, Validators.required),
    identification: new FormControl(null, Validators.required),
    ownership: new FormControl(null, Validators.required),
    address: new FormControl(null, Validators.required),
    city: new FormControl(null, Validators.required),
    state: new FormControl(null, Validators.required),
    zipcode: new FormControl(null, Validators.required),
    country: new FormControl(null, Validators.required),
    sageReference: new FormControl({ value: null, disabled: true }, Validators.required),
    deliveryAddresses: new FormControl([], Validators.required),
    dispatchNoteCodes: new FormGroup({
      fruit: new FormControl(null, this.getDispatchNoteValidator()),
      plant: new FormControl(null, this.getDispatchNoteValidator())
    })
  });

  enableSageReference = (enabled: boolean) => {
    const control = this.form.get('sageReference');
    this.hasSageReference = enabled;
    enabled ? control.enable() : control.disable();
  };

  updateDeliveryAddresses = (addresses: ICompanyDeliveryAddress[]) => {
    this.form.get('deliveryAddresses').setValue(addresses);
    this.form.updateValueAndValidity();
  }

  setTitle = () => this.title = this.translateService.instant(`companies.titles.${this.mode.toUpperCase()}`);

  selectSection = (event: MatSelectChange) => this.section = event.value;

  submitForm = () => {
    if (this.form.invalid) return;
    this.loading = true;
  
    const fn = this.mode === FORM_MODES.add
      ? this.companiesService.addCompany({ ...this.form.value })
      : this.companiesService.editCompany(this.company._id, { ...this.form.value });
  
    fn.then(() => {
      const message = this.translateService.instant(`companies.messages.${this.mode.toUpperCase()}_SUCCESS`);
      this.snackbarService.success(message);
      this.router.navigate(this.exitRoute, { relativeTo: this.activatedRoute });
    }).catch(error => this.snackbarService.error(error.error.message))
      .finally(() => {
        this.loading = false;
      });
  }

}
