import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ICampaignDevice, ICampaignSite } from '@routes/campaigns/campaign.model';
import { WorkingDaysMassEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/working-days-mass-edit.service';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';

@Component({
  selector: 'app-campaigns-detail-working-days-mass-edit-form',
  templateUrl: './campaigns-detail-working-days-mass-edit-form.component.html',
  styleUrls: ['./campaigns-detail-working-days-mass-edit-form.component.scss']
})
export class CampaignsDetailWorkingDaysMassEditFormComponent implements OnInit {
  
  form: FormGroup;

  texts: any;
  timeRegExp: RegExp = /^\d{1,2}[:]{1}\d{2}$/;

  allDevices: ICampaignDevice[];
  allSites: ICampaignSite[];

  editDevice: string;
  editSite: string;

  enabledControls = {
    siteChecked: false,
    deviceChecked: false,
    startDateChecked:  false,
    endDateChecked: false,
    breakChecked: false,
  }

  startControl: FormControl;
  endControl: FormControl;
  breakStartControl: FormControl;
  breakEndControl: FormControl;

  constructor(
    private translateService: TranslateService,
    private plannerService: CampaignPlannerService,
    private massEditService: WorkingDaysMassEditService
  ) { }

  ngOnInit(): void {
    this.allDevices = this.plannerService.devices;
    this.allSites = this.plannerService.campaign.sites;
    this.texts = Object.values(this.translateService.instant([
      'campaigns.workingDays.mass_edit_dialog.tabs']
    )).reduce((prev, curr) => Object.assign(prev, curr), {});
    this.setupForm(); 
  }

  setupForm = () => {
    this.form = new FormGroup({
      site: new FormControl('', [Validators.required]),
      siteChecked: new FormControl(false),
      device: new FormControl('', [Validators.required]),
      deviceChecked: new FormControl(false),
      startDate: new FormControl(null, [Validators.required]),
      startTime: new FormControl(null, [Validators.pattern(this.timeRegExp), Validators.required]),
      startDateChecked: new FormControl(false),
      endDate: new FormControl(null, [Validators.required]),
      endTime: new FormControl(null, [Validators.pattern(this.timeRegExp), Validators.required]),
      endDateChecked: new FormControl(false),
      startDateBreak: new FormControl(null, [Validators.required]),
      startTimeBreak: new FormControl(null, [Validators.pattern(this.timeRegExp), Validators.required]),
      endDateBreak: new FormControl(null, [Validators.required]),
      endTimeBreak: new FormControl(null, [Validators.pattern(this.timeRegExp), Validators.required]),
      breakChecked: new FormControl(false),
    
    });
    this.massEditService.setForm(this.form); 

    this.form.valueChanges.subscribe(form => {
      this.enabledControls.siteChecked = form.siteChecked;
      this.enabledControls.deviceChecked = form.deviceChecked;
      this.enabledControls.startDateChecked = form.startDateChecked;
      this.enabledControls.endDateChecked = form.endDateChecked; 
      this.enabledControls.breakChecked = form.breakChecked;

      if(!form.siteChecked && !form.deviceChecked && !form.startDateChecked && !form.endDateChecked && !form.breakChecked) this.form.setErrors({ 'invalid': true });
      this.editDevice = this.form.value.device 
        ? this.plannerService.devices.filter(device => this.form.value.device === device._id)[0].name
        : "";
      this.editSite = this.form.value.site
        ? this.plannerService.campaign.sites.filter(site => this.form.value.site === site.siteId)[0].siteName
        : "";

      this.massEditService.setForm(this.form);
      this.massEditService.setEditDevice(this.editDevice);
      this.massEditService.setEditSite(this.editSite);
    })

    this.startControl = this.form.get('startDate') as FormControl;
    this.endControl = this.form.get('endDate') as FormControl;
    this.breakStartControl = this.form.get('startDateBreak') as FormControl;
    this.breakEndControl = this.form.get('endDateBreak') as FormControl;
  }

}
