import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CampaignPlannerService } from '../campaign.planner.service';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { Router } from '@angular/router';
import { ABS_BASE_ROUTE, CAMPAIGNS_ROUTES, SECTIONS } from '@shared/constants/routes';

@Component({
  selector: 'app-campaigns-planner-workers',
  templateUrl: './campaigns-planner-workers.component.html',
  styleUrls: ['./campaigns-planner-workers.component.scss']
})
export class CampaignsPlannerWorkersComponent implements OnInit {

  @Output() changeSection = new EventEmitter<string>();

  constructor(
    private campaignService: CampaignService,
    private plannerService: CampaignPlannerService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  createCampaign = () =>
    this.campaignService.addCampaign(this.plannerService.campaign).then(campaign =>
      this.router.navigate([ABS_BASE_ROUTE, SECTIONS.production, CAMPAIGNS_ROUTES.parent, CAMPAIGNS_ROUTES.detail, campaign.id]));
}
