import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { IWorkerReceivedGroup } from '@routes/workers/worker.model';
import { WorkerService } from '@routes/workers/worker.service';
import { WorkerActivationMode, WorkerStatus, WorkerTranslation } from '@routes/workers/workers.constants';

import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { CommonTranslation } from '@shared/constants/translatation.constants';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { TranslationUtils } from '@shared/utils/translation.utils';

@Component({
  selector: 'app-workers-activation-dialog',
  templateUrl: './workers-activation-dialog.component.html',
  styleUrls: ['./workers-activation-dialog.component.scss']
})
export class WorkersActivationDialogComponent extends TranslationUtils implements OnInit {

  worker: IWorkerReceivedGroup;
  mode: WorkerActivationMode;
  activating: boolean;
  texts: any;
  translateKey: string;
  cancelButton: IGenericButtonConfig;
  confirmButton: IGenericButtonConfig;

  constructor(
    private workersService: WorkerService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<WorkersActivationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(translateService);
  }

  ngOnInit(): void {
    this.worker = this.data.worker;
    this.mode = this.data.mode;
    this.activating = this.mode === WorkerActivationMode.Activate;
    this.retrieveTexts();
    this.configureButtons();
  }

  retrieveTexts = () => {
    this.texts = this.translate([
      WorkerTranslation.Activation,
      CommonTranslation.Action
    ], {name: `${this.worker.name} ${this.worker.surname}`});

    this.translateKey = this.mode === WorkerActivationMode.Activate ? 'ACTIVATION' : 'DEACTIVATION';
    this.texts.TITLE = this.texts[`${this.translateKey}_TITLE`];
    this.texts.TEXT = this.texts[`${this.translateKey}_TEXT`];
    this.texts.WARNING = this.texts[`${this.translateKey}_WARNING`];
  }

  configureButtons = () => {
    this.cancelButton = {
      color: GenericButtonColor.Warn,
      type: GenericButtonType.Button,
      text: this.texts.CANCEL
    };
    this.confirmButton = {
      color: GenericButtonColor.Primary,
      type: GenericButtonType.Button,
      text: this.texts.CONFIRM
    }
  }

  confirmAction = async () => {
    try {
      const status = this.activating ? WorkerStatus.Active : WorkerStatus.Inactive;
      await this.workersService.updateWorker(this.worker._id, {status});
      this.snackbarService.success(this.texts[`${this.translateKey}_SUCCESS`]);
      this.dialogRef.close({reload: true});
    } catch (error) {
      this.snackbarService.error(error.error.message);
    }
  }

  closeDialog = () => this.dialogRef.close();

}
