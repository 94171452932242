<ng-container *ngIf="section === 'table'; else upload">
    <div class="d-flex flex-row flex-nowrap justify-content-center justify-content-sm-end mb-2 mb-sm-4">
        <button mat-flat-button color="primary" class="btn mx-2" type="button" (click)="changeSection('upload')">
            <img class="btn-image" src="/assets/images/icons/folder.svg" alt="{{ 'general.alt.UPLOAD_FILE' | translate }}"/>
            <span class="btn-text">{{ 'general.actions.UPLOAD_FILE' | translate }}</span>
        </button>
    </div>
    <app-campaigns-workers (campaignHasChanged)="campaignChanged = true"></app-campaigns-workers>
    <section class="controls d-flex justify-content-between mt-4">
        <button mat-flat-button color="primary" class="btn" type="button"
            [routerLink]="MAIN_ROUTE">
            {{ 'general.actions.BACK' | translate }}
        </button>
        <button mat-flat-button color="primary" class="btn" type="button"
            (click)="editCampaign()" [disabled]="!campaignChanged || campaign.status === CampaignStatus.Finished">
            {{ 'general.actions.APPLY_CHANGES' | translate }}
        </button>
    </section>
</ng-container>

<ng-template #upload>
    <app-upload-file-page [config]="uploadConfig" (exitUpload)="changeSection('table')">
        <div class="alert alert-secondary">
            <p class="m-0">{{ 'campaigns.workers.FILE_UPLOAD_HINT' | translate }}</p>
        </div>
    </app-upload-file-page>
</ng-template>