import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Keychain } from '@routes/keychains/keychain.model';
import { KeychainService } from '@routes/keychains/keychain.service';
import { WorkerReceived, WorkerReceivedGroup } from '@routes/workers/worker.model';
import { WorkerService } from '@routes/workers/worker.service';
import { WorkersDetailService } from '@routes/workers/workers-detail/workers-detail.service';

import { SECTIONS, ABS_BASE_ROUTE, WORKER_ROUTES } from '@shared/constants/routes';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { WORKER_GENDERS, WORKER_MARITAL_STATUSES, WORKER_STATUSES } from '@shared/interfaces/types';
import { Pagination } from '@shared/models/pagination.model';
import { ICountry } from '@shared/interfaces/country.models';
import { CountryService } from '@shared/services/country.service';
import { WorkersValidationService } from '@routes/workers/workers-validation.service';
import { DateTime } from "luxon";

@Component({
  selector: 'app-workers-detail-form',
  templateUrl: './workers-detail-form.component.html',
  styleUrls: ['./workers-detail-form.component.scss']
})
export class WorkersDetailFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('recommended') recommended: ElementRef;
  @ViewChild('keychain') keychain: ElementRef;

  BASE_URL: string[] = [ABS_BASE_ROUTE, SECTIONS.workforce, WORKER_ROUTES.parent];
  EDIT_URL: string[];
  DETAIL_URL: string[];

  worker: WorkerReceived;
  editMode: boolean;

  form: FormGroup;
  workers: WorkerReceivedGroup[];
  keychains: Keychain[];
  countries: ICountry[];
  workerStatuses = [...WORKER_STATUSES];
  workerGenders = [...WORKER_GENDERS];
  workerMaritalStatuses = [...WORKER_MARITAL_STATUSES];
  keychainAssignedMessage: string;
  subscriptions: Subscription[] = [];
  pagination: Pagination = new Pagination({
    size: 10, page: 1, sort: {field: 'name', order: 1}});
  Validators = Validators;

  constructor(
    private workerService: WorkerService,
    private snackbarService: CustomSnackbarService,
    private detailService: WorkersDetailService,
    private keychainService: KeychainService,
    private countryService: CountryService,
    private translateService: TranslateService,
    private router: Router,
    private workersValidation: WorkersValidationService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.detailService.workerObservable.subscribe(worker => {
      this.DETAIL_URL = [...this.BASE_URL, WORKER_ROUTES.detail, worker.id];
      this.EDIT_URL = [...this.BASE_URL, WORKER_ROUTES.edit, worker.id];
      this.worker = worker;
    }));
    this.subscriptions.push(this.detailService.editObservable.subscribe(editMode => this.editMode = editMode));
    this.countries = this.countryService.getCountries();

    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      surname: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      socialSecurityNumber: new FormControl(null),
      phoneNumber: new FormControl(null),
      birthDate: new FormControl(null),
      gender: new FormControl(null),
      maritalStatus: new FormControl(null),
      nationality: new FormControl(null),
      nif: new FormControl(null),
      taxResidence: new FormControl(null),
      iban: new FormControl(null),
      recommendedBy: new FormControl(null),
      keychain: new FormControl(null),
      alphanumericCode: new FormControl(null),
      promoter: new FormControl(false, Validators.required)
    });
    this.editMode
      ? this.subscribeKeychain()
      : this.form.disable();

    this.subscriptions.push(this.form.get('country').valueChanges.subscribe(value => this.workersValidation.validateCountryControls(value, this.form, null)));
    this.form.patchValue(this.worker);
    this.form.controls.recommendedBy.setValue(this.worker.recommendedBy);
    this.form.controls.keychain.setValue(this.worker.keychain);
  }

  ngAfterViewInit(): void {
    fromEvent(this.recommended.nativeElement, 'input').pipe(debounceTime(250)).subscribe(async () => {
      this.pagination.search = (this.recommended.nativeElement as HTMLInputElement).value;
      this.workers = (await this.workerService.getWorkers(this.pagination)).workers;
    });

    fromEvent(this.keychain.nativeElement, 'input').pipe(debounceTime(250)).subscribe(async () => {
      this.pagination.search = (this.keychain.nativeElement as HTMLInputElement).value;
      this.keychains = (await this.keychainService.getKeychains(this.pagination)).keychains;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeKeychain = () => {
    this.subscriptions.push(this.form.get('status').valueChanges.subscribe(value => {
      const keychain = this.form.get('keychain');
      value === 'active' ? keychain.enable() : keychain.disable();
      keychain.setValue(null);
    }));
  }

  displayWorker = (worker: WorkerReceivedGroup) =>
    worker ? `${worker.name} ${worker.surname} (${worker.sequentialId})` : '';

  displayKeychain = (keychain: Keychain) => keychain ? `${keychain.code} (${keychain.tag})` : '';

  keychainAvailable = async (event: MatAutocompleteSelectedEvent) => {
    const id = (event.option.value as Keychain)._id;
    const worker = (await this.keychainService.getKeychain(id)).worker;

    this.keychainAssignedMessage = (worker && worker._id !== this.worker.id)
      ? this.translateService.instant('workers.detail.form.KEYCHAIN_ASSIGNED_MESSAGE', { worker }) : '';
  }

  updateWorker = (): void => {
    const value = { ...this.form.value };

    if (this.form.value.recommendedBy) {
      value.recommendedBy = this.form.value.recommendedBy.id || this.form.value.recommendedBy._id;
    }
    if(this.form.value.birthDate && typeof this.form.value.birthDate !== 'string' ) value.birthDate = DateTime.fromJSDate(this.form.value.birthDate).toFormat('dd-MM-yyyy').toString();
  
    value.keychainId = this.form.value.keychain?._id || null;
    delete value.keychain;
    Object.entries(value).forEach(([key, val]) => value[key] = (val !== '') ? val : null);

    this.workerService.editWorker(this.worker.id, value)
      .then(() => this.router.navigate(this.DETAIL_URL))
      .catch(error => this.snackbarService.error(error.error.message));
  }

}