export const DATE_REG_EXP = {

};

export const TIME_REG_EXP = {
    HOUR_MINUTES: new RegExp(/^([0-1]\d|[2][0-3]):([0-5]\d)$/)
};

export const GENERIC_REG_EXP = {
    PHONE: new RegExp(/^[\+]?[\d]+$/)
};

export const BATCH_REG_EXP = {
    UNIQUE: new RegExp(/^LU(?<siteCode>\d{2})(?<zoneCode>\d{2})(?<cropCode>\d{3})(?<packagingCode>\d{3})(?<boxes>\d{3})(?<julianDate>(?!000)([012]\d\d|3[0-5]\d|36[0-6]))(?<hours>[0-1]\d|2[0-3])(?<minutes>[0-5]\d)$/),
    MIXED: new RegExp(/^LX(?<companyCode>\d{2})(?<warehouseCode>\d{2})(?<productionLineCode>\d{2})(?<julianDate>(?!000)([012]\d\d|3[0-5]\d|36[0-6]))(?<hours>[0-1]\d|2[0-3])(?<minutes>[0-5]\d)$/),
    PLATE: new RegExp(/^([A-Z]|[0-9]){7}$/),
};
