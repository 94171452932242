import { Component, OnInit } from '@angular/core';
import { ItemsService } from '@routes/items/items.service';
import { WeightUnit } from '@shared/constants/common.constants';
import { ABS_BASE_ROUTE, ITEMS_ROUTES, SECTIONS } from '@shared/constants/routes';
import { ItemWeight } from '../items.model';

@Component({
  selector: 'app-items-upload',
  templateUrl: './items-upload.component.html',
  styleUrls: ['./items-upload.component.scss']
})
export class ItemsUploadComponent implements OnInit {

  backRoute: string[] = [
    ABS_BASE_ROUTE, SECTIONS.production,
    ITEMS_ROUTES.parent
  ];
  uploadConfig = {
    uploadFunction: this.itemsService.uploadItemsFile,
    backRoute: this.backRoute,
    fileFormats: ['csv', 'xls', 'xlsx'],
    tableColumns: [
      {
        header: 'items.table.SAGE_REFERENCE',
        name: 'sageReference',
        value: 'sageReference'
      }, {
        header: 'items.table.DESCRIPTION',
        name: 'description',
        value: 'description'
      }, {
        header: 'items.table.FAMILY',
        name: 'category',
        value: 'category',
        formatFunction: (category: string) => category || '-'
      }, {
        header: 'items.table.SUBFAMILY',
        name: 'subcategory',
        value: 'subcategory',
        formatFunction: (subcategory: string) => subcategory || '-'
      }, {
        header: 'items.table.GROSS_WEIGHT',
        name: 'weight.gross',
        value: 'weight',
        formatFunction: (weight: ItemWeight) => `${weight.gross} ${WeightUnit.Kilogram}`
      }, {
        header: 'items.table.NET_WEIGHT',
        name: 'weight.net',
        value: 'weight',
        formatFunction: (weight: ItemWeight) => `${weight.net} ${WeightUnit.Kilogram}`
      }
    ],
    successTab: {
      content: 'items.upload.ITEMS_TAB',
      empty: 'items.upload.NO_ITEMS'
    }
  };

  constructor(
    private itemsService: ItemsService
  ) {}

  ngOnInit(): void {
  }

}
