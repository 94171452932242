import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule, Routes } from '@angular/router';

import { DispatchNotesResolver } from '@routes/dispatch-notes/dispatch-notes.resolver';
import { DispatchNotesComponent } from '@routes/dispatch-notes/dispatch-notes.component';
import { DispatchNotesTableComponent } from '@routes/dispatch-notes/dispatch-notes-table/dispatch-notes-table.component';
import { DispatchNotesDetailComponent } from '@routes/dispatch-notes/dispatch-notes-detail/dispatch-notes-detail.component';
import { DispatchNotesDetailDescriptionComponent } from '@routes/dispatch-notes/dispatch-notes-detail/dispatch-notes-detail-description/dispatch-notes-detail-description.component';
import { DispatchNotesDetailConfirmationComponent } from '@routes/dispatch-notes/dispatch-notes-detail/dispatch-notes-detail-confirmation/dispatch-notes-detail-confirmation.component';

import { DISPATCH_NOTES_ROUTES } from '@shared/constants/routes';

const routes: Routes = [
  {
    path: '',
    component: DispatchNotesComponent,
    children: [
      {
        path: '',
        component: DispatchNotesTableComponent,
        pathMatch: 'full'
      },
      {
        path: `${DISPATCH_NOTES_ROUTES.detail}/:id`,
        component: DispatchNotesDetailComponent,
        resolve: { dispatchNote: DispatchNotesResolver }
      }
    ]
  }
];

@NgModule({
  declarations: [
    DispatchNotesComponent,
    DispatchNotesTableComponent,
    DispatchNotesDetailComponent,
    DispatchNotesDetailDescriptionComponent,
    DispatchNotesDetailConfirmationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DispatchNotesModule {}
