import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class WorkersValidationService {

  constructor() { }

  validateCountryControls = (country: string, form: FormGroup, controlRoute: string) => {
    const validators = country === 'PT' ? [Validators.required] : null;
    const names = ['socialSecurityNumber', 'phoneNumber', 'gender', 'maritalStatus', 'birthDate'];

    names.forEach(name => {
        const controlName = controlRoute ? `${controlRoute}.${name}` : name;
        const control = form.get(controlName);
        control.setValidators(validators);
        control.updateValueAndValidity();
    });
  }
}
