import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

import { StorageService } from 'src/app/core/storage/storage.service';

@Injectable()
export class TranslatorService {

  private defaultLanguage = 'es';

  private availableLanguages = [
    { code: 'en', text: 'English' },
    { code: 'es', text: 'Spanish' }
  ];

  constructor(
    public translate: TranslateService
  ) {
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localeEn, 'en');
    if (!translate.getDefaultLang()) translate.setDefaultLang(this.defaultLanguage);
    this.useLanguage();
  }

  useLanguage = (lang: string = null) => this.translate.use(lang ?? this.translate.getDefaultLang());

  getAvailableLanguages = () => this.availableLanguages;

  getCurrentLanguage = () => this.translate.currentLang ?? this.defaultLanguage;

}
