import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UploadPageConfiguration } from '@shared/interfaces/upload';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-file-page',
  templateUrl: './upload-file-page.component.html',
  styleUrls: ['./upload-file-page.component.scss']
})
export class UploadFilePageComponent implements OnInit {

  @Input() config: UploadPageConfiguration;

  @Output() exitUpload: EventEmitter<void> = new EventEmitter<void>();

  data: any;
  file: File;
  section = 'form';
  loading = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService
  ) {}

  ngOnInit(): void {}

  exit = () => this.config.backRoute
    ? this.router.navigate(this.config.backRoute)
    : this.exitUpload.emit();

  backToUpload = () => {
    this.data = null;
    this.section = 'form';
  }

  uploadFile = (file: File, mode: string) => {
    this.file = file;

    const data = new FormData();
    data.append('file', file, file.name);

    const id = this.config.entityId
      ? this.config.entityId : '';

    const message = this.translateService.instant('general.upload.UPLOADING_FILE');
    this.snackbarService.loading(message);
    this.loading = true;

    this.config.uploadFunction(data, mode, id).then(data => {
      if (mode === 'read') {
        this.snackbarService.snackBar.dismiss();
        this.data = data;
        this.section = 'table';
        return;
      }
      const message = this.translateService.instant('general.upload.SUCCESSFULLY_UPLOAD');
      this.snackbarService.success(message);
      this.exit();
    })
    .catch(error => this.snackbarService.error(error.error.message))
    .finally(() => this.loading = false);
  }

}