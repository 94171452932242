<h2 class="title h2">Agregar intervalo</h2>
<form [formGroup]="form" (submit)="submitForm()">
    <div *ngIf="mode !== FORM_MODES.delete" class="form-section">
        <!-- End timestamp -->
        <mat-form-field>
            <mat-label>{{ 'campaigns.tasks.edit.END_DATE' | translate }}</mat-label>
            <input matInput id="endTimestamp" name="endTimestamp" formControlName="endTimestamp"
                [matDatepicker]="endTimestamp" [readonly]="!taskIsExtended">
            <mat-datepicker-toggle
                matSuffix [for]="endTimestamp" [hidden]="!taskIsExtended"
            ></mat-datepicker-toggle>
            <mat-datepicker #endTimestamp></mat-datepicker>
            <mat-hint>
                <ng-container *ngIf="!taskIsExtended; else endTimestampEditable">
                    {{ 'general.form.AUTOCOMPLETED' | translate }}
                </ng-container>
                <ng-template #endTimestampEditable>
                    * {{ 'general.form.MANDATORY_LONG_DATE' | translate }}
                </ng-template>
            </mat-hint>
        </mat-form-field>
        <!-- End time -->
        <mat-form-field>
            <mat-label>{{ 'campaigns.tasks.edit.END_TIME' | translate }}</mat-label>
            <input matInput appReactiveTimeInput type="text" formControlName="endTime" [dateControl]="dateControl">
            <mat-hint>* {{ 'general.form.MANDATORY_SHORT_TIME' | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'campaigns.tasks.edit.ZONE' | translate }}</mat-label>
            <mat-select formControlName="zoneSequentialId">
                <mat-option *ngFor="let zone of zones" [value]="zone.sequentialId">
                    {{ zone.name }}
                </mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Crop -->
        <mat-form-field>
            <mat-label>{{ 'campaigns.tasks.edit.CROP' | translate }}</mat-label>
            <mat-select formControlName="cropId">
                <mat-option *ngFor="let crop of crops" [value]="crop._id">
                    {{ crop.name }} ({{ crop.variety }})
                </mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Packaging -->
        <mat-form-field *ngIf="isHarvest">
            <mat-label>{{ 'Formato' }}</mat-label>
            <mat-select formControlName="packagingId">
                <mat-option *ngFor="let packaging of packagings" [value]="packaging._id">
                    {{ packaging.description }} 
                </mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Amount -->
        <mat-form-field>
            <mat-label>{{ 'Cantidad' }}</mat-label>
            <input matInput type="number" formControlName="amount">
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <mat-checkbox class="full-row" color="primary" formControlName="endedWithKeychain">
            {{ 'campaigns.tasks.edit.ENDED_WITH_KEYCHAIN' | translate }}
        </mat-checkbox>
    </div>

    <div class="remove-section" *ngIf="mode === FORM_MODES.delete">
        <p>{{ 'campaigns.tasks.edit.REMOVE_CONFIRMATION_MESSAGE' | translate }}</p>
        <ul class="list">
            <li><strong>{{ 'campaigns.tasks.edit.TIME' | translate }}</strong>: {{ interval.endTimestamp | date : 'HH:mm' : 'GMT' }}</li>
            <li><strong>{{ 'campaigns.tasks.edit.CROP' | translate }}</strong>: {{ interval.crop.name }} ({{ interval.crop.variety }})</li>
            <li><strong>{{ 'campaigns.tasks.edit.AMOUNT' | translate }}</strong>: {{ interval.amount }}</li>
        </ul>
    </div>

    <div class="buttons">
        <app-generic-button [config]="cancelButtonConfig" (click)="closeDialog()"></app-generic-button>
        <app-generic-button [config]="submitButtonConfig" [disabled]="mode !== FORM_MODES.delete && form.invalid"></app-generic-button>
    </div>
</form>
