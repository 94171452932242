export enum WORKING_DAY_STATUSES {
    PENDING = 'pending',
    AGREE = 'agree',
    CHECKED = 'checked',
    ERROR = 'error',
    UNASSIGNED = 'unassigned'
};

export enum WorkingDayError {
    Range = 'WORKING_DAY_RANGE'
};

export enum BreakError {
    Range = 'BREAK_RANGE',
    Start = 'BREAK_START',
    End = 'BREAK_END'
};

export enum TaskError {
    Range = 'TASK_RANGE',
    Start = 'TASK_START',
    End = 'TASK_END'
};

export enum IntervalError {
    Start = 'INTERVAL_START',
    End = 'INTERVAL_END'
};

export enum StepperEvent {
    Forward = 'FORWARD',
    Back = 'BACK'
}