import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { CAMPAIGN_TYPES, ITYPE } from './../../../shared/interfaces/types';
import { CAMPAIGNS_ROUTES, SECTIONS, ABS_BASE_ROUTE } from '@shared/constants/routes';

@Component({
  selector: 'app-campaigns-main',
  templateUrl: './campaigns-main.component.html',
  styleUrls: ['./campaigns-main.component.scss']
})
export class CampaignsMainComponent implements OnInit {

  BASE_ROUTE = ABS_BASE_ROUTE;
  SECTIONS = SECTIONS;
  CAMPAIGNS_ROUTES = CAMPAIGNS_ROUTES;
  CAMPAIGN_PLAINNING_ROUTE = [ABS_BASE_ROUTE, SECTIONS.production, CAMPAIGNS_ROUTES.parent, CAMPAIGNS_ROUTES.planning];
  campaignType: string;
  campaignTypes: ITYPE[];

  constructor() { }

  ngOnInit(): void {
    this.campaignTypes = [...CAMPAIGN_TYPES];
    this.campaignType = this.campaignTypes[0].value;
  }

  selectCampaignType = (event: MatSelectChange) => this.campaignType = event.value;

  getCampaignType = (index: number): string => `campaigns.main.${CAMPAIGN_TYPES[index].tag}`;

}
