import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CampaignService } from '@routes/campaigns/campaign.service';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';

import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class CampaignFinishedResolver implements Resolve<boolean> {

    constructor(
        private campaignService: CampaignService,
        private snackbarService: CustomSnackbarService,
        private translateService: TranslateService
    ) {}

    async resolve(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Promise<boolean> {
        const id = this.getCampaignId(activatedRouteSnapshot);
        const campaign = await this.campaignService.getCampaign(id);

        if (campaign.status === CampaignStatus.Finished) {
            const message = this.translateService.instant('campaigns.finish.NOT_EDITABLE');
            this.snackbarService.error(message);
            throw new Error(message);
        }
        return true;
    }

    private getCampaignId = (activatedRouteSnapshot: ActivatedRouteSnapshot): string => {
        let snapshot = activatedRouteSnapshot;

        while (snapshot.parent) {
            if (snapshot.params.campaignId) return snapshot.params.campaignId;
            snapshot = snapshot.parent;
        }
        throw new Error('Campaign identifier not found');
    }

}