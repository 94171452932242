import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';

import { AgenciesComponent } from '@routes/agencies/agencies.component';
import { AgenciesMainComponent } from '@routes/agencies/agencies-main/agencies-main.component';
import { AgenciesEditComponent } from '@routes/agencies/agencies-edit/agencies-edit.component';
import { AgencyResolver } from '@routes/agencies/agency.resolver';

import { AGENCIES_ROUTES } from '@shared/constants/routes';
import { FORM_MODES } from '@shared/constants/forms';


const routes: Routes = [{
  path: '',
  component: AgenciesComponent,
  children: [{
    path: '',
    component: AgenciesMainComponent,
    pathMatch: 'full'
  }, {
    path: AGENCIES_ROUTES.add,
    component: AgenciesEditComponent,
    data: {mode: FORM_MODES.add}
  }, {
    path: `${AGENCIES_ROUTES.edit}/:id`,
    component: AgenciesEditComponent,
    data: {mode: FORM_MODES.edit},
    resolve: {agency: AgencyResolver}
  }, {
    path: `${AGENCIES_ROUTES.detail}/:id`,
    component: AgenciesEditComponent,
    data: {mode: FORM_MODES.detail},
    resolve: {agency: AgencyResolver}
  }]
}];

@NgModule({
  declarations: [
    AgenciesComponent,
    AgenciesMainComponent,
    AgenciesEditComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AgenciesModule {}
