import { Injectable } from '@angular/core';
import { ABS_BASE_ROUTE, CROPS_ROUTES, SECTIONS } from '@shared/constants/routes';

@Injectable({
  providedIn: 'root'
})
export class CropsRoutingService {

  constructor() {}

  private parentRoute = [ABS_BASE_ROUTE, SECTIONS.production, CROPS_ROUTES.parent];

  main = () => [...this.parentRoute];

  add = () => [...this.parentRoute, CROPS_ROUTES.add];

  edit = (_id: string) => [...this.parentRoute, CROPS_ROUTES.edit, _id];
  
  detail = (_id: string) => [...this.parentRoute, CROPS_ROUTES.detail, _id];

}
