<h3 class="h3">{{ 'campaigns.workingDays.mass_edit_dialog.TITLE' | translate }}</h3> 
<h4 class="h4">  {{ 'campaigns.workingDays.mass_edit_dialog.SUBTITLE' | translate }} {{ selectedWorkingDays.size }} </h4>
<mat-horizontal-stepper linear #stepper>
    <mat-step [completed]="selectedWorkingDays.size > 0">
        <ng-template matStepLabel>{{ 'campaigns.workingDays.mass_edit_dialog.steps.SELECT' | translate }}</ng-template>
        <app-campaigns-detail-working-days-mass-edit-selector>
        </app-campaigns-detail-working-days-mass-edit-selector>
    </mat-step>
    <mat-step [stepControl]="form">
        <ng-template matStepLabel>{{ 'campaigns.workingDays.mass_edit_dialog.steps.EDIT' | translate }}</ng-template>
        <app-campaigns-detail-working-days-mass-edit-editor>
        </app-campaigns-detail-working-days-mass-edit-editor>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>{{ 'campaigns.workingDays.mass_edit_dialog.steps.APPROVE' | translate }}</ng-template>
        <app-campaigns-detail-working-days-mass-edit-confirmation>
        </app-campaigns-detail-working-days-mass-edit-confirmation>
    </mat-step>
  </mat-horizontal-stepper>
