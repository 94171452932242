import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CampaignPlannerService } from '../campaign.planner.service';
import { ICampaign } from '../../campaign.model';

@Component({
  selector: 'app-campaigns-planner-basic',
  templateUrl: './campaigns-planner-basic.component.html',
  styleUrls: ['./campaigns-planner-basic.component.scss']
})
export class CampaignsPlannerBasicComponent implements OnInit {

  @Output() changeSection = new EventEmitter<string>();

  form: FormGroup;
  campaign: ICampaign;

  constructor(
    private plannerService: CampaignPlannerService
  ) {}

  ngOnInit(): void {
    this.campaign = this.plannerService.campaign;

    this.plannerService.campaignChanges().subscribe(campaign => this.campaign = campaign);

    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      agreeWorkdays: new FormControl(false)
    }, { validators: [this.dateValidation] });

    if (this.campaign) {
      this.form.patchValue(this.campaign);
    }
    this.form.get('type').valueChanges.subscribe(() => this.plannerService.removeAllSites());
  }

  dateValidation = (control: AbstractControl): {[key: string]: any} | null => {
    const startDate = control.value.startDate;
    const endDate = control.value.endDate;

    if (!startDate || !endDate) {
      return null;
    }
    if (startDate > endDate) {
      return { endDate: control.value };
    }
    return null;
  }

  submitForm = () => {
    if (!this.form.valid) return;

    const campaign: ICampaign = { ...this.form.value };

    campaign.sites = this.campaign?.sites || [];
    campaign.workers = this.campaign?.workers || [];

    this.plannerService.setCampaignBasic(campaign);
    this.changeSection.emit('sites');
  }

}
