<h2 class="title h2">{{ title }}</h2>
<form [formGroup]="form" (submit)="submitForm()">
    <mat-tab-group animationDuration="0ms">
        <mat-tab [label]="'campaigns.detail.TAB_INFORMATION' | translate" [id]="TasksEditSection.Task">
            <app-campaigns-detail-tasks-edit-task [mode]="mode" ></app-campaigns-detail-tasks-edit-task>
        </mat-tab>
        <mat-tab [label]="'general.menu.PRODUCTION_INTERVALS' | translate" [id]="TasksEditSection.Task" [disabled]="form.invalid">
            <app-campaigns-detail-tasks-edit-production-intervals></app-campaigns-detail-tasks-edit-production-intervals>
        </mat-tab>
    </mat-tab-group>
    <div class="buttons">
        <app-generic-button
            [config]="exitButtonConfig" [routerLink]="exitRoute" queryParamsHandling="merge"
        ></app-generic-button>
        <app-generic-button
            [config]="submitButtonConfig" [disabled]="form.invalid"
        ></app-generic-button>
    </div>
</form>
