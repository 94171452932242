import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';
import { CampaignsDetailWorkingDaysTableReassignmentDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-table-reassignment-dialog/campaigns-detail-working-days-table-reassignment-dialog.component';
import { CampaignsDetailWorkingDaysTableComplaintDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-table-complaint-dialog/campaigns-detail-working-days-table-complaint-dialog.component';

import { IWorkingDay, WorkingDay, WorkingDayErrors } from '@shared/models/working-day.model';
import { Pagination } from '@shared/models/pagination.model';
import { WorkingDayService } from '@shared/services/working-day.service';
import { DeleteDialogComponent } from '@shared/widgets/delete-dialog/delete-dialog.component';
import { CAMPAIGNS_DETAIL_ROUTES } from '@shared/constants/routes';
import { WORKING_DAY_STATUSES } from '@shared/constants/working-day';
import { TableColumnCheckbox, TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { FiltersButtonAction, FiltersButtonLink, FiltersConfiguration, FiltersControlDate, FiltersControlOption, FiltersControlSelect } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableColumnStyle, GenericTableElementColor } from '@shared/components/generic-table/generic-table.constants';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { CampaignsDetailWorkingDaysTableErrorsDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-table-errors-dialog/campaigns-detail-working-days-table-errors-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { CampaignsDetailWorkingDaysSelectStateDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-select-state-dialog/campaigns-detail-working-days-select-state-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-campaigns-detail-working-days-table',
  templateUrl: './campaigns-detail-working-days-table.component.html',
  styleUrls: ['./campaigns-detail-working-days-table.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailWorkingDaysTableComponent implements OnInit {

  tableConfig: TableConfiguration<WorkingDay>;
  filtersConfig: FiltersConfiguration;
  campaign: Campaign;
  errors = new Map<string, boolean>();

  constructor(
    private workingDayService: WorkingDayService,
    private plannerService: CampaignPlannerService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.campaign = this.plannerService.campaign;
    this.initFilters();
    this.initTable();
    this.retrieveCampaignData();

    const errorId = this.activatedRoute.snapshot.queryParams.errors;
    if (errorId) this.loadWorkingDayErrors(errorId);
  }

  retrieveWorkingDays = async (pagination: Pagination) => {
    const data = await this.workingDayService.getWorkingDays(this.campaign.id, pagination);
    this.tableConfig.data.next({ data: data.workingDays, total: data.total });
    this.retrieveWorkingDayErrors(data.workingDays);
  }

  retrieveCampaignData = async () => {
    this.filtersConfig.updateControlOptions('deviceIds', this.plannerService.devices
      .map((device): FiltersControlOption => ({ display: device.name, value: device._id })));
    this.filtersConfig.updateControlOptions('siteIds', this.campaign.sites
      .map((site): FiltersControlOption => ({ display: site.siteName, value: site.siteId })));
  }

  retrieveWorkingDayErrors = (workingDays: WorkingDay[]) => {
    this.errors.clear();
    workingDays.forEach(workingDay => {
      const errors = new WorkingDayErrors(workingDay);
      this.errors.set(workingDay._id, errors.hasErrors());
    });
  }

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'campaigns.workingDays',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1, size: 10, search: '', params: null,
      sort: { field: 'startTimestamp', order: -1 }
    },
    columns: [
      new TableColumnText({
        header: 'WORKER',
        name: 'worker',
        sorting: 'worker.name',
        displayAt: 0,
        value: workingDay => `${workingDay.worker.name} ${workingDay.worker.surname}`
      }),
      new TableColumnText({
        header: 'START',
        name: 'startTimestamp',
        sorting: 'startTimestamp',
        displayAt: 520,
        value: workingDay => this.datepipe.transform(workingDay.startTimestamp, 'dd/MM (HH:mm)', 'GMT')
      }),
      new TableColumnText({
        header: 'END',
        name: 'endTimestamp',
        sorting: 'endTimestamp',
        displayAt: 960,
        value: workingDay => this.datepipe.transform(workingDay.endTimestamp, 'dd/MM (HH:mm)', 'GMT')
      }),
      new TableColumnText({
        header: 'HOURS',
        name: 'hours',
        sorting: 'totalHours',
        displayAt: 1024,
        value: workingDay => workingDay.totalHours
      }),
      new TableColumnText({
        header: 'SITE',
        name: 'site',
        sorting: 'site.name',
        displayAt: 1280,
        value: workingDay => workingDay.site.name
      }),
      new TableColumnText({
        header: 'DEVICE',
        name: 'device',
        sorting: 'device.name',
        displayAt: 1440,
        value: workingDay => workingDay.device.name
      }),
      new TableColumnText({
        header: 'STATUS',
        name: 'status',
        sorting: 'status',
        displayAt: 1650,
        value: workingDay => this.translateService.instant(`campaigns.workingDays.${workingDay.status.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).toUpperCase()}`)
      }),
      new TableColumnCheckbox({
        header: 'ERRORS',
        name: 'errors',
        displayAt: 0,
        intermediate: true,
        color: GenericTableElementColor.Warn,
        disabled: () => true,
        checked: (workingDay) => this.errors.get(workingDay._id),
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'ADD_TASK',
            icon: GenericMenuOptionIcon.Assingment,
            link: workingDay => [workingDay._id, CAMPAIGNS_DETAIL_ROUTES.taskAdd],
            disabled: () => this.campaign.status === CampaignStatus.Finished
          }),
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: workingDay => [CAMPAIGNS_DETAIL_ROUTES.workingDayDetail, workingDay._id]
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            link: workingDay => [CAMPAIGNS_DETAIL_ROUTES.workingDayEdit, workingDay._id],
            disabled: () => this.campaign.status === CampaignStatus.Finished
          }),
          new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: workingDay => this.displayDeleteDialog(workingDay),
            disabled: () => this.campaign.status === CampaignStatus.Finished
          }),
          new TableMenuOption({
            text: 'REASSIGN',
            icon: GenericMenuOptionIcon.Group,
            disabled: workingDay => !workingDay.endTimestamp || this.campaign.status === CampaignStatus.Finished,
            action: workingDay => this.displayReassignmentDialog(workingDay)
          }),
          new TableMenuOption({
            text: 'VIEW_COMPLAINT',
            icon: GenericMenuOptionIcon.Description,
            action: workingDay => this.displayComplaintDialog(workingDay),
            disabled: workingDay => workingDay.status !== WORKING_DAY_STATUSES.CHECKED || !workingDay.complaints?.length
          }),
          new TableMenuOption({
            text: 'VIEW_ERRORS',
            icon: GenericMenuOptionIcon.Error,
            action: workingDay => this.displayErrorsDialog(workingDay),
            disabled: workingDay => !this.errors.get(workingDay._id),
          }),
        ]
      })
    ]
  })

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    buttons: [
      new FiltersButtonLink({
        text: 'ADD_WORKING_DAY',
        link: [CAMPAIGNS_DETAIL_ROUTES.workingDayAdd],
        disabled: () => this.campaign.status === CampaignStatus.Finished
      }),
      new FiltersButtonLink({
        text: 'MASS_EDIT_WORKING_DAYS',
        link: [CAMPAIGNS_DETAIL_ROUTES.workingDayMassEdit],
        disabled: () => this.campaign.status === CampaignStatus.Finished
      }),
      
    ],
    controls: [
      new FiltersControlDate({
        label: 'START_DATE',
        name: 'startDate',
        value: new Date(),
        modifier: date => date.setHours(0, 0, 0, 0)
      }),
      new FiltersControlDate({
        label: 'END_DATE',
        name: 'endDate',
        value: null,
        modifier: date => date.setHours(23, 59, 59, 999)
      }),
      new FiltersControlSelect({
        label: 'STATUSES',
        name: 'statuses',
        value: [],
        options: new BehaviorSubject(Object.entries(WORKING_DAY_STATUSES).map((status): FiltersControlOption => ({
          display: this.translateService.instant(`campaigns.workingDays.${status[0]}`),
          value: status[1]
        })))
      }),
      new FiltersControlSelect({
        label: 'DEVICES',
        name: 'deviceIds',
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'SITES',
        name: 'siteIds',
        value: [],
        options: new BehaviorSubject([])
      })
    ]
  });

  displayReassignmentDialog = (workingDay: WorkingDay) => this.dialog.open(CampaignsDetailWorkingDaysTableReassignmentDialogComponent, {
    data: { workingDay },
    width: '520px'
  }).afterClosed()
    .pipe(filter(data => data && data.workingDayChanged))
    .subscribe(async () => {
      const message = 'campaigns.workingDays.reassign_dialog.SUCCESS_MESSAGE';
      this.snackbarService.success(this.translateService.instant(message));
      await this.retrieveWorkingDays(new Pagination(this.tableConfig.pagination));
    })

  displayComplaintDialog = (workingDay: WorkingDay) => this.dialog.open(CampaignsDetailWorkingDaysTableComplaintDialogComponent, {
    data: {workingDay},
    width: '1280px'
  });

  loadWorkingDayErrors = async (_id: string) => {
    const workingDay = await this.workingDayService.getWorkingDay(this.campaign.id, _id);
    const errors = new WorkingDayErrors(workingDay);

    if (!errors.hasErrors()) {
      return this.displaySelectStateDialog(workingDay);
    }
    this.displayErrorsDialog(workingDay);
  }

  displaySelectStateDialog = (workingDay: WorkingDay) => this.dialog.open(CampaignsDetailWorkingDaysSelectStateDialogComponent, {
    data: {workingDay}, width: '420px', disableClose: true
  }).afterClosed()
    .subscribe(async () => {
      try {
        await this.retrieveWorkingDays(new Pagination(this.tableConfig.pagination));
        const message = this.translateService.instant('campaigns.workingDays.errors_dialog.ERRORS_SOLVED');
        this.snackbarService.success(message);
      } catch (error) {
        const message = error.status !== 500
          ? error.error.message
          : 'campaigns.workingDays.errors_dialog.STATUS_ERROR'
        this.snackbarService.error(this.translateService.instant(message));
      }
    });
    
  displayErrorsDialog = (workingDay: WorkingDay) => this.dialog.open(CampaignsDetailWorkingDaysTableErrorsDialogComponent, {
    data: {workingDay}, width: '960px', disableClose: true});

  displayDeleteDialog = (workingDay: WorkingDay) => this.dialog.open(DeleteDialogComponent, {
    data: {
      title: 'campaigns.workingDays.delete_dialog.TITLE',
      msg: this.translateService.instant('campaigns.workingDays.delete_dialog.BODY', { value: workingDay.worker })
    },
    width: '520px'
  }).afterClosed()
    .pipe(filter(data => data?.confirmDelete))
    .subscribe(async () => {
      workingDay.deleted = true;
      workingDay.pickingTasks?.forEach(task => task.deleted = true);
      workingDay.workableTasks?.forEach(task => task.deleted = true);
      workingDay.notWorkableTasks?.forEach(task => task.deleted = true);

      try {
        await this.workingDayService.editWorkingDay(this.campaign.id, workingDay._id, workingDay);
        this.retrieveWorkingDays(new Pagination(this.tableConfig.pagination));
        const message = 'campaigns.workingDays.delete_dialog.SUCCESS_MESSAGE';
        this.snackbarService.success(this.translateService.instant(message));
      } catch (error) {
        const message = error.status !== 500
          ? error.error.message
          : 'campaigns.workingDays.delete_dialog.ERROR_MESSAGE'
        this.snackbarService.error(this.translateService.instant(message));
      }
  });

}