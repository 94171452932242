<h2 class="h2 title">{{ 'batchs.tabs.USED_BATCHS' | translate }}</h2>
<app-generic-table
    [tableConfig]="tableConfig"
></app-generic-table>
<div class="buttons" [ngClass]="{ 'space-layout': editable }">
    <app-generic-button
        [config]="exitButtonConfig" (click)="navigateToPreviousLink()"
    ></app-generic-button>
    <app-generic-button
        *ngIf="editable" [config]="editButtonConfig" [routerLink]="editLink" [queryParams]="{ section: 'batchs' }"
    ></app-generic-button>
</div>