import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { User } from '../user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-users-disable-dialog',
  templateUrl: './users-disable-dialog.component.html',
  styleUrls: ['./users-disable-dialog.component.scss']
})
export class UsersDisableDialogComponent implements OnInit {

  user: User;

  constructor(
    public dialogRef: MatDialogRef<UsersDisableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.user = this.data.user;
  }

  getDialogTitle = () => this.translateService.instant(this.user.disabled
    ? 'users.dialog.ENABLE'
    : 'users.dialog.DISABLE');

  getDialogBody = () => this.translateService.instant(this.user.disabled
    ? 'users.message.ENABLE_EXPLANATION'
    : 'users.message.DISABLE_EXPLANATION');

  editDisabled = () => {
    const user = { ...this.user };
    user.disabled = !user.disabled;

    this.userService.editUser(user._id, user).then(() => {
      const message = this.translateService.instant(user.disabled
        ? 'users.message.ACCOUNT_DISABLED'
        : 'users.message.ACCOUNT_ENABLED');
      this.snackbarService.open(SNACKBAR_TYPES.SUCCESS, message, 3000);
      this.dialogRef.close({ updated: true });
    }).catch(error => this.snackbarService.error(error.error.message))
  }

}
