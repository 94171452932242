import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { IBatchMixedItem, IBatchMixedReceived } from '@routes/batchs/batchs.model';
import { BATCH_CATEGORY, BATCH_MIXED_STATUS_EDIT } from '@routes/batchs/batchs.constants';

import { GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnText, TableConfiguration } from '@shared/components/generic-table/generic-table.model';
import { WeightUnit } from '@shared/constants/common.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { BATCHS_ROUTES } from '@shared/constants/routes';

@Component({
  selector: 'app-batchs-detail-items',
  templateUrl: './batchs-detail-items.component.html',
  styleUrls: ['./batchs-detail-items.component.scss']
})
export class BatchsDetailItemsComponent implements OnInit {

  @Input() batch: IBatchMixedReceived;

  items: IBatchMixedItem[] = [];
  editable: boolean;
  editLink: string[] = ['..', BATCHS_ROUTES.edit];
  tableConfig: TableConfiguration<IBatchMixedItem>;
  navigation: string;

  exitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.BACK',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button
  };
  editButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.EDIT_BATCH',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button
  };

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.items = this.batch.items;
    this.setEditable();
    this.initTable();
    this.activatedRoute.queryParams.subscribe(params =>  this.navigation = params['navigation']);
  }

  setEditable = () =>
    this.editable = BATCH_MIXED_STATUS_EDIT.includes(this.batch.status);

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({ data: this.items, total: this.items.length }),
    pagination: {
      page: 1, size: 10, search: '', params: {},
      sort: { field: 'sageReference', order: 1 }
    },
    columns: [
      new TableColumnText({
        header: 'SAGE_REFERENCE',
        name: 'sageReference',
        sorting: 'sageReference',
        displayAt: 1024,
        value: batch => batch.sageReference
      }),
      new TableColumnText({
        header: 'DESCRIPTION',
        name: 'description',
        sorting: 'description',
        displayAt: 0,
        value: batch => batch.description
      }),
      new TableColumnText({
        header: 'CATEGORY',
        name: 'category',
        sorting: 'category',
        displayAt: 1280,
        value: batch => batch.category
      }),
      new TableColumnText({
        header: 'SUBCATEGORY',
        name: 'subcategory',
        sorting: 'subcategory',
        displayAt: 1280,
        value: batch => batch.subcategory
      }),
      new TableColumnText({
        header: 'WEIGHT_NET',
        name: 'weight',
        sorting: 'weight',
        displayAt: 0,
        value: batch => `${batch.weight} ${WeightUnit.Kilogram}`
      }),
      new TableColumnText({
        header: 'BOXES',
        name: 'boxes',
        sorting: 'boxes',
        displayAt: 640,
        value: batch => batch.boxes
      })
    ]
  });

  navigateToPreviousLink = () => this.navigation === 'previous'
    ? this.location.back() 
    : this.router.navigate(['../..'], { relativeTo: this.activatedRoute })

}
