export enum BATCH_CATEGORY {
    UNIQUE = 'unique',
    MIXED = 'mixed'
};

export enum BATCH_UNIQUE_STATUS {
    PALLETIZED = 'palletized',
    STORED = 'stored',
    DISPATCHED = 'dispatched',
    INCIDENT = 'incident'
};

export enum BATCH_MIXED_STATUS {
    OPEN = 'open',
    CLOSED = 'closed',
    SYNCHRONIZING = 'synchronizing',
    SAGE = 'sage',
};

export const BATCH_DETAIL_STATUS = [
    BATCH_UNIQUE_STATUS.PALLETIZED,
    BATCH_UNIQUE_STATUS.STORED,
    BATCH_UNIQUE_STATUS.DISPATCHED,
    BATCH_MIXED_STATUS.OPEN,
    BATCH_MIXED_STATUS.CLOSED
];

export const BATCH_MIXED_STATUS_EDIT = [
    BATCH_MIXED_STATUS.OPEN,
    BATCH_MIXED_STATUS.CLOSED,
];

export const BATCH_ALL_STATUS = {
    ...BATCH_UNIQUE_STATUS,
    ...BATCH_MIXED_STATUS
};

export enum BATCH_PROCESS {
    PRODUCTION = 'production',
    SALES = 'sales',
    TRANSFORMATION = 'transformation'
};

export enum BATCH_TYPE {
    FRUIT = 'fruit',
    PLANT = 'plant'
};

export const BATCH_PROCESS_PER_STATUS = {
    [BATCH_UNIQUE_STATUS.PALLETIZED]: [BATCH_PROCESS.PRODUCTION],
    [BATCH_UNIQUE_STATUS.STORED]: [BATCH_PROCESS.PRODUCTION],
    [BATCH_UNIQUE_STATUS.DISPATCHED]: [BATCH_PROCESS.SALES],
    [BATCH_MIXED_STATUS.OPEN]: [BATCH_PROCESS.TRANSFORMATION],
    [BATCH_MIXED_STATUS.CLOSED]: [BATCH_PROCESS.TRANSFORMATION],
    [BATCH_MIXED_STATUS.SYNCHRONIZING]: [BATCH_PROCESS.TRANSFORMATION],
    [BATCH_MIXED_STATUS.SAGE]: [BATCH_PROCESS.TRANSFORMATION]
};

export const BATCH_UNIT_PER_TYPE = {
    [BATCH_TYPE.FRUIT]: 'kg',
    [BATCH_TYPE.PLANT]: 'pl'
};
