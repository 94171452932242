import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { CampaignsDetailFinishDialogComponent } from '@routes/campaigns/campaigns-main/campaigns-detail-finish-dialog/campaigns-detail-finish-dialog.component';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';

@Component({
  selector: 'app-campaigns-detail-basic',
  templateUrl: './campaigns-detail-basic.component.html',
  styleUrls: ['./campaigns-detail-basic.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailBasicComponent implements OnInit {

  CampaignStatus = CampaignStatus;

  finishButtonConfig: IGenericButtonConfig = {
    type: GenericButtonType.Button,
    color: GenericButtonColor.Primary,
    text: 'general.actions.FINISH_CAMPAIGN',
  };
  campaign: Campaign;

  constructor(
    public campaignService: CampaignService,
    private plannerService: CampaignPlannerService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.campaign = this.plannerService.campaign;
  }

  displayFinishDialog = () => this.dialog.open(CampaignsDetailFinishDialogComponent,
    {data: {campaign: this.campaign}, width: '420px', disableClose: true}
  ).afterClosed().pipe(filter(data => data?.finished)).subscribe(data => {
    const message = this.translateService.instant('campaigns.finish.SUCCESS');
    this.snackbarService.success(message);
    this.campaign = data.campaign;
    this.plannerService.setCampaignBasic(data.campaign);
  });

}