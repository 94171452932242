import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignSite } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { Pagination } from 'src/app/shared/models/pagination.model';
import { WorkingSite } from 'src/app/routes/working-sites/working-site.model';
import { WorkingSiteService } from 'src/app/routes/working-sites/working-site.service';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { SITE_TYPE } from '@routes/working-sites/working-sites.constants'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaing-details-zones-dialog',
  templateUrl: './campaing-details-zones-dialog.component.html',
  styleUrls: ['./campaing-details-zones-dialog.component.scss']
})
export class CampaingDetailsZonesDialogComponent implements OnInit {

  form: FormGroup;
  sites: WorkingSite[];
  selectedSite: WorkingSite;
  genericPagination = new Pagination({
    page: 1,
    size: Number.MAX_SAFE_INTEGER,
    sort: { field: 'name', order: 1 }
  });

  constructor(    
    private siteService: WorkingSiteService,
    private plannerService: CampaignPlannerService,
    private campaignService : CampaignService,
    private snackbarService: CustomSnackbarService,
    public dialogRef: MatDialogRef<CampaingDetailsZonesDialogComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      site: new FormControl(null, Validators.required)
    });
    
    this.retrieveSites().then(data => this.loadSites(data));

    
  }

  retrieveSites = (): Promise<object> => this.siteService.getSites(this.genericPagination);

  loadSites(data: object) {
    this.sites =  data['sites'].filter(site => {
      return ((this.plannerService.campaign.type === site.type || site.type === SITE_TYPE.BOTH) 
      && (!this.plannerService.siteIsIncluded(site.id)))
    });

  }

  submitForm() {
    this.selectedSite = this.form.value.site;
    const site = new CampaignSite({ siteId: this.selectedSite.id,  siteName: this.selectedSite.name, zones: [] });
    this.plannerService.addSite(site);
    this.campaignService.editCampaign(this.plannerService.campaign)
      .then(() => {
        this.snackbarService.success(this.translateService.instant('campaigns.sites.SITE_ADDED'));
        this.dialogRef.close({ siteId: this.selectedSite.id });
      })
      .catch(error => this.snackbarService.open(SNACKBAR_TYPES.ERROR, error.error.message));
  }



}
