export const transformDateToUTC = (date: Date | string): Date | null => {
    if (!date) return null;
    const dateWrapper = new Date(date);
    if (!dateWrapper) return null;
    return new Date(Date.UTC(
        dateWrapper.getFullYear(),
        dateWrapper.getMonth(),
        dateWrapper.getDate(),
        dateWrapper.getHours(),
        dateWrapper.getMinutes(),
        dateWrapper.getSeconds()
    ))
};
