import { Injectable } from '@angular/core';
import { PositionsStorageService } from '@routes/positions/positions-storage.service';
import { Position, PositionDTO, PositionPaginated } from '@routes/positions/positions.models';
import { Pagination } from '@shared/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class PositionsMainService {

  constructor(
    private positionsStorageService: PositionsStorageService
  ) {}

  getAll = async (pagination?: Pagination): Promise<PositionPaginated> => {
    try {
      return await this.positionsStorageService.getAll(pagination);
    } catch (error) {
      throw error;
    }
  }

  get = async (_id: string): Promise<Position> => {
    try {
      return await this.positionsStorageService.get(_id);
    } catch (error) {
      throw error;
    }
  }

  add = async (position: PositionDTO): Promise<Position> => {
    try {
      return await this.positionsStorageService.add(position);
    } catch (error) {
      throw error;
    }
  }

  edit = async (_id: string, position: PositionDTO): Promise<Position> => {
    try {
      return await this.positionsStorageService.edit(_id, position);
    } catch (error) {
      throw error;
    }
  }

  delete = async (_id: string): Promise<void> => {
    try {
      await this.positionsStorageService.delete(_id);
    } catch (error) {
      throw error;
    }
  }

}
