import { TASK_TYPES_TYPES } from '@shared/constants/task-types';

export interface ITaskTypesPaginated {
    tasksTypes: ITaskType[];
    total: number;
}

export interface ITaskType {
    _id?: string;
    id: string;
    name: string;
    workable: boolean;
    type: TASK_TYPES_TYPES;
    unit?: string;
    protected: boolean;
}
export class TaskType implements ITaskType {
    public _id?: string;
    public id: string;
    public name: string;
    public workable: boolean;
    public type: TASK_TYPES_TYPES;
    public unit?: string;
    public protected: boolean;

    constructor(taskType: ITaskType) {
        Object.assign(this, taskType);
    }
}

export interface ITaskTypeUnit {
    name: string;
    symbol: string;
}

export class TaskTypeUnit implements ITaskTypeUnit {
    name: string;
    symbol: string;

    constructor(unit: ITaskTypeUnit) {
        Object.assign(this, unit);
    }
}