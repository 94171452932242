import { Component, OnInit } from '@angular/core';
import { ABS_BASE_ROUTE, KEYCHAIN_ROUTES, SECTIONS } from '@shared/constants/routes';
import { UploadPageConfiguration } from '@shared/interfaces/upload';
import { KeychainService } from '@routes/keychains/keychain.service';

@Component({
  selector: 'app-keychains-file-upload',
  templateUrl: './keychains-file-upload.component.html',
  styleUrls: ['./keychains-file-upload.component.scss']
})
export class KeychainsFileUploadComponent implements OnInit {

  uploadConfig: UploadPageConfiguration;
  mainRoute = [ABS_BASE_ROUTE, SECTIONS.workforce, KEYCHAIN_ROUTES.parent];
  
  constructor(
    private keychainService: KeychainService
  ) { }

  ngOnInit(): void {
    this.uploadConfig = {
      uploadFunction: this.keychainService.addKeychainsByFile,
      backRoute: this.mainRoute,
      fileFormats: ['csv', 'xls', 'xlsx'],
      tableColumns: [
        {
          header: 'keychains.table.CODE',
          name: 'code',
          value: 'code'
        }, {
          header: 'keychains.table.TAG',
          name: 'tag',
          value: 'tag'
        }
      ],
      successTab: {
        content: 'keychains.upload.KEYCHAINS',
        empty: 'keychains.upload.NO_KEYCHAINS'
      }
    }
  }
}

  


