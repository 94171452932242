export enum GenericTableBehaviour {
    Local = 'local',
    Server = 'server'
}

export enum GenericTableColumnStyle {
    Compact = 'compact',
    Normal = 'normal'
}

export enum GenericTableColumnAlign {
    Left = 'left',
    Center = 'center',
    Right = 'right'
}

export enum GenericTableColumnType {
    Text = 'text',
    Chip = 'chip',
    Menu = 'menu',
    Checkbox = 'checkbox',
    Selector = 'selector'
}

export enum GenericTableElementColor {
    Primary = 'primary',
    Accent = 'accent',
    Warn = 'warn'
}

export enum GenericTableCellColor {
    Alert = 'alert',
    Success = 'success',
    Error = 'error'
}

export enum GenericMenuOptionIcon {
    Search = 'search',
    Edit = 'edit',
    Add = 'add',
    Delete = 'delete',
    Copy = 'content_copy',
    Group = 'group',
    Assingment = 'assignment',
    NumberedList = 'format_list_numbered',
    DeviceApproval = 'system_security_update_good',
    Download = 'download',
    Description = 'description',
    Error = 'error',
    Person = 'person',
    PersonOff = 'person_off'
}

export type TableQueryParam = {[key: string]: string | number};

export type TableColorType<T> = {
    color: GenericTableCellColor,
    active: (entity: T) => boolean
};
