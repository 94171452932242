import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { CampaignUtilsService } from '@routes/campaigns/campaign.utils.service';
import { CampaignZone, Campaign, CampaignSite } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaingDetailsZonesDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-zones/campaing-details-zones-dialog/campaing-details-zones-dialog.component';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';

import { CAMPAIGNS_DETAIL_ROUTES } from '@shared/constants/routes';

@Component({
  selector: 'app-campaigns-detail-zones-table',
  templateUrl: './campaigns-detail-zones-table.component.html',
  styleUrls: ['./campaigns-detail-zones-table.component.scss']
})
export class CampaignsDetailZonesTableComponent implements OnInit, OnDestroy {

  CAMPAIGNS_DETAIL_ROUTES = CAMPAIGNS_DETAIL_ROUTES;
  CampaignStatus = CampaignStatus;

  campaign: Campaign;
  sites: CampaignSite[];
  site: CampaignSite;
  zones: CampaignZone[];
  subscription: Subscription;


  constructor(
    private plannerService: CampaignPlannerService,
    private campaignUtilsService: CampaignUtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.campaign = this.plannerService.campaign;
    this.site = this.plannerService.site;
    this.zones = this.plannerService.zones;
    this.sites = this.plannerService.campaign.sites;
    this.subscription = this.plannerService.campaignChanges().subscribe(() => {
      this.zones = this.plannerService.zones;
      this.sites = this.plannerService.campaign.sites;
      this.site = this.plannerService.site;
    });
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  calculateDensity = (area: number, plants: number): string =>
    this.campaignUtilsService.calculateDensity(area, plants);

  selectCampaignSite = (event: MatSelectChange) => {
    this.plannerService.selectSite(event.value);
    this.site = this.plannerService.site;
    this.zones = this.plannerService.zones;
  }

  editZone = (zone: CampaignZone) => {
    this.plannerService.selectZone(zone.sequentialId);
    this.router.navigate(['./', this.site.siteId, CAMPAIGNS_DETAIL_ROUTES.editZone, zone.sequentialId], { relativeTo: this.activatedRoute });
  }

  displayDialog = () =>
    this.dialog.open(CampaingDetailsZonesDialogComponent, {
      data: {},
      width: '520px'
    }).afterClosed().subscribe(response => response && response.siteId && this.plannerService.selectSite(response.siteId));

}
