import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiService } from '@shared/services/api.service';
import { Pagination } from '@shared/models/pagination.model';
import { ITaskPaginated, ITaskReceived, TaskSent } from '@shared/models/task.model';
import { IWorkingDayPaginated, WorkingDay, WorkingDaySent, WorkingDayReceived, IWorkingDayMassEdits } from '@shared/models/working-day.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WorkingDayService {

    constructor(
        private api: ApiService,
        private http: HttpClient
    ) {}

    private getWorkingDaysUrl = (campaignId: string): string =>
        `${this.api.getBaseUrl()}/campaigns/${campaignId}/workingDays`;

    private getWorkingDayUrl = (campaignId: string, workingDayId: string): string =>
        `${this.getWorkingDaysUrl(campaignId)}/${workingDayId}`;

    private getWorkingDaysMassEditUrl = (campaignId: string, workingDayIds: string, confirmation: boolean): string =>
        `${this.getWorkingDaysUrl(campaignId)}/mass-edit/${workingDayIds}/${confirmation}`;

    private getTasksUrl = (campaignId: string): string =>
        `${this.api.getBaseUrl()}/campaigns/${campaignId}/tasks`;
    
    private getTaskUrl = (campaignId: string, taskId: string): string =>
        `${this.getTasksUrl(campaignId)}/${taskId}`;

    /* Working days */

    getWorkingDays = (campaignId: string, pagination: Pagination) =>
        lastValueFrom(this.http.get<IWorkingDayPaginated>(this.getWorkingDaysUrl(campaignId), { params: pagination.loadHttpParams() }));

    getWorkingDay = (campaignId: string, workingDayId: string) =>
        lastValueFrom(this.http.get<WorkingDay>(this.getWorkingDayUrl(campaignId, workingDayId)));

    addWorkingDay = (campaignId: string, workingDay: WorkingDaySent): Promise<WorkingDayReceived> =>
        lastValueFrom(this.http.post<WorkingDayReceived>(this.getWorkingDaysUrl(campaignId), workingDay));

    editWorkingDay = (campaignId: string, workingDayId: string, workingDay: WorkingDay) =>
        lastValueFrom(this.http.put<WorkingDay>(this.getWorkingDayUrl(campaignId, workingDayId), workingDay));

    deleteWorkingDay = (campaignId: string, workingDayId: string) =>
        lastValueFrom(this.http.delete<any>(this.getWorkingDayUrl(campaignId, workingDayId)));

    editMultipleWorkingDays = (campaignId: string, workingDayIds: string, edits: IWorkingDayMassEdits, confirmation: boolean) => 
        lastValueFrom(this.http.put<IWorkingDayMassEdits>(this.getWorkingDaysMassEditUrl(campaignId, workingDayIds, confirmation), edits));
    
    /* Tasks */
    getTasks = (campaignId: string, pagination: Pagination) =>
        lastValueFrom(this.http.get<ITaskPaginated>(this.getTasksUrl(campaignId), { params: pagination.loadHttpParams() }));

    getTask = (campaignId: string, taskId: string) =>
        lastValueFrom(this.http.get<ITaskReceived>(this.getTaskUrl(campaignId, taskId)));
        
}