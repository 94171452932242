<form [formGroup]="form" (submit)="submitForm()">
    <h2 class="h2 title">
        {{ 'campaigns.workingDays.errors_dialog.CHANGE_STATUS' | translate }}
    </h2>
    <p class="alert alert-success">
        {{ 'campaigns.workingDays.errors_dialog.ERRORS_SOLVED_MESSAGE' | translate }}
    </p>
    <mat-form-field class="input" appearance="standard">
        <mat-label>{{ 'campaigns.workingDays.STATUS' | translate }}</mat-label>
        <mat-select formControlName="status">
            <mat-option *ngFor="let status of WORKING_DAY_STATUSES | keyvalue" [value]="status.value" [disabled]="!avaliableStatuses.includes(status.value)">
                {{ 'campaigns.workingDays.' + status.key | translate }}
            </mat-option>
        </mat-select>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <mat-dialog-actions class="actions">
        <button mat-flat-button mat-dialog-close color="primary" class="actions-button btn" type="submit" [disabled]="form.invalid">
            {{ 'general.actions.ADD' | translate }}
        </button>
    </mat-dialog-actions>
</form>