import { Component } from '@angular/core';
import { CountryService } from '@shared/services/country.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private countryService: CountryService
  ) {}

  title = 'viveros';
  
}
