import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { environment } from '@environments/environment';
import { HeaderService } from '@layout/header/header.service';

import { BatchsSageModalComponent } from '@routes/batchs/batchs-sage-modal/batchs-sage-modal.component';
import { BATCH_CATEGORY, BATCH_MIXED_STATUS, BATCH_MIXED_STATUS_EDIT } from '@routes/batchs/batchs.constants';
import { IBatchMixedPaginated } from '@routes/batchs/batchs.model';
import { BatchsService } from '@routes/batchs/batchs.service';
import { CompaniesService } from '@routes/companies/companies.service';

import { FiltersButtonAction, FiltersButtonLink, FiltersConfiguration, FiltersControlDate, FiltersControlOption, FiltersControlSelect, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { GenericTableBehaviour, GenericTableElementColor, GenericTableColumnStyle, GenericMenuOptionIcon } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnCheckbox, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { WeightUnit } from '@shared/constants/common.constants';
import { BATCHS_ROUTES } from '@shared/constants/routes';
import { filter } from 'rxjs/operators';
import { DeleteDialogComponent } from '@shared/widgets/delete-dialog/delete-dialog.component';
import { FilterOperator } from '@shared/components/generic-filters/generic-filters.constants';

@Component({
  selector: 'app-batchs-table-mixed',
  templateUrl: './batchs-table-mixed.component.html',
  styleUrls: ['./batchs-table-mixed.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class BatchsTableMixedComponent implements OnInit {

  tableConfig: TableConfiguration<IBatchMixedPaginated>;
  filtersConfig: FiltersConfiguration;

  selectedBatchs: Set<string> = new Set();

  baseDate: Date;

  loading = false;

  constructor(
    private batchsService: BatchsService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private companiesService: CompaniesService,
    private headerService: HeaderService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.baseDate = new Date(`${new Date().getFullYear()}-01-01T00:00:00`);
    this.initFilters();
    this.initTable();
    this.getCompanies();

    this.headerService.typeFilterChanges().subscribe(type => {
      Object.assign(this.tableConfig.pagination.params, {type});
      this.tableConfig.updatePagination.next(this.tableConfig.pagination as FiltersEvent);
    });
  }

  getBatchs = (pagination: Pagination) => this.batchsService.getBatchs(pagination)
    .then(response => this.tableConfig.data.next(response))
    .catch(error => this.snackbarService.error(error.error.message))

  getCompanies = async () => {
    const companies = await this.companiesService.getCompanies();

    const filterOptions = companies.data.map(company => new FiltersControlOption({
      value: company._id,
      display: company.businessName
    }));
    this.filtersConfig.updateControlOptions('companies', filterOptions);
  }

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [
      new FiltersControlDate({
        label: 'START_DATE',
        name: 'startTime',
        field: 'datetime',
        value: null,
        operator: FilterOperator.GreaterEqual,
        modifier: date => date.setHours(0, 0, 0, 0),
        maxDateReferenceName: 'endDate',
      }),
      new FiltersControlDate({
        label: 'END_DATE',
        name: 'endDate',
        field: 'datetime',
        value: null,
        operator: FilterOperator.LessEqual,
        modifier: date => date.setHours(23, 59, 59, 999),
        minDateReferenceName: 'startDate',
      }),
      new FiltersControlSelect({
        label: 'YEAR',
        name: 'year',
        field: 'datetime',
        multiple: false,
        operator: FilterOperator.GreaterEqual,
        value: this.baseDate,
        options: new BehaviorSubject([
          {
            display: this.translateService.instant(`general.filters.ALL`),
            value: null
          }, {
            display: new Date().getFullYear().toString(),
            value: this.baseDate,
          }
        ])
      }),
      new FiltersControlSelect({
        label: 'STATUSES',
        name: 'statuses',
        field: 'status',
        value: [],
        operator: FilterOperator.In,
        options: new BehaviorSubject(Object.entries(BATCH_MIXED_STATUS).map((status): FiltersControlOption => ({
          display: this.translateService.instant(`batchs.status.${status[0]}`),
          value: status[1]
        }))),
      }),
      new FiltersControlSelect({
        label: 'COMPANIES',
        name: 'companies',
        field: 'companyId',
        operator: FilterOperator.In,
        value: [],
        options: new BehaviorSubject([]),
      }),
    ],
    buttons: [
      new FiltersButtonAction({
        text: 'DESELECT_ALL',
        action: () => this.selectedBatchs = new Set(),
        disabled: () => this.selectedBatchs.size === 0
      }),
      new FiltersButtonAction({
        text: 'SYNC_WITH_SAGE',
        action: () => this.displaySageSyncDialog(),
        hidden: () => !environment.sage.active
      }),
      new FiltersButtonLink({
        text: 'CREATE_NEW_BATCH',
        icon: 'add',
        link: [BATCHS_ROUTES.add]
      })
    ],
  })

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1, size: 10, search: '',
      sort: { field: 'datetime', order: -1 },
      params: { category: BATCH_CATEGORY.MIXED, type: this.headerService.typeFilter }
    },
    columns: [
      new TableColumnCheckbox({
        header: null,
        name: 'select',
        displayAt: 0,
        style: GenericTableColumnStyle.Compact,
        color: GenericTableElementColor.Primary,
        checked: batch => this.selectedBatchs.has(batch._id),
        disabled: batch => batch.status !== BATCH_MIXED_STATUS.CLOSED,
        onChange: (batch, event) => this.selectMixedBatch(batch, event)
      }),
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: batch => batch.code
      }),
      new TableColumnText({
        header: 'STATUS',
        name: 'status',
        sorting: 'status',
        displayAt: 520,
        value: batch => this.translateService.instant(`batchs.status.${batch.status.toUpperCase()}`)
      }),
      new TableColumnText({
        header: 'COMPANY',
        name: 'company',
        sorting: 'company.businessName',
        displayAt: 1280,
        value: batch => batch.company.businessName
      }),
      new TableColumnText({
        header: 'WAREHOUSE',
        name: 'warehouse',
        sorting: 'warehouse.warehouse',
        displayAt: 1650,
        value: batch => batch.warehouse.warehouse
      }),
      new TableColumnText({
        header: 'WEIGHT',
        name: 'amount',
        sorting: 'amount',
        displayAt: 960,
        value: batch => batch.amount ? `${this.decimalPipe.transform(batch.amount, '1.0-2')} ${WeightUnit.Kilogram}` : null
      }),
      new TableColumnText({
        header: 'PALLET_NUMBER',
        name: 'palletNumber',
        sorting: 'palletNumber',
        displayAt: 1024,
        value: batch => batch.palletNumber
      }),
      new TableColumnText({
        header: 'DATETIME',
        name: 'datetime',
        sorting: 'datetime',
        displayAt: 640,
        value: batch => new Date(batch.datetime).getFullYear() === new Date().getFullYear()
          ? this.datePipe.transform(batch.datetime, 'd MMMM, H:mm', 'GMT')
          : this.datePipe.transform(batch.datetime, 'd MMMM, H:mm (yyyy)', 'GMT')
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'action',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: batch => [batch._id, BATCHS_ROUTES.detail]
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            link: batch => [batch._id, BATCHS_ROUTES.edit],
            disabled: batch => !BATCH_MIXED_STATUS_EDIT.includes(batch.status)
          }),
          new TableMenuOption({
            text: 'REPORT',
            icon: GenericMenuOptionIcon.Description,
            action: batch => this.downloadBatchReport(batch),
            disabled: batch => batch.status === BATCH_MIXED_STATUS.OPEN
          }),
          new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: batch => this.displayDeleteBatchDialog(batch),
            disabled: batch => !BATCH_MIXED_STATUS_EDIT.includes(batch.status)
          })
        ]
      })
    ]
  })

  displaySageSyncDialog = () => this.dialog.open(BatchsSageModalComponent, {
    width: '520px', data: { selectedBatchs: this.selectedBatchs }
  }).afterClosed().subscribe(updated => updated ? this.getBatchs(new Pagination(this.tableConfig.pagination)) : null)

  displayDeleteBatchDialog = (batch: IBatchMixedPaginated) => this.dialog.open(DeleteDialogComponent, {
    data: {
      title: 'batchs.dialog.delete.TITLE',
      msg: this.translateService.instant('batchs.dialog.delete.BODY', { batch })
    }, width: '420px'
  }).afterClosed()
    .pipe(filter(data => data && data.confirmDelete))
    .subscribe(async () => {
      try {
        this.snackbarService.loading(this.translateService.instant('batchs.snackbar.delete.LOADING'));
        await this.batchsService.deleteBatch(batch._id);
        this.snackbarService.success(this.translateService.instant('batchs.snackbar.delete.SUCCESS'));
        this.getBatchs(new Pagination(this.tableConfig.pagination));
      } catch (error) {
        this.snackbarService.error(this.translateService.instant('batchs.snackbar.delete.ERROR'));
      }
    })

  selectMixedBatch = (batch: IBatchMixedPaginated, event: MatCheckboxChange) => {
    if (batch.status !== BATCH_MIXED_STATUS.CLOSED) return;
    return event.checked
      ? this.selectedBatchs.add(batch._id)
      : this.selectedBatchs.delete(batch._id);
  }

  downloadBatchReport = async batch => {
    this.loading = true;
    this.snackbarService.loading(this.translateService.instant('reports.messages.LOADING_REPORT'));

    try {
      const blob = await this.batchsService.downloadBatchReport(batch._id);
      saveAs(blob, this.translateService.instant('traceabilityReports.files.MIXED_BATCH', { code: batch.code }));
      this.snackbarService.snackBar.dismiss();
    } catch (error) {
      this.snackbarService.error(this.translateService.instant('reports.messages.ERROR_LOADING'))
    } finally {
      this.loading = false;
    }
  }

}
