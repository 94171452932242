import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { IPackagingReceived } from '@routes/packagings/packaging.model';
import { PackagingService } from '@routes/packagings/packaging.service';

@Injectable({
    providedIn: 'root'
})
export class PackagingResolver implements Resolve<IPackagingReceived> {

    constructor(
        private packagingService: PackagingService
    ) {}

    resolve = (route: ActivatedRouteSnapshot): Promise<IPackagingReceived> =>
        this.packagingService.getPackaging(route.params.id);
    
}