<div class="campaign-name">
    <h2>{{ campaign.name }}</h2>
    <span class="chip" [ngClass]="campaign.type">
        <img class="chip-img" src="/assets/images/icons/chips/{{ campaign.type }}.svg" alt="#"/>
        <span class="chip-txt">{{ campaignService.getCampaignTypeTranslation(campaign.type) }}</span>
    </span>
</div>
<nav mat-tab-nav-bar class="campaign-menu mb-4">
    <a mat-tab-link *ngFor="let link of links" (click)="activeLink = link" [active]="activeLink == link" [routerLink]="link.route">
        {{ link.text }}
    </a>
</nav>
<router-outlet></router-outlet>