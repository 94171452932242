import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IWarehouseReceived } from '@routes/warehouses/warehouses.model';
import { WarehousesService } from '@routes/warehouses/warehouses.service';

import { Pagination } from '@shared/models/pagination.model';
import { ABS_BASE_ROUTE, WAREHOUSES_ROUTES, SECTIONS } from '@shared/constants/routes';
import { TableColumnText, TableConfiguration } from '@shared/components/generic-table/generic-table.model';
import { GenericTableBehaviour, GenericTableColumnAlign, GenericTableColumnStyle } from '@shared/components/generic-table/generic-table.constants';
import { FiltersButtonLink, FiltersConfiguration } from '@shared/components/generic-filters/generic-filters.model';

@Component({
  selector: 'app-warehouses-table',
  templateUrl: './warehouses-table.component.html',
  styleUrls: ['./warehouses-table.component.scss']
})
export class WarehousesTableComponent implements OnInit {

  tableConfig: TableConfiguration<IWarehouseReceived>;
  filtersConfig: FiltersConfiguration;

  constructor(
    private warehousesService: WarehousesService
  ) {}

  ngOnInit(): void {
    this.initFilters();
    this.initTable();
  }

  getWarehouses = async (pagination: Pagination) =>
    this.tableConfig.data.next(await this.warehousesService.getWarehouses(pagination));

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    buttons: [
      new FiltersButtonLink({
        text: 'UPLOAD_FILE',
        icon: 'folder',
        link: [
          ABS_BASE_ROUTE, SECTIONS.production,
          WAREHOUSES_ROUTES.parent, WAREHOUSES_ROUTES.upload]
      })
    ],
    controls: []
  });

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'warehouses.table',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1, size: 10, search: '',
      sort: { field: 'warehouse', order: 1 },
      params: { deleted: false }
    },
    columns: [
      new TableColumnText({
        header: 'WAREHOUSE',
        name: 'warehouse',
        sorting: 'warehouse',
        displayAt: 0,
        value: warehouse => warehouse.warehouse,
      }),
      new TableColumnText({
        header: 'SAGE_REFERENCE',
        name: 'sageReference',
        sorting: 'sageReference',
        displayAt: 520,
        style: GenericTableColumnStyle.Compact,
        align: GenericTableColumnAlign.Left,
        value: warehouse => warehouse.sageReference,
      }),
      new TableColumnText({
        header: 'COMPANY_SAGE_REFERENCE',
        name: 'companySageReference',
        sorting: 'companySageReference',
        displayAt: 960,
        style: GenericTableColumnStyle.Compact,
        align: GenericTableColumnAlign.Left,
        value: warehouse => warehouse.companySageReference,
      }),
      new TableColumnText({
        header: 'TRACE_CODE',
        name: 'traceCode',
        sorting: 'traceCode',
        displayAt: 960,
        style: GenericTableColumnStyle.Compact,
        align: GenericTableColumnAlign.Left,
        value: warehouse => warehouse.traceCode,
      })
    ]
  });

}
