import { Directive, Input } from '@angular/core';
import { AbstractControl, FormGroupDirective, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[appDateComparison]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DateComparisonDirective, multi: true }]
})
export class DateComparisonDirective implements Validator {

    @Input() maxDateControlName: string;
    @Input() minDateControlName: string;

    constructor(private form: FormGroupDirective) { }

    validate(control: AbstractControl): { [key: string]: any } {
        if (!control.value) return null;
        const result = {} as any;
        const maxDateControl = this.form.control.get(this.maxDateControlName);
        const minDateControl = this.form.control.get(this.minDateControlName);
        if (maxDateControl?.value && control.value > maxDateControl?.value) result.maxValue = true;
        if (minDateControl?.value && control.value < minDateControl?.value) result.minValue = true;
        return (result.minValue || result.maxValue) ? result : null;
    }

}
