<div class="upload p-4" (fileDrop)="checkFile($event)" appDropFile>
  <form class="upload-form">
    <input type="file" name="file" id="file" (change)="checkFile()" #input/>
  </form>
  <button class="upload-btn" mat-fab color="{{ color }}" (click)="triggerFileExplorer()">
    <mat-icon>{{ button }}</mat-icon>
  </button>
  <span class="upload-txt my-3">
    {{ text }}
  </span>
  <div class="upload-file">
    {{ file ? file.name : 'general.upload.NO_FILE' | translate  }}
  </div>
  <div class="upload-drop">
    <p>{{ 'general.upload.DROP_MESSAGE' | translate }}</p>
  </div>
</div>
<div class="upload-error alert alert-danger mt-4" *ngIf="error">
  {{ error }}
</div>