<h2 class="h2 title">{{ texts.TITLE }}</h2>
<p [innerHTML]="texts.TEXT"></p>
<p [innerHTML]="texts.WARNING" class="alert" [ngClass]="{'alert-success': activating, 'alert-warning': !activating}"></p>
<div class="buttons">
    <app-generic-button
        [config]="cancelButton" (click)="closeDialog()"
    ></app-generic-button>
    <app-generic-button
        [config]="confirmButton" (click)="confirmAction()"
    ></app-generic-button>
</div>