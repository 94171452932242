import { Component, OnInit } from '@angular/core';
import { WorkingDaysMassEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/working-days-mass-edit.service';

@Component({
  selector: 'app-campaigns-detail-working-days-mass-edit-filters',
  templateUrl: './campaigns-detail-working-days-mass-edit-filters.component.html',
  styleUrls: ['./campaigns-detail-working-days-mass-edit-filters.component.scss']
})
export class CampaignsDetailWorkingDaysMassEditFiltersComponent implements OnInit {

  selectedDevice: string;
  filters: any;
  
  dateFormat = 'dd/MM/yyyy';
  
  constructor(
    private massEditService: WorkingDaysMassEditService
  ) { }

  ngOnInit(): void {
    this.massEditService.selectedDeviceChanges().subscribe(selectedDevice => {
      this.selectedDevice = selectedDevice;
    })
    this.massEditService.filtersChanges().subscribe(filters => {
      this.filters = filters;
    })
  }

}
