import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { ReportsComponent } from "@routes/reports/reports.component";
import { NgModule } from "@angular/core";
import { ReportsMainComponent } from "@routes/reports/reports-main/reports-main.component";
import { ReactiveFormsModule } from "@angular/forms";

const routes: Routes = [
    {
      path: '',
      component: ReportsComponent,
      children: [
        {
          path: '',
          component: ReportsMainComponent,
          pathMatch: 'full'
        }
      ]
    }
  ];

@NgModule({
    declarations: [
      ReportsComponent,
      ReportsMainComponent
    ],
    imports: [
      CommonModule,
      SharedModule,
      RouterModule.forChild(routes),
      ReactiveFormsModule
    ],
    exports: [
      RouterModule
    ]
  })
export class ReportsModule {}