import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FORM_MODES } from '@shared/constants/forms';
import { ABS_BASE_ROUTE, KEYCHAIN_ROUTES, SECTIONS } from '@shared/constants/routes';
import { Keychain } from '@routes/keychains/keychain.model';
import { KeychainService } from '@routes/keychains/keychain.service';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-keychain-form',
  templateUrl: './keychain-form.component.html',
  styleUrls: ['./keychain-form.component.scss']
})
export class KeychainFormComponent implements OnInit {

  EXIT_ROUTE = [ABS_BASE_ROUTE, SECTIONS.workforce, KEYCHAIN_ROUTES.parent];
  FORM_MODES = FORM_MODES;

  form: FormGroup;
  mode: string;
  keychain: Keychain;
  title: string;

  constructor(
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private keychainService: KeychainService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      code: new FormControl(null, Validators.required),
      tag: new FormControl(null, Validators.required),
      
    });

    this.keychain = this.activatedRoute.snapshot.data.keychain;
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.title = this.translateService.instant(`keychains.titles.${this.mode.toUpperCase()}`);

    if (this.mode === FORM_MODES.detail) this.form.disable();
    if (this.mode !== FORM_MODES.add) this.form.patchValue(this.keychain);
  }

  submitForm = () => {
    const value = { ...this.form.value };
    const fn = this.mode === FORM_MODES.add
      ? this.keychainService.addKeychain(value)
      : this.keychainService.editKeychain(this.keychain.id, value)

    fn
      .then(() => this.router.navigate(this.EXIT_ROUTE))
      .catch((error: HttpErrorResponse) => this.snackbarService.error(error.error.message));
  }

}
