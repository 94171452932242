<app-upload-file-page-form
    *ngIf="section === 'form'" [config]="config" [loading]="loading"
    (uploadFile)="uploadFile($event, 'read')" (exitUpload)="exit()">
    <ng-content></ng-content>
</app-upload-file-page-form>

<app-upload-file-page-table
    *ngIf="section === 'table'"
    [records]="data" [config]="config" [file]="file" [loading]="loading"
    (uploadFileAndExit)="uploadFile($event, 'write')" (backToUpload)="backToUpload()">
</app-upload-file-page-table>