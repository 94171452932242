import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private printSubject = new Subject<string[]>();
  printObservable = this.printSubject.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  startPrint = (classes?: string[]) => {
    this.printSubject.next(classes);
    requestAnimationFrame(() => this.document.defaultView.print());
  }

  endPrint = () => this.printSubject.next([]);

}
