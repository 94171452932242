<form [formGroup]="form" class="form" (submit)="submitForm()">
    <h2 class="title h2">{{ title }}</h2>
    <!-- Name -->
    <mat-form-field class="input">
        <mat-label for="name">{{ 'crops.form.NAME' | translate }}</mat-label>
        <input matInput type="text" name="name" id="name" formControlName="name">
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Variety -->
    <mat-form-field class="input">
        <mat-label for="variety">{{ 'crops.form.VARIETY' | translate }}</mat-label>
        <input matInput type="text" name="variety" id="variety" formControlName="variety">
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Type -->
    <mat-form-field class="input" appearance="fill">
        <mat-label>{{ 'crops.form.TYPE' | translate }}</mat-label>
        <mat-select name="type" id="type" formControlName="type">
            <mat-option *ngFor="let type of CROP_TYPES; let i = index" value="{{ type.value }}">
                {{ 'crops.types.' + type.value.toUpperCase() | translate }}
            </mat-option>
        </mat-select>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Buttons -->
    <ul class="controls">
        <li>
            <button mat-flat-button [color]="mode !== FORM_MODES.detail ? 'warn' : 'primary'" class="btn" type="button" [routerLink]="EXIT_ROUTE">
                {{ (mode === FORM_MODES.detail ? 'general.actions.BACK' : 'general.actions.CANCEL') | translate }}
            </button>
        </li>
        <li>
            <button mat-flat-button color="primary" class="btn" [disabled]="form.invalid || loading" *ngIf="mode !== FORM_MODES.detail">
                {{ (mode === FORM_MODES.add ? 'general.actions.ADD' : 'general.actions.EDIT') | translate }}
            </button>
        </li>
    </ul>
</form>