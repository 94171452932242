import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BATCH_UNIQUE_STATUS, BATCH_PROCESS, BATCH_MIXED_STATUS, BATCH_PROCESS_PER_STATUS, BATCH_ALL_STATUS, BATCH_CATEGORY, BATCH_DETAIL_STATUS, BATCH_MIXED_STATUS_EDIT } from '@routes/batchs/batchs.constants';
import { IBatchType, IBatchTypeRecord } from '@routes/batchs/batchs.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BATCHS_ROUTES } from '@shared/constants/routes';
import { BatchsService } from '@routes/batchs/batchs.service';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DeleteDialogComponent } from '@shared/widgets/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-batchs-detail-summary-status',
  templateUrl: './batchs-detail-summary-status.component.html',
  styleUrls: ['./batchs-detail-summary-status.component.scss']
})
export class BatchsDetailSummaryStatusComponent implements OnInit {

  @Input('batch') batch: IBatchType;

  BATCH_UNIQUE_STATUS = BATCH_UNIQUE_STATUS;
  BATCH_MIXED_STATUS = BATCH_MIXED_STATUS;
  BATCH_ALL_STATUS = BATCH_ALL_STATUS;
  BATCH_MIXED_STATUS_EDIT = BATCH_MIXED_STATUS_EDIT;
  BATCH_CATEGORY = BATCH_CATEGORY;

  images = {
    [BATCH_PROCESS.TRANSFORMATION]: '/assets/images/icons/pallet.svg',
    [BATCH_PROCESS.SALES]: '/assets/images/icons/dispatch-note.svg',
    [BATCH_PROCESS.PRODUCTION]: '/assets/images/icons/pallet.svg'
  };
  processes: { [key: string]: IBatchTypeRecord[] } = {
    [BATCH_PROCESS.TRANSFORMATION]: [],
    [BATCH_PROCESS.SALES]: [],
    [BATCH_PROCESS.PRODUCTION]: []
  };
  category: BATCH_CATEGORY;
  editLink: string[];
  loading = false;
  navigation: string;

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private batchsService: BatchsService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.setupProcesses();
    this.category = this.batch.category;
    this.editLink = ['..', BATCHS_ROUTES.edit];
    this.activatedRoute.queryParams.subscribe(params => this.navigation = params['navigation']);
  }

  setupProcesses = () => {
    this.batch.record.forEach(record => {
      if (!BATCH_DETAIL_STATUS.includes(record.status)) return;

      const process = BATCH_PROCESS_PER_STATUS[record.status];
      this.processes[process].push(record);
    });

    for (const process in this.processes) {
      if (!this.processes[process].length) delete this.processes[process];
    }
  }

  navigateToPreviousLink = () => this.navigation === 'previous'
    ? this.location.back() 
    : this.router.navigate(['../..'], { relativeTo: this.activatedRoute })

  downloadBatchReport = async () => {
    this.loading = true;
    this.snackbarService.loading(this.translateService.instant('reports.messages.LOADING_REPORT'));

    try {
      const blob = await this.batchsService.downloadBatchReport(this.batch._id);
      saveAs(blob, this.translateService.instant('traceabilityReports.files.MIXED_BATCH', { code: this.batch.code }));
      this.snackbarService.snackBar.dismiss();
    } catch (error) {
      this.snackbarService.error(this.translateService.instant('reports.messages.ERROR_LOADING'))
    } finally {
      this.loading = false;
    }
  }

  displayDeleteBatchDialog = () => this.dialog.open(DeleteDialogComponent, {
    data: {
      title: 'batchs.dialog.delete.TITLE',
      msg: this.translateService.instant('batchs.dialog.delete.BODY', this)
    }, width: '420px'
  }).afterClosed()
    .pipe(filter(data => data && data.confirmDelete))
    .subscribe(async () => {
      try {
        this.snackbarService.loading(this.translateService.instant('batchs.snackbar.delete.LOADING'));
        await this.batchsService.deleteBatch(this.batch._id);
        this.snackbarService.success(this.translateService.instant('batchs.snackbar.delete.SUCCESS'));
        this.navigateToPreviousLink();
      } catch (error) {
        this.snackbarService.error(this.translateService.instant('batchs.snackbar.delete.ERROR'));
      }
    })

  checkCanDeleteBatch = (batch) => batch.category === BATCH_CATEGORY.MIXED && BATCH_MIXED_STATUS_EDIT.includes(batch.status);
}
