import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WorkingSite } from 'src/app/routes/working-sites/working-site.model';
import { CampaignPlannerService } from '../campaign.planner.service';
import { ICampaignSite } from '../../campaign.model';
import { WorkingSiteService } from 'src/app/routes/working-sites/working-site.service';

@Component({
  selector: 'app-campaigns-planner-summary',
  templateUrl: './campaigns-planner-summary.component.html',
  styleUrls: ['./campaigns-planner-summary.component.scss']
})
export class CampaignsPlannerSummaryComponent implements OnInit {

  @Output() changeSection = new EventEmitter<string>();

  sites: ICampaignSite[];

  constructor(
    private plannerService: CampaignPlannerService,
    private sitesService: WorkingSiteService
  ) {}

  ngOnInit(): void {
    this.sites = this.plannerService.campaign.sites;
  }

  getChipText = (zones: number) => zones > 0 ? 'campaigns.summary.WITH_ZONES' : 'campaigns.summary.NO_ZONES';

  getZonesLength = (zones: number) => ({ value: zones });

  getSiteName = (id: string): Promise<WorkingSite> => this.sitesService.getSite(id);

  sitesHasZones = (): boolean => this.plannerService.campaign.sites.every(site => site.zones.length > 0);

  selectSite = (id: string) => {
    this.plannerService.selectSite(id);
    this.changeSection.emit('zones');
  }

}
