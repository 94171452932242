export enum REPORTS_I18N_LIST {
    HARVEST = 'reports.links.HARVEST',
    WEEKLY_HARVEST = 'reports.links.WEEKLY_HARVEST',
    WORKER_HOURS = 'reports.links.WORKER_HOURS',
    MAINTENANCE_TASKS = 'reports.links.MAINTENANCE_TASKS',
    HARVEST_INCENTIVE = 'reports.links.HARVEST_INCENTIVE',
    HARVEST_MAINTENANCE_SUMMARY = 'reports.links.HARVEST_MAINTENANCE_SUMMARY',
    DAILY_PERFORMANCE = 'reports.links.DAILY_PERFORMANCE',
    WEEKLY_PERFORMANCE = 'reports.links.WEEKLY_PERFORMANCE',
    HARVEST_PACKAGING_COST = 'reports.links.HARVEST_PACKAGING_COST',
    PLANTS_HARVEST = 'reports.links.PLANTS_HARVEST',
    WORKABLE_TASKS = 'reports.links.WORKABLE_TASKS',
    WORK_LOG = 'reports.links.WORK_LOG'
}

export enum REPORTS_I18N_FILES_LIST {
    HARVEST = 'reports.files.HARVEST',
    WEEKLY_HARVEST = 'reports.files.WEEKLY_HARVEST',
    WORKER_HOURS = 'reports.files.WORKER_HOURS',
    MAINTENANCE_TASKS = 'reports.files.MAINTENANCE_TASKS',
    HARVEST_INCENTIVE = 'reports.files.HARVEST_INCENTIVE',
    HARVEST_MAINTENANCE_SUMMARY = 'reports.files.HARVEST_MAINTENANCE_SUMMARY',
    DAILY_PERFORMANCE = 'reports.files.DAILY_PERFORMANCE',
    WEEKLY_PERFORMANCE = 'reports.files.WEEKLY_PERFORMANCE',
    HARVEST_PACKAGING_COST = 'reports.files.HARVEST_PACKAGING_COST',
    PLANTS_HARVEST = 'reports.files.PLANTS_HARVEST',
    WORKABLE_TASKS = 'reports.files.WORKABLE_TASKS',
    WORK_LOG = 'reports.files.WORK_LOG'
}

export enum REPORTS_FILTER_INPUTS {
    START_DATE = 'startDate',
    START_TIME = 'startTime',
    END_DATE = 'endDate',
    END_TIME = 'endTime',
    DEVICE_IDS = 'deviceIds',
    SITE_IDS = 'siteIds',
    TASK_TYPE_IDS = 'taskTypeIds',
    PACKAGING_IDS = 'packagingIds',
    CROP_IDS = 'cropIds',
    CONFIRMED_WORKING_DAYS = 'onlyConfirmedWorkingDays',
    WORKABLE_TASKS = 'workableTasksIds',
    ZONE_IDS = 'zoneIds'
}

export enum REPORTS_FILTER_TYPES {
    DATE = 'date',
    SELECT = 'select'
}

export enum REPORTS_FILTER_I18N {
    START_DATE = 'reports.filters.START_DATE',
    START_TIME = 'reports.filters.START_TIME',
    END_DATE = 'reports.filters.END_DATE',
    END_TIME = 'reports.filters.END_TIME',
    DEVICES = 'reports.filters.DEVICES',
    SITES = 'reports.filters.SITES',
    TASK_TYPES = 'reports.filters.TASK_TYPES'
}

export enum REPORTS_FILTER_LAYOUTS {
    COLUMN = 'column',
    ROW = 'row'
}

export enum REPORTS_FORMAT_EXTENSIONS {
    XLSX = 'xlsx',
    PDF = 'pdf'
}
