import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { USERS_DIALOGS, USERS_DIALOGS_COMPONENT, USERS_ROLES } from '@shared/constants/users';
import { USERS_ROUTES } from '@shared/constants/routes';
import { Pagination } from '@shared/models/pagination.model';
import { User } from '../user.model';
import { UserService } from '../user.service';
import { PageEvent } from '@angular/material/paginator';
import { PaginationService } from '@shared/services/pagination.service';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-users-main',
  templateUrl: './users-main.component.html',
  styleUrls: ['./users-main.component.scss']
})
export class UsersMainComponent implements OnInit {

  USERS_DIALOGS = USERS_DIALOGS;
  USERS_ROUTES = USERS_ROUTES;

  users: User[] = [];
  total: number;
  loading: boolean = false;

  columns = [
    'username', 'name', 'email',
    'disabled', 'role', 'actions'
  ];
  pagination = new Pagination({
    page: 1,
    size: 10,
    sort: {
      order: 1,
      field: 'username'
    },
    params: {
      roles: [Object.values(USERS_ROLES)]
    }
  });

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private paginationService: PaginationService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
  ) {}

  ngOnInit(): void {
    this.retrieveUsers();
  }

  displayDialog = (dialog: string, user: User) => this.dialog.open(USERS_DIALOGS_COMPONENT[dialog], {
    width: '480px',
    data: { user }
  }).afterClosed().subscribe(response => response && response.updated && this.retrieveUsers());

  retrieveUsers = async () => {
    this.loading = true;
    this.snackbarService.loading(this.translateService.instant('users.message.LOADING_USERS'));

    this.userService.getUsers(this.pagination)
    .then(response => {
      this.users = response.users;
      this.total = response.total;
      this.snackbarService.snackBar.dismiss();
    })
    .catch(error => this.snackbarService.error(error.message || error.error.message))
    .finally(()=> this.loading = false);
  }

  sortUsers = (sort: Sort) => {
    this.pagination = this.paginationService.sort(this.pagination, sort);
    this.retrieveUsers();
  }

  searchUsers = (search: string) => {
    this.pagination.search = search;
    this.pagination.page = 1;
    this.retrieveUsers();
  }

  paginateUsers = (event: PageEvent) => {
    this.pagination = this.paginationService.paginate(this.pagination, event);
    this.retrieveUsers();
  }

  getUserDisabledLabel = (user: User) => this.translateService.instant(user.disabled
    ? 'users.main.DISABLED'
    : 'users.main.ENABLED');

}
