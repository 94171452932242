import { NgModule } from "@angular/core";
import { MenuService } from '@core/menu/menu.service';
import { StorageService } from '@core/storage/storage.service';
import { TranslatorService } from '@core/translator/translator.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@shared/network/http.interceptor';

@NgModule({
    declarations: [],
    providers: [
        StorageService,
        MenuService,
        TranslatorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    imports: [],
    exports: []
})

export class CoreModule { }