import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Agency } from '@models/agency.model';

import { AgencyService } from '@routes/agencies/agency.service';
import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { Keychain } from '@routes/keychains/keychain.model';
import { KeychainService } from '@routes/keychains/keychain.service';
import { WorkerReceivedGroup, WorkerSent } from '@routes/workers/worker.model';
import { WorkerService } from '@routes/workers/worker.service';
import { FormSection } from '@routes/workers/workers-add/workers-add.constants';
import { WorkingSite } from '@routes/working-sites/working-site.model';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';

import { PositionsMainService } from '@routes/positions/positions-main.service';
import { Position } from '@routes/positions/positions.models';
import { WorkersValidationService } from '@routes/workers/workers-validation.service';
import {
  ABS_BASE_ROUTE,
  SECTIONS,
  WORKER_ROUTES,
} from '@shared/constants/routes';
import { ICountry } from '@shared/interfaces/country.models';
import {
  WORKER_GENDERS,
  WORKER_MARITAL_STATUSES,
  WORKER_STATUSES,
} from '@shared/interfaces/types';
import { Pagination } from '@shared/models/pagination.model';
import { CountryService } from '@shared/services/country.service';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-workers-add',
  templateUrl: './workers-add.component.html',
  styleUrls: ['./workers-add.component.scss'],
})
export class WorkersAddComponent implements OnInit, AfterViewInit, OnDestroy {
  FormSection = FormSection;
  Validators = Validators;

  pagination = new Pagination({
    size: 10,
    page: 1,
    sort: { field: 'name', order: 1 },
  });
  campaignsPagination = new Pagination({
    sort: { field: 'name', order: 1 },
    params: { status: 'active' },
  });
  sitesPagination = new Pagination({
    sort: { field: 'name', order: 1 },
  });

  loading: boolean = false;

  WORKERS_LIST_ROUTE = [
    ABS_BASE_ROUTE,
    SECTIONS.workforce,
    WORKER_ROUTES.parent,
  ];

  section = FormSection.Personal;
  workerStatuses = [...WORKER_STATUSES];
  workerGenders = [...WORKER_GENDERS];
  workerMaritalStatuses = [...WORKER_MARITAL_STATUSES];

  form: FormGroup;
  keychainAssignedMessage: string;
  subscriptions: Subscription[] = [];
  agencies: Agency[];
  keychains: Keychain[];
  campaigns: Campaign[];
  sites: WorkingSite[];
  positions: Position[];
  workers: WorkerReceivedGroup[];
  countries: ICountry[];

  @ViewChild('agency') agency: ElementRef;
  @ViewChild('keychain') keychain: ElementRef;
  @ViewChild('recomended') recomended: ElementRef;

  constructor(
    private campaignService: CampaignService,
    private sitesService: WorkingSiteService,
    private workerService: WorkerService,
    private agencyService: AgencyService,
    private positionsMainService: PositionsMainService,
    private snackbarService: CustomSnackbarService,
    private keychainService: KeychainService,
    private translateService: TranslateService,
    private countryService: CountryService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private workersValidation: WorkersValidationService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getReferenceData();
  }

  ngAfterViewInit(): void {
    fromEvent(this.agency.nativeElement, 'input')
      .pipe(debounceTime(250))
      .subscribe(() => {
        this.pagination.search = (
          this.agency.nativeElement as HTMLInputElement
        ).value;
        this.agencyService
          .getAgencies(this.pagination)
          .then((response) => (this.agencies = response.data));
      });
    fromEvent(this.recomended.nativeElement, 'input')
      .pipe(debounceTime(250))
      .subscribe(() => {
        this.pagination.search = (
          this.recomended.nativeElement as HTMLInputElement
        ).value;
        this.workerService
          .getWorkers(this.pagination)
          .then((response) => (this.workers = response.workers));
      });
    fromEvent(this.keychain.nativeElement, 'input')
      .pipe(debounceTime(250))
      .subscribe(async () => {
        this.pagination.search = (
          this.keychain.nativeElement as HTMLInputElement
        ).value;
        this.keychains = (
          await this.keychainService.getKeychains(this.pagination)
        ).keychains;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  initForm = () => {
    this.form = new FormGroup({
      personal: new FormGroup({
        name: new FormControl(null, Validators.required),
        surname: new FormControl(null, Validators.required),
        nationality: new FormControl(null),
        nif: new FormControl(null),
        address: new FormControl(null),
        taxResidence: new FormControl(null),
        iban: new FormControl(null),
      }),
      professional: new FormGroup({
        status: new FormControl(null, Validators.required),
        country: new FormControl(null, Validators.required),
        socialSecurityNumber: new FormControl(null),
        phoneNumber: new FormControl(null),
        birthDate: new FormControl(null),
        gender: new FormControl(null),
        maritalStatus: new FormControl(null),
        positionId: new FormControl(null),
        agency: new FormControl(null),
        campaignId: new FormControl(null),
        siteId: new FormControl(null),
        recommendedBy: new FormControl(null),
        alphanumericCode: new FormControl(null),
        promoter: new FormControl(false, Validators.required),
        keychain: new FormControl({ disabled: true, value: null }),
      }),
    });
    this.subscriptions.push(
      this.form.get('professional.status').valueChanges.subscribe((value) => {
        const keychain = this.form.get('professional.keychain');
        value === 'active' ? keychain.enable() : keychain.disable();
        keychain.setValue(null);
      })
    );
    this.subscriptions.push(
      this.form
        .get('professional.country')
        .valueChanges.subscribe((value) =>
          this.workersValidation.validateCountryControls(
            value,
            this.form,
            'professional'
          )
        )
    );
  };

  getReferenceData = async () => {
    this.positions = (await this.positionsMainService.getAll()).positions;
    this.campaigns = (
      await this.campaignService.getCampaigns(this.campaignsPagination)
    ).data;
    this.sites = (await this.sitesService.getSites(this.sitesPagination)).sites;
    this.countries = this.countryService.getCountries();
  };

  displayAgency = (agency: Agency) => (agency ? agency.name : '');

  displayKeychain = (keychain: Keychain) =>
    keychain ? `${keychain.code} (${keychain.tag})` : '';

  displayWorker = (worker: WorkerReceivedGroup) =>
    worker ? `${worker.name} ${worker.surname} (${worker.sequentialId})` : '';

  keychainAvailable = async (event: MatAutocompleteSelectedEvent) => {
    const id = (event.option.value as Keychain)._id;
    const worker = (await this.keychainService.getKeychain(id)).worker;

    this.keychainAssignedMessage = worker
      ? this.translateService.instant(
          'workers.detail.form.KEYCHAIN_ASSIGNED_MESSAGE',
          { worker }
        )
      : '';
  };

  createWorker = async () => {
    const values = {
      ...this.form.value.personal,
      ...this.form.value.professional,
    };

    if (this.form.value.professional.agency) {
      values.agencyId = this.form.value.professional.agency._id;
      delete values.agency;
    }
    if (this.form.value.professional.keychain) {
      values.keychainId = this.form.value.professional.keychain._id;
      delete values.keychain;
    }
    if (this.form.value.professional.recommendedBy) {
      values.recommendedBy = this.form.value.professional.recommendedBy._id;
    }
    if (this.form.value.professional.birthDate)
      values.birthDate = DateTime.fromJSDate(values.birthDate)
        .toFormat('dd-MM-yyyy')
        .toString();
    Object.entries(values).forEach(
      ([key, val]) => (values[key] = val !== '' ? val : null)
    );

    const worker = new WorkerSent(values);

    try {
      if (this.form.invalid) return;
      this.loading = true;
      await this.workerService.addWorker(worker);
      this.router.navigate(['..'], { relativeTo: this.activatedRoute });
    } catch (error) {
      this.snackbarService.error(error.error.message || error.message);
    } finally {
      this.loading = false;
    }
  };
}
