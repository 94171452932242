<form class="form" [formGroup]="form" (submit)="submitForm()">
    <h2 class="h2">{{ texts[mode.toUpperCase()] | translate }}</h2>
    <!-- Name -->
    <mat-form-field class="input" appearance="standard">
        <mat-label>{{ texts.NAME | translate }}</mat-label>
        <input matInput type="text" formControlName="name" appUniqueInValidator [elements]="literals">
        <mat-error *ngIf="form.get('name').errors as errors">
            <ng-container *ngIf="errors.alreadyExists">
                {{ texts.ALREADY_EXISTS | translate }}
            </ng-container>
        </mat-error>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Buttons -->
    <div class="buttons">
        <app-generic-button
            [config]="exitButtonConfig" [routerLink]="exitRoute"
        ></app-generic-button>
        <app-generic-button
            [config]="submitButtonConfig" [disabled]="form.invalid"
        ></app-generic-button>
    </div>
</form>