<form [formGroup]="form" class="form" (submit)="submitForm()">
    <h2 class="title h2">{{ title }}</h2>
    <mat-form-field class="input" >
      <mat-label for="code">{{ 'keychains.detail.form.CODE' | translate }}</mat-label>
      <input matInput type="text" name="code" id="code" formControlName="code">
      <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field  class="input">
      <mat-label for="tag">{{ 'keychains.detail.form.TAG' | translate }}</mat-label>
      <input matInput type="text" name="tag" id="tag" formControlName="tag">
      <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <div class="controls">
      <button mat-flat-button color="primary" class="btn" type="button"
        [routerLink]="EXIT_ROUTE"> {{ (mode === FORM_MODES.detail ? 'general.actions.BACK' : 'general.actions.CANCEL') | translate }}</button>
      <button mat-flat-button color="primary" class="btn" type="submit" *ngIf="mode !== FORM_MODES.detail"
        [disabled]="form.invalid"> {{ (mode === FORM_MODES.add ? 'general.actions.ADD' : 'general.actions.EDIT') | translate }}</button>
    </div>
</form>
