import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { WorkingSite } from 'src/app/routes/working-sites/working-site.model';
import { WorkingSiteService } from 'src/app/routes/working-sites/working-site.service';
import { PageEvent } from '@angular/material/paginator';
import { CampaignPlannerService } from '../campaign.planner.service';
import { CampaignSite } from '../../campaign.model';
import { Pagination } from 'src/app/shared/models/pagination.model';
import { PaginationService } from 'src/app/shared/services/pagination.service';

@Component({
  selector: 'app-campaigns-planner-sites',
  templateUrl: './campaigns-planner-sites.component.html',
  styleUrls: ['./campaigns-planner-sites.component.scss']
})
export class CampaignsPlannerSitesComponent implements OnInit {

  @Output() changeSection = new EventEmitter<string>();

  sites: WorkingSite[];
  columns: string[] = ['select', 'name', 'alias', 'city'];

  pagination: Pagination = new Pagination({
    size: 5,
    page: 1,
    sort: {
      field: 'name',
      order: 1
    },
    params: {
      type: this.plannerService.campaign.type
    }
  });
  paginationTotal: number;

  constructor(
    private siteService: WorkingSiteService,
    private plannerService: CampaignPlannerService,
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    if (this.campaignHasSites() === false) {
      this.pagination.size = 99999;

      this.retrieveSites().then(data => (data['sites'] as WorkingSite[])
        .map(site => ({ siteId: site.id, siteName: site.name, zones: [] }))
        .forEach(site => this.plannerService.addSite(site)));
    }

    this.pagination.size = 5;
    this.retrieveSites().then(data => this.loadSites(data));
  }

  retrieveSites = (): Promise<object> => this.siteService.getSites(this.pagination);

  loadSites(data: object) {
    this.sites = data['sites'];
    this.paginationTotal = data['total'];
  }

  paginateSites = (event: PageEvent) => {
    this.pagination = this.paginationService.paginate(this.pagination, event);
    this.retrieveSites().then(data => this.loadSites(data));
  }

  siteIsSelected = (id: string): boolean => this.plannerService.siteIsIncluded(id);

  selectSite = (event: Event, id: string, siteName: string) => {
    const site = new CampaignSite({ siteId: id, siteName, zones: [] });

    (event.target as HTMLInputElement).checked
      ? this.plannerService.addSite(site)
      : this.plannerService.removeSite(site);
  }

  campaignHasSites = (): boolean =>
    this.plannerService.campaign.sites &&
    this.plannerService.campaign.sites.length > 0;
}