<mat-tab-group mat-align-tabs="start" animationDuration="0ms">
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="d-flex flex-row flex-nowrap align-items-center">
                <p class="mat-tab-txt mb-0">{{ config.successTab.content | translate }}</p>
                <span class="mat-tab-nmb ml-2" [ngClass]="{'with-content': records.data.length}">
                    {{ records.data.length }}
                </span>
            </div>
        </ng-template>
        <ng-container *ngIf="records.data.length; else noData">
            <table class="w-100" mat-table [dataSource]="dataSource" matSort>
                <!-- Columns -->
                <ng-container *ngFor="let column of config.tableColumns">
                    <ng-container matColumnDef="{{ column.name }}">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ column.formatFunction ? column.formatFunction(row[column.value]) : row[column.value] }}
                        </td>
                      </ng-container>
                </ng-container>
                <!-- Row definition -->
                <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"></mat-paginator>
        </ng-container>
        <ng-template #noData>
            <div class="mt-4 mb-0 text-center alert alert-secondary">
                {{ config.successTab.empty | translate }}
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="d-flex flex-row flex-nowrap align-items-center">
                <p class="mat-tab-txt mb-0">{{ 'general.upload.WARNINGS' | translate }}</p>
                <span class="mat-tab-nmb ml-2 warning" [ngClass]="{'with-content': records.warnings.length}">
                    {{ records.warnings.length }}
                </span>
            </div>
        </ng-template>
        <ul class="mt-4 mb-0" *ngIf="records.warnings.length; else noWarnings">
            <li class="alert alert-warning" *ngFor="let warning of records.warnings">{{ warning.msg }}</li>
        </ul>
        <ng-template #noWarnings>
            <div class="alert alert-success mt-4 mb-0 text-center alert alert-success">
                {{ 'general.upload.NO_WARNINGS' | translate }}
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="d-flex flex-row flex-nowrap align-items-center">
                <p class="mat-tab-txt mb-0">{{ 'general.upload.ERRORS' | translate }}</p>
                <span class="mat-tab-nmb ml-2 error" [ngClass]="{'with-content': records.error.length}">
                    {{ records.error.length }}
                </span>
            </div>
        </ng-template>
        <ul class="mt-4 mb-0" *ngIf="records.error.length; else noErrors">
            <li class="alert alert-danger" *ngFor="let err of records.error">Error: {{ err.msg }}</li>
        </ul>
        <ng-template #noErrors>
            <div class="mt-4 mb-0 text-center alert alert-success">
                {{ 'general.upload.NO_ERRORS' | translate }}
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
<div class="d-flex flex-row flex-nowrap justify-content-between mt-4">
    <button mat-flat-button color="primary" class="btn" type="button" (click)="backToUpload.emit()" [disabled]="loading">
        {{ 'general.actions.BACK' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" (click)="confirmUpload()" [disabled]="!enableUpload || loading">
        {{ 'general.actions.CONFIRM_UPLOAD' | translate }}
    </button>
</div>