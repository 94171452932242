import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-packagings',
  templateUrl: './packagings.component.html',
  styleUrls: ['./packagings.component.scss']
})
export class PackagingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
