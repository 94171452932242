export enum FilterType {
    Time = 'time',
    Date = 'date',
    Select = 'select',
    Checkbox = 'checkbox',
    GroupSelect = 'groupSelect'
}

export enum FilterOperator {
    Equal = 'EQUAL',
    NotEqual = 'NOT_EQUAL',
    In = 'IN',
    NotIn = 'NOT_IN',
    Greater = 'GREATER_THAN',
    Less = 'LESS_THAN',
    GreaterEqual = 'GREATER_EQUAL_THAN',
    LessEqual = 'LESS_EQUAL_THAN',
    Regex = 'REGEX'
};

export enum ButtonType {
    Action = 'action',
    Link = 'link'
}

export enum LayoutDirection {
    Horizontal = 'horizontal',
    Vertical = 'vertical'
}
