import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroupDirective, NG_VALIDATORS, Validator } from '@angular/forms';
import { updateValidationAllControls } from '@shared/utils/forms.utils';

@Directive({
  selector: '[appUpdateDateControl]'
})
export class UpdateDateControlDirective {

  @Input() dateControlReference: string;

  constructor(
    private el: ElementRef,
    private form: FormGroupDirective
  ) { }

  @HostListener('input') onInput() {
    const [hours, minutes] = this.el.nativeElement.value.split(':').map(element => parseInt(element, 10));

    const dateControl = this.form.control.get(this.dateControlReference);
    const date = dateControl.value as Date;
    dateControl.patchValue(new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours, minutes));
    updateValidationAllControls(this.form.control);
  }

}
