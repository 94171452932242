import { UsersDeleteDialogComponent } from "@routes/users/users-delete-dialog/users-delete-dialog.component";
import { UsersDisableDialogComponent } from "@routes/users/users-disable-dialog/users-disable-dialog.component";
import { UsersPasswordDialogComponent } from "@routes/users/users-password-dialog/users-password-dialog.component";

export enum USERS_DIALOGS {
    password = 'password',
    disable = 'disable',
    delete = 'delete'
};

export enum USERS_ROLES {
    superadmin = 'SUPERADMIN',
    admin = 'ADMIN',
    user = 'USER'
};

export enum USERS_FORM_MODES {
    add = 'add',
    edit = 'edit',
    detail = 'detail'
};

export enum USERS_FORM_TITLES {
    add = 'users.form.ADD',
    edit = 'users.form.EDIT',
    detail = 'users.form.DETAIL'
};

export const USERS_DIALOGS_COMPONENT = {
    password: UsersPasswordDialogComponent,
    disable: UsersDisableDialogComponent,
    delete: UsersDeleteDialogComponent
};