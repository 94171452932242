import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserResolver implements Resolve<object> {

    constructor(
        private userService: UserService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<object> {
        return this.userService.getUserMe();
    }
}