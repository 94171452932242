<div class="basic">
    <mat-form-field class="input" appareance="standard">
        <mat-label for="name">{{ 'campaigns.detail.NAME' | translate }}</mat-label>
        <input matInput disabled type="text" name="name" id="name" [value]="campaign.name"/>
    </mat-form-field>
    <mat-form-field class="input" appareance="standard">
        <mat-label for="startDate">{{ 'campaigns.detail.START' | translate }}</mat-label>
        <input matInput disabled type="text" name="startDate" id="startDate" [value]="campaign.startDate | date: 'longDate'"/>
    </mat-form-field>
    <mat-form-field class="input" appareance="standard">
        <mat-label for="endDate">{{ 'campaigns.detail.END' | translate }}</mat-label>
        <input matInput disabled type="text" name="endDate" id="endDate" [value]="campaign.endDate | date: 'longDate'"/>
    </mat-form-field>
    <mat-form-field class="input" appareance="standard">
        <mat-label for="type">{{ 'campaigns.detail.TYPE' | translate }}</mat-label>
        <input matInput disabled type="text" name="type" id="type" [value]="campaignService.getCampaignTypeTranslation(campaign.type)"/>
    </mat-form-field>
    <mat-checkbox class="agree-workdays-checkbox" color="primary" [(ngModel)]="campaign.agreeWorkdays" disabled>
        {{ 'campaigns.basic.AGREE_WORKDAYS_' + (campaign.agreeWorkdays ? 'ENABLED' : 'DISABLED') | translate }}
    </mat-checkbox>
    <app-generic-button
        *ngIf="campaign.status !== CampaignStatus.Finished" class="button"
        (click)="displayFinishDialog()" [config]="finishButtonConfig"
    ></app-generic-button>
</div>
<app-campaigns-detail-performance class="performance"></app-campaigns-detail-performance>