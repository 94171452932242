import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IPackagingReceived, IPackagingSent } from '@routes/packagings/packaging.model';

import { ApiService } from '@shared/services/api.service';
import { Pagination } from '@shared/models/pagination.model';
import { IPaginatedData } from '@shared/models/query.models';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PackagingService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  private getPackagingsUrl = (): string => `${this.apiService.getBaseUrl()}/packagings`;
  private getPackagingUrl = (_id: string): string => `${this.getPackagingsUrl()}/${_id}`;

  getPackagings = (pagination?: Pagination): Promise<IPaginatedData<IPackagingReceived>> =>
    lastValueFrom(this.http.get<IPaginatedData<IPackagingReceived>>(this.getPackagingsUrl(), {params: pagination?.loadHttpParams()}));

  getPackaging = (_id: string): Promise<IPackagingReceived> =>
    lastValueFrom(this.http.get<IPackagingReceived>(`${this.getPackagingsUrl()}/${_id}`));

  addPackaging = (packaging: IPackagingSent): Promise<IPackagingReceived> =>
    lastValueFrom(this.http.post<IPackagingReceived>(this.getPackagingsUrl(), packaging));

  editPackaging = (_id: string, packaging: IPackagingSent): Promise<IPackagingReceived> =>
    lastValueFrom(this.http.put<IPackagingReceived>(this.getPackagingUrl(_id), packaging));

  deletePackaging = (_id: string): Promise<void> =>
    lastValueFrom(this.http.delete<void>(`${this.getPackagingsUrl()}/${_id}`));

}