<ng-content></ng-content>
<app-upload-file
    class="my-4" [color]="'primary'" [button]="'add'"
    [formats]="config.fileFormats" (uploadedFile)="uploadedFile($event)">
</app-upload-file>
<div class="d-flex flex-row flex-nowrap justify-content-between">
    <button mat-flat-button color="primary" class="btn" type="button" [disabled]="loading" (click)="exitUpload.emit()">
        {{ 'general.actions.BACK' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" [disabled]="!file || loading" (click)="processFile()">
        {{ 'general.actions.PROCESS_FILE' | translate }}
    </button>
</div>