import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ICampaign, ICampaignZone } from '@routes/campaigns/campaign.model';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { ICrop } from '@routes/crops/crop.model';
import { CropService } from '@routes/crops/crop.service';
import { IPackagingReceived } from '@routes/packagings/packaging.model';
import { PackagingService } from '@routes/packagings/packaging.service';
import { ITaskType } from '@routes/tasks/task.model';
import { TaskService as TaskTypeService } from '@routes/tasks/task.service';

import { FORM_MODES } from '@shared/constants/forms';
import { GenericPagination } from '@shared/models/pagination.model';
import { TaskReceived } from '@shared/models/task.model';
import { WorkingDay } from '@shared/models/working-day.model';
import { WorkingDayService } from '@shared/services/working-day.service';

@Injectable()
export class CampaignsDetailTasksEditService {

  private pagination = new GenericPagination({ sortField: 'name' });

  mode: FORM_MODES;

  campaign: ICampaign;
  workingDay: WorkingDay;
  task: TaskReceived;

  crops: ICrop[];
  packagings: IPackagingReceived[];
  taskTypes: ITaskType[];
  zones: ICampaignZone[];

  constructor(
    private campaignService: CampaignService,
    private cropService: CropService,
    private packagingService: PackagingService,
    private plannerService: CampaignPlannerService,
    private taskTypeService: TaskTypeService,
    private workingDayService: WorkingDayService
  ) {}

  private getCampaign = async (activatedRoute: ActivatedRouteSnapshot) => {
    const campaignId = activatedRoute.parent.parent.params.campaignId;
    this.campaign = await this.campaignService.getCampaign(campaignId);
    this.plannerService.setCampaignBasic(this.campaign);
  }

  private getWorkingDay = async (activatedRoute: ActivatedRouteSnapshot) => {
    const workingDayId = activatedRoute.params.id;
    this.workingDay = await this.workingDayService.getWorkingDay(this.campaign._id, workingDayId);
    this.plannerService.selectSite(this.workingDay.siteId);
  }

  private getTask = async (activatedRoute: ActivatedRouteSnapshot) => {
    const taskId = activatedRoute.params.taskId;
    this.task = await this.workingDayService.getTask(this.campaign._id, taskId);
  }

  private getCrops = async () => this.crops = (await this.cropService.getCrops(this.pagination)).data;

  private getPackagings = async () => this.packagings = (await this.packagingService.getPackagings(this.pagination)).data;

  private getTaskTypes = async () => this.taskTypes = (await this.taskTypeService.getTasks(this.pagination)).tasksTypes;

  private getZones = () => {
    this.plannerService.selectSite(this.workingDay.siteId);
    this.zones = this.plannerService.zones;
  }

  initService = async (activatedRoute: ActivatedRouteSnapshot) => {
    await this.getCampaign(activatedRoute);
    await this.getWorkingDay(activatedRoute);
    await Promise.all([
      this.getCrops(),
      this.getPackagings(),
      this.getTaskTypes(),
      this.getZones()]);

    this.mode = activatedRoute.data.mode;
    if (this.mode === FORM_MODES.edit) await this.getTask(activatedRoute);
  }

  findTaskTypeType = (taskTypeId: string) =>
    this.taskTypes.find(taskType => taskType._id === taskTypeId).type;

  findZone = (zoneSequentialId: number) =>
    this.zones.find(zone => zone.sequentialId === zoneSequentialId);

  findCrop = (cropId: string) =>
    this.crops.find(crop => crop._id === cropId);

}
