import { FormControl, FormGroup, Validators } from "@angular/forms";

export const createItemFormGroup = (data: any): FormGroup => {
    const formGroup = new FormGroup({
        _id: new FormControl(null, Validators.required),
        sageReference: new FormControl(null, Validators.required),
        description: new FormControl(null, Validators.required),
        category: new FormControl(null, Validators.required),
        subcategory: new FormControl(null, Validators.required),
        boxes: new FormControl(null, [Validators.required, Validators.min(1)]),
        weight: new FormControl(null, [Validators.required, Validators.min(0)]),
        deleted: new FormControl(false, Validators.required)
    });
    formGroup.patchValue(data);
    return formGroup;
};

export const createBatchFormGroup = (data: any): FormGroup => {
    const formGroup = new FormGroup({
        code: new FormControl(null, Validators.required),
        crop: new FormControl(null),
        packaging: new FormControl(null),
        boxes: new FormControl(null, [Validators.required, Validators.min(1)]),
        deleted: new FormControl(null, Validators.required)
    });
    formGroup.patchValue(data);
    return formGroup;
};
