import { Component, OnInit } from '@angular/core';
import { Agency } from '@models/agency.model';
import { AgencyService } from '@routes/agencies/agency.service';
import { AGENCIES_ROUTES, ABS_BASE_ROUTE, SECTIONS } from '@shared/constants/routes';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { FiltersButtonLink, FiltersConfiguration } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { Pagination } from '@shared/models/pagination.model';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-agencies-main',
  templateUrl: './agencies-main.component.html',
  styleUrls: ['./agencies-main.component.scss']
})
export class AgenciesMainComponent implements OnInit {

  filtersConfig: FiltersConfiguration;
  tableConfig: TableConfiguration<Agency>;
  
  constructor(
    private agencyService: AgencyService,
    private snackbarService: CustomSnackbarService
  ) {}

  ngOnInit(): void {
    this.initializeTable();
    this.initializeFilters();
  }

  retrieveAgencies = async (pagination: Pagination) => this.agencyService.getAgencies(pagination)
    .then(response => this.tableConfig.data.next(response))
    .catch(error => this.snackbarService.error(error.error.message));

  initializeFilters = () => this.filtersConfig = new FiltersConfiguration({
    buttons: [
      new FiltersButtonLink({
        text: 'NEW',
        icon: 'add',
        link: [ABS_BASE_ROUTE, SECTIONS.workforce, AGENCIES_ROUTES.parent, AGENCIES_ROUTES.add]
      })
    ],
    controls: []
  });
  
  initializeTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'agencies.table',
    data: new BehaviorSubject({data: null, total: 0}),
    pagination: {
      page: 1, size: 10, search: '',
      sort: {field: 'name', order: 1}
    },
    columns: [
      new TableColumnText({
        header: 'NAME',
        name: 'name',
        sorting: 'name',
        displayAt: 0,
        value: agency => agency.name
      }),
      new TableColumnText({
        header: 'ABBREVIATION',
        name: 'abbreviation',
        sorting: 'abbreviation',
        displayAt: 520,
        value: agency => agency.abbreviation
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            icon: GenericMenuOptionIcon.Search,
            text: 'DETAIL',
            link: agency => [AGENCIES_ROUTES.detail, agency._id]
          }),
          new TableMenuOption({
            icon: GenericMenuOptionIcon.Edit,
            text: 'EDIT',
            link: agency => [AGENCIES_ROUTES.edit, agency._id]
          })
        ]
      })
    ]
  });

}
