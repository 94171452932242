<section class="confirmation">
    <h2 class="h2">{{ texts.TITLE }}</h2>
    <p class="message">{{ texts.MESSAGE }}</p>
    <mat-dialog-actions class="d-flex justify-content-between">
        <button mat-flat-button (click)="resendRecovery()" color="primary" class="actions-button btn" type="button">
            {{ texts.REQUEST_RECOVERY }}
        </button>
        <button mat-flat-button mat-dialog-close color="primary" class="actions-button btn" type="button">
            {{ 'general.actions.CANCEL' | translate }}
        </button>
    </mat-dialog-actions>
    </section>
