import { Directive, Input, OnInit } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SubscriptionManager } from '@shared/classes/subscription-manager';
import { TIME_REG_EXP } from '@shared/constants/regexp';

@Directive({
  selector: '[appReactiveTimeInput]',
})
export class ReactiveTimeInputDirective extends SubscriptionManager implements OnInit {

  @Input() dateControl: FormControl;

  hostControl: FormControl;

  constructor(
    private control: NgControl
  ) {
    super()
  }

  ngOnInit(): void {
    if (!this.dateControl) throw new Error('Date reactive control not found');
    this.hostControl = this.control.control as FormControl;
    if (!this.hostControl) throw new Error('Host reactive control not found');
    this.reactToDateControl();
    this.updateDateControl();
  }

  reactToDateControl = () => this.addSubscription(merge(
    this.dateControl.valueChanges.pipe(filter(value => value)),
    this.hostControl.valueChanges.pipe(filter(value => TIME_REG_EXP.HOUR_MINUTES.test(value)))
  ).subscribe(() => this.updateDateControl()));

  updateDateControl = () => {
    if (!this.dateControl.value || !TIME_REG_EXP.HOUR_MINUTES.test(this.hostControl.value)) return;
    const date = new Date(this.dateControl.value);
    const time = this.hostControl.value.split(':');
    date.setHours(Number.parseInt(time[0]), Number.parseInt(time[1]));
    this.dateControl.setValue(date.toISOString(), {emitEvent: false});
  }

}
