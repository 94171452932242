import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { HeaderService } from '@layout/header/header.service';

import { IDispatchNotePaginated, IDispatchNoteReceived } from '@routes/dispatch-notes/dispatch-notes.model';
import { DispatchNotesService } from '@routes/dispatch-notes/dispatch-notes.service';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';
import { CompaniesService } from '@routes/companies/companies.service';
import { DISPATCH_NOTE_BATCH_STATUS, DISPATCH_NOTE_STATUS, DISPATCH_NOTE_TYPE } from '@routes/dispatch-notes/dispatch-notes.constants';

import { GenericPagination, Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { DISPATCH_NOTES_ROUTES } from '@shared/constants/routes';

import { TableColumnMenu, TableColumnCheckbox, TableColumnChip, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { FiltersConfiguration, FiltersControlDate, FiltersControlSelect, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { GenericTableBehaviour, GenericTableElementColor, GenericTableColumnStyle, GenericTableColumnAlign, GenericMenuOptionIcon } from '@shared/components/generic-table/generic-table.constants';
import { BehaviorSubject } from 'rxjs';
import { FilterOperator } from '@shared/components/generic-filters/generic-filters.constants';

@Component({
  selector: 'app-dispatch-notes-table',
  templateUrl: './dispatch-notes-table.component.html',
  styleUrls: ['./dispatch-notes-table.component.scss'],
  providers: [DatePipe]
})
export class DispatchNotesTableComponent implements OnInit {

  tableConfig: TableConfiguration<IDispatchNotePaginated>;
  filtersConfig: FiltersConfiguration;
  invalidDispatchNotes = new Map<string, boolean>();

  constructor(
    private dispatchNotesService: DispatchNotesService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private sitesService: WorkingSiteService,
    private companiesService: CompaniesService,
    private datepipe: DatePipe,
    private headerService: HeaderService,
  ) {}

  ngOnInit(): void {
    this.initFilters();
    this.initTable();
    this.getCompanies();
    this.getSites();
    
    this.headerService.typeFilterChanges().subscribe(type => {
      Object.assign(this.tableConfig.pagination.params, { type });
      this.tableConfig.updatePagination.next(this.tableConfig.pagination as FiltersEvent);
    });
  }

  getDispatchNotes = (pagination: Pagination) => this.dispatchNotesService.getDispatchNotes(pagination)
    .then(response => {
      this.tableConfig.data.next(response);
      this.calculateInvalidDispatchNotes(response.data);
    })
    .catch(error => this.snackbarService.error(error.error.message));

  getCompanies = async () => {
    const companies = await this.companiesService.getCompanies();

    const filterOptions = companies.data.map(company => ({
      value: company._id,
      display: company.businessName
    }));
    this.filtersConfig.updateControlOptions('origins', filterOptions);
    this.filtersConfig.updateControlOptions('destinations', filterOptions);
  }

  getSites = async () => {
    const sites = await this.sitesService.getSites(new GenericPagination({ sortField: 'name'}));
    const filterOptions = sites['sites'].map(site => ({
      value: site._id,
      display: site.name
    }));
    this.filtersConfig.updateControlOptions('sites', filterOptions);
  }

  calculateInvalidDispatchNotes = (dispatchNotes: IDispatchNoteReceived[]) => {
    this.invalidDispatchNotes.clear();
    dispatchNotes.forEach(dispatchNote => this.invalidDispatchNotes.set(
      dispatchNote._id,
      dispatchNote.batchs.findIndex(batch => batch.status === DISPATCH_NOTE_BATCH_STATUS.INVALID) !== -1
    ));
  }

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [
      new FiltersControlDate({
        label: 'START_DATE',
        name: 'startDate',
        field: 'datetime',
        operator: FilterOperator.GreaterEqual,
        value: null,
        modifier: date => date.setHours(0, 0, 0, 0)
      }),
      new FiltersControlDate({
        label: 'END_DATE',
        name: 'endDate',
        field: 'datetime',
        operator: FilterOperator.LessEqual,
        value: null,
        modifier: date => date.setHours(23, 59, 59, 999)
      }),
      new FiltersControlSelect({
        label: 'STATUSES',
        name: 'statuses',
        field: 'status',
        operator: FilterOperator.In,
        value: [DISPATCH_NOTE_STATUS.CLOSED],
        options: new BehaviorSubject(Object.values(DISPATCH_NOTE_STATUS).map(value => ({
          value, display: this.translateService.instant(`dispatchNotes.statuses.${value.toUpperCase()}`)
        })))
      }),
      new FiltersControlSelect({
        label: 'SITES',
        name: 'sites',
        field: 'siteId',
        operator: FilterOperator.In,
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'ORIGINS',
        name: 'origins',
        field: 'originId',
        operator: FilterOperator.In,
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'DESTINATIONS',
        name: 'destinations',
        field: 'destinationId',
        operator: FilterOperator.In,
        value: [],
        options: new BehaviorSubject([])
      })
    ],
    buttons: []
  });

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'dispatchNotes.entities',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1,
      size: 10,
      search: '',
      params: { type: this.headerService.typeFilter },
      sort: { field: 'datetime', order: -1 },
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: dispatchNote => dispatchNote.code
      }),
      new TableColumnText({
        header: 'STATUS',
        name: 'status',
        sorting: 'status',
        displayAt: 520,
        value: dispatchNote => this.translateService.instant('dispatchNotes.statuses.' + dispatchNote.status.toUpperCase())
      }),
      new TableColumnText({
        header: 'ORIGIN',
        name: 'origin',
        sorting: 'origin.businessName',
        displayAt: 1280,
        value: dispatchNote => dispatchNote.origin?.businessName
      }),
      new TableColumnText({
        header: 'DESTINATION',
        name: 'destination',
        sorting: 'destination.businessName',
        displayAt: 1280,
        value: dispatchNote => dispatchNote.destination?.businessName
      }),
      new TableColumnText({
        header: 'SITE',
        name: 'site',
        sorting: 'site.name',
        displayAt: 860,
        value: dispatchNote => dispatchNote.site.name
      }),
      new TableColumnText({
        header: 'DATETIME',
        name: 'datetime',
        sorting: 'datetime',
        displayAt: 640,
        value: dispatchNote => this.datepipe.transform(dispatchNote.datetime, 'dd/MM/yyyy, HH:mm', 'GMT')
      }),
      new TableColumnChip({
        header: 'TYPE',
        name: 'type',
        sorting: 'type',
        displayAt: 768,
        align: GenericTableColumnAlign.Center,
        value: dispatchNote => dispatchNote.type ? this.translateService.instant(`general.types.${dispatchNote.type.toUpperCase()}`) : '',
        color: dispatchNote => this.getChipColor(dispatchNote),
      }),
      new TableColumnCheckbox({
        header: 'ERRORS',
        name: 'errors',
        displayAt: 1440,
        style: GenericTableColumnStyle.Compact,
        color: GenericTableElementColor.Warn,
        intermediate: true,
        checked: dispatchNote => this.invalidDispatchNotes.get(dispatchNote._id),
        disabled: () => true,
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: dispatchNote => [DISPATCH_NOTES_ROUTES.detail, dispatchNote._id]
          })
        ]
      })
    ]
  })

  getChipColor = (dispatchNote: IDispatchNotePaginated) => {
    switch (dispatchNote.type) {
      case DISPATCH_NOTE_TYPE.PLANT: return GenericTableElementColor.Primary;
      case DISPATCH_NOTE_TYPE.FRUIT: return GenericTableElementColor.Warn;
      default: return GenericTableElementColor.Accent;
    }
  }
}
