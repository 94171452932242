import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    constructor() {}

    setItem = (key: string, value: string) => localStorage.setItem(key, value);
    getItem = (key: string) => localStorage.getItem(key);
    removeItem = (key: string) => localStorage.removeItem(key);
    clear() {
        const currentLang = localStorage.getItem('lang');
        localStorage.clear();
        localStorage.setItem('lang', currentLang);
    }

}
