import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ITYPE, WORKER_HISTORICS } from 'src/app/shared/interfaces/types';
import { WorkerReceived } from 'src/app/routes/workers/worker.model';
import { ActivatedRoute } from '@angular/router';
import { WORKER_ROUTES } from '@shared/constants/routes';
import { WorkersDetailService } from './workers-detail.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-workers-detail',
  templateUrl: './workers-detail.component.html',
  styleUrls: ['./workers-detail.component.scss']
})
export class WorkersDetailComponent implements OnInit, OnDestroy {

  section: string;
  worker: WorkerReceived;
  subscriptions: Subscription[] = [];
  historics: ITYPE[] = WORKER_HISTORICS;

  constructor(
    private activatedRoute: ActivatedRoute,
    private detailService: WorkersDetailService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.detailService.workerObservable.subscribe(worker => this.worker = worker));
    this.subscriptions.push(this.detailService.selectorObservable.subscribe(section => this.section = section));
    
    this.detailService.setEditMode(this.activatedRoute.snapshot.url[0].path === WORKER_ROUTES.edit);
    this.detailService.setWorker(this.activatedRoute.snapshot.data.worker);
    this.detailService.setSelectorSection('general');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  displayHistoricSection = (event: MatSelectChange) => {
    this.detailService.setSelectorSection(event.value);
    this.detailService.setHistoricSection('table');
  };

}