import { BuildInfo } from './../models/buildinfo.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BuildService {

    constructor(
        private http: HttpClient,
        private api: ApiService
    ) { }

    private getApkUrl = (): string => `${this.api.getBaseUrl()}/builds/apk`;

    async getLatestBuild(): Promise<BuildInfo> {
        return (await lastValueFrom(this.http.get(`${this.api.getBaseUrl()}/builds/version/latest`)) as BuildInfo);
    }
    
    downloadApk = (): Promise<Blob> =>  lastValueFrom(this.http.get(this.getApkUrl(), { responseType: 'blob' }));
}
