import { Routes } from '@angular/router';

import { LayoutComponent } from '@layout/layout.component';

import { LoginComponent } from '@routes/login/login.component';
import { PasswordRecoveryComponent } from '@routes/password-recovery/password-recovery.component';
import { WorkingSitesModule } from '@routes/working-sites/working-sites.module';
import { CropsModule } from '@routes/crops/crops.module';
import { CampaignsModule } from '@routes/campaigns/campaigns.module';
import { AgenciesModule } from '@routes/agencies/agencies.module';
import { WorkersModule } from '@routes/workers/workers.module';
import { TasksModule } from '@routes/tasks/tasks.module';
import { PackagingsModule } from '@routes/packagings/packagings.module';
import { DevicesModule } from '@routes/devices/devices.module';
import { BatchsModule } from '@routes/batchs/batchs.module';
import { UsersModule } from '@routes/users/users.module';
import { DispatchNotesModule } from '@routes/dispatch-notes/dispatch-notes.module';
import { CompaniesModule } from '@routes/companies/companies.module';
import { ItemsModule } from '@routes/items/items.module';
import { WarehousesModule } from '@routes/warehouses/warehouses.module';
import { ProductionLinesModule } from '@routes/production-lines/production-lines.module';
import { HelpModule } from '@routes/help/help.module';
import { KeychainsModule } from '@routes/keychains/keychains.module';
import { ReportsModule } from '@routes/reports/reports.module';
import { MobileAppModule } from '@routes/mobile-app/mobile-app.module';

import { BuildService } from '@shared/services/build.service';
import { AuthGuard } from '@shared/guards/auth.guard';
import { UserResolver } from '@shared/resolvers/user.resolver';
import { RedirectGuard } from '@shared/guards/redirect.guard';
import { RedirectComponent } from '@shared/widgets/redirect/redirect.component';
import {
    LOGIN_ROUTE,
    BASE_ROUTE,
    SECTIONS,
    USERS_ROUTES,
    WORKER_ROUTES,
    CAMPAIGNS_ROUTES,
    WORKING_SITES_ROUTES,
    AGENCIES_ROUTES,
    CROPS_ROUTES,
    TASKS_ROUTES,
    PACKAGINGS_ROUTES,
    DEVICES_ROUTES,
    BITRISE_ROUTE,
    BATCHS_ROUTES,
    DISPATCH_NOTES_ROUTES,
    COMPANIES_ROUTES,
    ITEMS_ROUTES,
    WAREHOUSES_ROUTES,
    PRODUCTION_LINES_ROUTES,
    POSITIONS_ROUTES,
    HELP_ROUTES,
    KEYCHAIN_ROUTES,
    MOBILE_APP_ROUTES,
    TRACEABILITY_REPORTS_ROUTES,
    PASSWORD_RECOVERY_ROUTE,
    RESET_PASSWORD_ROUTE
} from '@shared/constants/routes';
import { RECOVERY_FORM_MODES } from '@shared/constants/password-recovery';
import { PositionsModule } from '@routes/positions/positions.module';

export const routes: Routes = [
    {
        path: '',
        redirectTo: LOGIN_ROUTE,
        pathMatch: 'full'
    },
    {
        path: BITRISE_ROUTE,
        component: RedirectComponent,
        canActivate: [RedirectGuard],
        data: {
            url: async (buildService: BuildService) => {
                const latestBuild = await buildService.getLatestBuild();
                console.log('latestBuild', latestBuild);
                return latestBuild.url;
            }
        }
    },
    {
        path: LOGIN_ROUTE,
        component: LoginComponent
    },
    {
        path: PASSWORD_RECOVERY_ROUTE,
        component: PasswordRecoveryComponent,
        data: {
            mode: RECOVERY_FORM_MODES.RECOVERY
          }
    },
    {
        path: RESET_PASSWORD_ROUTE,
        component: PasswordRecoveryComponent,
        data: {
            mode: RECOVERY_FORM_MODES.CHANGE
          }
    },
    {
        path: BASE_ROUTE,
        component: LayoutComponent,
        canActivateChild: [
            AuthGuard
        ],
        resolve: {
            user: UserResolver
        },
        children: [{
            path: '',
            redirectTo: WORKING_SITES_ROUTES.parent,
            pathMatch: 'full'
        }, {
            path: USERS_ROUTES.parent,
            loadChildren: () => UsersModule
        }, {
            path: WORKING_SITES_ROUTES.parent,
            loadChildren: () => WorkingSitesModule
        }, {
            path: `${SECTIONS.production}/${CAMPAIGNS_ROUTES.parent}`,
            loadChildren: () => CampaignsModule
        }, {
            path: `${SECTIONS.production}/${CROPS_ROUTES.parent}`,
            loadChildren: () => CropsModule
        }, {
            path: `${SECTIONS.production}/${ITEMS_ROUTES.parent}`,
            loadChildren: () => ItemsModule
        }, {
            path: `${SECTIONS.production}/${WAREHOUSES_ROUTES.parent}`,
            loadChildren: () => WarehousesModule
        }, {
            path: `${SECTIONS.production}/${PRODUCTION_LINES_ROUTES.parent}`,
            loadChildren: () => ProductionLinesModule
        }, {
            path: `${SECTIONS.production}/${TASKS_ROUTES.parent}`,
            loadChildren: () => TasksModule
        }, {
            path: `${SECTIONS.production}/${PACKAGINGS_ROUTES.parent}`,
            loadChildren: () => PackagingsModule
        }, {
            path: `${SECTIONS.workforce}/${AGENCIES_ROUTES.parent}`,
            loadChildren: () => AgenciesModule
        }, {
            path: `${SECTIONS.workforce}/${POSITIONS_ROUTES.parent}`,
            loadChildren: () => PositionsModule
        }, {
            path: `${SECTIONS.workforce}/${WORKER_ROUTES.parent}`,
            loadChildren: () => WorkersModule
        }, {
            path: `${SECTIONS.workforce}/${DEVICES_ROUTES.parent}`,
            loadChildren: () => DevicesModule
        }, {
            path: `${SECTIONS.workforce}/${KEYCHAIN_ROUTES.parent}`,
            loadChildren: () => KeychainsModule
        }, {
            path: `${SECTIONS.traceability}/${BATCHS_ROUTES.parent}`,
            loadChildren: () => BatchsModule
        }, {
            path: `${SECTIONS.traceability}/${DISPATCH_NOTES_ROUTES.parent}`,
            loadChildren: () => DispatchNotesModule
        }, {
            path: `${SECTIONS.traceability}/${TRACEABILITY_REPORTS_ROUTES.parent}`,
            loadChildren: () => ReportsModule
        }, {
            path: COMPANIES_ROUTES.parent,
            loadChildren: () => CompaniesModule
        }, {
            path: HELP_ROUTES.parent,
            loadChildren: () => HelpModule
        }, {
            path: MOBILE_APP_ROUTES.parent,
            loadChildren: () => MobileAppModule
        }]
    }
];
