<h2 class="h2">{{ 'dispatchNotes.titles.CLOSE' | translate }}</h2>
<p>{{ 'dispatchNotes.messages.CONFIRMATION_DIALOG' | translate }}</p>
<form class="form" [formGroup]="form" [ngSwitch]="step" (submit)="submitForm()">
    <div class="section" formGroupName="delivery" *ngSwitchCase="STEPS.DELIVERY">
        <h3 class="h3 full-grid-row">{{ 'dispatchNotes.entities.ORIGIN_AND_DESTINATION' | translate }}</h3>
        <!-- Origin -->
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.ORIGIN' | translate }}</mat-label>
            <mat-select formControlName="originId">
                <mat-option *ngFor="let company of companies" [value]="company._id">{{ company.businessName }}</mat-option>
            </mat-select>
            <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
        </mat-form-field>
        <!-- Destination -->
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.DESTINATION' | translate }}</mat-label>
            <mat-select formControlName="destinationId">
                <mat-option *ngFor="let company of companies" [value]="company._id">{{ company.businessName }}</mat-option>
            </mat-select>
            <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
        </mat-form-field>
        <!-- Delivery address -->
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.DELIVERY_ADDRESS' | translate }}</mat-label>
            <mat-select formControlName="deliveryAddress">
                <mat-option *ngFor="let address of deliveryAddresses" [value]="address">
                    {{ address.address }}, {{ address.city }}, {{ address.state }}
                </mat-option>
            </mat-select>
            <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
        </mat-form-field>
    </div>
    <!-- Load Place -->
    <div class="section" formGroupName="loadPlace" *ngSwitchCase="STEPS.LOAD_PLACE">
        <h3 class="h3 full-grid-row">{{ 'dispatchNotes.entities.LOAD_PLACE_INFORMATION' | translate }}</h3>
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.LOAD_PLACE' | translate }}</mat-label>
            <input matInput type="text" formControlName="site">
        </mat-form-field>
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.PERSON_IN_CHARGE' | translate }}</mat-label>
            <input matInput type="text" formControlName="personInCharge">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.NIF' | translate }}</mat-label>
            <input matInput type="text" formControlName="nif">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.LOAD_TIME' | translate }}</mat-label>
            <input matInput type="text" formControlName="time">
        </mat-form-field>
    </div>
    <!-- Transport -->
    <div class="section" formGroupName="transport" *ngSwitchCase="STEPS.TRANSPORT">
        <h3 class="h3 full-grid-row">{{ 'dispatchNotes.entities.TRANSPORT_INFORMATION' | translate }}</h3>
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.TRANSPORT_COMPANY' | translate }}</mat-label>
            <input matInput type="text" formControlName="company">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.TRUCK_PLATE' | translate }}</mat-label>
            <input matInput type="text" formControlName="truckNumber">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.TRAILER_PLATE' | translate }}</mat-label>
            <input matInput type="text" formControlName="trailerLoadNumber">
        </mat-form-field>
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.DRIVER' | translate }}</mat-label>
            <input matInput type="text" formControlName="chofer">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.PHONE' | translate }}</mat-label>
            <input matInput type="text" formControlName="phone">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.NIF' | translate }}</mat-label>
            <input matInput type="text" formControlName="nif">
        </mat-form-field>
    </div>
    <!-- Reception -->
    <div class="section" formGroupName="reception" *ngSwitchCase="STEPS.RECEPTION">
        <h3 class="h3 full-grid-row">{{ 'dispatchNotes.entities.RECEPTION_INFORMATION' | translate }}</h3>
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.NAME' | translate }}</mat-label>
            <input matInput type="text" formControlName="name">
        </mat-form-field>
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.NIF' | translate }}</mat-label>
            <input matInput type="text" formControlName="nif">
        </mat-form-field>
    </div>
    <!-- Others -->
    <div class="section" formGroupName="others" *ngSwitchCase="STEPS.OTHERS">
        <h3 class="h3 full-grid-row">{{ 'dispatchNotes.entities.OTHERS_INFORMATION' | translate }}</h3>
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.COMMENTS' | translate }}</mat-label>
            <input matInput type="text" formControlName="comments">
        </mat-form-field>
        <mat-form-field class="full-grid-row" appearance="fill">
            <mat-label>{{ 'dispatchNotes.entities.TEMPERATURE' | translate }}</mat-label>
            <input matInput type="number" formControlName="temperature">
        </mat-form-field>
    </div>

    <div class="buttons">
        <button mat-flat-button color="warn" class="btn" type="button" [disabled]="loading" (click)="closeDialog()">
            {{ 'general.actions.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" class="btn" type="button" [disabled]="step === STEPS.DELIVERY || loading" (click)="previousStep()">
            {{ 'general.actions.BACK' | translate }}
        </button>
        <button mat-flat-button color="primary" class="btn" type="button" [disabled]="form.get(step).invalid" *ngIf="step !== STEPS.OTHERS" (click)="nextStep()">
            {{ 'general.actions.CONTINUE' | translate }}
        </button>
        <button mat-flat-button color="primary" class="btn" type="submit" *ngIf="step === STEPS.OTHERS" [disabled]="form.invalid || loading">
            {{ 'general.actions.CONFIRM' | translate }}
        </button>
    </div>
</form>
