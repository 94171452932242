export interface IWorkingSitesPaginated {
    sites: IWorkingSite[];
    total: number;
}

export interface IWorkingSite {
    _id?: string;
    id: string;
    name: string;
    alias: string;
    city: string;
    abbreviation: string;
    timezone: string;
    cadastralRef: string;
    location: {[key: string]: number};
    area: string;
    enclosure: string;
    plot: string;
    type: string;
    traceCode: string;
}

export class WorkingSite implements IWorkingSite {
    public _id?;
    public id;
    public name;
    public alias;
    public city;
    public abbreviation;
    public timezone;
    public cadastralRef;
    public location;
    public area;
    public enclosure;
    public plot;
    public type;
    public traceCode;

    constructor(workingsite: IWorkingSite) {
        Object.assign(this, workingsite)
    }
}