import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { map } from 'rxjs/operators';
import { ITaskTypesPaginated, ITaskType, TaskType } from './task.model';
import { Pagination } from '@shared/models/pagination.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  private getTaskTypesUrl = (): string => `${this.apiService.getBaseUrl()}/tasksTypes`;
  private getTaskTypeUrl = (id: string): string => `${this.getTaskTypesUrl()}/${id}`;

  getTasks = (pagination: Pagination): Promise<ITaskTypesPaginated> =>
    lastValueFrom(this.http.get<TaskType[]>(this.getTaskTypesUrl(), { params: pagination.loadHttpParams() }).pipe(map(this.parseTaskTypes)));

  getTask = (id: string): Promise<TaskType> =>
    lastValueFrom(this.http.get<TaskType>(`${this.getTaskTypeUrl(id)}`).pipe(map(this.parseTaskType)));

  addTask = (task: TaskType): Promise<TaskType> =>
    lastValueFrom(this.http.post<TaskType>(this.getTaskTypesUrl(), task).pipe(map(this.parseTaskType)));

  editTask = (task: TaskType): Promise<TaskType> =>
    lastValueFrom(this.http.put<TaskType>(this.getTaskTypeUrl(task.id), task).pipe(map(this.parseTaskType)));

  deleteTask = (id: string): Promise<object> =>
    lastValueFrom(this.http.delete(`${this.getTaskTypeUrl(id)}`));

  parseTaskType = (taskType: any): ITaskType => new TaskType({ id: taskType._id, ...taskType });

  parseTaskTypes = (tasksObj: object): ITaskTypesPaginated => {
    if (!tasksObj || !tasksObj['tasksTypes']) {
      return null;
    }
    const tasksTypes = tasksObj['tasksTypes'].map((taskType: ITaskType) => this.parseTaskType(taskType));
    const total = tasksObj['total'];
    return { tasksTypes, total };
  }
}
