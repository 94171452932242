<h2 class="dashboard-subheading">{{ 'campaigns.summary.TITLE' | translate }}</h2>
<ul class="zone-list">
    <li class="zone-item" *ngFor="let site of sites" (click)="selectSite(site.siteId)">
        <span class="zone-name">{{ site.siteName }}</span>
        <mat-chip
            class="zone-chip"
            [ngClass]="{'with-zones': site.zones.length > 0}">{{ getChipText(site.zones.length) | translate: getZonesLength(site.zones.length) }}
        </mat-chip>
    </li>
</ul>
<div class="section-buttons">
    <button mat-flat-button color="primary" class="btn" type="button"(click)="changeSection.emit('sites')">
        {{ 'campaigns.summary.SITES' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" [disabled]="!sitesHasZones()" (click)="changeSection.emit('workers')">
        {{ 'campaigns.summary.WORKERS' | translate }}
    </button>
</div>