<section class="login-page">
    <form class="form" [formGroup]="form" (submit)="submitForm()">
        <img class="form-logo" src="/assets/images/logos/vivero_el_pinar_icon_logo.png" alt="Vivero El Pinar"/>
        <!-- Requesting recovery -->
        <ng-container *ngIf="recovering; else changingPassword">
            <p class="message">{{ 'passwordRecovery.form.INFO' | translate }}</p>
            <p class="message alert alert-warning">{{ 'passwordRecovery.form.WARNING' | translate }}</p>
            <mat-form-field appearance="standard">
                <mat-label>{{ 'passwordRecovery.form.EMAIL' | translate }}</mat-label>
                <input matInput type="text" formControlName="email">
                <mat-hint>* {{'general.form.MANDATORY' | translate }}</mat-hint>
            </mat-form-field>
            <button mat-button color="primary" type="button" [routerLink]="LOGIN_ROUTE">
                {{ 'passwordRecovery.form.GO_BACK' | translate }}
            </button>
            <button mat-flat-button color="primary" class="form-button btn" type="submit" [disabled]="form.invalid || processing">
                {{ 'passwordRecovery.form.SEND_EMAIL' | translate }}
            </button>
        </ng-container>
        <!-- Changing password -->
        <ng-template #changingPassword>
            <p class="message">{{ 'passwordRecovery.resetForm.MESSAGE' | translate }}</p>
            <mat-form-field appearance="standard">
                <mat-label>{{ 'passwordRecovery.resetForm.PASSWORD' | translate }}</mat-label>
                <input matInput type="password" formControlName="password">
                <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>{{ 'passwordRecovery.resetForm.REPEAT_PASSWORD' | translate }}</mat-label>
                <input matInput type="password" formControlName="repeat">
                <mat-hint>* {{'general.form.MANDATORY' | translate }}</mat-hint>
                <mat-error *ngIf="form.get('repeat').errors?.mismatch">
                    {{ 'users.message.PASSWORD_MISMATCH' | translate }}
                </mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" class="form-button btn" type="submit" [disabled]="form.invalid || processing">
                {{ 'general.actions.CONFIRM' | translate }}
            </button>
        </ng-template>
    </form>
</section>
