export enum DISPATCH_NOTE_STATUS {
    OPEN = 'open',
    CLOSED = 'closed'
};

export enum DISPATCH_NOTE_BATCH_STATUS {
    VALID = 'valid',
    INVALID = 'invalid'
};

export enum DISPATCH_NOTE_BATCH_REASONS {
    WRONG_DATA = 'wrong-data',
    AVAILABLE = 'available',
    UNAVAILABLE = 'unavailable'
};

export enum DISPATCH_NOTE_TYPE {
    FRUIT = 'fruit',
    PLANT = 'plant'
};

export const DISPATCH_NOTE_UNIT_PER_TYPE = {
    [DISPATCH_NOTE_TYPE.FRUIT]: 'kg',
    [DISPATCH_NOTE_TYPE.PLANT]: 'pl'
};
