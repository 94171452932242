import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenericFiltersService {

  private value: any = {};

  constructor() {}

  setFiltersValue = (value: any) => this.value = value;

  getFiltersValue = () => this.value;

  removeFiltersValue = () => this.value = {};

}
