import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { WorkerReceivedGroup } from '@routes/workers/worker.model';
import { MatSelectChange } from '@angular/material/select';
import { ITYPE, CAMPAIGNS_WORKERS_SECTIONS } from '@shared/interfaces/types';
import { Pagination } from '@shared/models/pagination.model';
import { WorkerService } from '@routes/workers/worker.service';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { CampaignWorker } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '../campaigns-planner/campaign.planner.service';
import { WorkerStatus } from '@routes/workers/workers.constants';

@Component({
  selector: 'app-campaigns-workers',
  templateUrl: './campaigns-workers.component.html',
  styleUrls: ['./campaigns-workers.component.scss']
})
export class CampaignsWorkersComponent implements OnInit {

  @Output() campaignHasChanged: EventEmitter<void> = new EventEmitter<void>();

  workersSections: ITYPE[] = CAMPAIGNS_WORKERS_SECTIONS;
  workersSection: string = 'search';
  workersList: CampaignWorker[];
  pagination: Pagination = new Pagination({
    size: 25, page: 1, params: {status: WorkerStatus.Active, deleted: false}});
  columns: string[] = [
    'name', 'sequentialId', 'nif', 'position', 'actions'];
  total: number;

  constructor(
    public plannerService: CampaignPlannerService,
    private workerService: WorkerService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.retrieveWorkers();
  }

  displayWorkersSection = (event: MatSelectChange) => {
    this.workersSection = event.value;
    this.pagination.page = 1;

    if (this.workersSection === 'search') {
      this.pagination.page = 1;
      this.retrieveWorkers();
    } else {
      this.pagination.page = 0;
      this.loadIncludedWorkers();
    }
  };

  retrieveWorkers = () => {
    this.workerService.getWorkers(this.pagination).then(result => {
      this.workersList = this.parseWorkers(result.workers);
      this.total = result.total;
    });
  };

  searchWorkers = (search: string) => {
    this.pagination.search = search;

    if (this.workersSection === 'search') {
      this.retrieveWorkers();
    }
  };

  paginateWorkers = (event: PageEvent) => {
    this.pagination.page = this.workersSection === 'search'
      ? event.pageIndex + 1
      : event.pageIndex;
    this.pagination.size = event.pageSize;

    this.workersSection === 'search'
      ? this.retrieveWorkers()
      : this.paginateLocalWorkers();
  }

  paginateLocalWorkers = () => {
    if (this.pagination.page * this.pagination.size >= this.total) {
      this.pagination.page--;
    }
    const start: number = this.pagination.page * this.pagination.size;
    const end: number = (this.pagination.page + 1) * this.pagination.size;
    this.workersList = this.plannerService.workers.slice(start, end);
  }

  addWorker = (worker: CampaignWorker) => {
    this.plannerService.addWorker(worker);
    this.campaignHasChanged.emit();
  };

  removeWorker = (worker: CampaignWorker) => {
    this.plannerService.removeWorker(worker);
    this.campaignHasChanged.emit();

    if (this.workersSection === 'included') {
      this.loadIncludedWorkers();
    }
  }

  loadIncludedWorkers = () => {
    this.workersList = this.plannerService.workers;
    this.total = this.workersList.length;
    this.paginateLocalWorkers();
  }

  parseWorkers = (workers: WorkerReceivedGroup[]): CampaignWorker[] =>
    workers.map(worker => new CampaignWorker({ workerId: worker.id, ...worker}));

  workerHighlight = (worker: CampaignWorker) =>
    this.plannerService.workerIsIncluded(worker.workerId) && this.workersSection === 'search';
  
}