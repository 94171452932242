<form class="pt-3" [formGroup]="form" (submit)="submitForm()">
  <h3 class="add-record">{{ 'workers.detail.historic.NEW_HISTORIC_DATA' | translate }}</h3>
  <!-- Input -->
  <mat-form-field *ngIf="section === HistoricSections.ADDRESSES; else selector" class="col-12 px-0 pb-4" appareance="standard">
    <mat-label for="value">{{ historicTag | translate }}</mat-label>
    <input matInput type="text" name="value" id="value" formControlName="value">
    <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
  </mat-form-field>
  <!-- Selector -->
  <ng-template #selector>
    <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
      <mat-label for="value">{{ historicTag | translate }}</mat-label>
      <mat-select formControlName="value" name="value" id="value">
        <!-- Positions -->
        <ng-container *ngIf="section === HistoricSections.POSITIONS">
          <mat-option *ngFor="let position of positions" [value]="position._id">
            {{ position.name }}
          </mat-option>
        </ng-container>
        <!-- Agencies -->
        <ng-container *ngIf="section === HistoricSections.AGENCIES">
          <mat-option value="">
            {{ 'workers.detail.form.WITHOUT_AGENCY' | translate }}
          </mat-option>
          <mat-option *ngFor="let agency of agencies" [value]="agency._id">
            {{ agency.name }}
          </mat-option>
        </ng-container>
        <!-- Sites -->
        <ng-container *ngIf="section === HistoricSections.SITES">
          <mat-option *ngFor="let site of sites" [value]="site._id">
            {{ site.name }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
  </ng-template>
  <!-- Start date -->
  <mat-form-field class="col-6 pl-0 pb-4" appareance="standard">
    <mat-label for="startDate">{{ 'workers.detail.historic.START_DATE' | translate }}</mat-label>
    <input matInput [matDatepicker]="startDate" name="startDate" id="startDate" formControlName="startDate">
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate></mat-datepicker>
    <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
  </mat-form-field>
  <!-- End date -->
  <mat-form-field class="col-6 pr-0 pb-4" appareance="standard">
    <mat-label for="endDate">{{ 'workers.detail.historic.END_DATE' | translate }}</mat-label>
    <input matInput [matDatepicker]="endDate" name="endDate" id="endDate" formControlName="endDate">
    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate></mat-datepicker>
  </mat-form-field>
  <!-- Actions -->
  <div class="d-flex justify-content-between mt-4">
    <button mat-flat-button color="primary" class="btn" type="button" (click)="cancelEdit()">
      {{ 'general.actions.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="submit" *ngIf="formMode === FormMode.add" [disabled]="form.invalid">
      {{ 'general.actions.ADD' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="submit" *ngIf="formMode === FormMode.edit" [disabled]="form.invalid">
      {{ 'general.actions.EDIT' | translate }}
    </button>
  </div>
</form>
