<form [formGroup]="form" class="form" (submit)="submitForm()">
    <h2 class="h2 title">{{ title | translate }}</h2>
    <section class="form-section mandatory">
        <mat-form-field>
            <mat-label for="name">{{ 'workingSites.form.NAME' | translate }}</mat-label>
            <input matInput type="text" name="name" id="name" formControlName="name"/>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
            <mat-error>{{ 'general.form.MANDATORY_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label for="alias">{{ 'workingSites.form.ALIAS' | translate }}</mat-label>
            <input matInput type="text" name="alias" id="alias" formControlName="alias"/>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
            <mat-error>{{ 'general.form.MANDATORY_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label for="city">{{ 'workingSites.form.CITY' | translate }}</mat-label>
            <input matInput type="text" name="city" id="city" formControlName="city"/>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
            <mat-error>{{ 'general.form.MANDATORY_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label for="abbreviation">{{ 'workingSites.form.ABBREVIATION' | translate }}</mat-label>
            <input matInput type="text" name="abbreviation" id="abbreviation" formControlName="abbreviation"/>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
            <mat-error>{{ 'workingSites.form.ABBREVIATION_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label for="companyId">{{ 'workingSites.form.COMPANY' | translate }}</mat-label>
            <mat-select id="companyId" name="companyId" formControlName="companyId">
                <mat-option *ngFor="let company of companies" [value]="company._id">
                    {{ company.businessName }}
                </mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
            <mat-error>{{ 'general.form.MANDATORY_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'workingSites.form.TYPE' | translate }}</mat-label>
            <mat-select name="type" id="type" formControlName="type">
                <mat-option *ngFor="let type of WORKING_SITE_TYPES; let i = index" value="{{ type.value }}">
                    {{ getFormType(i) | translate }}
                </mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
            <mat-error>{{ 'general.form.MANDATORY_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-row" *ngIf="mode !== FORM_MODES.add">
            <mat-label for="traceCode">{{ 'workingSites.form.TRACE_CODE' | translate }}</mat-label>
            <input matInput type="text" name="traceCode" id="traceCode" formControlName="traceCode" />
            <mat-hint>* {{ 'general.form.NOT_EDITABLE' | translate }}</mat-hint>
        </mat-form-field>
    </section>

    <section class="form-section optional">
        <ng-container formGroupName="location">
            <mat-form-field>
                <mat-label for="latitude">{{ 'workingSites.form.LATITUDE' | translate }}</mat-label>
                <input matInput type="number" name="latitude" id="latitude" formControlName="latitude"/>
            </mat-form-field>
            <mat-form-field>
                <mat-label for="longitude">{{ 'workingSites.form.LONGITUDE' | translate }}</mat-label>
                <input matInput type="number" name="longitude" id="longitude" formControlName="longitude"/>
            </mat-form-field>
        </ng-container>
        <mat-form-field>
            <mat-label for="cadastralRef">{{ 'workingSites.form.CADASTRAL' | translate }}</mat-label>
            <input matInput type="text" name="cadastralRef" id="cadastralRef" formControlName="cadastralRef"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label for="area">{{ 'workingSites.form.AREA' | translate }}</mat-label>
            <input matInput type="text" name="area" id="area" formControlName="area"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label for="enclosure">{{ 'workingSites.form.ENCLOSURE' | translate }}
            </mat-label>
            <input matInput type="text" name="enclosure" id="enclosure" formControlName="enclosure"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label for="plot">{{ 'workingSites.form.PLOT' | translate }}</mat-label>
            <input matInput type="text" name="plot" id="plot" formControlName="plot"/>
        </mat-form-field>
    </section>

    <ul class="form-controls">
        <li>
            <button mat-flat-button [color]="mode !== FORM_MODES.detail ? 'warn' : 'primary'" class="btn" type="button"
                [routerLink]="EXIT_ROUTE">
                {{ (mode === FORM_MODES.detail ? 'general.actions.BACK' : 'general.actions.CANCEL') | translate }}
            </button>
        </li>
        <li>
            <button mat-flat-button color="primary" class="btn" [disabled]="form.invalid || loading"
                *ngIf="mode !== FORM_MODES.detail">
                {{ (mode === FORM_MODES.add ? 'general.actions.ADD' : 'general.actions.EDIT') | translate }}
            </button>
        </li>
    </ul>
</form>