<h2 class="h2 title">{{ 'batchs.tabs.MIXED_BATCHS' | translate }}</h2>
<div class="alert alert-secondary table-description">
    <p class="message">{{ 'batchs.messages.BATCHS_TABLE' | translate }}</p>
</div>
<app-generic-table
    [tableConfig]="tableConfig"
></app-generic-table>
<div class="buttons">
    <app-generic-button
        [config]="exitButtonConfig" (click)="navigateToPreviousLink()"
    ></app-generic-button>
</div>
