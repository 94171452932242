import { BuildService } from './../services/build.service';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

    constructor(
        @Inject(DOCUMENT) readonly document: Document,
        private buildService: BuildService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const url = typeof route.data.url === 'function' ? (await route.data.url(this.buildService)) : route.data.url;

        if (url) {
            this.document.defaultView.open(url, '_self');
        }
        return false;
    }

}
