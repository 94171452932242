import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { Campaign, ICampaignWorkersFile } from '@routes/campaigns/campaign.model';
import { UploadData } from '@shared/interfaces/upload';
import { IDataPaginated, Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private translateService: TranslateService
  ) {}

  private getCampaignsUrl = () => `${this.apiService.getBaseUrl()}/campaigns`;
  private getLiteCampaignsUrl = () => `${this.apiService.getBaseUrl()}/campaigns/lite`;
  private getCampaignUrl = (id: string) => `${this.getCampaignsUrl()}/${id}`;
  private getFinishCampaignUrl = (id: string) => `${this.getCampaignUrl(id)}/finish`;
  private getCampaignWorkersFileUrl = (mode: string, id: string) => `${this.getCampaignUrl(id)}/workers/file/${mode}`;

  getCampaigns = (pagination?: Pagination): Promise<IDataPaginated<Campaign>> =>
    lastValueFrom(this.http.get<IDataPaginated<Campaign>>(this.getCampaignsUrl(), {params: pagination.loadHttpParams()}));

  getLiteCampaigns = (pagination?: Pagination): Promise<IDataPaginated<Campaign>> =>
    lastValueFrom(this.http.get<IDataPaginated<Campaign>>(this.getLiteCampaignsUrl(), {params: pagination.loadHttpParams()}));

  getCampaign = (id: string): Promise<Campaign> =>
    lastValueFrom(this.http.get<Campaign>(this.getCampaignUrl(id)).pipe(map(this.parseCampaign)));

  addCampaign = (campaign: Campaign): Promise<Campaign> =>
    lastValueFrom(this.http.post<Campaign>(this.getCampaignsUrl(), campaign).pipe(map(this.parseCampaign)));

  editCampaign = (campaign: Campaign): Promise<Campaign> =>
    lastValueFrom(this.http.put<Campaign>(this.getCampaignUrl(campaign.id), campaign).pipe(map(this.parseCampaign)));

  addWorkersFromFile = (data: FormData, mode: string, campaignId: string): Promise<UploadData> =>
    lastValueFrom(this.http.post<UploadData>(this.getCampaignWorkersFileUrl(mode, campaignId), data));

  finishCampaign = (_id: string): Promise<Campaign> =>
    lastValueFrom(this.http.patch<Campaign>(this.getFinishCampaignUrl(_id), null).pipe(map(this.parseCampaign)));

  parseCampaign = (campaignObj: any): Campaign => new Campaign({ id: campaignObj._id, ...campaignObj});

  parseFileData = (data: ICampaignWorkersFile): UploadData => ({ data: data.addedWorkers, warnings: data.warnings, error: data.error });

  getCampaignTypeTranslation = (type: string) => this.translateService.instant(`campaigns.main.${type.toUpperCase()}`);

  getDetailQueryParamsData = (queryParams: Params) => {
    const data = {
      startDate: queryParams.startDate ? new Date(queryParams.startDate) : null,
      endDate: queryParams.endDate ? new Date(queryParams.endDate) : null,
      deviceId: queryParams.deviceId || '',
      siteId: queryParams.siteId || '',
    };

    if (Object.values(data).every(data => !data)) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      data.startDate = today;
    }
    return data;
  }

}
