import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Position, PositionDTO, PositionDTOPaginated, PositionPaginated } from '@routes/positions/positions.models';
import { Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PositionsStorageService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  private positionsUrl = () => `${this.apiService.getBaseUrl()}/positions`;

  private positionUrl = (_id: string) => `${this.positionsUrl()}/${_id}`;

  getAll = (pagination?: Pagination): Promise<PositionPaginated> =>
    lastValueFrom(this.http.get<PositionDTOPaginated>(this.positionsUrl(), {params: pagination?.loadHttpParams()}).pipe(
      map(result => new PositionPaginated(result))
    ));

  get = (_id: string): Promise<Position> =>
    lastValueFrom(this.http.get<PositionDTO>(this.positionUrl(_id)).pipe(
      map(position => new Position(position))
    ));

  add = (position: PositionDTO): Promise<Position> =>
    lastValueFrom(this.http.post<PositionDTO>(this.positionsUrl(), position).pipe(
      map(position => new Position(position))
    ));

  edit = (_id: string, position: PositionDTO): Promise<Position> =>
    lastValueFrom(this.http.put<PositionDTO>(this.positionUrl(_id), position).pipe(
      map(position => new Position(position))
    ));

  delete = (_id: string): Promise<void> =>
    lastValueFrom(this.http.delete<void>(this.positionUrl(_id)));

}
