import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BATCH_CATEGORY, BATCH_TYPE } from '@routes/batchs/batchs.constants';
import { IBatchUniquePaginated } from '@routes/batchs/batchs.model';
import { BatchsService } from '@routes/batchs/batchs.service';
import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CropService } from '@routes/crops/crop.service';
import { FilterOperator } from '@shared/components/generic-filters/generic-filters.constants';
import { FiltersConfiguration, FiltersControlDate, FiltersControlOption, FiltersControlSelect } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableElementColor } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnChip, TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { BATCHS_ROUTES } from '@shared/constants/routes';
import { GenericPagination, Pagination } from '@shared/models/pagination.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-campaigns-detail-unique-batchs-table',
  templateUrl: './campaigns-detail-unique-batchs-table.component.html',
  styleUrls: ['./campaigns-detail-unique-batchs-table.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailUniqueBatchsTableComponent implements OnInit {

  tableConfig: TableConfiguration<IBatchUniquePaginated>;
  filtersConfig: FiltersConfiguration;
  campaign: Campaign;

  constructor(
    private plannerService: CampaignPlannerService,
    private batchsService: BatchsService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private cropsService: CropService,
  ) { }

  ngOnInit(): void {
    this.campaign = this.plannerService.campaign;
    this.setupFilters();
    this.setupTable();
    this.setFilterOptions();
  }

  retrieveUniqueBatchs = async (pagination: Pagination) => {
    const data = await this.batchsService.getBatchs(pagination);
    this.tableConfig.data.next(data);
  }

  setFilterOptions = async () => {
    const crops = await this.cropsService.getCrops(new GenericPagination({ sortField: 'name' }));

    const cropOptions = crops.data.map(crop => new FiltersControlOption({
      value: crop._id,
      display: `${crop.name} (${crop.variety})`
    }));
    const siteOptions = this.campaign.sites.map(site => new FiltersControlOption({
      value: site.siteId,
      display: site.siteName
    }));
    this.filtersConfig.updateControlOptions('cropIds', cropOptions);
    this.filtersConfig.updateControlOptions('siteIds', siteOptions);
  }

  setupFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [
      new FiltersControlDate({
        label: 'START_DATE',
        name: 'startDate',
        field: 'datetime',
        value: null,
        operator: FilterOperator.GreaterEqual,
        modifier: date => date.setHours(0, 0, 0, 0),
        maxDateReferenceName: 'endDate',
      }),
      new FiltersControlDate({
        label: 'END_DATE',
        name: 'endDate',
        field: 'datetime',
        value: null,
        operator: FilterOperator.LessEqual,
        modifier: date => date.setHours(23, 59, 59, 999),
        minDateReferenceName: 'startDate',
      }),
      new FiltersControlSelect({
        label: 'SITES',
        name: 'siteIds',
        field: 'siteId',
        operator: FilterOperator.In,
        value: [],
        options: new BehaviorSubject([]),
      }),
      new FiltersControlSelect({
        label: 'CROPS',
        name: 'cropIds',
        field: 'cropId',
        operator: FilterOperator.In,
        value: [],
        options: new BehaviorSubject([]),
      }),
    ],
    buttons: [],
  });

  setupTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1,
      size: 10,
      search: '',
      sort: { field: 'datetime', order: -1 },
      params: { category: BATCH_CATEGORY.UNIQUE, type: this.campaign.type, campaignId: this.campaign.id }
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: batch => batch.code
      }),
      new TableColumnText({
        header: 'PLATE',
        name: 'plate',
        sorting: 'plate',
        displayAt: 1440,
        value: batch => batch.plate
      }),
      new TableColumnText({
        header: 'STATUS',
        name: 'status',
        sorting: 'status',
        displayAt: 520,
        value: batch => this.translateService.instant(`batchs.status.${batch.status.toUpperCase()}`)
      }),
      new TableColumnText({
        header: 'SITE',
        name: 'site',
        sorting: 'site.name',
        displayAt: 1280,
        value: batch => batch.site.name
      }),
      new TableColumnText({
        header: 'CROP',
        name: 'crop',
        sorting: 'crop.name',
        displayAt: 1280,
        value: batch => `${batch.crop.name}`
      }),
      new TableColumnText({
        header: 'PACKAGING',
        name: 'packaging',
        sorting: 'packaging.description',
        displayAt: 1650,
        value: batch => `${batch.packaging.description}`
      }),
      new TableColumnText({
        header: 'DATETIME',
        name: 'datetime',
        sorting: 'datetime',
        displayAt: 960,
        value: batch => this.datePipe.transform(batch.datetime, 'd MMMM, H:mm', 'GMT')
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'action',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: batch => [batch._id, BATCHS_ROUTES.detail]
          })
        ]
      })
    ]
  });

  getChipColor = (batch: IBatchUniquePaginated) => {
    switch (batch.type) {
      case BATCH_TYPE.PLANT: return GenericTableElementColor.Primary;
      case BATCH_TYPE.FRUIT: return GenericTableElementColor.Warn;
      default: return GenericTableElementColor.Accent;
    }
  }
}
