import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { WorkersComponent } from '@routes/workers/workers.component';
import { WorkersMainComponent } from '@routes/workers/workers-main/workers-main.component';
import { WorkersDetailComponent } from '@routes/workers/workers-detail/workers-detail.component';
import { WorkersDetailHistoricComponent } from '@routes/workers/workers-detail/workers-detail-historic/workers-detail-historic.component';
import { WorkersDetailFormComponent } from '@routes/workers/workers-detail/workers-detail-form/workers-detail-form.component';
import { WorkersAddComponent } from '@routes/workers/workers-add/workers-add.component';
import { WorkersDetailHistoricTableComponent } from '@routes/workers/workers-detail/workers-detail-historic/workers-detail-historic-table/workers-detail-historic-table.component';
import { WorkersDetailHistoricFormComponent } from '@routes/workers/workers-detail/workers-detail-historic/workers-detail-historic-form/workers-detail-historic-form.component';
import { WorkersFileUploadComponent } from '@routes/workers/workers-file-upload/workers-file-upload.component';
import { WorkerResolver } from '@routes/workers/worker.resolver';

import { SharedModule } from '@shared/shared.module';
import { WORKER_ROUTES } from '@shared/constants/routes';
import { WorkersActivationDialogComponent } from './workers-main/workers-activation-dialog/workers-activation-dialog.component';

const routes: Routes = [{
  path: '',
  component: WorkersComponent,
  children: [{
    path: '',
    component: WorkersMainComponent,
    pathMatch: 'full'
  }, {
    path: WORKER_ROUTES.add,
    component: WorkersAddComponent
  }, {
    path: WORKER_ROUTES.file,
    component: WorkersFileUploadComponent
  }, {
    path: `${WORKER_ROUTES.edit}/:id`,
    component: WorkersDetailComponent,
    resolve: {
      worker: WorkerResolver
    }
  }, {
    path: `${WORKER_ROUTES.detail}/:id`,
    component: WorkersDetailComponent,
    resolve: {
      worker: WorkerResolver
    }
  }]
}];

@NgModule({
  declarations: [
    WorkersComponent,
    WorkersMainComponent,
    WorkersAddComponent,
    WorkersDetailComponent,
    WorkersDetailFormComponent,
    WorkersDetailHistoricComponent,
    WorkersDetailHistoricTableComponent,
    WorkersDetailHistoricFormComponent,
    WorkersFileUploadComponent,
    WorkersActivationDialogComponent
  ],
  providers: [

  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class WorkersModule { }