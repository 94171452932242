<div class="dispatch-notes-detail-tables">
    <!-- Tables -->
    <h2 class="title h2">{{ 'dispatchNotes.titles.DETAIL' | translate }} ({{ dispatchNote.code }})</h2>
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="{{ 'dispatchNotes.entities.VALID_BATCHS' | translate }}">
            <ng-container *ngIf="!validBatchs.length; else validTable">
                <div class="alert alert-warning">{{ 'dispatchNotes.messages.DISPATCH_NOTES_HAS_NOT_BATCHS' | translate }}</div>
            </ng-container>
            <ng-template #validTable>
                <app-generic-table class="table" [tableConfig]="validBatchsTableConfig"></app-generic-table>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{ 'dispatchNotes.entities.INVALID_BATCHS' | translate }}">
            <ng-container *ngIf="!invalidBatchs.length; else invalidTable">
                <div class="alert alert-success">{{ 'dispatchNotes.messages.WITHOUT_INVALID_BATCHS' | translate }}</div>
            </ng-container>
            <ng-template #invalidTable>
                <div class="alert alert-warning">{{ 'dispatchNotes.messages.INVALID_BATCHS_DESCRIPTION' | translate }}</div>
                <app-generic-table class="table" [tableConfig]="invalidBatchsTableConfig"></app-generic-table>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <!-- Buttons -->
    <div class="buttons">
        <button mat-flat-button color="primary" class="btn" type="button" routerLink="../..">
            {{ 'general.actions.BACK' | translate }}
        </button>
        <button mat-flat-button color="primary" class="btn" type="button" (click)="closeDispatchNote()"
            [disabled]="loading" *ngIf="dispatchNote.status === status.OPEN && dispatchNote.batchs.length">
                {{ 'general.actions.FINISH' | translate }}
        </button>
        <button mat-flat-button color="primary" class="btn" type="button" (click)="downloadDispatchNote()"
            [disabled]="loading || downloadDisabled" *ngIf="dispatchNote.status === status.CLOSED">
                {{ 'general.actions.PRINT_PDF' | translate }}
        </button>
    </div>
</div>
<app-dispatch-notes-detail-description
    class="dispatch-notes-detail-description"
    [dispatchNote]="dispatchNote"
></app-dispatch-notes-detail-description>