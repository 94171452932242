import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { HeaderService } from '@layout/header/header.service';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { CampaignType } from '@routes/campaigns/campaigns.constants';

import { FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableElementColor } from '@shared/components/generic-table/generic-table.constants';
import { Pagination } from '@shared/models/pagination.model';
import { TableColumnChip, TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { ABS_BASE_ROUTE, SECTIONS, CAMPAIGNS_ROUTES } from '@shared/constants/routes';
import { CAMPAIGN_STATUSES } from '@shared/constants/campaigns';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaigns-inactive',
  templateUrl: './campaigns-inactive.component.html',
  styleUrls: ['./campaigns-inactive.component.scss'],
  providers: [DatePipe]
})
export class CampaignsInactiveComponent implements OnInit {

  tableConfig: TableConfiguration<Campaign>;

  constructor(
    private campaignService: CampaignService,
    private headerService: HeaderService,
    private translateService: TranslateService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.headerService.typeFilterChanges().subscribe(type => {
      Object.assign(this.tableConfig.pagination.params, {type});
      this.tableConfig.updatePagination.next(this.tableConfig.pagination as FiltersEvent);
    });
    this.initTable();
    this.getCampaigns(new Pagination(this.tableConfig.pagination));
  }

  getCampaigns = async (pagination: Pagination) => {
    const response = await this.campaignService.getCampaigns(pagination);
    this.tableConfig.data.next(response);
  }

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    data: new BehaviorSubject({ data: null, total: 0 }),
    i18nRoot: 'campaigns.main',
    pagination: {
      page: 1, size: 10, search: '',
      sort: {field: 'startDate', order: -1},
      params: {
        status: CAMPAIGN_STATUSES.FINISHED,
        type: this.headerService.typeFilter
      }
    },
    columns: [
      new TableColumnText({
        header: 'NAME',
        name: 'name',
        sorting: 'name',
        displayAt: 0,
        value: campaign => campaign.name
      }),
      new TableColumnText({
        header: 'START',
        name: 'startDate',
        sorting: 'startDate',
        displayAt: 0,
        value: campaign => this.datepipe.transform(campaign.startDate, 'longDate')
      }),
      new TableColumnText({
        header: 'END',
        name: 'endDate',
        sorting: 'endDate',
        displayAt: 0,
        value: campaign => this.datepipe.transform(campaign.endDate, 'longDate')
      }),
      new TableColumnChip({
        header: 'TYPE',
        name: 'type',
        displayAt: 0,
        value: campaign => this.translateService.instant(`general.types.${campaign.type.toUpperCase()}`),
        color: campaign => this.getTypeColor(campaign)
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: campaign => [ABS_BASE_ROUTE, SECTIONS.production, CAMPAIGNS_ROUTES.parent, CAMPAIGNS_ROUTES.detail, campaign._id]
          })
        ]
      })
    ]
  });

  getTypeColor = (campaign: Campaign) => campaign.type === CampaignType.Fruit
    ? GenericTableElementColor.Warn
    : GenericTableElementColor.Primary;

}
