import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    refreshingToken: boolean = false;

    constructor(
        private authenticationService: AuthService,
        private translate: TranslateService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: this.getHeaders(request)
        });

        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
                if (request.url === this.authenticationService.getLoginUrl()) {
                    return throwError(error);
                }
                if (!this.refreshingToken) {
                    this.refreshingToken = true;
                    return this.authenticationService.regenerateToken().pipe(switchMap(() => {
                        this.refreshingToken = false;
                        request = request.clone({
                            setHeaders: this.getHeaders(request)
                        });
                        return next.handle(request);
                    }), (catchError(error => {
                        this.refreshingToken = false;
                        this.authenticationService.logOut();
                        return throwError(error);
                    })));
                }
            }
            return next.handle(request);
        }));
    }

    getHeaders(request: HttpRequest<any>) {
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': this.translate.currentLang
        };
        if (this.authenticationService.isAuthenticated()) {
            headers['Authorization'] = this.authenticationService.getToken();
        }
        if (request.body instanceof FormData) delete headers['Content-Type'];
        return headers;
    }
}