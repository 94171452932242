import { MatCheckboxChange } from '@angular/material/checkbox';
import { FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { GenericTableBehaviour, GenericTableElementColor, GenericTableColumnAlign, GenericTableColumnStyle, GenericTableColumnType, GenericMenuOptionIcon, TableQueryParam } from '@shared/components/generic-table/generic-table.constants';

interface IGenericTableConfig<T> {
    behaviour: GenericTableBehaviour;
    data: BehaviorSubject<IGenericTableData>;
    columns: IGenericTableColumnType<T>[];
    pagination: IGenericTablePagination;
    reload?: Subject<void>;
    updatePagination?: Subject<FiltersEvent>;
    i18nRoot?: string;
};

export class TableConfiguration<T> implements IGenericTableConfig<T> {
    behaviour: GenericTableBehaviour;
    data: BehaviorSubject<IGenericTableData>;
    columns: IGenericTableColumnType<T>[];
    pagination: IGenericTablePagination;
    reload?: Subject<void> = new Subject<any>();
    updatePagination?: Subject<FiltersEvent> = new Subject<FiltersEvent>();
    i18nRoot?: string;

    constructor(config: IGenericTableConfig<T>) {
        Object.assign(this, config);
    }
};

export interface IGenericTableData {
    data: any[];
    total: number;
};

export interface IGenericTablePagination {
    size: number;
    page: number;
    search: string;
    params?: any;
    sort: {
        field: string;
        order: number;
    };
};

const validDisplayAtWidths = [0, 400, 520, 640, 768, 860, 960, 1024, 1280, 1366, 1440, 1650, 1920] as const;
export type validDisplayAtWidth = typeof validDisplayAtWidths[number];

interface ITableColumn<T> {
    name: string;
    header: string;
    displayAt: validDisplayAtWidth;
    style?: GenericTableColumnStyle;
    align?: GenericTableColumnAlign;
};

interface ITableColumnText<T> extends ITableColumn<T> {
    value: (entity: T) => string | number;
    sorting?: string;
};

interface ITableColumnChip<T> extends ITableColumnText<T> {
    color?: (entity: T) => string;
}

interface ITableColumnCheckbox<T> extends ITableColumn<T> {
    color: GenericTableElementColor;
    checked: (entity: T) => boolean;
    disabled: (entity: T) => boolean;
    onChange?: (entity: T, event: MatCheckboxChange) => void;
    intermediate?: boolean;
};

interface ITableColumnSelector<T> {
    name: string;
    color: GenericTableElementColor;
    displayAt: validDisplayAtWidth;
    selectionProperty: string;
    
};


interface ITableColumnMenu<T> extends ITableColumn<T> {
    options: ITableMenuOption<T>[];
};

interface ITableMenuOption<T> {
    text: string;
    icon: GenericMenuOptionIcon;
    link?: (entity?: T) => string[];
    queryParams?: TableQueryParam;
    action?: (entity?: T) => void;
    disabled?: (entity?: T) => boolean;
};

export class TableColumn<T> implements ITableColumn<T> {
    name: string;
    header: string;
    displayAt: validDisplayAtWidth;
    value?: (entity: T) => string | number;
    style?: GenericTableColumnStyle;
    align?: GenericTableColumnAlign;

    constructor(config: ITableColumn<T>) {
        Object.assign(this, config);
    }
};

export class TableColumnText<T> extends TableColumn<T> implements ITableColumnText<T> {
    type = GenericTableColumnType.Text;
    value: (entity: T) => string | number;
    sorting?: string;

    constructor(config: ITableColumnText<T>) {
        super(config);
        Object.assign(this, config);
    }
};

export class TableColumnChip<T> extends TableColumnText<T> {
    type = GenericTableColumnType.Chip;
    color: (entity: T) => string;

    constructor(config: ITableColumnChip<T>) {
        super(config);
        Object.assign(this, config);
    }
}

export class TableColumnMenu<T> extends TableColumn<T> implements ITableColumnMenu<T> {
    type = GenericTableColumnType.Menu;
    style = GenericTableColumnStyle.Compact;
    align = GenericTableColumnAlign.Center;
    options: TableMenuOption<T>[];

    constructor(config: ITableColumnMenu<T>) {
        super(config);
        Object.assign(this, config);
    }
};

export class TableMenuOption<T> implements ITableMenuOption<T> {
    text: string;
    icon: GenericMenuOptionIcon;
    link?: (entity?: T) => string[];
    queryParams?: TableQueryParam;
    action?: (entity?: T) => void;
    disabled?: (entity?: T) => boolean;

    constructor(config: ITableMenuOption<T>) {
        Object.assign(this, config);
    }
};

export class TableColumnCheckbox<T> extends TableColumn<T> implements ITableColumnCheckbox<T> {
    type = GenericTableColumnType.Checkbox;
    color: GenericTableElementColor;
    checked: (entity: T) => boolean;
    disabled: (entity: T) => boolean;
    onChange?: (entity: T, event: MatCheckboxChange) => void;
    intermediate?: boolean;

    constructor(config: ITableColumnCheckbox<T>) {
        super(config);
        Object.assign(this, config);
    }

};

export class TableColumnSelector<T> implements ITableColumnSelector<T> {
    type = GenericTableColumnType.Selector;
    name: string;
    color: GenericTableElementColor;
    displayAt: validDisplayAtWidth;
    selectionProperty: string;

    constructor(config: ITableColumnSelector<T>) {
        Object.assign(this, config);
    }

};

export type IGenericTableColumnType<T> = ITableColumnText<T> | ITableColumnCheckbox<T> | ITableColumnMenu<T> | ITableColumnSelector<T>;
