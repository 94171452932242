import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ItemReceived } from '@routes/items/items.model';
import { ItemsService } from '@routes/items/items.service';

import { Pagination } from '@shared/models/pagination.model';
import { ABS_BASE_ROUTE, ITEMS_ROUTES, SECTIONS } from '@shared/constants/routes';
import { WeightUnit } from '@shared/constants/common.constants';
import { TableColumnText, TableConfiguration } from '@shared/components/generic-table/generic-table.model';
import { GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { FiltersButtonLink, FiltersConfiguration } from '@shared/components/generic-filters/generic-filters.model';

@Component({
  selector: 'app-items-table',
  templateUrl: './items-table.component.html',
  styleUrls: ['./items-table.component.scss']
})
export class ItemsTableComponent implements OnInit {

  tableConfig: TableConfiguration<ItemReceived>;
  filtersConfig: FiltersConfiguration;

  constructor(
    private itemsService: ItemsService
  ) {}

  ngOnInit(): void {
    this.initFilters();
    this.initTable();
  }

  getItems = async (pagination: Pagination) => {
    const response = await this.itemsService.getItems(pagination);
    this.tableConfig.data.next(response);
  }

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    buttons: [
      new FiltersButtonLink({
        icon: 'folder',
        text: 'UPLOAD_FILE',
        link: [
          ABS_BASE_ROUTE, SECTIONS.production,
          ITEMS_ROUTES.parent, ITEMS_ROUTES.upload]
      })
    ],
    controls: []
  });

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'items.table',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1,
      size: 10,
      search: '',
      sort: { field: 'description', order: 1 },
      params: { deleted: false }
    },
    columns: [
      new TableColumnText({
        header: 'SAGE_REFERENCE',
        name: 'sageReference',
        sorting: 'sageReference',
        displayAt: 0,
        value: item => item.sageReference,
      }),
      new TableColumnText({
        header: 'DESCRIPTION',
        name: 'description',
        sorting: 'description',
        displayAt: 640,
        value: item => item.description,
      }),
      new TableColumnText({
        header: 'FAMILY',
        name: 'category',
        sorting: 'category',
        displayAt: 960,
        value: item => item.category
      }),
      new TableColumnText({
        header: 'SUBFAMILY',
        name: 'subcategory',
        sorting: 'subcategory',
        displayAt: 1024,
        value: item => item.subcategory,
      }),
      new TableColumnText({
        header: 'GROSS_WEIGHT',
        name: 'grossWeight',
        sorting: 'weight.gross',
        displayAt: 1280,
        value: item => `${item.weight.gross} ${WeightUnit.Kilogram}`
      }),
      new TableColumnText({
        header: 'NET_WEIGHT',
        name: 'netWeight',
        sorting: 'weight.net',
        displayAt: 1280,
        value: item => `${item.weight.net} ${WeightUnit.Kilogram}`
      })
    ]
  });

}
