<div class="d-flex flex-column flex-sm-row align-items-center align-items-sm-start"
    [ngClass]="{'justify-content-between': workersSection === 'search', 'justify-content-end': workersSection === 'included'}">
    <app-search
        class="flex-shrink-1 pr-3" *ngIf="workersSection === 'search'" (evtSearch)="searchWorkers($event)">
    </app-search>
    <mat-form-field class="select pl-3" appearance="fill">
        <mat-label for="workersSection">{{ 'workers.detail.selector.SELECTOR' | translate }}</mat-label>
        <mat-select [value]="workersSections[0].value" (selectionChange)="displayWorkersSection($event)" name="workersSection" id="workersSection">
            <mat-option *ngFor="let workersSection of workersSections" [value]="workersSection.value">
                {{ 'campaigns.workers.' + workersSection.tag | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>    
</div>
<div class="d-flex flex-column align-itemd-stretch">
    <ng-container *ngIf="workersList && workersList.length; else noWorkers">
        <table class="table" mat-table [dataSource]="workersList">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="name">
                    {{ 'campaigns.workers.NAME' | translate }}
                </th>
                <td mat-cell *matCellDef="let worker" class="name" [ngClass]="{ 'included': workerHighlight(worker) }">
                    {{ worker.name }} {{ worker.surname }}
                </td>
            </ng-container>
            <ng-container matColumnDef="sequentialId">
                <th mat-header-cell *matHeaderCellDef class="sequentialId">
                    {{ 'campaigns.workers.SEQUENTIAL_ID' | translate }}
                </th>
                <td mat-cell *matCellDef="let worker" class="sequentialId" [ngClass]="{ 'included': workerHighlight(worker) }">
                    {{ worker.sequentialId }}
                </td>
            </ng-container>
            <ng-container matColumnDef="nif">
                <th mat-header-cell *matHeaderCellDef class="nif">
                    {{ 'campaigns.workers.NIF' | translate }}
                </th>
                <td mat-cell *matCellDef="let worker" class="nif" [ngClass]="{ 'included': workerHighlight(worker) }">
                    {{ worker.nif }}
                </td>
            </ng-container>
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef class="position">
                    {{ 'campaigns.workers.POSITION' | translate }}
                </th>
                <td mat-cell *matCellDef="let worker" class="position" [ngClass]="{ 'included': workerHighlight(worker) }">
                    {{ worker.position?.name | translate }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="table-actions actions">
                    {{ 'campaigns.workers.ACTIONS' | translate }}
                </th>
                <td mat-cell *matCellDef="let worker" class="table-actions actions" [ngClass]="{'included': workerHighlight(worker) }">
                    <ng-container *ngIf="!plannerService.workerIsIncluded(worker.workerId); else workerNotIncluded">
                        <button class="action-btn" (click)="addWorker(worker)">
                            <img class="action-img" src="/assets/images/icons/add-user.svg" alt="{{ 'general.alt.ADD' | translate }}"/>
                        </button>
                    </ng-container>
                    <ng-template #workerNotIncluded>
                        <button class="action-btn" (click)="removeWorker(worker)">
                            <img class="action-img" src="/assets/images/icons/delete.svg" alt="{{ 'general.alt.DELETE' | translate }}"/>
                        </button>
                    </ng-template>
                </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns" class="compressed"></tr>
        </table>
        <mat-paginator
            [length]="total" [pageSize]="pagination.size" [pageSizeOptions]="[10, 25, 50, 100]"
            (page)="paginateWorkers($event)">
        </mat-paginator>
    </ng-container>
    <ng-template #noWorkers>
        <div *ngIf="workersSection === 'search'" class="alert alert-secondary" role="alert">
            {{ 'campaigns.workers.NOT_WORKERS_FOUND' | translate }}
        </div>
        <div *ngIf="workersSection === 'included'" class="alert alert-secondary" role="alert">
            {{ 'campaigns.workers.NOT_WORKERS_INCLUDED' | translate }}
        </div>
    </ng-template>
</div>