<form [formGroup]="form" (submit)="submitForm()">
    <h2 class="title h2">{{ title }}</h2>
    <mat-form-field class="input" appearance="standard" *ngIf="mode !== FORM_MODES.delete">
        <mat-label>{{ 'devices.dialog.NAME' | translate }}</mat-label>
        <input matInput formControlName="name" type="text" name="name" id="name" [value]="device.name">
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    
    <mat-form-field class="input" appearance="standard" *ngIf="mode === FORM_MODES.edit">
        <mat-label>{{ 'devices.dialog.APP_VERSION' | translate }}</mat-label>
        <input matInput formControlName="appVersion" type="text" name="appVersion" id="appVersion">
        <mat-hint>* {{ 'general.form.NOT_EDITABLE' | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field class="input" appearance="standard" *ngIf="mode !== FORM_MODES.delete">
        <mat-label>{{ 'devices.dialog.COUNTRY' | translate }}</mat-label>
        <mat-select formControlName="country">
            <mat-option *ngFor="let country of countries" [value]="country.code">
                {{ country.name }}
            </mat-option>
        </mat-select>
        <mat-hint>
            <ng-container [ngSwitch]="mode">
                <span *ngSwitchCase="FORM_MODES.add">* {{ 'general.form.MANDATORY' | translate }}</span>
                <span *ngSwitchCase="FORM_MODES.edit">* {{ 'general.form.NOT_EDITABLE' | translate }}</span>
            </ng-container>
        </mat-hint>
    </mat-form-field>

    <mat-form-field class="input" appearance="standard" *ngIf="mode !== FORM_MODES.delete">
        <mat-label>{{ 'devices.dialog.ROLE' | translate }}</mat-label>
        <mat-select formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role">{{ 'general.roles.' + role.toUpperCase() | translate }}</mat-option>
        </mat-select>
        <mat-hint>
            <ng-container [ngSwitch]="mode">
                <span *ngSwitchCase="FORM_MODES.add">* {{ 'general.form.MANDATORY' | translate }}</span>
                <span *ngSwitchCase="FORM_MODES.edit">* {{ 'general.form.NOT_EDITABLE' | translate }}</span>
            </ng-container>
        </mat-hint>
    </mat-form-field>

    <mat-form-field class="input" appearance="standard" *ngIf="form.getRawValue().role === DeviceRole.Harvest">
        <mat-label>{{ 'devices.dialog.CAMPAIGN' | translate }}</mat-label>
        <mat-select formControlName="campaignId">
            <mat-option *ngFor="let campaign of campaigns" [value]="campaign._id">{{ campaign.name }}</mat-option>
        </mat-select>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field class="input" appearance="standard" *ngIf="form.getRawValue().role === DeviceRole.Warehouse">
        <mat-label>{{ 'devices.dialog.WAREHOUSE' | translate }}</mat-label>
        <mat-select formControlName="warehouseId">
            <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{ warehouse.name }}</mat-option>
        </mat-select>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>

    <mat-checkbox color="primary" formControlName="confirm" *ngIf="mode === FORM_MODES.delete">
        {{ 'devices.dialog.CONFIRM_DELETE' | translate }}
    </mat-checkbox>
    
    <mat-dialog-actions class="buttons" [ngClass]="{ 'deletion': mode === FORM_MODES.delete }">
        <app-generic-button
            [config]="cancelButtonConfig" mat-dialog-close
        ></app-generic-button>
        <app-generic-button
            [config]="submitButtonConfig" [disabled]="form.invalid || loading"
        ></app-generic-button>
    </mat-dialog-actions>
</form>
