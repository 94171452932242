import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

import { UsersComponent } from './users.component';
import { UsersMainComponent } from './users-main/users-main.component';
import { UsersEditComponent } from './users-edit/users-edit.component';
import { UsersDeleteDialogComponent } from './users-delete-dialog/users-delete-dialog.component';
import { UsersPasswordDialogComponent } from './users-password-dialog/users-password-dialog.component';
import { UsersDisableDialogComponent } from './users-disable-dialog/users-disable-dialog.component';

import { USERS_ROUTES } from '@shared/constants/routes';
import { UserEditResolver } from './user.resolver';

const routes: Routes = [
    {
        path: '',
        component: UsersComponent,
        children: [
            {
                path: '',
                component: UsersMainComponent,
                pathMatch: 'full'
            },
            {
                path: USERS_ROUTES.add,
                component: UsersEditComponent,
                data: { mode: USERS_ROUTES.add }
            },
            {
                path: `${USERS_ROUTES.edit}/:id`,
                component: UsersEditComponent,
                data: { mode: USERS_ROUTES.edit },
                resolve: {
                    user: UserEditResolver
                }
            },
            {
                path: `${USERS_ROUTES.detail}/:id`,
                component: UsersEditComponent,
                data: { mode: USERS_ROUTES.detail },
                resolve: {
                    user: UserEditResolver
                }
            }
        ]
    }
];

@NgModule({
    declarations: [
        UsersComponent,
        UsersMainComponent,
        UsersEditComponent,
        UsersDeleteDialogComponent,
        UsersPasswordDialogComponent,
        UsersDisableDialogComponent
    ],
    providers: [

    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class UsersModule {}
