import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WorkingDay } from '@shared/models/working-day.model';
import { WorkingDaysMassEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/working-days-mass-edit.service';

@Component({
  selector: 'app-campaigns-detail-working-days-mass-edit',
  templateUrl: './campaigns-detail-working-days-mass-edit.component.html',
  styleUrls: ['./campaigns-detail-working-days-mass-edit.component.scss'],
  providers: [DatePipe, WorkingDaysMassEditService]
})
export class CampaignsDetailWorkingDaysMassEditComponent implements OnInit {

  selectedWorkingDays: Map<string, WorkingDay> = new Map();
  form: FormGroup;
  
  constructor(
    private massEditService: WorkingDaysMassEditService
    ) { }


  ngOnInit(): void {
    this.setupForm();
    this.massEditService.selectedWorkingDaysChanges().subscribe(selected => {
      this.selectedWorkingDays = selected;
    })

  }

  setupForm = () => {
    this.form = new FormGroup({});
    this.massEditService.formChanges().subscribe(form => {
      this.form = form;
    })
  }

}





