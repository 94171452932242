import { Component, OnInit, Inject } from '@angular/core';
import { SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {

  TYPES = SNACKBAR_TYPES;
  iconClass: string;
  snackbarClass: string;
  iconClasses = {
    [SNACKBAR_TYPES.SUCCESS]: 'fa-check-circle',
    [SNACKBAR_TYPES.WARNING]: 'fa-exclamation-triangle',
    [SNACKBAR_TYPES.ERROR]: 'fa-times-circle',
    [SNACKBAR_TYPES.INFO]: 'fa-info-circle',
    [SNACKBAR_TYPES.IN_PROGRESS]: 'fa-spinner fa-spin'
  };
  snackBarClasses = {
    [SNACKBAR_TYPES.SUCCESS]: 'snack-bar-success',
    [SNACKBAR_TYPES.WARNING]: 'snack-bar-warning',
    [SNACKBAR_TYPES.ERROR]: 'snack-bar-error',
    [SNACKBAR_TYPES.INFO]: 'snack-bar-info',
    [SNACKBAR_TYPES.IN_PROGRESS]: 'snack-bar-progress'
  };
  baseFa = 'fa fa-3x';

  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { type: string, message: string, action: string }
  ) { }

  ngOnInit(): void {
    this.iconClass = this.getIconClass();
    this.snackbarClass = this.snackBarClasses[this.data.type];
  }

  private getBaseFaClass = (cssClass: string): string => `${this.baseFa} ${cssClass}`;

  getIconClass = () => this.iconClasses[this.data.type] ? this.getBaseFaClass(this.iconClasses[this.data.type]) : '';

}
