import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BATCH_CATEGORY, BATCH_UNIT_PER_TYPE } from '@routes/batchs/batchs.constants';
import { IBatchType, IBatchUniqueReceived } from '@routes/batchs/batchs.model';

@Component({
  selector: 'app-batchs-detail-summary-description',
  templateUrl: './batchs-detail-summary-description.component.html',
  styleUrls: ['./batchs-detail-summary-description.component.scss']
})
export class BatchsDetailSummaryDescriptionComponent implements OnInit {

  @Input() batch: IBatchType;

  BATCH_CATEGORY = BATCH_CATEGORY;
  category: BATCH_CATEGORY;
  unit: string;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.category = this.activatedRoute.snapshot.data.category;

    if (this.category === BATCH_CATEGORY.UNIQUE) {
      const batch = <IBatchUniqueReceived>this.batch;
      this.unit = BATCH_UNIT_PER_TYPE[batch.type];
    }
  }

}
