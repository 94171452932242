import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { saveAs } from 'file-saver';

import { IWorkerReceivedGroup } from '@routes/workers/worker.model';
import { PositionsMainService } from '@routes/positions/positions-main.service';
import { WorkerService } from '@routes/workers/worker.service';
import { WorkerActivationMode, WorkerStatus, WorkerTranslation } from '@routes/workers/workers.constants';
import { WorkersActivationDialogComponent } from '@routes/workers/workers-main/workers-activation-dialog/workers-activation-dialog.component';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';

import { ABS_BASE_ROUTE, SECTIONS, WORKER_ROUTES } from '@shared/constants/routes';
import { IGenericTablePagination, TableColumnCheckbox, TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { FiltersButtonLink, FiltersConfiguration, FiltersControlOption, FiltersControlSelect, FiltersButtonAction } from '@shared/components/generic-filters/generic-filters.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableElementColor } from '@shared/components/generic-table/generic-table.constants';
import { Pagination } from '@shared/models/pagination.model';
import { DeleteDialogComponent } from '@shared/widgets/delete-dialog/delete-dialog.component';
import { AgencyService } from '@routes/agencies/agency.service';
import { CountryService } from '@shared/services/country.service';
import { TranslationUtils } from '@shared/utils/translation.utils';

@Component({
  selector: 'app-workers-main',
  templateUrl: './workers-main.component.html',
  styleUrls: ['./workers-main.component.scss']
})
export class WorkersMainComponent extends TranslationUtils implements OnInit {

  tableConfig: TableConfiguration<IWorkerReceivedGroup>;
  filtersConfig: FiltersConfiguration;
  disableExport = false;
  loading = false;

  constructor(
    private positionsMainService: PositionsMainService,
    private workerService: WorkerService,
    private siteService: WorkingSiteService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private agencyService: AgencyService,
    private countryService: CountryService,
    private dialog: MatDialog,
  ) {
    super(translateService);
  }

  ngOnInit(): void {
    this.initFilters();
    this.initTable();
    this.retrieveFiltersData();
  }

  getWorkers = (pagination: Pagination) => {
    this.workerService.getWorkers(pagination)
      .then(response => {
        this.tableConfig.data.next({ data: response.workers, total: response.total });
        this.disableExport = !response.total;
      })
      .catch(error => this.snackbarService.error(error.error.message));
  }

  retrieveFiltersData = async () => {
    const {positions} = await this.positionsMainService.getAll(new Pagination({sort: {field: 'name', order: 1 }, params: {deleted: false}}));
    this.filtersConfig.updateControlOptions('positionIds',
      positions.map(position => new FiltersControlOption({
        value: position._id,
        display: position.name
      })));

    const {sites} = (await this.siteService.getSites(new Pagination({sort: {field: 'name', order: 1}})));
    this.filtersConfig.updateControlOptions('siteIds',
      sites.map(site => new FiltersControlOption({
        value: site._id,
        display: site.name
      })));

    const agencies = (await this.agencyService.getAgencies()).data;
    this.filtersConfig.updateControlOptions('agencyIds',
      agencies.map(agency => new FiltersControlOption({
        value: agency._id,
        display: agency.name
      })));

    this.filtersConfig.updateControlOptions('statuses',
      Object.values(WorkerStatus).map(status => new FiltersControlOption({
        value: status,
        display: this.translateService.instant(`workers.status.${status.toUpperCase()}`)
      })));

    this.filtersConfig.updateControlOptions('country',
      this.countryService.getCountries().map(({code, name}) => new FiltersControlOption({value: code, display: name})));
  }

  downloadTemplate = () => {
    this.loading = true;
    const texts = this.translate([WorkerTranslation.Template]);
    this.snackbarService.loading(this.translateService.instant(texts.CREATING_FILE));
    
    this.workerService.downloadTemplate()
      .then(blob => {
        saveAs(blob, this.translateService.instant(texts.FILE_NAME));
        const message = this.translateService.instant(texts.DOWNLOAD_SUCCESSFUL);
        this.snackbarService.success(message);
      })
      .catch (error => {
        const i18n = error.status !== 500 ? error.error.message : texts.DOWNLOAD_ERROR;
        this.snackbarService.error(this.translateService.instant(i18n));
      })
      .finally(() => this.loading = false);
  }

  exportWorkers = (tablePagination: IGenericTablePagination) => {
    this.loading = true;
    const pagination = new Pagination({ params: tablePagination.params });
    this.snackbarService.loading(this.translateService.instant('workers.export.CREATING_FILE'));

    this.workerService.downloadWorkers(pagination).then(blob => {
      saveAs(blob, this.translateService.instant('workers.export.FILE_NAME'));
      const message = this.translateService.instant('workers.export.DOWNLOAD_SUCCESSFUL');
      this.snackbarService.success(message);
    })
      .catch((error) => {
        const i18n = error.status === 404 ? 'NOT_WORKERS_FOUND' : 'DOWNLOAD_ERROR';
        this.snackbarService.error(this.translateService.instant(`workers.export.${i18n}`));
      })
      .finally(() => this.loading = false);
  }

  displayActivationDialog = (worker: IWorkerReceivedGroup, mode: WorkerActivationMode) =>
    this.dialog.open(WorkersActivationDialogComponent, {
      data: {worker, mode}, width: '420px'}
    ).afterClosed().pipe(
      filter(value => value?.reload)
    ).subscribe(() => this.tableConfig.reload.next());

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [
      new FiltersControlSelect({
        label: 'POSITIONS',
        name: 'positionIds',
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'SITES',
        name: 'siteIds',
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'AGENCIES',
        name: 'agencyIds',
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'WORK_COUNTRY',
        name: 'country',
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'STATUS',
        name: 'statuses',
        value: [WorkerStatus.Active],
        options: new BehaviorSubject([])
      })
    ],
    buttons: [
      new FiltersButtonLink({
        text: 'ADD',
        icon: 'add',
        link: [ABS_BASE_ROUTE, SECTIONS.workforce, WORKER_ROUTES.parent, WORKER_ROUTES.add],
        disabled: () => this.loading
      }),
      new FiltersButtonAction({
        text: 'TEMPLATE',
        icon: 'excel',
        action: () => this.downloadTemplate(),
        disabled: () => this.loading
      }),
      new FiltersButtonLink({
        text: 'UPLOAD_FILE',
        icon: 'folder',
        link: [ABS_BASE_ROUTE, SECTIONS.workforce, WORKER_ROUTES.parent, WORKER_ROUTES.file],
        disabled: () => this.loading
      }),
      new FiltersButtonAction({
        text: 'EXPORT_EXCEL',
        icon: 'download',
        action: () => this.exportWorkers(this.tableConfig.pagination),
        disabled: () => this.disableExport || this.loading
      })
    ]
  });

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'workers.table',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      size: 10, page: 1, search: '',
      sort: {field: 'name', order: 1},
      params: {deleted: false}
    },
    columns: [
      new TableColumnText({
        header: 'NAME',
        name: 'name',
        sorting: 'name',
        displayAt: 0,
        value: worker => `${worker.name} ${worker.surname}`
      }),
      new TableColumnText({
        header: 'SEQUENTIAL_ID',
        name: 'sequentialId',
        sorting: 'sequentialId',
        displayAt: 640,
        value: worker => worker.sequentialId
      }),
      new TableColumnText({
        header: 'ALPHANUMERIC_CODE',
        name: 'alphanumericCode',
        sorting: 'alphanumericCode',
        displayAt: 520,
        value: worker => worker.alphanumericCode
      }),
      new TableColumnText({
        header: 'KEYCHAIN',
        name: 'keychain',
        sorting: 'keychain',
        displayAt: 1024,
        value: worker => worker.keychain?.code
      }),
      new TableColumnText({
        header: 'NIF',
        name: 'nif',
        sorting: 'nif',
        displayAt: 1024,
        value: worker => worker.nif
      }),
      new TableColumnText({
        header: 'WORK_COUNTRY',
        name: 'country',
        sorting: 'country',
        displayAt: 1440,
        value: worker => this.countryService.getCountryNameByCode(worker.country)
      }),
      new TableColumnText({
        header: 'SITE',
        name: 'site',
        sorting: 'site.name',
        displayAt: 1440,
        value: worker => worker.site?.name
      }),
      new TableColumnText({
        header: 'POSITION',
        name: 'position',
        sorting: 'position',
        displayAt: 1280,
        value: worker => worker.position?.name
      }),
      new TableColumnText({
        header: 'AGENCY',
        name: 'agency',
        sorting: 'agency',
        displayAt: 1650,
        value: worker => worker.agency ? worker.agency.name : this.translateService.instant('workers.table.WITHOUT_AGENCY')
      }),
      new TableColumnCheckbox({
        header: 'ACTIVE',
        name: 'status',
        displayAt: 400,
        color: GenericTableElementColor.Primary,
        disabled: () => true,
        checked: worker => worker.status === WorkerStatus.Active ? true : false
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: worker => [ABS_BASE_ROUTE, SECTIONS.workforce, WORKER_ROUTES.parent, WORKER_ROUTES.detail, worker.id]
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            link: worker => [ABS_BASE_ROUTE, SECTIONS.workforce, WORKER_ROUTES.parent, WORKER_ROUTES.edit, worker.id]
          }),
          new TableMenuOption({
            text: 'ACTIVATE',
            icon: GenericMenuOptionIcon.Person,
            action: worker => this.displayActivationDialog(worker, WorkerActivationMode.Activate),
            disabled: worker => worker.status === WorkerStatus.Active
          }),
          new TableMenuOption({
            text: 'DEACTIVATE',
            icon: GenericMenuOptionIcon.PersonOff,
            action: worker => this.displayActivationDialog(worker, WorkerActivationMode.Deactivate),
            disabled: worker => worker.status === WorkerStatus.Inactive
          }),
          new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: worker => this.dialog.open(DeleteDialogComponent, {
              data: {
                title: 'workers.delete.TITLE',
                msg: this.translateService.instant('workers.delete.BODY', {
                  name: worker.name,
                  surname: worker.surname,
                  nif: worker.nif ? ' - ' + worker.nif : ''
                })
              },
              width: '520px'
            }).afterClosed().subscribe((result: any) =>
              result?.confirmDelete
              && this.workerService.deleteWorker(worker._id)
                .then(() => this.getWorkers(this.tableConfig.pagination as Pagination)))
          })
        ]
      })
    ]
  });

}
