<mat-tab-group animationDuration="0ms">
    <mat-tab label="{{ 'batchs.tabs.BATCH_DETAIL' | translate }}">
        <app-batchs-detail-summary
            [batch]="batch"
        ></app-batchs-detail-summary>
    </mat-tab>
    <mat-tab label="{{ 'batchs.tabs.USED_INTERVALS' | translate }}" *ngIf="category === BATCH_CATEGORY.UNIQUE">
        <app-batchs-detail-intervals
            [intervals]="intervals"
        ></app-batchs-detail-intervals>
    </mat-tab>
    <mat-tab label="{{ 'batchs.tabs.MIXED_BATCHS' | translate }}" *ngIf="category === BATCH_CATEGORY.UNIQUE">
        <app-batchs-detail-mixed-batchs
            [batch]="batch"
        ></app-batchs-detail-mixed-batchs>
    </mat-tab>
    <mat-tab label="{{ 'batchs.tabs.USED_BATCHS' | translate }}" *ngIf="category === BATCH_CATEGORY.MIXED">
        <app-batchs-detail-unique-batchs
            [batch]="batch"
        ></app-batchs-detail-unique-batchs>
    </mat-tab>
    <mat-tab label="{{ 'batchs.tabs.OUTPUT_ITEMS' | translate }}" *ngIf="category === BATCH_CATEGORY.MIXED">
        <app-batchs-detail-items
            [batch]="batch">
        </app-batchs-detail-items>
    </mat-tab>
</mat-tab-group>