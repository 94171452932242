export interface IKeychainPaginated {
    keychains: Keychain[];
    total: number;
}

export interface IKeychainWorker {
    _id: string;
    name: string;
    surname: string;
    nif: string;
    sequentialId: number;
}

export interface IKeychain {
    id: string;
    _id?: string;
    code: string;
    tag: string;
    worker?: IKeychainWorker;
}

export class Keychain implements IKeychain {
    id: string;
    _id?: string;
    code: string;
    tag: string;
    worker?: IKeychainWorker;

    constructor(keychain: IKeychain) {
        Object.assign(this, keychain);
    }
}

export interface IKeychainFileResponse {
    createdKeychains: Keychain[];
    warnings: { msg: string }[];
    error: { msg: string }[];
}