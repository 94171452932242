import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import { IUsersPaginated, User } from './user.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  private getUsersUrl = () => `${this.apiService.getBaseUrl()}/users`;
  private getUserUrl = (_id: string) => `${this.getUsersUrl()}/${_id}`;
  private getUserPasswordUrl = (_id: string) => `${this.getUserUrl(_id)}/password`;
  private getRecoveryUrl = () => `${this.getUsersUrl()}/recovery`

  getUsers = (pagination: Pagination): Promise<IUsersPaginated> =>
    lastValueFrom(this.http.get<IUsersPaginated>(this.getUsersUrl(), { params: pagination.loadHttpParams() }));

  getUser = (_id: string): Promise<User> =>
    lastValueFrom(this.http.get<User>(this.getUserUrl(_id)));

  addUser = (user: User): Promise<User> =>
    lastValueFrom(this.http.post<User>(this.getUsersUrl(), user));

  editUser = (_id: string, user: User): Promise<User> =>
    lastValueFrom(this.http.put<User>(this.getUserUrl(_id), user));

  editUserPassword = (_id: string, password: string): Promise<User> =>
    lastValueFrom(this.http.put<User>(this.getUserPasswordUrl(_id), { password }));

  deleteUser = (_id: string): Promise<void> =>
    lastValueFrom(this.http.delete<void>(this.getUserUrl(_id)));

  recoverPassword = (email: string): Promise<void> => 
    lastValueFrom(this.http.post<void>(this.getRecoveryUrl(), { email }));

  resetPassword = (recoveryCode: string, newPassword: string): Promise<void> => 
    lastValueFrom(this.http.put<void>(this.getRecoveryUrl(), {recoveryCode, newPassword}));

}
