import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { COMPANIES_ROUTES } from '@shared/constants/routes';

import { CompaniesComponent } from '@routes/companies/companies.component';
import { CompaniesTableComponent } from '@routes/companies/companies-table/companies-table.component';
import { CompaniesEditComponent } from '@routes/companies/companies-edit/companies-edit.component';
import { CompaniesResolver } from './companies.resolver';
import { FORM_MODES } from '@shared/constants/forms';
import { CompaniesEditDeliveryAddressesComponent } from './companies-edit/companies-edit-delivery-addresses/companies-edit-delivery-addresses.component';

const routes: Routes = [
  {
    path: '',
    component: CompaniesComponent,
    children: [
      {
        path: '',
        component: CompaniesTableComponent,
        pathMatch: 'full'
      },
      {
        path: `${COMPANIES_ROUTES.detail}/:id`,
        component: CompaniesEditComponent,
        resolve: { data: CompaniesResolver },
        data: { mode: FORM_MODES.detail }
      },
      {
        path: `${COMPANIES_ROUTES.edit}/:id`,
        component: CompaniesEditComponent,
        resolve: { data: CompaniesResolver },
        data: { mode: FORM_MODES.edit }
      },
      {
        path: COMPANIES_ROUTES.add,
        component: CompaniesEditComponent,
        resolve: { data: CompaniesResolver },
        data: { mode: FORM_MODES.add }
      }
    ]
  }
]

@NgModule({
  declarations: [
    CompaniesComponent,
    CompaniesTableComponent,
    CompaniesEditComponent,
    CompaniesEditDeliveryAddressesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CompaniesModule { }
