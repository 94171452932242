import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { BATCH_CATEGORY, BATCH_TYPE } from '@routes/batchs/batchs.constants';
import { IBatchUniquePaginated } from '@routes/batchs/batchs.model';
import { BatchsService } from '@routes/batchs/batchs.service';
import { CropService } from '@routes/crops/crop.service';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';
import { CampaignService } from '@routes/campaigns/campaign.service';

import { FiltersConfiguration, FiltersControlDate, FiltersControlOption, FiltersControlSelect, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableElementColor } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnChip, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { BATCHS_ROUTES } from '@shared/constants/routes';
import { GenericPagination, Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { HeaderService } from '@layout/header/header.service';
import { FilterOperator } from '@shared/components/generic-filters/generic-filters.constants';
import { CAMPAIGN_STATUSES } from '@shared/constants/campaigns';

@Component({
  selector: 'app-batchs-table-unique',
  templateUrl: './batchs-table-unique.component.html',
  styleUrls: ['./batchs-table-unique.component.scss'],
  providers: [DatePipe]
})
export class BatchsTableUniqueComponent implements OnInit {

  tableConfig: TableConfiguration<IBatchUniquePaginated>;
  filtersConfig: FiltersConfiguration;

  constructor(
    private batchsService: BatchsService,
    private cropsService: CropService,
    private sitesService: WorkingSiteService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private headerService: HeaderService,
    private datePipe: DatePipe,
    private campaignsService: CampaignService
  ) {}

  ngOnInit(): void {
    this.setupFilters();
    this.setupTable();
    this.getCrops();
    this.getSites();
    this.getCampaigns();
    this.headerService.typeFilterChanges().subscribe(type => {
      Object.assign(this.tableConfig.pagination.params, { type });
      this.tableConfig.updatePagination.next(this.tableConfig.pagination as FiltersEvent);
    });
  }

  getBatchs = (pagination: Pagination) => this.batchsService.getBatchs(pagination)
    .then(response => this.tableConfig.data.next(response))
    .catch(error => this.snackbarService.error(error.error.message));

  getCrops = async () => {
    const crops = await this.cropsService.getCrops();
    
    this.filtersConfig.updateControlOptions('crops', crops.data.map(crop => new FiltersControlOption({
      value: crop._id,
      display: `${crop.name} (${crop.variety})`
    })));
  }

  getSites = async () => {
    const sites = await this.sitesService.getSites(new GenericPagination({ sortField: 'name' }));

    this.filtersConfig.updateControlOptions('sites', sites['sites'].map(site => new FiltersControlOption({
      value: site._id,
      display: site.name
    })));
  }

  getCampaigns = async () => {
    const campaigns = await this.campaignsService.getCampaigns(new Pagination({params: {status: CAMPAIGN_STATUSES.ACTIVE}}));

    this.filtersConfig.updateControlOptions('campaigns', campaigns.data.map(campaign => new FiltersControlOption({
      value: campaign._id,
      display: campaign.name
    })));
  }

  setupFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [
      new FiltersControlDate({
        label: 'START_DATE',
        name: 'startTime',
        field: 'datetime',
        value: null,
        operator: FilterOperator.GreaterEqual,
        modifier: date => date.setHours(0, 0, 0, 0),
        maxDateReferenceName: 'endDate',
      }),
      new FiltersControlDate({
        label: 'END_DATE',
        name: 'endTime',
        field: 'datetime',
        value: null,
        operator: FilterOperator.LessEqual,
        modifier: date => date.setHours(23, 59, 59, 999),
        minDateReferenceName: 'startDate',
      }),
      new FiltersControlSelect({
        label: 'SITES',
        name: 'sites',
        field: 'siteId',
        value: [],
        operator: FilterOperator.In,
        options: new BehaviorSubject([]),
      }),
      new FiltersControlSelect({
        label: 'CROPS',
        name: 'crops',
        field: 'cropId',
        value: [],
        operator: FilterOperator.In,
        options: new BehaviorSubject([]),
      }),
      new FiltersControlSelect({
        label: 'CAMPAIGNS',
        name: 'campaigns',
        field: 'campaignId',
        value: [],
        operator: FilterOperator.In,
        options: new BehaviorSubject([]),
      })
    ],
    buttons: [],
  });

  setupTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1,
      size: 10,
      search: '',
      sort: { field: 'datetime', order: -1 },
      params: { category: BATCH_CATEGORY.UNIQUE, type: this.headerService.typeFilter, historic: false  }
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: batch => batch.code
      }),
      new TableColumnText({
        header: 'PLATE',
        name: 'plate',
        sorting: 'plate',
        displayAt: 1650,
        value: batch => batch.plate
      }),
      new TableColumnText({
        header: 'STATUS',
        name: 'status',
        sorting: 'status',
        displayAt: 520,
        value: batch => this.translateService.instant(`batchs.status.${batch.status.toUpperCase()}`)
      }),
      new TableColumnText({
        header: 'SITE',
        name: 'site',
        sorting: 'site.name',
        displayAt: 1650,
        value: batch => batch.site.name
      }),
      new TableColumnText({
        header: 'CROP',
        name: 'crop',
        sorting: 'crop.name',
        displayAt: 1650,
        value: batch => batch.crop.name
      }),
      new TableColumnText({
        header: 'PACKAGING',
        name: 'packaging',
        sorting: 'packaging.description',
        displayAt: 1920,
        value: batch => batch.packaging.description
      }),
      new TableColumnText({
        header: 'DATETIME',
        name: 'datetime',
        sorting: 'datetime',
        displayAt: 960,
        value: batch => this.datePipe.transform(batch.datetime, 'd MMMM, H:mm', 'GMT')
      }),
      new TableColumnText({
        header: 'CAMPAIGN',
        name: 'campaign',
        sorting: 'campaign',
        displayAt: 1280,
        value: batch => batch.campaign? batch.campaign.name : '-'
      }),
      new TableColumnChip({
        header: 'TYPE',
        name: 'type',
        sorting: 'type',
        displayAt: 1650,
        value: batch => this.translateService.instant(`general.types.${batch.type.toUpperCase()}`),
        color: batch => this.getChipColor(batch),
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'action',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: batch => [batch._id, BATCHS_ROUTES.detail]
          })
        ]
      })
    ]
  });

  getChipColor = (batch: IBatchUniquePaginated) => {
    switch (batch.type) {
      case BATCH_TYPE.PLANT: return GenericTableElementColor.Primary;
      case BATCH_TYPE.FRUIT: return GenericTableElementColor.Warn;
      default: return GenericTableElementColor.Accent;
    }
  }
}
