<form class="form" [formGroup]="form" (submit)="addZone()">
    <!-- Name -->
    <div class="form-row">
        <mat-form-field appearance="standard">
            <mat-label for="name">{{ 'campaigns.zones.NAME' | translate }}</mat-label>
            <input matInput type="text" name="name" id="name" formControlName="name"/>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
    </div>
    <!-- Crop -->
    <div class="form-row">
        <mat-form-field appearance="standard">
            <mat-label for="crop">{{ 'campaigns.zones.CROP' | translate }}</mat-label>
            <input type="text" placeholder="{{ 'campaigns.zones.WRITE_CROP' | translate }}" matInput
                formControlName="crop" [matAutocomplete]="auto" #crop>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCrop" (optionSelected)="selectCrop($event)">
                <mat-option *ngFor="let crop of crops" [value]="crop">{{ crop.name }} ({{ crop.variety }})</mat-option>
            </mat-autocomplete>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
    </div>
    <!-- Area -->
    <div class="form-row">
        <mat-form-field appearance="standard">
            <mat-label for="area">{{ 'campaigns.zones.AREA' | translate }}</mat-label>
            <input matInput type="number" name="area" id="area" formControlName="area"/>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }} ({{ 'general.form.NUMERIC' | translate | lowercase }})</mat-hint>
        </mat-form-field>
    </div>
    <!-- Plants -->
    <div class="form-row">
        <mat-form-field appearance="standard">
            <mat-label for="plants">{{ 'campaigns.zones.PLANTS' | translate }}</mat-label>
            <input matInput type="number" name="plants" id="plants" formControlName="plants"/>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }} ({{ 'general.form.NUMERIC' | translate | lowercase }})</mat-hint>
        </mat-form-field>
    </div>
    <!-- Density -->
    <div class="form-row readonly">
        <mat-form-field appareance="standard">
            <mat-label for="density">{{ 'campaigns.zones.DENSITY' | translate }}</mat-label>
            <input matInput disabled type="text" name="density" id="density" #density/>
        </mat-form-field>
    </div>
    <div class="form-controls">
        <button class="form-button" [disabled]="form.invalid">{{ 'general.actions.ADD' | translate }}</button>
    </div>
</form>