import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Pagination } from 'src/app/shared/models/pagination.model';
import { IWorkingSite, IWorkingSitesPaginated, WorkingSite } from '@routes/working-sites/working-site.model';
import { ApiService } from '@shared/services/api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WorkingSiteService {
    constructor(
        private http: HttpClient,
        private api: ApiService
    ) {}

    private getSitesUrl = (): string => `${this.api.getBaseUrl()}/sites`;
    private getSiteUrl = (id: string): string => `${this.api.getBaseUrl()}/sites/${id}`;

    getSites = (pagination: Pagination): Promise<IWorkingSitesPaginated> =>
        lastValueFrom(this.http.get<object>(this.getSitesUrl(), { params: pagination.loadHttpParams() }).pipe(map(this.parseWorkingSites)));

    getSite = (id: string): Promise<WorkingSite> =>
        lastValueFrom(this.http.get<WorkingSite>(this.getSiteUrl(id)).pipe(map(this.parseWorkingSite)));

    deleteSite = (id: string): Promise<any> =>
        lastValueFrom(this.http.delete(this.getSiteUrl(id)));

    editSite = (workingSite: IWorkingSite): Promise<any> =>
        lastValueFrom(this.http.put(this.getSiteUrl(workingSite.id), workingSite));

    createSite = (workingSite: WorkingSite): Promise<any> =>
        lastValueFrom(this.http.post(this.getSitesUrl(), workingSite));

    parseWorkingSite = (workingSite: IWorkingSite): WorkingSite => new WorkingSite({id: workingSite._id, ...workingSite})

    parseWorkingSites = (workingSites: IWorkingSite): IWorkingSitesPaginated => {
        if (!workingSites || !workingSites['sites']) {
            return null;
        }
        const sites = workingSites['sites'].map((site: IWorkingSite) => this.parseWorkingSite(site));
        const total = workingSites['total'];
        return { sites, total };
    }
        
}