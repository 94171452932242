import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IWorkerReceived } from '@routes/workers/worker.model';
import { IKeychain } from '@routes/keychains/keychain.model';
import { WorkerService } from '@routes/workers/worker.service';

import { UploadPageConfiguration } from '@shared/interfaces/upload';
import { ABS_BASE_ROUTE, SECTIONS, WORKER_ROUTES } from '@shared/constants/routes';
import { IAgency } from '@models/agency.model';
import { IWorkingSite } from '@routes/working-sites/working-site.model';
import { Position } from '@routes/positions/positions.models';
import { WORKER_GENDERS, WORKER_MARITAL_STATUSES } from '@shared/interfaces/types';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-workers-file-upload',
  templateUrl: './workers-file-upload.component.html',
  styleUrls: ['./workers-file-upload.component.scss'],
  providers: [DatePipe]
})
export class WorkersFileUploadComponent implements OnInit {

  uploadConfig: UploadPageConfiguration;
  mainRoute = [ABS_BASE_ROUTE, SECTIONS.workforce, WORKER_ROUTES.parent];

  constructor(
    private workerService: WorkerService,
    private translateService: TranslateService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.uploadConfig = {
      uploadFunction: this.workerService.addWorkersByFile,
      backRoute: this.mainRoute,
      fileFormats: ['csv', 'xls', 'xlsx'],
      tableColumns: [
        {
          header: 'workers.table.NAME',
          name: 'name',
          value: 'name'
        }, {
          header: 'workers.table.SURNAME',
          name: 'surname',
          value: 'surname'
        }, {
          header: 'workers.table.NIF',
          name: 'nif',
          value: 'nif'
        }, {
          header: 'workers.table.ALPHANUMERIC_CODE',
          name: 'alphanumericCode',
          value: 'alphanumericCode',
          formatFunction: (alphanumericCode: string) => alphanumericCode || '-'
        }, {
          header: 'workers.table.POSITION',
          name: 'position',
          value: 'position',
          formatFunction: (position: Position) => position?.name || '-'
        }, {
          header: 'workers.table.KEYCHAIN',
          name: 'keychain',
          value: 'keychain',
          formatFunction: (keychain: IKeychain) => keychain ? keychain.code : '-'
        }, {
          header: 'workers.table.NATIONALITY',
          name: 'nationality',
          value: 'nationality',
          formatFunction: (nationality: string) => nationality || '-'
        }, {
          header: 'workers.table.ADDRESS',
          name: 'address',
          value: 'address'
        }, {
          header: 'workers.table.TAX_RESIDENCE',
          name: 'taxResidence',
          value: 'taxResidence',
          formatFunction: (taxResidence: string) => taxResidence || '-'
        }, {
          header: 'workers.table.IBAN',
          name: 'iban',
          value: 'iban',
          formatFunction: (iban: string) => iban || '-'
        }, {
          header: 'workers.table.RECOMMENDED_BY',
          name: 'recommendedBy',
          value: 'recommendedBy',
          formatFunction: (recommendedBy: IWorkerReceived) => recommendedBy ? `${recommendedBy.name} ${recommendedBy.surname} (${recommendedBy.nif})` : '-'
        }, {
          header: 'workers.table.AGENCY',
          name: 'agency',
          value: 'agency',
          formatFunction: (agency: IAgency) => agency?.name || this.translateService.instant('workers.table.WITHOUT_AGENCY')
        }, {
          header: 'workers.table.SITE',
          name: 'site',
          value: 'site',
          formatFunction: (site: IWorkingSite) => site?.name || '-'
        }, {
          header: 'workers.table.SOCIAL_SECURITY_NUMBER',
          name: 'socialSecurityNumber',
          value: 'socialSecurityNumber',
          formatFunction: (socialSecurityNumber: string) => socialSecurityNumber || '-'
        }, {
          header: 'workers.table.PHONE_NUMBER',
          name: 'phoneNumber',
          value: 'phoneNumber',
          formatFunction: (phoneNumber: string) => phoneNumber || '-'
        }, {
          header: 'workers.table.GENDER',
          name: 'gender',
          value: 'gender',
          formatFunction: (gender: string) => gender ? this.translateService.instant(`workers.gender.${WORKER_GENDERS.find(h => h.value === gender).tag}`) : '-'
        }, {
          header: 'workers.table.BIRTH_DATE',
          name: 'birthDate',
          value: 'birthDate',
          formatFunction: (birthDate: string) => birthDate ? birthDate : '-'
        }, {
          header: 'workers.table.MARITAL_STATUS',
          name: 'maritalStatus',
          value: 'maritalStatus',
          formatFunction: (maritalStatus: string) => maritalStatus ? this.translateService.instant(`workers.maritalStatus.${WORKER_MARITAL_STATUSES.find(h => h.value === maritalStatus).tag}`) : '-'
        }
      ],
      successTab: {
        content: 'workers.upload.WORKERS',
        empty: 'workers.upload.NO_WORKERS'
      }
    };
  }

}
