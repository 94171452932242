import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatAutocompleteActivatedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { v4 } from 'uuid';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignStatus } from '@routes/campaigns/campaigns.constants';
import { DeviceService } from '@routes/devices/device.service';
import { WorkerService } from '@routes/workers/worker.service';
import { IWorkerReceivedGroup, WorkerReceived } from '@routes/workers/worker.model';
import { DeviceReceived, IDevicePaginated } from '@routes/devices/device.model';

import { WorkingDay, WorkingDaySent } from '@shared/models/working-day.model';
import { WorkingDayService } from '@shared/services/working-day.service';
import { CAMPAIGNS_DETAIL_ROUTES } from '@shared/constants/routes';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { WORKING_DAY_STATUSES } from '@shared/constants/working-day';
import { FORM_MODES } from '@shared/constants/forms';
import { Pagination } from '@shared/models/pagination.model';
import { transformDateToUTC } from '@shared/utils/date.utils';
import { WorkingDayFormOrigin, WorkingDayFormSection } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-form/campaigns-detail-working-days-form.constants';
import { CampaignsRoutingService } from '@routes/campaigns/campaigns-routing.service';

@Component({
  selector: 'app-campaigns-detail-working-days-form',
  templateUrl: './campaigns-detail-working-days-form.component.html',
  styleUrls: ['./campaigns-detail-working-days-form.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailWorkingDaysFormComponent implements OnInit, AfterViewInit {

  CampaignStatus = CampaignStatus;

  @ViewChild('worker') worker: ElementRef;
  @ViewChild('device') device: ElementRef;

  get breaks() { return <FormArray>this.form.get('breaks') }

  WORKING_DAY_STATUSES = WORKING_DAY_STATUSES;
  FORM_MODES = FORM_MODES;
  WorkingDayFormSection = WorkingDayFormSection;
  EDIT_ROUTE: string[];
  DETAIL_ROUTE: string[];

  campaign: Campaign;
  workingDay: WorkingDay;
  avaliableStatuses: string[];

  workers: IWorkerReceivedGroup[];
  devices: IDevicePaginated[];

  mode: FORM_MODES;
  section: WorkingDayFormSection;
  origin: WorkingDayFormOrigin;

  form: FormGroup;
  startControl: FormControl;
  endControl: FormControl;
  timeRegExp: RegExp = /^\d{1,2}[:]{1}\d{2}$/;
  pagination = new Pagination({
    page: 1, size: 5, search: '',
    sort: { field: 'name', order: 1 },
  });

  constructor(
    private datepipe: DatePipe,
    private campaignService: CampaignPlannerService,
    private campaignsRoutingService: CampaignsRoutingService,
    private workingDayService: WorkingDayService,
    private deviceService: DeviceService,
    private workerService: WorkerService,
    private snackbarService: CustomSnackbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.campaign = this.campaignService.campaign;
    this.workingDay = this.activatedRoute.snapshot.data.workingDay;
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.selectSection();
    this.initForm();

    if (this.mode === FORM_MODES.edit || this.mode === FORM_MODES.detail) {
      this.EDIT_ROUTE = ['../..', CAMPAIGNS_DETAIL_ROUTES.workingDayEdit, this.workingDay._id];
      this.DETAIL_ROUTE = ['../..', CAMPAIGNS_DETAIL_ROUTES.workingDayDetail, this.workingDay._id];
      this.loadWorkingDay();
    }
    this.setAvaliableStatuses();
  }

  setAvaliableStatuses = () => {
    const {PENDING, AGREE, CHECKED, ERROR} = WORKING_DAY_STATUSES;
    this.avaliableStatuses = this.workingDay && this.workingDay.status === ERROR ? [] : [AGREE, CHECKED, PENDING];
  }

  ngAfterViewInit(): void {
    const workerEvent = fromEvent(this.worker.nativeElement, 'input');
    const deviceEvent = fromEvent(this.device.nativeElement, 'input');

    merge(workerEvent, deviceEvent).pipe(debounceTime(250)).subscribe((value: InputEvent) => {
      const input = <HTMLInputElement>value.target;
      this.pagination.search = input.value;

      switch (input.name) {
        case 'worker': this.retrieveWorkers(); break;
        case 'device': this.retrieveDevices(); break;
      }
    });
    workerEvent.subscribe(() => this.form.get('worker').setErrors({ incorrect: true }))
    deviceEvent.subscribe(() => this.form.get('device').setErrors({ incorrect: true }))
  }

  selectSection = () => {
    const {section, origin} = this.activatedRoute.snapshot.queryParams;
    this.section = Object.values(WorkingDayFormSection).includes(section)
      ? section
      : WorkingDayFormSection.Basic;
    this.origin = Object.values(WorkingDayFormOrigin).includes(origin)
      ? origin
      : WorkingDayFormOrigin.Table;
  }

  initForm = () => {
    this.form = new FormGroup({
      worker: new FormControl({ value: null, disabled: this.mode !== FORM_MODES.add }, Validators.required),
      site: new FormControl({ value: null, disabled: this.mode !== FORM_MODES.add }, Validators.required),
      device: new FormControl({ value: null, disabled: this.mode !== FORM_MODES.add }, Validators.required),
      hours: new FormControl({ value: null, disabled: true }),
      status: new FormControl(WORKING_DAY_STATUSES.PENDING, Validators.required),
      startDate: new FormControl(null, Validators.required),
      startTime: new FormControl(null, [Validators.required, Validators.pattern(this.timeRegExp)]),
      startedWithKeychain: new FormControl(false),
      endDate: new FormControl(null, this.validateEndDateTime),
      endTime: new FormControl(null, this.validateEndDateTime),
      endedWithKeychain: new FormControl({ value: false, disabled: true }),
      breaks: new FormArray([])
    });

    merge(
      this.form.get('endDate').valueChanges,
      this.form.get('endTime').valueChanges
    ).subscribe(() => this.checkEndedWithKeychain());

    if (this.mode === FORM_MODES.detail) this.form.disable();
    this.startControl = this.form.get('startDate') as FormControl;
    this.endControl = this.form.get('endDate') as FormControl;
  }

  loadWorkingDay = () => {
    this.form.patchValue({
      worker: this.workingDay.worker,
      site: this.workingDay.site._id,
      device: this.workingDay.device,
      hours: this.workingDay.totalHours,
      status: this.workingDay.status,
      startDate: this.workingDay.startTimestamp,
      startTime: this.datepipe.transform(this.workingDay.startTimestamp, 'HH:mm', 'GMT'),
      startedWithKeychain: this.workingDay.startedWithKeychain,
      endDate: this.workingDay.endTimestamp,
      endTime: this.datepipe.transform(this.workingDay.endTimestamp, 'HH:mm', 'GMT'),
      endedWithKeychain: this.workingDay.endedWithKeychain
    });
    this.workingDay.breaks.forEach(b => this.addBreakFormGroup(b.startTimestamp, b.endTimestamp));
  }

  displayWorker = (worker: WorkerReceived) => worker ? `${worker.name} ${worker.surname}` : null;

  displayDevice = (device: DeviceReceived) => device ? device.name : null;

  retrieveWorkers = () =>
    this.workerService.getWorkers(this.pagination).then(response => this.workers = response.workers);

  retrieveDevices = () =>
    this.deviceService.getDevices(this.pagination).then(response => this.devices = response.devices);

  selectWorker = (event: MatAutocompleteActivatedEvent) => {
    this.form.get('worker').setValue(event.option.value);
    this.form.get('worker').updateValueAndValidity();
    this.workers = [];
  }

  selectDevice = (event: MatAutocompleteActivatedEvent) => {
    this.form.get('device').setValue(event.option.value);
    this.form.get('device').updateValueAndValidity();
    this.devices = [];
  }

  validateEndDateTime = (control: AbstractControl): {[key: string]: any} | null => {
    if (!this.form) return null;

    const endDate: AbstractControl = control.parent.controls['endDate'];
    const endTime: AbstractControl = control.parent.controls['endTime'];
    const conditions = [
      (endDate.value && endTime.value && this.timeRegExp.test(endTime.value)),
      (!endDate.value && !endTime.value)
    ];

    if (conditions.some(c => c === true)) {
      endDate.setErrors(null);
      endTime.setErrors(null);
      return null;
    }
    return { endDate: { value: control.value }};
  }

  checkEndedWithKeychain = () => {
    if (this.mode === FORM_MODES.detail) return null;
    const endedWithKeychain = this.form.get('endedWithKeychain');
    const inputDisabled = JSON.parse(JSON.stringify(endedWithKeychain.disabled));

    !this.form.get('endDate').value && !this.form.get('endTime').value
      ? endedWithKeychain.disable()
      : endedWithKeychain.enable();
    if (inputDisabled !== endedWithKeychain.disabled) endedWithKeychain.setValue(false);
  }

  addBreakFormGroup = (startDate: Date = null, endDate: Date = null) => {
    const startTime = startDate ? this.datepipe.transform(startDate, 'HH:mm', 'GMT') : null;
    const endTime = endDate ? this.datepipe.transform(endDate, 'HH:mm', 'GMT') : null;
    startDate = startDate || <Date>this.form.value.startDate;
    endDate = endDate || <Date>this.form.value.startDate;

    (<FormArray>this.form.get('breaks')).push(new FormGroup({
      startDate: new FormControl(startDate, Validators.required),
      startTime: new FormControl(startTime, [Validators.required, Validators.pattern(this.timeRegExp)]),
      endDate: new FormControl(endDate, Validators.required),
      endTime: new FormControl(endTime, [Validators.required, Validators.pattern(this.timeRegExp)])
    }));
  }

  changeSection = (event: MatSelectChange) => this.section = event.value;

  createUTCDatetime = (date: Date, time: string): Date => new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    parseInt(time.split(':')[0]),
    parseInt(time.split(':')[1])
  ));

  submitForm = async () => {
    const startTimestamp = transformDateToUTC(this.form.value.startDate);
    const endTimestamp = transformDateToUTC(this.form.value.endDate);

    const breaks = (this.form.value.breaks as any[]).map(br => ({
      startTimestamp: this.createUTCDatetime(new Date(br.startDate), br.startTime),
      endTimestamp: this.createUTCDatetime(new Date(br.endDate), br.endTime)
    }));

    const startedWithKeychain = this.form.value.startedWithKeychain;
    const endedWithKeychain = this.form.get('endedWithKeychain').enabled
      ? this.form.value.endedWithKeychain
      : null;
    const status = this.form.value.status;

    if (this.mode === FORM_MODES.add) {
      const workingDay = new WorkingDaySent({
        _id: v4(),
        campaignId: this.campaign.id,
        workerId: this.form.value.worker._id,
        siteId: this.form.value.site,
        deviceId: this.form.value.device._id,
        deleted: false,
        status,
        startTimestamp,
        startedWithKeychain,
        endTimestamp,
        endedWithKeychain,
        breaks
      });
      if (!endTimestamp) {
        delete workingDay['endTimestamp'];
        delete workingDay['endedWithKeychain'];
      }
      this.workingDayService.addWorkingDay(this.campaign.id, workingDay)
        .then(workingDay => this.router.navigate(['..', CAMPAIGNS_DETAIL_ROUTES.workingDayDetail, workingDay._id], { relativeTo: this.activatedRoute }))
        .catch(error => this.snackbarService.error(error.error.message));
    } else {
      const workingDay = new WorkingDay({
        ...this.workingDay,
        status,
        startTimestamp,
        endTimestamp,
        startedWithKeychain,
        endedWithKeychain,
        breaks
      });
      
      if (!endTimestamp) {
        delete workingDay.endTimestamp;
        delete workingDay.endedWithKeychain;
      }
      this.workingDayService.editWorkingDay(this.campaign.id, this.workingDay._id, workingDay)
        .then(() => this.manageExitNavigation())
        .catch(error => this.snackbarService.error(error.error.message));
    }
  }

  manageExitNavigation = () => {
    const route = this.origin === WorkingDayFormOrigin.Table
      ? this.campaignsRoutingService.detailWorkingDay(this.workingDay.campaignId, this.workingDay._id)
      : this.campaignsRoutingService.mainWorkingDays(this.workingDay.campaignId);
    const queryParams = this.origin === WorkingDayFormOrigin.Errors ? {errors: this.workingDay._id} : {};

    this.router.navigate(route, {queryParams});
  }

}