<div class="header">
    <mat-form-field class="zones-input" appearance="standard">
        <mat-label>{{ 'campaigns.detail.SITE' | translate }}</mat-label>
        <mat-select [value]="site.siteId" (selectionChange)="selectCampaignSite($event)" name="sites" id="sites">
            <mat-option *ngFor="let site of sites" value="{{ site.siteId }}">{{ site.siteName }}</mat-option>
        </mat-select>
        <mat-hint>{{ 'campaigns.detail.HINT' | translate }}</mat-hint>
    </mat-form-field>

    <div>
    <button mat-flat-button color="primary" class="btn" type="button" [disabled]="campaign.status === CampaignStatus.Finished" (click)="displayDialog()">
        {{ 'campaigns.detail.ADD_SITE_BTN' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn zones-button" type="button" [disabled]="campaign.status === CampaignStatus.Finished" [routerLink]="['./', site.siteId, CAMPAIGNS_DETAIL_ROUTES.addZone]">
        {{ 'campaigns.detail.ADD_ZONE_BTN' | translate }}
    </button>
    </div>
    
    
</div>

<table class="zones-table" *ngIf="zones.length; else noZones">
    <thead>
        <tr class="zones-row">
            <th class="zones-cell name">{{ 'campaigns.detail.ZONE' | translate }}</th>
            <th class="zones-cell crop">{{ 'campaigns.detail.CROP' | translate }}</th>
            <th class="zones-cell traceCode">{{ 'campaigns.detail.TRACE_CODE' | translate }}</th>
            <th class="zones-cell area">{{ 'campaigns.detail.AREA' | translate }}</th>
            <th class="zones-cell plants">{{ 'campaigns.detail.PLANTS' | translate }}</th>
            <th class="zones-cell density">{{ 'campaigns.detail.DENSITY' | translate }}</th>
            <th class="zones-cell table-actions actions">{{ 'campaigns.detail.ACTIONS' | translate }}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let zone of zones" class="zones-row">
            <td class="zones-cell name">{{ zone.name }}</td>
            <td class="zones-cell crop">{{ zone.cropName }} ({{ zone.cropVariety }})</td>
            <td class="zones-cell traceCode">{{ zone.traceCode }}</td>
            <td class="zones-cell area">{{ zone.area }}</td>
            <td class="zones-cell plants">{{ zone.plants }}</td>
            <td class="zones-cell density">{{ calculateDensity(zone.area, zone.plants) }}</td>
            <td class="zones-cell table-actions actions">
                <button class="action-btn" (click)="editZone(zone)" [disabled]="campaign.status === CampaignStatus.Finished">
                    <img class="action-img" src="/assets/images/icons/edit.svg" alt="{{ 'general.alt.EDIT' | translate }}"/>
                </button>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #noZones>
<div class="alert alert-warning">
    <p class="m-0 text-center">{{ 'campaigns.zones.NO_ZONES' | translate }}</p>
</div>
</ng-template> 