<form [formGroup]="form" class="form" (submit)="submitForm()">
    <h2 class="form-title">{{ title | translate }}</h2>
    <div class="form-row">
        <mat-form-field>
            <mat-label appearance="fill" for="name">{{ 'tasks.form.NAME' | translate }} </mat-label>
            <input matInput type="text" name="name" id="name" formControlName="name">
            <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
        </mat-form-field>
        <div class="col-12 px-0 mt-3">
            <mat-checkbox color="primary" formControlName="workable">{{ 'tasks.form.WORKABLE' | translate }}</mat-checkbox>
        </div>
        <mat-form-field appearance="fill" class="mt-3" *ngIf="form.getRawValue().workable">
            <mat-label for="unit">{{ 'tasks.form.UNIT' | translate }}</mat-label>
            <mat-select formControlName="unit">
                <mat-option *ngFor="let unit of taskTypesUnits" [value]="unit.symbol">{{ translateUnit(unit.name) }}</mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
    </div>
    <ul class="form-controls">
        <li>
            <button mat-flat-button [color]="mode !== FORM_STATUS.detail ? 'warn' : 'primary'" class="btn" type="button" [routerLink]="TASK_LIST_ROUTE">
                {{ mode === FORM_STATUS.detail ? ('general.actions.BACK' | translate) : ('general.actions.CANCEL' | translate) }}
            </button>
        </li>
        <li>
            <button mat-flat-button color="primary" class="btn" [disabled]="form.invalid || loading" *ngIf="mode !== FORM_STATUS.detail">
                {{ mode === FORM_STATUS.add ? ('general.actions.ADD' | translate) : ('general.actions.EDIT' | translate) }}
            </button>
        </li>
    </ul>
</form>
