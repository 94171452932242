import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AgencyService } from '@routes/agencies/agency.service';
import { Agency } from '@models/agency.model';
import { AGENCIES_ROUTES, SECTIONS, ABS_BASE_ROUTE } from '@shared/constants/routes';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { FORM_MODES } from '@shared/constants/forms';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';

@Component({
  selector: 'app-agencies-edit',
  templateUrl: './agencies-edit.component.html',
  styleUrls: ['./agencies-edit.component.scss']
})
export class AgenciesEditComponent implements OnInit {

  agency: Agency;
  form: FormGroup;
  mode: FORM_MODES;
  title: string;
  loading: boolean;
  isDetail: boolean;
  exitRoute: string[];

  exitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button
  }
  actionButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CONFIRM',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private agencyService: AgencyService,
    private router: Router,
    private snackbarService: CustomSnackbarService
  ) {}

  ngOnInit(): void {
    this.agency = this.activatedRoute.snapshot.data.agency;
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.isDetail = this.mode === FORM_MODES.detail;
    this.title = `agencies.form.title.${this.mode.toUpperCase()}`;
    this.exitRoute = [ABS_BASE_ROUTE, SECTIONS.workforce, AGENCIES_ROUTES.parent];
    this.setForm();
    this.setButtons();
  }

  setForm = () => {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      abbreviation: new FormControl(null, [Validators.required, Validators.maxLength(2)])
    });
    if (this.mode === FORM_MODES.detail) this.form.disable();
    if (this.mode !== FORM_MODES.add) this.form.patchValue(this.agency);
  }

  setButtons = () => {
    if (this.mode === FORM_MODES.detail) {
      this.exitButtonConfig.text = 'general.actions.BACK';
      this.exitButtonConfig.color = GenericButtonColor.Primary;
    }
  }

  submitForm = () => {
    const agency = {...this.form.value};
    this.loading = true;

    const fn = this.mode === FORM_MODES.add
      ? this.agencyService.addAgency(agency)
      : this.agencyService.editAgency(this.agency._id, agency);

    fn
      .then(() => this.router.navigate(this.exitRoute))
      .catch(error => this.snackbarService.error(error.error.message))
      .finally(() => this.loading = false);
  }

}
