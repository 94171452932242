import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WorkingDaysMassEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-working-days/campaigns-detail-working-days-table/campaigns-detail-working-days-mass-edit/working-days-mass-edit.service';

@Component({
  selector: 'app-campaigns-detail-working-days-mass-edit-editor',
  templateUrl: './campaigns-detail-working-days-mass-edit-editor.component.html',
  styleUrls: ['./campaigns-detail-working-days-mass-edit-editor.component.scss']
})
export class CampaignsDetailWorkingDaysMassEditEditorComponent implements OnInit {
  
  form: FormGroup;

  constructor(
    private massEditService: WorkingDaysMassEditService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({});
    this.massEditService.formChanges().subscribe(form => {
      this.form = form;
    })
  }

}
