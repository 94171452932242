<h2 class="h2">{{ title | translate }}</h2>

<div class="wrapper">
    <app-generic-filters class="filters" [filtersConfig]="filtersConfig" (filtersEvent)="updateFilters($event)" (validationEvent)="updateSubmitButton($event)">
    </app-generic-filters>


    <div class="report" [ngSwitch]="true">
        <ng-container *ngSwitchCase="loadingWarning">
            <div class="alert alert-secondary text-center">{{ 'reports.messages.LOADING_WARNING' | translate }}</div>
        </ng-container>

        <ng-container *ngSwitchCase="harvestReport !== null">
            <header class="report-header">
                <div class="item workers">
                    <span class="title">{{ 'general.entities.WORKERS' | translate }}</span>
                    <span class="content">{{ harvestReport.workers.length }}</span>
                </div>
                <div class="item total-harvested">
                    <span class="title">{{ 'campaigns.reports.TOTAL_HARVESTED' | translate }}</span>
                    <span class="content">{{ harvestReport.totalHarvested | number:'1.0-2' }} {{ unit }}</span>
                </div>
                <div class="item total-boxes">
                    <span class="title">{{ 'campaigns.reports.TOTAL_BOXES' | translate }}</span>
                    <span class="content">{{ harvestReport.totalPackagings }} {{ 'general.entities.BOXES' | translate |
                        lowercase }}</span>
                </div><div class="item average-harvested">
                    <span class="title">{{ 'campaigns.reports.AVERAGE_HARVESTED' | translate }}</span>
                    <span class="content">{{ harvestReport.averageHarvested | number:'1.0-2' }} {{ unit }}/p</span>
                </div>
                <div class="item harvest-performance">
                    <span class="title">{{ 'campaigns.reports.HARVEST_PERFORMANCE' | translate }}</span>
                    <span class="content">{{ harvestReport.harvestPerformance | number:'1.0-2' }} {{ unit }}/h/px</span>
                </div>
            </header>
            <section class="report-breakdown" *ngFor="let worker of harvestReport.workers">
                <table>
                    <tr>
                        <th class="worker">{{ worker.name }} {{ worker.surname }} - {{ worker.sequentialId }} - {{
                            worker.nif }}</th>
                        <th>{{ worker.totalHarvested | number:'1.0-2' }} {{ unit }}</th>
                        <th>{{ worker.totalPackagings }} {{ 'general.entities.BOXES' | translate | lowercase }}</th>
                    </tr>
                    <tbody>
                        <ng-container *ngFor="let site of worker.sites">
                            <tr class="lvl-1">
                                <td>{{ site.name }}</td>
                                <td>{{ site.totalHarvested | number:'1.0-2' }} {{ unit }}</td>
                                <td>{{ site.totalPackagings }} {{ 'general.entities.BOXES' | translate | lowercase }}
                                </td>
                            </tr>
                            <ng-container *ngFor="let crop of site.crops">
                                <tr class="lvl-2">
                                    <td>{{ crop.name }} ({{ crop.variety }})</td>
                                    <td>{{ crop.totalHarvested | number:'1.0-2' }} {{ unit }}</td>
                                    <td>{{ crop.totalPackagings }} {{ 'general.entities.BOXES' | translate | lowercase
                                        }}
                                    </td>
                                </tr>
                                <ng-container *ngFor="let packaging of crop.packagings">
                                    <tr class="lvl-3">
                                        <td>{{ packaging.description }} ({{ packaging.amount }} {{ packaging.unit }})
                                        </td>
                                        <td>{{ packaging.totalHarvested | number:'1.0-2' }} {{ unit }}</td>
                                        <td>{{ packaging.totalPackagings }} {{ 'general.entities.BOXES' | translate |
                                            lowercase }}</td>
                                    </tr>
                                    <tr class="detail-row">
                                        <th>{{ 'general.dates.DATE' | translate }}</th>
                                        <th>{{ 'general.entities.BOXES' | translate }}</th>
                                        <th>{{ 'general.dates.LENGTH' | translate }}</th>
                                    </tr>
                                    <ng-container *ngFor="let productionInterval of packaging.productionIntervals">
                                        <tr class="detail-row">
                                            <td>{{ productionInterval.endTimestamp | date : 'dd/MM/yyyy - HH:mm' : 'GMT'
                                                }}
                                            </td>
                                            <td>{{ productionInterval.amount }}</td>
                                            <td>{{ productionInterval.totalHours | hour }}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </section>
        </ng-container>

        <ng-container *ngSwitchCase="loading">
            <div class="alert alert-secondary text-center">{{ 'reports.messages.LOADING_REPORT' | translate }}</div>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <div class="alert alert-warning text-center">{{ 'reports.messages.NO_HARVEST_REPORTS' | translate }}</div>
        </ng-container>
    </div>
</div>