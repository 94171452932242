import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Directive({
    selector: '[appTextToHours]',
})
export class TextToHoursDirective {

    @Input() formControlName: string;

    constructor(
        private el: ElementRef,
        private form: FormGroupDirective
    ) { }

    @HostListener('input') onInput() {
        const control = this.form.control.get(this.formControlName);
        const formated = this.el.nativeElement.value
            .replace(/\D/g, '')
            .slice(-4)
            .padStart(4, '0')
            .replace(/^[3-9]/, '0')
            .replace(/^2([4-9])/, '0$1')
            .split('')
            .join(':')
            .replace(/:(\d:\d):/, '$1');
        control.setValue(formated);
        control.updateValueAndValidity();
    }
}
