<section class="wrapper">
    <form class="section" [formGroup]="mandatoryForm">
        <!-- Task type -->
        <mat-form-field class="input task-type">
            <mat-label>{{ 'campaigns.tasks.edit.TASK' | translate }}</mat-label>
            <mat-select formControlName="taskTypeId">
                <mat-option *ngFor="let taskType of taskTypes" [value]="taskType._id">
                    {{ taskType.name }}
                </mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Start timestamp -->
        <div class="datetime-row">
            <mat-form-field class="input date">
                <mat-label>{{ 'campaigns.tasks.edit.START_DATE' | translate }}</mat-label>
                <input matInput id="startTimestamp" name="startTimestamp" formControlName="startTimestamp"
                    [matDatepicker]="startTimestamp" [readonly]="isHarvestTask">
                <mat-datepicker-toggle
                    matSuffix [for]="startTimestamp" [hidden]="isHarvestTask"
                ></mat-datepicker-toggle>
                <mat-datepicker #startTimestamp></mat-datepicker>
                <mat-hint>
                    <ng-container *ngIf="isHarvestTask; else startTimestampEditable">
                        {{ 'general.form.AUTOCOMPLETED' | translate }}
                    </ng-container>
                    <ng-template #startTimestampEditable>
                        * {{ 'general.form.MANDATORY_LONG_DATE' | translate }}
                    </ng-template>
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="input time">
                <mat-label>{{ 'campaigns.tasks.edit.START_TIME' | translate }}</mat-label>
                <input matInput appReactiveTimeInput type="text" formControlName="startTime"
                    [dateControl]="startControl" [readonly]="isHarvestTask">
                <mat-hint>
                    <ng-container *ngIf="isHarvestTask; else startTimeEditable">
                        {{ 'general.form.AUTOCOMPLETED' | translate }}
                    </ng-container>
                    <ng-template #startTimeEditable>
                        * {{ 'general.form.MANDATORY_SHORT_TIME' | translate }}
                    </ng-template>
                </mat-hint>
            </mat-form-field>
        </div>
        <!-- End timestamp -->
        <div class="datetime-row">
            <mat-form-field class="input date">
                <mat-label>{{ 'campaigns.tasks.edit.END_DATE' | translate }}</mat-label>
                <input matInput id="endTimestamp" name="endTimestamp" formControlName="endTimestamp"
                    [matDatepicker]="endTimestamp" [readonly]="isHarvestTask">
                <mat-datepicker-toggle
                    matSuffix [for]="endTimestamp" [hidden]="isHarvestTask"
                ></mat-datepicker-toggle>
                <mat-datepicker #endTimestamp></mat-datepicker>
                <mat-hint>
                    <ng-container *ngIf="isHarvestTask; else endTimestampEditable">
                        {{ 'general.form.AUTOCOMPLETED' | translate }}
                    </ng-container>
                    <ng-template #endTimestampEditable>
                        * {{ 'general.form.MANDATORY_LONG_DATE' | translate }}
                    </ng-template>
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="input end">
                <mat-label>{{ 'campaigns.tasks.edit.END_TIME' | translate }}</mat-label>
                <input matInput appReactiveTimeInput type="text" formControlName="endTime"
                    [dateControl]="endControl" [readonly]="isHarvestTask">
                <mat-hint>
                    <ng-container *ngIf="isHarvestTask; else endTimeEditable">
                        {{ 'general.form.AUTOCOMPLETED' | translate }}
                    </ng-container>
                    <ng-template #endTimeEditable>
                        * {{ 'general.form.MANDATORY_SHORT_TIME' | translate }}
                    </ng-template>
                </mat-hint>
            </mat-form-field>
        </div>
    </form>
    <form class="section" [formGroup]="optionalForm" [ngClass]="{'disabled': isHarvestTask}">
        <!-- Zone -->
        <mat-form-field class="input">
            <mat-label>{{ 'campaigns.tasks.edit.ZONE' | translate }}</mat-label>
            <mat-select formControlName="zoneSequentialId">
                <mat-option *ngFor="let zone of zones" [value]="zone.sequentialId">
                    {{ zone.name }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="!isHarvestTask">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Crop -->
        <mat-form-field class="input">
            <mat-label>{{ 'campaigns.tasks.edit.CROP' | translate }}</mat-label>
            <mat-select formControlName="cropId">
                <mat-option *ngFor="let crop of crops" [value]="crop._id">
                    {{ crop.name }} ({{ crop.variety }})
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="!isHarvestTask">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Started with keychain -->
        <mat-checkbox class="form-checkbox" color="primary" formControlName="startedWithKeychain">
            {{ 'campaigns.tasks.edit.STARTED_WITH_KEYCHAIN' | translate }}
        </mat-checkbox>
        <!-- Ended with keychain -->
        <mat-checkbox class="form-checkbox" color="primary" formControlName="endedWithKeychain">
            {{ 'campaigns.tasks.edit.ENDED_WITH_KEYCHAIN' | translate }}
        </mat-checkbox>
    </form>
</section>
<section class="information">
    <section class="working-day alert alert-secondary">
        <h3 class="h3">{{ 'campaigns.tasks.edit.WORKING_DAY_TITLE' | translate }}</h3>
        <ul class="list">
            <li class="item">
                <p class="item-category">{{ 'campaigns.tasks.edit.WORKING_DAY_WORKER' | translate }}</p>
                <p class="item-text">{{ workingDay.worker.name }} {{ workingDay.worker.surname }}</p>
            </li>
            <li class="item">
                <p class="item-category">{{ 'campaigns.tasks.edit.WORKING_DAY_START_TIMESTAMP' | translate }}</p>
                <p class="item-text">{{ workingDay.startTimestamp | date : 'medium' : 'GMT' }}</p>
            </li>
            <li class="item">
                <p class="item-category">{{ 'campaigns.tasks.edit.WORKING_DAY_END_TIMESTAMP' | translate }}</p>
                <p class="item-text">{{ (workingDay.endTimestamp | date : 'medium' : 'GMT') || '-' }}</p>
            </li>
        </ul>
    </section>
    <section class="info alert alert-warning">
        <h3 class="h3">{{ 'campaigns.tasks.edit.INFO_TITLE' | translate }}</h3>
        <ul class="list">
            <li *ngFor="let text of ('campaigns.tasks.edit.INFO_DESCRIPTION' | translate)" [innerHTML]="text" class="item"></li>
        </ul>
    </section>
</section>
