import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { HeaderService } from '@layout/header/header.service';

import { IPackagingReceived } from '@routes/packagings/packaging.model';
import { PackagingService } from '@routes/packagings/packaging.service';

import { FiltersButtonLink, FiltersConfiguration, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableColumnAlign, GenericTableColumnStyle } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnChip, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { CROP_TYPES } from '@shared/constants/crops';
import { ABS_BASE_ROUTE, SECTIONS, PACKAGINGS_ROUTES } from '@shared/constants/routes';
import { Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-packagings-main',
  templateUrl: './packagings-main.component.html',
  styleUrls: ['./packagings-main.component.scss']
})
export class PackagingsMainComponent implements OnInit {

  tableConfig: TableConfiguration<IPackagingReceived>;
  filtersConfig: FiltersConfiguration;

  PACKAGINGS_BASE_ROUTE = [ ABS_BASE_ROUTE, SECTIONS.production, PACKAGINGS_ROUTES.parent ];

  constructor(
    private packagingService: PackagingService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private headerService: HeaderService,
  ) {}

  ngOnInit(): void {
    this.setupFilters();
    this.setupTable();
    this.headerService.typeFilterChanges().subscribe(type => {
      Object.assign(this.tableConfig.pagination.params, { type });
      this.tableConfig.updatePagination.next(this.tableConfig.pagination as FiltersEvent);
    });
  }

  getPackagings = (pagination: Pagination) => this.packagingService.getPackagings(pagination)
    .then(response => this.tableConfig.data.next(response))
    .catch(error => this.snackbarService.error(error.error.message));

  setupFilters = () => this.filtersConfig = new FiltersConfiguration({
    buttons: [
      new FiltersButtonLink({
        text: 'NEW',
        icon: 'add',
        link: [...this.PACKAGINGS_BASE_ROUTE, PACKAGINGS_ROUTES.add]
      })
    ],
    controls: []
  });

  setupTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'packagings.table',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1,
      size: 10,
      search: '',
      sort: { field: 'description', order: 1 },
      params: { type: this.headerService.typeFilter },
    },
    columns: [
      new TableColumnText({
        header: 'DESCRIPTION',
        name: 'description',
        sorting: 'description',
        displayAt: 0,
        value: packaging => packaging.description
      }),
      new TableColumnText({
        header: 'SAGE_REFERENCE',
        name: 'sageReference',
        sorting: 'sageReference',
        displayAt: 1366,
        value: packaging => packaging.sageReference
      }),
      new TableColumnText({
        header: 'AMOUNT',
        name: 'amount',
        sorting: 'amount',
        align: GenericTableColumnAlign.Right,
        style: GenericTableColumnStyle.Compact,
        displayAt: 1024,
        value: packaging => `${packaging.amount} ${packaging.unit}`
      }),
      new TableColumnText({
        header: 'TRACE_CODE',
        name: 'traceCode',
        sorting: 'traceCode',
        style: GenericTableColumnStyle.Compact,
        align: GenericTableColumnAlign.Center,
        displayAt: 1366,
        value: packaging => packaging.traceCode
      }),
      new TableColumnChip({
        header: 'TYPE',
        name: 'type',
        sorting: 'type',
        align: GenericTableColumnAlign.Center,
        displayAt: 1024,
        value: packaging => this.translateService.instant(`general.types.${packaging.type.toUpperCase()}`),
        color: packaging => packaging.type === CROP_TYPES.PLANT ? 'primary' : 'warn'
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: packaging => [ ...this.PACKAGINGS_BASE_ROUTE, PACKAGINGS_ROUTES.detail, packaging._id]
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            link: packaging => [ ...this.PACKAGINGS_BASE_ROUTE, PACKAGINGS_ROUTES.edit, packaging._id]
          }),
          new TableMenuOption({
            text: 'DUPLICATE',
            icon: GenericMenuOptionIcon.Copy,
            link: packaging => [ ...this.PACKAGINGS_BASE_ROUTE, PACKAGINGS_ROUTES.clone, packaging._id]
          })
        ]
      })
    ]
  });

}
