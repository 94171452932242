import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-campaigns-detail-working-days-mass-edit-errors',
  templateUrl: './campaigns-detail-working-days-mass-edit-errors.component.html',
  styleUrls: ['./campaigns-detail-working-days-mass-edit-errors.component.scss']
})
export class CampaignsDetailWorkingDaysMassEditErrorsComponent implements OnInit {

  error: string;
  alert: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CampaignsDetailWorkingDaysMassEditErrorsComponent>
  ) { }

  ngOnInit(): void {
    const {message} = this.data.error.error;
    this.error = this.data.error.error.type? message : 'campaigns.workingDays.mass_edit_dialog.ERROR';
    this.alert = this.data.error.error.type === 'ALERT';
  }

  confirm = () => this.dialogRef.close(true);

}
