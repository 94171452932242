import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { DeviceReceived } from '@routes/devices/device.model';
import { DeviceService } from '@routes/devices/device.service';
import { DevicesDialogComponent } from '@routes/devices/devices-modal/devices-dialog.component';

import { Pagination } from '@shared/models/pagination.model';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { FiltersConfiguration, FiltersControlOption, FiltersControlSelect } from '@shared/components/generic-filters/generic-filters.model';
import { DeviceRole, DeviceStatus } from '@shared/constants/device.constants';
import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { FORM_MODES } from '@shared/constants/forms';
import { BuildService } from '@shared/services/build.service';
import { CountryService } from '@shared/services/country.service';
import { ICountry } from '@shared/interfaces/country.models';

@Component({
  selector: 'app-devices-table',
  templateUrl: './devices-table.component.html',
  styleUrls: ['./devices-table.component.scss']
})
export class DevicesTableComponent implements OnInit {

  tableConfig: TableConfiguration<DeviceReceived>;
  filtersConfig: FiltersConfiguration;
  currentVersion: string;
  countries: ICountry[];

  constructor(
    private buildService: BuildService,
    private translateService: TranslateService,
    private deviceService: DeviceService,
    private countryService: CountryService,
    private dialog: MatDialog
  ) {}

  private displayDialog = (device: DeviceReceived, mode: FORM_MODES) => this.dialog.open(DevicesDialogComponent, {
    data: { device, mode },
    width: '420px'
  }).afterClosed().subscribe(() => this.retrieveDevices(new Pagination(this.tableConfig.pagination)));

  ngOnInit(): void {
    this.countries = this.countryService.getCountries();
    this.getCurrentAppVersion();
    this.initFilters();
    this.initTable();
  }

  initFilters = () => this.filtersConfig = new FiltersConfiguration({
    controls: [
      new FiltersControlSelect({
        label: 'APP_VERSION',
        name: 'appVersion',
        multiple: false,
        value: [],
        options: new BehaviorSubject([])
      }),
      new FiltersControlSelect({
        label: 'STATUSES',
        name: 'statuses',
        value: [],
        options: new BehaviorSubject(Object.entries(DeviceStatus).map((status): FiltersControlOption => ({
          display: this.translateService.instant(`devices.status.${status[0].toUpperCase()}`),
          value: status[1]
        })))
      }),
      new FiltersControlSelect({
        label: 'COUNTRY',
        name: 'country',
        value: [],
        options: new BehaviorSubject(this.countries.map(({code, name}) => new FiltersControlOption({
          display: name, value: code
        })))
      }),
      new FiltersControlSelect({
        label: 'ROLES',
        name: 'roles',
        value: [],
        options: new BehaviorSubject(Object.entries(DeviceRole).map((role): FiltersControlOption => ({
          display: this.translateService.instant(`devices.roles.${role[0].toUpperCase()}`),
          value: role[1]
        })))
      })
    ],
    buttons: []
  });

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'devices.table',
    data: new BehaviorSubject({ data: [], total: 0 }),
    pagination: {
      page: 1, size: 10, search: '', params: {deleted: false},
      sort: {field: 'name', order: 1}
    },
    columns: [
      new TableColumnText({
        header: 'NAME',
        name: 'name',
        sorting: 'name',
        displayAt: 0,
        value: device => device.name
      }),
      new TableColumnText({
        header: 'COUNTRY',
        name: 'country',
        sorting: 'country',
        displayAt: 860,
        value: device => this.countries.find(country => country.code === device.country)?.name
      }),
      new TableColumnText({
        header: 'APP_VERSION',
        name: 'appVersion',
        sorting: 'appVersion',
        displayAt: 1650,
        value: device => device.appVersion
      }),
      new TableColumnText({
        header: 'STATUS',
        name: 'status',
        sorting: 'status',
        displayAt: 520,
        value: device => this.translateService.instant(`devices.status.${device.status.toUpperCase()}`)
      }),
      new TableColumnText({
        header: 'ROLE',
        name: 'role',
        sorting: 'role',
        displayAt: 1024,
        value: device => device.role ? this.translateService.instant(`devices.roles.${device.role.toUpperCase()}`) : null
      }),
      new TableColumnText({
        header: 'CAMPAIGN',
        name: 'campaign',
        sorting: 'campaign.name',
        displayAt: 1280,
        value: device => device.campaign?.name
      }),
      new TableColumnText({
        header: 'WAREHOUSE',
        name: 'warehouse',
        sorting: 'warehouse.name',
        displayAt: 1280,
        value: device => device.warehouse?.name
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'APPROVE_DEVICE',
            icon: GenericMenuOptionIcon.DeviceApproval,
            action: device => this.displayDialog(device, FORM_MODES.add),
            disabled: device => device.status !== DeviceStatus.Pending
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            action: device => this.displayDialog(device, FORM_MODES.edit),
            disabled: device => device.status !== DeviceStatus.Approved
          }),
          new TableMenuOption({
            text: 'DELETE',
            icon: GenericMenuOptionIcon.Delete,
            action: device => this.displayDialog(device, FORM_MODES.delete)
          })
        ]
      })
    ]
  });

  retrieveDevices = async (pagination: Pagination) => {
    const response = await this.deviceService.getDevices(pagination);
    this.tableConfig.data.next({ data: response.devices, total: response.total });
  }

  getCurrentAppVersion = async () => {
    this.currentVersion = (await this.buildService.getLatestBuild()).version;
    this.filtersConfig.updateControlOptions('appVersion', [
      {
        display: 'Todos',
        value: null
      }, {
        display: this.currentVersion,
        value: this.currentVersion,
      }, {
        display: 'Otras versiones',
        value: encodeURIComponent(`^(?!${this.currentVersion}$).+$`)
      }
    ]);
  }

}
