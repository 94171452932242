<h3>{{ 'users.dialog.PASSWORD' | translate }}</h3>
<p class="alert alert-secondary">{{ 'users.message.PASSWORD_EXPLANATION' | translate }}</p>
<form [formGroup]="form" class="form">
    <!-- Password -->
    <mat-form-field appearance="fill">
        <mat-label>{{ 'users.main.PASSWORD' | translate }}</mat-label>
        <input matInput type="password" formControlName="password">
    </mat-form-field>
    <!-- Password repeat -->
    <mat-form-field appearance="fill">
        <mat-label>{{ 'users.main.REPEAT_PASSWORD' | translate }}</mat-label>
        <input matInput type="password" formControlName="repeat">
    </mat-form-field>
</form>
<div class="buttons">
    <button mat-flat-button color="warn" class="btn" type="button" (click)="dialogRef.close()">
        <span class="btn-text">{{ 'general.actions.CANCEL' | translate }}</span>
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" (click)="editPassword()" [disabled]="form.invalid">
        <span class="btn-text">{{ 'general.actions.CONFIRM' | translate }}</span>
    </button>
</div>