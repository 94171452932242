<h2>{{ getFormTitle() | uppercase }}</h2>
<form [formGroup]="form" class="form" (submit)="submitForm()">
    <!-- Username -->
    <mat-form-field appearance="standard">
        <mat-label>{{ 'users.main.USERNAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="username">
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
    </mat-form-field>
    <!-- Password -->
    <mat-form-field appearance="standard" *ngIf="mode === USERS_FORM_MODES.add">
        <mat-label>{{ 'users.main.PASSWORD' | translate }}</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
    </mat-form-field>
    <!-- Password repeat -->
    <mat-form-field appearance="standard" *ngIf="mode === USERS_FORM_MODES.add">
        <mat-label>{{ 'users.main.REPEAT_PASSWORD' | translate }}</mat-label>
        <input matInput type="password" formControlName="repeat">
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
    </mat-form-field>
    <!-- Name -->
    <mat-form-field appearance="standard">
        <mat-label>{{ 'users.main.NAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
    </mat-form-field>
    <!-- E-mail -->
    <mat-form-field appearance="standard">
        <mat-label>{{ 'users.main.EMAIL' | translate }}</mat-label>
        <input matInput type="text" formControlName="email">
        <mat-hint>* {{'general.form.MANDATORY' | translate}}</mat-hint>
    </mat-form-field>
    <!-- Role -->
    <mat-form-field appearance="fill">
        <mat-label>{{ 'users.main.ROLE' | translate }}</mat-label>
        <mat-select formControlName="role">
            <mat-option *ngFor="let role of USERS_ROLES | keyvalue" [value]="role.value">{{ 'general.roles.' + role.value | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
</form>
<div class="buttons" [ngClass]="{ 'detail': mode === USERS_FORM_MODES.detail, 'edit': mode !== USERS_FORM_MODES.detail }">
    <button mat-flat-button color="warn" class="btn" type="button" [routerLink]="EXIT_ROUTE" *ngIf="mode !== USERS_FORM_MODES.detail">
        <span class="btn-text">{{ 'general.actions.CANCEL' | translate }}</span>
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" (click)="submitForm()" [disabled]="form.invalid" *ngIf="mode !== USERS_FORM_MODES.detail">
        <span class="btn-text">{{ 'general.actions.CONFIRM' | translate }}</span>
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" [routerLink]="EXIT_ROUTE" *ngIf="mode === USERS_FORM_MODES.detail">
        <span class="btn-text">{{ 'general.actions.BACK' | translate }}</span>
    </button>
</div>