<form [formGroup]="form" (submit)="updateWorker()">
  <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="name">{{ 'workers.detail.form.NAME' | translate }}</mat-label>
    <input matInput type="text" name="name" id="name" formControlName="name">
    <mat-hint *ngIf="editMode">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
    <mat-label for="surname">{{ 'workers.detail.form.SURNAME' | translate }}</mat-label>
    <input matInput type="text" name="surname" id="surname" formControlName="surname">
    <mat-hint *ngIf="editMode">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="status">{{ 'workers.detail.form.STATUS' | translate }}</mat-label>
    <mat-select formControlName="status">
      <mat-option *ngFor="let status of workerStatuses; let i = index" value="{{ status.value }}">
        {{ 'workers.detail.form.' + status.tag | translate }}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="editMode">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
    <mat-label for="country">{{ 'workers.detail.form.WORK_COUNTRY' | translate }}</mat-label>
    <mat-select formControlName="country">
      <mat-option *ngFor="let country of countries" [value]="country.code">
        {{ country.name }}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="editMode">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="socialSecurityNumber">{{ 'workers.detail.form.SOCIAL_SECURITY_NUMBER' | translate }}</mat-label>
    <input matInput type="text" name="socialSecurityNumber" id="socialSecurityNumber" formControlName="socialSecurityNumber">
    <mat-hint *ngIf="form.get('socialSecurityNumber').hasValidator(Validators.required)">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
</mat-form-field>

<mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
    <mat-label for="phoneNumber">{{ 'workers.detail.form.PHONE_NUMBER' | translate }}</mat-label>
    <input matInput type="text" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber">
    <mat-hint *ngIf="form.get('phoneNumber').hasValidator(Validators.required)">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
</mat-form-field>

<mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="gender">{{ 'workers.detail.form.GENDER' | translate }}</mat-label>
    <mat-select formControlName="gender">
        <mat-option *ngFor="let gender of workerGenders" value="{{ gender.value }}">
            {{ 'workers.gender.' + gender.tag | translate }}
        </mat-option>
    </mat-select>
    <mat-hint *ngIf="form.get('gender').hasValidator(Validators.required)">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
</mat-form-field>

<mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
  <mat-label for="maritalStatus">{{ 'workers.detail.form.MARITAL_STATUS' | translate }}</mat-label>
  <mat-select formControlName="maritalStatus">
      <mat-option *ngFor="let maritalStatus of workerMaritalStatuses" value="{{ maritalStatus.value }}">
          {{ 'workers.maritalStatus.' + maritalStatus.tag | translate }}
      </mat-option>
  </mat-select>
  <mat-hint *ngIf="form.get('maritalStatus').hasValidator(Validators.required)">* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
</mat-form-field>

<mat-form-field class="col-6 pl-0 mb-4 input-date" appearance="standard">
    <mat-label for="birthDate">{{ 'workers.detail.form.BIRTH_DATE' | translate }}</mat-label>
    <input matInput id="birthDate" name="birthDate" [matDatepicker]="birthDate" formControlName="birthDate">
    <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
    <mat-datepicker #birthDate></mat-datepicker>
    <mat-hint *ngIf="form.get('birthDate').hasValidator(Validators.required)">* {{ 'general.form.MANDATORY_LONG_DATE' | translate }}</mat-hint>
</mat-form-field>

  <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
    <mat-label for="nationality">{{ 'workers.detail.form.NATIONALITY' | translate }}</mat-label>
    <input matInput type="text" name="nationality" id="nationality" formControlName="nationality">
  </mat-form-field>

  <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="nif">{{ 'workers.detail.form.NIF' | translate }}</mat-label>
    <input matInput type="text" name="nif" id="nif" formControlName="nif">
  </mat-form-field>

  <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
    <mat-label for="sequentialId">{{ 'workers.detail.form.SEQUENTIAL_ID' | translate }}</mat-label>
    <input disabled matInput type="text" name="sequentialId" id="sequentialId" value="{{ worker.sequentialId }}">
    <mat-hint *ngIf="editMode">* {{ 'general.form.NOT_EDITABLE' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="alphanumericCode">{{ 'workers.detail.form.ALPHANUMERIC_CODE' | translate }}</mat-label>
    <input matInput type="text" name="alphanumericCode" id="alphanumericCode" formControlName="alphanumericCode"
      value="{{ worker.alphanumericCode }}">
  </mat-form-field>


  <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
    <mat-label for="position">{{ 'workers.detail.form.POSITION' | translate }}</mat-label>
    <input disabled matInput type="text" name="position" id="position"
      value="{{ worker.position?.name || '-' }}">
    <mat-hint *ngIf="editMode">* {{ 'workers.detail.form.HINT_HISTORIC' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="agency">{{ 'workers.detail.form.AGENCY' | translate }}</mat-label>
    <input disabled matInput type="text" name="agency" id="agency"
      value="{{ worker.agency?.name || ('workers.detail.form.WITHOUT_AGENCY' | translate) }}">
    <mat-hint *ngIf="editMode">* {{ 'workers.detail.form.HINT_HISTORIC' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
    <mat-label for="campaign">{{ 'workers.detail.form.CAMPAIGN' | translate }}</mat-label>
    <input disabled matInput type="text" name="campaign" id="campaign" value="{{ worker.campaign?.name || '-' }}">
    <mat-hint *ngIf="editMode">* {{ 'workers.detail.form.HINT_HISTORIC' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="site">{{ 'workers.detail.form.SITE' | translate }}</mat-label>
    <input disabled matInput type="text" name="site" id="site" value="{{ worker.site?.name || '-' }}">
    <mat-hint *ngIf="editMode">* {{ 'workers.detail.form.HINT_HISTORIC' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
    <mat-label for="address">{{ 'workers.detail.form.ADDRESS' | translate }}</mat-label>
    <input disabled matInput type="text" name="address" id="address" value="{{ worker.addresses[0]?.address }}">
    <mat-hint *ngIf="editMode">* {{ 'workers.detail.form.HINT_HISTORIC' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
    <mat-label for="taxResidence">{{ 'workers.detail.form.TAX_RESIDENCE' | translate }}</mat-label>
    <input matInput type="text" name="taxResidence" id="taxResidence" formControlName="taxResidence">
  </mat-form-field>

  <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
    <mat-label for="iban">{{ 'workers.detail.form.IBAN' | translate }}</mat-label>
    <input matInput type="text" name="iban" id="iban" formControlName="iban">
  </mat-form-field>

  <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
    <mat-label for="keychain">{{ 'workers.detail.form.KEYCHAIN' | translate }}</mat-label>
    <input matInput type="text" name="keychain" id="keychain" formControlName="keychain"
      [matAutocomplete]="keychainAuto" placeholder="Escribe para mostrar resultados" #keychain>
    <mat-autocomplete #keychainAuto="matAutocomplete" [displayWith]="displayKeychain"
      (optionSelected)="keychainAvailable($event)">
      <mat-option *ngFor="let keychain of keychains" [value]="keychain">{{ keychain.code }} ({{ keychain.tag }})
      </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="form.get('keychain').disabled && editMode">
      {{ 'workers.detail.form.KEYCHAIN_HINT' | translate }}
    </mat-hint>
  </mat-form-field>
  <div class="alert alert-warning mt-n4" role="alert" *ngIf="keychainAssignedMessage">
    {{ keychainAssignedMessage }}
  </div>

  <mat-form-field class="col-12 px-0 mb-1" appareance="standard">
    <mat-label for="recommendedBy">{{ 'workers.detail.form.RECOMMENDED_BY' | translate }}</mat-label>
    <input matInput type="text" name="recommendedBy" id="recommendedBy" formControlName="recommendedBy"
      [matAutocomplete]="recomendedAuto" placeholder="Escribe para mostrar resultados" #recommended>
    <mat-autocomplete #recomendedAuto="matAutocomplete" [displayWith]="displayWorker">
      <mat-option *ngFor="let worker of workers" [value]="worker">
        {{ worker.name }} {{ worker.surname }} ({{ worker.sequentialId }})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="col-12 px-0 mb-1">
    <mat-checkbox color="primary" [checked]="worker.promoter" formControlName="promoter">
      {{ 'workers.detail.form.PROMOTER' | translate }}</mat-checkbox>
  </div>
  <div class="col-12 px-0 mt-4 d-flex justify-content-between">
    <button mat-flat-button color="primary" class="btn" type="button"
      [routerLink]="['../..']">{{ 'general.actions.BACK' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" *ngIf="!editMode"
      [routerLink]="EDIT_URL">{{ 'general.actions.EDIT' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="submit" *ngIf="editMode"
      [disabled]="form.invalid">{{ 'general.actions.UPDATE' | translate }}
    </button>
  </div>
</form>