import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, map } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';

import { FiltersConfiguration, FiltersControlDate, FiltersControlGroupOption, FiltersControlGroupSelect, FiltersControlOption, FiltersControlSelect, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { ButtonType, FilterOperator, FilterType, LayoutDirection } from '@shared/components/generic-filters/generic-filters.constants';
import { GenericFiltersService } from '@shared/components/generic-filters/generic-filters-service.service';
import { TIME_REG_EXP } from '@shared/constants/regexp';
import { GenericButtonColor } from '@shared/components/generic-button/generic-button.constants';

@Component({
  selector: 'app-generic-filters',
  templateUrl: './generic-filters.component.html',
  styleUrls: ['./generic-filters.component.scss']
})
export class GenericFiltersComponent implements OnInit, AfterViewInit, OnDestroy {

  FilterType = FilterType;
  ButtonType = ButtonType;
  GenericButtonColor = GenericButtonColor;

  TIME_REG_EXP = TIME_REG_EXP;

  @Input() filtersConfig: FiltersConfiguration;
  @Output() filtersEvent: EventEmitter<FiltersEvent> = new EventEmitter<FiltersEvent>();
  @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('searcher', {static: false}) searcher: ElementRef;

  subscriptions: Subscription[] = [];
  form: FormGroup;
  options: { [name: string]: FiltersControlOption[] } = {};
  groupOptions: { [name: string]: FiltersControlGroupOption[] } = {};
  searchPattern: string;
  LayoutDirection = LayoutDirection;

  constructor(
    private genericFiltersService: GenericFiltersService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.manageObservables();
    this.initFiltersForm();
    this.setInitialFiltersValue();
    this.initReactiveChanges();
    this.form.valueChanges.subscribe(() => this.validationEvent.emit(this.form.valid));
  }

  ngAfterViewInit(): void {
    this.setupSearcher();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  manageObservables = () => {
    this.filtersConfig.controls
      .filter(control => control.type === FilterType.Select)
      .forEach((control: FiltersControlSelect) => {
        const subscription = control.options.subscribe(options => this.options[control.name] = options);
        this.subscriptions.push(subscription);
    })
    this.filtersConfig.controls
      .filter(control => control.type === FilterType.GroupSelect)
      .forEach((control: FiltersControlGroupSelect) => {
        const subscription = control.options.subscribe(options => this.groupOptions[control.name] = options);
        this.subscriptions.push(subscription);
    })
  }

  setupSearcher = () => {
    if (!this.filtersConfig.search) return;
    const subscription = fromEvent(this.searcher.nativeElement, 'input')
      .pipe(
        map((event: InputEvent) => (event.target as HTMLInputElement).value),
        debounceTime(500))
      .subscribe(search => this.formatFilterValue(search));
    this.subscriptions.push(subscription);
  }

  initFiltersForm = () => {
    this.form = new FormGroup({});

    this.filtersConfig.controls.forEach(control => {
      const formControl = new FormControl(control.value, control.validatorFn);
      formControl.markAllAsTouched();
      this.form.addControl(control.name, formControl);
    });
  }

  setInitialFiltersValue = () => {
    const values = {};

    this.filtersConfig.controls.forEach(control => {
      const serviceValue = this.genericFiltersService.getFiltersValue();
      const value = serviceValue && serviceValue[control.name]
        ? serviceValue[control.name]
        : control.value;
      if (value && (control as FiltersControlDate).modifier) (control as FiltersControlDate).modifier(value);
      if (value) values[control.name] = value;
    });
    this.form.patchValue(values);
    this.formatFilterValue(this.searchPattern);
  }

  getSelectorTriggerText = (controlName: string) => {
    const selected = this.form.controls[controlName].value;
    const options = this.options[controlName];
    return selected?.length === options.length
      ? this.translateService.instant('general.filters.EVERY_OPTION_SELECTED')
      : options.filter(o => Array.isArray(selected) ? selected.includes(o.value) : selected === o.value).map(o => o.display).join(' / ');
  }

  initReactiveChanges = () => Object.entries(this.form.controls).forEach(entry => {
    const formName = entry[0];
    const formControl = entry[1];

    formControl.valueChanges.subscribe(controlValue => {
      const params = {...this.form.value, [formName]: controlValue};
      this.genericFiltersService.setFiltersValue(params);
      setTimeout(() => this.formatFilterValue(this.searchPattern, params));
    });
  });

  formatFilterValue = (search: string, filter?: any) => {
    const params = filter ? {...filter} : {...this.form.value};

    Object.entries(params).forEach(([name, value]) => {
      const field = this.filtersConfig.controls.find(control => control.name === name).field;
      if (value instanceof Date) value = value.toISOString();
      const configurations = this.filtersConfig.controls.filter(control => control.field === field);

      if (!configurations.length) {
        return this.addCondition(params, name, name, value);
      }
      if (configurations.length === 1) {
        return this.addCondition(params, name, field, value, configurations[0].operator);
      }
      const control = configurations.find(control => control.name === name);
      this.addCondition(params, name, field, value, control.operator);
    });
    this.filtersEvent.emit({params, search});
  }

  private addCondition = (params: object, name: string, field: string, value: any, operator?: FilterOperator) => {
    if (!operator) return;
    params[field] && Object.entries(params[field]).length > 0
      ? params[field][operator] = value
      : params[field] = {[operator]: value};
    if (name !== field) delete params[name];
  }

}
