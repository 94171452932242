<div class="main">
    <div class="filters">
        <app-campaigns-detail-working-days-mass-edit-filters>
        </app-campaigns-detail-working-days-mass-edit-filters>
        <div class="buttons">
            <button mat-flat-button matStepperPrevious class="actions-button btn button" type="button" color="primary">
                <span class="btn-text">{{ 'campaigns.workingDays.mass_edit_dialog.steps.PREVIOUS' | translate }}</span>
            </button>
            <button mat-flat-button matStepperNext class="actions-button btn button" type="button" color="primary" [disabled]="form.invalid">
                <span class="btn-text">{{ 'campaigns.workingDays.mass_edit_dialog.steps.NEXT' | translate }}</span>
            </button>
        </div>
    </div>
    <app-campaigns-detail-working-days-mass-edit-form>
    </app-campaigns-detail-working-days-mass-edit-form> 
</div>
