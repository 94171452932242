import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDropFile]'
})
export class DropFileDirective {

  @Output() fileDrop: EventEmitter<File> = new EventEmitter<File>();

  @HostBinding('class.fileover') fileOver: boolean;

  @HostListener('dragover', ['$event']) public onDragOver(event: DragEvent) {
    this.eventHandler(event);
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
    this.eventHandler(event);
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(event: DragEvent) {
    this.eventHandler(event);
    this.fileOver = false;
    this.fileDrop.emit(event.dataTransfer.files[0]);
  }

  constructor() {}

  eventHandler = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
  }

}