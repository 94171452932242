<div class="edit-form">
    <div class="section editables form-wrapper">
        <h3 class="form-subtitle">{{ 'campaigns.workingDays.mass_edit_dialog.SUMMARY' | translate }}</h3>
        <ul class="detail">
            <li class="category" *ngIf="editSite">
                <p class="title">{{ 'campaigns.workingDays.SITE' | translate }}</p>
                <p class="content">{{ editSite? editSite : '-' }}</p>
            </li>
            <li class="category" *ngIf="editDevice">
                <p class="title">{{ 'campaigns.workingDays.DEVICE' | translate }}</p>
                <p class="content">{{ editDevice? editDevice : '-' }}</p>
            </li>
            <li class="category" *ngIf="form.value.startDate">
                <p class="title">{{ 'campaigns.workingDays.START_TITLE' | translate }}</p>
                <p class="content">{{ form.value.startDate? (form.value.startDate | date : summaryDateFormat : 'GMT+1') : '-' }}</p>
            </li>
            <li class="category" *ngIf="form.value.endDate">
                <p class="title">{{ 'campaigns.workingDays.END_TITLE' | translate }}</p>
                <p class="content">{{ form.value.endDate? (form.value.endDate | date : summaryDateFormat : 'GMT+1') : '-' }}</p>
            </li>
            <li class="category" *ngIf="form.value.startDateBreak">
                <p class="title">{{ 'campaigns.workingDays.mass_edit_dialog.START_END_BREAK_ADDED' | translate }}</p>
                <p class="content">{{ 'campaigns.workingDays.BREAK_START_DATE' | translate }}: {{ form.value.startDateBreak? (form.value.startDateBreak | date : summaryDateFormat : 'GMT+1') : '-' }}</p>
                <p class="content">{{ 'campaigns.workingDays.BREAK_END_DATE' | translate }}: {{ form.value.endDateBreak? (form.value.endDateBreak | date : summaryDateFormat : 'GMT+1') : '-' }}</p>
            </li>
            <li class="category" *ngIf="form.value.editStartDateBreak">
                <p class="title">{{ 'campaigns.workingDays.mass_edit_dialog.START_END_BREAK_EDITED' | translate }}</p>
                <p class="content">{{ 'campaigns.workingDays.BREAK_START_DATE' | translate }}: {{ form.value.editStartDateBreak? (form.value.editStartDateBreak | date : summaryDateFormat : 'GMT+1') : '-' }}</p>
                <p class="content">{{ 'campaigns.workingDays.BREAK_END_DATE' | translate }}: {{ form.value.editEndDateBreak? (form.value.editEndDateBreak | date : summaryDateFormat : 'GMT+1') : '-' }}</p>
            </li>
        </ul>
        
            <form class="form" [formGroup]="confirmationForm">
                <div class="confirmation-form">
                <h3 class="form-subtitle">{{ 'campaigns.workingDays.mass_edit_dialog.NEW_STATUS' | translate }}</h3>
                <p class="alert alert-warning">{{ 'campaigns.workingDays.mass_edit_dialog.alerts.STATUS_CHANGE' | translate }}</p>
                <mat-form-field class="input" appearance="standard">
                    <mat-label>{{ 'campaigns.workingDays.STATUS' | translate }}</mat-label>
                    <mat-select formControlName="status">
                        <mat-option *ngFor="let status of statuses | keyvalue" [value]="status.value" [disabled]="!avaliableStatuses.includes(status.value)">
                            {{ 'campaigns.workingDays.' + status.key | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-checkbox color="primary" formControlName="confirmEdit">{{ 'campaigns.workingDays.mass_edit_dialog.checkbox.ALL_CHANGES' | translate }}</mat-checkbox>
                </div>
            </form>
        
    </div>
</div> 
