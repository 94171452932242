<h2 class="title h2">{{ 'campaigns.tasks.table.PRODUCTION_INTERVALS' | translate }}</h2>
<app-generic-table
    [tableConfig]="tableConfig">
</app-generic-table>
<p class="total-boxes" *ngIf="isHarvestTask">
    {{ 'campaigns.tasks.table.TOTAL_BOXES' | translate : {totalBoxes: totalBoxes} }}
</p>
<mat-dialog-actions class="buttons">
    <app-generic-button
        [config]="cancelButtonConfig" mat-dialog-close
    ></app-generic-button>
    <app-generic-button
        [config]="editButtonConfig" [disabled]="campaign.status === CampaignStatus.Finished" (click)="editTask()"
    ></app-generic-button>
</mat-dialog-actions>