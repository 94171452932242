export interface IUser {
    _id: string;
    username: string;
    password: string;
    name: string;
    email: string;
    disabled: boolean;
    role: string;
};

export class User implements IUser {
    _id: string;
    username: string;
    password: string;
    name: string;
    email: string;
    disabled: boolean;
    role: string;

    constructor(user: IUser) {
        Object.assign(this, user);
    }
};

export interface IUsersPaginated {
    users: User[];
    total: number;
};
