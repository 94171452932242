import { Injectable } from "@angular/core";
import { ApiService } from '../../shared/services/api.service';
import { Pagination } from '@shared/models/pagination.model';
import { IKeychainPaginated, Keychain, IKeychain, IKeychainFileResponse } from '@routes/keychains/keychain.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UploadData } from "@shared/interfaces/upload";
import { FileMode } from "@shared/constants/files";
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class KeychainService {

    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) {}

    getKeychainsUrl = (): string => `${this.apiService.getBaseUrl()}/keychains`;
    getKeychainUrl = (id: string): string => `${this.getKeychainsUrl()}/${id}`;
    private getKeychainsFileUrl = (mode: string): string => `${this.getKeychainsUrl()}/file/${mode}`;

    getKeychains = (pagination: Pagination): Promise<IKeychainPaginated> =>
        lastValueFrom(this.http.get<IKeychainPaginated>(this.getKeychainsUrl(), { params: pagination.loadHttpParams() })
            .pipe(map(this.parseKeychains)));

    getKeychain = (id: string): Promise<Keychain> =>
        lastValueFrom(this.http.get<Keychain>(this.getKeychainUrl(id)).pipe(map(this.parseKeychain)));

    parseKeychain = (keychain: Keychain): Keychain => new Keychain({ id: keychain._id, ...keychain });

    parseKeychains = (data: IKeychainPaginated): IKeychainPaginated => {
        if (!data) return null;

        return {
            keychains: data.keychains.map(keychain => this.parseKeychain(keychain)),
            total: data.total
        }
    }

    parseFileData = (data: IKeychainFileResponse): UploadData => ({ data: data.createdKeychains, warnings: data.warnings, error: data.error });

    deleteKeychain = (id: string): Promise<void> =>
        lastValueFrom(this.http.delete<void>(this.getKeychainUrl(id)));

    editKeychain = (id: string, data: Keychain): Promise<Keychain> =>
        lastValueFrom(this.http.put<Keychain>(this.getKeychainUrl(id), data).pipe(map(this.parseKeychain)));

    addKeychain = (data: Keychain): Promise<Keychain> =>
        lastValueFrom(this.http.post<Keychain>(this.getKeychainsUrl(), data).pipe(map(this.parseKeychain)));

    addKeychainsByFile = (data: FormData, mode: FileMode): Promise<UploadData> => 
        lastValueFrom(this.http.post<any>(this.getKeychainsFileUrl(mode), data));
    

    

}