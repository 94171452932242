import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Agency } from '@models/agency.model';

import { HISTORIC_SECTIONS } from '@routes/workers/worker.service';
import { WorkersDetailService } from '@routes/workers/workers-detail/workers-detail.service';
import { AgencyService } from '@routes/agencies/agency.service';
import { WorkerReceived } from '@routes/workers/worker.model';
import { WorkingSite } from '@routes/working-sites/working-site.model';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';
import { Position } from '@routes/positions/positions.models';
import { PositionsMainService } from '@routes/positions/positions-main.service';

import { Pagination } from '@shared/models/pagination.model';
import { FORM_MODES } from '@shared/constants/forms';

@Component({
  selector: 'app-workers-detail-historic-form',
  templateUrl: './workers-detail-historic-form.component.html',
  styleUrls: ['./workers-detail-historic-form.component.scss']
})
export class WorkersDetailHistoricFormComponent implements OnInit {

  HistoricSections = HISTORIC_SECTIONS;
  FormMode = FORM_MODES;

  worker: WorkerReceived;
  form: FormGroup;
  section: string;
  formMode: string;
  historicIndex: number;
  historicData: any;
  historicTag: string;

  positions: Position[] = [];
  agencies: Agency[] = [];
  sites: WorkingSite[] = [];
  pagination: Pagination = new Pagination({sort: {field: 'name', order: 1}});

  constructor(
    private detailService: WorkersDetailService,
    private agencyService: AgencyService,
    private positionsMainService: PositionsMainService,
    private sitesService: WorkingSiteService
  ) {}

  async ngOnInit() {
    this.worker = this.detailService.worker;
    this.section = this.detailService.selectorSection;
    this.formMode = this.detailService.formMode;
    this.historicIndex = this.detailService.historicIndex;
    this.historicTag = this.detailService.getHistoricTag(this.section);

    this.form = new FormGroup({
      value: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null)
    });
    await this.retrieveReferenceData();
    if (this.formMode === FORM_MODES.edit) this.patchForm();
    if (this.section === HISTORIC_SECTIONS.AGENCIES) this.form.get('value').clearValidators();
  }

  retrieveReferenceData = async () => {
    const result = await Promise.all([
      this.sitesService.getSites(this.pagination),
      this.agencyService.getAgencies(this.pagination),
      this.positionsMainService.getAll(this.pagination)
    ]);
    this.sites = result[0].sites;
    this.agencies = result[1].data;
    this.positions = result[2].positions;
  }

  patchForm = () => {
    this.historicData = this.detailService.worker[this.section][this.historicIndex];

    const value = new Map([
      [this.HistoricSections.AGENCIES, this.historicData.agencyId],
      [this.HistoricSections.SITES, this.historicData.siteId],
      [this.HistoricSections.POSITIONS, this.historicData.positionId],
      [this.HistoricSections.ADDRESSES, this.historicData.address],
    ]).get(this.section as HISTORIC_SECTIONS);

    this.form.patchValue({ ...this.historicData, value });
  }

  submitForm = async (): Promise<void> => {
    let key: string;

    switch (this.section) {
      case this.HistoricSections.POSITIONS: key = 'positionId'; break;
      case this.HistoricSections.AGENCIES: key = 'agencyId'; break;
      case this.HistoricSections.ADDRESSES: key = 'address'; break;
      case this.HistoricSections.SITES: key = 'siteId'; break;
    }
    const data = {
      ...{ [key]: this.form.value.value },
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate
    };
    const historicData = JSON.parse(JSON.stringify(this.worker[this.section]));

    this.formMode === FORM_MODES.add
      ? historicData.push(data)
      : historicData[this.historicIndex] = data;

    return await this.detailService.editWorkerHistoric(this.worker.id, this.section, historicData);
  }

  cancelEdit = (): void => this.detailService.setHistoricSection('table');

}