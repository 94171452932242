import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IDevicesPaginated, DeviceSent, DeviceReceived, IDeviceHelp } from '@routes/devices/device.model';
import { Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    private getDevicesUrl = (): string => `${this.apiService.getBaseUrl()}/devices`;
    private getDeviceUrl = (id: string): string => `${this.getDevicesUrl()}/${id}`;
    private getDeviceHelpUrl = () => `${this.getDevicesUrl()}/faq`;

    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) {}

    getDevices = (pagination: Pagination): Promise<IDevicesPaginated> =>
        lastValueFrom(this.http.get<IDevicesPaginated>(this.getDevicesUrl(), { params: pagination.loadHttpParams() }));

    getDevice = (_id: string): Promise<DeviceReceived> =>
        lastValueFrom(this.http.get<DeviceReceived>(this.getDeviceUrl(_id)));

    getDeviceFAQ = (): Promise<IDeviceHelp> =>
        lastValueFrom(this.http.get<IDeviceHelp>(this.getDeviceHelpUrl()));

    editDevice = (_id: string, device: DeviceSent): Promise<DeviceReceived> =>
        lastValueFrom(this.http.put<DeviceReceived>(this.getDeviceUrl(_id), device));

    deleteDevice = (_id: string): Promise<void> =>
        lastValueFrom(this.http.delete<void>(this.getDeviceUrl(_id)));
    
}