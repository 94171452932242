import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICrop } from '@routes/crops/crop.model';

import { ApiService } from '@shared/services/api.service';
import { Pagination } from '@shared/models/pagination.model';
import { IPaginatedData } from '@shared/models/query.models';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CropService {

    constructor(
        private http: HttpClient,
        private apiService: ApiService
    ) {}

    private getCropsUrl = (): string => `${this.apiService.getBaseUrl()}/crops`;
    private getCropUrl = (id: string): string => `${this.apiService.getBaseUrl()}/crops/${id}`;

    getCrops = (pagination?: Pagination): Promise<IPaginatedData<ICrop>> => 
        lastValueFrom(this.http.get<IPaginatedData<ICrop>>(this.getCropsUrl(), {params: pagination?.loadHttpParams()}));

    getCrop = (id: string): Promise<ICrop> =>
        lastValueFrom(this.http.get<ICrop>(this.getCropUrl(id)));

    addCrop = (crop: ICrop): Promise<ICrop> =>
        lastValueFrom(this.http.post<ICrop>(this.getCropsUrl(), crop));

    editCrop = (_id: string, crop: ICrop): Promise<ICrop> =>
        lastValueFrom(this.http.put<ICrop>(this.getCropUrl(_id), crop));

    deleteCrop = (id: string): Promise<object> =>
        lastValueFrom(this.http.delete(this.getCropUrl(id)));

}
