import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { IWorkerPaginated, WorkerReceived, WorkerSent, IWorkerReceived, WorkerReceivedGroup, IWorkerFileResponse } from '@routes/workers/worker.model';

import { UploadData } from '@shared/interfaces/upload';
import { Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  private getWorkersUrl = () => `${this.apiService.getBaseUrl()}/workers`;
  private getWorkersFileUrl = (mode: string) => `${this.getWorkersUrl()}/file/${mode}`;
  private getWorkersExportUrl = () => `${this.getWorkersUrl()}/export`;
  private getWorkersTemplateUrl = () => `${this.getWorkersUrl()}/template`;
  private getWorkerUrl = (id: string) => `${this.getWorkersUrl()}/${id}`;
  private getWorkerHistoricUrl = (id: string, section: string) => `${this.getWorkerUrl(id)}/${section}`;

  getWorkers = (pagination: Pagination): Promise<IWorkerPaginated> =>
    lastValueFrom(this.http.get<IWorkerPaginated>(this.getWorkersUrl(), { params: pagination.loadHttpParams() })
      .pipe(map(this.parseWorkerReceivedGroup)));

  getWorker = (id: string): Promise<WorkerReceived> =>
    lastValueFrom(this.http.get<WorkerReceived>(this.getWorkerUrl(id)).pipe(map(this.parseWorkerReceived)));

  addWorker = (data: WorkerSent): Promise<WorkerReceived> =>
    lastValueFrom(this.http.post<WorkerReceived>(this.getWorkersUrl(), data).pipe(map(this.parseWorkerReceived)));

  addWorkersByFile = (data: FormData, mode: string): Promise<UploadData> =>
    lastValueFrom(this.http.post<UploadData>(this.getWorkersFileUrl(mode), data));

  deleteWorker = (id: string): Promise<void> =>
    lastValueFrom(this.http.delete<void>(this.getWorkerUrl(id)));

  editWorker = (id: string, data: WorkerSent): Promise<WorkerReceived> =>
    lastValueFrom(this.http.put<WorkerReceived>(this.getWorkerUrl(id), data).pipe(map(this.parseWorkerReceived)));

  editWorkerHistoric = (id: string, section: string, data: any): Promise<any> =>
    lastValueFrom(this.http.put<WorkerReceived>(this.getWorkerHistoricUrl(id, section), data).pipe(map(this.parseWorkerReceived)));

  updateWorker = (_id: string, worker: Partial<WorkerReceived>) =>
    lastValueFrom(this.http.patch<WorkerReceived>(this.getWorkerUrl(_id), worker));

  parseWorkerReceived = (worker: IWorkerReceived): WorkerReceived =>
    new WorkerReceived({ id: worker._id, ...worker })

  parseWorkerReceivedGroup = (obj: IWorkerPaginated): IWorkerPaginated => {
    if (!obj) return null;

    return {
      workers: obj.workers.map(worker => new WorkerReceivedGroup({ id: worker._id, ...worker })),
      total: obj.total
    };
  }

  downloadWorkers = (pagination: Pagination): Promise<Blob> =>
    lastValueFrom(this.http.get(this.getWorkersExportUrl(), {responseType: 'blob', params: pagination.loadHttpParams()}));

  downloadTemplate = () => lastValueFrom(this.http.get(this.getWorkersTemplateUrl(), {responseType: 'blob'}));

}

export enum HISTORIC_SECTIONS {
  CAMPAIGNS = 'campaigns',
  POSITIONS = 'positions',
  AGENCIES = 'agencies',
  ADDRESSES = 'addresses',
  SITES = 'sites'
}
