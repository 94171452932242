<h2 class="h2">{{ "workers.add.TITLE" | translate }}</h2>
<form [formGroup]="form" (submit)="createWorker()">
  <div
    class="form-section"
    formGroupName="personal"
    [hidden]="section !== FormSection.Personal"
  >
    <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
      <mat-label for="name">{{
        "workers.detail.form.NAME" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="name"
        id="name"
        formControlName="name"
      />
      <mat-hint>* {{ "general.form.MANDATORY" | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
      <mat-label for="surname">{{
        "workers.detail.form.SURNAME" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="surname"
        id="surname"
        formControlName="surname"
      />
      <mat-hint>* {{ "general.form.MANDATORY" | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
      <mat-label for="nationality">{{
        "workers.detail.form.NATIONALITY" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="nationality"
        id="nationality"
        formControlName="nationality"
      />
    </mat-form-field>

    <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
      <mat-label for="nif">{{
        "workers.detail.form.NIF" | translate
      }}</mat-label>
      <input matInput type="text" name="nif" id="nif" formControlName="nif" />
    </mat-form-field>

    <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
      <mat-label for="address">{{
        "workers.detail.form.ADDRESS" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="address"
        id="address"
        formControlName="address"
      />
    </mat-form-field>

    <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
      <mat-label for="taxResidence">{{
        "workers.detail.form.TAX_RESIDENCE" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="taxResidence"
        id="taxResidence"
        formControlName="taxResidence"
      />
    </mat-form-field>

    <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
      <mat-label for="iban">{{
        "workers.detail.form.IBAN" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="iban"
        id="iban"
        formControlName="iban"
      />
    </mat-form-field>

    <div class="d-flex justify-content-between">
      <button
        mat-flat-button
        color="warn"
        class="btn"
        type="button"
        [routerLink]="WORKERS_LIST_ROUTE"
      >
        {{ "general.actions.CANCEL" | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="btn"
        type="button"
        [disabled]="form.controls.personal.invalid"
        (click)="section = FormSection.Professional"
      >
        {{ "general.actions.CONTINUE" | translate }}
      </button>
    </div>
  </div>

  <div
    class="form-section"
    formGroupName="professional"
    [hidden]="section !== FormSection.Professional"
  >
    <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
      <mat-label for="status">{{
        "workers.detail.form.STATUS" | translate
      }}</mat-label>
      <mat-select formControlName="status">
        <mat-option
          *ngFor="let status of workerStatuses"
          value="{{ status.value }}"
        >
          {{ "workers.detail.form." + status.tag | translate }}
        </mat-option>
      </mat-select>
      <mat-hint>* {{ "general.form.MANDATORY" | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
      <mat-label for="status">{{
        "workers.detail.form.WORK_COUNTRY" | translate
      }}</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of countries" [value]="country.code">
          {{ country.name }}
        </mat-option>
      </mat-select>
      <mat-hint>* {{ "general.form.MANDATORY" | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
      <mat-label for="socialSecurityNumber">{{
        "workers.detail.form.SOCIAL_SECURITY_NUMBER" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="socialSecurityNumber"
        id="socialSecurityNumber"
        formControlName="socialSecurityNumber"
      />
      <mat-hint
        *ngIf="
          form.controls.professional
            .get('socialSecurityNumber')
            .hasValidator(Validators.required)
        "
        >* {{ "general.form.MANDATORY" | translate }}</mat-hint
      >
    </mat-form-field>

    <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
      <mat-label for="phoneNumber">{{
        "workers.detail.form.PHONE_NUMBER" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="phoneNumber"
        id="phoneNumber"
        formControlName="phoneNumber"
      />
      <mat-hint
        *ngIf="
          form.controls.professional
            .get('phoneNumber')
            .hasValidator(Validators.required)
        "
        >* {{ "general.form.MANDATORY" | translate }}</mat-hint
      >
    </mat-form-field>

    <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
      <mat-label for="gender">{{
        "workers.detail.form.GENDER" | translate
      }}</mat-label>
      <mat-select formControlName="gender">
        <mat-option
          *ngFor="let gender of workerGenders"
          value="{{ gender.value }}"
        >
          {{ "workers.gender." + gender.tag | translate }}
        </mat-option>
      </mat-select>
      <mat-hint
        *ngIf="
          form.controls.professional
            .get('gender')
            .hasValidator(Validators.required)
        "
        >* {{ "general.form.MANDATORY" | translate }}</mat-hint
      >
    </mat-form-field>

    <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
      <mat-label for="maritalStatus">{{
        "workers.detail.form.MARITAL_STATUS" | translate
      }}</mat-label>
      <mat-select formControlName="maritalStatus">
        <mat-option
          *ngFor="let maritalStatus of workerMaritalStatuses"
          value="{{ maritalStatus.value }}"
        >
          {{ "workers.maritalStatus." + maritalStatus.tag | translate }}
        </mat-option>
      </mat-select>
      <mat-hint
        *ngIf="
          form.controls.professional
            .get('maritalStatus')
            .hasValidator(Validators.required)
        "
        >* {{ "general.form.MANDATORY" | translate }}</mat-hint
      >
    </mat-form-field>

    <mat-form-field class="col-6 pl-0 mb-4 input-date" appearance="standard">
      <mat-label for="birthDate">{{
        "workers.detail.form.BIRTH_DATE" | translate
      }}</mat-label>
      <input
        matInput
        id="birthDate"
        name="birthDate"
        [matDatepicker]="birthDate"
        formControlName="birthDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="birthDate"
      ></mat-datepicker-toggle>
      <mat-datepicker #birthDate></mat-datepicker>
      <mat-hint
        *ngIf="
          form.controls.professional
            .get('birthDate')
            .hasValidator(Validators.required)
        "
        >* {{ "general.form.MANDATORY_LONG_DATE" | translate }}</mat-hint
      >
    </mat-form-field>

    <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
      <mat-label for="positionId">{{
        "workers.detail.form.POSITION" | translate
      }}</mat-label>
      <mat-select formControlName="positionId">
        <mat-option *ngFor="let position of positions" [value]="position._id">
          {{ position.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
      <mat-label for="campaign">{{
        "workers.detail.form.CAMPAIGN" | translate
      }}</mat-label>
      <mat-select formControlName="campaignId">
        <mat-option
          *ngFor="let campaign of campaigns"
          value="{{ campaign.id }}"
        >
          {{ campaign.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
      <mat-label for="agency">{{
        "workers.detail.form.AGENCY" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="agency"
        id="agency"
        formControlName="agency"
        [matAutocomplete]="agenciesAuto"
        placeholder="Escribe para mostrar resultados"
        #agency
      />
      <mat-autocomplete
        #agenciesAuto="matAutocomplete"
        [displayWith]="displayAgency"
      >
        <mat-option *ngFor="let agency of agencies" [value]="agency">{{
          agency.name
        }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
      <mat-label for="siteId">{{
        "workers.detail.form.SITE" | translate
      }}</mat-label>
      <mat-select formControlName="siteId" name="siteId" id="siteId">
        <mat-option *ngFor="let site of sites" [value]="site._id">
          {{ site.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-6 pr-0 mb-4" appareance="standard">
      <mat-label for="alphanumericCode">{{
        "workers.detail.form.ALPHANUMERIC_CODE" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="alphanumericCode"
        id="alphanumericCode"
        formControlName="alphanumericCode"
      />
    </mat-form-field>

    <mat-form-field class="col-6 pl-0 mb-4" appareance="standard">
      <mat-label for="keychain">{{
        "workers.detail.form.KEYCHAIN" | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        name="keychain"
        id="keychain"
        formControlName="keychain"
        [matAutocomplete]="keychainAuto"
        placeholder="Escribe para mostrar resultados"
        #keychain
      />
      <mat-autocomplete
        #keychainAuto="matAutocomplete"
        [displayWith]="displayKeychain"
        (optionSelected)="keychainAvailable($event)"
      >
        <mat-option *ngFor="let keychain of keychains" [value]="keychain"
          >{{ keychain.code }} ({{ keychain.tag }})</mat-option
        >
      </mat-autocomplete>
      <mat-hint *ngIf="form.get('professional.keychain').disabled">
        {{ "workers.detail.form.KEYCHAIN_HINT" | translate }}
      </mat-hint>
    </mat-form-field>
    <div
      class="alert alert-warning"
      role="alert"
      *ngIf="keychainAssignedMessage"
    >
      {{ keychainAssignedMessage }}
    </div>

    <mat-form-field class="col-12 px-0 mb-4" appareance="standard">
      <mat-label for="recommendedBy"
        >{{ "workers.detail.form.RECOMMENDED_BY" | translate }}
      </mat-label>
      <input
        matInput
        type="text"
        name="recommendedBy"
        id="recommendedBy"
        formControlName="recommendedBy"
        [matAutocomplete]="recomendedAuto"
        placeholder="Escribe para mostrar resultados"
        #recomended
      />
      <mat-autocomplete
        #recomendedAuto="matAutocomplete"
        [displayWith]="displayWorker"
      >
        <mat-option *ngFor="let worker of workers" [value]="worker"
          >{{ worker.name }} {{ worker.surname }} ({{
            worker.sequentialId
          }})</mat-option
        >
      </mat-autocomplete>
    </mat-form-field>

    <div class="col-12 pr-0 mb-5 mt-3">
      <mat-checkbox color="primary" formControlName="promoter">
        {{ "workers.detail.form.PROMOTER" | translate }}
      </mat-checkbox>
    </div>

    <div class="d-flex justify-content-between">
      <button
        mat-flat-button
        color="primary"
        class="btn"
        type="button"
        (click)="section = FormSection.Personal"
      >
        {{ "general.actions.BACK" | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="btn"
        [disabled]="form.invalid || loading"
      >
        {{ "general.actions.FINISH" | translate }}
      </button>
    </div>
  </div>
</form>
