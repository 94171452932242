import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { HeaderService } from '@layout/header/header.service';
import { CampaignService } from '@routes/campaigns/campaign.service';
import { Campaign } from '@routes/campaigns/campaign.model';

import { IDataPaginated, Pagination } from '@shared/models/pagination.model';
import { PaginationService } from '@shared/services/pagination.service';
import { CAMPAIGN_STATUSES } from '@shared/constants/campaigns';
import { ABS_BASE_ROUTE, SECTIONS, CAMPAIGNS_ROUTES } from '@shared/constants/routes';

@Component({
  selector: 'app-campaigns-active',
  templateUrl: './campaigns-active.component.html',
  styleUrls: ['./campaigns-active.component.scss']
})
export class CampaignsActiveComponent implements OnInit {

  CAMPAIGN_DETAIL_BASE_ROUTE = [
    ABS_BASE_ROUTE, SECTIONS.production,
    CAMPAIGNS_ROUTES.parent, CAMPAIGNS_ROUTES.detail];

  pagination = new Pagination({
    size: 5, page: 1,
    sort: {field: 'startDate', order: 1},
    params: {
      status: CAMPAIGN_STATUSES.ACTIVE,
      type: this.headerService.typeFilter,
    }
  });
  campaigns: Campaign[];
  paginationTotal: number;

  constructor(
    private paginationService: PaginationService,
    private headerService: HeaderService,
    public campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    this.retrieveCampaigns();
    this.headerService.typeFilterChanges().subscribe(type => {
      Object.assign(this.pagination.params, { type });
      this.retrieveCampaigns();
    })
  }

  retrieveCampaigns = async () => {
    const campaigns = await this.campaignService.getLiteCampaigns(this.pagination);
    this.processCampaigns(campaigns);
  }

  processCampaigns = (campaigns: IDataPaginated<Campaign>) => {
    this.campaigns = campaigns.data;
    this.paginationTotal = campaigns.total;
  }

  paginateCampaigns = (event: PageEvent) => {
    this.pagination = this.paginationService.paginate(this.pagination, event);
    this.retrieveCampaigns();
  }

}
