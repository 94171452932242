import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IBatchUniqueReceivedInterval } from '@routes/batchs/batchs.model';

import { GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnText, TableConfiguration } from '@shared/components/generic-table/generic-table.model';

@Component({
  selector: 'app-batchs-detail-intervals',
  templateUrl: './batchs-detail-intervals.component.html',
  styleUrls: ['./batchs-detail-intervals.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class BatchsDetailIntervalsComponent implements OnInit {
  
  @Input() intervals: IBatchUniqueReceivedInterval[];

  tableConfig: TableConfiguration<IBatchUniqueReceivedInterval>;

  constructor(
    private datepipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {
    this.initTable();
  }

  initTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'traceability',
    data: new BehaviorSubject({
      data: this.intervals,
      total: this.intervals.length
    }),
    pagination: {
      page: 1, size: 10, search: '', params: {},
      sort: { field: 'endTimestamp', order: 1 }
    },
    columns: [
      new TableColumnText({
        header: 'WORKER',
        name: 'worker',
        sorting: 'worker.name',
        displayAt: 0,
        value: interval => `${interval.worker.name} ${interval.worker.surname}`,
      }),
      new TableColumnText({
        header: 'PACKAGING',
        name: 'packaging',
        sorting: 'packaging.description',
        displayAt: 0,
        value: interval => `${interval.packaging.description}`,
      }),
      new TableColumnText({
        header: 'BOXES',
        name: 'boxes',
        sorting: 'packaging.boxes',
        displayAt: 0,
        value: interval => interval.packaging.boxes,
      }),
      new TableColumnText({
        header: 'TOTAL',
        name: 'total',
        sorting: 'packaging.total',
        displayAt: 0,
        value: interval => `${this.decimalPipe.transform(interval.packaging.total, '1.0-2')} ${interval.packaging.unit}`,
      }),
      new TableColumnText({
        header: 'TIME',
        name: 'time',
        sorting: 'endTimestamp',
        displayAt: 0,
        value: interval => this.datepipe.transform(interval.endTimestamp, 'HH:mm', 'GMT'),
      })
    ]
  });



}
