import { Component, OnInit } from '@angular/core';
import { REPORTS_I18N_LIST } from '@shared/constants/reports';
import { REPORTS_ROUTES } from '@shared/constants/routes';
import { IReportLink } from '@shared/interfaces/reports';

@Component({
  selector: 'app-campaigns-detail-reports-main',
  templateUrl: './campaigns-detail-reports-main.component.html',
  styleUrls: ['./campaigns-detail-reports-main.component.scss']
})
export class CampaignsDetailReportsMainComponent implements OnInit {

  REPORT_LINKS: IReportLink[] = [
    {
      link: REPORTS_ROUTES.harvest,
      i18n: REPORTS_I18N_LIST.HARVEST
    }, {
      link: REPORTS_ROUTES.harvestWeekly,
      i18n: REPORTS_I18N_LIST.WEEKLY_HARVEST
    }, {
      link: REPORTS_ROUTES.workerHours,
      i18n: REPORTS_I18N_LIST.WORKER_HOURS
    }, {
      link: REPORTS_ROUTES.maintenanceTasks,
      i18n: REPORTS_I18N_LIST.MAINTENANCE_TASKS
    }, {
      link: REPORTS_ROUTES.harvestIncentive,
      i18n: REPORTS_I18N_LIST.HARVEST_INCENTIVE
    }, {
      link: REPORTS_ROUTES.dailyPerformance,
      i18n: REPORTS_I18N_LIST.DAILY_PERFORMANCE
    }, {
      link: REPORTS_ROUTES.weeklyPerformance,
      i18n: REPORTS_I18N_LIST.WEEKLY_PERFORMANCE
    }, {
      link: REPORTS_ROUTES.harvestMaintenanceSummary,
      i18n: REPORTS_I18N_LIST.HARVEST_MAINTENANCE_SUMMARY
    }, {
      link: REPORTS_ROUTES.harvestPackagingCost,
      i18n: REPORTS_I18N_LIST.HARVEST_PACKAGING_COST
    }, {
      link: REPORTS_ROUTES.plantsHarvest,
      i18n: REPORTS_I18N_LIST.PLANTS_HARVEST
    }, {
      link: REPORTS_ROUTES.workableTasks,
      i18n: REPORTS_I18N_LIST.WORKABLE_TASKS
    }, {
      link: REPORTS_ROUTES.workLog,
      i18n: REPORTS_I18N_LIST.WORK_LOG
    }
  ];

  constructor() {}

  ngOnInit(): void {}

}
