import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { WarehousesComponent } from '@routes/warehouses/warehouses.component';
import { WarehousesTableComponent } from '@routes/warehouses/warehouses-table/warehouses-table.component';
import { WarehousesUploadComponent } from '@routes/warehouses/warehouses-upload/warehouses-upload.component';
import { WAREHOUSES_ROUTES } from '@shared/constants/routes';

const routes: Routes = [
  {
    path: '',
    component: WarehousesComponent,
    children: [
      {
        path: '',
        component: WarehousesTableComponent,
        pathMatch: 'full'
      },
      {
        path: WAREHOUSES_ROUTES.upload,
        component: WarehousesUploadComponent,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  declarations: [
    WarehousesComponent,
    WarehousesTableComponent,
    WarehousesUploadComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ]
})
export class WarehousesModule { }
