import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Agency } from '@models/agency.model';
import { AgencyService } from '@routes/agencies/agency.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyResolver implements Resolve<Agency> {

  constructor(
    private agencyService: AgencyService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Agency> {
    return this.agencyService.getAgency(route.params.id);
  }

}
