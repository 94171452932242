import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TIME_REG_EXP } from "@shared/constants/regexp";

export const createIntervalFormGroup = (data: any): FormGroup => {
    const formGroup = new FormGroup({
        _id: new FormControl(null, Validators.required),
        endTimestamp: new FormControl(null, Validators.required),
        amount: new FormControl(null, Validators.required),
        endedWithKeychain: new FormControl(null, Validators.required),
        zoneName: new FormControl(null, Validators.required),
        zoneSequentialId: new FormControl(null, Validators.required),
        crop: new FormControl(null, Validators.required),
        cropId: new FormControl(null, Validators.required),
        packaging: new FormControl(null),
        packagingId: new FormControl(null)
    });
    formGroup.patchValue(data);
    return formGroup;
};
