export interface PositionDTO {
    name: string;
}

export interface PositionDTOPaginated {
    positions: PositionDTO[];
    total: number;
}

export class Position implements PositionDTO {
    _id: string;
    name: string;
    deleted: false;

    constructor (position: PositionDTO) {
        Object.assign(this, position);
    }
}

export class PositionPaginated implements PositionDTOPaginated {
    positions: Position[];
    total: number;

    constructor (data: PositionDTOPaginated) {
        this.positions = data.positions.map(position => new Position(position));
        this.total = data.total;
    }
}
