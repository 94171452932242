import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ABS_BASE_ROUTE, SECTIONS, TASKS_ROUTES } from '@shared/constants/routes';


@Component({
  selector: 'app-tasks-main',
  templateUrl: './tasks-main.component.html',
  styleUrls: ['./tasks-main.component.scss']
})
export class TasksMainComponent implements OnInit {
  searchPattern: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  search = (search: string) => this.searchPattern = search;

  addTask = () => this.router.navigate([
    ABS_BASE_ROUTE, SECTIONS.production,
    TASKS_ROUTES.parent, TASKS_ROUTES.add
  ])
}
