<div class="alert alert-secondary last-updated" *ngIf="report && report.performance">
    <p class="message">{{ 'campaigns.performance.LAST_UPDATE' | translate }}: <strong>{{ report.updatedAt | date : 'medium' }}</strong></p>
    <button mat-flat-button color="primary" class="actions-button btn align-self-center" type="button" [disabled]="campaign.status === CampaignStatus.Finished" (click)="loadReportDialog()">
        <span class="btn-text">{{ 'general.actions.UPDATE_REPORT' | translate }}</span>
    </button>
</div>
<ul class="summary" *ngIf="report && report.performance">
    <li class="item">
        <span class="title">{{ 'campaigns.performance.TOTAL_HARVESTED' | translate }}</span>
        <span class="content">{{ report ? (report.performance.totalHarvested | number : '1.2-2') + ' ' + totalUnit : '-' }}</span>
    </li>
    <li class="item">
        <span class="title">{{ 'campaigns.performance.TOTAL_HOURS' | translate }}</span>
        <span class="content">{{ report ? (report.performance.totalHours | number : '1.2-2') + ' h' : '-' }}</span>
    </li>
    <li class="item">
        <span class="title">{{ 'campaigns.performance.WORKING_DAYS' | translate }}</span>
        <span class="content">{{ report ? (report.performance.workingDays | number : '1.0-0') : '-' }}</span>
    </li>
    <li class="item">
        <span class="title">{{ 'campaigns.performance.AVERAGE_HOURS' | translate }}</span>
        <span class="content">{{ report ? (report.performance.averageHours | number : '1.2-2') + ' h/px' : '-' }}</span>
    </li>
</ul>

<section class="tables">
    <table class="crops" *ngIf="report && report.performance">
        <thead>
            <tr>
                <th class="name">{{ 'campaigns.performance.CROP' | translate }}</th>
                <th class="working-days">{{ 'campaigns.performance.WORKING_DAYS' | translate }}</th>
                <th class="average-hours">{{ 'campaigns.performance.AVERAGE_HOURS' | translate }}</th>
                <th class="average-harvested">{{ 'campaigns.performance.AVERAGE_HARVESTED' | translate }}</th>
                <th class="total-hours">{{ 'campaigns.performance.TOTAL_HOURS' | translate }}</th>
                <th class="total-harvested">{{ 'campaigns.performance.TOTAL_HARVESTED' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let crop of report.performance.crops">
                <td class="name">{{ crop.name }}</td>
                <td class="working-days">{{ crop.workingDays | number : '1.0-0' }}</td>
                <td class="average-hours">{{ crop.averageHours | number : '1.0-2' }} h/px</td>
                <td class="average-harvested">{{ crop.averageHarvested | number : '1.0-2' }} {{ averageUnit }}</td>
                <td class="total-hours">{{ crop.totalHours | number : '1.0-2' }} h</td>
                <td class="total-harvested">{{ crop.totalHarvested | number : '1.0-2' }} {{ totalUnit }}</td>
            </tr>
        </tbody>
    </table>
</section>

<section class="tables" [ngSwitch]="true">
    <ng-container *ngSwitchCase="missingReport">
        <div class="alert alert-secondary d-flex flex-column align-items-stretch">
            <p>{{ 'campaigns.performance.MISSING_REPORT' | translate }}</p>
            <button mat-flat-button color="primary" class="actions-button btn align-self-center" type="button" [disabled]="campaign.status === CampaignStatus.Finished" (click)="loadReportDialog()">
                <span class="btn-text">{{ 'general.actions.GENERATE_REPORT' | translate }}</span>
            </button>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="report && report.performance === null">
        <div class="alert alert-warning d-flex flex-column align-items-stretch">
            <p>{{ 'campaigns.performance.MISSING_REPORT_DATA' | translate }}</p>
            <button mat-flat-button color="primary" class="actions-button btn align-self-center" type="button" [disabled]="campaign.status === CampaignStatus.Finished" (click)="loadReportDialog()">
                <span class="btn-text">{{ 'general.actions.GENERATE_REPORT' | translate }}</span>
            </button>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="report !== null && report.performance !== null">
        <table *ngFor="let date of dates; let tableIndex = index">
            <thead>
                <tr>
                    <th colspan="6" class="date">{{ date.date | date : 'longDate' }}</th>
                </tr>
                <tr>
                    <th class="name text-left">{{ 'campaigns.performance.SITE' | translate }}</th>
                    <th class="working-days">{{ 'campaigns.performance.WORKING_DAYS' | translate }}</th>
                    <th class="average-hours">{{ 'campaigns.performance.AVERAGE_HOURS' | translate }}</th>
                    <th class="average-harvested">{{ 'campaigns.performance.AVERAGE_HARVESTED' | translate }}</th>
                    <th class="total-hours">{{ 'campaigns.performance.TOTAL_HOURS' | translate }}</th>
                    <th class="total-harvested">{{ 'campaigns.performance.TOTAL_HARVESTED' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let site of date.sites; let rowIndex = index">
                    <tr class="table-row" (click)="showCropRow(tableIndex, rowIndex)">
                        <td class="name site">
                            <img src="/assets/images/icons/arrow-down.svg" alt="#" [ngClass]="{ 'rotate': displayedRow === 'show-' + tableIndex + '-' + rowIndex }">
                            <span>{{ site.name }}</span>
                        </td>
                        <td class="working-days">{{ site.workingDays | number : '1.0-0' }}</td>
                        <td class="average-hours">{{ site.averageHours | number : '1.0-2' }} h/px</td>
                        <td class="average-harvested">{{ site.averageHarvested | number : '1.0-2' }} {{ averageUnit }}</td>
                        <td class="total-hours">{{ site.totalHours | number : '1.0-2' }} h</td>
                        <td class="total-harvested">{{ site.totalHarvested | number : '1.0-2' }} {{ totalUnit }}</td>
                    </tr>
                    <tr>
                        <td class="crop-detail" colspan="6" [ngClass]="{ 'show': displayedRow === 'show-' + tableIndex + '-' + rowIndex }">
                            <table>
                                <tr *ngFor="let crop of site.crops">
                                    <td class="name">{{ crop.name }}</td>
                                    <td class="working-days">{{ crop.workingDays | number : '1.0-0' }}</td>
                                    <td class="average-hours">{{ crop.averageHours | number : '1.0-2' }} h/px</td>
                                    <td class="average-harvested">{{ crop.averageHarvested | number : '1.0-2' }} {{ averageUnit }}</td>
                                    <td class="total-hours">{{ crop.totalHours | number : '1.0-2' }} h</td>
                                    <td class="total-harvested">{{ crop.totalHarvested | number : '1.0-2' }} {{ totalUnit }}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr class="table-row">
                    <th class="name site">{{ 'campaigns.performance.TOTAL' | translate }}</th>
                    <th class="working-days">{{ date.workingDays | number : '1.0-0' }}</th>
                    <th class="average-hours">{{ date.averageHours | number : '1.0-2' }} h/px</th>
                    <th class="average-harvested">{{ date.averageHarvested | number : '1.0-2' }} {{ averageUnit }}</th>
                    <th class="total-hours">{{ date.totalHours | number : '1.0-2' }} h</th>
                    <th class="total-harvested">{{ date.totalHarvested | number : '1.0-2' }} {{ totalUnit }}</th>
                </tr>
                <tr>
                    <td class="crop-detail" colspan="6" [ngClass]="{ 'show': displayedRow === 'show-' + tableIndex + '-' + (date.sites.length + 1) }">
                        <table>
                            <tr *ngFor="let crop of date.crops">
                                <td class="name">{{ crop.name }}</td>
                                <td class="working-days">{{ crop.workingDays | number : '1.0-0' }}</td>
                                <td class="average-hours">{{ crop.averageHours | number : '1.0-2' }} h/px</td>
                                <td class="average-harvested">{{ crop.averageHarvested | number : '1.0-2' }} {{ averageUnit }}</td>
                                <td class="total-hours">{{ crop.totalHours | number : '1.0-2' }} h</td>
                                <td class="total-harvested">{{ crop.totalHarvested | number : '1.0-2' }} {{ totalUnit }}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tfoot>
        </table>
    </ng-container>

    <ng-container *ngSwitchCase="loadingSummary">
        <div class="alert alert-secondary text-center">{{ 'reports.messages.LOADING_REPORT' | translate }}</div>
    </ng-container>

    <mat-paginator
        [ngClass]="{ 'paginator-hidden': loadingSummary || report === null || report.performance === null }"
        [length]="paginatorLength" [pageSize]="3" [pageSizeOptions]="[1, 2, 3, 4, 5, 6, 7]" (page)="paginatePerformance($event)">
    </mat-paginator>
</section>