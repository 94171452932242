import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import { DateTime } from "luxon";

@Injectable({
    providedIn: 'root'
})
export class CustomDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek = (): number => 1;

    override deserialize(value: any): Date | null {
        if (typeof value === 'string' && !DateTime.fromFormat(value, 'dd-MM-yyyy').invalid) {
            const date = DateTime.fromFormat(value, 'dd-MM-yyyy');
            return new Date(date.year, date.month - 1, date.day);
        }
        return super.deserialize(value)
    }
       
}
