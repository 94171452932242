import { IPackagingType, IPackagingUnit } from '@routes/packagings/packaging.model';

export const PackagingUnit: IPackagingUnit[] = [{
    name: 'Kilogramo',
    symbol: 'kg'
}, {
    name: 'Unidades',
    symbol: 'ud'
}];

export const PackagingType: IPackagingType[] = [{
    name: 'fruit',
    tag: 'FRUIT'
}, {
    name: 'plant',
    tag: 'PLANT'
}];
