export const SNACKBAR_TYPES = {
    SUCCESS: 'SUCCESS',
    INFO: 'INFO',
    IN_PROGRESS: 'IN_PROGRESS',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
};
export const SNACKBAR_OPTIONS = {
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'bottom'
};
