import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { DataProviderService } from "@shared/services/data-provider.service";

@Injectable({
    providedIn: 'root'
})
export class DataProviderResolver implements Resolve<void[]> {

    constructor(
        private dataProviderService: DataProviderService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void[]> {
        const entities = route.data.entities;
        if (entities && entities.length) return this.dataProviderService.updateEntities(entities);
    }
    
}