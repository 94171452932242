<form [formGroup]="form" (submit)="submitForm()">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="{{ 'batchs.tabs.BATCH_DETAIL' | translate }}">
            <app-batchs-edit-main [batch]="batch"></app-batchs-edit-main>
        </mat-tab>
        <mat-tab label="{{ 'batchs.tabs.USED_BATCHS' | translate }}">
            <app-batchs-edit-unique-batchs></app-batchs-edit-unique-batchs>
        </mat-tab>
        <mat-tab label="{{ 'batchs.tabs.OUTPUT_ITEMS' | translate }}">
            <app-batchs-edit-items></app-batchs-edit-items>
        </mat-tab>
    </mat-tab-group>

    <div class="buttons">
        <app-generic-button
            [config]="cancelButtonConfig" [routerLink]="exitRoute"
        ></app-generic-button>
        <app-generic-button
            [config]="saveButtonConfig" [disabled]="form.invalid || isSaving"
        ></app-generic-button>
    </div>
</form>