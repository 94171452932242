import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBatchUniqueReceived, IBatchUniqueReceivedMixedBatch } from '@routes/batchs/batchs.model';
import { GenericMenuOptionIcon, GenericTableBehaviour } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { BehaviorSubject } from 'rxjs';
import { DatePipe, Location, DecimalPipe } from '@angular/common';
import { ABS_BASE_ROUTE, BATCHS_ROUTES, TRACEABILITY_ROUTES } from '@shared/constants/routes';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';

@Component({
  selector: 'app-batchs-detail-mixed-batchs',
  templateUrl: './batchs-detail-mixed-batchs.component.html',
  styleUrls: ['./batchs-detail-mixed-batchs.component.scss'],
  providers: [DatePipe, DecimalPipe]
})
export class BatchsDetailMixedBatchsComponent implements OnInit {

  @Input() batch: IBatchUniqueReceived;

  tableConfig: TableConfiguration<IBatchUniqueReceivedMixedBatch>;
  exitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.BACK',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button
  };
  navigation: string;
  
  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit(): void {
    this.setupTable();
    this.activatedRoute.queryParams.subscribe(params => this.navigation = params['navigation']);
  }

  setupTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Local,
    i18nRoot: 'batchs',
    data: new BehaviorSubject({ data: this.batch.mixedBatchs, total: this.batch.mixedBatchs.length }),
    pagination: {
      page: 1, size: 10, search: '', params: {},
      sort: { field: 'code', order: 1 }
    },
    columns: [
      new TableColumnText({
        header: 'CODE',
        name: 'code',
        sorting: 'code',
        displayAt: 0,
        value: batch => batch.code
      }),
      new TableColumnText({
        header: 'AMOUNT',
        name: 'amount',
        sorting: 'amount',
        displayAt: 960,
        value: batch => batch.boxes
      }),
      new TableColumnText({
        header: 'PALLET_NUMBER',
        name: 'palletNumber',
        sorting: 'palletNumber',
        displayAt: 640,
        value: batch => batch.palletNumber? batch.palletNumber : '0'
      }),
      new TableColumnText({
        header: 'DATETIME',
        name: 'datetime',
        sorting: 'datetime',
        displayAt: 520,
        value: batch => this.datePipe.transform(batch.datetime, 'd MMMM, H:mm', 'GMT')
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: batch => [ABS_BASE_ROUTE, TRACEABILITY_ROUTES.parent, BATCHS_ROUTES.parent, BATCHS_ROUTES.mixed, batch._id, BATCHS_ROUTES.detail],
            queryParams: {navigation: "previous"}
          })
        ]
      })
    ]
  });

  navigateToPreviousLink = () => this.navigation === 'previous'
    ? this.location.back() 
    : this.router.navigate(['../..'], { relativeTo: this.activatedRoute })


}
