import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { HelpComponent } from '@routes/help/help.component';
import { HelpMainComponent } from '@routes/help/help-main/help-main.component';
import { HelpResolver } from '@routes/help/help.resolver';

import { SharedModule } from '@shared/shared.module';

const routes: Routes = [
  {
    path: '',
    component: HelpComponent,
    children: [{
      path: '',
      component: HelpMainComponent,
      pathMatch: 'full',
      resolve: {
        help: HelpResolver
      }
    }]
  }
];

@NgModule({
  declarations: [
    HelpComponent,
    HelpMainComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ]
})
export class HelpModule {}
