import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { pairwise, tap } from 'rxjs/operators';
import { v4 } from 'uuid';

import { Campaign } from '@routes/campaigns/campaign.model';
import { CampaignPlannerService } from '@routes/campaigns/campaigns-planner/campaign.planner.service';
import { CampaignsDetailTasksEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.service';
import { createIntervalFormGroup } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.utils';

import { TASK_TYPES_TYPES } from '@shared/constants/task-types';
import { TaskEdit, TaskReceived } from '@shared/models/task.model';
import { WorkingDay } from '@shared/models/working-day.model';
import { WorkingDayService } from '@shared/services/working-day.service';
import { FORM_MODES } from '@shared/constants/forms';
import { TaskWrapper } from '@shared/constants/tasks';
import { CAMPAIGNS_DETAIL_ROUTES } from '@shared/constants/routes';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { TIME_REG_EXP } from '@shared/constants/regexp';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { transformDateToUTC } from '@shared/utils/date.utils';
import { TasksEditOrigin, TasksEditSection } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.constants';
import { CampaignsRoutingService } from '@routes/campaigns/campaigns-routing.service';

@Component({
  selector: 'app-campaigns-detail-tasks-edit',
  templateUrl: './campaigns-detail-tasks-edit.component.html',
  styleUrls: ['./campaigns-detail-tasks-edit.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailTasksEditComponent implements OnInit {

  @ViewChild(MatTabGroup, {static: true}) tabGroup: MatTabGroup;

  TasksEditSection = TasksEditSection;

  campaign: Campaign;
  workingDay: WorkingDay;
  task: TaskReceived;
  form: FormGroup;
  section: TasksEditSection;
  origin: TasksEditOrigin;
  mode: FORM_MODES;
  title: string;
  exitRoute: string[];

  exitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button
  };
  submitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.SAVE',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit
  };

  constructor(
    private plannerService: CampaignPlannerService,
    private tasksEditService: CampaignsDetailTasksEditService,
    private workingDayService: WorkingDayService,
    private campaignsRoutingService: CampaignsRoutingService,
    private translateService: TranslateService,
    private snackbarService: CustomSnackbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.campaign = this.plannerService.campaign;
    this.workingDay = this.tasksEditService.workingDay;
    this.task = this.tasksEditService.task;
    this.mode = this.tasksEditService.mode;

    this.setForm();
    this.setTitle();
    this.setSection();
    this.exitRoute = this.getExitRoute();
  }

  setForm = () => {
    this.form = new FormGroup({
      mandatory: new FormGroup({
        taskTypeId: new FormControl(null, Validators.required),
        startTimestamp: new FormControl(null, Validators.required),
        startTime: new FormControl(null, [Validators.required, Validators.pattern(TIME_REG_EXP.HOUR_MINUTES)]),
        endTimestamp: new FormControl(null, Validators.required),
        endTime: new FormControl(null, [Validators.required, Validators.pattern(TIME_REG_EXP.HOUR_MINUTES)]),
      }),
      optional: new FormGroup({
        zoneSequentialId: new FormControl(null, Validators.required),
        cropId: new FormControl(null, Validators.required),
        startedWithKeychain: new FormControl(false),
        endedWithKeychain: new FormControl(false)
      }),
      productionIntervals: new FormArray([])
    });
    this.form.get('mandatory.taskTypeId').valueChanges.pipe(tap(taskTypeId => this.checkSelectedTask(taskTypeId)), pairwise()).subscribe(value => {
      if (value.length < 2) return;
      const firstTaskType = this.tasksEditService.findTaskTypeType(value[0]);
      const secondTaskType = this.tasksEditService.findTaskTypeType(value[1]);
      if (firstTaskType !== secondTaskType) (this.form.get('productionIntervals') as FormArray).clear();
    });
    this.form.get('optional').disable();
    if (this.mode === FORM_MODES.edit) this.patchForm();
  }

  patchForm = () => {
    const task: TaskEdit = new TaskEdit({ ...this.task });
    task.startTime = this.datePipe.transform(task.startTimestamp, 'HH:mm', 'GMT');
    task.endTime = this.datePipe.transform(task.endTimestamp, 'HH:mm', 'GMT');

    this.form.get('mandatory').patchValue(task);
    this.form.get('optional').patchValue(task);

    task.productionIntervals?.forEach(interval => {
      const formGroup = createIntervalFormGroup(interval);
      (this.form.get('productionIntervals') as FormArray).push(formGroup);
    });
    this.form.updateValueAndValidity();
  }

  checkSelectedTask = (taskTypeId: string) => {
    const taskType = this.tasksEditService.findTaskTypeType(taskTypeId);
    const endDateControl = this.form.get('mandatory.endTimestamp') as FormControl;
    const endTimeControl = this.form.get('mandatory.endTime') as FormControl;
    const optionalFormGroup = this.form.get('optional') as FormGroup;

    if (taskType !== TASK_TYPES_TYPES.HARVEST) {
      endTimeControl.setValidators(Validators.required);
      optionalFormGroup.enable();
      return;
    }
    if (this.workingDay.endTimestamp) {
      endDateControl.setValidators(Validators.required);
      endTimeControl.setValidators(Validators.required);
    } else {
      endDateControl.clearValidators();
      endTimeControl.clearValidators();
    }
    optionalFormGroup.disable();
    optionalFormGroup.reset({startedWithKeychain: false, endedWithKeychain: false});
  }

  setTitle = () => {
    const title = `campaigns.tasks.titles.${this.mode.toUpperCase()}`;
    this.title = this.translateService.instant(title);
  }

  setSection = () => {
    const {section, origin} = this.activatedRoute.snapshot.queryParams;
    this.section = section ?? TasksEditSection.Task;
    this.origin = origin ?? TasksEditOrigin.Table;

    const index = Object.values(TasksEditSection).indexOf(this.section);
    this.tabGroup.selectedIndex = index;
  }

  getExitRoute = () => {
    switch (this.mode) {
      case FORM_MODES.add: return ['../..'];
      case FORM_MODES.edit: return ['../../../..', CAMPAIGNS_DETAIL_ROUTES.tasks];
    }
  }

  submitForm = () => {
    let task: TaskEdit = {
      ...this.task,
      ...this.form.value.mandatory,
      ...this.form.value.optional,
      ...this.form.value,
      deleted: false
    };
    let workingDay = new WorkingDay({...JSON.parse(JSON.stringify(this.workingDay))});
    task.startTimestamp = transformDateToUTC(task.startTimestamp);
    task.endTimestamp = transformDateToUTC(task.endTimestamp);
    if (this.mode === FORM_MODES.edit) this.removePreviousTask(workingDay);
    if (this.mode === FORM_MODES.add) task._id = v4();

    const taskType = this.tasksEditService.findTaskTypeType(task.taskTypeId);
    const taskWrapper = TaskWrapper[taskType];
    workingDay[taskWrapper].push(task);

    this.workingDayService.editWorkingDay(this.campaign.id, workingDay._id, workingDay)
      .then(() => this.manageExitNavigation())
      .catch(error => this.snackbarService.error(error.error.message));
  }

  removePreviousTask = (workingDay: WorkingDay) => {
    const taskType = this.tasksEditService.findTaskTypeType(this.task.taskTypeId);
    const taskWrapper = TaskWrapper[taskType];
    const index = workingDay[taskWrapper].findIndex((task: TaskReceived) => task && task._id === this.task._id);
    workingDay[taskWrapper].splice(index, 1);
  }

  manageExitNavigation = () => {
    const route = this.origin === TasksEditOrigin.Errors
      ? this.campaignsRoutingService.mainWorkingDays(this.workingDay.campaignId)
      : this.exitRoute;
    const queryParams = this.origin === TasksEditOrigin.Errors ? {errors: this.workingDay._id} : {};

    this.router.navigate(route, {queryParams, relativeTo: this.activatedRoute});
  }

}
