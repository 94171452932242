import { HttpParams } from '@angular/common/http';

interface ISort {
    order: number;
    field: string;
}

interface IPagination {
    size?: number;
    page?: number;
    sort?: ISort;
    search?: string;
    params?: object;
}

export interface IDataPaginated<T> {
    data: T[];
    total: number;
}

export class Pagination implements IPagination {
    public size?: number;
    public page?: number;
    public sort?: ISort;
    public search?: string;
    public params?: any;

    constructor(pagination?: IPagination) {
        if (pagination) {
            this.size = pagination.size;
            this.page = pagination.page;
            this.sort = pagination.sort;
            this.search = pagination.search;
            this.params = pagination.params;
        } else {
            this.params = {};
        }
    }

    loadHttpParams = (): HttpParams => {
        let params = new HttpParams();

        if (this.size && this.page) {
            params = params.set('size', this.size).set('page', this.page);
        }
        if (this.sort) {
            params = params.set('sort', this.sort.field).set('sortOrder', this.sort.order);
        }
        if (this.search) {
            params = params.set('search', this.search);
        }
        if (this.params) {
            Object.keys(this.params).forEach(key => {
                if (this.params[key] instanceof Object && this.params[key].constructor === Object) {
                    return Object.entries(this.params[key]).forEach(([operator, value]) =>
                        params = params.set(`${key}[${operator}]`, String(value)));
                }
                if (this.params[key] != null) {
                    return params = params.set(key, String(this.params[key]));
                }
            });
        }
        return params;
    }
}

export class GenericPagination extends Pagination {
    public size;
    public page;
    public search;
    public sort;

    constructor( private pagination: { size?, page?, search?, private?, sortField, orderField? }) {
        super();
        if (!pagination) return;
        this.size = pagination.size || Number.MAX_SAFE_INTEGER;
        this.page = pagination.page || 1;
        this.search = pagination.search || '';
        this.sort = { field: pagination.sortField, order: pagination.orderField || 1 };
    }
}