import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { User } from './user.model';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class UserEditResolver implements Resolve<User> {

    constructor(
        private userService: UserService
    ) {}

    resolve = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User> =>
        await this.userService.getUser(route.params.id);

}