import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IWarehouseReceived } from '@routes/warehouses/warehouses.model';

import { FileMode } from '@shared/constants/files';
import { IDataPaginated, Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import { UploadData } from '@shared/interfaces/upload';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WarehousesService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  private getWarehousesUrl = () => `${this.apiService.getBaseUrl()}/warehouses`;
  private getWarehouseUrl = (_id: string) => `${this.getWarehousesUrl()}/${_id}`;
  private getWarehousesFileUrl = (mode: string) => `${this.getWarehousesUrl()}/file/${mode}`;

  getWarehouses = (pagination?: Pagination): Promise<IDataPaginated<IWarehouseReceived>> =>
    lastValueFrom(this.http.get<IDataPaginated<IWarehouseReceived>>(this.getWarehousesUrl(), {params: pagination?.loadHttpParams()}));

  getWarehouse = (_id: string): Promise<IWarehouseReceived> =>
    lastValueFrom(this.http.get<IWarehouseReceived>(this.getWarehouseUrl(_id)));

  uploadWarehousesFile = (data: FormData, mode: FileMode): Promise<UploadData> =>
    lastValueFrom(this.http.post<any>(this.getWarehousesFileUrl(mode), data));

}
