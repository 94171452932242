import { Directive, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Directive()
export class SubscriptionManager implements OnDestroy {

    private subscriptions: Subscription[] = [];

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    addSubscription = (subscription: Subscription) => this.subscriptions.push(subscription);

}