<ul class="list">
    <li>
        <p class="category">{{ 'dispatchNotes.entities.CODE' | translate }}</p>
        <p class="content">{{ dispatchNote.code }}</p>
    </li>
    <li>
        <p class="category">{{ 'dispatchNotes.entities.STATUS' | translate }}</p>
        <p class="content">{{ 'dispatchNotes.statuses.' + dispatchNote.status.toUpperCase() | translate }}</p>
    </li>
    <li>
        <p class="category">{{ 'dispatchNotes.entities.SITE' | translate }}</p>
        <p class="content">{{ dispatchNote.site.name }}</p>
    </li>
    <li>
        <p class="category">{{ 'dispatchNotes.entities.DATETIME' | translate }}</p>
        <p class="content">{{ dispatchNote.datetime | date : 'dd/MM/yyyy, HH:mm' }}</p>
    </li>
    <li>
        <p class="category">{{ 'dispatchNotes.entities.ORIGIN' | translate }}</p>
        <p class="content">{{ dispatchNote.originId ? dispatchNote.origin.businessName : '-' }}</p>
    </li>
    <li>
        <p class="category">{{ 'dispatchNotes.entities.DESTINATION' | translate }}</p>
        <p class="content">{{ dispatchNote.destinationId ? dispatchNote.destination.businessName : '-' }}</p>
    </li>
    <li>
        <p class="category">{{ 'dispatchNotes.entities.TOTAL' | translate }}</p>
        <p class="content">
            <span>{{ dispatchNote.amount === 0 ? '-' : (dispatchNote.amount | number : '1.2-2') }}</span>
            <span class="unit" *ngIf="dispatchNote.amount">{{ unit }}</span>
        </p>
    </li>
</ul>
