<div *ngIf="!deliveryAddresses.length; else hasAddresses" class="alert alert-secondary align-justify">
    {{ 'companies.messages.NO_DELIVERY_ADDRESSES' | translate }}
</div>
<ng-template #hasAddresses>
    <table mat-table class="table" [dataSource]="dataSource">
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>{{ 'companies.entities.ADDRESS' | translate }}</th>
            <td mat-cell *matCellDef="let deliveryAddress">{{ deliveryAddress.address }}</td>
        </ng-container>
        
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>{{ 'companies.entities.CITY' | translate }}</th>
            <td mat-cell *matCellDef="let deliveryAddress">{{ deliveryAddress.city }}</td>
        </ng-container>
        
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>{{ 'companies.entities.STATE' | translate }}</th>
            <td mat-cell *matCellDef="let deliveryAddress">{{ deliveryAddress.state }}</td>
        </ng-container>

        <ng-container matColumnDef="zipcode">
            <th mat-header-cell *matHeaderCellDef>{{ 'companies.entities.ZIPCODE' | translate }}</th>
            <td mat-cell *matCellDef="let deliveryAddress">{{ deliveryAddress.zipcode }}</td>
        </ng-container>

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef>{{ 'companies.entities.COUNTRY' | translate }}</th>
            <td mat-cell *matCellDef="let deliveryAddress">{{ deliveryAddress.country }}</td>
        </ng-container>
        
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="table-cell table-actions">{{ 'general.tables.ACTIONS' | translate }}</th>
            <td mat-cell *matCellDef="let deliveryAddress; let i = index" class="table-cell table-actions">
                <button class="action-btn" (click)="deleteAddress(i)">
                    <img class="action-img" src="/assets/images/icons/delete.svg"/>
                </button>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</ng-template>
<form class="form" [formGroup]="form" (submit)="addAddress()" *ngIf="mode !== FORM_MODES.detail">
    <mat-form-field class="input full">
        <mat-label>{{ 'companies.entities.ADDRESS' | translate }}</mat-label>
        <input matInput type="text" formControlName="address" #deliveryAddress/>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    
    <mat-form-field class="input half">
        <mat-label>{{ 'companies.entities.CITY' | translate }}</mat-label>
        <input matInput type="text" formControlName="city"/>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    
    <mat-form-field class="input half">
        <mat-label>{{ 'companies.entities.STATE' | translate }}</mat-label>
        <input matInput type="text" formControlName="state"/>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>  

    <mat-form-field class="input half">
        <mat-label>{{ 'companies.entities.ZIPCODE' | translate }}</mat-label>
        <input matInput type="text" formControlName="zipcode"/>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>   

    <mat-form-field class="input half">
        <mat-label>{{ 'companies.entities.COUNTRY' | translate }}</mat-label>
        <input matInput type="text" formControlName="country"/>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>

    <div class="buttons">
        <button mat-flat-button color="primary" type="submit" class="btn" [disabled]="form.invalid">{{ 'general.actions.ADD' | translate }}</button>
    </div>
</form>