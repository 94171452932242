import { TaskTypeUnit } from '@routes/tasks/task.model';

export const taskTypesUnits: TaskTypeUnit[] = [
    {
        name: 'KILOGRAMS_PER_HOUR',
        symbol: 'kg/h'
    },
    {
        name: 'PLANTS_PER_HOUR',
        symbol: 'pl/h'
    }
];

export enum TASK_TYPES_TYPES {
    HARVEST = 'harvest',
    WORKABLE = 'workable',
    NOT_WORKABLE = 'notWorkable',
    OTHER = 'other'
};
