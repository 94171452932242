import { Injectable } from "@angular/core";

import { CompaniesService } from "@routes/companies/companies.service";
import { CropService } from "@routes/crops/crop.service";
import { ItemsService } from "@routes/items/items.service";
import { PackagingService } from "@routes/packagings/packaging.service";
import { ProductionLinesService } from "@routes/production-lines/production-lines.service";
import { WarehousesService } from "@routes/warehouses/warehouses.service";
import { IBatchEditReferences } from "@routes/batchs/batchs-edit/batchs-edit.models";
import { WorkingSiteService } from "@routes/working-sites/working-site.service";

import { GenericPagination } from "@shared/models/pagination.model";

@Injectable({
    providedIn: 'root'
})
export class BatchsEditService {

    data: IBatchEditReferences = {};

    constructor(
        private cropsService: CropService,
        private packagingService: PackagingService,
        private itemsService: ItemsService,
        private companiesService: CompaniesService,
        private warehousesService: WarehousesService,
        private sitesService: WorkingSiteService,
        private productionLinesService: ProductionLinesService,
    ) {}

    retrieveReferenceData = async () => Promise.all([
        this.getSites(),
        this.getCrops(),
        this.getPackagings(),
        this.getItems(),
        this.getCompanies(),
        this.getWarehouses(),
        this.getProductionLines()
    ]);

    private getSites = () => this.sitesService.getSites(new GenericPagination({ sortField: 'name' }))
        .then(response => this.data.sites = response['sites']);

    private getCrops = async () =>
        this.data.crops = (await this.cropsService.getCrops()).data;

    private getPackagings = async () =>
        this.data.packagings = (await this.packagingService.getPackagings()).data;

    private getItems = async () =>
        this.data.items = (await this.itemsService.getItems()).data;

    private getCompanies = async () =>
        this.data.companies = (await this.companiesService.getCompanies()).data.filter(company => company.sageReference);

    private getWarehouses = async () =>
        this.data.warehouses = (await this.warehousesService.getWarehouses()).data;

    private getProductionLines = async () =>
        this.data.productionLines = (await this.productionLinesService.getProductionLines()).data;
    
}
