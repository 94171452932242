import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";

import { BuildInfo } from "@shared/models/buildinfo.model";
import { BuildService } from "@shared/services/build.service";

@Injectable({
    providedIn: 'root'
})
export class MobileAppResolver implements Resolve<BuildInfo> {

    constructor(
        private buildService: BuildService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<BuildInfo> {
        return this.buildService.getLatestBuild();
    }

}