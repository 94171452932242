export enum TaskType {
    Harvest = 'harvest',
    Workable = 'workable',
    Other = 'other'
};

export const TaskWrapper: {[key: string]: string} = {
    [TaskType.Harvest]: 'pickingTasks',
    [TaskType.Workable]: 'workableTasks',
    [TaskType.Other]: 'notWorkableTasks'
};
