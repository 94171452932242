<div class="account">
  <img class="account-image" src="/assets/images/icons/user.svg" alt="{{ 'general.alt.USER' | translate }}"/>
  <span class="account-name">{{ user.username }}</span>
  <span class="account-role">{{ user.role }}</span>
</div>
<nav class="nav">
  <ul class="nav-list">
    <ng-container *ngFor="let item of menuItems">
      <li class="nav-item" [routerLinkActive]="['active']" *ngIf="hasRole(item.roles)">
        <!-- If single menu item -->
        <a
          class="nav-link"
          *ngIf="!item.submenu || (item.submenu && item.submenu.length === 0)"
          [routerLink]="item.link"
          [queryParams]="item.queryParams"
          [attr.route]="formatRouteQueryParams(item.link, item.queryParams)"
          title="{{ (item.translate | translate) || item.text }}"
          (click)="toggleSubmenuClick($event)"
        >
          <img class="nav-icon" [src]="item.icon" [alt]="item.text" />
          <span class="nav-text">{{ (item.translate | translate) || item.text }}</span>
        </a>
  
        <!-- If has submenu -->
        <a
          class="nav-link"
          *ngIf="item.submenu && item.submenu.length && !item.link"
          title="{{ (item.translate | translate) || item.text }}"
          (click)="toggleSubmenuClick($event)"
        >
          <img class="nav-icon" [src]="item.icon" [alt]="item.text" />
          <span class="nav-text">{{ (item.translate | translate) || item.text }}</span>
        </a>
  
        <!-- submenu -->
        <ul *ngIf="item.submenu" class="nav-list sidebar-subnav" [routerLinkActive]="['opening']">
          <ng-container *ngFor="let subitem of item.submenu">
            <li *ngIf="hasRole(subitem.roles)" class="nav-item" routerLinkActive="active">
              <a
                class="nav-link"
                [routerLink]="subitem.link"
                [queryParams]="subitem.queryParams"
                [attr.route]="formatRouteQueryParams(subitem.link, subitem.queryParams)"
                title="{{ (subitem.translate | translate) || subitem.text }}">
                <img class="nav-icon" [src]="subitem.icon" [alt]="subitem.text" />
                <span class="nav-text">{{ (subitem.translate | translate) || subitem.text }}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-container>
    <li class="nav-item logout">
      <a class="nav-link logout" (click)="logOut()">
        <img class="nav-icon" src="/assets/images/icons/logout.svg" alt="{{ 'general.alt.LOGOUT' | translate }}"/>
        <span class="nav-text">{{ "sidebar.LOGOUT" | translate }}</span>
      </a>
    </li>
  </ul>
</nav>