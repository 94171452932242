import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

import { ProductionLinesComponent } from '@routes/production-lines/production-lines.component';
import { ProductionLinesTableComponent } from '@routes/production-lines/production-lines-table/production-lines-table.component';
import { ProductionLinesDialogComponent } from './production-lines-modal/production-lines-dialog.component';

const routes: Routes = [
  {
    path: '',
    component: ProductionLinesComponent,
    children: [
      {
        path: '',
        component: ProductionLinesTableComponent,
        pathMatch: 'full'
      }
    ]
  }
]

@NgModule({
  declarations: [
    ProductionLinesComponent,
    ProductionLinesTableComponent,
    ProductionLinesDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProductionLinesModule { }
