<div class="wrapper">
    <div class="alert alert-secondary table-description msg">
        <p class="message">{{ 'traceabilityReports.messages.MAIN' | translate }}</p> 
        <ul class="list">
            <li *ngFor="let text of translation">{{ text }}</li>
        </ul> 
    </div>
    <form [formGroup]="form" (submit)="submitForm()">
        <div class="searcher">
            <div class="radio">
                <mat-radio-group formControlName="reportCategory" id="reportCategory" color="primary">
                    <mat-radio-button value="plate">{{ 'traceabilityReports.reportTypes.PLATE_REPORT' | translate }}</mat-radio-button>
                    <mat-radio-button value="mixed">{{ 'traceabilityReports.reportTypes.MIXED_BATCH_REPORT' | translate }}</mat-radio-button>
                </mat-radio-group>
                </div>
            <div class="searchbar">
            <img class="icon" src="/assets/images/icons/search.svg"/>
            <mat-form-field class="control">
                <mat-label>{{placeholder}}</mat-label>
                <input id="search" formControlName="search" matInput #searcher type="text" >
                <mat-error *ngIf="form.get('search').errors">
                    <ng-container *ngIf="form.get('search').errors.pattern">
                        {{ errorMessage | translate }}
                    </ng-container>
                </mat-error>
            </mat-form-field>
            </div>
            <div class="buttons">
            <button  mat-flat-button color="primary" class="actions-button btn button" type="button" type="submit" [disabled]="form.invalid || loading">
                <img  class="btn-image" src="/assets/images/icons/download.svg" alt="#"/>
                <span class="btn-text">{{ 'traceabilityReports.button.DOWNLOAD' | translate }}</span>
            </button>
            </div>
        </div>
    </form>
</div>

