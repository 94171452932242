import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keychains',
  templateUrl: './keychains.component.html',
  styleUrls: ['./keychains.component.scss']
})
export class KeychainsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
