import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { Pagination } from '@shared/models/pagination.model';
import { PaginationService } from '@shared/services/pagination.service';
import { sortDataSource } from '@shared/utils/table.utils';
import { GenericTableBehaviour, GenericTableColumnType } from '@shared/components/generic-table/generic-table.constants';
import { IGenericTableData, TableConfiguration } from '@shared/components/generic-table/generic-table.model';
import { FiltersConfiguration, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit, OnDestroy {

  @Input() tableConfig: TableConfiguration<any>;
  @Input() filtersConfig: FiltersConfiguration;

  @Output() tableEvent: EventEmitter<Pagination> = new EventEmitter<Pagination>();
  @Output() selectionEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  Behaviours = GenericTableBehaviour;
  ColumnTypes = GenericTableColumnType;

  dataSource: MatTableDataSource<any>;
  pagination: Pagination;

  data: any[] = [];
  columnNames: string[] = [];
  total = 0;

  selectedEntities = new Map();

  loading = true;
  totalUpdates = 0;
  subscription = new Subscription();

  constructor(
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.pagination = new Pagination({ ...this.tableConfig.pagination });
    this.columnNames = this.tableConfig.columns.map(column => column.name);
    this.subscription.add(this.tableConfig.data.subscribe(data => this.updateData(data)));

    if (this.tableConfig.behaviour === GenericTableBehaviour.Local) {
      this.refreshData();
    }
    this.subscription.add(this.tableConfig.reload.subscribe(() => {
      this.loading = true;
      this.tableEvent.emit(this.pagination);
    }));
    this.subscription.add(this.tableConfig.updatePagination.subscribe(change => this.processFilterEvent(change)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  sortTable = (event: Sort) => {
    if (this.tableConfig.behaviour === GenericTableBehaviour.Server) {
      this.loading = true;
      this.paginationService.sort(this.pagination, event);
      this.tableEvent.emit(this.pagination);
    }
  }

  checkAll = (checked: boolean, selectionProperty: string) => {
    checked
      ? this.data.forEach(entity => this.selectedEntities.set(entity[selectionProperty], entity))
      : this.selectedEntities.clear();

    this.selectionEvent.emit(this.selectedEntities)
  }

  allChecked = (selectionProperty: string) => this.data.every(entity => this.selectedEntities.has(entity[selectionProperty]));

  someChecked = (selectionProperty: string) => this.data.some(entity => this.selectedEntities.has(entity[selectionProperty]));

  isChecked = (entity: any, selectionProperty: string) => this.selectedEntities.has(entity[selectionProperty]);

  check = (checked: boolean, selectionProperty: string, entity: any)  => {
    checked
      ? this.selectedEntities.set(entity[selectionProperty], entity)
      : this.selectedEntities.delete(entity[selectionProperty]);

    this.selectionEvent.emit(this.selectedEntities)
  }

  paginateTable = (event: PageEvent) => {
    if (this.tableConfig.behaviour === GenericTableBehaviour.Server) {
      this.loading = true;
    }
    this.paginationService.paginate(this.pagination, event);
    this.tableEvent.emit(this.pagination);
  }

  updateData = (data: IGenericTableData) => {
    this.totalUpdates++;
    this.loading = false;
    this.data = data.data;
    this.total = data.total;
    this.calculateRenderedData();

    if (this.tableConfig.behaviour === GenericTableBehaviour.Local) {
      this.refreshData();
    }
  }

  calculateRenderedData = () => {
    // TODO
  }

  refreshData = () => {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = sortDataSource;
  }

  processFilterEvent = (event: FiltersEvent) => {
    this.loading = true;
    this.pagination.params = { ...this.pagination.params, ...event.params };
    this.pagination.search = event.search;
    this.tableConfig.pagination = <any>{ ...this.pagination };

    if (this.pagination.page === 1) {
      this.tableEvent.emit(this.pagination);
      return;
    }
    this.pagination.page = 1;
    this.paginator.firstPage();
  }

}
