import { Injectable } from "@angular/core";
import { IDataPaginated, Pagination } from "@shared/models/pagination.model";
import { ApiService } from "@shared/services/api.service";
import { IDispatchNoteReceived, IDispatchNoteSent } from "@routes/dispatch-notes/dispatch-notes.model";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DispatchNotesService {

    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) {}

    private getDispatchNotesUrl = () => `${this.apiService.getBaseUrl()}/dispatch-notes`;
    private getDispatchNoteUrl = (_id: string) => `${this.getDispatchNotesUrl()}/${_id}?web=true`;
    private getDispatchNoteForDownloadUrl = (_id: string) => `${this.getDispatchNotesUrl()}/${_id}`;
    private getDispatchNoteDownloadUrl = (_id: string) => `${this.getDispatchNoteForDownloadUrl(_id)}/download`;

    getDispatchNotes = (pagination: Pagination): Promise<IDataPaginated<IDispatchNoteReceived>> =>
        lastValueFrom(this.http.get<IDataPaginated<IDispatchNoteReceived>>(this.getDispatchNotesUrl(), {params: pagination.loadHttpParams()}));

    getDispatchNote = (_id: string): Promise<IDispatchNoteReceived> =>
        lastValueFrom(this.http.get<IDispatchNoteReceived>(this.getDispatchNoteUrl(_id)));

    downloadDispatchNote = (_id: string): Promise<Blob> =>
        lastValueFrom(this.http.get(this.getDispatchNoteDownloadUrl(_id), {responseType: 'blob'}));

    editDispatchNote = (_id: string, dispatchNote: IDispatchNoteSent): Promise<IDispatchNoteReceived> =>
        lastValueFrom(this.http.put<IDispatchNoteReceived>(this.getDispatchNoteUrl(_id), dispatchNote));

}
