import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { BatchsEditService } from '@routes/batchs/batchs-edit/batchs-edit.service';
import { IBatchMixedBatch, IBatchMixedCrop, IBatchMixedPackaging, IBatchMixedSite } from '@routes/batchs/batchs.model';
import { ICrop } from '@routes/crops/crop.model';
import { IPackagingReceived } from '@routes/packagings/packaging.model';
import { IWorkingSite } from '@routes/working-sites/working-site.model';
import { BATCH_CATEGORY } from '@routes/batchs/batchs.constants';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { GenericButtonColor, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { FORM_MODES } from '@shared/constants/forms';
import { BATCH_REG_EXP } from '@shared/constants/regexp';
import { BatchsService } from '@routes/batchs/batchs.service';
import { Pagination } from '@shared/models/pagination.model';
import { maxDecimals } from '@shared/utils/validators.utils';

@Component({
  selector: 'app-batch-edit-unique-dialog',
  templateUrl: './batch-edit-unique-dialog.component.html',
  styleUrls: ['./batch-edit-unique-dialog.component.scss']
})
export class BatchsEditUniqueDialogComponent implements OnInit {

  FORM_MODES = FORM_MODES;
  mode: FORM_MODES;

  crops: ICrop[];
  packagings: IPackagingReceived[];
  sites: IWorkingSite[];
  loading: boolean;
  form: FormGroup;
  batchs: IBatchMixedBatch[];
  batch: IBatchMixedBatch;

  cancelButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CANCEL',
    color: GenericButtonColor.Warn,
    type: GenericButtonType.Button
  };
  submitButtonConfig: IGenericButtonConfig = {
    text: 'general.actions.CONFIRM',
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Submit
  };

  constructor(
    private batchsService: BatchsService,
    private batchsEditService: BatchsEditService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<BatchsEditUniqueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.mode = this.data.mode;
    if (this.mode === FORM_MODES.delete) return;

    this.batch = this.data.batch;
    this.batchs = this.data.batchs;
    this.setupForm();
    this.getReferenceData();
    
    const label = this.mode === FORM_MODES.add ? 'ADD' : 'EDIT';
    this.submitButtonConfig.text = `general.actions.${label}`;
  }

  setupForm = () => {
    this.form = new FormGroup({
      code: new FormControl(null, [Validators.required, this.codeAlreadyExistsValidator()]),
      boxes: new FormControl(null, [Validators.required, Validators.min(1), maxDecimals(2)]),
      deleted: new FormControl(false, Validators.required)
    });
    if (this.batch) this.form.patchValue(this.batch);
  }

  getReferenceData = () => {
    this.crops = this.batchsEditService.data.crops;
    this.packagings = this.batchsEditService.data.packagings;
    this.sites = this.batchsEditService.data.sites;
  }

  codeAlreadyExistsValidator = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
    const codes = this.batchs.map(b => b.code);
    if (this.batch) codes.splice(codes.findIndex(c => c === this.batch.code), 1);
    const existing = codes.find(c => c === control.value);
    return existing ? { alreadyExists: { value: control.value }} : null
  }

  submitForm = async () => {
    if (this.form.invalid) return;
    this.loading = true;
    try {
      const data = this.form.value;

      if (BATCH_REG_EXP.UNIQUE.test(data.code)) {
        const { siteCode, cropCode, packagingCode } = BATCH_REG_EXP.UNIQUE.exec(data.code).groups;
        
        const site = this.sites.find(s => s.traceCode === siteCode) as IBatchMixedSite;
        if (!site) return this.codeReferenceMissing('site', siteCode);

        const crop = this.crops.find(c => c.traceCode === cropCode) as IBatchMixedCrop;
        if (!crop) return this.codeReferenceMissing('crop', cropCode);

        const packaging = this.packagings.find(p => p.traceCode === packagingCode) as IBatchMixedPackaging;
        if (!packaging) return this.codeReferenceMissing('packaging', packagingCode);

        if (await this.batchExists(this.form.value.code)) {
          data.site = site;
          data.crop = crop;
          data.packaging = packaging;
        }
      }

      this.dialogRef.close(data);
    } catch (error) {
      console.error('Error en submitForm:', error);
    } finally {
      this.loading = false;
    }
  };

  codeReferenceMissing = (entity: string, code: string) => {
    let i18n: string;

    switch (entity) {
      case 'crop': i18n = 'CROP_DOES_NOT_EXISTS'; break;
      case 'packaging': i18n = 'PACKAGING_DOES_NOT_EXISTS'; break;
      case 'site': i18n = 'SITE_DOES_NOT_EXISTS'; break;
    }
    const message = this.translateService.instant(`batchs.messages.${i18n}`, { code });
    this.snackbarService.error(message);
  }

  batchExists = async (code: string): Promise<boolean> => {
    const pagination = new Pagination({
      page: 1, size: 1, search: code,
      params: { category: BATCH_CATEGORY.UNIQUE }});
    return (await this.batchsService.getBatchs(pagination)).total > 0;
  };

  confirmDelete = () => this.dialogRef.close(true);

  closeDialog = () => this.dialogRef.close();
  
}
