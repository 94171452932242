<app-generic-filters
    *ngIf="filtersConfig" [filtersConfig]="filtersConfig" (filtersEvent)="processFilterEvent($event)">
</app-generic-filters>

<div class="table-and-paginator" [hidden]="totalUpdates && !total">
    <table class="table" mat-table matSort [dataSource]="tableConfig.behaviour === Behaviours.Server ? data : dataSource" (matSortChange)="sortTable($event)">
        <ng-container *ngFor="let column of tableConfig.columns">
            <ng-container matColumnDef="{{ column.name }}">
                <ng-container [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="ColumnTypes.Selector">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox [color]="column.color"
                                    [checked]="allChecked(column.selectionProperty)"
                                    [indeterminate]="someChecked(column.selectionProperty) && !allChecked(column.selectionProperty)"
                                    (change)="checkAll($event.checked, column.selectionProperty)">
                            </mat-checkbox>
                        </th>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.sorting" [disabled]="!column.sorting" [ngClass]="['generic-cell', 'table-cell', column.name, column.style || '', 'align-' + column.align || '', 'display-at-' + column.displayAt]">
                            {{ (column.header && tableConfig.i18nRoot ? tableConfig.i18nRoot + '.' + column.header : column.header) | translate }}
                        </th>
                    </ng-container>
                </ng-container>
                <td mat-cell *matCellDef="let entity" class="generic-cell table-cell" [ngClass]="[column.name, column.style || '', 'align-' + column.align || '', 'display-at-' + column.displayAt]">
                    <ng-container [ngSwitch]="column.type">
                        <!-- Text -->
                        <ng-container *ngSwitchCase="ColumnTypes.Text">
                            {{ column.value(entity) || '-' }}
                        </ng-container>
                        <!-- Chip -->
                        <ng-container *ngSwitchCase="ColumnTypes.Chip">
                            <mat-chip-list>
                                <mat-chip [color]="column.color(entity)" selected>
                                    {{ column.value(entity) || '-' }}
                                </mat-chip>
                            </mat-chip-list>
                        </ng-container>
                        <!-- Menu -->
                        <ng-container *ngSwitchCase="ColumnTypes.Menu">
                            <button mat-icon-button type="button" [matMenuTriggerFor]="menu" aria-label="Menú de accciones">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" xPosition="before">
                                <ng-container *ngFor="let option of column.options">
                                    <button mat-menu-item *ngIf="option.action" (click)="option.action(entity)" [disabled]="option.disabled && option.disabled(entity)">
                                        <mat-icon>{{ option.icon }}</mat-icon>
                                        <span>{{ 'general.menu.' + option.text | translate }}</span>
                                    </button>
                                    <a mat-menu-item *ngIf="option.link" [routerLink]="option.link(entity)" [queryParams]="option.queryParams" [disabled]="option.disabled && option.disabled(entity)">
                                        <mat-icon>{{ option.icon }}</mat-icon>
                                        <span>{{ 'general.menu.' + option.text | translate }}</span>
                                    </a>
                                </ng-container>
                            </mat-menu>
                        </ng-container>
                        <!-- Checkbox --> 
                        <ng-container *ngSwitchCase="ColumnTypes.Checkbox">
                            <mat-checkbox
                                [ngClass]="{'disabled': column.disabled(entity)}"
                                [color]="column.color"
                                [checked]="column.checked(entity)"
                                [indeterminate]="column.intermediate && column.checked(entity)"
                                (change)="column.onChange(entity, $event)">
                            </mat-checkbox>
                        </ng-container>
                        <!-- Selector --> 
                        <ng-container *ngSwitchCase="ColumnTypes.Selector">
                            <mat-checkbox [color]="column.color" 
                                    [checked]="isChecked(entity, column.selectionProperty)"
                                    (change)="check($event.checked, column.selectionProperty, entity)">
                            </mat-checkbox>
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
    
    <mat-paginator class="paginator" [length]="total" [pageSizeOptions]="[5, 10, 25, 50, 100]"
        [pageSize]="tableConfig.pagination.size" (page)="paginateTable($event)" showFirstLastButtons>
    </mat-paginator>
</div>

<div class="alert alert-warning" *ngIf="totalUpdates && !total">
    <div *ngIf="filtersConfig; else missingWithoutFilters" [innerHTML]="'general.tables.MISSING_DATA_WITH_FILTERS' | translate"></div>
    <ng-template #missingWithoutFilters>
        <div [innerHTML]="'general.tables.MISSING_LOCAL_DATA' | translate"></div>
    </ng-template>
</div>

<div class="spinner" [ngClass]="{ 'show': loading }">
    <mat-spinner></mat-spinner>
</div>