<form [formGroup]="form" (submit)="submitForm()" *ngIf="mode !== FORM_MODES.delete; else deleteDialog">
    <h2 class="h2">{{ 'batchs.edit.batchs.dialog.' + mode.toUpperCase() | translate }}</h2>
    <!-- Code -->
    <mat-form-field class="input" appearance="standard">
        <mat-label>{{ 'batchs.edit.batchs.dialog.CODE' | translate }}</mat-label>
        <input matInput formControlName="code" type="text" name="code" id="code">
        <mat-hint>
            * {{ 'general.form.MANDATORY' | translate }}
        </mat-hint>
        <mat-error *ngIf="form.get('code').errors?.alreadyExists">
            {{ 'batchs.messages.BATCH_ALREADY_INCLUDED' | translate }}
        </mat-error>
    </mat-form-field>
    <!-- Boxes -->
    <mat-form-field class="input" appearance="standard">
        <mat-label>{{ 'batchs.edit.batchs.dialog.BOXES' | translate }}</mat-label>
        <input matInput formControlName="boxes" type="number" step="1" min="0" name="boxes" id="boxes">
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Buttons -->
    <mat-dialog-actions class="d-flex justify-content-between">
        <app-generic-button
            [config]="cancelButtonConfig" (click)="closeDialog()"
        ></app-generic-button>
        <app-generic-button
            [config]="submitButtonConfig" [disabled]="form.invalid || loading"
        ></app-generic-button>
    </mat-dialog-actions>
</form>

<ng-template #deleteDialog>
    <h2 class="title h2">{{ 'general.dialogs.delete.TITLE' | translate }}</h2>
    <div class="dialog-body">{{ 'general.dialogs.delete.BODY' | translate }}</div>
    <mat-dialog-actions class="d-flex justify-content-between">
        <app-generic-button
            [config]="cancelButtonConfig" (click)="closeDialog()"
        ></app-generic-button>
        <app-generic-button
            [config]="submitButtonConfig" (click)="confirmDelete()"
        ></app-generic-button>
    </mat-dialog-actions>
</ng-template>
