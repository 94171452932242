import { Sort } from '@angular/material/sort';
import { Pagination } from '../models/pagination.model';
import { PageEvent } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  paginate = (pagination: Pagination, event: PageEvent): Pagination => {
    pagination.page = event.pageIndex + 1;
    pagination.size = event.pageSize;
    return pagination;
  }

  sort = (pagination: Pagination, sort: Sort): Pagination => {
    if (sort.direction) {
      pagination.sort.field = sort.active;
      pagination.sort.order = (sort.direction === 'asc') ? 1 : -1;
    } else {
      pagination.sort.field = '';
      pagination.sort.order = 1;
    }
    return pagination;
  }

}