import { CampaignStatus } from '@routes/campaigns/campaigns.constants';
import { IWorkerPosition, WorkerReceived } from '@routes/workers/worker.model';

export interface ICampaignPaginated {
    total: number;
    campaigns: ICampaign[]
}

export interface ICampaign {
    _id?: string;
    name: string;
    type: string;
    status: CampaignStatus;
    startDate: Date;
    endDate: Date;
    sites: ICampaignSite[];
    workers: ICampaignWorker[];
    deviceIds: string[];
    devices?: ICampaignDevice[];
    agreeWorkdays: boolean;
}

export interface ICampaignSite {
    siteId: string;
    siteName?: string;
    zones: ICampaignZone[];
}

export interface ICampaignZone {
    sequentialId?: number;
    traceCode: string;
    name: string;
    cropId: string;
    cropName?: string;
    cropVariety?: string;
    area: number;
    plants: number;
}

export interface ICampaignWorker {
    workerId: string;
    name: string;
    surname: string;
    sequentialId: number;
    nif?: string;
    position?: IWorkerPosition;
}

export interface ICampaignDevice {
    _id: string;
    name: string;
}

export interface ICampaignWorkersFile {
    addedWorkers: WorkerReceived;
    warnings: { msg: string }[];
    error: { msg: string }[];
}

export class Campaign implements ICampaign {
    public _id?: string;
    public id?: string;
    public name: string;
    public type: string;
    public status: CampaignStatus;
    public startDate: Date;
    public endDate: Date;
    public sites: CampaignSite[];
    public workers: CampaignWorker[];
    public deviceIds: string[];
    public devices?: CampaignDevice[];
    public agreeWorkdays: boolean;

    constructor(campaign: ICampaign) {
        Object.assign(this, campaign);
    }
}

export class CampaignSite implements ICampaignSite {
    public siteId: string;
    public zones: CampaignZone[];
    public siteName?: string;

    constructor(site: ICampaignSite) {
        Object.assign(this, site);
    }
}

export class CampaignZone implements ICampaignZone {
    public sequentialId?: number;
    public traceCode: string;
    public name: string;
    public cropId: string;
    public cropName?: string;
    public cropVariety?: string;
    public area: number;
    public plants: number;
    
    constructor(zone: ICampaignZone) {
        Object.assign(this, zone);
    }
}

export class CampaignWorker implements ICampaignWorker {
    workerId: string;
    name: string;
    surname: string;
    sequentialId: number;
    nif?: string;
    position?: IWorkerPosition;

    constructor(worker: ICampaignWorker) {
        Object.assign(this, worker);
    }
}

export class CampaignDevice implements ICampaignDevice {
    _id: string;
    name: string;

    constructor(device: ICampaignDevice) {
        Object.assign(this, device);
    }
}