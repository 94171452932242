<ng-container *ngIf="mode !== FORM_MODES.delete; else deleteDialog">
    <form [formGroup]="form" (submit)="submitForm()">
        <h2 class="h2">{{ 'batchs.edit.items.dialog.' + mode.toUpperCase() | translate }}</h2>
        <!-- Item -->
        <mat-form-field class="input" appearance="standard">
            <mat-label>{{ 'batchs.edit.items.dialog.ITEM' | translate }}</mat-label>
            <input matInput formControlName="item" [matAutocomplete]="auto"/>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayItem" (optionSelected)="selectItem($event)"> 
                <mat-option *ngFor="let item of filteredItems" [value]="item">
                    {{ item.sageReference }} ({{ item.description }})
                </mat-option>
            </mat-autocomplete>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
            <mat-error *ngIf="form.get('item').errors">
                <ng-container *ngIf="form.get('item').errors.alreadyExists">
                    {{ 'batchs.messages.ITEM_ALREADY_INCLUDED' | translate }}
                </ng-container>
                <ng-container *ngIf="form.get('item').errors.itemNotSelected">
                    {{ 'batchs.messages.ITEM_NOT_SELECTED' | translate }}
                </ng-container>
            </mat-error>
        </mat-form-field>
        <!-- Boxes -->
        <mat-form-field class="input" appearance="standard">
            <mat-label>{{ 'batchs.edit.items.dialog.BOXES' | translate }}</mat-label>
            <input matInput formControlName="boxes" type="number" step="1" min="0" name="boxes" id="boxes">
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Weight -->
        <mat-form-field class="input" appearance="standard">
            <mat-label>{{ 'batchs.edit.items.dialog.NET_WEIGHT' | translate }}</mat-label>
            <input matInput formControlName="weight" type="number" min="0" name="weight" id="weight">
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Buttons -->
        <mat-dialog-actions class="d-flex justify-content-between">
            <app-generic-button
                [config]="cancelButtonConfig" mat-dialog-close
            ></app-generic-button>
            <app-generic-button
                [config]="submitButtonConfig" [disabled]="form.invalid || loading"
            ></app-generic-button>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-template #deleteDialog>
    <h2 class="title h2">{{ 'general.dialogs.delete.TITLE' | translate }}</h2>
    <div class="dialog-body">{{ 'general.dialogs.delete.BODY' | translate }}</div>
    <mat-dialog-actions class="d-flex justify-content-between">
        <app-generic-button
            [config]="cancelButtonConfig" mat-dialog-close
        ></app-generic-button>
        <app-generic-button
            [config]="submitButtonConfig" (click)="confirmDelete()"
        ></app-generic-button>
    </mat-dialog-actions>
</ng-template>
