<h2 class="title">{{ 'campaigns.workingDays.mass_edit_dialog.ERROR' | translate }}</h2>
<p class="alert " [ngClass]="alert ? 'alert-warning' : 'alert-danger'">{{ error | translate }}</p>
<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close color="primary" class="actions-button btn">
        {{ 'general.actions.CLOSE' | translate }}
    </button>
    <button mat-flat-button type="submit" color="primary" class="actions-button btn" *ngIf="alert" (click)="confirm()">
        {{ 'general.actions.EDIT' | translate }}
    </button>
</mat-dialog-actions>
    
