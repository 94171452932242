import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Agency } from '@models/agency.model';
import { ApiService } from '@shared/services/api.service';
import { Pagination } from '@shared/models/pagination.model';
import { IPaginatedData } from '@shared/models/query.models';
import { FilterOperator } from '@shared/components/generic-filters/generic-filters.constants';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgencyService {

    constructor(
        private http: HttpClient,
        private apiService: ApiService
    ) {}

    private getAgenciesUrl = () => `${this.apiService.getBaseUrl()}/agencies`;
    private getAgencyUrl = (_id: string) => `${this.getAgenciesUrl()}/${_id}`;

    getAgencies = (pagination?: Pagination): Promise<IPaginatedData<Agency>> =>
        lastValueFrom(this.http.get<IPaginatedData<Agency>>(this.getAgenciesUrl(), {params: pagination?.loadHttpParams()}));

    getAgency = (_id: string): Promise<Agency> =>
        lastValueFrom(this.http.get<Agency>(this.getAgencyUrl(_id)));

    addAgency = (agency: Agency): Promise<Agency> =>
        lastValueFrom(this.http.post<Agency>(this.getAgenciesUrl(), agency));

    editAgency = (_id: string, agency: Agency): Promise<Agency> =>
        lastValueFrom(this.http.put<Agency>(this.getAgencyUrl(_id), agency));

    deleteAgency = (_id: string): Promise<void> =>
        lastValueFrom(this.http.delete<void>(this.getAgencyUrl(_id)));

    validateName = async (name: string) => {
        const params = {params: {name: {[FilterOperator.Regex]: `/${name}/i`}}};
        const pagination = new Pagination(params);
        const response = await this.getAgencies(pagination);
        return response.total > 0;
    }

    validateAbbreviation = async (abbreviation: string) => {
        const params = {params: {abbreviation: {[FilterOperator.Regex]: `/${abbreviation}/i`}}};
        const pagination = new Pagination(params);
        const response = await this.getAgencies(pagination);
        return response.total > 0;
    }

}
