export enum WorkerStatus {
    Active = 'active',
    Inactive = 'inactive'
}

export enum WorkerTranslation {
    Main = 'workers.main',
    Table = 'workers.table',
    Add = 'workers.add',
    Detail = 'workers.detail',
    DetailSelector = 'workers.detail.selector',
    DetailForm = 'workers.detail.form',
    DetailHistoric = 'workers.detail.historic',
    Upload = 'workers.upload',
    Delete = 'workers.delete',
    Template = 'workers.template',
    Export = 'workers.export',
    Activation = 'workers.activation'
}

export enum WorkerActivationMode {
    Activate,
    Deactivate
}
