import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from '@layout/header/header.service';
import { TranslateService } from '@ngx-translate/core';
import { IWorkingSite } from '@routes/working-sites/working-site.model';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';
import { SITE_TYPE } from '@routes/working-sites/working-sites.constants';
import { FiltersButtonLink, FiltersConfiguration, FiltersEvent } from '@shared/components/generic-filters/generic-filters.model';
import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableColumnStyle, GenericTableElementColor } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnMenu, TableColumnChip, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { ABS_BASE_ROUTE, WORKING_SITES_ROUTES } from '@shared/constants/routes';
import { Pagination } from '@shared/models/pagination.model';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-working-sites-main',
  templateUrl: './working-sites-main.component.html',
  styleUrls: ['./working-sites-main.component.scss']
})
export class WorkingSitesMainComponent implements OnInit {

  tableConfig: TableConfiguration<IWorkingSite>;
  filtersConfig: FiltersConfiguration;

  WORKING_SITES_BASE_ROUTE = [ ABS_BASE_ROUTE, WORKING_SITES_ROUTES.parent ];

  constructor(
    private workingSiteService: WorkingSiteService,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private headerService: HeaderService,
  ) { }

  ngOnInit(): void {
    this.setupFilters();
    this.setupTable();
    this.headerService.typeFilterChanges().subscribe(type => {
      Object.assign(this.tableConfig.pagination.params, { type });
      this.tableConfig.updatePagination.next(this.tableConfig.pagination as FiltersEvent);
    });
  }

  getWorkingSites = (pagination: Pagination) => this.workingSiteService.getSites(pagination)
    .then(response => this.tableConfig.data.next({ data: response.sites, total: response.total }))
    .catch(error => this.snackbarService.error(error.error.message))

  setupFilters = () => this.filtersConfig = new FiltersConfiguration({
    buttons: [
      new FiltersButtonLink({
        text: 'NEW',
        icon: 'add',
        link: [...this.WORKING_SITES_BASE_ROUTE, WORKING_SITES_ROUTES.add]
      })
    ],
    controls: []
  });

  setupTable = () => this.tableConfig = new TableConfiguration({
    behaviour: GenericTableBehaviour.Server,
    i18nRoot: 'workingSites.table',
    data: new BehaviorSubject({ data: null, total: 0 }),
    pagination: {
      page: 1, size: 10, search: '',
      sort: { field: 'name', order: 1 },
      params: { type: this.headerService.typeFilter },
    },
    columns: [
      new TableColumnText({
        header: 'NAME',
        name: 'name',
        sorting: 'name',
        displayAt: 0,
        value: workingSite => workingSite.name
      }),
      new TableColumnText({
        header: 'ALIAS',
        name: 'alias',
        sorting: 'alias',
        displayAt: 1280,
        value: workingSite => workingSite.alias
      }),
      new TableColumnText({
        header: 'CITY',
        name: 'city',
        sorting: 'city',
        displayAt: 1024,
        value: workingSite => workingSite.city
      }),
      new TableColumnText({
        header: 'ABBREVIATION',
        name: 'abbreviation',
        sorting: 'abbreviation',
        displayAt: 400,
        value: workingSite => workingSite.abbreviation
      }),
      new TableColumnText({
        header: 'TRACE_CODE',
        name: 'tracecode',
        sorting: 'tracecode',
        displayAt: 520,
        value: workingSite => workingSite.traceCode
      }),
      new TableColumnChip({
        header: 'TYPE',
        name: 'type',
        sorting: 'type',
        displayAt: 1280,
        value: workingSite => this.translateService.instant(`general.types.${workingSite.type.toUpperCase()}`),
        color: workingSite => this.getChipColor(workingSite),
      }),
      new TableColumnMenu({
        header: 'ACTIONS',
        name: 'actions',
        displayAt: 0,
        options: [
          new TableMenuOption({
            text: 'DETAIL',
            icon: GenericMenuOptionIcon.Search,
            link: (workingSite: IWorkingSite) => [ ...this.WORKING_SITES_BASE_ROUTE, WORKING_SITES_ROUTES.detail, workingSite._id]
          }),
          new TableMenuOption({
            text: 'EDIT',
            icon: GenericMenuOptionIcon.Edit,
            link: (workingSite: IWorkingSite) => [ ...this.WORKING_SITES_BASE_ROUTE, WORKING_SITES_ROUTES.edit, workingSite._id]
          })
        ]
      })
    ]
  })

  getChipColor = (workingSite: IWorkingSite) => {
    switch (workingSite.type) {
      case SITE_TYPE.PLANT: return GenericTableElementColor.Primary;
      case SITE_TYPE.FRUIT: return GenericTableElementColor.Warn;
      default: return GenericTableElementColor.Accent;
  }
}

}
