import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IErrorSync } from '@shared/interfaces/errors-sync.models';
import { ApiService } from '@shared/services/api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorsSyncService {

  private getErrorsSyncUrl = () => `${this.apiService.getBaseUrl()}/errors-sync`;

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  getErrorsSync = (filter: any): Promise<IErrorSync[]> =>
    lastValueFrom(this.http.get<IErrorSync[]>(this.getErrorsSyncUrl(), { params: filter }));

}
