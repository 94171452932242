<form [formGroup]="form" class="form" (submit)="submitForm()">
    <h2 class="title h2">{{ 'packagings.titles.' + mode.toUpperCase() | translate }}</h2>
    <!-- Description -->
    <mat-form-field class="input">
        <mat-label for="description">{{ 'packagings.form.DESCRIPTION' | translate }} </mat-label>
        <input matInput type="text" name="description" id="description" formControlName="description" placeholder="" value="">
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Type -->
    <mat-form-field class="input">
        <mat-label for="type">{{ 'packagings.form.TYPE' | translate }}</mat-label>
        <mat-select formControlName="type">
            <mat-option *ngFor="let type of PackagingType" [value]="type.name">
                {{ 'packagings.types.' + type.tag | translate }}
            </mat-option>
        </mat-select>
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Amount and unit -->
    <div class="shared">
        <!-- Amount -->
        <mat-form-field class="input amount">
            <mat-label for="description">{{ 'packagings.form.AMOUNT' | translate }} </mat-label>
            <input matInput type="number" name="amount" id="amount" formControlName="amount">
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Unit -->
        <mat-form-field class="input unit">
            <mat-label for="unit">{{ 'packagings.form.UNIT' | translate }}</mat-label>
            <mat-select formControlName="unit" name="unit" id="unit">
                <mat-option *ngFor="let unit of PackagingUnit" [value]="unit.symbol">
                    {{ unit.name }} ({{ unit.symbol }})
                </mat-option>
            </mat-select>
            <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
        </mat-form-field>
    </div>
    <!-- SAGE reference -->
    <mat-form-field class="input">
        <mat-label for="sageReference">{{ 'packagings.form.SAGE_REFERENCE' | translate }} </mat-label>
        <input matInput name="sageReference" id="sageReference" formControlName="sageReference">
        <mat-hint>* {{ 'general.form.MANDATORY' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Trace code -->
    <mat-form-field class="input" *ngIf="mode === FormMode.edit || mode === FormMode.detail">
        <mat-label for="traceCode">{{ 'packagings.form.TRACE_CODE' | translate }} </mat-label>
        <input matInput name="traceCode" id="traceCode" formControlName="traceCode">
        <mat-hint>* {{ 'general.form.NOT_EDITABLE' | translate }}</mat-hint>
    </mat-form-field>
    <!-- Actions -->
    <div class="buttons">
        <button *ngIf="mode === 'detail'" mat-flat-button color="primary" type="button" class="btn" [routerLink]="mainRoute">{{ 'general.actions.BACK' | translate }}</button>
        <button *ngIf="mode !== 'detail'" mat-flat-button color="warn" type="button" class="btn" [routerLink]="mainRoute">{{ 'general.actions.CANCEL' | translate }}</button>
        <button *ngIf="mode !== 'detail'" mat-flat-button color="primary" type="submit" class="btn" [disabled]="form.invalid || loading">{{ 'general.actions.CONFIRM' | translate }}</button>
    </div>
</form>
