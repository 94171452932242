import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FILTER_VALUES } from '@layout/header/header.constants';
import { HeaderService } from '@layout/header/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output('evtMenu') evtMenu = new EventEmitter<void>();

  FILTER_VALUES = FILTER_VALUES;
  filterValues = Object.values(FILTER_VALUES);
  filter: string;

  constructor(
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.filter = this.headerService.typeFilter || FILTER_VALUES.BOTH;
  }

  processFilter = (value: string) => this.headerService.setTypeFilter(value);

  getAsset = (filter: string, color: string = '') => `/assets/images/icons/chips/${filter}${color}.svg`;

}
