import { IMenuMainItem } from '@shared/interfaces/menu';
import { USERS_ROLES } from '@shared/constants/users';
import {
    WORKING_SITES_ROUTES,
    SECTIONS,
    CAMPAIGNS_ROUTES,
    CROPS_ROUTES,
    WORKER_ROUTES,
    AGENCIES_ROUTES,
    USERS_ROUTES,
    BATCHS_ROUTES,
    DISPATCH_NOTES_ROUTES,
    COMPANIES_ROUTES,
    ITEMS_ROUTES,
    WAREHOUSES_ROUTES,
    PRODUCTION_LINES_ROUTES,
    HELP_ROUTES,
    KEYCHAIN_ROUTES,
    MOBILE_APP_ROUTES,
    POSITIONS_ROUTES,
    TRACEABILITY_REPORTS_ROUTES
} from '@shared/constants/routes';

const ICONS_ROUTE = '/assets/images/icons';

const Users: IMenuMainItem = {
    text: 'Users',
    icon: `${ICONS_ROUTE}/users-management.svg`,
    translate: 'sidebar.USERS',
    link: USERS_ROUTES.parent,
    roles: [USERS_ROLES.superadmin]
}

const WorkingSites: IMenuMainItem = {
    text: 'WorkingSites',
    icon: `${ICONS_ROUTE}/garden-center.svg`,
    translate: 'sidebar.SITES',
    link: `${WORKING_SITES_ROUTES.parent}`,
    roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
};

const Companies: IMenuMainItem = {
    text: 'Companies',
    icon: `${ICONS_ROUTE}/companies.svg`,
    translate: 'sidebar.COMPANIES',
    link: `${COMPANIES_ROUTES.parent}`,
    roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
};

const Workforce = {
    text: 'Workforce',
    icon: `${ICONS_ROUTE}/workforce.svg`,
    translate: 'sidebar.WORKFORCE',
    roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user],
    submenu: [
        {
            text: 'Workers',
            icon: `${ICONS_ROUTE}/workers.svg`,
            link: `${SECTIONS.workforce}/${WORKER_ROUTES.parent}`,
            translate: 'sidebar.WORKERS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        },
        {
            text: 'Positions',
            icon: `${ICONS_ROUTE}/positions.svg`,
            link: `${SECTIONS.workforce}/${POSITIONS_ROUTES.parent}`,
            translate: 'sidebar.POSITIONS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin]
        },
        {
            text: 'Agencies',
            icon: `${ICONS_ROUTE}/agencies.svg`,
            link: `${SECTIONS.workforce}/${AGENCIES_ROUTES.parent}`,
            translate: 'sidebar.AGENCIES',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        },
        {
            text: 'Devices',
            icon: `${ICONS_ROUTE}/devices.svg`,
            link: `${SECTIONS.workforce}/devices`,
            translate: 'sidebar.DEVICES',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        },
        {
            text: 'Keychains',
            icon: `${ICONS_ROUTE}/keychain.svg`,
            link: `${SECTIONS.workforce}/${KEYCHAIN_ROUTES.parent}`,
            translate: 'sidebar.KEYCHAINS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        }
    ]
};

const Production: IMenuMainItem = {
    text: 'Production',
    icon: `${ICONS_ROUTE}/production.svg`,
    translate: 'sidebar.PRODUCTION',
    roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user],
    submenu: [
        {
            text: 'Campaigns',
            icon: `${ICONS_ROUTE}/campaigns.svg`,
            link: `${SECTIONS.production}/${CAMPAIGNS_ROUTES.parent}`,
            translate: 'sidebar.CAMPAIGNS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        }, {
            text: 'Crops',
            icon: `${ICONS_ROUTE}/crops.svg`,
            link: `${SECTIONS.production}/${CROPS_ROUTES.parent}`,
            translate: 'sidebar.CROPS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        }, {
            text: 'Tasks',
            icon: `${ICONS_ROUTE}/tasks.svg`,
            link: `${SECTIONS.production}/tasks`,
            translate: 'sidebar.TASKS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        }, {
            text: 'Packagings',
            icon: `${ICONS_ROUTE}/packagings.svg`,
            link: `${SECTIONS.production}/packagings`,
            translate: 'sidebar.PACKAGINGS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        },
        {
            text: 'Production Lines',
            icon: `${ICONS_ROUTE}/production-line.svg`,
            link: `${SECTIONS.production}/${PRODUCTION_LINES_ROUTES.parent}`,
            translate: 'sidebar.PRODUCTION_LINES',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        },
    ]
};

const Traceability: IMenuMainItem = {
    text: 'Traceability',
    icon: `${ICONS_ROUTE}/traceability.svg`,
    translate: 'sidebar.TRACEABILITY',
    roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user],
    submenu: [
        {
            text: 'Batchs',
            icon: `${ICONS_ROUTE}/pallet.svg`,
            link: `${SECTIONS.traceability}/${BATCHS_ROUTES.parent}/${BATCHS_ROUTES.unique}`,
            translate: 'sidebar.UNIQUE_BATCHS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        },
        {
            text: 'Batchs',
            icon: `${ICONS_ROUTE}/transform.svg`,
            link: `${SECTIONS.traceability}/${BATCHS_ROUTES.parent}/${BATCHS_ROUTES.mixed}`,
            translate: 'sidebar.MIXED_BATCHS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        },
        {
            text: 'Batchs',
            icon: `${ICONS_ROUTE}/dispatch-note.svg`,
            link: `${SECTIONS.traceability}/${DISPATCH_NOTES_ROUTES.parent}`,
            translate: 'sidebar.DISPATCH_NOTES',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        },
        {
            text: 'Batchs',
            icon: '/assets/images/icons/report-black.svg',
            link: `${SECTIONS.traceability}/${TRACEABILITY_REPORTS_ROUTES.parent}`,
            translate: 'sidebar.REPORTS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        }
    ]
};

const Sage: IMenuMainItem = {
    text: 'Sage',
    icon: `${ICONS_ROUTE}/sage.svg`,
    translate: 'sidebar.SAGE',
    roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user],
    submenu: [
        {
            text: 'Items',
            icon: `${ICONS_ROUTE}/items.svg`,
            link: `${SECTIONS.production}/${ITEMS_ROUTES.parent}`,
            translate: 'sidebar.ITEMS',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        }, {
            text: 'Warehouses',
            icon: `${ICONS_ROUTE}/warehouse.svg`,
            link: `${SECTIONS.production}/${WAREHOUSES_ROUTES.parent}`,
            translate: 'sidebar.WAREHOUSES',
            roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
        }
    ]
};

const Help: IMenuMainItem = {
    text: 'Help',
    icon: `${ICONS_ROUTE}/help.svg`,
    translate: 'sidebar.HELP',
    link: `${HELP_ROUTES.parent}`,
    roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
};

const MobileApp: IMenuMainItem = {
    text: 'Mobile App',
    icon: `${ICONS_ROUTE}/mobile-app.svg`,
    translate: 'sidebar.MOBILE_APP',
    link: `${MOBILE_APP_ROUTES.parent}`,
    roles: [USERS_ROLES.superadmin, USERS_ROLES.admin, USERS_ROLES.user]
};

export const menu: IMenuMainItem[] = [
    Users, WorkingSites, Companies,
    Workforce, Production, Traceability,
    Sage, Help, MobileApp
];
