import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { PackagingsComponent } from './packagings.component';
import { PackagingsMainComponent } from './packagings-main/packagings-main.component';
import { PackagingsEditComponent } from './packagings-edit/packagings-edit.component';
import { PACKAGINGS_ROUTES } from '@shared/constants/routes';
import { PackagingResolver } from './packaging.resolver';
import { FORM_MODES } from '@shared/constants/forms';

const routes: Routes = [
  {
    path: '',
    component: PackagingsComponent,
    children: [
      {
        path: '',
        component: PackagingsMainComponent,
        pathMatch: 'full'
      },
      {
        path: PACKAGINGS_ROUTES.add,
        component: PackagingsEditComponent,
        data: { mode: FORM_MODES.add }
      },
      {
        path: `${PACKAGINGS_ROUTES.detail}/:id`,
        component: PackagingsEditComponent,
        data: { mode: FORM_MODES.detail },
        resolve: { packaging: PackagingResolver }
      },
      {
        path: `${PACKAGINGS_ROUTES.edit}/:id`,
        component: PackagingsEditComponent,
        data: { mode: FORM_MODES.edit },
        resolve: { packaging: PackagingResolver }
      },
      {
        path: `${PACKAGINGS_ROUTES.clone}/:id`,
        component: PackagingsEditComponent,
        data: { mode: FORM_MODES.clone },
        resolve: { packaging: PackagingResolver }
      }
    ]
  }
];

@NgModule({
  declarations: [
    PackagingsComponent,
    PackagingsMainComponent,
    PackagingsEditComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PackagingsModule {}
