import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericFiltersService } from '@shared/components/generic-filters/generic-filters-service.service';

@Component({
  selector: 'app-dispatch-notes',
  templateUrl: './dispatch-notes.component.html',
  styleUrls: ['./dispatch-notes.component.scss']
})
export class DispatchNotesComponent implements OnInit, OnDestroy {

  constructor(
    private genericFiltersService: GenericFiltersService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.genericFiltersService.removeFiltersValue();
  }

}
