import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CampaignService } from "@routes/campaigns/campaign.service";
import { CampaignStatus } from "@routes/campaigns/campaigns.constants";
import { CropService } from "@routes/crops/crop.service";
import { FORM_MODES } from "@shared/constants/forms";
import { CustomSnackbarService } from "@shared/services/snackbar.service";

@Injectable({
    providedIn: 'root'
})
export class CampaignsDetailZonesFormResolver implements Resolve<any> {

    constructor(
        private campaignService: CampaignService,
        private cropService: CropService,
        private translateService: TranslateService,
        private snackbarService: CustomSnackbarService
    ) {}

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        const campaignId = route.parent.parent.params.campaignId;
        const siteId = route.params.siteId;
        const sequentialId = route.params.sequentialId;
        const mode = route.data.mode;

        const campaign = await this.campaignService.getCampaign(campaignId);
        if (!campaign) this.throwError('CAMPAIGN_NOT_FOUND');

        const site = campaign.sites.find(site => site.siteId === siteId);
        if (!site) this.throwError('SITE_NOT_FOUND');

        if (mode === FORM_MODES.add) return { campaign, site };

        const zone = site.zones.find(zone => zone.sequentialId === Number.parseInt(sequentialId));
        if (!zone) this.throwError('ZONE_NOT_FOUND');;

        const crop = await this.cropService.getCrop(zone.cropId);
        if (!crop) this.throwError('CROP_NOT_FOUND');;

        return { campaign, site, zone, crop };
    }

    throwError = (message: string) => {
        this.snackbarService.error(this.translateService.instant(`general.errors.${message}`));
        throw new Error(message);
    }

}