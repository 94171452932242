import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BATCH_MIXED_STATUS, BATCH_MIXED_STATUS_EDIT } from '@routes/batchs/batchs.constants';
import { BatchsEditService } from '@routes/batchs/batchs-edit/batchs-edit.service';
import { IBatchMixedReceived } from '@routes/batchs/batchs.model';
import { ICompanyReceived } from '@routes/companies/companies.model';
import { IProductionLineReceived } from '@routes/production-lines/production-lines.model';
import { IWarehouseReceived } from '@routes/warehouses/warehouses.model';

import { FORM_MODES } from '@shared/constants/forms';
import { BATCH_REG_EXP } from '@shared/constants/regexp';

@Component({
  selector: 'app-batchs-edit-main',
  templateUrl: './batchs-edit-main.component.html',
  styleUrls: ['./batchs-edit-main.component.scss']
})
export class BatchsEditMainComponent implements OnInit {

  BATCH_MIXED_STATUS_EDIT = BATCH_MIXED_STATUS_EDIT;
  BATCH_MIXED_STATUS = BATCH_MIXED_STATUS;

  @Input() batch: IBatchMixedReceived;

  companies: ICompanyReceived[];
  warehouses: IWarehouseReceived[];
  productionLines: IProductionLineReceived[];

  mode: FORM_MODES;
  form: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private batchsEditService: BatchsEditService,
    private parentFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.mode = this.activatedRoute.snapshot.data.mode;
    this.form = this.parentFormGroup.control.get('basic') as FormGroup;
    this.getReferenceData();

    this.form.statusChanges.subscribe(status => {
      if (status === 'VALID') this.calculateBatchCode();
    });
  }

  getReferenceData = () => {
    this.companies = this.batchsEditService.data.companies;
    this.warehouses = this.batchsEditService.data.warehouses;
    this.productionLines = this.batchsEditService.data.productionLines;
  }

  calculateJulianDate = () => {
    const date = new Date();
    const current = date.getTime();
    const newYear = new Date(date.getFullYear(), 0, 1).getTime();
    return Math.ceil((current - newYear + 1) / (1000 * 60 * 60 * 24))
  }

  calculateBatchCode = () => {
    const companyCode = this.companies.find(c => c._id === this.form.value.companyId).traceCode;
    const warehouseCode = this.warehouses.find(w => w._id === this.form.value.warehouseId).traceCode;
    const productionLineCode = this.productionLines.find(p => p._id === this.form.value.productionLineId).traceCode;
    const traceCodeFragment = `LX${companyCode}${warehouseCode}${productionLineCode}`;

    if (this.mode === FORM_MODES.add) {
      const julianDate = this.calculateJulianDate().toString().padStart(3, '0');
      const hours = new Date().getHours().toString().padStart(2, '0');
      const minutes = new Date().getMinutes().toString().padStart(2, '0');

      this.form.get('code').setValue(`${traceCodeFragment}${julianDate}${hours}${minutes}`, { emitEvent: false });
      return;
    }
    const { julianDate, hours, minutes } = BATCH_REG_EXP.MIXED.exec(this.batch.code).groups;
    this.form.get('code').setValue(`${traceCodeFragment}${julianDate}${hours}${minutes}`, { emitEvent: false });
  }

}
