import { IntervalError, TaskError } from "@shared/constants/working-day";

export type IProductionInterval = IHarvestInterval | IWorkableInterval;

export interface ITaskPaginated {
    tasks: ITaskReceived[];
    total: number;
}

export interface ITaskReceived {
    _id: string;
    taskTypeId: string;
    workingDayId: string;
    startTimestamp: Date;
    endTimestamp?: Date;
    startedWithKeychain: boolean;
    endedWithKeychain?: boolean;
    totalHours?: number;
    error?: TaskError;
    deleted: boolean;
    zoneSequentialId?: number;
    zoneName?: string;
    cropId?: string;
    crop?: ITaskReceivedCrop;
    productionIntervals?: IHarvestInterval[] | IWorkableInterval[];
    taskType: ITaskReceivedTaskType;
    site: ITaskReceivedSite;
    worker: ITaskReceivedWorker;
    device: ITaskReceivedDevice;
    workingDay: ITaskReceivedWorkingDay;
}

export interface IHarvestInterval {
    _id: string;
    endTimestamp: Date;
    endedWithKeychain: boolean;
    amount: number;
    zoneSequentialId: number;
    zoneName: string;
    packagingId: string;
    packaging: ITaskReceivedPackaging;
    cropId: string;
    crop: ITaskReceivedCrop;
    totalHours: number;
    error?: IntervalError;
}

export interface IWorkableInterval {
    _id: string;
    cropId: string;
    totalHours: number;
    amount: number;
    zoneSequentialId: number;
    zoneName: string;
    endedWithKeychain: boolean;
    endTimestamp: Date;
    crop: ITaskReceivedCrop;
    error?: IntervalError;
}

export interface IErrorInterval extends IHarvestInterval, IWorkableInterval {
    taskId: string;
    taskName: string;
}

export interface ITaskReceivedPackaging {
    _id: string;
    unit: string;
    amount: string;
    description: string;
}

export interface ITaskReceivedCrop {
    _id: string;
    name: string;
    variety: string;
}

export interface ITaskReceivedTaskType {
    _id: string;
    name: string;
    workable: boolean;
    type: string;
}

export interface ITaskReceivedSite {
    _id: string;
    name: string;
    alias: string;
}

export interface ITaskReceivedWorker {
    _id: string;
    name: string;
    surname: string;
}

export interface ITaskReceivedDevice {
    _id: string;
    name: string;
}

export interface ITaskReceivedWorkingDay {
    startTimestamp: Date;
    endTimestamp: Date;
}

export class TaskReceived implements ITaskReceived {
    _id: string;
    taskTypeId: string;
    workingDayId: string;
    startTimestamp: Date;
    endTimestamp?: Date;
    startedWithKeychain: boolean;
    endedWithKeychain?: boolean;
    totalHours?: number;
    error?: TaskError;
    deleted: boolean;
    zoneSequentialId?: number;
    zoneName?: string;
    cropId?: string;
    productionIntervals?: IProductionInterval[];
    taskType: ITaskReceivedTaskType;
    site: ITaskReceivedSite;
    worker: ITaskReceivedWorker;
    device: ITaskReceivedDevice;
    workingDay: ITaskReceivedWorkingDay;
    crop?: ITaskReceivedCrop;

    constructor(task: ITaskReceived) {
        Object.assign(this, task);
    }
}

export interface ITaskSent {
    _id: string;
    taskTypeId: string;
    startTimestamp?: Date;
    startedWithKeychain?: boolean;
    endTimestamp?: Date;
    endedWithKeychain?: boolean;
    deleted: boolean;
    zoneSequentialId?: number;
    cropId?: string;
    productionIntervals?: IProductionInterval[];
}

export interface ITaskSentProductionInterval {
    _id: string;
    endTimestamp: Date;
    endedWithKeychain: boolean;
    zoneSequentialId: number;
    amount: number;
    cropId: string;
}

export interface ITaskSentHarvestInterval extends ITaskSentProductionInterval {
    packagingId: string;
    batchCode?: string;
}

export class TaskSent implements ITaskSent {
    _id: string;
    taskTypeId: string;
    taskType?: string;
    startTimestamp?: Date;
    startedWithKeychain?: boolean;
    endTimestamp?: Date;
    endedWithKeychain?: boolean;
    deleted: boolean;
    zoneSequentialId?: number;
    cropId?: string;
    productionIntervals?: IProductionInterval[];

    constructor(task: ITaskSent) {
        Object.assign(this, task);
    }
}

export class TaskEdit extends TaskSent {
    startTime: string;
    endTime: string;
}

export interface ITask {
    _id: string;
    taskTypeId: string;
    workingDayId: string;
    productionIntervals: ITaskProductionIntervals[];
}

export interface ITaskProductionIntervals {
    endTimestamp: Date;
    zoneId: string;
    packagingId: string;
    amount: number;
    zoneSequentialId: number;
}

export class Task implements ITask {
    _id: string;
    taskTypeId: string;
    workingDayId: string;
    productionIntervals: ITaskProductionIntervals[];

    constructor(task: ITask) {
        Object.assign(this, task);
    }
}

export interface ProductionIntervalResolved {
    task: TaskReceived;
    index: number;
}
