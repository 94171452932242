import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CampaignsDetailTasksEditProductionIntervalsDialogComponent } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit-production-intervals/campaigns-detail-tasks-edit-production-intervals-dialog/campaigns-detail-tasks-edit-production-intervals-dialog.component';
import { createIntervalFormGroup } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.utils';

import { GenericMenuOptionIcon, GenericTableBehaviour, GenericTableElementColor } from '@shared/components/generic-table/generic-table.constants';
import { TableColumnCheckbox, TableColumnMenu, TableColumnText, TableConfiguration, TableMenuOption } from '@shared/components/generic-table/generic-table.model';
import { IHarvestInterval, IProductionInterval } from '@shared/models/task.model';
import { TASK_TYPES_TYPES } from '@shared/constants/task-types';
import { CampaignsDetailTasksEditService } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.service';
import { IGenericButtonConfig } from '@shared/components/generic-button/generic-button.model';
import { GenericButtonColor, GenericButtonIcon, GenericButtonType } from '@shared/components/generic-button/generic-button.constants';
import { FORM_MODES } from '@shared/constants/forms';
import { ActivatedRoute } from '@angular/router';
import { TasksEditOrigin, TasksEditSection } from '@routes/campaigns/campaigns-detail/campaigns-detail-tasks/campaigns-detail-tasks-edit/campaigns-detail-tasks-edit.constants';
import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaigns-detail-tasks-edit-production-intervals',
  templateUrl: './campaigns-detail-tasks-edit-production-intervals.component.html',
  styleUrls: ['./campaigns-detail-tasks-edit-production-intervals.component.scss'],
  providers: [DatePipe]
})
export class CampaignsDetailTasksEditProductionIntervalsComponent implements OnInit {

  TASK_TYPES_TYPES = TASK_TYPES_TYPES;
  FORM_MODES = FORM_MODES;

  tableConfig: TableConfiguration<IProductionInterval>;
  parentForm: FormGroup;
  mandatoryForm: FormGroup;
  intervalsForm: FormArray;
  taskType: string;

  addButtonConfig: IGenericButtonConfig = {
    color: GenericButtonColor.Primary,
    type: GenericButtonType.Button,
    icon: GenericButtonIcon.Add,
  };
  dialogWidth = '420px';
  i18n = 'campaigns.tasks.table';

  constructor(
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService,
    private formGroupDirective: FormGroupDirective,
    private taskEditService: CampaignsDetailTasksEditService
  ) {}

  ngOnInit(): void {
    
    this.parentForm = this.formGroupDirective.form;
    this.mandatoryForm = this.parentForm.get('mandatory') as FormGroup;
    this.intervalsForm = this.parentForm.get('productionIntervals') as FormArray;
    this.taskType = this.mandatoryForm.get('taskTypeId').value ? this.taskEditService.findTaskTypeType(this.mandatoryForm.get('taskTypeId').value) : '';
    this.mandatoryForm.get('taskTypeId')
      .valueChanges.subscribe(taskTypeId => this.taskType = this.taskEditService.findTaskTypeType(taskTypeId));
    this.intervalsForm
      .valueChanges.subscribe(intervals => this.tableConfig.data.next({ data: intervals, total: intervals.length }));

    this.initTable();
    this.checkInitialStatus();
    this.checkRouteOrigin();
  }

  checkInitialStatus = () => {
    const taskTypeId = this.mandatoryForm.value.taskTypeId;
    if (!taskTypeId) return;
    this.taskType = this.taskEditService.findTaskTypeType(taskTypeId);

    const intervals = this.parentForm.get('productionIntervals').value;
    if (intervals.length) this.tableConfig.data.next({ data: intervals, total: intervals.length });
  }

  checkRouteOrigin = () => {
    const {origin, interval} = this.activatedRoute.snapshot.queryParams;
    if (origin === TasksEditOrigin.Errors && interval) this.editInterval(interval);
  }

  initTable = () => {
    this.tableConfig = new TableConfiguration({
      behaviour: GenericTableBehaviour.Local,
      i18nRoot: this.i18n,
      data: new BehaviorSubject({ data: [], total: 0 }),
      pagination: {
        page: 1, size: 10, search: '', params: {},
        sort: { field: 'endTimestamp', order: 1 }
      },
      columns: [
        new TableColumnText({
          header: 'TIME',
          name: 'time',
          sorting: 'endTimestamp',
          displayAt: 0,
          value: interval => this.datePipe.transform(interval.endTimestamp, 'HH:mm', 'GMT')
        }),
        new TableColumnText({
          header: 'ZONE',
          name: 'zone',
          sorting: 'zoneName',
          displayAt: 0,
          value: interval => interval.zoneName
        }),
        new TableColumnText({
          header: 'CROP',
          name: 'crop',
          sorting: 'crop.name',
          displayAt: 0,
          value: interval => `${interval.crop.name} (${interval.crop.variety})`
        }),
        new TableColumnText({
          header: 'PACKAGING',
          name: 'packaging',
          sorting: 'packaging.description',
          displayAt: 0,
          value: (interval: IHarvestInterval) => interval.packaging?.description 
        }),
        new TableColumnText({
          header: 'AMOUNT',
          name: 'amount',
          sorting: 'amount',
          displayAt: 0,
          value: interval => interval.amount
        }),
        new TableColumnCheckbox({
          header: 'KEYCHAIN',
          name: 'keychain',
          color: GenericTableElementColor.Primary,
          displayAt: 0,
          checked: interval => interval.endedWithKeychain,
          disabled: () => true
        }),
        new TableColumnMenu({
          header: 'ACTIONS',
          name: 'actions',
          displayAt: 0,
          options: [
            new TableMenuOption({
              text: 'EDIT',
              icon: GenericMenuOptionIcon.Edit,
              action: interval => this.editInterval(interval._id)
            }),
            new TableMenuOption({
              text: 'DELETE',
              icon: GenericMenuOptionIcon.Delete,
              action: interval => this.deleteInterval(interval._id)
            })
          ]
        })
      ]
    });
    if (this.taskType !== TASK_TYPES_TYPES.HARVEST) {
      const index = this.tableConfig.columns.findIndex(column => column.name === 'packaging');
      this.tableConfig.columns.splice(index, 1);
    } 
  }

  addInterval = () => {
    const task = {...this.parentForm.value.mandatory, ...this.parentForm.value.optional};

    this.dialog.open(CampaignsDetailTasksEditProductionIntervalsDialogComponent, {
      data: {mode: FORM_MODES.add, task},
      width: this.dialogWidth
    }).afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        const formGroup = createIntervalFormGroup(data.interval);
        this.intervalsForm.push(formGroup);
      });
  }

  editInterval = (intervalId: string) => {
    const {index, interval, task} = this.processSelectedInterval(intervalId);

    this.dialog.open(CampaignsDetailTasksEditProductionIntervalsDialogComponent, {
      data: {mode: FORM_MODES.edit, interval, task},
      width: this.dialogWidth
    }).afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => this.intervalsForm.at(index).reset(data.interval));
  }

  deleteInterval = (intervalId: string) => {
    const {index, interval, task} = this.processSelectedInterval(intervalId);

    this.dialog.open(CampaignsDetailTasksEditProductionIntervalsDialogComponent, {
      data: {mode: FORM_MODES.delete, interval, task},
      width: this.dialogWidth
    }).afterClosed()
      .pipe(filter(data => data && data.remove))
      .subscribe(() => this.intervalsForm.removeAt(index));
  }

  processSelectedInterval = (intervalId: string) => {
    const index = this.intervalsForm.value.findIndex((i: IProductionInterval) => i._id === intervalId);
    if (index === -1) {
      const error = this.translateService.instant(`${this.i18n}.INTERVAL_NOT_FOUND`);
      this.snackbarService.error(error);
      throw new Error(error);
    }
    const task = {...this.parentForm.value.mandatory, ...this.parentForm.value.optional};
    const interval = this.intervalsForm.value[index];
    return {index, interval, task};
  }

}
