import { HttpClient, HttpParams } from '@angular/common/http';

import { IBatchMixedReceived, IBatchMixedSent, IBatchType } from '@routes/batchs/batchs.model';
import { BATCH_CATEGORY } from '@routes/batchs/batchs.constants';

import { Injectable } from '@angular/core';
import { Pagination } from '@shared/models/pagination.model';
import { ApiService } from '@shared/services/api.service';
import { IPaginatedData } from '@shared/models/query.models';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BatchsService {

  private getBatchsUrl = () => `${this.apiService.getBaseUrl()}/batchs`;

  private getBatchUrl = (_id: string) => `${this.getBatchsUrl()}/${_id}`;

  private getSageSyncUrl = () => `${this.getBatchsUrl()}/sync/sage`;

  private getBatchReportUrl = (_id: string) => `${this.getBatchUrl(_id)}/download`;

  private getTraceabilityReportUrl = (search: string, category: string) => `${this.getBatchUrl(category)}/${search}`;

  private getBatchParams = (category: BATCH_CATEGORY): HttpParams => {
    let params = new HttpParams();
    params = params.set('detailed', true);
    params = params.set('category', category);
    return params;
  }

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  getBatchs = (pagination: Pagination): Promise<IPaginatedData<IBatchType>> =>
    lastValueFrom(this.http.get<IPaginatedData<IBatchType>>(this.getBatchsUrl(), {params: pagination.loadHttpParams()}));

  getBatch = (_id: string, category: BATCH_CATEGORY): Promise<IBatchType> =>
    lastValueFrom(this.http.get<IBatchType>(this.getBatchUrl(_id), { params: this.getBatchParams(category) }));

  addBatch = (batch: IBatchMixedSent): Promise<IBatchMixedReceived> =>
    lastValueFrom(this.http.post<IBatchMixedReceived>(this.getBatchsUrl(), batch));

  editBatch = (_id: string, batch: IBatchMixedSent): Promise<IBatchMixedReceived> =>
    lastValueFrom(this.http.put<IBatchMixedReceived>(this.getBatchUrl(_id), batch));

  synchronizeSage = (selectedBatchs: string[]): Promise<void> =>
    lastValueFrom(this.http.post<void>(this.getSageSyncUrl(), { batchIds: selectedBatchs }));

  downloadBatchReport = (_id: string): Promise<Blob> =>
    lastValueFrom(this.http.get(this.getBatchReportUrl(_id), { responseType: 'blob'}));

  downloadLatestBatchReport = (search: string, category: string): Promise<Blob> =>
    lastValueFrom(this.http.get(this.getTraceabilityReportUrl(search, category), { responseType: 'blob'}));

  deleteBatch = (_id: string): Promise<void> =>
    lastValueFrom(this.http.delete<void>(this.getBatchUrl(_id)));

}
