import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CompaniesService } from '@routes/companies/companies.service';
import { ICompanyReceived } from '@routes/companies/companies.model';
import { WorkingSite } from '@routes/working-sites/working-site.model';
import { WorkingSiteService } from '@routes/working-sites/working-site.service';

import { CustomSnackbarService } from '@shared/services/snackbar.service';
import { Pagination } from '@shared/models/pagination.model';
import { COMPANY_OWNERSHIP } from '@routes/companies/companies.constants';
import { FORM_MODES } from '@shared/constants/forms';
import { WORKING_SITE_TYPES } from '@shared/interfaces/types';
import { WORKING_SITES_ROUTES, ABS_BASE_ROUTE } from '@shared/constants/routes';

@Component({
  selector: 'app-working-sites-edit',
  templateUrl: './working-sites-edit.component.html',
  styleUrls: ['./working-sites-edit.component.scss']
})
export class WorkingSitesEditComponent implements OnInit {

  EXIT_ROUTE = [ABS_BASE_ROUTE, WORKING_SITES_ROUTES.parent];
  FORM_MODES = FORM_MODES;
  WORKING_SITE_TYPES = WORKING_SITE_TYPES;

  loading: boolean;
  companies: ICompanyReceived[] = [];
  title: string;
  form: FormGroup;
  mode: string;
  siteId: string;

  constructor(
    private workingSiteService: WorkingSiteService,
    private companiesService: CompaniesService,
    private snackbarService: CustomSnackbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      alias: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      abbreviation: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^[a-zA-Z]{1,2}$/))
      ]),
      companyId: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      traceCode: new FormControl({ value: null, disabled: true }),
      location: new FormGroup({
        latitude: new FormControl(null),
        longitude: new FormControl(null),
      }),
      cadastralRef: new FormControl(null),
      area: new FormControl(null),
      enclosure: new FormControl(null),
      plot: new FormControl(null)
    });

    this.mode = this.activatedRoute.snapshot.data.mode;
    this.title = `workingSites.form.title.${this.mode.toUpperCase()}`;
    await this.getCompanies();

    if (this.mode === FORM_MODES.detail) this.form.disable();
    if (this.mode !== FORM_MODES.add) await this.retrieveSite();
  }

  getCompanies = async () => {
    try {
      const pagination = new Pagination({params: {ownership: COMPANY_OWNERSHIP.OWN}});
      this.companies = (await this.companiesService.getCompanies(pagination)).data;
    } catch (error) {
      this.snackbarService.error(error.error.message)
    }
  }

  retrieveSite = async () => {
    this.siteId = this.activatedRoute.snapshot.params.id;
    const site = await this.workingSiteService.getSite(this.siteId);
    this.form.patchValue(site);
  }

  getFormType = (index: number): string => `general.types.${WORKING_SITE_TYPES[index].tag}`;

  submitForm = () => {
    if (this.form.invalid) return;
    this.loading = true;
  
    const site = new WorkingSite({ id: this.siteId, ...this.form.value });
    
    const fn = this.mode === FORM_MODES.add
      ? this.workingSiteService.createSite(site)
      : this.workingSiteService.editSite(site);
  
    fn
      .then(() => {
        this.router.navigate(this.EXIT_ROUTE);
      })
      .catch((error: HttpErrorResponse) => {
        this.snackbarService.error(error.error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
