import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IProductionLineReceived, IProductionLineSent } from "@routes/production-lines/production-lines.model";
import { IDataPaginated, Pagination } from "@shared/models/pagination.model";
import { ApiService } from "@shared/services/api.service";
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProductionLinesService {

    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) {}

    private getProductionLinesUrl = () => `${this.apiService.getBaseUrl()}/production-lines`;
    private getProductionLineUrl = (_id: string) => `${this.getProductionLinesUrl()}/${_id}`;

    getProductionLines = (pagination?: Pagination): Promise<IDataPaginated<IProductionLineReceived>> =>
        lastValueFrom(this.http.get<IDataPaginated<IProductionLineReceived>>(this.getProductionLinesUrl(), {params: pagination?.loadHttpParams()}));

    getProductionLine = (_id: string): Promise<IProductionLineReceived> =>
        lastValueFrom(this.http.get<IProductionLineReceived>(this.getProductionLineUrl(_id)));

    addProductionLine = (productionLine: IProductionLineSent): Promise<IProductionLineReceived> =>
        lastValueFrom(this.http.post<IProductionLineReceived>(this.getProductionLinesUrl(), productionLine));

    editProductionLine = (_id: string, productionLine: IProductionLineSent): Promise<IProductionLineReceived> =>
        lastValueFrom(this.http.put<IProductionLineReceived>(this.getProductionLineUrl(_id), productionLine));

}