<h2 class="dashboard-subheading">{{ 'campaigns.sites.TITLE' | translate }}</h2>
<table class="table" mat-table [dataSource]="sites">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="table-cell select"></th>
        <td mat-cell *matCellDef="let element" class="table-cell select">
            <mat-checkbox color="primary" [checked]="siteIsSelected(element.id)" (input)="selectSite($event, element.id, element.name)"></mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="table-cell name">{{ 'workingSites.table.NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="table-cell name">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef class="table-cell alias">{{ 'workingSites.table.ALIAS' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="table-cell alias">{{ element.alias }}</td>
    </ng-container>

    <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef class="table-cell city">{{ 'workingSites.table.CITY' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="table-cell city">{{ element.city }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let site; columns: columns"></tr>
</table>
<mat-paginator
    [length]="paginationTotal" [pageSize]="pagination.size"[pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateSites($event)">
</mat-paginator>
<div class="section-buttons">
    <button mat-flat-button color="primary"  class="btn" type="button" (click)="changeSection.emit('basic')">
        {{ 'general.actions.BACK' | translate }}
    </button>
    <button mat-flat-button color="primary" class="btn" type="button" (click)="changeSection.emit('summary')" [disabled]="!campaignHasSites()">
        {{ 'general.actions.CONTINUE' | translate }}
    </button>
</div>