import { Injectable, NgZone, Component } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_OPTIONS, SNACKBAR_TYPES } from '@shared/interfaces/snackbar';
import { CustomSnackbarComponent } from '@shared/widgets/custom-snackbar/custom-snackbar.component';

@Injectable()
export class CustomSnackbarService {
    constructor(
        public snackBar: MatSnackBar,
        private zone: NgZone,
        private translateService: TranslateService
    ) { }

    public open(type = SNACKBAR_TYPES.INFO, message: string, duration = SNACKBAR_OPTIONS.duration,
        horizontalPosition = SNACKBAR_OPTIONS.horizontalPosition,
        verticalPosition = SNACKBAR_OPTIONS.verticalPosition) {
        this.zone.run(() => {
            this.snackBar.openFromComponent(CustomSnackbarComponent,
                {
                    data: { type, message, action: this.translateService.instant('general.snackbar.ACTION') },
                    duration,
                    horizontalPosition: horizontalPosition as MatSnackBarHorizontalPosition,
                    verticalPosition: verticalPosition as MatSnackBarVerticalPosition,
                    panelClass: ['custom-snackbar-panel']
                });
        });
    }

    loading = (message: string) => this.open(SNACKBAR_TYPES.IN_PROGRESS, message, Number.MAX_SAFE_INTEGER);

    error = (message: string) => this.open(SNACKBAR_TYPES.ERROR, message);

    success = (message: string) => this.open(SNACKBAR_TYPES.SUCCESS, message);
    
}
